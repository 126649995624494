<template lang="pug">
    #PersonalSetting    
        loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
        h3.d-flex.align-center.setting-title.px-4.py-3
            .cube-icon.small.bg-primary.mr-3
                v-icon(size='18' color='white') icon-user
            span {{$t('SETTING.PERSONALSETTING')}}
        .setting-inner
            //- h4.text-h6.mb-4 一般設定
            v-row.justify-center(no-gutters)
                    v-col(cols='12' md='4')
                        v-hover
                            template(v-slot:default="{ hover }")            
                                v-list-item-avatar.ma-0.mr-3(color='aliceBlue' @click size='200')
                                    v-icon.mx-auto(v-if="isUserPhoto === false" size='150') {{ userData.last_name }}
                                    v-img(v-else :src='userPhoto' :alt='userPhoto' max-height="200" max-width="200" )
                                    v-icon.mx-auto(v-else size='250')
                                    v-fade-transition.d-flex.align-center.justify-center
                                        v-overlay.d-flex.align-center.justify-center(v-if="hover" absolute color='black')
                                            v-file-input.no-border(v-show="false" v-model="userImage" ref="uploader" @change="uploadimage" accept="image/*")
                                            v-icon(@click="changephoto" size='55' ) mdi-camera
            p 
            v-form(v-model="valid", ref="form")
                p                
                    v-row(no-gutters)
                        v-col.pr-2(cols='6')
                            label {{$t('PERSONALSETTING.LAST_NAME')}}
                            v-text-field(v-model='userData.last_name' solo dense label='Last Name' :rules="formRule.nameRules")
                        v-col.pl-2(cols='6')
                            label {{$t('PERSONALSETTING.FIRST_NAME')}}
                            v-text-field(v-model='userData.first_name' solo dense label='First Name' :rules="formRule.firstNameRules")
                p
                    label(for='personalMail') {{$t('GENERAL.MAIL')}}
                    v-text-field#personalMail(v-model='userData.email' type='mail' :disabled='personal.mailDisabled' solo dense readonly)
                p
                    label(for='opptyDefaultStage') {{$t('PERSONALSETTING.OPPTY_DEFAULT_STAGE')}}
                    v-select#opptyDefaultStage(v-model='opptyProcess' :items='opptyProcessList' item-value='id' item-text='stage_type_name' solo dense)
                p(v-if='!isWiadvance()')
                    label(for='caseDefaultStage') {{$t('PERSONALSETTING.CASE_DEFAULT_STAGE')}}
                    v-select#caseDefaultStage(v-model='caseProcess' :items='caseProcessList' item-value='id' item-text='stage_type_name' solo dense)
                p
                    label(for='personalLanguage') {{$t('PERSONALSETTING.LANGUAGE')}}
                    v-select#personalLanguage(v-model='userLanguage' :items='languageList' item-value='key' item-text='name' solo dense :rules="formRule.fieldRules")
                p
                    label(for='personalLocation') {{$t('PERSONALSETTING.REGION')}}
                    v-autocomplete#personalLocation(v-model='userLocation' :items='locationList' item-value='key' :item-text='locationName' solo dense spellcheck="false" 
                    :filter="customFilter" :rules="formRule.fieldRules")
                        template(v-slot:item='{ item }')
                            v-list-item-content
                                v-list-item-title {{item.name}}
                                v-list-item-subtitle {{item.en_name}}
                            v-list-item-content
                p
                    v-menu( bottom offset-y :nudge-width="600" max-width="600" :close-on-content-click="false")
                        template(v-slot:activator="{ on, attrs }")
                            v-btn.justify-start( v-bind="attrs" v-on="on" height='38' width='600' color='#808080' outlined)
                                v-icon.pr-3(size='16') mdi-bell
                                span(style='font-size:16px;') {{$t('PERSONALSETTING.INBOX_NOTIFICATION_SETTINGS')}}
                        v-card( min-height='60' outlined elevation='0') 
                            v-checkbox.ml-5.mb-5.pa-0.pt-0(v-model='allow_web_push' :label=`$t('INBOX_NOTIFICATION_SETTINGS.ALLOW_WEB_PUSH')` hide-details="auto")
                            v-checkbox.ml-5.mb-5.pa-0.pt-0(v-model='allow_follow' :label=`$t('INBOX_NOTIFICATION_SETTINGS.ALLOW_FOLLOW')` hide-details="auto")
                            v-checkbox.ml-5.mb-5.pa-0.pt-0(v-model='allow_mention' :label=`$t('INBOX_NOTIFICATION_SETTINGS.ALLOW_MENTION')` hide-details="auto")
                            v-checkbox.ml-5.mb-5.pa-0.pt-0(v-model='allow_reply' :label=`$t('INBOX_NOTIFICATION_SETTINGS.ALLOW_REPLY')` hide-details="auto")
                            v-checkbox.ml-5.mb-5.pa-0.pt-0(v-model='allow_emoji' :label=`$t('INBOX_NOTIFICATION_SETTINGS.ALLOW_EMOJI')` hide-details="auto")
                p
                    v-checkbox.ma-0.pa-0.pt-1(v-model='board_contact_sync' :label=`$t('PERSONALSETTING.BOARD_CONTACT_SYNC')`)
                //- p
                //-     label(for='personalCurrency') {{$t('PERSONALSETTING.CURRENCY')}}
                //-     //- v-row.align-center(no-gutters)
                //-     //-     v-col.pr-2(cols='12')
                //-     v-select#personalCurrency(v-model='userLanguage' :items='currencyList' :disabled='personal.currencyDisabled' :class='personal.currencyDisabled ? "no-pointer-event" : ""' solo dense readonly)
                        //- v-col.pl-2(cols='1')
                        //-     v-btn(v-if='personal.currencyDisabled' icon @click='personal.currencyDisabled = false')
                        //-         v-icon(size='20') icon-edit
                        //-     v-btn(v-else icon @click='personal.currencyDisabled = true')
                        //-         v-icon(size='20') icon-cancel
                v-row.mt-10.justify-center(no-gutters)
                    v-col(cols='12' md='6')
                        v-btn(v-hide-element='false' :ripple='false' width='100%' height='45' color='success' depressed dark @click='onSave(userData)') {{$t('GENERAL.SAVE')}}
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
                message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
                success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
                error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    // import _ from 'lodash';
    import messageDialog from '@/components/Dialog/messageDialog';    
    import PersonalSettingService from "@/services/PersonalSettingService";
    import StageDataService from '@/services/StageDataService';
    import successDialog from "@/components/Dialog/successDialog";
    import errorDialog from "@/components/Dialog/errorDialog";
    import loadingOverlay from "@/components/Common/loadingOverlay"; 
    import passData from "@/services/passData.js";
    import locationList from "@/array/the_locationList";
    import i18n from '/common/plugins/vue-i18n.js'

    export default Vue.extend({
        props: {  
            
        },
        components: {   
                loadingOverlay,             
                messageDialog,
                successDialog,
                errorDialog,
            },        
        data() {
        return {
            board_contact_sync:false,
            locationName: i18n.locale == 'zh_TW' ? "name" : (i18n.locale == 'zh_CN' ? "cn_name" : "en_name"),
            loading:false,
            userLanguage:'',
            userLocation:'',
            userImageId:null,
            userID:null,
            userImage:null,
            userPhoto:"",
            isUserPhoto:false,
            errorMessage:"",
            message:"",
            messageDialog:false,
            errorDialog:false,
            successDialog:false,
            valid:false,
            languageList: [{key:'zh_TW',name:'繁體中文'},{key:'zh_CN',name:'简体中文'},{key:'en_US',name:'English'}],
            locationList: locationList,
            currencyList: ['TWD(新台幣)','USD(美元)'],
            personal: {
            firstName: 'James',
            lastName: 'Campbell',
            mail: 'James@gmail.com',
            mailDisabled: true,
            currency: 'TWD(新台幣)',
            currencyDisabled: true,
            language: 'zh_TW',                        
            },
            userData:{},  
            formRule: {
                nameRules: [
                            (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME_3'),
                            (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                        ],
                firstNameRules: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                emailRules: [(v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL')],
                phoneRules: [(v) => /^09\d{8}$|(^$)|null/.test(v) || i18n.t('RULE.RULE_PHONE')],
                fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
            },
            newPicUrl:'',
            newName:{},
            opptyProcessList: [],
            caseProcessList: [],
            opptyProcess: null, 
            caseProcess: null, 
            allow_web_push: true,
            allow_follow: true,
            allow_mention: true,
            allow_reply: true,
            allow_emoji: true, 
            }
        },
        created(){
            this.getUserDetail();
            this.getStageList();
        },
        mounted(){
            passData.$on("getStageList", () =>{
                this.getUserDetail();
                this.getStageList();
            });
        },
        methods:{
            validateForm(){
                this.valid = this.$refs.form.validate();
                if(this.valid)
                    return true;
                else
                    return false;
            },
            getStageList(){
                this.caseProcessList = [];
                this.opptyProcessList = [];
                StageDataService.stageList("setting")
                .then(response => {
                    response.data.forEach(e => {
                        if(e.type == "CASE"){
                            this.caseProcessList.push(e);
                        }
                        else if(e.type == "DEAL"){
                            this.opptyProcessList.push(e);
                        }
                    });
                })
                .finally(()=>{
                    let noDefaultStage = {
                        id: null,
                        stage_type_name: i18n.t("GENERAL.NONE")
                    }
                    this.caseProcessList.push(noDefaultStage);
                    this.opptyProcessList.push(noDefaultStage);
                })
            },
            getUserDetail(){
                PersonalSettingService.getCurrentUserDetail()
                .then(response => 
                {
                    this.userData = response.data[0];  
                    this.userID = this.userData.usersetting.user_id;
                    this.userImageId = this.userData.usersetting.id;
                    if(response.data[0].usersetting.photo != null)
                    {
                        this.isUserPhoto = true;
                        this.userPhoto = response.data[0].usersetting.photo;
                    }
                    if(response.data[0].usersetting.default_value != null)
                    {
                        if(response.data[0].usersetting.default_value.default_process != undefined){
                            this.opptyProcess = response.data[0].usersetting.default_value.default_process.opportunity;
                            this.caseProcess = response.data[0].usersetting.default_value.default_process.case;
                        }
                    }
                    this.userLanguage = response.data[0].usersetting.language;
                    this.userLocation = response.data[0].usersetting.location ;
                    this.board_contact_sync = response.data[0].usersetting.board_contact_sync;
                    if(response.data[0].usersetting.web_push  != null)
                    {
                        if("alert" in response.data[0].usersetting.web_push)
                        {
                            this.allow_web_push = response.data[0].usersetting.web_push['alert'];
                        }
                        if("follow" in response.data[0].usersetting.web_push)
                        {
                            this.allow_follow = response.data[0].usersetting.web_push['follow'];
                        }
                        if("mention" in response.data[0].usersetting.web_push)
                        {
                            this.allow_mention = response.data[0].usersetting.web_push['mention'];
                        }
                        if("reply" in response.data[0].usersetting.web_push)
                        {
                            this.allow_reply = response.data[0].usersetting.web_push['reply'];
                        }
                        if("emoji" in response.data[0].usersetting.web_push)
                        {
                            this.allow_emoji = response.data[0].usersetting.web_push['emoji'];
                        }
                    }
                })
                .catch((response) => {
                    this.showErrorDialog(response);
                });
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
                window.location.reload()
            },
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog() {
                this.messageDialog = false; 
            }, 
            async getUserDefaultProcess(item){
                let userDefaultValue = {
                    'default_filter' : {
                        'Contact': null,
                        'Opportunity': null,
                        'Organization': null,
                        'Case': null,
                    },
                    "default_process": {
                        "case": this.caseProcess,
                        "opportunity": this.opptyProcess
                    }
                }

                if(item.usersetting.default_value == null){
                    item.usersetting.default_value = userDefaultValue;
                }
                else if(item.usersetting.default_value != null){
                    item.usersetting.default_value.default_process = userDefaultValue.default_process;
                }
            },
            async onSave(item)
            {
                if(this.validateForm() && this.userData.id == this.userID)
                {
                    
                    item.usersetting.language = this.userLanguage;
                    item.usersetting.location = this.userLocation;
                    item.usersetting.board_contact_sync = this.board_contact_sync;
                    item.usersetting.web_push = {
                            'alert':this.allow_web_push,
                            'follow':this.allow_follow,
                            'mention':this.allow_mention,
                            'reply':this.allow_reply,
                            'emoji':this.allow_emoji,
                        };
                    await this.getUserDefaultProcess(item);
                    await PersonalSettingService.updateDetail(this.userID,item)
                    .then(() => {
                        const USER = 'user';
                        let userJson = window.localStorage.getItem(USER);
                        let user = JSON.parse(userJson);
                        user.first_name = this.userData.first_name;
                        user.last_name = this.userData.last_name;
                        user.default_value = item.usersetting.default_value;
                        window.localStorage.setItem(USER, JSON.stringify(user));
                        window.localStorage.setItem('language', this.userLanguage);
                        window.localStorage.setItem('location', this.userLocation);
                            
                        let newUserName = {'first_name':this.userData.first_name,'last_name':this.userData.last_name};
                        this.menuBarName(newUserName);
                        this.showSuccessDialog();
                        this.valid = false;
                    });
                }
            },
            changephoto(){
                this.$refs.uploader.$refs.input.click()
            },
            menuBarPhoto(data){
                this.newPicUrl = data
                passData.$emit("menuBarPic", this.newPicUrl);
            },
            menuBarName(data){
                this.newName = data
                passData.$emit("menuBarName", this.newName);
            },
            async uploadimage(event){
                if (event != null) {
                    // file type
                    let n = event.type.indexOf("image");
                    if (n == -1) {
                        this.showErrorDialog(i18n.t('PERSONALSETTING.ERROR'));
                    } else {
                    // photo size
                        if (event.size > 5242880) {
                            this.showErrorDialog(i18n.t('PERSONALSETTING.ERROR_2'));
                        } else {
                            await PersonalSettingService.image(this.userImageId, this.userImage)
                            .then(response => { 
                                this.isUserPhoto = true              
                                this.userPhoto = response.data.photo;                   
                                const USER = 'user';
                                let userJson = window.localStorage.getItem(USER);
                                let user = JSON.parse(userJson);
                                user.pic = this.userPhoto                                               
                                window.localStorage.setItem(USER, JSON.stringify(user));
                                this.menuBarPhoto(this.userPhoto)      
                            });
                        }
                    }
                }       
            },
            customFilter (item, queryText){                
                const textOne = item.name.toLowerCase()
                const textTwo = item.en_name.toLowerCase()
                const searchText = queryText.toLowerCase()

                return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
            },
        }
    });
</script>
<template lang="pug">
v-card.dealDialog
    v-card-title.d-flex.justify-center(v-if='otherData.case_id != undefined') {{$t('CHANGEOWNER.TITLE')}}
    v-card-title.d-flex.justify-center(v-else-if='otherData.ka_id != undefined') {{$t('CHANGEOWNER.TITLE_2')}}
    v-card-title.d-flex.justify-center(v-else) {{$t('CHANGEOWNER.TITLE_3')}}
    v-card-text
        v-form(v-model='changeOwnValid' ref='form')
            v-autocomplete.mt-4(
                v-model='ownerObj'
                :items='ownerList' 
                item-text='fullName'
                item-value='id' 
                return-object
                rounded 
                solo 
                prepend-inner-icon='mdi mdi-account')
    v-card-actions.px-2.py-4
        v-row
            v-col.pr-2(cols='6')
                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pl-2(cols='6')
                v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.SAVE')}}
</template>

<script>
    import Vue from 'vue';
    import OrganizationDataService from "@/services/OrganizationDataService";
    import ContactDataService from "@/services/ContactDataService";
    import OpportunityDataService from  "@/services/OpportunityDataService";
    import SegmentDataService from "@/services/SegmentDataService";
    import CampaignDataService from  "@/services/CampaignDataService";
    import CaseDataService from  "@/services/CaseDataService";
    import KnowledgeArticleDataService from "@/services/KnowledgeArticleDataService";
    import OrderDataService from "@/services/OrderDataService";

    export default Vue.extend({
        props: {
            ownerData: {
                type: Object,
                required: true,
            },
            otherData: {
                type: Object,
                required: true,
            },
            change: {
                type: String,
                required: false,
            },
        },
    created(){
        this.ownerData.fullName = this.ownerData.last_name + ' ' + this.ownerData.first_name;
        if (this.change)
        {
            OrganizationDataService.getchangeowner(this.change)
            .then((respose)=>{
                this.ownerList = respose.data;
                this.ownerList.forEach(item => {
                    item.fullName = item.last_name + ' ' + item.first_name
                });
            });
        }
        else{
             OrganizationDataService.getowner()
            .then((respose)=>{
                this.ownerList = respose.data;
                this.ownerList.forEach(item => {
                    item.fullName = item.last_name + ' ' + item.first_name
                });
            });
        }
       
    },
    data() {
        return {
            changeOwnValid: true,
            ownerList: [],
            ownerObj: this.ownerData,
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        onConfirm() {
            if (this.ownerObj != this.ownerData){
                delete this.ownerObj.fullName;
                this.ownerData.owner = this.ownerObj
            }
            delete this.ownerData.fullName;
            if (this.otherData.contact_id != undefined){
                let ownerChangeObj = {};
                ownerChangeObj.id = this.otherData.contact_id;
                ownerChangeObj.last_name = this.otherData.last_name;
                ownerChangeObj.owner = this.ownerObj;
                ContactDataService.edit(ownerChangeObj)
                .then(response => {
                    this.putNewOwnerData(response.data);
                });
            }
            else if (this.otherData.org_id != undefined){
                let ownerChangeObj = {};
                ownerChangeObj.id = this.otherData.org_id;
                ownerChangeObj.name = this.otherData.name;
                ownerChangeObj.owner = this.ownerObj;
                OrganizationDataService.edit(ownerChangeObj)
                .then(response => {
                    this.putNewOwnerData(response.data);
                });
            }
            else if (this.otherData.segment_id != undefined){
                let ownerChangeObj = {};
                ownerChangeObj.id = this.otherData.segment_id;
                ownerChangeObj.name = this.otherData.name;
                ownerChangeObj.owner_id = this.ownerObj.id;
                
                SegmentDataService.editSegment(ownerChangeObj)
                .then(response => {                    
                    this.putNewOwnerData(response.data);
                });
            }
            else if (this.otherData.oppty_id != undefined) {
                let ownerChangeObj = {};
                ownerChangeObj.id = this.otherData.oppty_id;
                ownerChangeObj.name = this.otherData.name;
                ownerChangeObj.owner = this.ownerObj;
                OpportunityDataService.edit(ownerChangeObj)
                .then(response => {
                    this.putNewOwnerData(response.data);
                });
            }
            else if (this.otherData.campaign_id != undefined) {
                let ownerChangeObj = {};
                ownerChangeObj.id = this.otherData.campaign_id;
                ownerChangeObj.name = this.otherData.name;
                ownerChangeObj.channel = this.otherData.channel;
                ownerChangeObj.segment = this.otherData.segment;
                ownerChangeObj.owner = this.ownerObj;
                delete ownerChangeObj.owner.usersetting;
                CampaignDataService.edit(ownerChangeObj)
                .then(response => {
                    this.putNewOwnerData(response.data);
                });
            }
            else if (this.otherData.case_id != undefined) {
                let ownerChangeObj = {};
                ownerChangeObj.id = this.otherData.case_id;
                ownerChangeObj.subject = this.otherData.subject;
                ownerChangeObj.owner = this.ownerObj;
                delete ownerChangeObj.owner.usersetting;
                CaseDataService.editdetail(ownerChangeObj)
                .then(response => {
                    this.putNewOwnerData(response.data);
                });
            }
            else if (this.otherData.ka_id != undefined) {
                let ownerChangeObj = {};
                ownerChangeObj.id = this.otherData.ka_id;
                ownerChangeObj.title = this.otherData.title;
                ownerChangeObj.owner = this.ownerObj;
                delete ownerChangeObj.owner.usersetting;
                KnowledgeArticleDataService.editauthor(ownerChangeObj)
                .then(response => {
                    this.putNewOwnerData(response.data);
                });
            }
            else if (this.otherData.order_id != undefined) {
                let ownerChangeObj = {};
                ownerChangeObj.id = this.otherData.order_id;
                ownerChangeObj.name = this.otherData.name;
                ownerChangeObj.owner = this.ownerObj;
                delete ownerChangeObj.owner.usersetting;
                OrderDataService.editowner(ownerChangeObj)
                .then(response => {
                    this.putNewOwnerData(response.data);
                });
            }
            this.$emit('emitChangeOwnDialog', true);
        },
        putNewOwnerData(data) {
            this.ownerData.email = data.owner.email;
            this.ownerData.first_name = data.owner.first_name;
            this.ownerData.last_name = data.owner.last_name;
            this.ownerData.id = data.owner.id;
            this.ownerData.usersetting.id = data.owner.usersetting.id;
            this.ownerData.usersetting.photo = data.owner.usersetting.photo;
        },
        onCancel() {
            this.$emit('emitChangeOwnDialog', false);
        },
    }
    });
</script>
<template lang="pug">
//- 匯入CSV
v-card(:loading="importing || timeOutImport")
  //- v-overlay(v-if='importing' :z-index='99' opacity='0')    
  v-card-title.d-flex.justify-center {{$t('IMPORT_EXPORT.TITLE')}}
  v-card-text
    v-form(v-model="valid", ref="form")
      v-textarea.mt-4.textarea-style(
        id='textarea_id',
        v-model="description",
        :label='description_label',
        solo
        no-resize
        readonly
        height='400'
        background-color="amber lighten-4"
      ) 
      v-btn(
        v-if='after_import'
        :ripple="false",
        @click="onCopyMsg",
        width="100%",
        height="40",
        color="primary",
        depressed,
        dark
      ) {{$t('IMPORT_EXPORT.COPY')}}
      v-snackbar(v-model="snackbar" :timeout="1000" 
        absolute
        centered
        color="primary") {{$t("IMPORT_EXPORT.COPY_2") }}
        
      v-select.no-border(
        :label=`$t('IMPORT_EXPORT.PAGE')`,
        v-model="page",
        prepend-icon="mdi-chart-pie",
        :items="pageList",   
        item-text="name",
        item-value="key",
        flat,
        :rules='formRule.fileRules',
        :disabled='importing || timeOutImport',
      )
      v-file-input.no-border(
        v-model="import_csv",
        prepend-icon="fa-solid fa-file-csv",
        :label=`$t('IMPORT_EXPORT.SELECT')`,
        accept="text/csv",
        @change='importCsv',
        :rules='formRule.fileRule',
        :disabled='importing || timeOutImport',
      )
      v-select.no-border(
        v-if='page=="Case"|page=="Opportunity"'
        :label=`$t('IMPORT_EXPORT.STAGE')`,
        v-model="stage",
        prepend-icon="mdi-chart-pie",
        :items="stageTypeList",
        item-text="stage_type_name",
        item-value="id",
        flat,
        :rules='formRule.fileRules', 
        :disabled='importing || timeOutImport',
      )
    v-row(no-gutters).mt-2
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="primary",importing
          :disabled='importing || timeOutImport',
        ) {{importing||timeOutImport ? $t('IMPORT_EXPORT.ING') : $t('IMPORT_EXPORT.IMPORT')}}
          v-icon(size='16' color='black' :disabled='true' v-if='importing') fas fa-circle-notch fa-spin
  v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import ResourceDataService from "@/services/ResourceDataService";
import UploadStatusDataService  from "@/services/UploadStatusDataService";
import pageList from "@/array/the_pageList";
import messageDialog from '@/components/Dialog/redirectDialog';
import i18n from '/common/plugins/vue-i18n.js' 
import StageDataService from "@/services/StageDataService"
import errorDialog from "@/components/Dialog/errorDialog";



export default Vue.extend({
  props: {    
    page_name: {
      type: String,
      required: true,
    },
    stage_type: {
    //   type: Number,
      required: false,
    },
  },
  components: {
    errorDialog,
    messageDialog
  },
  data() {
    return {
      track_id:null,
      interval:null,
      timeOutImport:false,
      errorMessage:'',
      errorDialog:false,
      after_import:false,
      snackbar: false,
      description_label:'',
      importing:false,
      fileType:false,
      fileSize:false,
      import_csv:null,
      description:'',
      dataType:'temp',
      page:null,
      stage:'',
      stageCaseList:[],
      stageOpptyList:[],
      stageTypeList:[],
      pageList: pageList,
      // typeList:[
      // { key:'temp', name: i18n.t('IMPORT_EXPORT.TEMP')},
      // { key:'db', name: i18n.t('IMPORT_EXPORT.DB_DATA')}
      // ],
      messageDialog:false,
      message:"",
      valid: true,
      isLoading: false,
      formRule: {
        fileRule: [
          () => { return (!this.fileSize || i18n.t('RULE.RULE_FILE_3'))},
          () => { return (!this.fileType || i18n.t('RULE.RULE_CSV'))},
        ],
        fieldRules: [
            (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
      },
    };
  },
  destroyed (){
    this.stopInterval();
  },
  async created(){
    this.pageList =this.pageList.filter(el=>el.key!="Activity");
    let isAdmin = false;
    let userJson = window.localStorage.getItem('user');
    let currentUser = JSON.parse(userJson);
    currentUser.is_staff ? isAdmin = true : isAdmin = false;
    if(this.isWiadvance() && !isAdmin){
        this.pageList = [
            { key:'Opportunity',name: i18n.t('DEAL.TITLE')},
        ]
    }
    this.page = this.page_name;
    this.stage = this.stage_type;

    await StageDataService.stageList('case')
            .then(response => {
                this.stageCaseList = response.data;
                if(this.stageCaseList.length == 0)
                {
                    let item = {};
                    item.stage_type_name = i18n.t('CASE.NO_CASE_STAGE');
                    this.stageTypeList.push(item);
                }
                else{
                    if(this.page_name == 'Case')
                        this.stageTypeList = this.stageCaseList;
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            })

    await StageDataService.stageList("deal")
            .then(response => {
                if (response.data.length != 0) {	
                    response.data.forEach(item => {
                        item.stagedef_set = item.stagedef_set.sort(function(a, b){ 
                            return a.sequence_number-b.sequence_number
                        });
                        this.stageOpptyList.push(item);
                    });
                    if(this.page_name == 'Opportunity')
                        this.stageTypeList = this.stageOpptyList;
                } 
                else {
                    let item = {}
                    item.stage_type_name = i18n.t('DEAL.NO_DEAL_STAGE');
                    this.stageOpptyList.push(item);
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            });
    if(this.stageOpptyList.length==0)
    {
      this.pageList = this.pageList.filter(e=>e.key == "Opportunity")
    }
    if(this.stageCaseList.length==0)
    {
      this.pageList = this.pageList.filter(e=>e.key == "Case")
    }
    
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) 
        return true;
      else 
        return false;
    },
    async onConfirm() {
      if (this.validateForm()&& !this.fileType && !this.fileSize && this.import_csv) {
        this.importing = true;
        this.description = "";
        let upload_status_data={
          method : "Import",
          type : this.page,
          total : 0,
          file_name : this.import_csv.name,
          success : 0,
          fail : 0,
          track_id : new Date().getTime()
        };
        await UploadStatusDataService.create(upload_status_data)
        .then((response)=>{
          this.track_id = response.data.id;
        });
        await this.start_import();
      }
    },
    async start_import(){
      ResourceDataService.import(this.import_csv,this.page,this.stage,this.track_id)
            .then(response => {
                this.description = response.data;
                this.after_import = true;
                this.stopInterval();
            })
            .then(()=>{
                const textarea = document.getElementById('textarea_id');
                textarea.scrollTop = textarea.scrollHeight;
            })
            .catch((response) => {
                if(String(response).indexOf('timeout') >= 0){
                    this.timeOutImport=true;
                }
                else{
                    this.showErrorDialog(i18n.t('IMPORT_EXPORT.ERROR_2'));
                    this.stopInterval();
                }
            })
        this.interval = await setInterval(() => {this.checkImport();}, 1000);
    },
    async checkImport(){
      if(this.importing == true || this.timeOutImport){
        const textarea = document.getElementById('textarea_id');
        await ResourceDataService.getnum(this.track_id)
        .then(response => {
            this.description = response.data.msg + "\n"+ i18n.t('IMPORT_EXPORT.PROGRESS') + response.data.num + "/" + response.data.total
            if(response.data.num == response.data.total){
              this.description = response.data.msg;
              this.stopInterval();
            }
        })
        textarea.scrollTop = textarea.scrollHeight
      } 
    },
    stopInterval(){
      this.timeOutImport = false;
      this.importing = false;
      this.import_csv = null;
      clearInterval(this.interval);
      this.interval = null;
      this.track_id = null;
    },
    importCsv(event) {
      if (event != null) {
        // file type
        let n = event.type.indexOf("csv");
        if (n == -1) {
          this.fileType = true;
        } else {
          this.fileType = false;
        }
        // for firefox
        if(event.type == 'application/vnd.ms-excel'){
          this.fileType = false;
        }

        // file size
        if (event.size > 20971520) {
          this.fileSize = true;
        } else {
          this.fileSize = false;
        }
      } else {
        this.fileSize = false;
        this.fileType = false;
      }
    },
    onCancel() {     
      if(this.importing || this.timeOutImport){
        this.showMessageDialog(i18n.t('IMPORT_EXPORT.STILL_RUN'));
      }else{
        this.$emit("emitImport");
      }
    },
    showMessageDialog(message) {
        this.messageDialog = true;
        this.message = message;
    },
    onCopyMsg(event){      
        const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
        clipboardData.writeText(this.description);
        this.snackbar = true
    },
    showErrorDialog(message) {
        this.errorDialog = true;
        this.errorMessage = message;
    },
    onEmitErrorDialog() {
        this.errorDialog = false;
    },
    onEmitMessageDialog(val){
        if(this.importing || this.timeOutImport){
          if(val){
            this.$router.replace('/Setting#UploadStatus');
          }else{
            this.$emit("emitImport");
            return;
          }
        }
        this.messageDialog = false;
        location.reload();
    }
  },
  watch:{
    "page":function () {      
        if(this.page == "Contact")
        {
            this.stage = null;
            this.description = i18n.t('IMPORT_EXPORT.CONTACT_LABEL')
        }
        if(this.page == "Organization")
        {
            this.stage = null;
            this.description = i18n.t('IMPORT_EXPORT.ORG_LABEL')
        }
        if(this.page == "KnowledgeArticle")
        {
            this.stage = null;
            this.description = i18n.t('IMPORT_EXPORT.KA_LABEL')
        }
        if(this.page == "Case")
        {
            this.stageTypeList = this.stageCaseList;
            this.description = i18n.t('IMPORT_EXPORT.CASE_LABEL')
            if(this.stage_type && this.page_name == this.page)
            {
                this.stage = this.stage_type;
            }else{
                this.stage = this.stageCaseList[0]['id'];
            }
        }
        if(this.page == "Opportunity")
        {
            this.stageTypeList = this.stageOpptyList;
            this.description = i18n.t('IMPORT_EXPORT.OPPTY_LABEL')
            if(this.stage_type && this.page_name == this.page)
            {
                this.stage = this.stage_type;
            }
            else{
                this.stage = this.stageOpptyList[0]['id'];
            }
        }
        if(this.page == "Product")
        {
            this.stage = null;
            this.description = i18n.t('IMPORT_EXPORT.PRODUCT_LABEL')
        }
        if(this.page == "Order")
        {
            this.stage = null;
            this.description = i18n.t('IMPORT_EXPORT.ORDER_LABEL')
        }
        if(this.page == "Order_License")
        {
            this.stage = null;
            this.description = i18n.t('IMPORT_EXPORT.LICENSE_LABEL')
        }
        if(this.page == "Order_Service")
        {
            this.stage = null;
            this.description = i18n.t('IMPORT_EXPORT.SERVICE_LABEL')
        }
    },
  }
});
</script>
<template lang="pug">
    v-card#KnowledgeTab.deal-tab-card.rounded-card.d-flex.justify-center(v-else style='width:95%;' flat )
        loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999' attach)
        //--AI助理知識管理--
        #page-inner.pt-0.d-flex.justify-center.knowledge_tab
            v-tabs(v-model='tab' background-color='transparent'  id='gptupload')
                v-tab(v-for='item in tabs' :key='item.key' :href='`#tab-${item.id}`'  style='display:flex; justify-content:center !important;')
                    span.spna {{ item.name }}
                //--已上傳檔案--
                //- v-row.mb-5.justify-end(v-if='tab==="tab-1"' no-gutters)
                //-     v-btn.btn_green(v-if='tab==="tab-1"' :ripple='false' width='150px' height='50'  dark depressed @click='uploagDialog=true') 
                //-         v-icon.mr-1(size="14") icon-add
                //-         span {{$t('GPT_KNOWLEDGE.UPLOAD')}} 
                v-tab-item(key='Uploaded' value='tab-1' :transition='false' :reverse-transition='false' style='min-height:0px;')                     
                    v-divider.divider.ma-0
                    v-row.pl-9.setting-title.align-center.py-3.px-4(no-gutters)
                        v-col(cols="11", md="11")
                            h6.d-flex.align-center.mb-0
                                .cube-icon.small.mr-4.openaicubeicon
                                    v-icon(class='openailogo')
                                span {{$t('GPT_KNOWLEDGE.TIP_1')}}
                    .pl-5.pr-5
                        v-data-table.pl-16.pr-16.mt-0.dashboard-table(
                            :item-class="rowClass"
                            :headers="FileTableheaders",
                            :items="filename_arr",
                            :items-per-page="FilesPerPage",
                            :page.sync="FileTablePage",
                            @page-count="FilePageCount = $event",
                            hide-default-footer="hide-default-footer",
                        )
                            template( v-slot:item.name="{ item }" )
                                .ellipsis(:title='item.name' height='36px')
                                div(class='d-flex justify-left' :title='item.name')
                                    v-icon(style='margin-left:0px;') mdi-file-outline
                                    span(style='margin-left:20px;') {{item.name}}
                            template( v-slot:item.image_status="{ item }" )
                                div(v-if='item.is_image_file' class='d-flex justify-center')
                                    v-checkbox.ml-3.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.DESCRIBE_IMG')` v-model='item.image_status.imgDescribeImg' disabled)
                                    v-checkbox.ml-3.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.IMG_SEARCH')` v-model='item.image_status.imgSearchImg' disabled)
                                    v-checkbox.ml-3.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.CUSTOMER_ATTRIBUTE')` v-model='item.image_status.imgCustomerAttr' disabled)
                            template( v-slot:item.actions="{ item }" )
                                v-btn(v-if='item.has_file' icon height="36"  @click="downloadtxt(item.name)" :disabled='txtDownloading' )
                                    v-icon mdi-download
                                v-btn(icon @click="singleDelete(item)")
                                    v-icon(size='18') icon-remove
                        v-pagination.mt-2(v-model='FileTablePage' :length='FilePageCount' circle :total-visible="10")
                //--網站分析--
                v-row.mb-5.justify-end(v-if='tab==="tab-7"' no-gutters)
                    v-btn(v-if='tab==="tab-7"' :ripple='false' width='150px' height='50' color='#2ace87' dark depressed @click='showAddWebsite') 
                        v-icon.mr-1(size="14") icon-add
                        span {{$t('UPGPT.ADD_URL')}} 
                v-tab-item(key='Website' value='tab-7' :transition='false' :reverse-transition='false' style='min-height:0px;')
                    v-divider.divider.ma-0
                    v-row.pl-9.setting-title.align-center.py-3.px-4(no-gutters)
                        v-col(cols="11", md="11")
                            h6.d-flex.align-center.mb-0
                                .cube-icon.small.mr-4.openaicubeicon
                                    v-icon(class='openailogo')
                                span {{$t('GPT_KNOWLEDGE.TIP_5')}}
                    .pl-5.pr-5
                        v-data-table.pl-16.pr-16.mt-0.dashboard-table(
                            :item-class="rowClass"
                            :headers="Websiteheaders",
                            :items="Object.values(WebsitesSettingData)",
                            :items-per-page="WebsitesPerPage",
                            :page.sync="WebsiteTablePage",
                            @page-count="WebsitePageCount = $event",
                            hide-default-footer="hide-default-footer",
                        )
                            template( v-slot:item.url="{ item }")
                                .ellipsis(:title='item.url' height='36px')
                                div(class='d-flex justify-left' :title='item.url')
                                    v-icon mdi-web
                                    span(style='margin-left:20px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;') {{item.url}} 
                            template( v-slot:item.level="{ item }")
                                span {{item.level}}   
                            template(v-slot:item.actions="{ item }")
                                v-btn(icon, @click="showEditWebsite(item)" )
                                    v-icon(size="18" ) icon-edit          
                                v-btn(icon, @click="onDelete(item.id)" )
                                    v-icon(size="18") icon-remove
                        v-row.mt-5.justify-end(no-gutters).pr-16
                            v-col(cols='12' md='2')
                                v-btn(:ripple='false' width='100%' height='45' color='#2ace87' dark :disabled='Webuploading || isAnalysising'  depressed  @click='web_analysis')
                                    v-icon(size='16' color='black' :disabled='true' v-if='Webuploading || isAnalysising') fas fa-circle-notch fa-spin
                                    v-icon(size='22').pt-1 mdi-atom
                                    span {{Webuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.START_ANALYSIS')}}
                            //- v-col.pl-3(cols='12' md='2' v-if="hasWebFile")
                            //-     v-btn(v-if="hasWebFile" :ripple='false' width='100%' height='45' color='primary' :disabled='zipDownloading' :loading='zipDownloading' depressed :dark='!zipDownloading' @click='downloadwebzip')
                            //-         v-icon(size='22').pt-1 mdi-arrow-down-bold
                            //-         span {{$t('UPGPT.DOWNLOAD_ZIP')}}
                //--Q&A資料上傳--
                v-tab-item(key='QA' value='tab-2' :transition='false' :reverse-transition='false' style='min-height:0px;')                     
                    v-divider.divider.ma-0
                    v-row.pl-9.setting-title.align-center.py-3.px-4(no-gutters)
                        v-col(cols="11", md="11")
                            h6.d-flex.align-center.mb-0
                                .cube-icon.small.mr-4.openaicubeicon
                                    v-icon(class='openailogo')
                                span {{$t('GPT_KNOWLEDGE.TIP_2')}}
                    .pl-5
                        v-form.pl-16.pr-5.pt-2(v-model="valid", ref="form")
                            p               
                                v-row(no-gutters)
                                    v-col(cols='4' id="QAinput")
                                        v-file-input.pa-0.pr-4( prepend-icon="fa-solid fa-file-csv" accept=".csv" :placeholder= '$t("UPGPT.QAFILEUPLOADMESSAGE")' truncate-length="20" :rules="formRule.QAfileRule" v-model='QAAttachments' @change='onChangeQAFile')
                                v-row(no-gutters).pt-3.pb-5
                                    v-col(cols='4')
                                        v-btn.btn_green(absolute right :ripple='false' width='25%' height='36' :dark='!QAuploading || !isAnalysising' :disabled='QAuploading || isAnalysising' depressed @click='onUpload("QA")') {{QAuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.QAUPLOAD')}}
                                            v-icon(size='16' color='black' :disabled='true' v-if='QAuploading || isAnalysising') fas fa-circle-notch fa-spin

                //--資料夾上傳--
                v-tab-item(key='Folder' value='tab-3' :transition='false' :reverse-transition='false' style='min-height:0px;')                   
                    v-divider.divider.ma-0
                    v-row.pl-9.setting-title.align-center.py-3.px-4(no-gutters)
                        v-col(cols="11", md="11")
                            h6.d-flex.align-center.mb-0
                                .cube-icon.small.mr-4.openaicubeicon
                                    v-icon(class='openailogo')
                                span {{$t('GPT_KNOWLEDGE.TIP_3')}}
                    .pl-5
                        v-form.pl-16.pr-5.pt-2(v-model="valid", ref="form")
                            p   
                                v-row(v-hide-element no-gutters)
                                    label.mr-2(class='d-flex align-center')
                                        p.mb-2(class='font-weight-bold') {{$t('GPT_KNOWLEDGE.IMAGE_SELECTION')}} :
                                    v-tooltip(top)
                                        template(v-slot:activator='{ on, attrs }')
                                            v-col.pr-3(cols='auto' v-bind='attrs' v-on='on')
                                                v-checkbox.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.DESCRIBE_IMG')` :disabled='!can_image_analysis' v-model='folderDescribeImg' @change="handleCheckboxChange('folder')")
                                        span.mt-1 {{$t('GPT_UPLOADIMG.TIP_1')}}
                                    v-tooltip(top)
                                        template(v-slot:activator='{ on, attrs }') 
                                            v-col.pr-3(cols='auto' v-bind='attrs' v-on='on')
                                                v-checkbox.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.IMG_SEARCH')` :disabled='!can_image_search' v-model='folderSearchImg' @change="handleCheckboxChange('folder')")
                                        span.mt-1 {{$t('GPT_UPLOADIMG.TIP_3')}}
                                    v-tooltip(top)
                                        template(v-slot:activator='{ on, attrs }') 
                                            v-col.pr-3(cols='auto' v-bind='attrs' v-on='on')
                                                v-row(no-gutters)
                                                    v-col.mr-2(cols='auto')
                                                        v-checkbox.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.CUSTOMER_ATTRIBUTE')` :disabled='!can_image_analysis' v-model='folderCustomerAttr' @change="handleCheckboxChange('folder')")
                                                    v-col(cols='6' class='d-flex'  v-if='can_image_analysis')
                                                        v-select.mt-0.pt-0(v-if='folderCustomerAttr==true' v-model='currentAttributeId' :items='customerAttributeList' item-value='id' item-text='name' :title='getItemNameFromListById(customerAttributeList,currentAttributeId)' style='max-width:150px;')
                                                        v-btn.pl-1(class='justify-start' v-if='folderCustomerAttr==true && customerAttributeList.length > 0' size="25" icon @click='showImageDataTable()')
                                                            v-icon mdi-grid 
                                        span.mt-1 {{$t('GPT_UPLOADIMG.TIP_2')}}
                                v-row.py-4(no-gutters)
                                    v-col(cols='4' id="Folderinput")
                                        v-file-input.pa-0.pr-4( prepend-icon="mdi-folder-zip" accept=".zip" :placeholder= '$t("UPGPT.FOLDERUPLOADMESSAGE")' truncate-length="20" :rules="formRule.FolderfileRule" v-model='FolderAttachments' @change='onChangeFolderFile')
                                v-row(no-gutters).pt-3.pb-5
                                    v-col(cols='4')
                                        v-btn.btn_green(absolute right :ripple='false' width='25%' height='36' :dark='!QAuploading || !isAnalysising' :disabled='Folderuploading || isAnalysising' depressed @click='onUpload("Folder")') {{Folderuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.FOLDERUPLOAD')}}
                                            v-icon(size='16' color='black' :disabled='true' v-if='Folderuploading || isAnalysising') fas fa-circle-notch fa-spin
                //--檔案上傳--
                v-tab-item(key='File' value='tab-4' :transition='false' :reverse-transition='false' style='min-height:0px;')                   
                    v-divider.divider.ma-0
                    v-row.pl-9.setting-title.align-center.py-3.px-4(no-gutters)
                        v-col(cols="11", md="11")
                            h6.d-flex.align-center.mb-0
                                .cube-icon.small.mr-4.openaicubeicon
                                    v-icon(class='openailogo')
                                span {{$t('GPT_KNOWLEDGE.TIP_4')}}
                    .pl-5
                        v-form.pl-16.pr-5.pt-2(v-model="valid", ref="form")
                            p               
                                v-row(no-gutters)
                                    v-col(cols='4' id="Fileinput")
                                        v-file-input.pa-0.pr-4( prepend-icon="mdi-file-document-plus" accept=".txt,.pdf,.docx,.pptx" :placeholder= '$t("UPGPT.FILEUPLOADMESSAGE")' truncate-length="20" :rules="formRule.FilefileRule" v-model='FileAttachments' @change='onChangeFile')
                                v-row(no-gutters).pt-3.pb-5
                                    v-col(cols='4')
                                        v-btn.btn_green(absolute right :ripple='false' width='25%' height='36' :dark='!QAuploading || !isAnalysising' :disabled='Fileuploading || isAnalysising' depressed @click='onUpload("File")') {{Fileuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.FILEUPLOAD')}}
                                            v-icon(size='16' color='black' :disabled='true' v-if='Fileuploading || isAnalysising') fas fa-circle-notch fa-spin
                //- 圖片上傳
                v-tab-item(key='UploadedImage' value='tab-5' :transition='false' :reverse-transition='false' style='min-height:0px;')
                    v-divider.divider.ma-0
                    v-row.pl-9.setting-title.align-center.py-3.px-4(no-gutters)
                        v-col(cols="11", md="11")
                            h6.d-flex.align-center.mb-0
                                .cube-icon.small.mr-4.openaicubeicon
                                    v-icon(class='openailogo')
                                span {{$t('GPT_KNOWLEDGE.TIP_6')}}
                    .pl-5
                        v-form.pl-16.pr-5.pt-2(v-model="valid", ref="form")
                            v-row(v-hide-element no-gutters)
                                v-tooltip(top)
                                    template(v-slot:activator='{ on, attrs }')
                                        v-col.pr-3(cols='2' v-bind='attrs' v-on='on')
                                            v-checkbox.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.DESCRIBE_IMG')` :disabled='!can_image_analysis' v-model='imgDescribeImg' @change="handleCheckboxChange('image')")
                                    span.mt-1 {{$t('GPT_UPLOADIMG.TIP_1')}}
                                v-tooltip(top)
                                    template(v-slot:activator='{ on, attrs }') 
                                        v-col.pr-3(cols='2' v-bind='attrs' v-on='on')
                                            v-checkbox.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.IMG_SEARCH')` :disabled='!can_image_search' v-model='imgSearchImg' @change="handleCheckboxChange('image')")
                                    span.mt-1 {{$t('GPT_UPLOADIMG.TIP_3')}}
                                v-tooltip(top)
                                    template(v-slot:activator='{ on, attrs }') 
                                        v-col.pr-3(cols='auto' v-bind='attrs' v-on='on')
                                            v-row(no-gutters)
                                                v-col.mr-2(cols='auto')
                                                    v-checkbox.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.CUSTOMER_ATTRIBUTE')` :disabled='!can_image_analysis' v-model='imgCustomerAttr' @change="handleCheckboxChange('image')")
                                                v-col(cols='6' class='d-flex' v-if='can_image_analysis')
                                                    v-select.mt-0.pt-0(v-if='imgCustomerAttr' v-model='currentAttributeId' :items='customerAttributeList' item-value='id' item-text='name' :title='getItemNameFromListById(customerAttributeList,currentAttributeId)' style='max-width:150px;')
                                                    v-btn.pl-1(class='justify-start' v-if="imgCustomerAttr && currentAttributeId != null " size="25" icon @click='showImageDataTable()')
                                                        v-icon mdi-grid 
                                    span.mt-1 {{$t('GPT_UPLOADIMG.TIP_2')}}
                            p
                                v-row.py-4(no-gutters)
                                    v-col(cols='4' id="Fileinput")
                                        v-file-input.pa-0.pr-4(:disabled='!can_image_process' prepend-icon="mdi-image-multiple" accept=".jpg,.jpeg,.png" :placeholder= '$t("GPT_UPLOADIMG.FILEUPLOADMESSAGE")' truncate-length="20" :rules="formRule.ImagefileRule" v-model='ImageAttachments' @change='onChangeImageFile')
                                v-row(no-gutters).pt-3.pb-5
                                    v-col(cols='4')
                                        v-btn.btn_green(absolute right :ripple='false' width='25%' height='36' :dark='!Imageuploading || !isAnalysising' :disabled='Imageuploading || isAnalysising' depressed @click='onUpload("Image")') {{Imageuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('GPT_UPLOADIMG.START_ANALYSIS')}}
                                                v-icon(size='16' color='black' :disabled='true' v-if='Imageuploading || isAnalysising') fas fa-circle-notch fa-spin
                            v-row(v-if='!can_image_process' no-gutters).pt-5
                                span.mt-1 {{$t('GPT_UPLOADIMG.IMAGE_TIP')}}
                //- 雲端檔案
                v-tab-item(v-if='tab == "tab-6"' key='Cloud' value='tab-6' :transition='false' :reverse-transition='false' style='min-height:0px;')
                    v-divider.divider.ma-0
                    //- v-row.pl-9.setting-title.align-center.py-3.px-4(no-gutters)
                    //-     v-col(cols="11", md="11")
                    //-         h6.d-flex.align-center.mb-0
                    //-             .cube-icon.small.mr-4.openaicubeicon
                    //-                 v-icon(class='openailogo')
                    //-             span {{$t('GPT_KNOWLEDGE.TIP_6')}}
                    v-row.cloud-tab-divider-line(no-gutters class='justify-start').mb-2
                        v-col(class='d-flex' cols='5').mt-2.mb-3
                            v-card-text.ml-6( v-if='o365AccountData.length == 0' class='d-flex align-center')
                                label.t-black.ellipsis.pt-1(class='font-weight-bold'  :title='$t("GPT_CLOUD.CONNECT_CLOUD")') {{$t('GPT_CLOUD.CONNECT_CLOUD')}} :
                                v-select.pl-2.pb-2.ellipsis(flat dense v-model='cloudType' :items='typeList'  item-value="key" item-text="name"  :menu-props='{closeOnClick:true}' style='max-width:150px;width:auto;')
                                v-btn(:disabled='!can_cloud_drive' icon @click='showConnectCloudStorageDialog' height='28' color='#18bedf' depressed :dark='can_cloud_drive')
                                    v-icon.mr-1(size='16') icon-add
                            v-card-text.ml-6(v-else class='d-flex align-center')
                                label.t-black.ellipsis.pt-1(class='font-weight-bold' :title='$t("GPT_CLOUD.CONNECT_ACCOUNT")') {{$t('GPT_CLOUD.CONNECT_ACCOUNT')}} :
                                span().mt-1.pl-1  {{ o365AccountData[0].display_name }} ({{ o365AccountData[0].o365_user_principal_name }})
                                span.mt-1.div
                                    v-btn(icon='icon' @click='onDeleteO365Account()')
                                        v-icon(size='18') icon-remove
                        v-col(no-gutters cols='7' class='d-flex')
                            v-row(v-hide-element).mt-2
                                label(class='d-flex align-center')
                                    p.mb-0(class='font-weight-bold') {{$t('GPT_KNOWLEDGE.IMAGE_SELECTION')}} :
                                v-tooltip(top)
                                    template(v-slot:activator='{ on, attrs }')
                                        v-col.pr-3(cols='auto' v-bind='attrs' v-on='on')
                                            v-checkbox.mt-3.no-border(no-resize :label=`$t('GPT_UPLOADIMG.DESCRIBE_IMG')` :disabled='!can_image_analysis' v-model='cloudDescribeImg' @change="handleCheckboxChange('cloud')")
                                    span.mt-1 {{$t('GPT_UPLOADIMG.TIP_1')}}
                                v-tooltip(top)
                                    template(v-slot:activator='{ on, attrs }') 
                                        v-col.pr-3(cols='auto' v-bind='attrs' v-on='on')
                                            v-checkbox.mt-3.no-border(no-resize :label=`$t('GPT_UPLOADIMG.IMG_SEARCH')` :disabled='!can_image_search' v-model='cloudSearchImg' @change="handleCheckboxChange('cloud')")
                                    span.mt-1 {{$t('GPT_UPLOADIMG.TIP_3')}}
                                v-tooltip(top)
                                    template(v-slot:activator='{ on, attrs }') 
                                        v-col.pr-3( v-bind='attrs' v-on='on' cols='auto')
                                            v-row(no-gutters)
                                                v-col.mr-2(cols='auto')
                                                    v-checkbox.mt-3.no-border(no-resize :label=`$t('GPT_UPLOADIMG.CUSTOMER_ATTRIBUTE')` :disabled='!can_image_analysis' v-model='cloudCustomerAttr' @change="handleCheckboxChange('cloud')")
                                                v-col(cols='6' class='d-flex' v-if='can_image_analysis')
                                                    v-select.mt-3.pt-0(v-if='cloudCustomerAttr' v-model='currentAttributeId' :items='customerAttributeList' item-value='id' item-text='name' :title='getItemNameFromListById(customerAttributeList,currentAttributeId)' style='max-width:150px;' )
                                                    v-btn.mt-3.pl-1(class='justify-start' v-if='cloudCustomerAttr && currentAttributeId != null' size="25" icon @click='showImageDataTable()')
                                                        v-icon mdi-grid 
                                    span.mt-1 {{$t('GPT_UPLOADIMG.TIP_2')}}

                        v-row
                            v-tabs#tab-bg.main-tabs(v-model='cloudTab' background-color='transparent' show-arrows)
                                v-tabs-slider
                                v-tab(v-for='item in cloudTabs' :key='item.key' :href='`#cloudTab-${item.id}`')
                                    span.spna {{ item.name }}
                    v-row.sheet-box.my-5(v-if='o365AccountData.length == 0')
                        v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                h5.ma-0.t-black {{can_cloud_drive? $t('EMAILSYNC.NO_CONNECT'): $t('GPT_UPLOADIMG.CLOUD_TIP')}}
                    v-row(no-gutters='no-gutters' v-if='o365AccountData.length != 0')
                        v-col(cols='6' md='6' )
                            v-container.select-file-container(v-if='cloudTab==="cloudTab-1"')
                                v-row(v-if='files_sync_loading' justify="center" align="center" class="fill-height")
                                    v-progress-circular(indeterminate size='40' color='primary' class="m-auto")
                                treeselect.mt-1.pl-7(v-model='selectedItems'
                                    v-if='!cloud_files_loading && !files_sync_loading'
                                    alwaysOpen,
                                    :options="myDrivetreeItems",
                                    :multiple="true"
                                    :disable-branch-nodes="false"
                                    :always-open="true"
                                    placeholder=''
                                    noResultsText='No data available'
                                    valueFormat='object',
                                    :loadOptions='getFolderFiles',
                                    :matchKeys='match_keys'
                                    :disableFuzzyMatching='true'
                                    )
                                    div(slot="option-label" slot-scope="{ node }" :title='node.raw.name') {{ node.raw.name }}
                                    div(slot="value-label" slot-scope="{ node }") {{ node.raw.name }}
                            v-container.select-file-container(v-if='cloudTab==="cloudTab-2"')
                                v-row(v-if='files_sync_loading' justify="center" align="center" class="fill-height")
                                    v-progress-circular(indeterminate size='40' color='primary' class="m-auto")
                                treeselect.mt-1.pl-7(v-model='selectedItems'
                                    v-if='!cloud_files_loading && !files_sync_loading'
                                    alwaysOpen,
                                    :options="shareWithMetreeItems",
                                    :multiple="true"
                                    :disable-branch-nodes="false"
                                    :always-open="true"
                                    placeholder=''
                                    noResultsText='No data available'
                                    valueFormat='object',
                                    :loadOptions='getFolderFiles',
                                    :matchKeys='match_keys'
                                    :disableFuzzyMatching='true'
                                    )
                                    div(slot="option-label" slot-scope="{ node }" :title='node.raw.name') {{ node.raw.name }}
                                    div(slot="value-label" slot-scope="{ node }") {{ node.raw.name }}
                            v-container.select-file-container(v-if='cloudTab==="cloudTab-3"')
                                v-row(v-if='files_sync_loading' justify="center" align="center" class="fill-height")
                                    v-progress-circular(indeterminate size='40' color='primary' class="m-auto")
                                treeselect.mt-1.pl-7(v-model='selectedItems'
                                        v-if='!cloud_files_loading && !files_sync_loading'
                                        alwaysOpen,
                                        :options="sharePointtreeItems",
                                        :multiple="true"
                                        :disable-branch-nodes="false"
                                        :always-open="true"
                                        placeholder=''
                                        noResultsText='No data available'
                                        valueFormat='object',
                                        :loadOptions='getFolderFiles',
                                        :matchKeys='match_keys'
                                        :disableFuzzyMatching='true'
                                        )
                                        div(slot="option-label" slot-scope="{ node }" :title='node.raw.name') {{ node.raw.name }}
                                        div(slot="value-label" slot-scope="{ node }") {{ node.raw.name }}
                        v-col.pr-5(cols='6')
                            v-container.select-file-container.tree-detail-subcard()
                                v-data-table.cloud-document-table(:headers="selectedCloudFileHeaders" :items="selectedItems" 
                                hide-default-footer="hide-default-footer" fixed-header height='100%' :items-per-page='itemPerPage')
                                    template( v-slot:item.file_path="{ item }" )
                                        .ellipsis(:title='item.file_path' height='36px')
                                        div(class='d-flex justify-left align-center' :title='item.file_path')
                                            v-icon(v-if='item.file_type == "folder"' style='margin-left:0px;') mdi-folder-outline
                                            v-icon(v-else-if='item.file_type == ".jpg" || item.file_type == ".jpeg" || item.file_type == ".png"' style='margin-left:0px;') mdi-image
                                            v-icon(v-else style='margin-left:0px;') mdi-file-outline
                                            span(style='margin-left:20px;') {{item.file_path}}
                                    template( v-slot:item.actions="{ item }" )
                                        v-btn(icon, @click="onDeleteCloudFile(item)")
                                            v-icon(size='18') icon-remove                      
                        v-col
                            v-card-actions
                                v-row(no-gutters class='d-flex')
                                    v-col(class="d-flex justify-center")
                                        //- v-btn(elevation="0" :ripple="false", @click="selectedItems = []") {{$t('GPT_CLOUD.RESET')}}
                                    v-col.pr-2.ml-10(v-hide-element class='ml-auto')
                                        .d-flex.justify-center
                                            v-btn.d-flex.justify-center(:disabled='!can_cloud_drive' elevation="0" :ripple="false", color="#18bedf" @click="uploadIntergrateData" :dark='can_cloud_drive')
                                                v-progress-circular(v-if='addBtn_loading' indeterminate size='20') 
                                                .t-white(v-else) {{$t('GPT_CLOUD.SAVE')}}
                                        span.d-flex.justify-center.pt-2(v-if='!can_cloud_drive') {{$t('GPT_UPLOADIMG.CLOUD_TIP')}}

        v-dialog( v-model="uploagDialog", persistent , max-width="500", content-class="setting-dialog", v-if="uploagDialog === true")
            v-card
                v-card-title.d-flex.justify-center {{$t('GPT_KNOWLEDGE.UPLOAD')}}
                v-tabs(v-model='uploadTab' background-color='transparent'  id='gptupload' fixed-tabs)
                    v-tab(v-for='item in uploadTabs' :key='item.key' :href='`#uploadTab-${item.id}`'  style='display:flex; justify-content:center !important;')
                        span.spna {{ item.name }}
                //--Q&A資料上傳--
                v-card-text(v-if='uploadTab == "uploadTab-1"')
                    //- h6.pl-6.pt-2 {{$t('UPGPT.QAUPLOAD')}}
                    v-form.pt-3(v-model="valid", ref="form")
                        p               
                            v-row(no-gutters)
                                v-col(cols='12' id="QAinput")
                                    v-file-input.pa-0( prepend-icon="fa-solid fa-file-csv" accept=".csv" :placeholder= '$t("UPGPT.QAFILEUPLOADMESSAGE")' truncate-length="20" :rules="formRule.QAfileRule" v-model='QAAttachments' @change='onChangeQAFile')
                        p                                          
                        v-row.justify-end(no-gutters)
                            v-col(cols='12' md='3')
                                v-btn(:ripple='false' width='100%' height='45' color='success' :disabled='QAuploading || isAnalysising' depressed @click='onUpload("QA")') {{QAuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.QAUPLOAD')}}
                                    v-icon(size='16' color='black' :disabled='true' v-if='QAuploading || isAnalysising') fas fa-circle-notch fa-spin
                //- v-divider.divider.ma-0
                //--資料夾上傳--
                v-card-text(v-if='uploadTab == "uploadTab-2"')
                    //- h6.pl-6.pt-2 {{$t('UPGPT.FOLDERUPLOAD')}}
                    v-form.pt-3(v-model="valid", ref="form")
                        p               
                            v-row(no-gutters)
                                v-col(cols='12' id="Folderinput")
                                    v-file-input.pa-0( prepend-icon="mdi-folder-zip" accept=".zip" :placeholder= '$t("UPGPT.FOLDERUPLOADMESSAGE")' truncate-length="20" :rules="formRule.FolderfileRule" v-model='FolderAttachments' @change='onChangeFolderFile')
                        p                                          
                        v-row.justify-end(no-gutters)
                            v-col(cols='12' md='3')
                                v-btn(:ripple='false' width='100%' height='45' color='success' :disabled='Folderuploading || isAnalysising' depressed @click='onUpload("Folder")') {{Folderuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.FOLDERUPLOAD')}}
                                    v-icon(size='16' color='black' :disabled='true' v-if='Folderuploading || isAnalysising') fas fa-circle-notch fa-spin
                //- v-divider.divider.ma-0
                //--檔案上傳--
                v-card-text(v-if='uploadTab == "uploadTab-3"')
                    //- h6.pl-6.pt-2 {{$t('UPGPT.FILEUPLOAD')}}
                    v-form.pt-3(v-model="valid", ref="form")
                        p               
                            v-row(no-gutters)
                                v-col(cols='12' id="Fileinput")
                                    v-file-input.pa-0( prepend-icon="mdi-file-document-plus" accept=".txt,.pdf,.docx,.pptx" :placeholder= '$t("UPGPT.FILEUPLOADMESSAGE")' truncate-length="20" :rules="formRule.FilefileRule" v-model='FileAttachments' @change='onChangeFile')
                        v-row.justify-end(no-gutters)
                            v-col(cols='12' md='3')
                                v-btn(:ripple='false' width='100%' height='45' color='success' :disabled='Fileuploading || isAnalysising' depressed @click='onUpload("File")') {{Fileuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.FILEUPLOAD')}}
                                    v-icon(size='16' color='black' :disabled='true' v-if='Fileuploading || isAnalysising') fas fa-circle-notch fa-spin
                v-card-actions.mt-2.mb-2
                    v-row(no-gutters align='center' justify='center')
                        v-col.pr-2(cols="6")
                            v-btn(:ripple="false", @click="uploagDialog=false", width="100%", height="40", color="cancel",depressed, dark) {{$t('GENERAL.CLOSE')}}
        v-dialog(v-model="websiteAddDialog", max-width="500", content-class="setting-dialog", v-if="websiteAddDialog === true")
            v-card
                v-card-title.d-flex.justify-center {{$t('UPGPT.ADD_URL')}}
                v-card-text
                    v-form(v-model="valid", ref="form")
                        v-text-field.no-border.v-text-field__slot(
                            v-model="addWebsitesSettingData.url",
                            type="text",
                            :label=`$t('UPGPT.URL')`,
                            prepend-icon="mdi-web",
                            :rules="formRule.URLRules"
                        )
                        v-text-field.no-border.v-text-field__slot(
                            v-model="addWebsitesSettingData.level",
                            type="number",
                            :label=`$t('UPGPT.LEVEL')`,
                            prepend-icon="mdi-digital-ocean",
                            :rules="formRule.LevelRules"
                        )          
                    
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols="6")
                                v-btn(
                                    :ripple="false",
                                    @click="onCancel",
                                    width="100%",
                                    height="40",
                                    color="cancel",
                                    depressed,
                                    dark
                                ) {{$t('GENERAL.CANCEL')}}    
                            v-col.pl-2(cols="6")
                                v-btn(
                                    :ripple="false",
                                    @click="onConfirmAddWeb()",
                                    width="100%",
                                    height="40",
                                    color="success",
                                    depressed,
                                    :disabled='addWebsiteloading',
                                    :loading='addWebsiteloading',
                                    :dark='!addWebsiteloading',
                                ) {{$t('GENERAL.ADD')}}
        
        v-dialog(v-model="websiteEditDialog", max-width="500", content-class="setting-dialog", v-if="websiteEditDialog === true")
            v-card
                v-card-title.d-flex.justify-center {{$t('UPGPT.EDIT_URL')}}
                v-card-text
                    v-form(v-model="valid", ref="form")
                        v-text-field.no-border.v-text-field__slot(
                            v-model="editWebsitesSettingData.url",
                            type="text",
                            :label=`$t('UPGPT.URL')`,
                            prepend-icon="mdi-web",
                            :rules="formRule.URLRules"
                        )
                        v-text-field.no-border.v-text-field__slot(
                            v-model="editWebsitesSettingData.level",
                            type="number",
                            :label=`$t('UPGPT.LEVEL')`,
                            prepend-icon="mdi-digital-ocean",
                            :rules="formRule.LevelRules"
                        )          
                    
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols="6")
                                v-btn(
                                    :ripple="false",
                                    @click="onCancel",
                                    width="100%",
                                    height="40",
                                    color="cancel",
                                    depressed,
                                    dark
                                ) {{$t('GENERAL.CANCEL')}}    
                            v-col.pl-2(cols="6")
                                v-btn(
                                    :ripple="false",
                                    @click="onConfirmEditWeb()",
                                    width="100%",
                                    height="40",
                                    color="success",
                                    depressed,
                                    :disabled='editWebsiteloading',
                                    :loading='editWebsiteloading',
                                    :dark='!editWebsiteloading',
                                ) {{$t('GENERAL.EDIT')}}
        v-dialog(v-model='connectCloudStorageDialog' max-width='500' content-class='setting-dialog')
            v-card
                v-card-title.d-flex.justify-center.mb-4 {{$t('EMAILSYNC.DIALOG')}}
                v-card-text
                    v-form( ref='form')
                        v-row(no-gutters)
                            v-col.pr-2(cols='12')
                                p {{$t('EMAILSYNC.DIALOG_2')}}
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols='6')
                                v-btn(:ripple='false' @click='connectCloudStorageDialog = false' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                            v-col.pl-2(cols='6')
                                v-btn(:ripple='false' @click='onCloudStorageAdd' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.ADD')}}
        v-dialog(v-model='confirmCouldAccountDialog' max-width='500' content-class='setting-dialog')
            v-card
                v-card-title.d-flex.justify-center.mb-4 {{$t('EMAILSYNC.DIALOG_S')}}
                v-card-text
                    v-form(v-model='connectCalendar.valid' ref='form')
                        v-row(no-gutters)
                            v-col.pr-2(cols='12')
                                p {{$t('CALENDAR_SYNC_SETTINGS.CONNECT_SUCCESS')}}
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols='12')
                                v-btn(:ripple='false' @click='closeConnectCloudAccount' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.CLOSE')}}

        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='imageDataDialog' max-width='1200' content-class='statusDialog')
            imageData-dialog(@emitImageDataDialog='onEmitImageDataDialog' :imageAttributeHeader='imageAttributeHeader' :imageAttributeTableData='imageAttributeTableData' :imageAttributeTablePage='imageAttributeTablePage' :currentAttributeId='currentAttributeId')
</template>
<script>
	import Vue from 'vue';
    import loadingOverlay from "@/components/Common/loadingOverlay"; 
    import successDialog from "@/components/Dialog/successDialog";
    import errorDialog from "@/components/Dialog/errorDialog";
    import messageDialog from '@/components/Dialog/messageDialog';
    import i18n from '/common/plugins/vue-i18n.js';
    import UpGPTService from "@/services/UpGPTDataService";
    import AiBotsService from "@/services/AiBotsService";
    import PreferenceService from "@/services/PreferenceService";
    import UploadStatusDataService from "@/services/UploadStatusDataService";
    import O365Service from "@/services/O365Service";
    import JsZip from "jszip";
    import KnowledgeLibraryService from "@/services/KnowledgeLibraryService";
    import imageDataDialog from "@/components/GPT_Dialog/Knowledge/imageDataTable";
    import UpGPTDataintegrationService from "@/services/UpGPTDataintegrationService";
    import Treeselect from '@riophae/vue-treeselect';

	export default Vue.extend({
        props: {  
            target_id: {
                type: String,
                required: true,
            },
            knowledge_name: {
                type: String,
                required: true,
            },
        },
        components: {   
            loadingOverlay,
            successDialog,
            errorDialog,
            messageDialog,
            imageDataDialog,
            Treeselect
        },        
        data() {
		return {
            itemPerPage: 10000,
            singalDeleteData:null,
            uploagDialog:false,
            loading:false,
            valid:false,            
            BaseSettingData:{},
            formRule: {            
                AINameRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^.{0,20}$/.test(v) || i18n.t("RULE.RULE_20"),
                ],
                AIRoleRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^.{0,200}$/.test(v) || i18n.t("RULE.RULE_200"),
                ],
                TopicRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^.{0,200}$/.test(v) || i18n.t("RULE.RULE_200"),
                ],
                TopicLimitRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^.{0,200}$/.test(v) || i18n.t("RULE.RULE_200"),
                ],
                LanguageRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                ],
                QAfileRule: [
                    () => { return (this.QAfileSize || i18n.t('RULE.RULE_FILE_3'))},
                ],
                FolderfileRule: [
                    () => { return (this.FolderSize || i18n.t('RULE.RULE_FILE_3'))},
                ],
                FilefileRule: [
                    () => { return (this.FileSize || i18n.t('RULE.RULE_FILE_3'))},
                ],
                ImagefileRule: [
                    () => { return (this.ImgfileSize || i18n.t('RULE.RULE_FILE_4'))},
                ],
                URLRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+*.~#?&//=]*)$|(^$)|null/.test(v) || i18n.t("RULE.RULE_URL"),
                    (v) => /^.{0,500}$/.test(v) || i18n.t("RULE.RULE_500"),
                ],
                LevelRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^([1-2])$/.test(v) || i18n.t("RULE.RULE_R_NUM_6"),
                ],
            },
            editData:{},
            QAAttachments:null,
            FolderAttachments:null,
            FileAttachments:null,
            ImageAttachments:null,
            QAfileSize:true,
            FolderSize:true,
            ImgfileSize: true,
            FileSize:true,
            successDialog:false,
            errorDialog:false,
            errorMessage:"",
            messageDialog:false,
            message:"",
            messagetype:"",
            basesettingchangeing:false,
            QAuploading:false,
            Folderuploading:false,
            Fileuploading:false,
            Imageuploading:false,
            reseting:false,
            tab: null,
            tabs: [
                {
                    id: 1,
                    key: 'Uploaded',
                    name: i18n.t('UPGPT.FILE_TABLE')
                },
                {
                    id: 2,
                    key: 'QA',
                    name: i18n.t('UPGPT.QAUPLOAD')
                },
                {
                    id: 3,
                    key: 'Folder',
                    name: i18n.t('UPGPT.FOLDERUPLOAD')
                },
                {
                    id: 4,
                    key: 'File',
                    name: i18n.t('UPGPT.FILEUPLOAD')
                    
                },
                {
                    id: 5,
                    key: 'UploadedImage',
                    name: i18n.t('UPGPT.UPLOADIMG')
                },
                {
                    id: 6,
                    key: 'Cloud',
                    name: i18n.t('UPGPT.CLOUD')
                },
                {
                    id: 7,
                    key: 'Website',
                    name: i18n.t('UPGPT.WEBSITEANALYSIS')
                },
            ],
            uploadTab:null,
            uploadTabs:[
                {
                    id: 1,
                    key: 'QA',
                    name: i18n.t('UPGPT.QAUPLOAD')
                },
                {
                    id: 2,
                    key: 'Folder',
                    name: i18n.t('UPGPT.FOLDERUPLOAD')
                },
                {
                    id: 3,
                    key: 'File',
                    name: i18n.t('UPGPT.FILEUPLOAD')
                },
                {
                    id: 4,
                    key: 'UploadedImage',
                    name: i18n.t('UPGPT.UPLOADIMG')
                }
            ],
            total_file_amount: 0,
            updated_time: '',
            filename_arr: [],
            noDATA: false,
            FileTablePage: 1,
            FilePageCount: 0,
            FilesPerPage: 7,
            FileTableheaders: [
                {
                    text: i18n.t('UPGPT.FILE'),
                    align: 'left',
                    sortable: false,
                    value: 'name',
                    class: "white lighten-5",
                },
                {
                    text: i18n.t('GPT_UPLOADIMG.IMAGE_STATUS'),
                    align: 'center',
                    sortable: false,
                    value: 'image_status',
                    class: "white lighten-5",
                },
                { 
                    text: i18n.t('CAMPAIGN.ACTIVE'), 
                    align: 'center',
                    sortable: false,
                    value: 'actions', 
                    class: "white lighten-5",
                    width: '20%' ,
                },
            ],
            txtDownloading: false,
            WebsitesSettingData: {},
            websiteAddDialog: false,
            addWebsiteloading: false,
            addWebsitesSettingData: {
                url:'',
                level:1,
            },
            websiteEditDialog: false,
            editWebsiteloading: false,
            editWebsitesSettingData: {
                url:'',
                level:'',
            },
            Websiteheaders: [
                {
                    text: i18n.t('UPGPT.URL'),
                    align: 'left',
                    sortable: false,
                    value: 'url',
                    width: '60%' ,
                    class: "white lighten-5",
                },
                {
                    text: i18n.t('UPGPT.LEVEL'),
                    align: 'center',
                    sortable: false,
                    value: 'level',
                    width: '20%' ,
                    class: "white lighten-5",
                },
                { 
                    text: i18n.t('UPGPT.ACTION'), 
                    align: 'center',
                    sortable: false,
                    value: 'actions',
                    width: '20%' ,
                    class: "white lighten-5",
                },
            ],
            WebsiteTablePage: 1,
            WebsitePageCount: 0,
            WebsitesPerPage: 10,
            Websiteid: '',
            Webuploading: false,
            zipDownloading: false,
            hasWebFile:false,
            isAnalysising:false,
            assistant_languageList: [
                {key:'auto',name:i18n.t('UPGPT.AUTO_DETECT')},
                {key:'Traditional Chinese',name:'繁體中文'},
                {key:'English',name:'English'},
                {key:'Vietnamese',name:'Tiếng Việt'},
                {key:'Burmese',name:'မြန်မာအက္ခရာ'},
                {key:'Laotian',name:'ພະຍັນຊະນະລາວ'},
                {key:'Turkish',name:'Türkçe'},
                {key:'Thai',name:'ไทย'},
                {key:'Telugu',name:'తెలుగు'},
                {key:'Albanian',name:'Shqip'},
                {key:'Slovenian',name:'Slovenščina'},
                {key:'Slovak',name:'Slovenčina'},
                {key:'Russian',name:'Русский'},
                {key:'Romanian',name:'Română'},
                {key:'Brazilian Portuguese',name:'Português do Brasil'},
                {key:'Portuguese',name:'Português'},
                {key:'Dutch',name:'Nederlands'},
                {key:'Mongolian',name:'Mongolian'},
                {key:'Korean',name:'한국어'},
                {key:'Georgian',name:'ქართული'},
                {key:'Japanese',name:'日本語'},
                {key:'Italian',name:'Italiano'},
                {key:'Indonesian',name:'Bahasa Indonesia'},
                {key:'Hungarian',name:'Magyar'},
                {key:'Hebrew',name:'עברית '},
                {key:'French',name:'Français'},
                {key:'Finnish',name:'suomi'},
                {key:'Spanish',name:'Español'},
                {key:'German',name:'Deutsch'},
                {key:'Danish',name:'Dansk'},
                {key:'Czech',name:'Čeština'},
                {key:'Catalan',name:'Catalan'},
                {key:'Bulgarian',name:'Български'},
                {key:'Arabic',name:'العربية'},
                {key:'Simplified Chinese',name:'简体中文'},
                {key:'Polish',name:'Polski'},
                {key:'Swedish',name:'Svenska'},
            ],
            // cloud upload anlysis
            cloudDescribeImg: '',
            cloudCustomerAttr: '',
            cloudSearchImg: '',
            // folder upload anlysis
            folderDescribeImg: '',
            folderCustomerAttr: '',
            folderSearchImg: '',
            // image upload anlysis
            imgDescribeImg: '',
            imgCustomerAttr: '',
            imgSearchImg: '',
            // image status image upload anlysis
            statusDescribeImg: true,
            statusCustomerAttr: false,
            statusSearchImg: false,
            customerAttributeList: [],
            currentAttributeId: 0,
            imageDataDialog: false,
            // treeItems: [],
            myDrivetreeItems: [],
            shareWithMetreeItems: [],
            sharePointtreeItems: [],
            typeList: [
                { key: "ONEDRIVE", name: 'OneDrive' },
            ],
            connectCloudStorageDialog: false,
            o365AccountData: [],
            confirmCouldAccountDialog: this.$route.query.confirmO365Setup == 'Y',
            connectCalendar: {
                emailAddress: '',
            },
            cloudType: "ONEDRIVE",
            selectedItems: [],
            selectedCloudFileHeaders: [
                {
                    text: i18n.t('UPGPT.FILE'),
                    align: 'left',
                    sortable: false,
                    value: 'file_path',
                    class: "white lighten-5",
                },
                { 
                    text: i18n.t('CAMPAIGN.ACTIVE'), 
                    align: 'center',
                    sortable: false,
                    value: 'actions', 
                    class: "white lighten-5",
                    width: '20%' ,
                },
            ],
            imageAttributeHeader:[],
            imageAttributeTableData: [],
            imageAttributeTablePage:'',
            dynamicSlots: [],
            foldicon: false,
            currentUrl: '',
            cloud_files_loading:false,
            files_sync_loading:false,
            cloud_type:null,
            cloud_id:null,
            addBtn_loading:false,
            match_keys : ['file_path','id'],
            cloudTab: null,
            cloudTabs:[
                {
                    id: 1,
                    key: 'my_drive',
                    name: i18n.t('GPT_CLOUD.MY_DRIVE')
                },
                {
                    id: 2,
                    key: 'share_with_me',
                    name: i18n.t('GPT_CLOUD.SHARE_WITH_ME')
                },
                {
                    id: 3,
                    key: 'sharepoint',
                    name: i18n.t('GPT_CLOUD.SHAREPOINT')
                },
            ],
            checkboxesSetting: '',
            can_image_search:false,
            can_image_analysis:false,
            can_image_process:false,
            can_cloud_drive:false,
        }},
        async created()
        {
            
            this.getKnowledgeInfo();
            this.getWebsitesInfo();
            this.getImageCheckboxesSet();
            this.isAnalysising = false;
            this.getCustomerAttributeList();
            this.getImageAttributeHeader();
            this.getImageAttributeData();
            this.get_upgpt_setting();
            await O365Service.getO365OneDriveAccounts()
            .then(response => {
                this.o365AccountData = (response.data);
            }).then(()=>{
                if(this.o365AccountData.length>0){
                    this.cloud_files_loading = true;
                    this.cloud_type = "onedrive";
                    this.cloud_id = this.o365AccountData[0].o365_user_principal_name;
                    this.getOneDriveFiles();
                }
            });
        },
        methods:{
            async get_upgpt_setting(){
                let setting = await this.upgpt_image_func_setting();
                this.can_image_search = setting['can_image_search'];
                this.can_image_analysis = setting['can_image_analysis'];
                this.can_image_process = setting['can_image_process'];
                this.can_cloud_drive = setting['can_cloud_drive'];
            },
            async getOneDriveFiles(){
                this.files_sync_loading = true
                let data = {"knowledge_lib_id":this.$route.params.id,'cloud_id':this.cloud_id,'cloud_type':this.cloud_type,}
                await UpGPTDataintegrationService.cloud_files_list(data).then((response)=>{
                    this.myDrivetreeItems = response.data['my_drive']
                    this.shareWithMetreeItems = response.data['share_with_me']
                    this.sharePointtreeItems = response.data['sharepoint']
                    // this.treeItems.find(el=>el.id==1)['children'] = response.data['my_drive'];
                    // this.treeItems.find(el=>el.id==5)['children'] = response.data['share_with_me'];
                    // this.treeItems.find(el=>el.id==19)['children'] = response.data['sharepoint'];
                    this.selectedItems = response.data['selected_list'];
                }).finally(()=>{
                    this.cloud_files_loading = false;
                    this.files_sync_loading = false;
                })
            },
            async getFolderFiles({ action, parentNode, callback }){
                if (action === "LOAD_CHILDREN_OPTIONS") {
                    parentNode.cloud_id = this.cloud_id;
                    parentNode.cloud_type = this.cloud_type;
                    await UpGPTDataintegrationService.get_folder_files_list(parentNode).then((response)=>{
                        parentNode.children = response.data;
                    }).finally(()=>{
                        callback()
                    })
                }
            },
            async uploadIntergrateData(){
                this.addBtn_loading = true;
                let data = {
                    'cloud_id':this.cloud_id,
                    'cloud_type':this.cloud_type,
                    "knowledge_lib_id":this.$route.params.id,
                    "selected_list": this.selectedItems,
                    "describe_image_by_ai": this.cloudDescribeImg,
                    "save_customeized_attributes": this.cloudCustomerAttr,
                    "image_search": this.cloudSearchImg,
                    "upgpt_table_configuration_id": this.currentAttributeId,
                }
                await UpGPTDataintegrationService.upload_files(data).then((response)=>{
                    if(response.data == 'exceed maximum size'){
                        this.showErrorDialog(i18n.t('UPGPT.OVER_SIZE'));
                    }else{
                        this.addBtn_loading = false;
                        this.showSuccessDialog();
                    }
                })
                .catch((response) => {
                    // this.showSuccessDialog(response);
                    console.log(response)
                    this.showMessageDialog("processing",i18n.t('UPGPT.TIMEOUT_MESSAGE'));
                    this.addBtn_loading = false;
                    this.$router.replace(`/upgpt/Knowledges/${this.target_id}`);
                });

            },
            reFreshUpGPT(){
                UpGPTService.getSettingData().then(response => 
                {
                    this.BaseSettingData = {
                        "assistant_name" : response.data.assistant_name,
                        "assistant_role" : response.data.assistant_role,
                        "assistant_topic" : response.data.assistant_topic,
                        "assistant_topic_limit" : response.data.assistant_topic_limit,
                        "assistant_language" : response.data.assistant_language,
                    };
                    if(response.data.temp_zip_json){
                        this.hasWebFile = true;
                    }
                    else
                        this.hasWebFile = false;
                })
                .catch((response) => {
                    this.showErrorDialog(response);
                });
                this.getKnowledgeInfo();
                this.getWebsitesInfo();
            },
            validateForm(){
                this.valid = this.$refs.form.validate();
                if(this.valid)
                    return true;
                else
                    return false;
            },
            checkCheckboxs(describeAttribute,imgDescribeImg,imgSearchImg){
                if (describeAttribute || imgDescribeImg || imgSearchImg) {
                    return true;
                }
                return false;
            },
            onSave(data)
            {
                if(this.$refs.baseSettingForm.validate()){
                    this.basesettingchangeing = true;
                    this.editData = data;
                    UpGPTService.setSettingData(data).then((response)=>
                    {
                        this.basesettingchangeing = false;
                        if(response.data == "wrong preference"){
                            this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                        } else {
                            this.reFreshUpGPT()
                            this.showSuccessDialog();
                        }
                        this.editData = {};
                    })
                }
            },
            rowClass() {
                const rowClass = 'dataTableColor'
                return rowClass;
            }, 
            resetData() {
                this.showMessageDialog('reset',i18n.t('UPGPT.DELETEALLDATA'));
            },
            onChangeQAFile(event) {
                if (event != null) {
                    // file size
                    if (event.size > 20971520) {
                        this.QAfileSize = false;
                    } else {
                        this.QAfileSize = true;
                    }
                } else {
                    this.QAfileSize = false;
                }
            },
            onChangeFolderFile(event) {
                if (event != null) {
                    // file size
                    if (event.size > 20971520) {
                        this.FolderSize = false;
                    } else {
                        this.FolderSize = true;
                    }
                } else {
                    this.FolderSize = false;
                }
            },
            onChangeImageFile(event){
                if (event != null) {
                    if (event.size > 20971520) {
                        this.ImgfileSize = false;
                    } else {
                        this.ImgfileSize = true;
                    }
                } else {
                    this.ImgfileSize = false;
                }
            },
            onChangeFile(event) {
                if (event != null) {
                    // file size
                    if (event.size > 20971520) {
                        this.FileSize = false;
                    } else {
                        this.FileSize = true;
                    }
                } else {
                    this.FileSize = false;
                }
            },
            async onUpload(name){
                let data = {
                    "knowledge_lib_id":this.target_id,
                    "name":this.knowledge_name,
                    "describe_image_by_ai": null,
                    "save_customeized_attributes": null,
                    "image_search": null,
                    "upgpt_table_configuration_id": this.currentAttributeId,
                }
                if(name == "QA"){
                    if(this.validateForm() && this.QAfileSize && this.QAAttachments != null){
                        this.loading = true;
                        this.QAuploading = true;
                        await KnowledgeLibraryService.uploadqafilebyacs(data,this.QAAttachments).then((response)=>{
                            if(response.data == "wait"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WAIT'));
                            } 
                            else if(response.data == "wrong type"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WRONGTYPEINCSV'));
                            } 
                            else if(response.data == "wrong preference"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                            }
                            else if(response.data == "wrong embedding"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.EMBEDDING_WRONG'));
                            }
                            else if(response.data == "fail"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.UPLOAD_FAIL'));
                            }
                            else if(response.data == "exceed maximum size"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.OVER_SIZE'));
                            }
                            else{
                                this.loading = false;
                                this.QAuploading = false;
                                this.showSuccessDialog();
                                this.getKnowledgeInfo();
                                this.$emit("emitreFresh");
                            }
                        }).catch((response) => {
                            if(String(response).indexOf('timeout') >= 0){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showMessageDialog("processing",i18n.t('UPGPT.TIMEOUT_ERROR'));
                            }
                            else {
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(String(response));
                                this.getKnowledgeInfo();
                            }
                        }).finally(()=>{
                            this.QAAttachments = null;
                        });
                    }
                } else if(name == "Folder"){
                    data.describe_image_by_ai = (this.folderDescribeImg == false || this.folderDescribeImg == null) ? null : this.folderDescribeImg;
                    data.save_customeized_attributes = (this.folderCustomerAttr == false || this.folderCustomerAttr == null) ? null : this.folderCustomerAttr;
                    data.image_search = (this.folderSearchImg == false || this.folderSearchImg == null) ? null :this.folderSearchImg;
                    if(this.validateForm() && this.FolderSize && this.FolderAttachments != null){
                        this.loading = true;
                        this.Folderuploading = true;
                        await KnowledgeLibraryService.uploadFolder(data,this.FolderAttachments).then((response)=>{
                            if(response.data == "wait"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WAIT'));
                            } 
                            else if(response.data == "wrong type"){
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WRONGTYPEINZIP'));
                            }
                            else if(response.data == "wrong preference"){
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                            }
                            else if(response.data == "wrong embedding"){
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.EMBEDDING_WRONG'));
                            }
                            else if(response.data == "fail"){
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.UPLOAD_FAIL'));
                            }
                            else if(response.data == "exceed maximum size"){
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.OVER_SIZE'));
                            }
                            else{
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showSuccessDialog();
                                this.getKnowledgeInfo();
                                this.$emit("emitreFresh");
                            }
                        }).catch((response) => {
                            if(String(response).indexOf('timeout') >= 0){
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showMessageDialog("processing",i18n.t('UPGPT.TIMEOUT_ERROR'));
                            }
                            else {
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showErrorDialog(String(response));
                                this.getKnowledgeInfo();
                            }
                        }).finally(()=>{
                            this.FolderAttachments = null;
                        });
                    }
                } else if(name == "File"){
                    if(this.validateForm() && this.FileSize && this.FileAttachments != null){
                        this.loading = true;
                        this.Fileuploading = true;
                        await KnowledgeLibraryService.uploadFile(data,this.FileAttachments).then((response)=>{
                            if(response.data == "wait"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WAIT'));
                            } 
                            else if(response.data == "wrong type"){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WRONGTYPEINFILE'));
                            }
                            else if(response.data == "wrong preference"){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                            }
                            else if(response.data == "wrong embedding"){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.EMBEDDING_WRONG'));
                            }
                            else if(response.data == "fail"){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.UPLOAD_FAIL'));
                            }
                            else if(response.data == "exceed maximum size"){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.OVER_SIZE'));
                            }
                            else{
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showSuccessDialog();
                                this.getKnowledgeInfo();
                                this.$emit("emitreFresh");
                            }
                        }).catch((response) => {
                            if(String(response).indexOf('timeout') >= 0){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showMessageDialog("processing",i18n.t('UPGPT.TIMEOUT_ERROR'));
                            }
                            else {
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(String(response));
                                this.getKnowledgeInfo();
                            }
                        }).finally(()=>{
                            this.FileAttachments = null;
                        });
                    }
                } else if(name == "Image"){
                    data.describe_image_by_ai = this.imgDescribeImg
                    data.save_customeized_attributes = this.imgCustomerAttr
                    data.image_search = this.imgSearchImg
                    if(this.validateForm() && this.ImgfileSize && this.ImageAttachments != null ){
                        this.loading = true;
                        this.Imageuploading = true;
                        await KnowledgeLibraryService.uploadImg(data,this.ImageAttachments).then((response)=>{
                            if(response.data == "wait"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WAIT'));
                            }
                            else if(response.data == "wrong type"){
                                this.loading = false;
                                this.Imageuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WRONGTYPEINIMAGEFILE'));
                            }
                            else if(response.data == "wrong preference"){
                                this.loading = false;
                                this.Imageuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                            }
                            else if(response.data == "wrong embedding"){
                                this.loading = false;
                                this.Imageuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.EMBEDDING_WRONG'));
                            }
                            else if(response.data == "fail"){
                                this.loading = false;
                                this.Imageuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.UPLOAD_FAIL'));
                            }
                            else if(response.data == "exceed maximum size"){
                                this.loading = false;
                                this.Imageuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.OVER_SIZE'));
                            }
                            else{
                                this.loading = false;
                                this.Imageuploading = false;
                                this.showSuccessDialog();
                                this.getKnowledgeInfo();
                                this.$emit("emitreFresh");
                            }
                        }).catch((response) => {
                            if(String(response).indexOf('timeout') >= 0){
                                this.loading = false;
                                this.Imageuploading = false;
                                this.showMessageDialog("processing",i18n.t('UPGPT.TIMEOUT_ERROR'));
                            }
                            else {
                                this.loading = false;
                                this.Imageuploading = false;
                                this.showErrorDialog(String(response));
                                this.getKnowledgeInfo();
                            }
                        }).finally(()=>{
                            this.ImageAttachments = null;
                        });
                    }
                }
            },
            getKnowledgeInfo() {
                KnowledgeLibraryService.getknowledgedetail(this.target_id).then(response => 
                {
                    this.total_file_amount = response.data.length;
                    // if(response.data.updated_time == null){
                    //     this.noDATA = true;
                    // }
                    // else{
                    //     this.updated_time = response.data.updated_time;
                    //     this.noDATA = false;
                    // }
                    let data = response.data
                    this.filename_arr = data.map(el=>{
                        return {
                            "name":el.filename,
                            "id":el.id,
                            "has_file":el.has_file,
                            "image_status":{
                            "imgDescribeImg":el.describe_image_by_ai, 
                            "imgCustomerAttr":el.save_customeized_attributes, 
                            "imgSearchImg":el.image_search
                        }}
                    });
                    this.filename_arr.forEach(i=>{
                        if(i.image_status['imgDescribeImg'] != null && i.image_status['imgCustomerAttr'] != null && i.image_status['imgSearchImg'] != null){
                            i['is_image_file'] = true
                        }
                    })
                })
                .catch((response) => {
                    this.showErrorDialog(response);
                });
            },
            turntoupchatinbox() {
                PreferenceService.getUpChat().then(response => 
                {
                    let host = response.data.host;
                    let account_id = response.data.account_id;
                    window.open(`${host}/app/accounts/${account_id}/settings/inboxes/list`);
                })
                .catch((response) => {
                    this.showErrorDialog(response);
                });
            },
            singleDelete(item) {
                this.singalDeleteData=item;
                this.showMessageDialog("singalDelete",i18n.t("GPT_KNOWLEDGE.SINGAL_DELETE"))
            },
            downloadtxt(filename){
                this.txtDownloading = true;
                let dotdetect_arr = filename.split('.');
                let replace_str = '.' + dotdetect_arr[dotdetect_arr.length-1]
                let downloadfileName = filename.replace(replace_str,'.txt');
                KnowledgeLibraryService.downloadtxt(filename).then(response => 
                {
                    if(response.data == "deleted"){
                        this.txtDownloading = false;
                        this.showErrorDialog(i18n.t('UPGPT.FILE_HAS_BEEN_DELETED'));
                    } else {
                        let a = document.createElement('a');
                        a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.data);
                        a.download = downloadfileName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        this.txtDownloading = false;
                        this.showSuccessDialog();
                    }
                })
                .catch((response) => {
                    this.txtDownloading = false;
                    this.showErrorDialog(response);
                });
            },
            showAddWebsite() {
                this.websiteAddDialog = true;
            },
            getWebsitesInfo() {
                KnowledgeLibraryService.getWebList(this.target_id).then(response => 
                {
                    this.WebsitesSettingData = response.data;
                })
                .catch((response) => {
                    this.showErrorDialog(response);
                });
            },
            onCancel(){
                this.websiteAddDialog = false;
                this.websiteEditDialog = false;
                this.addWebsitesSettingData.url = '';
                this.addWebsitesSettingData.level = 1;
                this.editWebsitesSettingData.url = '';
                this.editWebsitesSettingData.level = '';
            },
            onConfirmAddWeb(){
                if(Object.keys(this.WebsitesSettingData).length < 5){
                    this.addWebsiteloading = true;
                    if(this.validateForm()){
                        this.addWebsitesSettingData['knowledge_lib_id'] = this.target_id;
                        KnowledgeLibraryService.addWebList(this.addWebsitesSettingData).then((response)=>
                        {
                            this.addWebsiteloading = false;
                            if(response.data == 'overlimit'){
                                this.showErrorDialog(i18n.t('UPGPT.OVERLIMIT'));
                            } else {
                                this.showSuccessDialog();
                            }
                        }).catch((response) => {
                            this.addWebsiteloading = false;
                            this.showErrorDialog(response);
                        }).finally(()=>{
                            this.getWebsitesInfo();
                            this.addWebsitesSettingData.url = '';
                            this.addWebsitesSettingData.level = 1;
                            this.websiteAddDialog = false;
                        });
                    } else {
                        this.addWebsiteloading = false;
                    }
                } else {
                    this.showErrorDialog(i18n.t('UPGPT.OVERLIMIT'));
                }
            },
            showEditWebsite (item) {
                this.websiteEditDialog = true;
                this.Websiteid = item.id;
                this.editWebsitesSettingData.url = item.url;
                this.editWebsitesSettingData.level = item.level;
            },
            onConfirmEditWeb(){
                this.editWebsiteloading = true;
                if(this.validateForm()){    
                    KnowledgeLibraryService.editWebList(this.Websiteid,this.editWebsitesSettingData).then(()=>
                    {
                        this.editWebsiteloading = false;
                        this.showSuccessDialog();
                    }).catch((response) => {
                        this.addWebsiteloading = false;
                        this.showErrorDialog(response);
                    }).finally(()=>{
                        this.getWebsitesInfo();
                        this.editWebsitesSettingData.url = '';
                        this.editWebsitesSettingData.level = '';
                        this.websiteEditDialog = false;
                        this.Websiteid = '';
                    });
                } else {
                    this.editWebsiteloading = false;
                }
            },
            async web_analysis(){
                this.loading = true;
                this.Webuploading = true;
                let data = {
                    "knowledge_lib_id":this.target_id,
                    "name":this.knowledge_name
                }
                await KnowledgeLibraryService.websiteanalysis(data).then((response)=>{
                    if(response.data == "wrong preference"){
                        this.loading = false;
                        this.Webuploading = false;
                        this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                    }
                    else if(response.data == "wrong embedding"){
                        this.loading = false;
                        this.Webuploading = false;
                        this.showErrorDialog(i18n.t('UPGPT.EMBEDDING_WRONG'));
                    }
                    else if(response.data == "fail"){
                        this.loading = false;
                        this.Webuploading = false;
                        this.showErrorDialog(i18n.t('UPGPT.UPLOAD_FAIL'));
                    }
                    else if(response.data == "no url"){
                        this.loading = false;
                        this.Webuploading = false;
                        this.showErrorDialog(i18n.t('UPGPT.NO_URL'));
                    }
                    else{
                        this.loading = false;
                        this.Webuploading = false;
                        this.hasWebFile = true;
                        this.showSuccessDialog();
                        this.reFreshUpGPT();
                    }
                }).catch((response) => {
                    if(String(response).indexOf('timeout') >= 0){
                        this.loading = false;
                        this.Webuploading = false;
                        this.showMessageDialog("processing",i18n.t('UPGPT.TIMEOUT_ERROR'));
                    }
                })
                this.$emit("emitreFresh");
            },
            onDelete(id) {
                this.Websiteid = id;
                this.showMessageDialog("delWebsite",i18n.t('UPGPT.DELETE_CHECK'));
            },
            downloadwebzip() {
                this.zipDownloading = true;
                UpGPTService.downloadwebzip().then(response => 
                {
                    if(response.data == "blank"){
                        this.zipDownloading = false;
                        this.showErrorDialog(i18n.t('UPGPT.BLANK_WEB_INFO'));
                    } else {
                        // 初始化zip打包物件
                        let zip = new JsZip();
                        // 逐筆建立要打包的檔案
                        for(let fileinfo of response.data){
                            zip.file(fileinfo.filename, fileinfo.content);
                        }
                        // 把打包內容非同步轉成blob二進位制格式
                        zip.generateAsync({type:"blob"}).then(function(content) {
                            // 下載zip    
                            // saveAs(content, "website_analysis_data.zip");
                            let a = document.createElement('a');
                            a.href = URL.createObjectURL(content);
                            a.download = 'website_analysis_data.zip';
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        });
                        this.zipDownloading = false;
                        this.showSuccessDialog();
                    }
                })
                .catch((response) => {
                    this.zipDownloading = false;
                    this.showErrorDialog(response);
                });
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
             },
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showMessageDialog(type,message) {
                this.messageDialog = true;
                this.messagetype = type
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if(this.messagetype == "processing"){
                    if(val){
                        this.$emit("emitChangeTab","UploadStatus")
                    }
                }
                else if(this.messagetype == "reset"){
                    if(val){
                        this.loading = true;
                        this.reseting = true;
                        UpGPTService.resetAllKnowledge()
                        .then((response)=>{
                            if(response.data == 'Succeeded'){
                                this.loading = false;
                                this.reseting = false;
                                this.showSuccessDialog();
                                this.reFreshUpGPT();
                            } else {
                                this.loading = false;
                                this.reseting = false;
                                this.showErrorDialog(i18n.t('UPGPT.NOT_CLEAN'));
                                this.reFreshUpGPT();
                            }
                        })
                        .catch((response) => {
                            if(String(response).indexOf('timeout') >= 0){
                                this.loading = false;
                                this.reseting = false;
                                this.showSuccessDialog();
                            } else {
                                this.loading = false;
                                this.reseting = false;
                                this.showErrorDialog(response);
                            }
                            this.reFreshUpGPT();
                        });
                    }
                }
                else if(this.messagetype == "delWebsite"){
                    if(val){
                        KnowledgeLibraryService.deleteWebList(this.Websiteid).then(()=>
                        {
                            this.showSuccessDialog();
                        }).catch((response) => {
                            this.showErrorDialog(response);
                        }).finally(()=>{
                            this.getWebsitesInfo();
                        });
                        this.Websiteid = '';
                    }
                }else if(this.messagetype == "singalDelete"){
                    if(val){
                        this.loading=true;
                        KnowledgeLibraryService.delete_single_knowledge(this.singalDeleteData.id)
                        .then(()=>{
                            this.filename_arr = this.filename_arr.filter(el=>el.id!=this.singalDeleteData.id);
                            this.$emit("emitCount");
                        }).catch(() => {
                            this.showErrorDialog(i18n.t("GPT_KNOWLEDGE.DELETEING"));
                        }).finally(()=>{
                            this.singalDeleteData=null;
                            this.loading=false;
                        });
                }}else if(this.messagetype == "delCloud"){
                    if(val){
                        this.loading = true;
                        let id = this.o365AccountData[0].id;
                        O365Service.deleteO365OneDriveAccounts(id)
                        .then(() => {
                            this.loading = false;
                            this.showSuccessDialog();
                            var index = this.o365AccountData.map(x => { return x.Id; }).indexOf(id);
                            this.o365AccountData.splice(index, 1);
                        })
                        .catch(() => {
                            this.loading = false;
                            this.errorDialog = true;
                            this.errorMessage = i18n.t('GPT_CLOUD.CLOUDSYNC_ERROR');
                        });
                    }
                }
                this.messagetype = '';
            }, 
            async analysis_check(){
                await UploadStatusDataService.analysis_check().then((response)=>{
                    this.isAnalysising = response.data;
                });
            },
            onEmitImageDataDialog(action){
                if(action === 'delete'){
                    this.getImageAttributeData();
                }
                this.getImageAttributeData();
            },
            showImageDataTable(){
                this.imageDataDialog = true
                this.getImageAttributeHeader();
                this.getImageAttributeData();
            },
            showConnectCloudStorageDialog(){
                this.connectCloudStorageDialog = true
            },
            onDeleteO365Account(){
                this.showMessageDialog("delCloud",i18n.t('GPT_CLOUD.DELETE_CHECK'));
            },
            onCloudStorageAdd(){
                this.currentUrl = window.location.href

                O365Service.addO365OneDriveAccounts({'url':this.currentUrl.replace("#tab-6","")+"#tab-6"})
                .then(response => {
                    var url = response.data;
                    window.location.href = url;
                    setTimeout(()=>{
                        this.confirmCouldAccountDialog = true;
                    },2000)
                });
            },
            closeConnectCloudAccount(){
                this.confirmCouldAccountDialog = false;
                window.location.search='';
            },
            async getCustomerAttributeList(){
                await AiBotsService.field_list().then(response => 
                {
                    this.customerAttributeList = response.data
                    this.currentAttributeId = this.customerAttributeList[0]['id']
                })
            },
            async getImageAttributeHeader(){
                let data = {'currentObjectTypeId': this.currentAttributeId}
                await AiBotsService.get_image_attribute_header(data).then(response => 
                {
                    let array = [];
                    response.data.forEach(e => {
                        let item = {
                            text: e.name,
                            value: e.flexfield_column,
                            align: 'center',
                            sortable: false,
                            data_type: e.flexfield_datatype,
                        };
                        array.push(item)
                    })
                    array.sort((a,b)=> a.text.localeCompare(b.text))
                    array.sort((a,b)=> a.text.length - b.text.length)
                    let action = {
                        text: i18n.t('GENERAL.ACTIVE') ,
                        value: "action",
                        align: "center",
                        width: '150px',
                        sortable: false,
                        data_type: '',
                    };
                    array.push(action)

                    if(array.length >= 6){
                        array.forEach(e=>{
                            e.width =  '200px';
                        })
                    }
                    
                    this.imageAttributeHeader = array
                })

                
            },
            async getImageAttributeData(){
                let data = {'currentObjectTypeId': this.currentAttributeId}
                await AiBotsService.get_image_attribute_table(data).then(response => 
                {
                    this.imageAttributeTableData = response.data;
                    this.imageAttributeTablePage = this.calculatePages(this.imageAttributeTableData.length);
                    this.imageAttributeTableData.forEach(keyItem => {
                        Object.keys(keyItem).forEach(key => {
                            if(key.includes('flexfield_datetime') && keyItem[key]){
                                keyItem[key] = this.convertDateTime(keyItem[key]);
                            }
                        })   
                    })
                })
            },
            calculatePages(totalItems){
                if (totalItems <= 10) {
                    return 1;
                }
                return Math.ceil(totalItems / 10);
            },
            onDeleteCloudFile(item){
                this.selectedItems = JSON.parse(JSON.stringify(this.selectedItems)).filter(el=>el.id!=item.id);
            },
            getImageCheckboxesSet() {
                KnowledgeLibraryService.imagecheckboxSet(this.target_id).then(response => 
                {
                    this.checkboxesSetting = response.data;
                    
                    this.cloudDescribeImg = this.checkboxesSetting.cloud.describe_image_by_ai
                    this.cloudCustomerAttr = this.checkboxesSetting.cloud.save_customeized_attributes
                    this.cloudSearchImg = this.checkboxesSetting.cloud.image_search

                    this.folderDescribeImg = this.checkboxesSetting.folder.describe_image_by_ai
                    this.folderCustomerAttr = this.checkboxesSetting.folder.save_customeized_attributes
                    this.folderSearchImg = this.checkboxesSetting.folder.image_search

                    this.imgDescribeImg = this.checkboxesSetting.image.describe_image_by_ai
                    this.imgCustomerAttr = this.checkboxesSetting.image.save_customeized_attributes
                    this.imgSearchImg = this.checkboxesSetting.image.image_search
                })
            },
            async handleCheckboxChange(type){
                let data = {
                    'knowledge_lib_id':this.target_id,
                    'upload_type':type,  
                }
                if(type=='image'){
                    data.describe_image_by_ai = this.imgDescribeImg
                    data.save_customeized_attributes = this.imgCustomerAttr
                    data.image_search = this.imgSearchImg
                }else if(type=='folder'){
                    data.describe_image_by_ai = this.folderDescribeImg
                    data.save_customeized_attributes = this.folderCustomerAttr
                    data.image_search = this.folderSearchImg
                }else if(type=='cloud'){
                    data.describe_image_by_ai = this.cloudDescribeImg
                    data.save_customeized_attributes = this.cloudCustomerAttr
                    data.image_search = this.cloudSearchImg
                }

                await KnowledgeLibraryService.saveImagecheckboxSet(data).then(()=>{
                    // 
                })

            },
             // 日期轉型 YYYY-MM-DD
            convertDateTime(time){
                if(!time) return null;
                const date = new Date (time);
                return date.toISOString().split('T')[0];
            },
        },
        watch:{
            '$route': {
                handler: function(path) {
                    if(path.hash == "#tab-6"){
                        this.tab = "tab-6";
                    }
                },
                deep: true,
                immediate: true
            }
        }
	});
</script>

<style lang="css"> 
  #QAinput .v-input .v-input__control .v-text-field__details  .v-messages .v-messages__wrapper .v-messages__message{
    margin: 5px 0 0 7%;
  } 

  #Folderinput .v-input .v-input__control .v-text-field__details  .v-messages .v-messages__wrapper .v-messages__message{
    margin: 5px 0 0 7%;
  }

  #Fileinput .v-input .v-input__control .v-text-field__details  .v-messages .v-messages__wrapper .v-messages__message{
    margin: 5px 0 0 7%;
  }

  .setting-GPTinnerpage{
    max-width: 1000px;
    padding: 10px 20px;
    margin: 0 auto;
    height: calc(100% - 61px);
  }
  .openaicubeicon{
    background-color: white;
  }
  .openailogo{
    height: 24px;
    width: 24px;
    content: url('https://api.iconify.design/logos/openai-icon.svg');
  }
  .circle_fill_information{
    height: 25px;
    width: 25px;
    margin: 0 0 1% 2%;
    content: url('https://api.iconify.design/mdi/information-outline.svg?color=%23007bff');
  }
  #gptupload .v-window{
    border-radius: 6px !important;
  } 
  .upchatinboxbtn{
    color:#00aa60 !important;
    font-weight: 600 !important;
    background-color: white !important;
    border: 2px solid #00aa60 !important;
  }
  .dataTableColor:hover {
    background-color: rgba(180, 240, 255, 0.164) !important;
  }
</style>
<template lang="pug">
#PasswordAndLogin
    loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    h3.d-flex.align-center.setting-title.px-4.py-3
        .cube-icon.small.bg-primary.mr-3
            v-icon(size='19' color='white') icon-padlock
        span {{$t('SETTING.PASSWORDANDLOGIN')}}
    .setting-inner
        h4.text-h6.mb-2 {{$t('PASSWORDANDLOGIN.TITLE')}}
        p.text-gary.mb-8 {{$t('PASSWORDANDLOGIN.MESSAGE')}}
        p
            label {{$t('PASSWORDANDLOGIN.OLD_PASSWORD')}}
            v-text-field(v-model='password.current' type='password' solo dense)
        p
            label {{$t('PASSWORDANDLOGIN.NEW_PASSWORD')}}
            v-text-field(
                v-model='password.new' type='password' solo dense required  @blur='checkLength'
            )
            span(v-show='passwordCheck!=null&&passwordCheck===false' style='color:#ff0517').mt-2.lighten-2--text.text-caption {{$t('PASSWORDANDLOGIN.ERROR')}}
        p
            label {{$t('PASSWORDANDLOGIN.NEW_PASSWORD_2')}}
            v-text-field(
                v-model='password.confirm' type='password' solo dense required @blur='checkConfirm'
            )
            span(v-show='confirmPasswordCheck!=null&&confirmPasswordCheck===false' style='color:#ff0517').mt-2.lighten-2--text.text-caption {{$t('PASSWORDANDLOGIN.ERROR_2')}}
        v-row.mt-10.justify-center(no-gutters)
            v-col(cols='12' md='6')
                v-btn(v-hide-element='false' :ripple='false' width='100%' height='45' color='success' depressed dark @click='onPasswordChange()') {{$t('GENERAL.CONFIRM')}}
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitErrorDialog(true)')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
	import Vue from 'vue';
	import errorDialog from '@/components/Dialog/errorDialog';
    import successDialog from '@/components/Dialog/successDialog';
	import messageDialog from '@/components/Dialog/messageDialog';
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import AccountDataService from "../../services/AccountDataService";
    import i18n from '/common/plugins/vue-i18n.js' 

	export default Vue.extend({
        components: {
            loadingOverlay,
            errorDialog,
            messageDialog,
            successDialog
        },
        props: {},
        data() {
            return {
                password: {
                    current: '',
                    new: '',
                    confirm: '',
                },
                messageDialog: false,
                successDialog: false,
                errorDialog: false,
                message: '',
                errorMessage: '',
                loading: false,
                passwordCheck:null,
                confirmPasswordCheck:null,
                haveChange: false    
            }
        },
        methods: {
            checkLength(){
                const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,30}/);
                this.passwordCheck = regex.test(this.password.new)
            },
            checkConfirm(){
                if(this.passwordCheck===true&&this.password.confirm===this.password.new)
                {
                    this.confirmPasswordCheck = true
                }
                else{
                    this.confirmPasswordCheck = false
                    }
            },
            async onPasswordChange() {
                if (!this.password.current)
                {
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('PASSWORDANDLOGIN.ERROR_3');
                    return false;
                }
                if (!this.password.new || !this.password.confirm)
                {
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('PASSWORDANDLOGIN.ERROR_4');
                    return false;
                }
                if (this.password.new != this.password.confirm)
                {
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('PASSWORDANDLOGIN.ERROR_7');
                    return false;
                }
                if(this.passwordCheck!=true&&this.confirmPasswordCheck!=true)
                {
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('PASSWORDANDLOGIN.ERROR_5');
                    return false;
                }
                var data = { 'old_password': this.password.current, 'new_password': this.password.new };
                this.loading = true;
                
                await AccountDataService.changePassword(data)
                .then(() => {
                    this.loading = false;
                    this.successDialog = true;
                })
                .catch(() => {
                    this.loading = false;
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('PASSWORDANDLOGIN.ERROR_6');
                });
                this.password = { current: '', new: '', confirm: '' };
                this.haveChange = true
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
        },
        watch:{
            "password.new":function(e){
                const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,30}/);
                this.passwordCheck = regex.test(e)
                // if(e.length>=8 && e.length<=30){
                //     this.passwordCheck = true
                // }else
                // {
                //     this.passwordCheck = false
                // }
                if(this.haveChange)
                {
                   this.passwordCheck = null
                }
            },
            "password.confirm":function(e){
                if(this.passwordCheck===true&&e===this.password.new){
                    this.confirmPasswordCheck = true                    
                }else
                {
                    this.confirmPasswordCheck = false
                }
                if(this.haveChange)
                {
                   this.confirmPasswordCheck = null 
                   this.haveChange = false
                }                
            },
        }
	});
</script>
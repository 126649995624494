<template lang="pug">
    v-card.caseDialog
        v-card-title(class='d-flex justify-center') {{$t('ACTIVITY.ADD')}}
        v-card-text
            v-form(v-model='valid' ref='form')  
                v-text-field.mb-4(v-model='addActivity.subject' :label=`$t('ACTIVITY.SUBJECT')` :rules='formRules.nameRule' required='required' large='large' validate-on-blur)
                //- v-row(no-gutters)
                //-     v-chip(class='mr-2 mt-2' :color='addActivity.activity_type == e? e.color : ""' v-for='e, index in activityType' :key='e.id' @click='changeType(e)')
                //-         v-icon(left) {{ e.icon }}
                //-         span {{ e.name }}
                v-row(no-gutters)
                    v-col(cols='12' :class='typeMenu ? "" : "ellipsis"')
                        v-chip(class='mr-2 mt-2' :color='addActivity.activity_type == e? e.color : ""' v-for='e, index in activity_type_list' :key='e.id' @click='changeType(e)')
                            v-icon(left) {{ e.icon }}
                            span {{ e.name }}
                    v-btn.pa-0(@click='changeMenu()' color='primary' text) 
                        v-icon {{typeMenu == true ? "mdi-chevron-up" : "mdi-chevron-down" }}
                        span {{typeMenu == true ? $t('CASE_DETAIL.COLLAPSE') : $t('CASE_DETAIL.EXPAND') }}
                v-row(no-gutters class='mt-2')
                    v-col(col)
                        .t-black.padding-top-9 {{$t('ACTIVITY.START_TIME')}} :
                    v-col(cols='3')
                        v-menu(ref="menuStart" v-model="startDayMenu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                            template(v-slot:activator="{ on, attrs }")
                                v-text-field.no-border.height-48(v-model='addActivity.start_date' readonly solo dense flat v-bind="attrs" v-on="on" :change='toDateCheck(addActivity)')
                            date-picker(v-model='addActivity.start_date' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange")
                    v-col(col class='d-flex align-center justify-center')
                        p.ma-0.pb-2.pr-3 — 
                    v-col(col)
                        .t-black.padding-top-9 {{$t('ACTIVITY.DUE_TIME')}} :
                    v-col(cols='3')
                        v-menu(ref="menuEnd" v-model="endDayMenu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                            template(v-slot:activator="{ on, attrs }")
                                v-text-field.no-border.height-48(v-model='addActivity.due_date' readonly solo dense flat v-bind="attrs" v-on="on")
                            date-picker(v-model='addActivity.due_date' :disabled-date="notBeforeStartDay" :disabled-time="notBeforeStartTime" type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange")
                v-row(no-gutters)
                    v-col(cols="6")
                        v-select.no-border.height-48(v-model='addActivity.priority' solo :items='activityPriorityList' prepend-icon='mdi-flag-variant-outline' item-text='name' item-value='key' :label= `$t('ACTIVITY.PRIORITY')`)
                    v-col(cols="6")
                        //- v-text-field.no-border.height-48(v-model.number='addActivity.progress' type='number' :label=`$t('ACTIVITY.PROGRESS')` large="large" :rules='formRules.progressRules' prepend-icon='mdi-percent-outline')
                        //- v-progress-linear(v-model="addActivity.progress" :color="addActivity.activity_type.color" height="10" stream buffer-value="0" )
                            //- template(v-slot='value')
                            //-     span {{ Math.ceil(addActivity.progress) }}
                        v-slider.pt-2(v-model="addActivity.progress" :label=`$t('ACTIVITY.PROGRESS')` :color="addActivity.activity_type.color" :track-color="addActivity.activity_type.color" :thumb-color="addActivity.activity_type.color" thumb-label="always" thumb-size="24")
                v-textarea.textarea-style.no-border(v-model='addActivity.remark' :label=`$t('ACTIVITY.REMARK')`, prepend-icon='mdi-calendar-text' large='large')
                v-combobox.no-border.height-48(v-model='addActivity.owner' :items='ownerList' item-text='full_name' item-value="id" :loading="isLoading"  prepend-icon='mdi-account' hide-details="auto" :label=`$t('ACTIVITY.OWNER')`)
                //- v-combobox.no-border.height-48(v-model='addActivity.regarding_case' :items='activityCaseList' item-text='subject' item-value="id" :loading="case_list_loading" :search-input.sync="searchCase" prepend-icon='mdi-link' hide-details="auto" :label=`$t('ACTIVITY.REGARDING')` :rules='formRules.companyRule')
                //- v-combobox.no-border.height-48(v-model='addActivity.regarding_oppty' :items='activityOpptyList' item-text='name' item-value="id" :loading="oppty_list_loading" :search-input.sync="searchOppty" prepend-icon='mdi-link' hide-details="auto" :label=`$t('ACTIVITY.REGARDING')` :rules='formRules.companyRule')
                v-combobox.no-border.height-48(v-model='addActivity.regarding' :items='activityRegardingList' item-text='list_name' item-value="id" :loading="regarding_list_loading" :search-input.sync="searchRegarding" prepend-icon='mdi-link' hide-details="auto" :label=`$t('ACTIVITY.REGARDING')`)
                    template(v-slot:no-data)
                        v-list-item()
                            span {{ isWiadvance() ? $t("ACTIVITY.WIADVANCE_NO_REGARDING") : $t("ACTIVITY.NO_REGARDING")}}
                    template(v-slot:item='data')
                        div.d-flex.ellipsis(:title='data.item.list_type == "case" ? data.item.subject + " [ " + $t("CASE.TITLE")+" ]":data.item.name + " [ " + $t("DEAL.TITLE")+" ]"')
                            .t-black.ml-2.ellipsis(v-if='data.item.list_type == "case"' style='max-width:400px;') {{ data.item.subject }}
                            .t-black.ml-2.ellipsis(v-else style='max-width:400px;') {{ data.item.name }}
                            .t-black.ml-2 [ {{ data.item.list_type == "case" ? $t('CASE.TITLE') : $t('DEAL.TITLE') }} ]
                    template(v-slot:selection='data')
                        div.d-flex.ellipsis(:title='data.item.list_type == "case" ? data.item.subject + " [ " + $t("CASE.TITLE")+" ]":data.item.name + " [ " + $t("DEAL.TITLE")+" ]"')
                            .t-black.ellipsis(v-if='data.item.list_type == "case"' style='max-width:450px;' ) {{ data.item.subject }}
                            .t-black.ellipsis(v-else-if='data.item.list_type == "oppty"' style='max-width:450px;' ) {{ data.item.name }}
                            .t-black.ml-2(v-if='data.item.list_type == "case"') [ {{ $t('CASE.TITLE') }} ]
                            .t-black.ml-2(v-else-if='data.item.list_type == "oppty"') [ {{ $t('DEAL.TITLE') }} ]
                v-combobox.no-border.height-48(v-model='addActivity.activity_contact' :items='activityContactList' item-text='full_name' item-value="id" :loading="contact_list_loading" :search-input.sync="searchContact" prepend-icon='mdi-account' hide-details="auto" :label=`$t('ACTIVITY.CONTACT')`)
                    template(v-slot:no-data)
                        v-list-item()
                            span {{$t("ACTIVITY.NO_CONTACT")}}
                    template(v-slot:item='data')
                        v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                            v-icon(v-if="data.item.image == null") mdi-account
                            img(v-else :src='data.item.image')
                        .t-black {{ data.item.full_name }}
                        .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                    template(v-slot:selection='data')
                        .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                v-combobox.no-border.height-48(v-model='addActivity.activity_org' :items='activityOrgList' item-text='name' item-value="id" :loading="org_list_loading" :search-input.sync="searchOrg" prepend-icon='mdi-domain' hide-details="auto" :label=`$t('ACTIVITY.ORG')`)
                    template(v-slot:no-data)
                        v-list-item()
                            span {{$t("ACTIVITY.NO_ORG")}}
                    template(v-slot:selection='data')
                        .t-black.ellipsis(:title='data.item.name') {{ data.item.name }}
        v-card-actions.px-2.py-4
            v-row
                v-col.mt-0(cols='4')
                    v-row.mt-0
                        v-checkbox.mt-0.no-border(v-model='addActivity.completed' , no-resize )
                        span.mt-1 {{$t('ACTIVITY.MARK_COMPLETED')}}
                v-col.pr-2(cols='4')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='4')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed :disabled='saving')
                        v-progress-circular(v-if='saving' indeterminate size='20')
                        .t-white(v-else) {{$t('GENERAL.ADD')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import errorDialog from "@/components/Dialog/errorDialog";
    import i18n from '/common/plugins/vue-i18n.js';
    import DatePicker from 'vue2-datepicker';
    // list
    import activityPriorityList from"@/array/the_activityPriorityList";
    import ContactDataService from "@/services/ContactDataService";
    import OrganizationDataService from "@/services/OrganizationDataService";
    import CaseDataService from "@/services/CaseDataService";
    import OpportunityDataService from "@/services/OpportunityDataService";
    import ActivityDataService from '../../../services/ActivityDataService';

    export default Vue.extend({
        props: {
            addActivity: {
                type: Object,
                required: true,
            }, 
            activityType: {
                type: Array,
                required: true,
            },
            ownerList: {
                type: Array,
                required: true,
            }, 
            newDateTime: {
                type: Object,
                required: false,
            }, 
            regarding_data: {
                type: Object,
                required: false,
            }, 
            from_page: {
                type: String,
                required: false,
            },
            activity_org: {
                type: Object,
                require: false,
            },
            activity_contact: {
                type: Object,
                require: false,
            }, 
        },
        components: {
            errorDialog,
            DatePicker,
        },
        data() {
            return {
                saving: false,
                isLoading: false,
                valid: true,
                formRules: {
                    nameRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_TITLE'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    dateRule: [(v) => !!v || i18n.t('RULE.RULE_DUE_DATE'),],
                    stageTypeRule: [(v) => !!v || i18n.t('RULE.RULE_PROCCESS')],
                    forecastRule: [(v) => !!v || i18n.t('RULE.RULE_FORECAST')],
                    contactRules: [(v) => !!v || i18n.t('RULE.RULE_R_Case_Contact')],
                    phoneRules: [],
                    emailRules: [
                        (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    companyRule: [
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
                    ],
                    contactNameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    progressRules: [
                        (v) => /^([0-9]|[1-9][0-9]|100)$/.test(v) || i18n.t('RULE.RULE_PROGRESS'),
                    ],
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                    // NEW
                    hhmmRule: [
                        (v) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || i18n.t('RULE.RULE_HHMM')
                    ]
                },
                comboText: '',
                search: null,
                menu: false,
                isNewContact: false,
                newContact: {
                    last_name: '',
                    first_name: ''
                },
                errorDialog: false,
                errorMessage: '',
                contactSearch: null,
                user: JSON.parse(window.localStorage.getItem('user')),
                search_parent_case : null,
                startDayMenu: false,
                endDayMenu: false,
                startDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)+" 09:00",
                endDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)+" 18:00",
                startDayTime: '09:00',
                endDayTime: '18:00',
                // list
                activity_type_list: [],
                activityPriorityList: activityPriorityList,
                activityContactList:[],
                activityOrgList:[],
                activityCaseList:[],
                activityOpptyList:[],
                activityRegardingList:[],
                searchContact:'',
                searchOrg:'',
                searchCase:'',
                searchOppty:'',
                searchRegarding:'',
                contact_list_loading:false,
                org_list_loading:false,
                case_list_loading:false,
                oppty_list_loading:false,
                regarding_list_loading:false,
                comboInput_case : null,
                comboInput_oppty : null,
                comboInput_regarding :null,
                typeMenu:false,
            }
        },
        created() {
            this.activity_type_list = this.activityType;
            if(this.addActivity.start_date == null){
                this.addActivity.start_date = this.startDay;
                this.addActivity.due_date = this.startDay;
            }
            this.addActivity.due_date = this.endDay;
            let start = new Date(this.addActivity.start_date);
            let end = new Date(this.addActivity.due_date);
            if(start > end){
                this.addActivity.due_date = this.addActivity.start_date;
            }
            if(this.newDateTime){
                this.addActivity.start_date = this.newDateTime.startDate;
                this.addActivity.due_date = this.newDateTime.endDate;
                this.startDayTime = this.newDateTime.startTime;
                this.endDayTime = this.newDateTime.endTime;
            }
            if(this.from_page){
                switch(this.from_page){
                    case "contact" :
                        this.addActivity.activity_contact = this.regarding_data;
                        break;
                    case "org" :
                        this.addActivity.activity_org = this.regarding_data;
                        break;
                    case "case" :
                        this.addActivity.regarding = this.regarding_data;
                        this.addActivity.activity_org = this.activity_org;
                        this.addActivity.activity_contact = this.activity_contact;
                        break;
                    case "oppty" :
                        this.addActivity.regarding = this.regarding_data;
                        this.addActivity.activity_org = this.activity_org;
                        this.addActivity.activity_contact = this.activity_contact;
                        break;
                    default:
                        break;
                }
            }
        },
        methods: {
            notBeforeStartDay(date) {
                const start = new Date(this.addActivity.start_date);
                start.setHours(0, 0, 0, 0);
                return date < start;
            },
            notBeforeStartTime(date) {
                const start = new Date(this.addActivity.start_date).getTime();
                return date.getTime() < start;
            },
            datepickerChange(value, type){
                if (type === 'minute') {
                    this.startDayMenu = false;
                    this.endDayMenu = false;
                }
            },
            minDate(date){
                let current_date = new Date(date).getTime();
                let min = current_date;
                return (new Date(min).getFullYear()).toString() +'-' +(new Date(min).getMonth()+1).toString().padStart(2,0)+'-'+ (new Date(min).getDate()).toString().padStart(2,0)+" "+(new Date(min).getHours()).toString().padStart(2,0)+":"+(new Date(min).getMinutes()).toString().padStart(2,0)
            },
            toDateCheck(item){
                if(item.start_date){
                    let FD = new Date(item.start_date).getTime();
                    let TD = new Date(item.due_date).getTime();
                    if(item.due_date && (TD-FD)<=0){
                        item.due_date = this.minDate(item.start_date);
                    }
                }
            },
            validateForm(){
                if(this.$refs.form.validate()){
                    return true;
                }
                else{
                    return false;
                }
            },
            changeMenu(){
                this.typeMenu = !this.typeMenu;
            },
            changeType(e){
                this.addActivity.activity_type = e;
                this.activity_type_list.map((item,index)=>{
                    if(item == e){
                        this.activity_type_list.splice(index,1);
                        this.activity_type_list.unshift(e);
                    }
                })
            },
            async onConfirm() {
                if(this.validateForm()){
                    this.saving = true;
                    let addData = {
                        subject: this.addActivity.subject,
                        start_date: this.addActivity.start_date,
                        due_date: this.addActivity.due_date,
                        completed : this.addActivity.completed == true ? "COMPLETED": "UNCOMPLETED",
                        priority : this.addActivity.priority,
                        progress : this.addActivity.progress,
                        remark: this.addActivity.remark,
                        activity_type: this.addActivity.activity_type,
                        owner: this.addActivity.owner,
                        activity_contact_id: this.addActivity.activity_contact == null || this.addActivity.activity_contact.id == undefined ? null : this.addActivity.activity_contact.id,
                        activity_org_id: this.addActivity.activity_org == null || this.addActivity.activity_org.id == undefined ? null : this.addActivity.activity_org.id,
                        regarding_case:[],
                        regarding_oppty:[]
                    }
                    if(this.addActivity.regarding && typeof(this.addActivity.regarding) != 'string'){
                        if(this.addActivity.regarding.list_type == 'case'){
                            addData.regarding_case = [
                                {
                                    id:this.addActivity.regarding.id,
                                    subject:this.addActivity.regarding.subject
                                }
                            ]
                            addData.regarding_oppty = [];
                        }else{
                            addData.regarding_oppty = [
                                {
                                    id:this.addActivity.regarding.id,
                                    name:this.addActivity.regarding.name
                                }
                            ]
                            addData.regarding_case = [];
                        }
                    }
                    ActivityDataService.addActivity(addData)
                    .then(()=>{
                        this.$emit('emitAddActivityDialog','add');
                        this.saving = false;
                    })
                }
            },
            onCancel() {
                this.$emit('emitAddActivityDialog',false);
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
        },
        computed: {
            isNew: function(){
                if(this.addActivity.case_org == " " || this.addActivity.case_org == null){
                    return false;
                }
                if(typeof (this.addActivity.case_org) === 'object'){
                    if(this.addActivity.case_org.id == 0){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    return true;
                }
            },
        },
        watch: {
            "addActivity.progress":function(){
                this.addActivity.progress = Math.ceil(this.addActivity.progress);
            },
            async searchOrg(val) {
                var re = /^(?!\s*$).+/;
                var check = re.test(val);
                var checkLength = /^.{0,200}$/.test(val);
                this.comboText = "";
                if(check && val != null && checkLength){
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    this.comboText = val;
                    if (!this.isLoading && charNum>=2 && charNum==val.length ) {
                        this.org_list_loading = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val,true)
                        .then(response => { 
                            this.activityOrgList = response.data; 
                            let num = 0
                            this.activityOrgList.forEach((item)=>{
                            item.uqNumber = num
                            num ++
                            })
                        })
                        .finally(() => (this.org_list_loading = false));
                    }
                    else {
                        this.org_list_loading = false;
                    }
                }
            },
            searchContact(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString){
                    this.contact_list_loading = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val)){
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.activityContactList = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.contact_list_loading = false;
                    })
                }
                else{
                    this.activityContactList = [];
                }
            },
            async searchRegarding(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array_case = [];
                let array_oppty = [];
                // search
                if(isEnZhNum && !!val && isString) {
                    this.regarding_list_loading = true;
                    this.comboInput_regarding = val;

                    await CaseDataService.kanabn('list', 'all', 0, 1, val,9999,'','')
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.list_type = "case";
                            item.list_name = item.subject;
                            array_case.push(item);
                        })
                        this.activityRegardingList = this.activityRegardingList.concat(array_case)
                    })

                    await OpportunityDataService.list('list', 'all', 0, 1, val, 50, [],'')
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.list_type = "oppty";
                            item.list_name = item.name;
                            array_oppty.push(item);
                        })
                        this.activityRegardingList = this.activityRegardingList.concat(array_oppty);
                    })
                    .finally(() => {
                        this.regarding_list_loading = false;
                    })
                }
                else{
                    this.comboInput_regarding = null
                }
            },
        },
    });
</script>
<template lang='pug'>
v-app#app(v-if='route.query.next && route.query.next.includes("/upmeeting") || route.path.includes("/upmeeting")')
    v-main(class="v-main_meeting" )
        router-view(@loginSuccess='loginSuccess')
v-app#app(v-else-if='!route.path.toLowerCase().includes("/upgpt")')
    NoticeBoard(v-if='member.userid != "" && noticeBoard_isclose == false && $route.meta.showMenu != false')
    MenuBar(@logoutSuccess='logoutSuccess' :member='member' v-if='$route.meta.showMenu != false')
    v-main(:class="route.path === '/login' ? 'login-page':''" v-if='$route.meta.showMenu != false')
        router-view(@loginSuccess='loginSuccess')
    v-main(:class="route.path === '/login' ? 'login-page':'v-main_empty'" v-else)
        router-view(@loginSuccess='loginSuccess')
v-app#app(v-else)
    MenuBarGpt(@logoutSuccess='logoutSuccess' :member='member' v-if='$route.meta.showMenu != false')
    v-main.v-main-gpt(:class="route.path === '/login' ? 'login-page':''" v-if='$route.meta.showMenu != false')
        router-view(@loginSuccess='loginSuccess')
</template>
<script>
import NoticeBoard from '@/layout/NoticeBoard.vue';
import MenuBar from '@/layout/MenuBar.vue';
import MenuBarGpt from '@/layout/MenuBarGpt.vue';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'App',
    components: {
        NoticeBoard,
        MenuBar,
        MenuBarGpt
    },
    data() {
        return {
            member: this.getLoginUserInfo(),
            noticeBoard_isclose: this.checkNoticeIsClosed()
        };
    },
    computed: {
        route() {
            return this.$route;
        },
        ...mapState(['isLoggedIn']),
    },
    methods: {
        handleLogout(event) {
            event.preventDefault();
            this.logout();
            this.$router.push('/');
        },
        loginSuccess() {
            this.member = this.getLoginUserInfo();
        },
        logoutSuccess() {
            this.member = this.getLoginUserInfo();          
        },
        getLoginUserInfo() {
            var user = window.localStorage.getItem('user');
            let userObj = null;
            if (user == null || user == undefined) 
                userObj = { userid: '' };
            else {
                let obj = JSON.parse(user);
                
                userObj = {
                    userid: obj.userid,
                    name: [obj.last_name, obj.first_name].join(' '),
                    pic: obj.pic? obj.pic:'',
                    nickname: [obj.last_name.charAt(0), obj.first_name.charAt(0)].join(''),
                    is_service_agent: obj.is_service_agent,
                    is_tech_user: obj.is_tech_user,
                    is_view_only_user: obj.is_view_only_user,
                    status: obj.status
                }
                this.$isViewOnly.isViewOnly = obj.is_view_only_user;
            }
            return userObj;
        },
        checkNoticeIsClosed() {
            this.noticeBoard_isclose = true
            if(window.localStorage.getItem('NoticeBoard_IsClose') == null){
                return false;
            }
            return window.localStorage.getItem('NoticeBoard_IsClose');
        },  
        ...mapActions(['logout']),
    },
    watch :{
        $route (to, from){
            if(from.fullPath == "/UpChat"){
                document.body.classList.remove('chat_hide_scroll_bar')
            }
        }
    }
};
</script>

<style lang="scss">
@import '~normalize.css/normalize.css';
@import 'style/allview'; //共用的all.scss
</style>
import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const contactsFieldList = [
  { key:'created_on', name: i18n.t('CONTACTSFIELDLIST.created_on'),type:'date'},
	{ key:'created_by', name: i18n.t('CONTACTSFIELDLIST.created_by'),type:'user'},
	{ key:'updated_on', name: i18n.t('CONTACTSFIELDLIST.updated_on'),type:'date'},
	{ key:'updated_by',name: i18n.t('CONTACTSFIELDLIST.updated_by'),type:'user'},
	{ key:'owner_name',name: i18n.t('CONTACTSFIELDLIST.owner'),type:'user'},
	{ key:'org_name',name: i18n.t('CONTACTSFIELDLIST.orgs'),type:'fk_org'},
	{ key:'full_name',name: i18n.t('CONTACTSFIELDLIST.full_name'),type:'text'},
	{ key:'english_full_name',name: i18n.t('CONTACTSFIELDLIST.english_full_name'),type:'text'},
	{ key:'english_title',name: i18n.t('CONTACTSFIELDLIST.english_title'),type:'text'},
	{ key:'english_department',name: i18n.t('CONTACTSFIELDLIST.english_department'),type:'text'},
	{ key:'invoice_recieved',name: i18n.t('CONTACTSFIELDLIST.invoice_recieved'),type:'bol'},
	{ key:'mailing_address',name: i18n.t('CONTACTSFIELDLIST.mailing_address'),type:'text'},
	{ key:'total_amount',name: i18n.t('CONTACTSFIELDLIST.total_amount'),type:'num'},
	{ key:'title',name: i18n.t('CONTACTSFIELDLIST.title'),type:'text'},
	{ key:'email',name: i18n.t('CONTACTSFIELDLIST.email'),type:'text'},
	{ key:'mobile_phone',name: i18n.t('CONTACTSFIELDLIST.mobile_phone'),type:'text'},
	{ key:'office_phone',name: i18n.t('CONTACTSFIELDLIST.office_phone'),type:'text'},
	{ key:'description',name: i18n.t('CONTACTSFIELDLIST.description'),type:'text'},
	{ key:'department',name: i18n.t('CONTACTSFIELDLIST.department'),type:'text'},
	{ key:'birthdate',name: i18n.t('CONTACTSFIELDLIST.birthdate'),type:'date'},
	{ key:'level',name: i18n.t('CONTACTSFIELDLIST.level'),type:'opt'},
	{ key:'fax',name: i18n.t('CONTACTSFIELDLIST.fax'),type:'text'},
	{ key:'facebook',name: i18n.t('CONTACTSFIELDLIST.facebook'),type:'text'},
	{ key:'linkedin',name: i18n.t('CONTACTSFIELDLIST.linkedin'),type:'text'},
	{ key:'instagram_id',name: i18n.t('CONTACTSFIELDLIST.instagram_id'),type:'text'},
	{ key:'line_id',name: i18n.t('CONTACTSFIELDLIST.line_id'),type:'text'},
	{ key:'billing_address',name: i18n.t('CONTACTSFIELDLIST.billing_address'),type:'text'},
	{ key:'city',name: i18n.t('CONTACTSFIELDLIST.city'),type:'text'},
	{ key:'country',name: i18n.t('CONTACTSFIELDLIST.country'),type:'text'},
	{ key:'zip_code',name: i18n.t('CONTACTSFIELDLIST.zip_code'),type:'text'},
	{ key:'province',name: i18n.t('CONTACTSFIELDLIST.province'),type:'text'},
	{ key:'primary_campaign_id',name: i18n.t('CONTACTSFIELDLIST.primary_campaign_id'),type:'fk_cam'},
	{ key:'segment',name: i18n.t('CONTACTSFIELDLIST.seg'),type:'fk_seg'}
];
export default contactsFieldList;
import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"
}
i18n.locale = lang

const paymentTermsList =  [
    { key: '0', name: i18n.t('PAYMENT_TERM.PAYMENT_WITHIN_30D') },
    { key: "1", name: i18n.t('PAYMENT_TERM.PAYMENT_WITHIN_30D2PERCENT') },
    { key: "2", name: i18n.t('PAYMENT_TERM.PAYMENT_WITHIN_45D') },
    { key: "3", name: i18n.t('PAYMENT_TERM.PAYMENT_WITHIN_60D') },
    { key: "4", name: i18n.t('PAYMENT_TERM.PAYMENT_WITHIN_90D') },
];

export default paymentTermsList;
import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const nameList = ['大聯大集團','中華電信集團','台塑集團','台達電集團','李長榮集團','佳世達集團','東元集團',
'冠德建設集團','強茂集團','華新麗華集團','微軟集團','慈濟財團法人','新光集團','臺灣大學醫學院','緯創集團','聯強集團']

const belongGroupList =  nameList.map(el => {return {key:el,name:el}});

export default belongGroupList
;
import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const orderShippingMethodsList =  [
    { key: "air_freight", name: i18n.t('SHIPPINH_METHODS.AIR_FREIGHT') },
    { key: "DHL", name: 'DHL' },
    { key: "FedEx", name: 'FedEx' },
    { key: "UPS", name: 'UPS' },
    { key: "mail_delivery", name: i18n.t('SHIPPINH_METHODS.MAIL_DELIVERY') },
    { key: "full_load", name: i18n.t('SHIPPINH_METHODS.FULL_LOAD') },
    { key: "pick_up", name: i18n.t('SHIPPINH_METHODS.PICK_UP') },
];

export default orderShippingMethodsList;
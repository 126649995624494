import { authRequest,searchString } from './api/auth';

class ProductDataService {
    list(page,search,pageitem,sortby,type) {
        search = searchString(search)
        return authRequest.get(`/product/?page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}&type=${type}`);
    }
    create(data) {
        return authRequest.post(`/product/`,data);
    }
    edit(data) {
        return authRequest.put(`/product/${data.id}/`,data);
    }   
    delete(id) {
        return authRequest.delete(`/product/${id}/`);
    } 

    productcategorylist() {
        return authRequest.get(`/product/productcategory`);
    }
    productcategorycreate(data) {
        return authRequest.post(`/product/productcategory`,data);
    }
    productcategoryedit(data) {
        return authRequest.put(`/product/productcategory/${data.id}/`,data);
    }   
    productcategorydelete(id) {
        return authRequest.delete(`/product/productcategory/${id}/`);
    }

    productserieslist() {
        return authRequest.get(`/product/productseries`);
    }
    productseriescreate(data) {
        return authRequest.post(`/product/productseries`,data);
    }
    productseriesedit(data) {
        return authRequest.put(`/product/productseries/${data.id}/`,data);
    }   
    productseriesdelete(id) {
        return authRequest.delete(`/product/productseries/${id}/`);
    } 
}

export default new ProductDataService();

import axios from 'axios';
import i18n from '/common/plugins/vue-i18n.js' 
import { headerWithLang } from './lang';

// this base url will be change based on
// if you need to point to production.
const PUBLIC_URL = process.env.VUE_APP_API_BASE_URL;
const BASE_URL = PUBLIC_URL + '/tenant';
const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const TENANT = 'tenant';
const USER = 'user';
const UPCHAT_LOGIN = 'upchat_login';
const NOTICEBOARD_ISCLOSE = 'NoticeBoard_IsClose';

const tokenRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

const publicRequest = axios.create({
  baseURL: PUBLIC_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

const loginUser = (username, password, tenant, loginValiCode) => {
  //debugger;
  const loginBody = { username, password, loginValiCode };
  // let loginUrl = tokenRequest.defaults.baseURL.includes('api') ? '/token' : `${tenant}/api/token`;
  let loginURL = `/${tenant}/api/token`;
  return tokenRequest.post(loginURL, loginBody)
    .then((response) => {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access);
      window.localStorage.setItem(REFRESH_TOKEN, response.data.refresh);
      window.localStorage.setItem(TENANT, tenant);
      const upchat_url = response.data.upchat_dashboard;
      let upchat_host = "";
      if (upchat_url != "" && upchat_url != null && upchat_url != undefined) {
        const { origin } = new URL(upchat_url);
        upchat_host = origin;
      }
      var user = { 
        userid: response.data.userid,
        pic: response.data.pic,
        username: response.data.username,
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        email: response.data.email,
        change_password: response.data.change_password,
        language: response.data.language,
        location: response.data.location,
        is_service_agent: response.data.is_service_agent,
        is_tech_user: response.data.is_tech_user,
        is_view_only_user: response.data.is_view_only_user,
        status: response.data.status,
        is_staff: response.data.is_staff,
        fcm_token: response.data.fcm_token,
        default_value: response.data.default_value,
        upchat_sso_link: response.data.upchat_sso_link,
        upchat_dashboard: response.data.upchat_dashboard,
        upchat_host: upchat_host,
        upgpt:response.data.upgpt,
      };
      window.localStorage.setItem('isUpchatUser', response.data.upchat);
      window.localStorage.setItem('isUpGPTUser', response.data.upgpt);
      window.localStorage.setItem(UPCHAT_LOGIN, false);
      window.localStorage.setItem(USER, JSON.stringify(user));
      window.localStorage.setItem('language', response.data.language);
      window.localStorage.setItem('location', response.data.location); 
      window.localStorage.setItem('is_staff', response.data.is_staff);     
      window.localStorage.setItem('fcm_token', response.data.fcm_token);
      i18n.locale = response.data.language
      // set tenant base url for authRequest
      // authRequest.defaults.baseURL = BASE_URL + `/${tenant}/api`;
      // tokenRequest.defaults.baseURL = BASE_URL + `/${tenant}/api`;
      return Promise.resolve(response.data);
    }).catch((error) => {
      return Promise.reject(error);
    });
};

// login for UpSide
const loginUserUpSide = (username, password, tenant, loginValiCode) => {
  const loginBody = { username, password, loginValiCode };
  let loginURL = `/${tenant}/api/token`;
  return tokenRequest.post(loginURL, loginBody)
    .then((response) => {
      i18n.locale = response.data.language
      return Promise.resolve(response.data);
    }).catch((error) => {
      return Promise.reject(error);
    });
};

const refreshToken = () => {
  //debugger;
  const refreshBody = { refresh: window.localStorage.getItem(REFRESH_TOKEN) };
  const tenant = window.localStorage.getItem(TENANT);
  const refreshURL = `/${tenant}/api/token/refresh`;
  return tokenRequest.post(refreshURL, refreshBody)
    .then((response) => {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access);
      return Promise.resolve(response.data);
    }).catch((error) => Promise.reject(error));
};

const isCorrectRefreshError = (status) => status === 401;

/*
 * authRequest
 *
 * This refreshes the request and retries the token if it is invalid.
 * This is what you use to create any requests that need the Tokens.
 * Reference: https://hackernoon.com/110percent-complete-jwt-authentication-with-django-and-react-2020-iejq34ta
 *
 * Example:
 *     authRequest.get('/path/to/endpoint/',extraParameters)
 *        .then(response=>{
 *          // do something with successful request
 *        }).catch((error)=> {
 *          // handle any errors.
 *        });
*/
const authRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: headerWithLang(),
});

const logoutUser = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
  window.localStorage.removeItem(TENANT);
  window.localStorage.removeItem(USER);
  window.localStorage.removeItem(NOTICEBOARD_ISCLOSE);
  window.localStorage.removeItem('isUpchatUser');
  window.localStorage.removeItem('isUpGPTUser');
  window.localStorage.removeItem(UPCHAT_LOGIN);
  authRequest.defaults.headers.Authorization = '';
};

const errorInterceptor = (error) => {
  const originalRequest = error.config;
  if (error.response != null) {
    const { status } = error.response;
    if (isCorrectRefreshError(status)) {
      return refreshToken().then(() => {
        const headerAuthorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`;
        authRequest.defaults.headers.Authorization = headerAuthorization;
        originalRequest.headers.Authorization = headerAuthorization;
        return authRequest(originalRequest);
      }).catch((tokenRefreshError) => {
        let tenant = window.localStorage.getItem('tenant');
        // if token refresh fails, logout the user to avoid potential security risks.
        logoutUser();
        if (tenant != null || tenant != '')
          window.location.href = `/login?tenant=${tenant}`;
        else
          window.location.href = '/login';
        return Promise.reject(tokenRefreshError);
      });
    }  
  }
  return Promise.reject(error);
};

// add tenant to url for all requests
authRequest.interceptors.request.use(
  function (config) {
    const tenant = window.localStorage.getItem(TENANT);   
    const requestURL = config.url.charAt(0) == '/' ? config.url.slice(1) : config.url; 
    config.url = config.url.includes('api') ? config.url : `/${tenant}/api/${requestURL}`;
    config.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`;
    return config;
  }  
);

authRequest.interceptors.response.use(
  (response) => response, // this is for all successful requests.
  (error) => errorInterceptor(error), // handle the request
);

const unAuthenticated = () => (!(ACCESS_TOKEN in window.localStorage)
    && !(REFRESH_TOKEN in window.localStorage));

const searchString = function(val){
  if(typeof(val)==="string"){
      return encodeURIComponent(val);
  }else{
      return val;
  }
}

export {
  tokenRequest, publicRequest, loginUser,loginUserUpSide, logoutUser, refreshToken, authRequest,
  errorInterceptor, BASE_URL, ACCESS_TOKEN, REFRESH_TOKEN, unAuthenticated,searchString
};


import { authRequest } from './api/auth';

class EndCustomerDataService {
    getEndCustomersData(data) {
        return authRequest.post(`/end_customer/list/`, data);
    }
    
    delete(id) {
        return authRequest.delete(`/end_customer/update/${id}/`);
    }

    create(data, file){
        if(file != null){
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            let jsonString = JSON.stringify(data.detail_field);
            let formData = new FormData();
            formData.append('name', data.name);
            formData.append('type', data.type);
            formData.append('organization', data.organization);
            formData.append('detail_field', jsonString);
            formData.append('file', file);
            return authRequest.post(`/end_customer/`, formData, config);
        }else{
            return authRequest.post(`/end_customer/`, data);
        }
    }

    edit(id, data, file){
        if(file != null){
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            let jsonString = JSON.stringify(data.detail_field);
            let formData = new FormData();
            formData.append('name', data.name);
            formData.append('type', data.type);
            formData.append('organization', data.organization);
            formData.append('detail_field', jsonString);
            formData.append('file', file);
            return authRequest.put(`/end_customer/update/${id}/`, formData, config);
        }else{
            return authRequest.put(`/end_customer/update/${id}/`, data);
        }
    }

    selectList(org_id) {
        return authRequest.get(`/end_customer/selectlist/?org_id=${org_id}`);
    }
}
export default new EndCustomerDataService();
<template lang="pug">
// 編輯客戶
v-card
  v-card-title.d-flex.justify-center {{$t('Edit_COMPANY.TITLE')}}
  v-card-text
    v-form(v-model="editAccount.valid", ref="form")
      v-text-field.mb-4(
        v-model='editAccount.name',
        :label=`$t('Edit_COMPANY.ORG_NAME')`, name='name',
        :rules='formRule.organizationRules', 
        required='required', 
        large='large'
        disabled readonly
      )      
      v-select.no-border(
        :label=`$t('Edit_COMPANY.TYPE')`,
        v-model="editAccount.organization_type",
        prepend-icon="mdi-domain",
        :items="orgtypeList",
        item-text="name",
        item-value="key",
        flat
      )
      v-select.no-border(
        :label=`$t('Edit_COMPANY.INDUSTRY')`,
        v-model="editAccount.industry",
        prepend-icon="mdi-domain",
        :items="industryList",
        item-text="name",
        item-value="key",
        flat
      )
      v-file-input.no-border(
        v-model="organizationImage",
        prepend-icon="mdi-image",
        :label=`$t('Edit_COMPANY.IMAGE')`,
        accept="image/*",
        @change='onChangePhoto',
        :rules="formRule.photoRule"
      )
      v-text-field.no-border(
        v-model="editAccount.email",
        prepend-icon="mdi-email",
        :rules="formRule.emailRules",
        :label= `$t('GENERAL.MAIL')`,
      )
      v-text-field.no-border(
        v-model="editAccount.website",
        prepend-icon="mdi-earth",
        :rules='formRule.urlRules',
        :label=`$t('Edit_COMPANY.WEB')`,
      )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="editAccount.main_phone",
            :rules="formRule.contentLenRule50",
            prepend-icon="mdi-phone",
            :label=`$t('Edit_COMPANY.PHONE')`,
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="editAccount.fax",
            :rules="formRule.contentLenRule20",
            prepend-icon="mdi-fax",
            :label=`$t('Edit_COMPANY.FAX')`,
          )
      v-select.no-border(
        v-if='isWiadvance()',
        label='Customer Code',
        v-model="editAccount.customer_code",
        prepend-icon="mdi-domain",
        :rules="formRule.selectRuleX",
        :items="customer_code_List",
        item-text="name",
        item-value="key",
        @change='auto_complete_by_bc_data',
        flat
        clearable
      )
      v-text-field.no-border(
        v-if='!isWiadvance()',
        label='Customer Code',
        v-model="editAccount.customer_code",
        prepend-icon="mdi-domain",
        :rules="formRule.contentLenRule100X",
      )
      v-text-field.no-border(
        v-model="editAccount.company_number",
        prepend-icon="mdi-domain",
        :rules="formRule.contentLenRule100X",
        :label=`$t('ADD_COMPANY.COMPANY_NUMBER')`,
        disabled readonly
      )
      v-text-field.no-border(
        v-model="editAccount.country",
        prepend-icon="mdi-map",
        :rules="formRule.contentLenRule50X",
        :label=`$t('ADD_COMPANY.COUNTRY')`,
        disabled readonly
      )
      v-text-field.no-border(
        v-model="editAccount.zip_code",
        prepend-icon="mdi-map",
        :rules="formRule.contentLenRule50X",
        :label=`$t('ADD_COMPANY.ZIP_CODE')`,
        disabled readonly
      )
      v-text-field.no-border(
        v-model="editAccount.city",
        prepend-icon="mdi-map",
        :rules="formRule.contentLenRule50X",
        :label=`$t('ADD_COMPANY.CITY')`,
        disabled readonly
      )
      v-text-field.no-border(
        v-model="editAccount.billing_address",
        prepend-icon="mdi-map",
        :label=`$t('Edit_COMPANY.ADDRESS')`,
        disabled readonly
      )
      v-text-field.no-border(
        v-model="paymentTermName",
        prepend-icon="mdi-domain",
        :items="wiadvancePaymentTermList",
        :label=`$t('ADD_COMPANY.PAYMENT_TERM')`,
        item-text="name",
        item-value="key",
        :rules="[v => !!v || $t('RULE.RULE_R')]",
        flat
        disabled readonly
      )
      v-text-field.no-border(
        v-model="editAccount.employee",
        prepend-icon="mdi-account-group",
        :rules="formRule.employeeRules",
        :label=`$t('Edit_COMPANY.EMPLOYEE')`,
      )
      v-window
        customFieldRequired(ref='CFRequired' DataClass = 'Organization' :editData='this.editAccount.custom_field'  :StageTypeId='null' ).mt-3

  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="success",
          depressed,
          dark
        ) 
          v-progress-circular(v-if='editBtn_loading' indeterminate size='20')
          .t-white(v-else) {{$t('GENERAL.SAVE')}}
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
    error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import OrganizationDataService from "@/services/OrganizationDataService";
import industryList from "@/array/the_IndustryList";
import orgtypeList from "@/array/the_OrgtypeList";
import i18n from '/common/plugins/vue-i18n.js';
import customFieldRequired from "@/components/Dialog/customFieldRequired";
import wiadvancePaymentTermList from "@/array/the_wiadvancePaymentTermList";
import getCustomerCodeListOptions from "@/views-wiadvance/getCustomerCodeListOptions";
import checkCustomerCodeRepeat from "@/views-wiadvance/checkCustomerCodeRepeat";
import errorDialog from "@/components/Dialog/errorDialog";

export default Vue.extend({
  props: {
    editAccount: {
      type: Object,
      required: true,
    },
  },
  components: {
      customFieldRequired,
      errorDialog
  },
  data() {
    return {
      editBtn_loading:false,
      organizationList: [],
      organizationImage: null,
      orgphoto:this.editAccount.image,
      userphoto:this.editAccount.owner.usersetting.photo,
      industryList: industryList,
      customer_code_List: [],
      orgtypeList: orgtypeList,
      wiadvancePaymentTermList: wiadvancePaymentTermList,
      formRule: {
        organizationRules: [
          (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
        ],
        urlRules: [
          (v) => /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_URL'),
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
        ],
        emailRules: [
          (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
        ],
        employeeRules: [(v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM')],
        contentLenRule70: [(v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')],
        contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
        contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
        contentLenRule10: [(v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')],
        contentLenRule50X: [
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        contentLenRule100X: [
          (v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        selectRuleX: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        photoRule: [
          () => { return (!this.photoSize || i18n.t('RULE.RULE_FILE_2'))},
          () => { return (!this.fileType || i18n.t('RULE.RULE_PHOTO'))},
        ],
      },
      originOrgData:{},
      cfValid:true,
      errorDialog: false,
      errorMessage: '',
    };
  },
  // created(){
	//   OrganizationDataService.getowner()
  //   .then((response)=>{
  //       this.editAccount.ownList = response.data;
  //     });
  // },
  async created(){
    this.originOrgData = JSON.parse(JSON.stringify(this.editAccount))
    if(this.isWiadvance()){
      this.customer_code_List = await getCustomerCodeListOptions(this.originOrgData.name);
    }
  },
  computed: {
    paymentTermName(){
      return this.getItemNameFromList(this.wiadvancePaymentTermList,this.editAccount.payment_term)
    } 
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    validateForm(){
      if(this.$refs.form.validate())
        return true;
      else
        return false;
    },
    getResponsedata(){
      this.$refs.CFRequired.emitData();
      if(this.$refs.CFRequired.responsedata === false)
      {
        this.$refs.CFRequired.responsedata = null;
        this.cfValid = false;
        return;
      }
      this.editAccount.custom_field = this.$refs.CFRequired.responsedata;
    },
    auto_complete_by_bc_data(){
      if(this.customer_code_List != []){
        let option_obj = this.customer_code_List.find(item => item.key == this.editAccount.customer_code)
        let bc_data = option_obj.bc_data;
        this.editAccount.company_number = bc_data.taxRegistrationNumber;
        this.editAccount.name = bc_data.displayName ;
        this.editAccount.english_department = bc_data.englishName ;
        this.editAccount.country = bc_data.country;
        this.editAccount.zip_code = bc_data.postalCode;
        this.editAccount.city = bc_data.city;
        this.editAccount.billing_address = bc_data.addressLine1;
        this.editAccount.sub_address = bc_data.addressLine2;
        this.editAccount.belong_group = bc_data.belong_group;
        this.editAccount.closing_date = bc_data.closing_date;
        this.editAccount.payment_term = bc_data.payment_term;
        this.editAccount.regular_payment_date = bc_data.regular_payment_date;
        this.editAccount.temp_owner = bc_data.owner;
      }
    },
    async onConfirm() {
      if(this.validateForm() && this.editBtn_loading == false)
      {
        if((this.editAccount.custom_field != null && this.editAccount.custom_field !== undefined) || this.$refs.CFRequired.process_array.length != 0)
          this.getResponsedata();
        if(this.cfValid == false)
        {
          this.cfValid = true;
          return;
        }
        this.editBtn_loading = true;
        if(this.isWiadvance() && this.originOrgData.customer_code != this.editAccount.customer_code && await checkCustomerCodeRepeat(this.editAccount.id, this.editAccount)){
          if(checkCustomerCodeRepeat(this.editAccount.id, this.editAccount)){
            this.showErrorDialog(i18n.t('COMPANY.WIADVANCE_REPEAT_CUSTOMERCODE'));
            this.editBtn_loading = false;
          }
        }else{
          await OrganizationDataService.edit(this.editAccount)
          .then(
            this.editAccount.image = this.orgphoto,
            this.editAccount.owner.usersetting.photo = this.userphoto,
          );

          if (this.organizationImage != null) 
          {
            await OrganizationDataService.image(this.editAccount.id, this.organizationImage)
              .then(response => {
                this.editAccount.image = response.data.image;
            });
          }
          this.editBtn_loading = false;
          this.$emit("emitEditAccountDialog", true);
        }
        
      }
    },
    onCancel() {
      this.$emit("emitEditAccountDialog", false);
    },
    onChangePhoto(event) {
      if (event != null) 
      {
        // file type
        let n = event.type.indexOf("image");
        if (n == -1) 
        {
          this.fileType = true;
        } 
        else 
        {
          this.fileType = false;
        }

        // photo size
        if (event.size > 5242880) 
        {
          this.photoSize = true;
        } 
        else 
        {
          this.photoSize = false;
        }
      } 
      else 
      {
        this.photoSize = false;
        this.fileType = false;
      }
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
  }
});
</script>
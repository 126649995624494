import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

function isWiadvance(){
	let tanant = window.localStorage.getItem('tenant');
	return tanant == "wiadvance" || tanant == "demo"  ? true : false ;
}

const opptysFieldList = [
    { key:'created_on', name: i18n.t('OPPTYSFIELDLIST.created_on') ,type:'date'},
	{ key:'created_by', name: i18n.t('OPPTYSFIELDLIST.created_by') ,type:'user'},
	{ key:'updated_on', name: i18n.t('OPPTYSFIELDLIST.updated_on') ,type:'date'},
	{ key:'updated_by',name: i18n.t('OPPTYSFIELDLIST.updated_by') ,type:'user'},
	{ key:'owner',name: i18n.t('OPPTYSFIELDLIST.owner') ,type:'user'},
	{ key:'name',name: i18n.t('OPPTYSFIELDLIST.name') ,type:'text'},
	{ key:'amount',name: i18n.t('OPPTYSFIELDLIST.amount') ,type:'num'},
	{ key:'expected_close_date',name: i18n.t('OPPTYSFIELDLIST.expected_close_date') ,type:'date'},
	{ key:'closed_date',name: i18n.t('OPPTYSFIELDLIST.closed_date') ,type:'date'},
	{ key:'closed_reason',name: i18n.t('OPPTYSFIELDLIST.closed_reason') ,type:'opt'},
	{ key:'status',name: i18n.t('OPPTYSFIELDLIST.status') ,type:'opt'},
	{ key:'forecast_category',name: i18n.t('OPPTYSFIELDLIST.forecast_category') ,type:'opt'},
	{ key:'customer_needs',name: i18n.t('OPPTYSFIELDLIST.customer_needs') ,type:'text'},
	{ key:'customer_budget',name: i18n.t('OPPTYSFIELDLIST.customer_budget') ,type:'num'},
	{ key:'budget_confirmed',name: i18n.t('OPPTYSFIELDLIST.budget_confirmed') ,type:'bol'},
	{ key:'primary_campaign_id',name: i18n.t('OPPTYSFIELDLIST.primary_campaign_id') ,type:'fk_cam'},
	{ key:'stage_type',name: i18n.t('OPPTYSFIELDLIST.stage_type') ,type:'opt'},
	{ key:'oppty_contact',name: i18n.t('OPPTYSFIELDLIST.oppty_contact'),type:'fk_contact'},
	{ key:'oppty_org',name: i18n.t('OPPTYSFIELDLIST.oppty_contact') ,type:'fk_org'},
	{ key:'current_stage',name: i18n.t('OPPTYSFIELDLIST.current_stage') ,type:'opt'},
	{ key:'primary_contact',name: i18n.t('OPPTYSFIELDLIST.primary_contact') ,type:'fk_contact'},
	{ key:'description',name: i18n.t('OPPTYSFIELDLIST.description') ,type:'text'},
	{ key:'customer_needs',name: i18n.t('OPPTYSFIELDLIST.customer_needs') ,type:'text'},
	
	{ key:'purchase_needs_or_process',name: i18n.t('DEAL_DETAIL.PURCHASE_NEEDS_OR_PROCESS') ,type:'text'},
	{ key:'expect_or_nextsteps_or_others',name: i18n.t('DEAL_DETAIL.EXPECT_OR_NEXTSTEPS_OR_OTHERS') ,type:'text'},
	{ key:'sales_notes',name: i18n.t('DEAL_DETAIL.SALES_NOTES') ,type:'text'},
	{ key:'oppty_status',name: i18n.t('DEAL_DETAIL.OPPTY_STATUS') ,type:'opt'},
	{ key:'industry',name: i18n.t('DEAL_DETAIL.INDUSTRY') ,type:'opt'},
	{ key:'customer_source',name: i18n.t('DEAL_DETAIL.CUSTOMER_SOURCE') ,type:'opt'},
	{ key:'other_customer_source',name: i18n.t('DEAL_DETAIL.OTHER_CUSTOMER_SOURCE') ,type:'text'},
	// { key:'progress',name: i18n.t('DEAL_DETAIL.PROGRESS_2') ,type:'opt'},
	{ key:'oppty_type',name: i18n.t('DEAL_DETAIL.OPPTY_TYPE') ,type:'opt'},
	{ key:'customer_code',name: 'Customer Code' ,type:'text'},
];

if(isWiadvance()){
	opptysFieldList.push({ key:'serial_number',name: i18n.t('DEAL_DETAIL.SERIAL_NUMBER'),type:'text'});
	opptysFieldList.push({ key:'end_account_id',name: i18n.t('DEAL_DETAIL.ENDACCOUNT'),type:'opt'});
}

export default opptysFieldList;
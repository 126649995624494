<template lang="pug">
// 批量更新-公司-WiAdvance
v-card(:height="windowHeight-6" :max-height="windowHeight" outlined elevation='0')
  v-card-title.d-flex.justify-center {{$t('BULK_EDIT.TITLE')}}
  v-card(:height="windowHeight-150" :max-height="windowHeight-150"  outlined elevation='0'  class='scrollbar')
    v-card-text
      v-form(v-model="valid",ref="form",)
        //- 公司名稱-英
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.NAME_EN')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.english_department.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='english_department', @change="visibleChange('english_department')")
            v-text-field.mb-3(v-if='editOrgData.english_department.change == 1' v-model="editOrgData.english_department.value",prepend-icon="mdi-domain",:label= `$t('COMPANY_DETAIL.NAME_EN')`,:rules="formRule.organizationRules",validate-on-blur,required="required",large="large" ,ref='name_val',)
        //- 公司名稱-網站
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.WEB')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.website.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='website', @change="visibleChange('website')")
            v-text-field.mb-3(v-if='editOrgData.website.change == 1' v-model="editOrgData.website.value",prepend-icon="mdi-earth",:label=`$t('COMPANY_DETAIL.WEB')`,large="large",:rules="formRule.urlRules",ref='website_val',)
        
        //- label.ellipsis.select-label-size(style='width: 300px;' ) {{ 'Customer Code' }}
        //- div.bulk-edit-square(outline)
        //-     v-select.select-text-size.no-border.no-padding(v-model='editOrgData.customer_code.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='customer_code', @change="visibleChange('customer_code')")
        //-     v-text-field.mb-3(v-if='editOrgData.customer_code.change == 1', prepend-icon='mdi-tag', v-model="editOrgData.customer_code.value",:label=`'Customer Code'`,large="large",:rules='formRule.contentLenRule100' ,ref='customer_code',)
        //- 電子郵件
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('GENERAL.MAIL')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.email.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='email', @change="visibleChange('email')")
            v-text-field.no-border(
              v-if='editOrgData.email.change == 1'
              v-model="editOrgData.email.value",
              prepend-icon="mdi-email",
              :label= `$t('GENERAL.MAIL')`,
              :rules="formRule.emailRules"
              ,ref='email_val'
            )
        //- 電話
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.PHONE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.main_phone.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='main_phone', @change="visibleChange('main_phone')")
            v-text-field.mb-3(v-if='editOrgData.main_phone.change == 1' v-model="editOrgData.main_phone.value",prepend-icon="mdi-phone",:label=`$t('COMPANY_DETAIL.PHONE')`,large="large",:rules='formRule.contentLenRule50',ref='main_phone_val',)
        //- 行業別
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.INDUSTRY')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.industry.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='industry', @change="visibleChange('industry')")
            v-select#industry.no-border(v-if='editOrgData.industry.change == 1',prepend-icon="mdi-domain", v-model="editOrgData.industry.value" :rules="formRule.listRules" ,:label=`$t('COMPANY_DETAIL.INDUSTRY')`,  :items="industryList" item-text="name" item-value="key"  :menu-props='{closeOnClick:true}',ref='industry_val')
        //- 類型
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.TYPE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.organization_type.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='organization_type', @change="visibleChange('organization_type')")
            v-select#industry.no-border(v-if='editOrgData.organization_type.change == 1',prepend-icon="mdi-domain", v-model="editOrgData.organization_type.value" :rules="formRule.fieldRules" ,:label=`$t('COMPANY_DETAIL.TYPE')`,  :items="orgtypeList" item-text="name" item-value="key"  :menu-props='{closeOnClick:true}',ref='organization_type_val')
        //- 員工人數
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.EMPLOYEE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.employee.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='employee', @change="visibleChange('employee')")
            v-text-field.no-border.height-48(v-if='editOrgData.employee.change == 1' v-model='editOrgData.employee.value',prepend-icon="mdi-account-group" ,:label=`$t('COMPANY_DETAIL.EMPLOYEE')`, :rules='formRule.intRules' ,ref='employee_val', )
        //- 成立日期
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.ESTABLISH_DATE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.establish_date.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='establish_date', @change="visibleChange('establish_date')")
            v-menu(v-if='editOrgData.establish_date.change == 1' ref="menu" v-model="menu" :z-index='10000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field.no-border.height-48(v-model='editOrgData.establish_date.value',prepend-icon='mdi-calendar',readonly :rules='formRule.fieldRules',:label=`$t('COMPANY_DETAIL.ESTABLISH_DATE')`,   v-bind="attrs" v-on="on" ,ref='establish_date_val',)
                            template(v-slot:append)
                    v-date-picker.no-border( v-model='editOrgData.establish_date.value' @input='menu = false' no-title scrollable)
        //- 營業額 
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.ANNUAL_REVENUE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.annual_revenue.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='editAmount', @change="visibleChange('editAmount')")
            v-text-field(v-model='editOrgData.annual_revenue.value' prepend-icon="icon-chatmoney", :label=`$t('COMPANY_DETAIL.ANNUAL_REVENUE')`,v-if='editOrgData.annual_revenue.change == 1' v-show='annualRevenueShow|!aRValidate' :rules='formRule.intRules' :ref="'editAmount_val'" @blur='showFormatAmount("annual_revenue")')
            v-text-field(v-model='formatAR' prepend-icon="icon-chatmoney", :label=`$t('COMPANY_DETAIL.ANNUAL_REVENUE')`,v-if='editOrgData.annual_revenue.change == 1' @click='showEditAmount("annual_revenue")' @blur='showEditAmount("annual_revenue")' :readonly='true' v-show='!annualRevenueShow&&aRValidate')
        //- 資本額
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.REGISTERED_CAPITAL')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.registered_capital.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='editRC', @change="visibleChange('editRC')")
            v-text-field(v-model='editOrgData.registered_capital.value' prepend-icon="icon-chatmoney", :label=`$t('COMPANY_DETAIL.REGISTERED_CAPITAL')`, v-if='editOrgData.registered_capital.change == 1'  v-show='registeredCapitalShow|!rCValidate' :rules='formRule.BigIntRules' :ref="'editRC_val'" @blur='showFormatAmount("registered_capital")')
            v-text-field(v-model='formatRC' prepend-icon="icon-chatmoney", :label=`$t('COMPANY_DETAIL.REGISTERED_CAPITAL')`, v-if='editOrgData.registered_capital.change == 1' @click='showEditAmount("registered_capital")' @blur='showEditAmount("registered_capital")' :readonly='true' v-show='!registeredCapitalShow&&rCValidate')
        //- facebook
        label.ellipsis.select-label-size(style='width: 300px;' ) Facebook
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.facebook.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='facebook', @change="visibleChange('facebook')")
            v-text-field.no-border(
              v-if='editOrgData.facebook.change == 1'
              v-model="editOrgData.facebook.value",
              prepend-icon="mdi-facebook",
              :rules="formRule.fbRules",
              label="Facebook",
              ref='facebook_val',
            )
        //- instagram
        label.ellipsis.select-label-size(style='width: 300px;' ) Instagram
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.instagram_id.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='instagram_id', @change="visibleChange('instagram_id')")
            v-text-field.no-border(
              v-if='editOrgData.instagram_id.change == 1'
              v-model="editOrgData.instagram_id.value",
              prepend-icon="mdi-instagram",
              :rules="formRule.contentLenRule50",
              label="Instagram",
              ref='instagram_id_val',
            )
        //- line
        label.ellipsis.select-label-size(style='width: 300px;' ) Line ID
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.line_id.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='line_id', @change="visibleChange('line_id')")
            v-text-field.no-border(
              v-if='editOrgData.line_id.change == 1'
              v-model="editOrgData.line_id.value",
              prepend-icon="fab fa-line",
              :rules="formRule.contentLenRule50",
              label="Line ID",
              ref='line_id_val',
            )
        //- linkdin
        label.ellipsis.select-label-size(style='width: 300px;' ) LinkedIn
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.linkedin.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='linkedin', @change="visibleChange('linkedin')")
            v-text-field.no-border(
              v-if='editOrgData.linkedin.change == 1'
              v-model="editOrgData.linkedin.value",
              prepend-icon="mdi-linkedin",
              :rules="formRule.linkRules",
              label="LinkedIn",
              ref='linkedin_val',
            )
        //- 擁有者
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('GENERAL.CHANGE_OWNER')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.owner.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='owner', @change="visibleChange('owner')")
            v-autocomplete.no-border.height-48(v-if='editOrgData.owner.change == 1' v-model='editOrgData.owner.value' :items='ownerList' :rules="formRule.fieldRules" item-text='fullName' item-value='id' ,ref='owner_val', :label=`$t('GENERAL.CHANGE_OWNER_2')` return-object rounded  prepend-icon='mdi mdi-account')
        //- 相關活動
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CASE_DETAIL.RELATED_CAMPAIGN')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.primary_campaign_id.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='primary_campaign_id', @change="visibleChange('primary_campaign_id')")
            v-autocomplete.no-border(v-if='editOrgData.primary_campaign_id.change == 1' v-model='editOrgData.primary_campaign_id.value' :rules="formRule.fieldRules" :items='campaignList' item-text='name' item-value='id' :menu-props='{closeOnClick:true}',ref='primary_campaign_id_val' :label=`$t('CASE_DETAIL.RELATED_CAMPAIGN')` prepend-icon='mdi-tag')
        //- 傳真
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.FAX')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.fax.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='fax', @change="visibleChange('fax')")
            v-text-field.no-border.height-48(v-if='editOrgData.fax.change == 1' :label=`$t('COMPANY_DETAIL.FAX')`,prepend-icon="mdi-fax", v-model='editOrgData.fax.value'   :rules="formRule.contentLenRule20" ,ref='fax_val',)
        //- 地址2
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.SUB_ADDRESS')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.sub_address.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='sub_address', @change="visibleChange('sub_address')")
            v-text-field.no-border.height-48(v-if='editOrgData.sub_address.change == 1' :label=`$t('COMPANY_DETAIL.SUB_ADDRESS')`,prepend-icon="mdi-map", v-model='editOrgData.sub_address.value' :rules="formRule.fieldRules" ,ref='sub_address_val',)
        //- 省份
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.PROVINCE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.province.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='province', @change="visibleChange('province')")
            v-text-field.no-border.height-48(v-if='editOrgData.province.change == 1', prepend-icon='mdi-tag', :label=`$t('COMPANY_DETAIL.PROVINCE')`, v-model='editOrgData.province.value'   :rules="formRule.contentLenRule50" ,ref='province_val',)
        //- 描述
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.DESCRIPTION')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.description.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='description', @change="visibleChange('description')")
            v-textarea.no-border.textarea-style(v-if='editOrgData.description.change == 1', prepend-icon='mdi-tag', :label=`$t('COMPANY_DETAIL.DESCRIPTION')`, v-model='editOrgData.description.value' :rules="formRule.fieldRules" , no-resize ,ref='description_val',)
        //- 關帳日
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.CLOSING_DATE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.closing_date.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='closing_date', @change="visibleChange('closing_date')")
            v-select#industry.no-border(v-if='editOrgData.closing_date.change == 1',prepend-icon="mdi-domain", v-model="editOrgData.closing_date.value" :rules="formRule.fieldRules" ,:label=`$t('COMPANY_DETAIL.CLOSING_DATE')`,  :items="dateList" item-text="name" item-value="key"  :menu-props='{closeOnClick:true}',ref='closing_date' multiple)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.REGULAR_PAYMENT_DATE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.regular_payment_date.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='regular_payment_date', @change="visibleChange('regular_payment_date')")
            v-select#industry.no-border(v-if='editOrgData.regular_payment_date.change == 1',prepend-icon="mdi-domain", v-model="editOrgData.regular_payment_date.value" :rules="formRule.fieldRules" ,:label=`$t('COMPANY_DETAIL.REGULAR_PAYMENT_DATE')`,  :items="dateList" item-text="name" item-value="key"  :menu-props='{closeOnClick:true}',ref='regular_payment_date' multiple)
        //- 所屬集團
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('COMPANY_DETAIL.BELONG_GROUP')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editOrgData.belong_group.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='belog_group', @change="visibleChange('belong_group')")
            v-autocomplete#industry.no-border(v-if='editOrgData.belong_group.change == 1',prepend-icon="mdi-domain", v-model="editOrgData.belong_group.value" :rules="formRule.fieldRules" ,:label=`$t('COMPANY_DETAIL.BELONG_GROUP')`,  :items="belongGroupList" item-text="name" item-value="key"  :menu-props='{closeOnClick:true}',ref='belog_group')
        v-window
            customField(v-if='!isLoading' ref='CFRequired' DataClass = 'Organization'  :StageTypeId='null' )

  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="primary",
          depressed,
          :disabled="editBtn_loading || isLoading"
        ) 
          v-progress-circular(v-if='editBtn_loading' indeterminate size='20')
          .t-white(v-else) {{$t('GENERAL.CONFIRM')}}
  v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' persistent)
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
    error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import OrganizationDataService from "../../../services/OrganizationDataService";
import levelList from "@/array/the_levelList";
import i18n from '/common/plugins/vue-i18n.js';
import CampaignDataService from "@/services/CampaignDataService";
import messageDialog from '@/components/Dialog/messageDialog';
import forecast_category_List from "@/array/the_forecastCategoryList";
import industryList from "@/array/the_IndustryList";
import orgtypeList from "@/array/the_OrgtypeList";
import bulkEditList from "@/array/the_bulkEditList";
import customField from "@/components/Dialog/BulkEdit/customFieldBulkEdit";
import errorDialog from "@/components/Dialog/errorDialog";
import wiadvancePaymentTermList from "@/array/the_wiadvancePaymentTermList";
import belongGroupList from "@/array/the_belongGroupList";

export default Vue.extend({
  props: {
    editItems: {
        type: Array,
        required: true,
    },
  },
  components: {
      customField,
      errorDialog,
      messageDialog,
  },
  data() {
    return {
      valid: true,
      isLoading: false,
      search: null,
      organizationList: [],
      addContact_organization_set: null,
      comboText: "",
      contactImage: null,
      levelList: levelList,
      formRule: {
        listRules: [(v) => /(^[0-9]\d*$)|null/.test(v) || i18n.t('RULE.RULE_R')],
        fieldRules:[
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        organizationRules: [
          (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
          (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
        ],
        urlRules: [
          (v) => /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!]))?|(^$)|null/.test(v) || i18n.t('RULE.RULE_URL'),
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        emailRules: [
          (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) ||  i18n.t('RULE.RULE_EMAIL'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        phoneRules: [],
        linkRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /(http|https):\/\/\S*|(^$)|null/.test(v) || i18n.t('RULE.RULE_HTTP'),
          (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')
        ],
        fbRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /(http|https):\/\/\S*|(^$)|null/.test(v) || i18n.t('RULE.RULE_HTTP'),
          (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')
        ],
        contentLenRule100: [(v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100')],
        contentLenRule70: [(v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')],
        contentLenRule50: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
        ],
        contentLenRule20: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')
        ],
        contentLenRule10: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')
        ],
        photoRule: [
          () => { return (!this.photoSize || i18n.t('RULE.RULE_FILE_2'))},
          () => { return (!this.fileType || i18n.t('RULE.RULE_PHOTO'))},
        ],
        companyRule: [
          (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70'),
          (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
        ],
        intRules: [
            (v) => !!v || i18n.t('RULE.RULE_R'),
            (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
            (v) => /^.{0,9}$/.test(v) || i18n.t('RULE.RULE_9')
            ],
        BigIntRules: [
            (v) => !!v || i18n.t('RULE.RULE_R'),
            (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
            (v) => /^.{0,16}$/.test(v) || i18n.t('RULE.RULE_16')
            ],
      },
      isNumVaild: true,
      vue_tel: {
        isValid: true,
      },
      photoSize: false,
      fileType: false,
      editOrgData: {
          name: {change:0,value:''},
          english_department: {change:0,value:''},
          website: {change:0,value:''},
          company_number: {change:0,value:''},
          email: {change:0,value:''},
          main_phone: {change:0,value:''},
          industry: {change:0,value:''},
          employee: {change:0,value:''},
          establish_date: {change:0,value:''},
          annual_revenue: {change:0,value:''},
          registered_capital: {change:0,value:''},
          facebook: {change:0,value:''},
          linkedin: {change:0,value:''},
          instagram_id: {change:0,value:''},
          line_id: {change:0,value:''},
          custom_field: {change:0,value:''},
          primary_campaign_id:{change:0,value:''},
          fax:{change:0,value:''},
          billing_address:{change:0,value:''},
          sub_address:{change:0,value:''},
          city:{change:0,value:''},
          organization_type:{change:0,value:''},
          country:{change:0,value:''},
          zip_code:{change:0,value:''},
          province:{change:0,value:''},
          customer_code:{change:0,value:''},
          payment_term:{change:0,value:''},
          closing_date:{change:0,value:''},
          regular_payment_date:{change:0,value:''},
          belong_group:{change:0,value:''},
          description:{change:0,value:''},
          owner: {change:0,value:''},
        },
        windowHeight: window.innerHeight,
        processHeight: window.innerHeight-300,
        selectToChange: bulkEditList,
        selectToChangeNoDel: bulkEditList.filter(el=>el.key!=2),
        campaignList:[],
        ownerList: [],
        menu: false,
        messageDialog: false,
        message: '',
        bulkEditData:{},
        industryList: industryList, 
        orgtypeList: orgtypeList,
        paymentTermList: wiadvancePaymentTermList,
        forecast_category_List: forecast_category_List,
        belongGroupList:belongGroupList,
        annualRevenueShow:false,
        formatAR:null,
        aRValidate:false,
        registeredCapitalShow:false,
        formatRC:null,
        rCValidate:false,
        customFieldData:{},
        errorDialog: false,
        errorMessage: '',
        editBtn_loading: false,
        cfValid:true,
        isEditLoading:false,
        dateList: Array.from({ length: 31  }, (_, index)=>({
          key: index + 1,
          name: i18n.t(`THEDAYOFEVERYMONTH.${index + 1}`)
        }))
    };
  },
  async created(){
        await CampaignDataService.selectlist().then((response)=>{
            this.campaignList = response.data;
        })
        OrganizationDataService.getchangeowner('accounts')
          .then((respose)=>{
              this.ownerList = respose.data;
              this.ownerList.forEach(item => {
                  item.fullName = item.last_name + ' ' + item.first_name;
              });
          });
  },
  methods: {
    visibleChange(blurVal){
        this.$refs[blurVal].blur();
        let focuVal = blurVal+'_val';
        setTimeout(()=>{
            try{
                this.$refs[focuVal].focus();
                this.$refs[focuVal].isMenuActive = true;
            }catch{
            // pass;
            }
        },100)
    },
    validate() {
      this.$refs.form.validate();
    },
    validateForm(){
      if(this.$refs.form.validate()){
        return true;
      }
      else{
        return false;
      }
    },
    async onConfirm() {
      this.formRule.phoneRules.push(() => {return (this.isNumVaild || i18n.t('CONTACT_DETAIL.ERROR_3'))});
      if(this.validateForm()){
        this.editBtn_loading = true;
        let bulkEditOrgData = {};
        this.getResponsedata();
        if(this.cfValid == false){
          this.cfValid = true;
          return;
        }
        for(let i in this.editOrgData){
            if(this.editOrgData[i]['change']==1){
                bulkEditOrgData[i] = this.editOrgData[i]['value'];
                if(i == 'owner'){
                    bulkEditOrgData[i] = this.editOrgData[i]['value']['id'];
                }
                if(i == 'regular_payment_date' || i == 'closing_date'){
                  bulkEditOrgData[i] = this.editOrgData[i]['value'].join(',')
                }
            }
            if(this.editOrgData[i]['change']==2){
                bulkEditOrgData[i] = null;
            }
        }
        let editItemName = '';
        let editItemNameArray = [];
        for(let i in this.editItems){
            editItemName = i > 0 ? editItemName +','+ this.editItems[i].name : editItemName + this.editItems[i].name;
            editItemNameArray.push(this.editItems[i].id);
        }
        this.bulkEditData = {
            list:editItemNameArray,
            data:bulkEditOrgData,
            cf:Object.keys(this.customFieldData).length > 0 ? this.customFieldData :null
        };
        if(Object.keys(this.customFieldData).length === 0 && Object.keys(this.bulkEditData.data).length === 0){
            this.showErrorDialog(i18n.t('BULK_EDIT.MESSAGE_EMPTY'));
            return;
        }
        this.showMessageDialog(this.editItems.length);
      }
    },
    showMessageDialog(message) {
        this.messageDialog = true;
        if(Object.keys(this.bulkEditData.data).length != 0 || Object.keys(this.customFieldData).length != 0)
        {
            this.message = `${i18n.t('BULK_EDIT.MESSAGE_DATA')}${message}${i18n.t('BULK_EDIT.MESSAGE_DATA_2')}`;
        }else
        {
            this.showErrorDialog(i18n.t('BULK_EDIT.MESSAGE_EMPTY'));
            this.messageDialog = false;
            return;
        }
    },
    async onEmitMessageDialog(val){
        if(val){
          if(!this.isEditLoading){
            this.isEditLoading = true;
            if(Object.keys(this.bulkEditData.data).length != 0 || Object.keys(this.customFieldData).length != 0){
                await(this.$emit('emitBulkEditDialog','editing',this.bulkEditData));
            }
          }
        }
        this.editBtn_loading = false;
        this.messageDialog = false;
    },
    showErrorDialog(message) {
        this.errorDialog = true;
        this.errorMessage = message;
    },
    onEmitErrorDialog() {
        this.errorDialog = false;
        this.editBtn_loading = false;
    },
    onCancel() {
        this.$emit('emitBulkEditDialog', false);
    },
    getResponsedata(){
      this.$refs.CFRequired.emitData();
      if(this.$refs.CFRequired.responsedata === false)
      {
        this.$refs.CFRequired.responsedata = null;
        this.cfValid = false;
        this.editBtn_loading = false;
        return;
      }
      this.customFieldData = this.$refs.CFRequired.responsedata;
    },
    showFormatAmount(data){
        if(data==='annual_revenue'){
            if(this.editOrgData.annual_revenue.value == 0 | this.editOrgData.annual_revenue.value == "")
            {
                  this.formatAR = 0;
            }else
            {
                this.formatAR = this.getFormatAmount(this.editOrgData.annual_revenue.value).slice(0,-3);
            }
            this.annualRevenueShow = false;
            this.aRValidate = this.$refs.editAmount_val.validate();
          }
        if(data==='registered_capital'){
            if(this.editOrgData.registered_capital.value == 0 | this.editOrgData.registered_capital.value == "")
            {
                  
                  this.formatRC = 0;
            }else
            {
                this.formatRC = this.getFormatAmount(this.editOrgData.registered_capital.value).slice(0,-3);
            }
            this.registeredCapitalShow = false;
            this.rCValidate = this.$refs.editRC_val.validate();
        }
    },
    showEditAmount(data){
        if(data==='annual_revenue')
        {
            this.annualRevenueShow = true;
            this.$refs.editAmount_val.focus();
        }   
        if(data==='registered_capital')
        {
            this.registeredCapitalShow = true;
            this.$refs.editRC_val.focus();
        }
    },
  },
  computed: {
    isNew: function(){
      if(this.addContact_organization_set == "" || this.addContact_organization_set == null){
        return false;
      }
      if(typeof (this.addContact_organization_set) === 'object'){
        if(this.addContact_organization_set.id == 0){
          return true;
        }
        else{
          return false;
        }
      }
      else{
        return true;
      }
    },
  },
  watch: {
    "errorDialog":function(){
        if(!this.errorDialog){
            this.editBtn_loading = false;
        }
    },
    async search(val) {
      var re = /^(?!\s*$).+/;
      var check = re.test(val)
      var checkLength = /^.{0,200}$/.test(val);
      this.comboText = "";
      if(check && val != null && checkLength){
        let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
        this.comboText = val;
        if(!this.isLoading && charNum>=2 && charNum==val.length ){
          this.isLoading = true;
          await OrganizationDataService.findOrganizationByNamefromDB(val)
          .then((response) => {
            this.organizationList = response.data;
            let num = 0
            this.organizationList.forEach((item)=>{
              item.uqNumber = num
              num ++
              })
          })
          .finally(() => (this.isLoading = false));
        }
        else {
          this.isLoading = false;
        }
      }
    },
    "editOrgData.organization_set.value": function (){
      this.addContact_organization_set = this.editOrgData.organization_set.value;
      this.comboText = this.editOrgData.organization_set.value.name ? this.editOrgData.organization_set.value.name : "";
    },
  },
});
</script>
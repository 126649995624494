<template lang="pug">
#DataTableSetting.py-4.addTableDialog
    .d-flex.justify-center        
        h3(v-if='editMode == "EDIT"') {{$t('TABLESETTING.TITLE')}} 
        h3(v-else-if='editMode == "COPY"') {{$t('TABLESETTING.COPY')}}
    v-stepper(non-linear flat v-model='currentStep').w-100
        v-stepper-header
            v-stepper-step.stepper-btn(step="1" :complete="currentStep > 1") {{$t('TABLESETTING.SET')}} 
            v-divider
            v-stepper-step.stepper-btn(step="2" :complete="currentStep > 2") {{$t('TABLESETTING.FIELD')}}
            v-divider
            v-stepper-step.stepper-btn(step="3") {{$t('TABLESETTING.SEQ')}}
        v-stepper-items(step="1")
            //- 步驟1
            v-stepper-content(step="1")
                v-card.order-box.overflow-hidden(outlined flat)
                    v-card-text.pa-4
                        v-form(ref='form')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field(:label="$i18n.t('TABLESETTING.NAME')" :rules="formRule.nameRules" v-model='customView.name' required)
                v-row.mt-4
                    v-col.pr-2.pl-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed='depressed' dark='dark') {{$t('GENERAL.CANCEL')}}
                    v-col.pl-2.pr-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='to_2' width='100%' height='40' color='success' depressed='depressed' dark='dark') 
                            v-progress-circular(v-if='to_2_loading' indeterminate size='20')
                            .t-white(v-else) {{$t('GENERAL.NEXT')}}
            //- 步驟2
            v-stepper-content(step="2")
                v-card.order-box(outlined flat)
                    v-card-text.pa-4
                        v-list(flat).pa-0
                            v-list-item-group(v-model='selectedIndex' multiple)
                                v-list-item(v-for='item, index in fields' :key='item.value').pa-0
                                    template(v-slot:default='{ active }')
                                        v-list-item-action.ma-0.mr-4
                                            v-checkbox.ma-0(:input-value='active')
                                        v-list-item-content() {{ item.text }}
                v-row.mt-4
                    v-col.pr-2.pl-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='to_1' width='100%' height='40' color='cancel' depressed='depressed' dark='dark') {{$t('GENERAL.PRE')}}
                    v-col.pl-2.pr-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='to_3' width='100%' height='40' color='success' depressed='depressed' dark='dark') {{$t('GENERAL.NEXT')}}
            //- 步驟3
            v-stepper-content(step="3")
                v-card.order-box(outlined flat )
                    v-card-text.pa-4
                        draggable.h-100(group="column" v-model='selectedFields' :disabled='loading')
                            v-chip.d-flex.w-100.mt-2(v-for='item, index in selectedFields' :key='item.index' animation="1000") 
                                v-badge.mr-2(inline :content='index+1') 
                                span(v-if='item.is_customfield') {{ item.text }}
                                span(v-else-if='customViews[1].class_name == "Contact"') {{ getItemNameFromList(contactsFieldList , item.value) }}
                                span(v-else-if='customViews[1].class_name == "Organization"') {{ getItemNameFromList(orgsFieldList , item.value) }}
                                span(v-else-if='customViews[1].class_name == "Opportunity"') {{ getItemNameFromList(opptysFieldList , item.value) }}
                                span(v-else-if='customViews[1].class_name == "Case"') {{ getItemNameFromList(casesFieldList , item.value) }}
                                
                v-row.mt-4
                    v-col.pr-2.pl-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='from_3_to_2' width='100%' height='40' color='cancel' depressed='depressed' dark='dark') {{$t('GENERAL.PRE')}}
                    v-col.pl-2.pr-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark') 
                            v-progress-circular(v-if='loading' indeterminate size='20')
                            .t-white(v-else) {{$t('GENERAL.SAVE')}}
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import draggable from 'vuedraggable';
    import errorDialog from '@/components/Dialog/errorDialog';
    import messageDialog from "@/components/Dialog/messageDialog";
    import CustomViewDataService from '@/services/CustomViewDataService';
    import i18n from '/common/plugins/vue-i18n.js' 
    // list
    import classTypeList from "@/array/the_classTypeList";
    import opptysFieldList from "@/array/the_opptysFieldList";
    import orgsFieldList from "@/array/the_orgsFieldList";
    import contactsFieldList from "@/array/the_contactsFieldList";
    import casesFieldList from "@/array/the_casesFieldList";
    
    export default Vue.extend({
        components: {
            errorDialog,
            messageDialog,
            draggable
        },
        props: {
            customViews: {
                type: Array,
                required: true,
            },
            editData: {
                type: Object,
                required: true,
            },
            stageType: {
                required: false,
            },
            editMode: {
                type: String,
                required: true,
            },
        },
        data() {
            return {        
                // list 
                classTypeList: classTypeList,
                opptysFieldList: opptysFieldList,
                orgsFieldList: orgsFieldList,
                contactsFieldList: contactsFieldList,
                casesFieldList: casesFieldList,

                // dialog
                errorDialog: false,
                errorMessage: '',
                messageDialog: false,

                // data
                loading: false,
                to_2_loading: false,
                formRule: {
                    nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_NAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ]
                },
                currentStep: 1,
                fields: [],
                selectedIndex: [],
                old_Index: [],
                selectedFields: [],
                old_Fields: [],
                customView: {},
                customView_origin: {},
            }
        },
        created(){
            if(this.customViews.length == 1)
            {
                this.addCustomViewFisrtTime()
            }
            
            let userOwnCV = this.customViews.find(e => e.owner != null);
            let seedDataCV = this.customViews.find(e => e.owner == null);
            if(this.editData.owner == null){
                this.customView = seedDataCV.definition[0];
                this.customView_origin = JSON.parse(JSON.stringify(this.customView));
            }
            else if(this.editData.owner != null){
                if(userOwnCV.owner.id == this.editData.owner.id){
                    let index = userOwnCV.definition.findIndex(e => 
                        JSON.stringify(e.fields) == JSON.stringify(this.editData.fields)
                        && e.name == this.editData.name
                    )
                    this.customView = userOwnCV.definition[index];
                    this.customView_origin = JSON.parse(JSON.stringify(this.customView));
                }
            }

            // if mode is copy
            if(this.editMode == 'COPY'){
                this.customView = JSON.parse(JSON.stringify(this.customView));
                this.customView.name = this.customView.name + '-Copy';
                
                if(this.customView.owner == null){
                    this.customView.owner = userOwnCV.owner;
                }
            }
        },
        methods: {
            addCustomViewFisrtTime(){
                if(this.className == 'Case' || this.className == 'Opportunity'){
                    // add custom view data row
                    CustomViewDataService.addCustomView(this.customViewData)
                    .then(first_res => {
                        
                        // set seed data default to array 
                        let seedData = this.customViews.find(e => e.owner == null);
                        let array = [];
                        this.stageTypeList.forEach(e => {
                            array.push(String(e.id));
                        })
                        seedData.definition[0].is_default = array;
                        
                        // upadate seed data
                        this.customViews.push(first_res.data)
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.loading = false;
                    })
                }
                else{
                    // add custom view data row
                    this.customViewData = 
                    {
                        'definition':[],
                        'class_name':this.customViews[0].class_name,
                        'owner': null
                    }
                    CustomViewDataService.addCustomView(this.customViewData)
                    .then(response => {
                        this.customViews.push(response.data)
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.loading = false;
                    })
                }
            },
            to_1(){
                this.currentStep = 1;
            },
            async to_2(){
                if(this.$refs.form.validate()){
                    await this.getfield();
                    await (this.currentStep = 2);
                }
            },
            to_3(){
                if(this.selectedIndex.length > 0){
                    this.getSelectedFields();
                    this.currentStep = 3;
                }
            },
            from_3_to_2(){
                this.old_Index = [];
                this.old_Index = this.selectedIndex;

                this.old_Fields = [];
                this.old_Fields = this.selectedFields;
                this.currentStep = 2;
            },
            getSelectedFields(){
                this.selectedFields = [];
            
                if(this.old_Index.length == 0){
                    // if get fields first time
                    this.selectedIndex.forEach( e => 
                        this.selectedFields.push(this.fields[e])
                    )
                }
                else{
                    // else

                    // 1. get already exist fields and remain the sequence
                    this.selectedFields = this.old_Fields;
                    // 2. get new add fields
                    this.selectedIndex.forEach( i => {
                        let index = this.old_Index.indexOf(i);
                        if(index == -1){
                            this.selectedFields.push(this.fields[i]);
                        }
                    })
                    // 3. remove fields that user already remove
                    this.old_Index.forEach( i => {
                        let index = this.selectedIndex.indexOf(i);
                        if(index == -1){
                            let itemIndex = this.selectedFields.findIndex(e => e.value == this.fields[i].value);
                            this.selectedFields.splice(itemIndex, 1);
                        }
                    })
                }   
            },
            async getfield(){
                this.fields = [];
                this.to_2_loading = true;
                let class_name = this.customViews[1].class_name;
                let currentFieldList;
                switch(class_name){
                    case "Contact":
                        currentFieldList = this.contactsFieldList;
                        break;
                    case "Organization":
                        currentFieldList = this.orgsFieldList;
                        break;
                    case "Opportunity":
                        currentFieldList = this.opptysFieldList;
                        break;
                    case "Case":
                        currentFieldList = this.casesFieldList;
                        break;
                    default:
                        break ;
                }
                let id = null;
                if(this.stageType != null){
                    id = this.stageType.id 
                }
                else{
                    id = null;
                }

                CustomViewDataService.getFieldList(class_name, id)
                .then(response =>{
                    let data = response.data;
                    // 一般欄位
                    for(let i = 0; i<data.fields.length; i++){
                        if (data.fields[i] != 'bc_contact_id'){
                            let value = data.fields[i];
                            let text = this.getItemNameFromList(currentFieldList , value);
                            if(!text) continue;
                            let item = {
                                text: text,
                                value: value,
                                sequence_number: null,
                                is_customfield: false
                            } 
                            this.fields.push(item)
                        }
                    }

                    // 客製化欄位
                    if(data.customField != null){
                        for(let prop in data.customField){
                            let item = {
                                text: data.customField[prop].label,
                                value: prop,
                                sequence_number: null,
                                is_customfield: true
                            } 
                            this.fields.push(item)
                        }
                    }

                    // 取得已選擇的欄位
                    this.getSelectedIndex();
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
                
                this.to_2_loading = false;
            },
            getSelectedIndex(){
                this.customView.fields.forEach(selectedItem => {
                    this.fields.forEach((element, index) =>{
                        if(element.value == selectedItem.value){
                            let isContain = this.selectedIndex.some(e => e==index);
                            if(!isContain){
                                this.selectedIndex.push(index);
                            }
                        }
                    })
                })
            },
            // dialog
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog(){
                this.errorDialog = false;
            },
            onConfirm() {
                this.loading = true;

                // add sequence number
                for(let i = 0; i<this.selectedFields.length; i++){
                    this.selectedFields[i].sequence_number = i;
                }
                this.customView.fields = this.selectedFields;

                // call api to update
                let userOwnCV = this.customViews.find(e => e.owner != null);
                let seedDataCV = this.customViews.find(e => e.owner == null);
                if(this.editData.owner != null || this.editMode == 'COPY'){
                    if(this.editMode == 'COPY'){
                        if(userOwnCV.class_name == "Opportunity" || userOwnCV.class_name == "Case"){
                            this.customView.stage_type_id = this.stageType.id; 
                        }
                        this.customView.is_default = false;
                        delete this.customView.id;
                        userOwnCV.definition.push(this.customView);
                    }
                    CustomViewDataService.updateCustomView(userOwnCV)
                    .then((response) => {
                        for(let i of response.data.definition){
                            if(i.id == response.data.max_key){
                                this.customView = i;
                                this.customView.id = userOwnCV.id;
                                this.customView.view_id = response.data.max_key;
                            }
                        }
                        this.$emit('emitEditTable', this.customView)
                        this.$emit('emitEditTableDialog', true);
                    })
                    .catch(response => {
                        this.showErrorDialog(response)
                    })
                    .finally(()=>{
                        this.loading = false;
                    }) 
                    
                    return;
                }
                if(this.editData.owner == null && this.editMode == 'EDIT'){
                    CustomViewDataService.updateCustomView(seedDataCV)
                    .then(() => {
                        this.$emit('emitEditTable', this.customView)
                        this.$emit('emitEditTableDialog', true);
                    })
                    .catch(response => {
                        this.showErrorDialog(response)
                    })
                    .finally(()=>{
                        this.loading = false;
                    }) 
                    return;
                }
            },
            onCancel(){
                if(this.editMode == 'EDIT'){
                    let userOwnCV = this.customViews.find(e => e.owner != null);
                    let seedDataCV = this.customViews.find(e => e.owner == null);
                    
                    if(this.editData.owner == null){
                        seedDataCV.definition[0].name = this.customView_origin.name;
                        seedDataCV.definition[0].is_default = this.customView_origin.is_default;
                    }
                    else if(this.editData.owner != null){
                        if(userOwnCV.owner.id == this.editData.owner.id){
                            let index = userOwnCV.definition.findIndex(e => 
                                e.fields == this.editData.fields
                            )
                            userOwnCV.definition[index].name = this.customView_origin.name;
                            userOwnCV.definition[index].is_default = this.customView_origin.is_default;
                        }
                    }
                }
                this.$emit('emitEditTableDialog', false);
            },
        }
    });
</script>
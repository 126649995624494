<template lang='pug'>
    #KnowledgeArticle.main-page.detail-page
        v-overlay(v-model='loading' z-index='99' opacity='.6')
            v-progress-circular(indeterminate color='primary')
        div.height_100(v-show='!loading')
            v-breadcrumbs.pa-0.px-3.pb-2.ellipsis(:items='breadcrumbs')
            v-row#page-head.d-flex.align-center.my-0
                v-col(cols='6' lg='6')
                    h1.my-0.d-flex.align-center(:title='kaDetail.title')
                        .cube-icon.bg-og.mr-3
                            v-icon(color='white' size='22') mdi-book-open-variant
                        span.ellipsis {{ kaDetail.title }}
                v-col(cols='6' lg='6')
                    v-row.justify-end(no-gutters='no-gutters')
                        v-col.ml-2(cols='auto')
                            .btn-group
                                v-btn(v-if='ka_id != "New" && !isPublished' @click='onChangeOwn(kaDetail)' width='100%' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.CHANGE_OWNER_3')}}
                            .btn-group(style="border: none;")
                                v-btn.ml-2(v-if='ka_id != "New" && !isPublished' @click='onDelete' width='60' height='30' color='red' depressed dark)
                                    span(style="color: white;") {{$t('GENERAL.DELETE')}}
                                v-btn.ml-2(v-if='ka_id == "New"' @click='onSave' width='60' height='32' color='green' depressed dark)
                                    span(style="color: white;") {{$t('GENERAL.SAVE')}}
                                v-btn.ml-2(v-if='ka_id != "New" && !isPublished' @click='onPublish' width='70' height='32' color='green' depressed dark)
                                    span(style="color: white;") {{$t('GENERAL.RELEASE')}}
                                v-btn(v-if='ka_id != "New" && isPublished' @click='onReStart' width='80' height='32' color='green' depressed dark)
                                    span(style="color: white;") {{$t('GENERAL.RESTART_EDITING')}}
                        //- v-col.ml-2(v-else cols='auto')
                            v-btn.mr-4(@click='onReStart()' width='100' height='32' color='green' depressed dark)
                                span 編輯文章
                            v-chip.mr-4(v-if='isProcessClosed' color='red' text-color='white') {{ closeReasonName }} 於 {{ kaDetail.closed_date }} 結案
                            v-chip.mr-4(v-if='isProcessResolved' color='green' text-color='white') 已解決，於 {{ kaDetail.closed_date }} 結案
            #page-inner.pa-3.pt-0
                v-card.card-preface.card-shadow.px-0.py-6(flat)
                    v-row
                        v-col.py-0.px-2.px-md-4.ellipsis(cols='6' md='3')
                            v-card-subtitle.pa-0.t-lightgary {{$t('KNOWLEDGE_ARTICLE_DETAIL.NUMBER')}}
                            p.f-text-highlight.mb-0 {{ kaDetail.ka_number }}
                        v-col.py-0.px-2.px-md-4.ellipsis.border-left(cols='6' md='3')
                            v-card-subtitle.pa-0.t-lightgary {{$t('KNOWLEDGE_ARTICLE_DETAIL.SUBJECT')}}
                            p.f-text-highlight.mb-0 {{ getItemFromListById(subjectList, kaDetail.subject).name }}
                        //- 未發布文章
                        v-col.py-0.px-2.px-md-4.ellipsis.border-left(v-if='isPublished == false' cols='6' md='3')
                            v-card-subtitle.pa-0.t-lightgary {{$t('KNOWLEDGE_ARTICLE_DETAIL.STATUS')}}
                            p.f-text-highlight.mb-0 {{ getItemNameFromList(kaStatusList, kaDetail.status) }}
                        //- 已發布文章
                        v-col.py-0.px-2.px-md-4.ellipsis.border-left(v-else cols='6' md='3')
                            v-card-subtitle.pa-0.t-lightgary {{$t('KNOWLEDGE_ARTICLE_DETAIL.STATUS')}}
                            p.f-text-highlight.mb-0 {{ kaDetail.status }}
                        //- 已建立文章
                        v-col.py-0.px-2.px-md-4.border-left(v-if='ka_id!="New"' cols='6' md='3')
                            v-list-item.pa-0(href='/Setting' target='_blank')
                                v-list-item-avatar.ma-0.mr-2(color='aliceBlue')
                                    span.mx-auto(v-if="!isPhoto") {{ kaDetail.owner? kaDetail.owner.last_name : '' }}
                                    img(v-else :src='kaDetail.owner.usersetting.photo' :alt='kaDetail.owner.last_name')
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('KNOWLEDGE_ARTICLE_DETAIL.AUTHOR')}}
                                    a(v-if='kaDetail.owner != null' :href='onMail(kaDetail.owner.email)').t-primary.mb-0.f-text-highlight.ellipsis {{ kaDetail.owner ? kaDetail.owner.last_name : '' }} {{ kaDetail.owner? kaDetail.owner.first_name : '' }}
                        //- 未建立文章
                        v-col.py-0.px-2.px-md-4.border-left(v-else cols='6' md='3')
                            v-list-item.pa-0(href='/Setting' target='_blank')
                                v-list-item-avatar.ma-0.mr-2(color='aliceBlue')
                                    span.mx-auto(v-if="!isPhoto") {{ userData.last_name }}
                                    img(v-else :src='userData.pic' :alt='userData.last_name')
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('KNOWLEDGE_ARTICLE_DETAIL.AUTHOR')}}
                                    a(:href='onMail(userData.email)').t-primary.mb-0.f-text-highlight.ellipsis {{ userData.first_name != '' ? userData.last_name + ' ' +userData.first_name : userData.last_name }}
                v-row.mt-4(no-gutters='no-gutters')
                    v-col(cols='12' md='12')
                        v-card.deal-tab-card(flat)
                            v-tabs.main-tabs(background-color='transparent' show-arrows)
                                v-tabs-slider
                                v-tab(v-for='item in tabs' :key='item.key' :href='`#tab-${item.id}`') {{ item.name }}
                                // - Detail -
                                v-tab-item.pa-3(key='detail' value='tab-1' :transition='false' :reverse-transition='false')
                                    v-form(v-if='ka_id=="New"' v-model="valid" ref='formKaDetail')
                                        v-row(no-gutters).pb-5
                                            v-col.pr-md-4.my-1(cols='12' md='3')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('KNOWLEDGE_ARTICLE_DETAIL.TITLE')}}
                                                    v-text-field.ellipsis(v-model='kaDetail.title' solo='solo' :rules="formRule.titleRules")
                                            v-col.pr-md-4.my-1(cols='12' md='3')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('KNOWLEDGE_ARTICLE_DETAIL.AUTONUMBER')}}
                                                    v-text-field.ellipsis.no-pointer-event(v-if='autoNumberCheck' v-model='kaDetail.ka_number' solo='solo' :readonly='true' :label=`$t('KNOWLEDGE_ARTICLE_DETAIL.AUTONUMBER_1')`,)
                                                    v-text-field.ellipsis(v-else v-model='kaDetail.ka_number' solo='solo' :rules="formRule.KAnumRules")
                                            v-col.pr-md-4.my-1(cols='12' md='3')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('KNOWLEDGE_ARTICLE_DETAIL.SUBJECT_1')}}
                                                    v-select.no-border(v-model='kaDetail.subject' solo :items='subjectList' item-text='name' item-value='id' :menu-props='{closeOnClick:true}')
                                            v-col.pr-md-4.my-1(cols='12' md='3')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('KNOWLEDGE_ARTICLE_DETAIL.STATUS_2')}}
                                                    v-select.no-border(v-model='kaDetail.status' solo :items='kaStatusList' item-text='name' item-value='key' :menu-props='{closeOnClick:true}')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('KNOWLEDGE_ARTICLE_DETAIL.KEYWORD')}}
                                                    v-text-field.ellipsis(v-model='kaDetail.keywords' solo='solo' :rules="formRule.keywordRules")  
                                            v-col.pr-md-4.my-1(cols='12' md='12')
                                                p.d-flex.align-start.input-has-label
                                                    label(style='margin-top: 8px;') {{$t('KNOWLEDGE_ARTICLE_DETAIL.DESCRIPTIONL')}}
                                                    v-textarea.textarea-style(v-model='kaDetail.description' solo dense='dense' rows='3')
                                    v-form(v-else v-model="valid" ref='formKaDetail')
                                        v-row(no-gutters)
                                            v-col.pr-md-4.my-1(cols='12' md='3')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('KNOWLEDGE_ARTICLE_DETAIL.TITLE')}}
                                                    v-text-field#caseName.ellipsis(v-model='kaDetail.title' solo='solo' :readonly='isDetailReadonly' :rules="formRule.titleRules")
                                                        template(v-if='!isPublished' v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_kaDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_kaDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_kaDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='3')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('KNOWLEDGE_ARTICLE_DETAIL.AUTONUMBER')}}
                                                    v-text-field#caseName.ellipsis(v-model='kaDetail.ka_number' :rules="formRule.KAnumRules" solo='solo' :readonly='isDetailReadonly || autoNumberCheck' )
                                                        template(v-if='!isPublished && !autoNumberCheck' v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_kaDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_kaDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_kaDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='3')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label {{$t('KNOWLEDGE_ARTICLE_DETAIL.SUBJECT_1')}}
                                                    v-select.no-border(v-model='kaDetail.subject' solo :items='subjectList' item-text='name' item-value='id' :readonly='isDetailReadonly' :menu-props='{closeOnClick:true}')
                                                        template(v-if='!isPublished' v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_kaDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_kaDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_kaDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='3')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label {{$t('KNOWLEDGE_ARTICLE_DETAIL.STATUS_2')}}
                                                    v-text-field#caseName.ellipsis(v-if='isPublished == true' v-model='kaDetail.status' solo='solo' :readonly='isDetailReadonly')
                                                    v-select.no-border(v-else v-model='kaDetail.status' solo :items='kaStatusList' item-text='name' item-value='key' :readonly='isDetailReadonly' :menu-props='{closeOnClick:true}')
                                                        template(v-if='!isPublished' v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_kaDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_kaDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_kaDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('KNOWLEDGE_ARTICLE_DETAIL.KEYWORD')}}
                                                    v-text-field#caseName.ellipsis(v-model='kaDetail.keywords' solo='solo' :readonly='isDetailReadonly' :rules="formRule.keywordRules")
                                                        template(v-if='!isPublished' v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_kaDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_kaDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_kaDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='12')
                                                p.d-flex.align-start.input-has-label
                                                    label(style='margin-top: 8px;') {{$t('KNOWLEDGE_ARTICLE_DETAIL.DESCRIPTIONL')}}
                                                    v-textarea.textarea-style(v-model='kaDetail.description' solo :readonly='isDetailReadonly' dense='dense' rows='3')
                                                        template(v-if='!isPublished' v-slot:append-outer)
                                                            v-icon.mx-1(size='18' color="green" v-if='!isDetailReadonly && !loading_kaDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_kaDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon.ml-2(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular.ml-2(size='18' v-if='loading_kaDeatil' indeterminate color='primary')
                v-row.mt-4(no-gutters='no-gutters')
                    v-card(flat).w-100
                        v-col(v-if='ka_id != "New" && !isPublished' cols='12' md='12' ).pb-0
                            .w-100.d-flex.justify-end.align-center(style='height:36px;')
                                v-btn.ml-3(v-if='!isContentReadonly && !loading_kaContent' @click='confirmEditContent' width='70' text color='success')
                                    v-icon(size='20') icon-check
                                    span {{$t('GENERAL.SAVE')}} 
                                v-btn(v-if='!isContentReadonly && !loading_kaContent' @click='cancelEditContent' width='70' text color='gary')
                                    v-icon(size='20') icon-cancel
                                    span {{$t('GENERAL.CANCEL')}} 
                                v-btn.ml-3(v-if='isContentReadonly' @click='onEditContent' width='70' text color='gary' )
                                    v-icon(size='20') icon-edit
                                    span {{$t('GENERAL.EDIT')}} 
                                v-progress-circular(size='20' v-if='loading_kaContent' indeterminate color='primary')
                        v-col(v-if='ka_id == "New"' cols='12' md='12')
                            //- v-card(flat)
                            vue-editor(v-model='kaDetail.content' :editorOptions='editorSettings' :customModules="customModulesForEditor")
                        v-col(v-else cols='12' md='12')
                            //- v-card(flat)
                            vue-editor.can-select-text( v-model='kaContent' :disabled='isContentReadonly' :editorOptions='editorSettings' :customModules="customModulesForEditor")
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        //-change own or process
        v-dialog(v-model='changeOwnDialog' max-width='500' v-if='changeOwnDialog')
            change-own(@emitChangeOwnDialog='onEmitChangeOwnDialog' :owner-data='changeOwnData' :other-data='otherData')
        //- v-dialog(v-model='activityDialog' width='470' data-app='true')
        //-     activity-dialog(@emitActivityDialog='onEmitActivityDialog')
</template>

<script>
    // dialog
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';

    import changeOwn from '@/components/Dialog/changeOwn';

    // list

    // dataservice
    import AutoNumberService from "@/services/AutoNumberService";
    import KnowledgeArticleDataService from "@/services/KnowledgeArticleDataService";
    // vue editor
    import { VueEditor } from "vue2-editor";
    import ImageResize from "quill-image-resize-vue";
    import i18n from '/common/plugins/vue-i18n.js' 

    // import newsDisplay from '@/components/Common/newsDisplay';
    // import activityDialog from '@/components/Dialog/activityDialog';
    export default {
        name: 'KnowledgeArticle',
        components: {
            // dialog
            messageDialog,
            successDialog,
            errorDialog,

            changeOwn,
            // plug in
            VueEditor,

            // newsDisplay,
            // activityDialog,
        },
        data() {
            return {
                // data
                loading: true,
                ka_id: '',
                kaDetail: {},
                kaContent: "",
                breadcrumbs: [],
                isPhoto: false,
                isPublished: false,
                userData: {},
                loading_kaDeatil: false,
                loading_kaContent: false,

                // validation
                valid: true,
                validForm: {
                    basic: true,
                    detail: true,
                },

                // dialog
                messageDialog: false,
                message: '',
                msgDialogSituation: '',
                successDialog: false,
                errorDialog: false,
                errorMessage: '',

                changeOwnDialog: false,
                changeOwnData: {},
                otherData: {},

                // activityDialog: false,

                // list
                subjectList: [],
                kaStatusList: [
                    { key: "WAIT_PUBLISH", name: i18n.t('KASTATUSLIST.WAIT_PUBLISH') },
                    { key: "IN_REVIEW", name: i18n.t('KASTATUSLIST.IN_REVIEW') },
                    { key: "AUTHORING", name: i18n.t('KASTATUSLIST.AUTHORING') },
                ],
                
                // form rule
                formRule: {
                    titleRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_TITLE'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    keywordRules: [
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                    ],
                    KAnumRules: [
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                },
                
                // tab
                tabs: [
                    {
                        id: 1,
                        key: 'detail',
                        name: i18n.t('KNOWLEDGE_ARTICLE_DETAIL.DETAIL')
                    },
                ],

                // 詳細資訊 edit
                autoNumberCheck:null,
                isDetailReadonly: true,
                isContentReadonly: true,

                // original data
                originDetailData: {},
                originContentData: "",

                // funtions
                succMode: '',

                // vue editor
                customModulesForEditor: [
                    { alias: "imageResize", module: ImageResize }
                ],
                editorSettings: {
                    modules: {
                        imageResize: {},
                        toolbar:[
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ 'align': '' },{ 'align': 'center' },{ 'align': 'right' }, { 'align': 'justify' }],                            
                            ['blockquote', 'code-block'],
                            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                            [{ 'script': 'sub'}, { 'script': 'super' }],
                            [{ 'indent': '-1'}, { 'indent': '+1' }],
                            [{ 'direction': 'rtl' }],
                            [{ 'color': [] }, { 'background': [] }],
                            ['link'],
                            ['clean'] 
                            ]
                        },
                },
            }
        },
        mounted() {
            window.setTimeout(( () => this.loading = false), 1000);
        },
        created() {
            this.ka_id = this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id');
            if (this.ka_id == 'New') {
                this.settingNewArticle();
            } else {
                this.settingArticle();
            }

            KnowledgeArticleDataService.subjectlist()
            .then((response)=>{
                this.subjectList = response.data;
            });

            AutoNumberService.list()
            .then(response => {
                this.autoNumberCheck = response.data.filter(el => {return el.using_page == "KNOWLEDGE_ARTICLE"})[0].active;
            })
            .catch((response) => {
                this.showErrorDialog(response);
            });
        },
        methods: {
            validateForm(){
                return this.$refs.formKaDetail.validate();
            },
            //- message/success/error dialog
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val) {
                    if (this.succMode == "RESTART") {
                        let data = {
                            id: this.ka_id,
                            title: this.kaDetail.title,
                            status: "AUTHORING",
                        }

                        KnowledgeArticleDataService.edit(data)
                        .then(() => {
                            this.kaDetail.status = "AUTHORING";
                            this.isPublished = false;
                        })
                        .catch(error => {
                            this.showErrorDialog(error);
                        });
                    } else if (this.succMode == "PUBLISH") {
                        let data = {
                            id: this.kaDetail.id,
                            title: this.kaDetail.title,
                            status: "PUBLISHED",
                        }

                        KnowledgeArticleDataService.edit(data)
                        .then(() => {
                            this.kaDetail.status = i18n.t('KASTATUSLIST.PUBLISHED');
                            this.showSuccessDialog();
                            this.isPublished = true;
                        })
                        .catch(error => {
                            this.showErrorDialog(error);
                        });
                    } else if (this.succMode == "CHANGEOWN") {
                        this.kaDetail.owner = this.changeOwnData;
                    } else if (this.succMode == "DELETE") {
                        KnowledgeArticleDataService.delete(this.ka_id)
                        .then(() => {
                            this.showSuccessDialog();
                        })
                        .catch(error => {
                            this.showErrorDialog(error);
                        });
                    }
                }
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
                if (this.succMode == "DELETE") {
                    this.$router.replace('/KnowledgeArticles');
                }
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            //- set new knowledge article
            settingNewArticle() {
                this.breadcrumbs = [
                    {
                        text: i18n.t('KNOWLEDGE_ARTICLE_DETAIL.KNOWLEDGEARTICLES'),
                        disabled: false,
                        href: '/KnowledgeArticles',
                    },
                    {
                        text: i18n.t('KNOWLEDGE_ARTICLE_DETAIL.ADD_KNOWLEDGEARTICLES'),
                        disabled: true,
                        href: 'KnowledgeArticle',
                    },
                ];
                
                this.kaDetail = {
                    title: "",
                    ka_number: "",
                    subject: "",
                    status: "AUTHORING",
                    keywords: "",
                    description: "",
                    content: "",
                }

                const USER = 'user';
                this.userData = JSON.parse(window.localStorage.getItem(USER));

                if (this.userData.pic != undefined)
                    this.isPhoto = true;
            },
            //- set exist  knowledge article
            settingArticle() {
                KnowledgeArticleDataService.detail(this.ka_id)
                .then(response => {
                    this.kaDetail = {
                        id: response.data.id,
                        title: response.data.title,
                        ka_number: response.data.ka_number,
                        subject: response.data.subject,
                        status: response.data.status,
                        keywords: response.data.keywords,
                        description: response.data.description,
                        owner: response.data.owner,
                    };
                    const title = localStorage.getItem('title');    
                    document.title = title+' : '+this.kaDetail.title +' – UpDay'; 

                    this.kaContent = response.data.content;

                    if (this.kaDetail.owner.usersetting.photo != null)
                        this.isPhoto = true;
                    if (this.kaDetail.status == "PUBLISHED") {
                        this.isPublished = true;
                        this.kaDetail.status = i18n.t('KASTATUSLIST.PUBLISHED')
                    }

                    this.breadcrumbs = [
                        {
                            text: i18n.t('BREADCRUMBS.KnowledgeArticles'),
                            disabled: false,
                            href: '/KnowledgeArticles',
                        },
                        {
                            text: this.kaDetail.title,
                            disabled: true,
                            href: 'KnowledgeArticle',
                        },
                    ];
                }).catch(response => {
                    this.showErrorDialog(response);
                    // Redirect to PermissionError page if error status is 403
                    if (response.response.status == 403)
                    {
                        this.$router.replace('/PermissionError');
                    }
                    else if(response.response.status == 404)
                    {
                        this.$router.replace('/PageNotFound');
                    }
                });
            },
            //- edit detail
            onEditDetail() {
                this.originDetailData = JSON.parse(JSON.stringify(this.kaDetail));
                this.isDetailReadonly = false;
            },
            async confirmEditDetail() {
                if (this.validateForm()) {
                    await (this.loading_kaDeatil = true);
                    delete this.kaDetail.owner;
                    await KnowledgeArticleDataService.edit(this.kaDetail)
                    .then(response => {
                        this.kaDetail.owner = response.data.owner;
                        this.isDetailReadonly = true;
                        this.loading_kaDeatil = false;
                    })
                    .catch(error => {
                        this.showErrorDialog(error);
                    });
                }
                
            },
            cancelEditDetail() {
                this.kaDetail = this.originDetailData;
                this.isDetailReadonly = true;
            },
            // edit content
            onEditContent() {
                this.originContentData = JSON.parse(JSON.stringify(this.kaContent));
                this.isContentReadonly = false;
            },
            async confirmEditContent() {
                if (this.kaContent == "") {
                    this.showErrorDialog(i18n.t('KNOWLEDGE_ARTICLE_DETAIL.ERROR'));
                    return;
                } else {
                    await (this.loading_kaContent = true);
                    let data = {
                        id: this.ka_id,
                        title: this.kaDetail.title,
                        content: this.kaContent,
                    }
                    KnowledgeArticleDataService.edit(data)
                    .then(() => {
                        this.isContentReadonly = true;
                        this.loading_kaContent = false;
                    })
                    .catch(error => {
                        this.showErrorDialog(error);
                    })
                }
                
            },
            cancelEditContent() {
                this.kaContent = this.originContentData;
                this.isContentReadonly = true;
            },
            // delete
            onDelete () {
                this.succMode = "DELETE";
                this.showMessageDialog(i18n.t('KNOWLEDGE_ARTICLE_DETAIL.MESSAGE'));
            },
            // change author
            async onChangeOwn(data) {
                await (this.otherData.ka_id = data.id);
                await (this.otherData.title = data.title);
                this.changeOwnData = this.kaDetail.owner;
                await (this.changeOwnDialog = true);
            },
            onEmitChangeOwnDialog(val) {
                this.changeOwnDialog = false;
                if (val) {
                    this.succMode = "CHANGEOWN";
                    this.showSuccessDialog();
                }
            },
            onSave() { // save new article
                if (this.validateForm()) {
                    if (this.kaDetail.content == '') {
                        this.showErrorDialog(i18n.t('KNOWLEDGE_ARTICLE_DETAIL.ERROR'));
                        return;
                    } else {
                        delete this.kaDetail.owner;
                        KnowledgeArticleDataService.create(this.kaDetail)
                        .then(() => {
                            this.$router.replace("/KnowledgeArticles");
                        })
                        .catch(error => {
                            this.showErrorDialog(error);
                        });
                    }
                }

            },
            onPublish() { // publish article
                this.showMessageDialog(i18n.t('KNOWLEDGE_ARTICLE_DETAIL.MESSAGE_2'));
                this.succMode = "PUBLISH";
            },
            onReStart() { // restart edit this article
                this.showMessageDialog(i18n.t('KNOWLEDGE_ARTICLE_DETAIL.MESSAGE_3'));
                this.succMode = "RESTART";
            },
            // common function
            onMail: function (mail) {
                return 'mailto:' + mail;
            },
        },
    }
</script>

<style>
    .country-code div {
        padding-top: 0;
        margin-top: 0;
    }

    .vue-tel-input-vuetify div {
        padding-top: 0;
        margin-top: 0;
    }

    .hide-scrollbar textarea {
        overflow-y: hidden;
    }

    table thead tr th {
        font-size: 14px !important;
    }
</style>
<template lang="pug">
v-card.noteDialog
    v-card-title.d-flex.justify-center {{$t('END_CUSTOMER.EDIT')}}
    v-card-text
        v-form(v-model='valid' ref='form')
            v-text-field.no-border(v-model="editEndCustomer.name",prepend-icon="mdi-tag",:rules="formRule.nameRule", :label='end_customer_name' )
            v-row.mb-4(no-gutters)
                v-col(cols='12' md='12' v-for='(item,index) in field_definition' :key="item.field_key")
                    div(v-if='item.format=="str"' :key='item.index' )
                        v-text-field(v-model='editEndCustomer[item.field_key]' :label=`item.field_name` prepend-icon='mdi-tag')
                    div(v-if='item.format=="dat"' :key='item.index')
                        v-menu(ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                            template(v-slot:activator="{ on, attrs }")
                                v-text-field(v-model='editEndCustomer[item.field_key]' v-bind="attrs" v-on="on" :label=`item.field_name` prepend-icon='mdi-tag' readonly clearable=true)
                            v-date-picker(v-model='editEndCustomer[item.field_key]' @input='item.menu = false' no-title scrollable)
                    div(v-if='item.format=="opt"' :key='item.index')
                        v-autocomplete(v-model='editEndCustomer[item.field_key]'
                            :items="item.option",
                            item-text="value",
                            item-value="key",
                            :menu-props='{closeOnClick:true}',
                            :label=`item.field_name` prepend-icon='mdi-tag')
                    div(v-if='item.format=="fil"' :key='item.field_key' )
                        v-file-input.no-border(v-model='file' :placeholder="ori_file_name" :label=`item.field_name` prepend-icon='mdi-file-document', @change='checkFile', :rules='formRule.fileRule')
    v-card-actions.px-2.py-4
        v-row
            v-col.pr-2(cols='6')
                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pl-2(cols='6')
                v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed :disabled='upLoading') 
                    v-progress-circular(v-if='upLoading' indeterminate size='20')
                    .t-white(v-else) {{$t('GENERAL.CONFIRM')}}
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
	import Vue from 'vue';
    import EndCustomerDataService from '@/services/EndCustomerDataService';
    import DatePicker from 'vue2-datepicker';
    import i18n from '/common/plugins/vue-i18n.js' 
    import messageDialog from '@/components/Dialog/redirectDialog';
    import errorDialog from "@/components/Dialog/errorDialog";

	export default Vue.extend({
    props: {
        type_key:{
            type: String,
            require: true,
        },
        organization_id:{
            type: String,
            require: true,
        },
        field_definition:{
            type: Array,
            require: true,
        },
        editData:{
            type: Object,
            require: true,
        },
    },
    components: {
        DatePicker,
        messageDialog,
        errorDialog,
    },
    created(){
        this.resetEditData();
    },
        data() {
			return {
                end_customer_name:i18n.t('END_CUSTOMER.NAME'),
                upLoading:false,
                menu: false,
                openDatePicker:null, 
                editEndCustomer:{},
                fileSize:false,
                valid: true,
                messageDialog:false,
                message:"",
                errorMessage:'',
                errorDialog:false,
                formRule: {
                    fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')], 
                    nameRule:[(v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')],
                    fileRule: [() => { return (!this.fileSize || i18n.t('RULE.RULE_FILE_3'))}],
                },
                file:null,
                ori_file_name:'',
			}
		},
        methods: {
            resetEditData(){
                this.file = null;
                this.editEndCustomer = this.editData;
                for(let fd in this.field_definition){
                    if(this.field_definition[fd].format == 'fil'){
                        if(this.editEndCustomer[this.field_definition[fd].field_key] != null){
                            let url = this.editEndCustomer[this.field_definition[fd].field_key];
                            let url_split_arr = url.split("/");
                            this.ori_file_name = url_split_arr[url_split_arr.length - 1];
                        }
                    }
                }
            },
            datepickerFocus(item){
                this.openDatePicker = item;
            },
            datepickerChange(value, type){
                if (type === 'minute') {
                    this.openDatePicker.menu = false;
                }
            },
			validate() {
				this.$refs.form.validate();
			},
            validateForm(){
                if(this.$refs.form.validate())
                    return true;
                else
                    return false;
            },
			async onConfirm() {
                if(this.validateForm()&& !this.fileSize ){
                    this.upLoading = true;
                    let changeData={
                        name:this.editEndCustomer.name,
                        type:this.type_key,
                        organization:this.organization_id,
                    };
                    let detail_field = {};
                    for(let fd in this.field_definition){
                        let value = this.editEndCustomer[this.field_definition[fd].field_key] !== undefined ? this.editEndCustomer[this.field_definition[fd].field_key] : null;
                        detail_field[this.field_definition[fd].field_key] = value;
                    }
                    changeData['detail_field'] = detail_field
                    await EndCustomerDataService.edit(this.editEndCustomer.id, changeData, this.file).then(response => {
                        let data = response.data;
                        this.$emit('emitEditDialog',data);
                        this.editEndCustomer = {};
                        this.file = null;
                        this.upLoading = false;
                    });
                }
			},
			onCancel() {
                this.resetEditData();
                this.file = null;
				this.$emit('emitEditDialog', true);
			},
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            checkFile(event) {
                if (event != null) {
                    if (event.size > 20971520) {
                        this.fileSize = true;
                    } else {
                        this.fileSize = false;
                    }
                } else {
                    this.fileSize = false;
                }
            },
		},
        watch:{
            "editData": function(){
                this.resetEditData();
            },
        }
	});
</script>


<template lang='pug'>
#Companies.main-page.page-noScroll.pb-0
  loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
  v-row#page-head.d-flex.align-center.justify-md-space-between(v-if='!loading')
    v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(
      cols="3",
      md="4"
    )
      h1.my-0 {{$t('COMPANY.TITLE')}}
      v-btn.ml-3.add-deal-btn(
        @click="showAddAccount",
        width="40",
        height="40",
        :ripple="false",
        color="success",
        depressed,
        dark,
        fab
      )
        v-icon(size="16") icon-add
      v-btn.ml-3.add-deal-btn(v-if='selectedRows.length>0' @click='showBulkEditDialog()' width='40' height='40' :ripple='false' color='success' depressed dark fab :disabled="!checkWiadvanceShow()")
        v-icon(size='16') icon-edit
      v-btn.ml-3.add-deal-btn(v-if='selectedRows.length>0' @click='follow()' :title="$t('FOLLOW.ORG')" width='40' height='40' :ripple='false' color='success' depressed dark fab :disabled="!checkWiadvanceShow()")
            v-icon(size='18') mdi-star
    v-col(cols="9", md="8")
      v-row.justify-md-end(no-gutters="no-gutters")
        v-col(cols="auto")
          v-progress-circular(size='18' v-if='loading_content || loading_view' class='mr-3' indeterminate color='primary')
          .btn-group
            v-btn(
              @click="onCopy(selectedRows[0])", :disabled="selectedRows.length > 1 || selectedRows.length == 0 || !checkWiadvanceShow()", width="80", height="38", color="white", depressed,  tile, dark)
              v-icon(size="16") icon-edit
              span {{$t('GENERAL.COPY')}}
            v-btn.border-left(@click="onDelete()" :disabled="selectedRows.length == 0 || !checkWiadvanceShow()" width="80" height="38" color="white" depressed tile dark)
              v-icon(size="16") icon-remove
              span {{$t('GENERAL.DELETE')}}
        v-col(cols='auto').ml-4.d-flex.btn-group-with-label-contact
            label {{$t('FILTERSETTING.CS')}}
            .btn-group.ml-4 
              setting_btn(:filter_list='filter_list',:tables='tables',:currentTable='currentTable',:default_filter='default_filter' , tab='Organization', :is_staff='is_staff' :stage-type-list='[]' @emitView='onEmitView' @emitFilter='onEmitFilter' :customViews='customViews')
        v-col(cols='auto').ml-4.d-flex
            .btn-group
                v-text-field( v-model="searchAccount" flat dense solo prepend-inner-icon="icon-search")
            importExportBtn(@emitFilter='onEmitFilter' :page='"Organization"' :selectStageType='null' :search='searchAccount' :filter='default_filter.id')
  #page-inner.scroll-x.pt-md-6.pb-0.height_100vh
    v-form(v-model='valid' ref='form').data-area
      v-data-table.deals-table(
        :headers="accountHeader",
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
        :items="accountData",
        :items-per-page="itemPerPage",
        :page.sync="page",
        :page-count="pageCount",
        :sort-desc.sync='sortByDesc',
        :sort-by.sync="sortBy",
        multi-sort 
        height="100%",
        hide-default-footer="hide-default-footer",
        show-select,
        item-key="id",
        v-model="selectedRows",
        loading-text="Loading...",
        :loading='loading_edit'
        v-if='reRender()'
        v-resize-table="headerData"
        fixed-header
        ref='companyTable'
      )
        //- 公司名稱 name
        template(v-slot:item.name="{ item }")
            div(class=' d-flex justify-left position-relative' :title='item.name' )
                followIcon(:follow='item.follow')
                v-list-item-group(class='inline-edit-cell justify-start')
                    v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="40")
                        v-icon(v-if="item.image == null") mdi-domain 
                        img(v-else :src="item.image", :alt="item.name")
                    a(:href="'Account/'+ item.id " class='ellipsis') {{item.name}}
                inlineEditDialog(v-if="!isWiadvance()" field_type='orgname' editProp='name' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 電話 main_phone
        template(v-slot:item.main_phone="{ item }")
            div(class='d-flex justify-center align-center' :title='item.main_phone')
                .inline-edit-cell
                    a(v-if='!!item.main_phone' :href="onCall(item.main_phone)" class='ml-8 ellipsis') {{ item.main_phone }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='text_len50' editProp='main_phone' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 統一編號 company_number
        template(v-slot:item.company_number="{ item }")
            div(class='d-flex justify-center ' :title='item.company_number')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.company_number}}
                inlineEditDialog(v-if="!isWiadvance()" field_type='text_len20' editProp='company_number' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 類型 organization_type
        template(v-slot:item.organization_type='{ item }') 
            div(class='d-flex justify-center' :title='getItemNameFromList(orgTypeList, item.organization_type)')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{ getItemNameFromList(orgTypeList, item.organization_type) }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='select' editProp='organization_type' :editItem='item' :selectList='orgTypeList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')

        //- 行業別 industry
        template(v-slot:item.industry='{ item }') 
            div(class='d-flex justify-center' :title='getItemNameFromList(industryList, item.industry)')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{ getItemNameFromList(industryList, item.industry) }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='select' editProp='industry' :editItem='item' :selectList='industryList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')

        //- 營業額 annual_revenue
        template(v-slot:item.annual_revenue='{ item }') 
            div(class='d-flex justify-center' :title='getFormatAmount(item.annual_revenue)')
                .inline-edit-cell.justify-end
                    span(class='ml-8 mr-2 ellipsis') {{ getFormatAmount(item.annual_revenue) }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='int' editProp='annual_revenue' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
            
        //- 資本額 registered_capital
        template(v-slot:item.registered_capital='{ item }') 
            div(class='d-flex justify-center' :title=' getFormatAmount(item.registered_capital)')
                .inline-edit-cell.justify-end
                    span(class='ml-8 mr-2 ellipsis') {{ getFormatAmount(item.registered_capital) }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='bigint' editProp='registered_capital' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 進行商機總額 total_amount
        template(v-slot:item.total_amount='{ item }') 
            .ellipsis.d-flex.justify-end
                span(:title='item.total_amount_adj') {{ item.total_amount_adj }}

        //- 網站 website
        template(v-slot:item.website="{ item }")
            div(class='d-flex justify-center ' :title='item.website')
                .inline-edit-cell
                    a(class='ml-8 ellipsis' v-if='item.website != null' :href='item.website' target="_blank") {{ item.website }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='url' editProp='website' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
        
        //- 相關活動 primary_campaign_id
        template(v-slot:item.primary_campaign_id="{ item }")
            div(class='d-flex justify-center ' :title='getItemNameFromListById(campaignList, item.primary_campaign_id)')
                .inline-edit-cell
                    a(class='ml-8 ellipsis' v-if='item.primary_campaign_id != null' :href='"Campaign/"+item.primary_campaign_id' target="_blank") {{ getItemNameFromListById(campaignList, item.primary_campaign_id) }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='autocomplete' editProp='primary_campaign_id' :selectList='campaignList' item-text="name" item-value="id" :editItem='item' @emitInlineEdit='onEmitInlineEdit')
        
        //- 傳真 fax
        template(v-slot:item.fax="{ item }")
            div(class='d-flex justify-center ' :title='item.fax')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.fax}}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='text_len20' editProp='fax' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- Email Email
        template(v-slot:item.email="{ item }")
            div(class='d-flex justify-center ' :title='item.email')
                .inline-edit-cell
                    a(class='ml-8 ellipsis' v-if='item.email != null' :href='onMail(item.email)') {{ item.email }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='email' editProp='email' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 地址 billing_address
        template(v-slot:item.billing_address="{ item }")
            div(class='d-flex justify-center ' :title='item.billing_address')
                .inline-edit-cell
                    a(class='ml-8 ellipsis' v-if='item.billing_address != null' :href='onGoogleMap(item.billing_address)') {{ item.billing_address }}
                inlineEditDialog(v-if="!isWiadvance()" field_type='text' editProp='billing_address' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 地址2 sub_address
        template(v-slot:item.sub_address="{ item }")
            div(class='d-flex justify-center ' :title='item.sub_address')
                .inline-edit-cell
                    a(class='ml-8 ellipsis' v-if='item.sub_address != null' :href='onGoogleMap(item.sub_address)') {{ item.sub_address }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='text' editProp='sub_address' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- (英)公司名稱
        template(v-slot:item.english_department="{ item }")
            div(class='d-flex justify-center ' :title='item.english_department')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.english_department}}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='text_len100' editProp='english_department' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 付款條款
        template(v-slot:item.payment_term="{ item }")
            div(class='d-flex justify-center ' :title='getItemNameFromList(wiadvancePaymentTermList,item.payment_term)')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{getItemNameFromList(wiadvancePaymentTermList,item.payment_term)}}
                    span(v-if="!isWiadvance()" class='ml-8 ellipsis') {{getItemNameFromList(paymentTermsList,item.payment_term)}}
                    inlineEditDialog(v-if="!isWiadvance()" field_type='autocomplete' editProp='payment_term' :selectList='paymentTermsList' item-text="name" item-value="key" :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 固定付款日
        template(v-slot:item.regular_payment_date="{ item }")
            div(class='d-flex justify-center ' :title='getItemArrayNameFromList(dateList, item.regular_payment_date)')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{getItemArrayNameFromList(dateList, item.regular_payment_date)}}
                    inlineEditDialog(v-if='checkWiadvanceShow()' field_type='autocomplete_multiple' editProp='regular_payment_date' :selectList='dateList' item-text="name" item-value="key" :editItem='item' @emitInlineEdit='onEmitInlineEdit' page='contacts')
        //- 關帳日
        template(v-slot:item.closing_date="{ item }")
            div(class='d-flex justify-center ' :title='getItemArrayNameFromList(dateList,item.closing_date)')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{getItemArrayNameFromList(dateList,item.closing_date)}}
                    inlineEditDialog(v-if='checkWiadvanceShow()' field_type='autocomplete_multiple' editProp='closing_date' :selectList='dateList' item-text="name" item-value="key" :editItem='item' @emitInlineEdit='onEmitInlineEdit' page='contacts')

        //- customer code
        template(v-slot:item.customer_code="{ item }")
            div(class='d-flex justify-center ' :title='item.customer_code')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.customer_code}}
                    inlineEditDialog(v-if='isWiadvance()' field_type='customer_code_selectX' editProp='customer_code' :editItem='item' :selectList='customer_code_List' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')
                    inlineEditDialog(v-else field_type='text_len100X' editProp='customer_code' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
        
        //- 所屬集團
        template(v-slot:item.belong_group="{ item }")
            div(class='d-flex justify-center ' :title='item.belong_group')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.belong_group}}
                    inlineEditDialog(v-if='isWiadvance()' field_type='autocomplete' editProp='belong_group' :selectList='belongGroupList' item-text="name" item-value="key" :editItem='item' @emitInlineEdit='onEmitInlineEdit')
        //- 員工人數 employee
        template(v-slot:item.employee="{ item }")
            div(class='d-flex justify-center ' :title='item.employee')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.employee}}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='int' editProp='employee' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
        
        //- 城市 city 
        template(v-slot:item.city='{ item }')
            div(class='d-flex justify-center ' :title='item.city')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.city}}
                inlineEditDialog(v-if="!isWiadvance()" field_type='text_len50' editProp='city' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
        
        //- 國家 country 
        template(v-slot:item.country='{ item }')
            div(class='d-flex justify-center ' :title='item.country')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.country}}
                inlineEditDialog(v-if="!isWiadvance()" field_type='text_len20' editProp='country' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 省分 province 
        template(v-slot:item.province='{ item }')
            div(class='d-flex justify-center ' :title='item.province')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.province}}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='text_len50' editProp='province' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 郵遞區號 zip-code
        template(v-slot:item.zip_code='{ item }')
            div(class='d-flex justify-center ' :title='item.zip_code')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.zip_code}}
                inlineEditDialog(v-if="!isWiadvance()" field_type='text_len10' editProp='zip_code' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- facebook facebook
        template(v-slot:item.facebook='{ item }')
            div(class='d-flex justify-center ' :title='item.facebook')
                .inline-edit-cell
                    a(class='ml-8 ellipsis' v-if='item.facebook != null' :href='item.facebook') {{ item.facebook }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='url' editProp='facebook' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
        
        //- linkedin linkedin
        template(v-slot:item.linkedin='{ item }')
            div(class='d-flex justify-center ' :title='item.linkedin')
                .inline-edit-cell
                    a(class='ml-8 ellipsis' v-if='item.linkedin != null' :href='item.linkedin') {{ item.linkedin }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='url' editProp='linkedin' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- Line Line
        template(v-slot:item.line_id='{ item }')
            div(class='d-flex justify-center ' :title='item.line_id')
                .inline-edit-cell
                    a(class='ml-8 ellipsis' v-if='item.line_id != null' :href='"line://ti/p/" + item.line_id') {{ item.line_id }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='text_len50' editProp='line_id' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- instagram_id instagram_id
        template(v-slot:item.instagram_id='{ item }')
            div(class='d-flex justify-center ' :title='item.instagram_id')
                .inline-edit-cell
                    a(class='ml-8 ellipsis' v-if='item.instagram_id != null' :href='"https://www.instagram.com/"+item.instagram_id+"/"') {{ item.instagram_id }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='text_len50' editProp='instagram_id' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 擁有者 owner
        template(v-slot:item.owner="{ item }")
            div(class=' d-flex justify-left position-relative' :title='item.owner.last_name+" "+item.owner.first_name' )
                v-list-item-group(class='inline-edit-cell justify-start')
                    v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="40")
                        v-icon(v-if="item.owner == null") mdi-account 
                        v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
                        img(v-else :src='item.owner.usersetting.photo')
                    a(v-if='!!item.owner' :href='onMail(item.owner.email)' class='ellipsis') {{ item.owner.last_name+' '+item.owner.first_name }}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='select' editProp='owner' :editItem='item' :selectList='ownerList' item-text="fullName" @emitInlineEdit='onEmitInlineEdit')
                        
        //- 更新者 updated_by
        template(v-slot:item.updated_by='{ item }')
            div(class='d-flex w-100 align-center justify-start ml-8' :title='item.updated_by.last_name+" "+item.updated_by.first_name')
                v-list-item-avatar.ma-0.mr-4.d-inline-block(color='aliceBlue' size='40' v-if="item.updated_by != null")
                    v-icon(v-if="item.updated_by.usersetting.photo == null") mdi-account
                    img(v-else :src='item.updated_by.usersetting.photo')
                a(v-if="item.updated_by != null" :href='onMail(item.updated_by.email)' class='ellipsis') {{ item.updated_by.last_name+' '+item.updated_by.first_name }}

        //- 建立者 created_by
        template(v-slot:item.created_by='{ item }')
            div(class='d-flex w-100 align-center justify-start ml-8' :title='item.updated_by.last_name+" "+item.updated_by.first_name')
                v-list-item-avatar.ma-0.mx-2.d-inline-block(color='aliceBlue' size='40' v-if="item.created_by != null")
                    v-icon(v-if="item.created_by.usersetting.photo == null") mdi-account
                    img(v-else :src='item.created_by.usersetting.photo')
                a(v-if="item.created_by != null" :href='onMail(item.created_by.email)' class='ellipsis') {{ item.created_by.last_name+' '+item.created_by.first_name }}

        //- 建立時間 created_on
        template(v-slot:item.created_on='{ item }')
            .t-black.ellipsis {{ new Date(item.created_on).toLocaleString() }}

        //- 更新時間 updated_on
        template(v-slot:item.updated_on='{ item }')
           .t-black.ellipsis {{ new Date(item.updated_on).toLocaleString() }}
        
        //- 成立時間 establish_date
        template(v-slot:item.establish_date='{ item }')
            div(class='d-flex justify-center ' :title='item.establish_date')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.establish_date}}
                inlineEditDialog(v-if='checkWiadvanceShow()' field_type='date' editProp='establish_date' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
        
        //- 客製化欄位 custom_field
        template(v-for='e, index in cf_header' v-slot:[`item.${e.value}`]='{ item }')
            div(v-if='item.custom_field != null' class='d-flex justify-center')
                .inline-edit-cell
                    v-icon(v-if='e.type == "bol" && item.custom_field[`${e.prop}`] === true' class='ml-8 ellipsis' size="18" color="green" ) fas fa-check 
                    span(v-else-if='e.type == "bol" && item.custom_field[`${e.prop}`] === false')
                    span(v-else-if='e.type == "opt" || e.type == "tst"' class='ml-8 ellipsis' :title='getItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field[`${e.prop}`])') {{ getItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field[`${e.prop}`]) }}
                    span(v-else-if='e.type == "mlt"' class='ml-8 ellipsis' :title='getCustomfieldOptItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field[`${e.prop}`])') {{ getCustomfieldOptItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field[`${e.prop}`]) }}
                    span(v-else class='ml-8 ellipsis' :title='item.custom_field[`${e.prop}`]') {{ item.custom_field[`${e.prop}`] }}
                inlineEditDialog(v-if='e.type != "opt" && e.type != "mlt" && e.type != "tst" && checkWiadvanceShow()' :field_type='"custom_field_"+e.type' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' @emitInlineEdit='onEmitInlineEdit_cf')
                inlineEditDialog(v-if='e.type == "opt" && checkWiadvanceShow()' field_type='custom_field_opt' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].optList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf')
                inlineEditDialog(v-if='e.type == "mlt" && checkWiadvanceShow()' field_type='custom_field_mlt' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].optList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf')
                inlineEditDialog(v-if='e.type == "tst" && checkWiadvanceShow()' field_type='custom_field_tst' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].treeList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf' :treeSelectList='CFdef[`${e.prop}`].optList')
        //- 描述 description
        template(v-slot:item.description='{ item }')
            div(class='d-flex justify-center ' :title='item.description')
                .inline-edit-cell
                    span(class='ml-8 ellipsis') {{item.description}}
                inlineEditDialog(field_type='textarea' editProp='description' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

        //- 執行 action
        template(v-slot:item.action="{ item }")
            .ellipsis
                v-btn(icon="icon" @click="showEditAccount(item)" :disabled="!checkWiadvanceShow()")
                    v-icon(size="18") icon-edit
                v-btn(icon="icon" @click="onDelete(item.id)" :disabled="!checkWiadvanceShow()")
                    v-icon(size="18") icon-remove
      label.text-right.mr-4(v-if='reRender()') {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}} 
      v-pagination.py-1.mb-3(v-if='reRender()' v-model="page", :length="pageCount", circle :total-visible="10")
  v-dialog(
    v-model="addAccountDialog",
    max-width="500",
    @keydown.esc="onEmitAddAccountDialog(false)",
    v-if="addAccountDialog",
  )
    add-account(
        @emitAddAccountDialog="onEmitAddAccountDialog",
        :add-account="addAccountData"
    )
  v-dialog(
    v-model="addWiAdvanceAccountDialog",
    max-width="500",
    @keydown.esc="onEmitAddAccountDialog(false)",
    v-if="addWiAdvanceAccountDialog",
  )
    addWiAdvanceAccount(
        @emitAddAccountDialog="onEmitAddAccountDialog",
        :add-account="addAccountData"
    )
  v-dialog(
    v-model="editAccountDialog",
    max-width="500",
    @keydown.esc="onEmitEditAccountDialog(false)",
    v-if="editAccountDialog",
  )
    edit-account(
      @emitEditAccountDialog="onEmitEditAccountDialog",
      :edit-account="edtorAccountData"
    )
  v-dialog(
    v-model="editWiAdvanceAccountDialog",
    max-width="500",
    @keydown.esc="onEmitEditAccountDialog(false)",
    v-if="editWiAdvanceAccountDialog",
  )
    editWiAdvanceAccount(
      @emitEditAccountDialog="onEmitEditAccountDialog",
      :edit-account="edtorAccountData"
    )
  v-dialog(
    v-model="messageDialog",
    width="360",
    content-class="statusDialog",
    @keydown.esc="onEmitMessageDialog(false)",
  )
    message-dialog(
      @emitMessageDialog="onEmitMessageDialog",
      :message="message"
    )
  v-dialog(
    v-model="successDialog",
    width="360",
    content-class="statusDialog",
    @keydown.enter="onEmitSuccessDialog()"
  )
    success-dialog(@emitSuccessDialog="onEmitSuccessDialog")
  v-dialog(
    v-model="errorDialog",
    width="360",
    content-class="statusDialog",
    @keydown.esc="onEmitErrorDialog(false)",
  )
    error-dialog(
      @emitErrorDialog="onEmitErrorDialog",
      :errorMessage="errorMessage"
    )
  v-navigation-drawer.v-navigation-drawer-bulk-edit(v-model='bulkEditDialog'  v-if='bulkEditDialog' absolute bottom temporary right width="400" zIndex='99999')
      bulk-edit-dialog(@emitBulkEditDialog='onEmitBulkEditDialog' :editItems='selectedRows')
  v-navigation-drawer.v-navigation-drawer-bulk-edit(v-model='wiadvaceBulkEditDialog'  v-if='wiadvaceBulkEditDialog' absolute bottom temporary right width="400" zIndex='99999')
      wiadvaceBulkEditDialog(@emitBulkEditDialog='onEmitBulkEditDialog' :editItems='selectedRows')
  v-dialog(v-model='redirectDialog' width='360' content-class='statusDialog')
      redirect-dialog(@emitRedirectDialog='onEmitRedirectDialog' :message='message' :copyDataType='"org"' :copyDataId='copyDataId')
  v-dialog(v-model='editTableDialog' width='360' content-class='statusDialog' v-if='editTableDialog')
      edit-table-dialog(@emitEditTableDialog='onEmitEditTableDialog' @emitEditTable='onEmitEditTable' :custom-views='customViews' :edit-data='currentTable' :edit-mode='editMode')
  v-dialog(v-model='exportDialog' max-width='500' v-if='exportDialog')
      export-dialog(@emitExport='onExport' page_name='Organization')
</template>

<script>
import Vue from "vue";
// dialog
import addAccount from "@/components/Dialog/Account/addAccount";
import addWiAdvanceAccount from "@/components/Dialog/Account/addWiAdvanceAccount";
import editWiAdvanceAccount from "@/components/Dialog/Account/editWiAdvanceAccount";
import editAccount from "@/components/Dialog/Account/editAccount";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import redirectDialog from '@/components/Dialog/redirectDialog';
import editTableDialog from "@/components/Dialog/Setting/editTableDialog";
import exportDialog from '@/components/Common/exportDialog';
import inlineEditDialog from '@/components/Common/inlineEditDialog';
import bulkEditDialog from "@/components/Dialog/BulkEdit/orgBulkEditDialog";
import wiadvaceBulkEditDialog from "@/components/Dialog/BulkEdit/orgWiadvaceBulkEditDialog";

// service
import OrganizationDataService from "@/services/OrganizationDataService";
import CustomViewDataService from '@/services/CustomViewDataService';
import CustomFilterDataService from "@/services/CustomFilterDataService"; 
import CampaignDataService from "@/services/CampaignDataService";
import CustomFieldDataService from "@/services/CustomFieldDataService";
import BulkEditDataService from "@/services/BulkEditDataService";
import FollowDataService from '../services/FollowDataService';
import TreeOptionsDataService from '@/services/TreeOptionsDataService';
// list
import industryList from "@/array/the_IndustryList";
import orgTypeList from "@/array/the_OrgtypeList";
import orgsFieldList from "@/array/the_orgsFieldList";
import wiadvancePaymentTermList from "@/array/the_wiadvancePaymentTermList";
import paymentTermsList from "@/array/the_paymentTermsList";
import belongGroupList from "@/array/the_belongGroupList";
// other
import loadingOverlay from "@/components/Common/loadingOverlay";
import i18n from '/common/plugins/vue-i18n.js' 
import importExportBtn from "@/components/Common/importExportBtn";
import setting_btn from "@/components/Common/setting_btn";
import followIcon from "@/components/Common/followIcon";

export default Vue.extend({
    components: {
        exportDialog,
        redirectDialog,
        addAccount,
        addWiAdvanceAccount,
        editWiAdvanceAccount,
        editAccount,
        messageDialog,
        successDialog,
        errorDialog,
        editTableDialog,
        inlineEditDialog,
        loadingOverlay,
        importExportBtn,
        setting_btn,
        bulkEditDialog,
        wiadvaceBulkEditDialog,
        followIcon,
    },
    data() {
        return {
            user: JSON.parse(window.localStorage.getItem('user')),
            is_staff: false,
            loading: true,
            // Table Setting
            awaitingSearch: false, 
            sortBy:[],
            sortByForSearch:[],
            sortByDesc:[],
            pageDataCount:null,
            page: 1,
            pageCount: 0,
            currentData:0,
            itemPerPage: 50,
            selectedRows: [],
            loading_content: true,
            loading_view: true,
            loading_edit: false,
            // Account Table Data
            searchAccount: "",
            accountHeader: [],
            accountData: [],
            industryList: industryList,
            orgTypeList: orgTypeList,
            // Dialog
            exportDialog:false,
            redirectDialog: false,
            copyDataId:null,
            messageDialog: false,
            message: "",
            successDialog: false,
            successMessage: "",
            errorDialog: false,
            errorMessage: "",
            addAccountDialog: false,
            addWiAdvanceAccountDialog: false,
            editWiAdvanceAccountDialog: false,
            editAccountDialog: false,
            editTableDialog: false,
            // data
            addAccountData: {},
            edtorAccountData: {},
            deleteCompaniesIds: "",
            copyCompaniesIds: "",
            delError: false,
            originData: {},
            tables: [],
            currentTable: {},
            customViews: [],
            cf_header: [],
            orgsFieldList: orgsFieldList,
            editMode: 'EDIT',
            default_filter : null,
            filter_list : [],
            first_run:false,
            system_default_filter: null,
            // inline edit
            company_origin_data: null,
            campaignList: null,
            ownerList: null,
            CFdef: {},
            valid: true,
            customField:null,
            bulkEditDialog:false,
            wiadvaceBulkEditDialog:false,
            headerData:{
                id:"",
                name:"",
                value:{},
                page:""
            },
            belongGroupList:belongGroupList,
            wiadvancePaymentTermList: wiadvancePaymentTermList,
            paymentTermsList: paymentTermsList,
            dateList: Array.from({ length: 31  }, (_, index)=>({
                key: (index + 1).toString(),
                name: i18n.t(`THEDAYOFEVERYMONTH.${index + 1}`)
            })),
            hasCheck: false,
            customer_code_List: [],
        };
    },
    async created(){ 
        let filter_id = this.getDefaultFilter("Organization",null);
        await this.getFilterList();
        await (this.default_filter = this.getPageDefaultFilter(filter_id, this.filter_list, this.system_default_filter));
        await this.getCustomView();
        await this.getCampaginList();
        await this.getOwnerList();
        await this.getCFdef();
        await this.getListData();
        await (this.first_run = true);
        this.checkTechUser();
    },
    methods: {
        follow(){
            let add_list=[];
            let delete_list=[];
            for(let i = 0; i < this.selectedRows.length; i++)
                {
                if(!this.selectedRows[i].follow){
                    add_list.push(this.selectedRows[i].id);
                }else{
                    delete_list.push(this.selectedRows[i].id)
                }
                }
            let data = {
                "add_list":add_list,
                "delete_list":delete_list,
                "class_name":"Organization"
            }
            FollowDataService.bulkCreateDeleteFollow(data)
            .then((response)=>{
                for(let i in response.data.add_list){
                let org = this.accountData.filter(el => el.id == response.data.add_list[i]);
                org[0].follow = true;
                }
                for(let i in response.data.delete_list){
                let org = this.accountData.filter(el => el.id == response.data.delete_list[i]);
                org[0].follow = false;
                }
                this.selectedRows = [];
            })
        },
        onEmitInlineEdit(item, prop, value, list_data=[]){
            this.loading_edit = true;
            item[prop] = value;
            let editData = JSON.parse(JSON.stringify(item));
            prop == 'owner' ? delete editData.organization_type : '';
            editData.employee == '' ? editData.employee = null : '';
            editData.annual_revenue == ''? editData.annual_revenue = null : '';
            editData.registered_capital == ''? editData.registered_capital = null : '';
            if(['regular_payment_date', 'closing_date'].includes(prop)){
                let input_value = JSON.parse(JSON.stringify(value));
                editData[prop] = input_value.join(',');
            }

            if(this.isWiadvance() && prop == "customer_code" && list_data != []){
                let option_obj = list_data.find(item => item.key == value)
                let bc_data = option_obj.bc_data;
                editData.company_number = bc_data.taxRegistrationNumber;
                editData.name = bc_data.displayName ;
                editData.english_department = bc_data.englishName ;
                editData.country = bc_data.country;
                editData.zip_code = bc_data.postalCode;
                editData.city = bc_data.city;
                editData.billing_address = bc_data.addressLine1;
                editData.sub_address = bc_data.addressLine2;
                editData.belong_group = bc_data.belong_group;
                editData.closing_date = bc_data.closing_date;
                editData.payment_term = bc_data.payment_term;
                editData.regular_payment_date = bc_data.regular_payment_date;
                editData.temp_owner = bc_data.owner;
            }
            OrganizationDataService.edit(editData)
            .then((response)=>{
                item.updated_by = response.data.updated_by;
                editData.updated_by = response.data.updated_by;
                item.updated_on = response.data.updated_on;
                editData.updated_on = response.data.updated_on;
                item.regular_payment_date = response.data.regular_payment_date;
                item.closing_date = response.data.closing_date;
            })
            .catch((response)=>{
                console.log(response);
            })
            .finally(()=>{
                if(this.isWiadvance() && prop == "customer_code"){
                    this.getListData();
                }
                this.loading_edit = false;
            })
        },
        onEmitInlineEdit_cf(item, prop, value){
            this.loading_edit = true;
            item['custom_field'][prop] = value;
            let editData = JSON.parse(JSON.stringify(item));

            CustomFieldDataService.updateOrgCustomFieldDetail(editData.id,editData)
            .catch((response)=>{
                this.showErrorDialog(response);
            })
            .finally(()=>{
                let updated_by = this.ownerList.find(e => e.id == this.user.userid);
                item.updated_by = updated_by;
                item.updated_by = updated_by;
                item.updated_on = new Date();
                item.updated_on = new Date();
                this.loading_edit = false;
            })
        },
        getCampaginList(){
            CampaignDataService.selectlist()
            .then((response)=>{
                this.campaignList = response.data;
            })
        },
        getOwnerList(){
            OrganizationDataService.getchangeowner("accounts")
            .then((response)=>{
                this.ownerList = response.data;
                this.ownerList.forEach(item => {
                    item.fullName = item.last_name + ' ' + item.first_name
                });
            });
        },
        async getCFdef(){
            let treeSelectData = [];
            await TreeOptionsDataService.list().then((response)=>{
                treeSelectData = response.data;
            })
            await CustomFieldDataService.getCustomFieldDetail("Organization")
            .then((response) => {
                this.CFdef =  response.data[0].definition;
                for(let prop in this.CFdef){
                    if(prop.includes('tst')){
                    let treeData = treeSelectData.filter(el=>el.id == this.CFdef[prop].tree_select_id)[0]
                    let optList = treeData.option_set;                        
                    optList = Object.entries(optList).map((arr) => (
                        {
                            key: arr[0],
                            name: arr[1],
                        }));
                        this.CFdef[prop]['optList'] = optList;
                        this.CFdef[prop]['treeList'] = treeData.value;
                    }
                    if(prop.includes('opt') || prop.includes('mlt')){
                        let optList = Object.entries(this.CFdef[prop].option_set)
                        .map((arr) => (
                        {
                            key: arr[0],
                            name: arr[1],
                        }));
                        this.CFdef[prop]['optList'] = optList;
                    }
                }
            })
        },
        validateForm(){
            if(this.$refs.form.validate())
                return true;
            else
                return false;
        },
        onEmitView(view, mode){
            this.currentTable = view;
            if(mode != undefined){
                if(mode=="edit"){
                    this.editMode = 'EDIT';
                    this.showEditTableDialog();
                }
                else if(mode=='copy'){
                    this.editMode = 'COPY';
                    this.showEditTableDialog();
                }
                else if(mode=='add'){
                    const userOwnCV = this.customViews.find(e => e.owner != null);
                    let item = {
                        fields: view.fields,
                        is_default: false,
                        stage_type_id: this.selectStageType,
                        owner: userOwnCV.owner,
                        name: view.name,
                        id : view.view_id
                    }
                    view.owner = userOwnCV.owner;
                    
                    userOwnCV.definition.push(item);
                    this.getHeader();
                }
            }else{
                this.getHeader();
            }
        },
        onEmitFilter(filter, situation){
            
            if(situation == 'cancelEdit'){
                for(let prop in this.default_filter){
                    let p = prop.toString();
                    this.$set(this.default_filter, p, filter[prop]) 
                }
                return;
            }
            if(situation == 'applyFilter' && filter != undefined){
                filter.owner_id = filter.owner;
                this.default_filter = filter;
                return;
            }
            if(situation == 'addFilter' && filter != undefined){
                this.filter_list.push(filter);
                filter.owner_id = filter.owner;
                this.default_filter = filter;
                return;
            }
            if(situation == 'confirmEdit_exportDialog'){
                let oldData = this.filter_list.find(e => e.id == filter.id);
                for(let prop in oldData){
                    let p = prop.toString();
                    this.$set(oldData, p, filter[prop]) 
                }
                return;
            }
        },
        async changeFilter(item){
            this.default_filter = item.id
        },
        async getFilterList(){
            await CustomFilterDataService.list()
            .then((response)=>{
                this.filter_list = response.data
                this.filter_list = this.filter_list.filter(el => el.class_name == "Organization")
            })
            .finally(()=>{
                this.system_default_filter = this.filter_list.find(e => e.default);
            })
        },
        async getListData(){
            this.loading_content = true;
            this.accountData = [];
            await OrganizationDataService.getAll(this.page,this.searchAccount,this.itemPerPage,this.sortByForSearch,this.default_filter.id)
            .then((response) => {
                this.accountData = response.data.list;
                this.pageCount = response.data.total_page
                this.pageDataCount = response.data.pageDataCount
                this.currentData = (this.page)*this.itemPerPage >= this.pageDataCount ? this.pageDataCount:(this.page)*this.itemPerPage
                this.accountData.forEach(data => {
                    data.industryName = this.getItemNameFromList(this.industryList, data.industry);
                    data.typeName = this.getItemNameFromList(this.orgTypeList, data.organization_type);
                    data.total_amount_adj = '$ '+this.getFormatAmount(data.total_amount);
                    data.ownerName = data.owner.first_name == null ? data.owner.last_name : data.owner.last_name +" "+ data.owner.first_name;
                    data.owner.fullName = data.owner.first_name == null ? data.owner.last_name : data.owner.last_name +" "+ data.owner.first_name;
                });
            })
            .finally(()=> {
                this.loading = false;
                this.loading_content = false;
            })
        },
        // onResize: function () {
        //     let windowH = window.innerHeight - 200 //table高度
        //     let objH = 70
        //     this.itemPerPage = parseInt(windowH/objH)
        // },
        onCall: function (cell) {
            return "tel:" + cell;
        },
        onMail: function (mail) {
            return "mailto:" + mail;
        },
        onGoogleMap: function (address) {
            return "https://www.google.com.tw/maps/place/" + address;
        },
        changeTable(item){
            this.currentTable = item;
            this.getHeader();
        },
        async getHeader(){

            await (this.loading_view = true);
            let customWidthData = await this.getWidth("Organization",this.currentTable);
            await CustomFieldDataService.getCustomFieldDetail("Organization")
            .then((response)=>{
                this.customField = response.data[0].definition;
            })
            .then(()=>{
                let cfKey = this.customField ? Object.keys(this.customField) : [];
                let array = [];
                let select = {
                    value: "data-table-select",
                    align: "center",
                    width: '100px',
                };
                let action = {
                    text: i18n.t('GENERAL.ACTIVE') ,
                    value: "action",
                    align: "center",
                    width: '150px',
                    sortable: false,
                };
                this.currentTable.fields.forEach(e => {
                    let item = {
                        text: '',
                        value: e.value,
                        sequence: e.sequence_number,
                        align: 'center',
                        sortable: true,
                    };

                    // if(this.currentTable.fields.length >= 8){
                    //     item.width = '200px';
                    // }
                    
                    if(e.is_customfield && this.CFdef != null){
                        item.text = e.text;
                        item.value = 'custom_field_' + e.value;
                        item.prop = e.value;
                        item.type = (e.value).substr(0,3)
                        this.cf_header.push(item);
                    }
                    else{
                        item.text = this.getItemNameFromList(orgsFieldList , e.value);
                    }

                    if(e.is_customfield && cfKey.indexOf(e.value)!=-1){
                        array.push(item);
                    }
                    if(!e.is_customfield){
                        array.push(item);
                    }
                })
                array.sort(function(a, b){
                    return a.sequence_number - b.sequence_number
                });
                array.unshift(select);
                array.push(action);
                this.headerData.id = this.currentTable.view_id;
                this.headerData.name = this.currentTable.name;
                this.headerData.value = array;
                this.headerData.page = "Organization";
                this.headerData.customWidth = customWidthData;
                this.accountHeader = array;
            })
            .then(()=>{
                const title = localStorage.getItem('title');
                document.title = title+' : '+this.currentTable.name +' – UpDay';
            })
            .finally(()=>{
                this.loading_view = false;
            })
        },
        getHeaderwithWidth(data){
            this.accountHeader = data;
        },
        async getCustomView(){
            this.tables = [];
            CustomViewDataService.getCustomView('Organization')
            .then(response => {
                this.customViews = response.data;
                this.customViews.forEach(e => {
                    if(e.owner == null){
                        let item = {
                            id: e.id,
                            name: e.definition[0].name,
                            is_default: e.definition[0].is_default,
                            fields: e.definition[0].fields,
                            owner: e.owner,
                            view_id : e.definition[0].id,
                        }
                        
                        if(item.is_default)
                            this.currentTable = item;
                        this.tables.push(item)
                    }
                    else if(e.owner != null){
                        e.definition.forEach(table => {
                            let item = {
                                id: e.id,
                                name: table.name,
                                is_default: table.is_default,
                                fields: table.fields,
                                owner: e.owner,
                                view_id : table.id,
                            }

                            if(item.is_default)
                                this.currentTable = item;
                            this.tables.push(item)
                        })
                    }
                });
                this.tables.reverse()
                this.getHeader();
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
        },
        onEmitEditTableDialog(val){
            if(val){
                this.showSuccessDialog();
            }
            this.editTableDialog = false;
        },
        onEmitEditTable(data){
            if(this.editMode == 'EDIT'){
                this.currentTable.name = data.name;
                this.currentTable.fields = data.fields;
            }
            else if(this.editMode == 'COPY'){
                this.tables.unshift(data);
                this.currentTable = data;
            }
            this.getHeader();
        },
        // Dialog Area ----------------------------------------- [Start]
        showAddAccount(copyData) {
            if(this.isWiadvance()){
                if(copyData.name != undefined){
                    this.copyDataId = "org"
                    this.addAccountData = JSON.parse(JSON.stringify(copyData));        
                    this.addAccountData.name = {name:JSON.parse(JSON.stringify(copyData)).name,id:"0",company_number:JSON.parse(JSON.stringify(copyData)).company_number}
                    this.addAccountData.custom_field = JSON.parse(this.addAccountData.custom_field);
                    delete this.addAccountData.updated_by;
                    delete this.addAccountData.created_by;  
                } else {
                    this.addAccountData = {
                    name: "",
                    organization_type: null,
                    industry: null,
                    website: null,
                    main_phone: null,
                    fax: null,
                    billing_address: null,
                    employee: null,
                    company_number: null,
                    email: null,
                    custom_field: null,
                    customer_code: null,
                    country: null,
                    zip_code: null,
                    city: null,
                    payment_term: null,
                    }
                }
                this.addWiAdvanceAccountDialog = true;
            }else{      
                if(copyData.name != undefined){
                    this.copyDataId = "org"
                    this.addAccountData = JSON.parse(JSON.stringify(copyData));        
                    this.addAccountData.name = {name:JSON.parse(JSON.stringify(copyData)).name,id:"0",company_number:JSON.parse(JSON.stringify(copyData)).company_number}
                    this.addAccountData.custom_field = JSON.parse(this.addAccountData.custom_field);
                    delete this.addAccountData.updated_by;
                    delete this.addAccountData.created_by;  
                } else {
                    this.addAccountData = {
                    name: "",
                    organization_type: null,
                    industry: null,
                    website: null,
                    main_phone: null,
                    fax: null,
                    billing_address: null,
                    employee: null,
                    company_number: null,
                    email: null,
                    custom_field: null,
                    }
                }
                this.addAccountDialog = true;
                }
        },
        onEmitAddAccountDialog(val, data) {
            this.addAccountDialog = false;
            this.addWiAdvanceAccountDialog = false;
            this.selectedRows = [];
            if(val){
                if(val == "repeat customer code"){
                    this.showMessageDialog(i18n.t('COMPANY.WIADVANCE_REPEAT_CUSTOMERCODE'));
                }else{
                    this.addAccountData = data;
                    this.addAccountData.industryName = this.getItemNameFromList(this.industryList, this.addAccountData.industry);
                    this.addAccountData.typeName = this.getItemNameFromList(this.orgTypeList, this.addAccountData.organization_type);
                    this.addAccountData.total_amount_adj = '$ ' + 0;
                    this.addAccountData.ownerName = this.addAccountData.owner.first_name == null ? this.addAccountData.owner.last_name : this.addAccountData.owner.last_name +" "+ this.addAccountData.owner.first_name;
                    this.accountData.unshift(this.addAccountData); 

                    if(this.copyDataId == null){
                        this.showSuccessDialog();
                    }else{
                        this.redirectDialog = true
                        this.message = ""
                        this.copyDataId = this.addAccountData.id
                    }
                    this.getListData()
                }
            }else{
                this.copyDataId = null
            }
        },
        showEditAccount(data) {
            let dialogType = this.isWiadvance() ? 'editWiAdvanceAccountDialog' : 'editAccountDialog';
            this[dialogType] = true;
            this.edtorAccountData = data;
            this.originData = JSON.parse(JSON.stringify(data));
        },
        onEmitEditAccountDialog(val) {
            this.editAccountDialog = false;
            this.editWiAdvanceAccountDialog = false;
            let index = this.accountData.findIndex(item => {return item.id == this.edtorAccountData.id;});
            if (val) {
                this.showSuccessDialog();
                this.edtorAccountData.industryName = this.getItemNameFromList(this.industryList, this.edtorAccountData.industry);
                this.edtorAccountData.typeName = this.getItemNameFromList(this.orgTypeList, this.edtorAccountData.organization_type);
                this.accountData.splice(index, this.edtorAccountData);
            } else {
                this.accountData[index].name = this.originData.name;
                this.accountData[index].main_phone = this.originData.main_phone;
                this.accountData[index].billing_address = this.originData.billing_address;
                this.accountData[index].organization_type = this.originData.organization_type;
                this.accountData[index].industry = this.originData.industry;
                this.accountData[index].website = this.originData.website;
                this.accountData[index].fax = this.originData.fax;
                this.accountData[index].employee = this.originData.employee;
            }
        },
        // Message Dialog
        async onDelete(id) {
            this.deleteCompaniesIds = "";
            if (id != null) {
                this.showMessageDialog(i18n.t('COMPANY.MESSAGE'));
                this.deleteCompaniesIds += id;
            } else {
                this.showMessageDialog(i18n.t('COMPANY.MESSAGE'));
                for (let i = 0; i < this.selectedRows.length; i++) {
                this.deleteCompaniesIds += `${this.selectedRows[i].id},`;
                }
                this.deleteCompaniesIds = this.deleteCompaniesIds.slice(0, -1);
            }
        },
        showMessageDialog(message) {
            this.messageDialog = true;
            this.message = message;
        },
        onEmitMessageDialog(val) {
            this.messageDialog = false;
            if (val) {
                if (this.deleteCompaniesIds) 
                {
                OrganizationDataService.delete(this.deleteCompaniesIds)
                    .then((response) => {
                    if(response.data.error === 'Have Opportunity!'){
                        this.delError = true;
                        let checkOpptylist = response.data.errorList ? response.data.errorList.length : null;
                        let checkCaseylist = response.data.caselist ? response.data.caselist.length : null;
                        if(checkOpptylist > 0 && checkCaseylist ==0){
                            this.showErrorDialog(`${i18n.t('COMPANY.MESSAGE_2_1')} ${response.data.errorList} ${i18n.t('COMPANY.MESSAGE_2_2')}`);
                        }
                        else if(checkOpptylist == 0 && checkCaseylist > 0){
                            this.showErrorDialog(`${i18n.t('COMPANY.MESSAGE_2_1')} ${response.data.caselist} ${i18n.t('COMPANY.MESSAGE_2_3')}`);
                        }
                        else if(checkOpptylist >0 && checkCaseylist > 0){
                            this.showErrorDialog(`${i18n.t('COMPANY.MESSAGE_2_1')} ${response.data.errorList} ${i18n.t('COMPANY.MESSAGE_2_2')}，${response.data.caselist} ${i18n.t('COMPANY.MESSAGE_2_3')}`);
                        }
                        else{
                            this.showErrorDialog(i18n.t('COMPANY.MESSAGE_3'));
                        }
                        // if(response.data.errorList != null){
                        //     this.showErrorDialog(i18n.t('COMPANY.MESSAGE_2_1') + `${response.data.errorList}` + i18n.t('COMPANY.MESSAGE_2_2'));
                        // }
                        // else{
                        //     this.showErrorDialog(i18n.t('COMPANY.MESSAGE_3'));
                        // }
                    }
                    else if (response.status === 204 || response.status === 200) {
                        
                        let IdArr = this.deleteCompaniesIds.split(",");

                        for (let i = 0; i < IdArr.length; i++) {
                        let deleteItemMethod = (item) => item.id === IdArr[i];
                        let deleteItemIdx =
                            this.accountData.findIndex(deleteItemMethod);
                        this.accountData.splice(deleteItemIdx, 1);
                        }
                        if(this.selectedRows.length >0)
                        {
                        this.currentData = this.currentData - this.selectedRows.length
                        this.pageDataCount = this.pageDataCount - this.selectedRows.length
                        }else
                        {
                        this.currentData = this.currentData - 1
                        this.pageDataCount = this.pageDataCount - 1
                        }
                        this.selectedRows = [];
                        this.deleteCompaniesIds = "";
                        this.showSuccessDialog();
                    }
                    else {                
                        this.showMessageDialog(
                        `${response.statusText}, ${response.status}`
                        );
                    }
                    })
                    .catch(function (error) {
                        this.showErrorDialog(error);
                    });

                // this.showSuccessDialog();
                } 
            }else{
          if(this.deleteCompaniesIds){
            this.deleteCompaniesIds ='';
          }
      }
        },
        showSuccessDialog() {
            this.successDialog = true;
        },
        onEmitSuccessDialog() {
            this.successDialog = false;
        },
        showErrorDialog(message) {
            this.errorDialog = true;
            this.errorMessage = message;
        },
        onEmitErrorDialog(val) {
            this.errorDialog = false;
            if (val) {
                if(this.delError === true)
                {
                this.selectedRows = [];
                this.deleteCompaniesIds = "";
                this.errorDialog = false;
                this.delError = false;
                }
            }
        },
        async onCopy(copyData) {  
            let copyAddData = JSON.parse(JSON.stringify(copyData));
            delete copyAddData.owner;
            copyAddData.custom_field = JSON.stringify(copyAddData.custom_field)
            this.showAddAccount(copyAddData);
        },
        onEmitRedirectDialog(){
            this.redirectDialog = false
            this.copyDataId = null
        },
        showEditTableDialog(){
            this.editTableDialog = true;
        },
        exportCsv(){
            this.exportDialog = true
        },
        onExport(){
            this.exportDialog = false
        },
        reRender(){
            if(!this.loading && !this.loading_content && !this.loading_view){
                return true;
            }else{
                return false;
            }
        },
        showBulkEditDialog(){
            if(this.isWiadvance()){
                this.wiadvaceBulkEditDialog = true;
            }else{
                this.bulkEditDialog = true;
            }
        },
       async onEmitBulkEditDialog(val,editData){
            this.bulkEditDialog = false;
            this.wiadvaceBulkEditDialog = false;
            if(val){
                if(val == 'editing'){
                    this.loading = true;
                    await BulkEditDataService.editOrg(editData);
                    await (this.selectedRows = []);
                    await this.getListData();
                    await(this.loading = false);
                }
            }
        },
        // Dialog Area ----------------------------------------- [End]
        checkTechUser(){
            let user = JSON.parse(window.localStorage.getItem('user'));
            if(user){
                this.is_staff = JSON.parse(window.localStorage.getItem('user'))['is_staff'];
            }
        },
        checkWiadvanceShow(){
            if(this.isWiadvance()){
                return this.is_staff;
            }else{
                return true
            }
        },
        getItemArrayNameFromList(itemList, data){
            let value = data == '' ? [] : (data?.split(',') ?? []);
            if(value.length > 0){
                value = value.map(id=>itemList.find(item => item.key == id)?.name).join(',')
            }else{
                value = ''
            }
            return value
        }
    },
    watch:{
        "default_filter":function () {
            if(this.first_run){
                this.page = 1;
                this.getListData();
            } 
        },
        "redirectDialog":function () {
            if(this.redirectDialog == false)
            {
                this.copyDataId = null;
            }      
        },
        "addAccountDialog":function () {
            if(this.addAccountDialog == false && this.copyDataId=="org")
            {
                this.copyDataId = null;
            }      
        },
        "page":async function () {
            await this.getListData();
        },
        "searchAccount":async function () {
            if (!this.awaitingSearch) {
                this.isLoading = true;
                setTimeout(() => {
                    this.page = 1
                    this.getListData();
                    this.awaitingSearch = false;
                }, 1000);
            }
            this.awaitingSearch = true; 
        },    
        "sortByDesc":async function () {
            this.isLoading = true;
            this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
            for(let i in this.sortByDesc)
            {
                if(this.sortByForSearch[i] == "registered_capital")
                {
                    this.sortByForSearch[i] = "registered"
                }
                if(this.sortByDesc[i] == true)
                {
                    this.sortByForSearch[i] = '-'+this.sortByForSearch[i]
                }
            }
            // this.page = 1;
            await this.getListData();
        }
    }
});
</script>
<template lang="pug">
// 新增客戶
//- v-dialog(v-model='addAccountDialog' max-width='500')
  //- add-account(@emitAddAccountDialog='onCloseAddAccountDialog')
v-card
  v-card-title.d-flex.justify-center {{$t('ADD_COMPANY.TITLE')}}
  v-card-text
    v-form(v-model="valid", ref="form")
      v-combobox.no-border(
        v-model="companyData",
        :label=`$t('ADD_COMPANY.ORG_NAME')`,
        large="large",
        :items="organizationList",
        item-text="name",  
        item-value="uqNumber",      
        :search-input.sync="search",
        :loading="isLoading",
        :rules="formRule.organizationRules",
        required="required",
        prepend-icon="mdi-domain",
      )
        template(v-slot:append-outer)
          div(v-if="isNew")
            v-icon(color="red", size="25") mdi-new-box
        template(v-slot:item="data")
          div.ellipsis(v-if="data.item.id == 0")
            span {{ data.item.name }}
            v-icon(color="red", size="25") mdi-new-box
          div.ellipsis(v-else)
            span {{ data.item.name }}
      v-select.no-border(
        :label=`$t('ADD_COMPANY.TYPE')`,
        v-model="addAccount.organization_type",
        prepend-icon="mdi-domain",
        :items="orgtypeList",
        item-text="name",
        item-value="key",
        flat
      )
      v-select.no-border(
        :label=`$t('ADD_COMPANY.INDUSTRY')`,
        v-model="addAccount.industry",
        prepend-icon="mdi-domain",
        :items="industryList",
        item-text="name",
        item-value="key",
        flat
      )

      v-file-input.no-border(
        v-model="organizationImage",
        prepend-icon="mdi-image",
        :label=`$t('ADD_COMPANY.IMAGE')`,
        accept="image/*",
        @change='onChangePhoto',
        :rules="formRule.photoRule"
      )
      v-text-field.no-border(
        v-model="addAccount.email",
        prepend-icon="mdi-email",
        :rules="formRule.emailRules",
        :label= `$t('GENERAL.MAIL')`,
      )
      v-text-field.no-border(
        v-model="addAccount.website",
        prepend-icon="mdi-earth",
        :rules="formRule.urlRules",
        :label=`$t('ADD_COMPANY.WEB')`,
      )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addAccount.main_phone",
            prepend-icon="mdi-phone",
            :rules="formRule.contentLenRule50",
            :label=`$t('ADD_COMPANY.PHONE')`,
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addAccount.fax",
            prepend-icon="mdi-fax",,
            :rules="formRule.contentLenRule20",
            :label=`$t('ADD_COMPANY.FAX')`,
          )
      v-select.no-border(
        v-if='isWiadvance()',
        label='Customer Code',
        v-model="addAccount.customer_code",
        prepend-icon="mdi-domain",
        :items="customer_code_List",
        item-text="name",
        item-value="key",
        @change='auto_complete_by_bc_data',
        flat
        clearable
      )
      v-text-field.no-border(
        v-if='!isWiadvance()',
        label='Customer Code',
        v-model="addAccount.customer_code",
        prepend-icon="mdi-domain",
        :rules="formRule.contentLenRule100X",
      )
      v-text-field.no-border(
        v-model="addAccount.company_number",
        prepend-icon="mdi-domain",
        :rules="formRule.contentLenRule100X",
        :label=`$t('ADD_COMPANY.COMPANY_NUMBER')`,
      )
      v-text-field.no-border(
        v-model="addAccount.country",
        prepend-icon="mdi-map",
        :rules="formRule.contentLenRule50X",
        :label=`$t('ADD_COMPANY.COUNTRY')`,
      )
      v-text-field.no-border(
        v-model="addAccount.zip_code",
        prepend-icon="mdi-map",
        :rules="formRule.contentLenRule50X",
        :label=`$t('ADD_COMPANY.ZIP_CODE')`,
      )
      v-text-field.no-border(
        v-model="addAccount.city",
        prepend-icon="mdi-map",
        :rules="formRule.contentLenRule50X",
        :label=`$t('ADD_COMPANY.CITY')`,
      )
      v-text-field.no-border(
        v-model="addAccount.billing_address",
        prepend-icon="mdi-map",
        :rules="formRule.addressRules",
        :label=`$t('ADD_COMPANY.ADDRESS')`,
      )
      v-select.no-border(
        :label=`$t('ADD_COMPANY.PAYMENT_TERM')`,
        v-model="addAccount.payment_term",
        prepend-icon="mdi-domain",
        :items="wiadvancePaymentTermList",
        item-text="name",
        item-value="key",
        :rules="[v => !!v || $t('RULE.RULE_R')]",
        flat
      )
      v-text-field.no-border(
        v-model="addAccount.employee",
        prepend-icon="mdi-account-group",
        :rules="formRule.employeeRules",
        :label=`$t('ADD_COMPANY.EMPLOYEE')`,
      )
      v-window
        customFieldRequired(ref='CFRequired' DataClass = 'Organization' :editData='this.addAccount.custom_field'  :StageTypeId='null' ).mt-3

  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark,
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="success",
          depressed,
          dark,
        ) 
          v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
          .t-white(v-else) {{$t('GENERAL.ADD')}}
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
    error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import OrganizationDataService from "@/services/OrganizationDataService";
import ContactDataService from "../../../services/ContactDataService";
import getCustomerCodeListOptions from "@/views-wiadvance/getCustomerCodeListOptions";
import checkCustomerCodeRepeat from "@/views-wiadvance/checkCustomerCodeRepeat";
import customFieldRequired from "@/components/Dialog/customFieldRequired";
import industryList from "@/array/the_IndustryList";
import orgtypeList from "@/array/the_OrgtypeList";
import wiadvancePaymentTermList from "@/array/the_wiadvancePaymentTermList";
import i18n from '/common/plugins/vue-i18n.js'; 
import errorDialog from '@/components/Dialog/errorDialog';

export default Vue.extend({
  props: {
    addAccount: {
      type: Object,
      required: true,
    },
  },
  components: {
      customFieldRequired,
      errorDialog
  },
  data() {
    return {
      addBtn_loading:false,
      orgData:{},
      dataIndex:null,
      valid: true,
      search: null,
      isLoading: false,
      organizationList: [],
      organizationImage: null,
      industryList: industryList,
      customer_code_List: [],
      isWatch: true,
      orgtypeList: orgtypeList,
      wiadvancePaymentTermList: wiadvancePaymentTermList,
      formRule: {
        organizationRules: [
          (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
          (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
        ],
        urlRules: [
          (v) => /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_URL'),
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
        ],
        emailRules: [
          (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
        ],
        addressRules: [
          (v) => !!v || i18n.t('RULE.RULE_R_ADDRESS'),
          (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
        ],
        contentLenRule50X: [
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        contentLenRule100X: [
          (v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        employeeRules: [(v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM')],
        contentLenRule70: [(v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')],
        contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
        contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
        contentLenRule10: [(v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')],
        photoRule: [
          () => { return (!this.photoSize || i18n.t('RULE.RULE_FILE_2'))},
          () => { return (!this.fileType || i18n.t('RULE.RULE_PHOTO'))},
        ],
      },
      companyData: "",
      isNewOrg: false,
      isCopy: false,
      cfValid:true,
      originOrgData: {},
      errorDialog: false,
      errorMessage: '',
    };
  },
  created(){
    if(this.addAccount.name != "")
    {
      this.isCopy = true;
    }
    if(this.addAccount.name != null)
    {
      this.companyData = this.addAccount.name;
    }  
    this.originOrgData = this.addAccount  
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    validateForm() {
      if (this.$refs.form.validate()) 
        return true;
      else 
        return false;
    },
    getResponsedata(){
      this.$refs.CFRequired.emitData();
      if(this.$refs.CFRequired.responsedata === false)
      {
        this.$refs.CFRequired.responsedata = null;
        this.cfValid = false;
        return;
      }
      this.addAccount.custom_field = JSON.stringify(this.$refs.CFRequired.responsedata);
    },
    auto_complete_by_bc_data(){
      if(this.customer_code_List != []){
        let option_obj = this.customer_code_List.find(item => item.key == this.addAccount.customer_code)
        let bc_data = option_obj.bc_data;
        this.addAccount.company_number = bc_data.taxRegistrationNumber;
        this.addAccount.name = bc_data.displayName ;
        this.addAccount.english_department = bc_data.englishName ;
        this.addAccount.country = bc_data.country;
        this.addAccount.zip_code = bc_data.postalCode;
        this.addAccount.city = bc_data.city;
        this.addAccount.billing_address = bc_data.addressLine1;
        this.addAccount.sub_address = bc_data.addressLine2;
        this.addAccount.belong_group = bc_data.belong_group;
        this.addAccount.closing_date = bc_data.closing_date;
        this.addAccount.payment_term = bc_data.payment_term;
        this.addAccount.regular_payment_date = bc_data.regular_payment_date;
        this.addAccount.temp_owner = bc_data.owner;
      }
    },
    async onConfirm() {
      this.isWatch = false;
      if (this.validateForm() && this.addBtn_loading == false ) 
      {
        if((this.addAccount.custom_field != null && this.addAccount.custom_field !== undefined) || this.$refs.CFRequired.process_array.length != 0)
          this.getResponsedata();
        if(this.cfValid == false)
        {
          this.cfValid = true;
          return;
        }
        if (this.companyData.id == 0) 
        {
          this.isNewOrg = true;
        }
        if(this.addAccount.id != "0")
        {
           this.addAccount.id = "0";
        }
        if(this.addAccount.employee == ''){
          this.addAccount.employee = null;
        }   
        this.addBtn_loading = true;
        if(this.isWiadvance() && this.originOrgData.customer_code == this.addAccount.customer_code && await checkCustomerCodeRepeat(this.addAccount.id, this.addAccount)){
          this.showErrorDialog(i18n.t('COMPANY.WIADVANCE_REPEAT_CUSTOMERCODE'));
          // this.$emit("emitAddAccountDialog", 'repeat customer code');
          this.addBtn_loading = false;
        }else{
          await OrganizationDataService.create(this.addAccount)
          .then(response => {
            // if(response.data == "repeat customer code"){
            //   this.addBtn_loading = false;
            //   this.$emit("emitAddAccountDialog", 'repeat customer code');
            // }
            if (this.isNewOrg == true) 
            {
              let principalData = {
                "organization_id": response.data.id,
                "owner_id": response.data.owner.id,
              };
              ContactDataService.createCompanyPrincipal(principalData);
            }
            if (this.organizationImage != null) 
            {
              OrganizationDataService.image(response.data.id, this.organizationImage)
              .then(res => {
                  response.data.image = res.data.image;
                  this.$emit("emitAddAccountDialog", true, response.data);
              })
            }
            else
            {
              this.$emit("emitAddAccountDialog", true, response.data);
            }
            this.addBtn_loading = false;
          });
        }
      }
    },
    onCancel() {
      this.$emit("emitAddAccountDialog", false);
    },
    getIndex(data){
      if(this.addAccount.id)
      {
        this.dataIndex = data.findIndex(item => {return item.id == this.addAccount.id})
      }
      else
      {
        this.dataIndex = data.findIndex(item => {return item.id == "0"})
      }        
    },
    onChangePhoto(event) {
      if (event != null) 
      {
        // file type
        let n = event.type.indexOf("image");
        if (n == -1) 
        {
          this.fileType = true;
        } 
        else 
        {
          this.fileType = false;
        }

        // photo size
        if (event.size > 5242880) 
        {
          this.photoSize = true;
        } 
        else 
        {
          this.photoSize = false;
        }
      } 
      else 
      {
        this.photoSize = false;
        this.fileType = false;
      }
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
  },
  computed: {
    isNew: function(){      
      let orgName = this.companyData;
      if(typeof (orgName) === 'object' && orgName !== null)
      {
        if(orgName.id == 0 || orgName.id == "-1")
          return true; // new company from database
        else
          return false; // old company from database
      }
      else if(typeof (orgName) === 'string' && orgName.length > 0)
      {
        return true; // new company from user input
      }
      else
      {
        return false; // no input(undefined || "")
      }
    }, 
  },
  watch: {
    async search(val) {
      var re = /^(?!\s*$).+/;
      var check = re.test(val);
      var checkLength = /^.{0,200}$/.test(val);
      this.comboText = "";
      if(checkLength && check && val != null)
      {
        let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length - 1;
        this.comboText = val;
        this.addAccount.name = val;
        if (!this.isLoading && charNum >= 2 && charNum == val.length ) 
        {
          this.isLoading = true;
          await OrganizationDataService.findOrganizationByNamefromDB(val)
          .then((response) => {
            this.organizationList = response.data;
            let num = 0;
            this.organizationList.forEach((item)=>{
              item.uqNumber = num;
              num ++;
            });
          })
          .finally(() => {
            this.isLoading = false;
            if (typeof(this.companyData) == "string") 
            {
              this.companyData = {
                "id": "-1",
                "name": this.comboText,
                "company_number": "",
              }
            }
          });
        }
      }
    },
    "companyData": async function () {
      if(this.isWiadvance()){
        this.addAccount.customer_code = null;
        this.customer_code_List = await getCustomerCodeListOptions(this.companyData);
      }
      if(this.isWatch && this.companyData !== null) 
      {
        OrganizationDataService.findCompanyByNumberfromDB(this.companyData.company_number)
        .then((response) => {          
          this.orgData = response.data;
          this.getIndex(response.data);     
          if(this.dataIndex != -1)      
          {
            this.addAccount.city = this.orgData[this.dataIndex].city;
            this.addAccount.registered_capital = this.orgData[this.dataIndex].registered_capital;
            this.addAccount.billing_address = this.orgData[this.dataIndex].billing_address;
            this.addAccount.establish_date = this.orgData[this.dataIndex].establish_date;
            this.addAccount.organization_type = this.orgData[this.dataIndex].organization_type;
            this.addAccount.main_phone = this.orgData[this.dataIndex].main_phone;
            this.addAccount.fax = this.orgData[this.dataIndex].fax;
            this.addAccount.industry = this.orgData[this.dataIndex].industry;
            this.addAccount.company_number = this.orgData[this.dataIndex].company_number;
            if( this.orgData[this.dataIndex].employee != undefined)
            {
                this.addAccount.employee = this.orgData[this.dataIndex].employee;
            }
            else if(this.isCopy)
            {
              let employee = this.addAccount.employee;
              this.addAccount.employee = employee;
            }
            else
            {
                this.addAccount.employee = null;
            }
          }
        })
        .finally(() => {         
          this.isLoading = false;          
        });
      }     
    },
  },
});
</script>
<template lang="pug">
v-card(:class='contactData.length >= 8 ? "dialog-height-big" : ""')
  v-col.d-flex.align-center.pa-5.pb-0
    v-row(no-gutters)
      v-col(cols="7")
        .d-flex.align-center
          h2.my-0 {{$t('MAINCONTACTS.TITLE')}}
          v-btn.ml-3.add-deal-btn(
            @click="showAddMainContact(contactData.orgId)",
            width="34",
            height="34",
            min-width="34",
            :ripple="false",
            color="success",
            depressed,
            dark,
            fab
          )
            v-icon(size="16") icon-add
      v-col.text-right(cols="4")
        .btn-group.ml-auto.mr-2
          v-text-field(
            v-model="searchMainContact",
            flat,
            dense,
            solo,
            prepend-inner-icon="icon-search",
            hide-details
          )
      v-col.text-right(cols="1")
        v-btn.ml-auto(
          v-hide-element='false'
          :ripple="false",
          @click="onCancel",
          depressed="depressed",
          icon
        )
          v-icon icon-cancel
  v-card-text.pt-4.px-5.pb-5
    .data-area
      v-data-table.border-table(
        :headers="mainContactHeader",
        :items="contactData",
        :items-per-page="mainContactItemPerPage",
        :page.sync="mainContactPage",
        :search="searchMainContact",
        multi-sort
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}"
        @page-count="mainContactPageCount = $event",
        hide-default-footer="hide-default-footer"
      )
        template(v-slot:item.organizationcontacts_set[0].main_contact="{ item }")
          .w-100.d-flex.align-center.justify-center
            v-checkbox(
              v-model="item.organizationcontacts_set[0].main_contact",
              @click="onMain(item.organizationcontacts_set[0].id)"
            )
        template(v-slot:item.full_name="{ item }")
          .ellipsis
              a.ellipsis(:href="'/Contacts/' + item.id" :title='item.full_name') {{ item.full_name }}
        template(v-slot:item.job="{ item }") 
          p.ma-0.ellipsis(:title='item.job') {{ item.job }}
        template(v-slot:item.organizationcontacts_set[0].juristic_person_name="{ item }")
          p.ma-0.ellipsis(:title='item.organizationcontacts_set[0].juristic_person_name') {{ item.organizationcontacts_set[0].juristic_person_name }}
        template(v-slot:item.organizationcontacts_set[0].shareholding="{ item }" )
          p.ma-0.ellipsis(:title='item.organizationcontacts_set[0].shareholding') {{ item.organizationcontacts_set[0].shareholding }}
        template(v-slot:item.mobile_phone="{ item }")
            v-btn.w-100.d-block(v-if='item.mobile_phone != null' :href="onCall(item.mobile_phone)" color="primary" text :title='item.mobile_phone') 
                .ellipsis.pt-2.w-100 
                    span.w-100 {{ item.mobile_phone }} 
        template(v-slot:item.email="{ item }")
            v-btn.w-100.d-block(v-if='item.email != null' :href="onMail(item.email)" color="primary" text :title='item.email') 
                .ellipsis.pt-2.w-100 
                    span.w-100 {{ item.email }} 
        template(v-slot:item.active="{ item }")
          v-btn(icon="icon", @click="showEditContact(item)")
            v-icon(size="18") icon-edit
          v-btn(
            icon="icon",
            @click="onDelete(item.organizationcontacts_set[0].id)"
          )
            v-icon(size="18") icon-remove
      v-pagination.mt-3(
        v-model="mainContactPage",
        :length="mainContactPageCount",
        circle="circle"
        :total-visible="10"
      )
  v-dialog(v-model="addMainContactDialog" max-width="500" v-if='addMainContactDialog')
    add-main-contact(@emitAddMainContactDialog="onEmitAddMainContactDialog" :add-main-contact="addMainContactData")
  v-dialog(
    v-model="successDialog",
    width="360",
    content-class="statusDialog"
  )
  v-dialog(v-model="editMainContactDialog" max-width="500" v-if='editMainContactDialog')
    edit-main-contact(@emitEditMainContactDialog="onEmitEditMainContactDialog" :edit-main-contact="edtorMainContactData")
  v-dialog(
    v-model="successDialog",
    width="360",
    content-class="statusDialog"
  )
    success-dialog(@emitSuccessDialog="onEmitSuccessDialog")
  v-dialog(
    v-model="messageDialog",
    width="360",
    content-class="statusDialog"
  )
    message-dialog(
      @emitMessageDialog="onEmitMessageDialog",
      :message="message"
    )
</template>

<script>
import Vue from "vue";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import OrganizationDataService from "@/services/OrganizationDataService";
import addMainContact from "@/components/Dialog/Contact/addMainContact";
import editMainContact from "@/components/Dialog/Contact/editMainContact";
import i18n from '/common/plugins/vue-i18n.js' 


export default Vue.extend({
  props: {
    contactData: {
      type: Array,
      required: true,
    },
  },
  components: {
    addMainContact,
    editMainContact,
    successDialog,
    messageDialog,
  },
  created() {
    this.contactData.forEach((data) => {
      if(data.first_name != null){
        data.full_name = data.last_name +" "+ data.first_name;
      }
      else{
        data.full_name = data.last_name;
      }
    });
  },
  data() {
    return {
      searchMainContact: "",
      mainContactHeader: [
        {
          text: i18n.t('MAINCONTACTS.PRIMARY'),
          value: "organizationcontacts_set[0].main_contact",
          align: "center",
          filterable: false,
          width: '10%',
        },
        {
          text: i18n.t('MAINCONTACTS.CONTACT_NAME'),
          value: "full_name",
          align: "center",
          filterable: true,
        //   width: '15%',
        },
        {
          text: i18n.t('MAINCONTACTS.POSITION'),
          value: "job",
          align: "center",
          filterable: true,
        //   width: '12%',
        },
        {
          text: i18n.t('MAINCONTACTS.JURISTIC_PERSON_NAME'),
          value: "organizationcontacts_set[0].juristic_person_name",
          align: "center",
          filterable: true,
        //   width: '14%',
        },
        {
          text: i18n.t('MAINCONTACTS.SHAREHOLDING'),
          value: "organizationcontacts_set[0].shareholding",
          align: "center",
        //   width: '8%',
        },
        {
          text: i18n.t('MAINCONTACTS.PHONE'),
          value: "mobile_phone",
          align: "center",
          filterable: true,
        //   width: '15%',
        },
        { 
          text: i18n.t('MAINCONTACTS.MAIL'), 
          value: "email", 
          align: "center", 
          filterable: true,
        //   width: '18%', 
        },
        { 
          text: i18n.t('MAINCONTACTS.ACTIVE'), 
          value: "active", 
          align: "center", 
          sortable: false,
          width: '12%', 
        },
      ],
      mainContactPage: 1,
      mainContactPageCount: 0,
      mainContactItemPerPage: 8,
      successDialog: false,
      messageDialog: false,
      message: "",
      deleteContactIds: "",
      delData: {},
      delIndex: "",
      // Dialog
      addMainContactDialog: false,
      editMainContactDialog: false,
      addMainContactData:{},
      edtorMainContactData:{},
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    onCancel() {
      this.$emit("emitMainContactsDialog", false);
    },
    // Common Function
    onCall: function (cell) {
      return "tel:" + cell;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showAddMainContact(data) {
      this.addMainContactDialog = true;
      this.addMainContactData = data;
    },
    onEmitAddMainContactDialog(val) {
      this.addMainContactDialog = false;
      if (val) {
        this.showSuccessDialog();
        this.$emit('MainContact','success');
      }
    },
    showEditContact(data) {
      this.editMainContactDialog = true;
      if(!data.full_name)
      {
        data.full_name = data.last_name +" "+ data.first_name;
      }
      this.edtorMainContactData = data;
      this.edtorMainContactData.orgid = this.contactData.orgId      
    },
    onEmitEditMainContactDialog(val) {
      this.editMainContactDialog = false;
      if (val) {
        this.showSuccessDialog();
        this.$emit('MainContact','success');
      }
    },
    onEmitAddContactDialog(val) {
      this.addContactDialog = false;
      if (val) {
        this.showSuccessDialog();
      }
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onDelete(id) {
      this.showMessageDialog(i18n.t('MAINCONTACTS.MESSAGE'));
      this.deleteContactId = id;
      // this.delIndex = this.contactData.findIndex((item) => item.organizationcontacts_set[0].id == id)
    },
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if (val) {
        if (this.deleteContactId != false) {
          OrganizationDataService.maincontactdel(this.deleteContactId)
          .then(() => {
            this.$emit('MainContact','success');
          })
        }
        this.showSuccessDialog();
      }
    },
    onMain(id) {
      OrganizationDataService.maincontactedit(id);
    },
  },
});
</script>
<template lang='pug'>
    #Deal.main-page.page-noScroll.detail-page
        v-overlay(v-model='loading' z-index='99' opacity='.6')
            v-progress-circular(indeterminate color='primary')
        div.height_100(v-show='!loading')
            v-breadcrumbs.pa-0.px-3.pb-2.ellipsis(:items='breadcrumbs')
            v-row#page-head.d-flex.align-center.my-0
                v-col(cols='2' lg='4')
                    .d-flex.align-center.title-height
                        h1.my-0.d-flex.align-center.ellipsis(:title='opptyDetails.name')
                            .cube-icon.bg-og.mr-3
                                v-icon(color='white' size='22') icon-chatmoney-border
                            span.ellipsis {{ opptyDetails.name }}
                        followBtn(v-if='!isTechUser' :follow='isFollow' :page='"Opportunity"' :target_id='target_id' @emitChangeFollow='onEmitChangeFollow')
                v-col(cols='10' lg='8')
                    v-row.justify-md-end(v-if='!isTechUser' no-gutters='no-gutters')
                        v-col.ml-2(cols='auto')
                            .btn-group
                                v-btn(@click='showAddDealDialog' width='60' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.COPY')}}
                                v-btn.border-left(v-if='!isProcessComplete && checkWiadvanceShow()' @click='onDelete' width='60' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.DELETE')}}
                        v-col.ml-2(cols='auto')
                            .btn-group(v-if='!isProcessComplete')
                                v-btn( @click='onChangeOwn(opptyDetails)' width='100%'  height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.CHANGE_OWNER')}}
                        v-col.ml-2(cols='auto')
                            .btn-group(v-if='!isProcessComplete')
                                v-btn(@click='onChangeProcess(opptyDetails)' width='100%' height='30' color='white' depressed tile dark)
                                    span {{$t('DEAL_DETAIL.CHANGE_STAGE')}}
                        //- v-col.ml-2(cols='auto' v-if='!isProcessComplete')
                        //-     v-btn(@click='showAddCaseDialog' width='110' height='32' color='green' depressed dark)
                        //-         v-icon.mr-1(size='14') icon-add
                        //-         span {{$t('GENERAL.ADD_Case')}}
                        v-col.ml-2(v-if='!isProcessComplete' cols='auto')
                            v-btn(@click='onWon()' width='60' height='32' color='green' depressed dark)
                                span Won
                            v-menu(offset-y).z-100
                                template(v-slot:activator="{ on, attrs }")
                                    v-btn.ml-2( v-bind='attrs' v-on='on' width='60' height='32' color='red' depressed dark)
                                        span Lost
                                v-list
                                    v-list-item(v-for="(item, index) in closeReasonList" :key='index' link v-if='item.key >1 && item.selected')
                                        v-list-item-title(@click='onLost(item.key)') {{ item.name }}
                        v-col.ml-2(v-else cols='auto')
                            v-btn.mr-4(@click='onReStart()' width='100' height='32' color='green' depressed dark)
                                span {{$t('DEAL_DETAIL.REOPEN')}}
                            v-chip.mr-4(v-if='isProcessLost' color='red' text-color='white') {{$t('TO_DEAL.LOST')}}，{{$t('TO_DEAL.BECAUSE')}}{{ closeReasonName }} {{$t('TO_DEAL.IN')}} {{ opptyDetails.closed_date }} {{$t('TO_DEAL.CLOSED')}}
                            v-chip.mr-4(v-if='isProcessWon' color='green' text-color='white') {{$t('TO_DEAL.IN')}} {{ opptyDetails.closed_date }} {{$t('TO_DEAL.WIN')}}
            #page-inner.pa-3.pt-0
                v-card.card-preface.card-shadow.px-0.py-6(flat)
                    v-row
                        v-col.py-0.px-2.px-md-4.ellipsis(cols='6' md='2')
                            v-card-subtitle.pa-0.t-lightgary {{$t('DEAL_DETAIL.CLIENT_NAME')}}
                            a.f-text-highlight.mb-0.t-primary(v-if='opptyDetails.oppty_org != null' :href='"/Account/" + opptyDetails.oppty_org.id' target='_blank') {{ opptyDetails.oppty_org.name }}
                            a.f-text-highlight.mb-0.t-primary(v-if='opptyDetails.oppty_contact != null' :href='"/Contacts/" + opptyDetails.oppty_contact.id' target='_blank') {{ opptyDetails.oppty_contact.__chineseName__ }}
                        v-col.py-0.px-2.px-md-4.border-left.ellipsis(cols='6' md='2')
                            v-card-subtitle.pa-0.t-lightgary {{$t('DEAL_DETAIL.EXPECTED_CLOSE_DATE')}}
                            p.f-text-highlight.mb-0 {{ opptyDetails.expected_close_date }}
                        v-col.py-0.px-2.px-md-4.border-left(cols='6' md='2')
                            v-card-subtitle.pa-0.t-lightgary {{$t('DEAL_DETAIL.AMOUNT')}}
                            p.f-text-highlight.mb-0.t-green.ellipsis {{ formatAmount }}
                        v-col.py-0.px-2.px-md-4.border-left.ellipsis(cols='6' md='2')
                            v-tooltip(top v-if='haveTip')
                                template(v-slot:activator='{ on, attrs }' )
                                    div.pointer(v-bind='attrs' v-on='on')
                                        v-card-subtitle.pa-0.t-lightgary {{$t('DEAL_DETAIL.SCORE')}}
                                        span.mx-auto(v-if="opptyDetails.closed_reason === 1") 100
                                        span.mx-auto(v-else-if="opptyDetails.closed_reason > 1") 0
                                        span.mx-auto(v-else) {{ Math.ceil(score) }}  
                                p.ma-0(v-if='haveTip') {{ opptyDetails.stage_score.tip.account_history }}
                                p.ma-0(v-if='haveTip') {{ opptyDetails.stage_score.tip.amount }}
                                p.ma-0(v-if='haveTip') {{ opptyDetails.stage_score.tip.expected_closing_date }}
                                p.ma-0(v-if='haveTip') {{ opptyDetails.stage_score.tip.forecast }}
                                p.ma-0(v-if='haveTip') {{ opptyDetails.stage_score.tip.stage }} 
                            .pointer(v-else)
                                v-card-subtitle.pa-0.t-lightgary {{$t('DEAL_DETAIL.SCORE')}}
                                span.mx-auto(v-if="opptyDetails.closed_reason === 1") 100
                                span.mx-auto(v-else-if="opptyDetails.closed_reason > 1") 0
                                span.mx-auto(v-else) {{ Math.ceil(score) }}
                        v-col.py-0.px-2.px-md-4.border-left.ellipsis(cols='6' md='2')
                            v-card-subtitle.pa-0.t-lightgary {{$t('DEAL_DETAIL.PROGRESS')}}
                            p.f-text-highlight.mb-0 {{ Math.ceil(opptyDetails.stage_progress*100) }}%
                        v-col.py-0.px-2.px-md-4.border-left(cols='12' md='2')
                            v-list-item.pa-0(href='/Setting' target='_blank')
                                v-list-item-avatar.ma-0.mr-2(color='aliceBlue')
                                    span.mx-auto(v-if="!isPhoto") {{ opptyDetails.owner? opptyDetails.owner.last_name : '' }}
                                    img(v-else :src='opptyDetails.owner.usersetting.photo' :alt='opptyDetails.owner.last_name')
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('DEAL_DETAIL.OWNER')}}
                                    a(:href='onMail(opptyDetails.owner.email)').t-primary.mb-0.f-text-highlight.ellipsis {{ opptyDetails.owner ? opptyDetails.owner.last_name : '' }} {{ opptyDetails.owner? opptyDetails.owner.first_name : '' }}
                v-card.process-card.mt-4(:class="stepContent ? '':'hide-process-info'")
                    v-btn.process-box-open(v-hide-element='false' v-if='!isProcessComplete' width='40' height='40' depressed fab outlined @click='switchStepContent()')
                        v-icon(size='14' v-if='stepContent === true') icon-arrow-top
                        v-icon(size='14' v-else) icon-arrow-btm
                    v-stepper.process-box(v-model='currentStep' :class="isProcessComplete ? 'complete':''")
                        v-stepper-header.process-bar(:style='isProcessWon ? "" : "margin-left: 50px"')
                            template(v-for='item in opptySummary')
                                v-stepper-step(@click='onChangeOpptyStage(item)' :key='`${item.sequence_number}-process`' :complete='currentStep > item.sequence_number || isProcessWon' :step='item.sequence_number' :class='customStagecolor'  :style='getcolor(item.stage_color)'  edit-icon='icon-check' :editable='isProcessEdit && !isTechUser && !$isViewOnly.isViewOnly')
                                    span(v-if='currentStep < item.sequence_number')  {{ item.stage_name }}
                                    span(v-else)  {{ item.stage_name }} {{ (item.stage_duration/(24*60*60)).toFixed(1) }} 天
                            v-btn.mx-1(v-hide-element='false' v-if='allStage > 6 && isFirst && isProcessEdit' absolute style="marginTop: 4px; zIndex: 50;" fab dark x-small color="white" @click='preStage()')
                                v-icon(color="primary") icon-arrow-left
                            v-btn(v-hide-element='false' v-if='allStage > 6 && isLast && isProcessEdit' absolute style="marginTop: 4px; zIndex: 50; right: 5px"  fab dark x-small color="white" @click='nextStage()')
                                v-icon(color="primary") icon-arrow-right
                        v-stepper-items(:class="stepContent ? '':'hide'")
                            v-stepper-content.pa-0(v-for='(item, index) in opptySummary' :key='`${item.sequence_number}-process`' :step='item.sequence_number')
                                v-card(color flat) 
                                    v-form(ref='basicForm' v-model='validForm.basic')
                                        v-row(no-gutters='no-gutters')
                                            v-col.pr-md-4(cols='12' md='6')
                                                .d-flex.align-center
                                                    h4.my-2 {{$t('TO_DEAL.INFO')}}
                                                    .d-flex.align-center.ml-auto(v-if='!showEditKeywordCheckBtn && !isProcessComplete && !isTechUser')
                                                        v-btn(width='70' text color='gary' @click='editKeyword(item)')
                                                            v-icon(size='20') icon-edit
                                                            span {{$t('GENERAL.EDIT')}} 
                                                    .d-flex.align-center.ml-auto(v-if='showEditKeywordCheckBtn')
                                                        v-btn(width='70' text color='gary' @click='cancelEditKeyword()')
                                                            v-icon(size='20') icon-cancel
                                                            span {{$t('GENERAL.CANCEL')}}
                                                        v-btn.ml-3(width='70' text color='success' @click='confirmEditKeyword()')
                                                            v-icon(size='20') icon-check
                                                            span {{$t('GENERAL.SAVE')}} 
                                                p.d-flex.align-center.input-has-label.dance
                                                    label(:for='`${index}-budget_confirmed`') {{$t('TO_DEAL.BUDGET')}}
                                                    v-checkbox(v-model='opptyDetails.budget_confirmed' :id='`${index}-budget_confirmed`' :readonly='isReadonlyKeyword' dense='dense' :hide-details='true')
                                            v-col.pr-md-4(cols='12' md='6')
                                                h4.my-2 {{$t('TO_DEAL.GUIDE')}}
                                                p.d-flex.align-center.input-has-label.dance
                                                    v-text-field(v-model='item.guideline' :id='`${index}-guideline`' solo readonly dense='dense' :hide-details='true' height='41px')
                                            v-col.pr-md-4(cols='12' md='6').my-3
                                                p.d-flex.align-start.input-has-label.border-0
                                                    label.mt-2 {{$t('TO_DEAL.DESCRIPTION')}}
                                                    v-textarea.textarea-style(v-model='opptyDetails.description' :id='`${index}-describe`' solo no-resize :readonly='isReadonlyKeyword' dense='dense' rows='2')
                                            v-col.pr-md-4(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label.dance.border-0.mt-5
                                                    label(:for='`${index}-stage_probability`') {{$t('TO_DEAL.PROBABILITY')}}
                                                    label.t-black(v-if='item.stage_probability != null') {{ item.stage_probability +" %" }}
                v-row.pinning-card.hidden-sm-only.hidden-xs-only(no-gutters v-for='(item,index) in alertNote' :key='index' :style='setPosition(index)' :class='pinning ? "": "d-none"')
                    v-col(cols='1')
                        v-icon.ml-2(color='white') mdi-firework
                    v-col(:cols='index === 0 ? "7" : "9" ').ellipsis
                        strong.ellipsis {{ item.content.replace( /(<([^>]+)>)/ig, '') }}
                    v-col(:cols='index === 0 ? "4" : "2" ').d-flex.justify-end
                        span.ml-3 {{ item.alertTime }} 
                        .mx-3.d-flex( v-if='index === 0')
                            v-btn.process-box-open(v-hide-element='false' v-if='alertNote.length > 1' width='24' height='24' depressed fab outlined @click='folder(index)')
                                v-icon(size='14' v-if='getfolder === false') icon-arrow-top
                                v-icon(size='14' v-else) icon-arrow-btm
                            v-menu(bottom left)
                                template(v-slot:activator='{ on, attrs }')
                                    v-btn.ml-3(v-hide-element='false' color='white' dark v-bind='attrs' v-on='on' icon dense min-height='20' height='20' width='20')
                                        v-icon(size='16')  icon-dot-3
                                v-list.pa-0
                                    v-list-item.px-0.text-center
                                        v-btn(v-hide-element='false' @click='onPinning()' text='text')
                                            span.ml-1 {{$t('GENERAL.CLOSE')}}
                v-row(no-gutters='no-gutters' :class="stepContent ? 'deal-content mt-4':'deal-content big mt-4'")
                    v-col(cols='12' md='8')
                        v-card.deal-tab-card(flat)
                            v-tabs.main-tabs(v-model='tab' background-color='transparent' show-arrows)
                                v-tabs-slider
                                v-tab(v-for='item in tabs' :key='item.key' :href='`#tab-${item.id}`' @click='getTabName(item.key)')
                                    | {{ item.name }}
                                // - Detail -
                                v-tab-item.pa-3(key='detail' value='tab-1' :transition='false' :reverse-transition='false')
                                    v-form(v-model="valid" ref='formOpptyDetail')
                                        v-row(no-gutters).pb-5
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='dealName') {{$t('DEAL_DETAIL.NAME')}}
                                                    v-text-field#dealName.ellipsis(v-model='opptyDetails.name' solo='solo' :readonly='isDetailReadonly' :rules="formRule.nameRules")
                                                        template(v-slot:append v-if='!isProcessComplete && !isTechUser')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit  
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('DEAL_DETAIL.SERIAL_NUMBER')}}
                                                    v-text-field.ellipsis(v-model='opptyDetails.serial_number'  solo='solo' readonly)  
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='amount') {{$t('DEAL_DETAIL.AMOUNT')}}
                                                    v-text-field(v-model='opptyDetails.amount' solo='solo'  :rules='formRule.amountRule' v-show='!isDetailReadonly&&amountShow|!amountValidate' :ref="'editAmount'" @blur='showFormatAmount("amount")')
                                                        template(v-slot:append v-if='!isProcessComplete && !isTechUser')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                                    v-text-field(v-model='formatAmount' solo='solo' @click='showEditAmount("amount")' @blur='showEditAmount("amount")' :readonly='true' v-show='!amountShow|!amountShow&&amountValidate')
                                                        template(v-slot:append v-if='!isProcessComplete && !isTechUser')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('DEAL_DETAIL.CREATED_ON')}}
                                                    v-text-field.ellipsis(v-model='created_on'  solo='solo' readonly)
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('DEAL_DETAIL.STATUS')}}
                                                    v-text-field.ellipsis(v-model='opptyDetails.status'  solo='solo' readonly) 
                                            v-col.pr-md-4.my-1(cols='12' md='6' v-if='is_opptyOrg')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label(for='client') {{$t('DEAL_DETAIL.CLIENT_NAME')}}
                                                        v-text-field(v-if='!isDetailReadonly && isNew.oppty_org' solo :label=`$t('GENERAL.LAST_NAME')`  validate-on-blur required="required" large="large")
                                                        v-text-field(v-if='!isDetailReadonly && isNew.oppty_org' solo :label=`$t('GENERAL.FIRST_NAME')` large="large")
                                                        v-combobox#oppty_org(v-if='!isDetailReadonly && !isNew.oppty_org' v-model="opptyDetails.oppty_org" solo :items="organizationList" item-text="name" item-value="uqNumber" :search-input.sync="search_oppty_org" :loading="isLoading.oppty_org" :rules='formRule.companyRule')
                                                            template(v-slot:append-outer v-if='!isProcessComplete && !isTechUser')
                                                                div.mr-1(v-if="isOrgNew")
                                                                    v-icon(color="red", size="25") mdi-new-box
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                                            template(v-slot:item="data" v-if='!isProcessComplete && !isTechUser')
                                                                div.ellipsis(v-if="data.item.id == 0")
                                                                    span {{ data.item.name }}
                                                                    v-icon(color="red", size="25") mdi-new-box
                                                                div.ellipsis(v-else)
                                                                    span {{ data.item.name }}
                                                                    span.t-black.ml-2(v-if='data.item.customer_code') [ {{data.item.customer_code }} ]
                                                        .d-flex.justify-space-between.ellipsis(:class='isDetailReadonly ? "w-100":""')
                                                            a.t-primary.ellipsis(v-if="opptyDetails.oppty_org != null && isDetailReadonly" :href="'/Account/' + opptyDetails.oppty_org.id" :title='opptyDetails.oppty_org.name') {{ opptyDetails.oppty_org.name }}
                                                            v-icon(v-if='isDetailReadonly && !isProcessComplete && !isTechUser' size='18' v-hide-element @click='onEditDetail()') icon-edit
                                            //- v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label(for='primary_contact') {{$t('DEAL_DETAIL.CONTACT')}}
                                                        v-text-field.pr-2(v-if='!isDetailReadonly && isNew.primary_contact' v-model="newPri.lName" solo :label=`$t('GENERAL.LAST_NAME')` large="large" :rules='formRule.contactnNameRules' @input='cleanFirstName(newPri)')
                                                        v-text-field(v-if='!isDetailReadonly && isNew.primary_contact && newPri.lName' v-model="newPri.fName" solo :label=`$t('GENERAL.FIRST_NAME')` :rules='formRule.contentLenRule50' large="large")
                                                            template(v-slot:append-outer)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                                        v-combobox#primary_contact( v-if='!isDetailReadonly && !isNew.primary_contact' v-model="opptyDetails.primary_contact" solo :items="contactData" item-text="searchStr" item-value="id" :search-input.sync="search_primary_contact" :loading="isLoading.primary_contact" )
                                                            template(v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                                            template(v-slot:prepend-item )
                                                                v-list-item
                                                                    v-btn(width='120' height='32' color='blue' plain border-0 @click='isNew.primary_contact = true')
                                                                        v-icon.mr-1(size='14') icon-add
                                                                        span {{$t('ADD_DEAL.NEW_CONTACT')}}
                                                            template(v-slot:item='data')                          
                                                                .t-black {{ data.item.full_name }} 
                                                            template(v-slot:selection='data')
                                                                .t-black {{ data.item.full_name }} 
                                                        .d-flex.justify-space-between.ellipsis(:class='isDetailReadonly?"w-100":""')
                                                            a.t-primary.ellipsis(v-if="opptyDetails.primary_contact != null && isDetailReadonly" :href="'/Contacts/'+ opptyDetails.primary_contact.id") {{ opptyDetails.primary_contact.__chineseName__ }}
                                                            v-icon.ml-auto(v-if='isDetailReadonly && !isProcessComplete' size='18' v-hide-element @click='onEditDetail()') icon-edit
                                            v-col.pr-md-4.my-1(cols='12' md='6' v-else)
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label(for='oppty_contact') {{$t('DEAL_DETAIL.CLIENT_NAME')}}
                                                        v-text-field.pr-2(v-if='!isDetailReadonly && isNew.oppty_contact' v-model="newOppCon.lName" solo :label=`$t('GENERAL.LAST_NAME')` :rules='formRule.contactRules' large="large" @input='cleanFirstName(newOppCon)')
                                                        v-text-field(v-if='!isDetailReadonly && isNew.oppty_contact' v-model="newOppCon.fName" solo :label=`$t('GENERAL.FIRST_NAME')` large="large" :rules='formRule.contentLenRule50')
                                                            template(v-slot:append-outer v-if='!isProcessComplete')
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                                        v-combobox#oppty_contact(v-if='!isDetailReadonly && !isNew.oppty_contact' v-model="opptyDetails.oppty_contact" solo :items="contactData" :rules='formRule.opptyContactRules' item-text="searchStr" item-value="id" :search-input.sync="search_oppty_contact" :loading="isLoading.oppty_contact")
                                                            template(v-slot:append v-if='!isProcessComplete')
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                                            template(v-slot:prepend-item v-if='!isProcessComplete')
                                                                v-list-item
                                                                    v-btn(width='120' height='32' color='blue' plain border-0 @click='isNew.oppty_contact = true')
                                                                        v-icon.mr-1(size='14') icon-add
                                                                        span {{$t('DEAL_DETAIL.NEW_CLIENT')}}
                                                            template(v-slot:item='data' v-if='!isProcessComplete')
                                                                v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                                                    v-icon(v-if="data.item.image == null") mdi-account
                                                                    img(v-else :src='data.item.image')
                                                                .t-black {{ data.item.full_name }} 
                                                                .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                                            template(v-slot:selection='data' v-if='!isProcessComplete')
                                                                .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                                                        .d-flex.justify-space-between.w-100.ellipsis(v-if="opptyDetails.oppty_contact != null && isDetailReadonly")
                                                            a.t-primary.ellipsis( :href="'/Contacts/'+ opptyDetails.oppty_contact.id" :title='opptyDetails.oppty_contact.__chineseName__') {{ opptyDetails.oppty_contact.__chineseName__ }}
                                                            v-icon(size='18' v-hide-element @click='onEditDetail()' v-if='!isProcessComplete && !isTechUser') icon-edit
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label(for='primary_contact') {{$t('DEAL_DETAIL.CONTACT')}}
                                                        v-text-field.pr-2(v-if='!isDetailReadonly && isNew.primary_contact' v-model="newPri.lName" solo :label=`$t('GENERAL.LAST_NAME')` large="large" :rules='formRule.contactRules' @input='cleanFirstName(newPri)')
                                                        v-text-field(v-if='!isDetailReadonly && isNew.primary_contact' v-model="newPri.fName" solo :label=`$t('GENERAL.FIRST_NAME')` :rules='formRule.contentLenRule50' large="large")
                                                            template(v-slot:append-outer)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                                        v-combobox#primary_contact( v-if='!isDetailReadonly && !isNew.primary_contact' v-model="opptyDetails.primary_contact" solo :items="contactData" item-text="searchStr" item-value="id" :search-input.sync="search_primary_contact" :loading="isLoading.primary_contact" :rules='formRule.contactRules')
                                                            template(v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                                            template(v-slot:prepend-item )
                                                                v-list-item
                                                                    v-btn(width='120' height='32' color='blue' plain border-0 @click='isNew.primary_contact = true')
                                                                        v-icon.mr-1(size='14') icon-add
                                                                        span {{$t('ADD_DEAL.NEW_CONTACT')}}
                                                            template(v-slot:item='data')
                                                                v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                                                    v-icon(v-if="data.item.image == null") mdi-account
                                                                    img(v-else :src='data.item.image')
                                                                .t-black {{ data.item.full_name }} 
                                                                .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                                            template(v-slot:selection='data')
                                                                .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                                                        .d-flex.justify-space-between.ellipsis(:class='isDetailReadonly?"w-100":""')
                                                            a.t-primary.ellipsis(v-if="opptyDetails.primary_contact != null && isDetailReadonly" :href="'/Contacts/'+ opptyDetails.primary_contact.id") {{ opptyDetails.primary_contact.__chineseName__ }}
                                                            v-icon.ml-auto(v-if='isDetailReadonly && !isProcessComplete && !isTechUser' size='18' v-hide-element @click='onEditDetail()') icon-edit
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label Customer Code
                                                    v-text-field.ellipsis(v-model='customer_code'  solo='solo' readonly)
                                            //- oppty_status
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='oppty_status') {{$t('DEAL_DETAIL.OPPTY_STATUS')}}
                                                    v-select#forecast_category.no-border(v-model='opptyDetails.oppty_status' :menu-props='{closeOnClick:true}' solo :items='opptyStatusList' item-text='name' item-value='key' :readonly='isDetailReadonly' :rules="formRule.requireRules")
                                                        template(v-slot:append-outer v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='customer_budget') {{$t('DEAL_DETAIL.CLIENT_BUDGET')}}
                                                    v-text-field(v-model='opptyDetails.customer_budget' solo='solo' :rules='formRule.customerBudgetRule' v-show='!isDetailReadonly&&budgetShow|!budgetValidate' :ref="'editBudget'" @blur='showFormatAmount("budget")')
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly  && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary') 
                                                    v-text-field(v-model='formatBudget' solo='solo' @click='showEditAmount("budget")' @blur='showEditAmount("budget")' :readonly='true' v-show='!budgetShow|!budgetShow&&budgetValidate')
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly  && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='forecast_category') {{$t('DEAL_DETAIL.FORECAST_CATEGORY')}}
                                                    v-select#forecast_category.no-border(v-model='opptyDetails.forecast_category' :menu-props='{closeOnClick:true}' solo :items='forecast_category_List' item-text='name' item-value='key' :readonly='isDetailReadonly')
                                                        template(v-slot:append-outer v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='expected_close_date') {{$t('DEAL_DETAIL.EXPECTED_CLOSE_DATE')}}
                                                    v-text-field#expected_close_date(v-if='isDetailReadonly' v-model='opptyDetails.expected_close_date' solo :readonly='isDetailReadonly')
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                    v-menu(v-if='!isDetailReadonly' ref="menu" v-model="menu" :z-index='100' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                                                        template(v-slot:activator="{ on, attrs }")
                                                            v-text-field(v-model='opptyDetails.expected_close_date' solo :readonly='!isDetailReadonly' v-bind="attrs" v-on="on")
                                                                template(v-slot:append)
                                                                    v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                                    v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                    v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                                        v-date-picker(v-model='opptyDetails.expected_close_date' @input='menu = false' no-title scrollable) 
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='primary_campaign') {{$t('DEAL_DETAIL.RELATED_CAMPAIGN')}}
                                                    v-autocomplete#primary_campaign.no-border(v-model='opptyDetails.primary_campaign_id' solo :items='campaignList' item-text='name' item-value='id' :readonly='isDetailReadonly' :menu-props='{closeOnClick:true}')
                                                        template(v-slot:append-outer v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                                        template(v-slot:selection='data') 
                                                            a.t-primary.ellipsis(:href="'/Campaign/'+ data.item.id" style='max-width: 250px;') {{ data.item.name }}

                                            //- industry
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='industry') {{$t('DEAL_DETAIL.INDUSTRY')}}
                                                    v-select#forecast_category.no-border(v-model='opptyDetails.industry' :menu-props='{closeOnClick:true}' solo :items='opptyIndustryList' item-text='name' item-value='key' :readonly='isDetailReadonly' :rules="formRule.requireRules")
                                                        template(v-slot:append-outer v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            //- customer_source
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='customer_source') {{$t('DEAL_DETAIL.CUSTOMER_SOURCE')}}
                                                    v-select#forecast_category.no-border(v-model='opptyDetails.customer_source' :menu-props='{closeOnClick:true}' solo :items='customerSourceList' item-text='name' item-value='key' :readonly='isDetailReadonly' :rules="formRule.requireRules")
                                                        template(v-slot:append-outer v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            //- progress
                                            //- v-col.pr-md-4.my-1(cols='12' md='6')
                                            //-     p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                            //-         label(for='progress') {{$t('DEAL_DETAIL.PROGRESS_2')}}
                                            //-         v-select#forecast_category.no-border(v-model='opptyDetails.progress' :menu-props='{closeOnClick:true}' solo :items='opptyProgressList' item-text='name' item-value='key' :readonly='isDetailReadonly' :rules="formRule.requireRules")
                                            //-             template(v-slot:append-outer v-if='!isProcessComplete')
                                            //-                 v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                            //-                 v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                            //-                 v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                            //-                 v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            //- other_customer_source
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='other_customer_source') {{$t('DEAL_DETAIL.OTHER_CUSTOMER_SOURCE')}}
                                                    v-text-field#dealName.ellipsis(v-model='opptyDetails.other_customer_source' solo='solo' :readonly='isDetailReadonly' :rules="formRule.contentLenRule100")
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            //- oppty_type
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='oppty_type') {{$t('DEAL_DETAIL.OPPTY_TYPE')}}
                                                    v-select#forecast_category.no-border(v-model='opptyDetails.oppty_type' :menu-props='{closeOnClick:true}' solo :items='opptyTypeList' item-text='name' item-value='key' :readonly='isDetailReadonly')
                                                        template(v-slot:append-outer v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            //- 終端客戶
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='end_account') {{$t('DEAL_DETAIL.ENDACCOUNT')}}
                                                    v-select#forecast_category.no-border(v-model='opptyDetails.end_account_id' :menu-props='{closeOnClick:true}' solo :items='opptyDetails.end_account_list' item-text='name' item-value='id' :readonly='isDetailReadonly')
                                                        template(v-slot:append-outer v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='customer_needs') {{$t('DEAL_DETAIL.CUSTOMER_NEEDS')}}
                                                    v-textarea#customer_needs.textarea-style(v-model='opptyDetails.customer_needs' solo='solo' :readonly='isDetailReadonly' no-resize)
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='purchase_needs_or_process') {{$t('DEAL_DETAIL.PURCHASE_NEEDS_OR_PROCESS')}}
                                                    v-textarea#customer_needs.textarea-style(v-model='opptyDetails.purchase_needs_or_process' solo='solo' :readonly='isDetailReadonly' no-resize)
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='expect_or_nextsteps_or_others') {{$t('DEAL_DETAIL.EXPECT_OR_NEXTSTEPS_OR_OTHERS')}}
                                                    v-textarea#customer_needs.textarea-style(v-model='opptyDetails.expect_or_nextsteps_or_others' solo='solo' :readonly='isDetailReadonly' no-resize)
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='sales_notes') {{$t('DEAL_DETAIL.SALES_NOTES')}}
                                                    v-textarea#customer_needs.textarea-style(v-model='opptyDetails.sales_notes' solo='solo' :readonly='isDetailReadonly' no-resize)
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_dealDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_dealDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !isTechUser' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_dealDeatil' indeterminate color='primary')
                                        customField(v-if='!loading' :Data='customFieldData' DataClass = 'Opportunity' :Editable='cFEditable' :StageTypeId='StageTypeId')
                                // - note -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isNoteTab")
                                    v-btn(@click='onAddNote()' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1(size='14') icon-add
                                        span {{$t('CASE_DETAIL.ADD_NOTE')}}
                                v-tab-item.pa-3(key='note' value='tab-3' :transition='false' :reverse-transition='false')
                                    .editor
                                    v-row.sheet-box.my-5(v-if='!hasType(opptyTimeLines, "NOTE")')
                                        v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_NOTE')}}
                                    v-timeline.mt-5.pt-0(dense align-top)
                                        template(v-for='(item, index) in opptyTimeLines')
                                            v-timeline-item(v-if="item.type === 'NOTE'" :key='index' color='success' icon='icon-note' small right fill-dot)
                                                v-card.px-3.py-1(v-if="item.type === 'NOTE'" flat outlined :class='item.pinned == true ? "timeLine-note" : ""')
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.mr-3
                                                                | {{ item.timeConvert }} ・
                                                                | {{ item.owner }}
                                                        v-col.text-right(cols='1')
                                                            v-menu(bottom left)
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                        v-icon(size='16')  icon-dot-3
                                                                v-list.pa-0
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onPinNote(item)' text='text')
                                                                            v-icon.mr-1(v-if="item.pinned === true" size='10') fas fa-thumbtack
                                                                            span.ml-1 {{$t('GENERAL.PIN')}}
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onEditNote(item)' text='text')
                                                                            span.ml-1 {{$t('GENERAL.EDIT')}}
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onDeleteNote(item.id)' text='text')
                                                                            span.ml-1 {{$t('GENERAL.DELETE')}}
                                                        v-col(cols='12')
                                                            .timeLine-note-box(v-if='item.isMore_note')
                                                                vue-editor.can-select-text.v2e_no_border.v2e_post_max_height.ellipsis(ref='editor' v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                                //- p.mb-1  {{ showNote(item.content) }}
                                                            div(v-else)
                                                                vue-editor.can-select-text.v2e_no_border( v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                                //- p.mb-1  {{ item.content }}
                                                        v-col.d-flex.justify-end(cols='12' v-if='checkMore(item.content,300)')
                                                            v-btn.pa-0(v-hide-element='false' v-if='item.isMore_note' @click="isMore(index, 'NOTE')" color='primary' text) MORE
                                                            v-btn.pa-0(v-hide-element='false' v-else @click="isMore(index, 'NOTE')" color='primary' text) LESS
                                                //- v-card.timeLine-note.px-3.py-1(flat outlined)
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.mr-3
                                                                | {{ item.date }} ・
                                                                | {{ item.owner }}
                                                        v-col.text-right(cols='1')
                                                            v-menu(bottom left)
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                        v-icon(size='16')  icon-dot-3
                                                                v-list.pa-0
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(text='text')
                                                                            span.ml-1 編輯
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onDetele(item)' text='text')
                                                                            span.ml-1 刪除
                                                        v-col(cols='12')
                                                            p.mb-1  {{ item.content }}
                                // - mail -
                                v-tab-item.pa-3(key='mail' value='tab-4' :transition='false' :reverse-transition='false')
                                    emailEditor(:objectDetail='opptyDetails' :contactsDetail='opptyDetails.primary_contact' objectType='Opportunity' :contactEmail='""' :userEmail='userEmail' @emitSendEmailDialog='onEmitSendEmailDialog')
                                // - SMS -
                                v-tab-item.pa-3(key='sms' value='tab-5' :transition='false' :reverse-transition='false')
                                    smsEditor(:objectDetail='opptyDetails' :contactsDetail='opptyDetails.primary_contact' objectType='Opportunity' :contactSms='""' :userSms='userSms' @emitSendSmsDialog='onEmitSendSmsDialog')
                                // - News -
                                //- v-tab-item.pa-3(key='news' value='tab-6' :transition='false' :reverse-transition='false')
                                    newsDisplay()
                                v-tab-item.pa-3(key='cases' value='tab-6' :transition='false' :reverse-transition='false')
                                    casesTable(:cases='cases')
                                // - documents -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isdocumentTab")
                                    v-btn(@click='onDelDocument()' width='120' height='32' color='red' plain border-0 v-if="isDelDocument")
                                        v-icon.mr-1(size='14') icon-remove
                                        span {{$t('DOCUMENT.DEL')}}
                                    v-btn(@click='onAddDocument()' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1(size='14') icon-add
                                        span {{$t('DOCUMENT.ADD')}}
                                v-tab-item.pa-3(key='documents' value='tab-7' :transition='false' :reverse-transition='false')
                                    documentsTable(class_name='Deal' :target_id='target_id' @emitDocumentsList='selectDocumentsList' :isDel='openDelDocumentDialog' :addDocDialog ='isAddDoucment' @emitDocDialog='onEmitDocDialog' )
                                // - product -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isProductTab")
                                    v-btn(@click='onAddProduct()' v-if='tenant_type!="IT" && (opptyDetails.closed_reason == 0 || opptyDetails.closed_reason == null) && !isTechUser' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1(size='14') icon-add
                                        span {{$t('PRODUCTSETTING.ADD_PRODUCT')}}
                                v-tab-item.pa-3.scrollbar(style="overflow-y: hidden;" key='product' value='tab-2' :transition='false' :reverse-transition='false')
                                    industryProductLineItem(v-if='tenant_type=="IT"' :oppty='opptyDetails' ref='industryProductLineItem', @emit_fromProdcutLine='onEmit_fromProdcutLine')
                                    productLineItem(v-else :isAddProduct='isAddProduct' @emitAddProduct='onEmitAddProduct' @emit_editFromProdcutLine='onEmit_editFromProdcutLine' :oppty='opptyDetails' ref='productLines')
                                // - activity -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isActivityTab")
                                    .btn-switch.ml-3.mb-3
                                        v-btn(v-hide-element='false' :ripple='false' @click='activity_mode = "list"' width='30%' height='24' :color=" activity_mode == 'list'? 'primary':'white'"  depressed tile :dark='activity_mode != "list"')
                                            v-icon(size='14') icon-list
                                        v-btn(v-hide-element='false' :ripple='false' @click='activity_mode = "calender"' width='30%' height='24' :color=" activity_mode == 'calender' ? 'primary' : 'white' " depressed tile :dark='activity_mode != "calender"')
                                            v-icon.icon-calendar.mt-1(size='16') 
                                    v-btn(@click='onAddActivity()' v-if='opptyDetails.closed_reason == 0 || opptyDetails.closed_reason == null' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1.mb-1(size='16') icon-add
                                        span.mb-1 {{$t('ACTIVITY.ADD')}}
                                v-tab-item.pa-3(key='activity' value='tab-8' :transition='false' :reverse-transition='false')
                                    activitiesTable(:isAddActivity='isAddActivity' @emitAddActivity='onEmitAddActivity' :target_id='target_id' :mode='activity_mode' :target_name='opptyDetails.name' ,from_page='oppty' ,:activity_org='activity_org' ,:activity_contact='activity_contact')
                    v-col.pt-4.pt-md-0.pl-md-4.min-pad-scroll(cols='12' md='4')
                        v-card.contact-card(flat)
                            v-card-title.d-flex.justify-space-between.align-center.px-3.py-2
                                .d-flex.align-center.my-0
                                    .cube-icon.small.bg-primary.mr-2
                                        v-icon(size='21' color='white') mdi-account
                                    span {{$t('DEAL_DETAIL.OPPTY_CONTACT')}} 
                                        v-badge(v-if="opptyContactsAmount > 1" :content="opptyContactsAmount" float color="#007bff")
                                v-btn.pa-0(v-hide-element='false' v-if='!isTechUser' @click='onMainContacts(opptyContacts.contacts)' color='primary' text) MORE
                            v-card-text.py-0.mb-4
                                v-row.sheet-box.my-(v-if='opptyContactsAmount == 0')
                                    v-col.d-flex.align-center.justify-center.pa-0( cols='12' )
                                        v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                            h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_OPPTY_CONTACT')}}
                                div(v-else)
                                    v-list-item.px-0.py-2(v-for='(item,index) in opptyContacts.contacts' :key='item.name' v-if='index == 0')
                                        v-list-item-avatar.ma-0.mr-3(v-if='index == 0' color='blue-grey lighten-5' size='40')
                                            span.mx-auto(v-if="item.image === null") {{ item.last_name }}
                                            img(v-else :src='item.image' :alt='item.image')
                                        v-list-item-content(v-if='index == 0')
                                            v-list-item-title.d-flex.justify-space-between.align-Start
                                                v-row.w-100
                                                    v-col(cols='4').pl-0.ellipsis
                                                        a.f-text-highlight.text-subtitle-2.ellipsis.mb-0(:href='"/Contacts/"+item.id' v-if='item.first_name == null') {{ item.last_name }}
                                                        a.f-text-highlight.text-subtitle-2.ellipsis.mb-0(:href='"/Contacts/"+item.id' v-else) {{ item.last_name+" "+item.first_name }}
                                                    v-col(vols='8').ellipsis
                                                        .d-flex.justify-end.align-center
                                                            span.job-label.ml-3.d-block.ellipsis(v-if='!!item.organization_set[0]')  {{ item.organization_set[0].name }}
                                                            span.job-label.ml-3.d-block.ellipsis(v-if='!!item.title')  {{ item.title }}
                                            v-list-item-subtitle
                                                p.my-2.ellipsis(v-if='!!item.mobile_phone')
                                                    v-icon.mr-1(size='14' color='gary') icon-cell
                                                    a.t-black(href) {{ item.mobile_phone }}
                                                p.ma-0.ellipsis(v-if='!!item.email')
                                                    v-icon.mr-1(size='14' color='gary') icon-mail
                                                    a.t-black(href) {{ item.email }}
                        // - Time line -
                        v-card.contact-card.mb-6(flat).mt-3
                            v-card(flat)
                                v-card-title.d-flex.justify-space-between.align-center.px-3.py-2
                                    .d-flex.align-center.my-0
                                        .cube-icon.small.bg-pink.mr-2
                                            v-icon(size='21' color='white') mdi-clock
                                        span {{$t('CONTACT_DETAIL.TIMELINE')}}
                                v-card-text.py-0
                                    v-row.sheet-box.my-5(v-if='opptyTimeLines.length == 0')
                                        v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_TIMELINE')}}
                                    v-tabs.borderBtn-tabs(v-else height='32' background-color='white' :hide-slider='true' :centered='true' :show-arrows='true')
                                        v-tab(v-if="!loading" v-for='item in timeLineType' :key='item.key' :id='`Tlt-${item.key}`'  @click='onChangeTltSelected' :class='changeSelectedColor(item.key)')
                                            | {{ item.name }}
                                    div.pa-3.pt-5.scrollTimeLine(:style='timeline_max_height')
                                        v-timeline.pt-0(dense align-top)
                                            v-timeline-item(v-for='(item, index) in showTimelines' :key='index' :color='item.type == "DUE_ACTIVITY" ? "#FFD306" : "success"' :icon='getTimeLineIcon(item.type)' small right fill-dot v-if='item.show == true && item.show_index<timeline_count')
                                                //- // - Time Line Item - Meeting and Call  -
                                                //- v-card.px-3.py-1(v-if="item.type === 'call' || item.type === 'meeting'" flat outlined)
                                                //- 	v-row.justify-space-between.align-center(no-gutters)
                                                //- 		v-col(cols='12' md='5')
                                                //- 			v-card-subtitle.pa-0
                                                //- 				| {{ item.title }}
                                                //- 		v-col.f-smaller.t-lightgary.t-lightgary.detail(cols='12' md='7')
                                                //- 			span.mr-3
                                                //- 				| {{ item.date }} ・
                                                //- 				| {{ item.owner }}
                                                //- 			.d-flex.align-center.mr-3
                                                //- 				v-icon(size='17') icon-group
                                                //- 				span  {{ item.client }}
                                                //- 			.d-flex.align-center
                                                //- 				v-icon(size='15') icon-company
                                                //- 				span  {{ item.clientConpany }}
                                                //- 			v-menu(bottom left)
                                                //- 				template(v-slot:activator='{ on, attrs }')
                                                //- 					v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                //- 						v-icon(size='16')  icon-dot-3
                                                //- 				v-list.pa-0
                                                //- 					v-list-item.px-0.text-center
                                                //- 						v-btn(@click='onEditAactivity(item)' text='text')
                                                //- 							span.ml-1 編輯
                                                //- 					v-list-item.px-0.text-center
                                                //- 						v-btn(@click='onDelete()' text='text')
                                                //- 							span.ml-1 刪除
                                                // - Time Line Item - STAGE and AMOUNT and FIELDHISTORY -
                                                v-card.px-3.py-1(v-if="item.type === 'DUE_ACTIVITY'" flat outlined :class='"timeLine-note"')
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        //- v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                        //-     span.mr-3
                                                        //-         | {{ item.timeConvert }} ・
                                                        //-         | {{ item.changed_by }}
                                                        v-col(cols='12')
                                                            p(v-if="item.is_due < 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{(item.is_due)*-1}}{{$t("DEAL_DETAIL.SOON_DUE_1")}}
                                                            p(v-else-if="item.is_due == 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{$t("DEAL_DETAIL.SOON_DUE_4")}}
                                                            p(v-else).mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE_2")}}{{item.is_due}}{{$t("DEAL_DETAIL.SOON_DUE_3")}}
                                                // - Time Line Item - STAGE and AMOUNT and FIELDHISTORY -
                                                v-card.px-3.py-1(v-if="item.type === 'STAGE'|| item.type === 'AMOUNT' || item.type === 'FIELDHISTORY' || item.type === 'ACTIVITY_FIELDHISTORY'" flat outlined)
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.mr-3
                                                                | {{ item.timeConvert }} ・
                                                                | {{ item.changed_by }}
                                                        v-col(cols='12')
                                                            p.mb-1.t-black  {{ item.content }}
                                                // Time Line Item - NOTE
                                                v-card.px-3.py-1(v-if="item.type === 'NOTE'" flat outlined :class='item.pinned == true ? "timeLine-note" : ""')
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.mr-3
                                                                | {{ item.timeConvert }} ・
                                                                | {{ item.owner }}
                                                        v-col.text-right(cols='1')
                                                            v-menu(bottom left)
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                        v-icon(size='16')  icon-dot-3
                                                                v-list.pa-0
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onPinNote(item)' text='text')
                                                                            v-icon.mr-1(v-if="item.pinned === true" size='10') fas fa-thumbtack
                                                                            span.ml-1 {{$t('GENERAL.PIN')}}
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onEditNote(item)' text='text')
                                                                            span.ml-1 {{$t('GENERAL.EDIT')}}
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onDeleteNote(item.id)' text='text')
                                                                            span.ml-1 {{$t('GENERAL.DELETE')}}
                                                        v-col(cols='12')
                                                            .timeLine-note-box(v-if='item.isMore_timeLineAll')
                                                                vue-editor.can-select-text.v2e_no_border.v2e_post_max_height.ellipsis(ref='editor' v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                                //- p.mb-1  {{ showNote(item.content) }}
                                                            div(v-else)
                                                                vue-editor.can-select-text.v2e_no_border( v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                                //- p.mb-1  {{ item.content }}
                                                        v-col.d-flex.justify-end(cols='12' v-if='checkMore(item.content,300)')
                                                            v-btn.pa-0(v-hide-element='false' v-if='item.isMore_timeLineAll' @click="isMore(index, 'timeLineAll')" color='primary' text) MORE
                                                            v-btn.pa-0(v-hide-element='false' v-else @click="isMore(index, 'timeLineAll')" color='primary' text) LESS
                                                // - Time Line Item - EMAIL  -
                                                v-card.px-3.py-1(v-if="item.type === 'EMAIL'" flat outlined)
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='11')
                                                            span.mr-3
                                                                | {{ item.timeConvert }}
                                                            div.mr-2
                                                                v-icon.mr-1(size='16') mdi-eye
                                                                span.ma-0 
                                                                    | {{ item.opentime }}
                                                            div
                                                                v-icon.mr-1(size='16') mdi-link-variant 
                                                                span.ma-0 
                                                                    | {{ item.linkclicktime }}
                                                        v-col.mb-1.f-smaller.t-lightgary.text-right(cols='1')
                                                            div
                                                                span
                                                                    | {{ item.direction==true ?  $t('GENERAL.OUTGOING'):$t('GENERAL.INCOMING')}}
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.email-sapn
                                                                | {{$t('GENERAL.SENDER')}} :
                                                            a.mb-0( :href='onMail(item.sender_data.email)' :title='item.sender_data.sender_name' target='_blank') {{ item.sender_data.sender_name }} 
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.email-sapn
                                                                | {{$t('GENERAL.TO')}} :
                                                            div.auto-div
                                                                div(v-for='item,index in item.to_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                                                    a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                                                    a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.email-sapn
                                                                | {{$t('GENERAL.CC')}} :
                                                            div.auto-div
                                                                div(v-for='item,index in item.cc_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                                                    a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                                                    a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                                        //- v-col.text-right(cols='2')
                                                            v-menu(bottom left)
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    v-btn(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                        v-icon(size='16')  icon-dot-3
                                                                v-list.pa-0
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(text='text')
                                                                            span 回信
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            v-card-subtitle.pa-0.t-black
                                                                | {{ item.email_subject }}
                                                        v-col.d-none(cols='12 mt-2')
                                                            p.mb-1(v-html='item.email_body')
                                                    v-row.justify-end.align-center.mt-3(no-gutters)
                                                        v-col.text-right(cols='12')
                                                            v-btn.mr-2(v-hide-element='false' @click='showEmailDetail($event)' color='primary' text) {{$t('GENERAL.SHOW_CONTENT')}}
                                                            v-btn.mr-2(v-hide-element='false' v-if='item.attachments.length > 0' icon @click='onEmailAttachment(item.attachments)')
                                                                v-icon(size='20') icon-attach
                                                            //- v-btn.mr-2(icon @click='onEmailOppties(item.opportunities)')
                                                                v-icon(size='20') icon-chatmoney-border
                                                            v-btn(v-hide-element='false' icon @click='onEmailContact(item.contacts)')
                                                                v-icon(size='20') icon-contact-border
                                                // - Time Line Item - SMS  -
                                                v-card.px-3.py-1(v-if="item.type === 'SMS'" flat outlined)
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span
                                                                | {{ item.timeConvert }} 
                                                        v-col(cols='12')
                                                            v-textarea.no-border.textarea-style.mb-2(v-model='item.sms_body' rows=2 readonly=true style='font-size: 14.4px;')
                                                            //- v-textarea.no-border.no-pointer-event.hide-scrollbar(v-if='item.isMore_timeLineAll' v-model='item.sms_body' rows=3 no-resize=true style='font-size: 14.4px;')
                                                            //- v-textarea.no-border.no-pointer-event(v-else v-model='item.sms_body' auto-grow no-resize=true style='font-size: 14.4px;')
                                                        v-col.d-flex.justify-end(cols='12')
                                                            //- v-btn.pa-0(v-if='item.sms_body.length > 300 && item.isMore_timeLineAll' @click='isMore(index, "timeLineAll")' color='primary' text) MORE
                                                            //- v-btn.pa-0(v-if='item.sms_body.length > 300 && !item.isMore_timeLineAll' @click="isMore(index, 'timeLineAll')" color='primary' text) LESS
                                                            v-btn(v-hide-element='false' icon @click='onEmailContact(item.contacts)')
                                                                v-icon(size='20') icon-contact-border
                                        v-divider.pa-0.ma-0(id='timeline_btm' style='border-color:#00000000;')
                                    //- // - Time Line - AMOUNT -
                                    //- v-tab-item.pa-3.pt-5.scrollDealTimeLine(key='AMOUNT' value='tab-2' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(opptyTimeLines, "AMOUNT")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_AMOUNT_CHANGE')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in opptyTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'AMOUNT'" :key='index' color='success' :icon='getTimeLineIcon(item.type)' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'AMOUNT' " flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }} ・
                                    //-                                 | {{ item.changed_by }}
                                    //-                         v-col(cols='12')
                                    //-                             p.mb-1.t-black  {{ item.content }}
                                    //- // - Time Line - STAGE -
                                    //- v-tab-item.pa-3.pt-5.scrollDealTimeLine(key='STAGE' value='tab-3' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(opptyTimeLines, "STAGE")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_STAGE_CHANGE')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in opptyTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'STAGE'" :key='index' color='success' icon='mdi-progress-upload' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'STAGE' " flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }} ・
                                    //-                                 | {{ item.changed_by }}
                                    //-                         v-col(cols='12')
                                    //-                             p.mb-1.t-black  {{ item.content }}
                                    //- // - Time Line - FIELDHISTORY -
                                    //- v-tab-item.pa-3.pt-5.scrollDealTimeLine(key='FIELDHISTORY' value='tab-4' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(opptyTimeLines, "FIELDHISTORY")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_FIELD_CHANGE')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in opptyTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'FIELDHISTORY'" :key='index' color='success' :icon='getTimeLineIcon(item.type)' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'FIELDHISTORY' " flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }} ・
                                    //-                                 | {{ item.changed_by }}
                                    //-                         v-col(cols='12')
                                    //-                             p.mb-1.t-black  {{ item.content }}
                                    //- // - Time Line - EMAIL -
                                    //- v-tab-item.pa-3.pt-5.scrollDealTimeLine(key='EMAIL' value='tab-5' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(opptyTimeLines, "EMAIL")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_MAIL')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in opptyTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'EMAIL'" :key='index' color='success' icon='icon-mail' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'EMAIL'" flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='11')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }}
                                    //-                             div.mr-2
                                    //-                                 v-icon.mr-1(size='16') mdi-eye
                                    //-                                 span.ma-0 
                                    //-                                     | {{ item.opentime }}
                                    //-                             div
                                    //-                                 v-icon.mr-1(size='16') mdi-link-variant 
                                    //-                                 span.ma-0 
                                    //-                                     | {{ item.linkclicktime }}
                                    //-                         v-col.mb-1.f-smaller.t-lightgary.text-right(cols='1')
                                    //-                             div
                                    //-                                 span
                                    //-                                     | {{ item.direction==true ?  $t('GENERAL.OUTGOING'):$t('GENERAL.INCOMING')}}
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.email-sapn
                                    //-                                 | {{$t('GENERAL.SENDER')}} :
                                    //-                             a.mb-0( :href='onMail(item.sender_data.email)' :title='item.sender_data.sender_name' target='_blank') {{ item.sender_data.sender_name }} 
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.email-sapn
                                    //-                                 | {{$t('GENERAL.TO')}} :
                                    //-                             div.auto-div
                                    //-                                 div(v-for='item,index in item.to_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                    //-                                     a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                    //-                                     a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.email-sapn
                                    //-                                 | {{$t('GENERAL.CC')}} :
                                    //-                             div.auto-div
                                    //-                                 div(v-for='item,index in item.cc_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                    //-                                     a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                    //-                                     a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                    //-                         //- v-col.text-right(cols='2')
                                    //-                             v-menu(bottom left)
                                    //-                                 template(v-slot:activator='{ on, attrs }')
                                    //-                                     v-btn(color='primary' dark v-bind='attrs' v-on='on' icon)
                                    //-                                         v-icon(size='16')  icon-dot-3
                                    //-                                 v-list.pa-0
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(text='text')
                                    //-                                             span 回信
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             v-card-subtitle.pa-0.t-black
                                    //-                                 | {{ item.email_subject }}
                                    //-                         v-col.d-none(cols='12 mt-2')
                                    //-                             p.mb-1(v-html='item.email_body')
                                    //-                     v-row.justify-end.align-center.mt-3(no-gutters)
                                    //-                         v-col.text-right(cols='12')
                                    //-                             v-btn.mr-2(@click='showEmailDetail($event)' color='primary' text) {{$t('GENERAL.SHOW_CONTENT')}}
                                    //-                             v-btn.mr-2(v-if='item.attachments.length > 0' icon @click='onEmailAttachment(item.attachments)')
                                    //-                                 v-icon(size='20') icon-attach
                                    //-                             //- v-btn.mr-2(icon @click='onEmailOppties(item.opportunities)')
                                    //-                                 v-icon(size='20') icon-chatmoney-border
                                    //-                             v-btn(icon @click='onEmailContact(item.contacts)')
                                    //-                                 v-icon(size='20') icon-contact-border
                                    //- // - Time Line - SMS -
                                    //- v-tab-item.pa-0.pt-4.scrollDealTimeLine(key='SMS' value='tab-6' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(opptyTimeLines, "SMS")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_SMS')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in opptyTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'SMS'" :key='index' color='success' :icon='getTimeLineIcon(item.type)' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'SMS'" flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }}                                                      
                                    //-                         //- v-col.text-right(cols='2')
                                    //-                             v-menu(bottom left)
                                    //-                                 template(v-slot:activator='{ on, attrs }')
                                    //-                                     v-btn(color='primary' dark v-bind='attrs' v-on='on' icon)
                                    //-                                         v-icon(size='16')  icon-dot-3
                                    //-                                 v-list.pa-0
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(@click='' text='text')
                                    //-                                             span 釘選
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(text='text')
                                    //-                                             span 回信
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(@click='onDetele(item)' text='text')
                                    //-                                             span 刪除
                                    //-                         v-col(cols='12 mt-2')
                                    //-                             v-textarea.no-border.textarea-style.mb-2(v-model='item.sms_body' rows=2 readonly=true style='font-size: 14.4px;')
                                    //-                             //- v-textarea.no-border.no-pointer-event.hide-scrollbar(v-if='item.isMore_timeLineSms' v-model='item.sms_body' rows=3 no-resize=true style='font-size: 14.4px;')
                                    //-                             //- v-textarea.no-border.no-pointer-event(v-else v-model='item.sms_body' auto-grow no-resize=true style='font-size: 14.4px;')
                                    //-                     v-row.justify-end.align-center.mt-3(no-gutters)
                                    //-                         v-col.text-right(cols='12')
                                    //-                             //- v-btn.pa-0(v-if='item.sms_body.length > 300 && item.isMore_timeLineSms' @click='isMore(index, "timeLineNote")' color='primary' text) MORE
                                    //-                             //- v-btn.pa-0(v-if='item.sms_body.length > 300 && !item.isMore_timeLineSms' @click="isMore(index, 'timeLineNote')" color='primary' text) LESS
                                    //-                             v-btn(icon @click='onEmailContact(item.contacts)')
                                    //-                                 v-icon(size='20') icon-contact-border
                                    //- // - Time Line - NOTE -
                                    //- v-tab-item.pa-3.pt-5.scrollDealTimeLine(key='NOTE' value='tab-7' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(opptyTimeLines, "NOTE")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_NOTE')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in opptyTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'NOTE'" :key='index' color='success' icon='icon-note' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'NOTE'" flat outlined :class='item.pinned == true ? "timeLine-note" : ""')
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }} ・
                                    //-                                 | {{ item.owner }}
                                    //-                         v-col.text-right(cols='1')
                                    //-                             v-menu(bottom left)
                                    //-                                 template(v-slot:activator='{ on, attrs }')
                                    //-                                     v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                    //-                                         v-icon(size='16')  icon-dot-3
                                    //-                                 v-list.pa-0
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(@click='onPinNote(item)' text='text')
                                    //-                                             v-icon.mr-1(v-if="item.pinned === true" size='10') fas fa-thumbtack
                                    //-                                             span {{$t('GENERAL.PIN')}}
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(@click='onEditNote(item)' text='text')
                                    //-                                             span.ml-1 {{$t('GENERAL.EDIT')}}
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(@click='onDeleteNote(item.id)' text='text')
                                    //-                                             span.ml-1 {{$t('GENERAL.DELETE')}}
                                    //-                         v-col(cols='12')
                                    //-                             .timeLine-note-box(v-if='item.isMore_timeLineNote')
                                    //-                                 p.mb-1  {{ showNote(item.content) }}
                                    //-                             div(v-else)
                                    //-                                 p.mb-1  {{ item.content }}
                                    //-                         v-col.d-flex.justify-end(cols='12' v-if='item.content.length > 300')
                                    //-                             v-btn.pa-0(v-if='item.isMore_timeLineNote' @click="isMore(index, 'timeLineNote')" color='primary' text) MORE
                                    //-                             v-btn.pa-0(v-else @click="isMore(index, 'timeLineNote')" color='primary' text) LESS
                                    //- // - Time Line activity
                                    //- v-tab-item.pa-3.pt-5.scrollDealTimeLine(key='ACTIVITY' value='tab-8' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(activityTimeline, "ACTIVITY_FIELDHISTORY")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_ACTIVITY_CHANGE')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in opptyTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'DUE_ACTIVITY'" :key='item.id' color='#FFDC35' icon='mdi-bell-ring' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'DUE_ACTIVITY'" flat outlined class="timeLine-note")
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col(cols='12')
                                    //-                             p(v-if="item.is_due < 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{(item.is_due)*-1}}{{$t("DEAL_DETAIL.SOON_DUE_1")}}
                                    //-                             p(v-else-if="item.is_due == 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{$t("DEAL_DETAIL.SOON_DUE_4")}}
                                    //-                             p(v-else).mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE_2")}}{{item.is_due}}{{$t("DEAL_DETAIL.SOON_DUE_3")}}
                                    //-         template(v-for='(item, index) in activityTimeline')
                                    //-             v-timeline-item(v-if="item.type === 'ACTIVITY_FIELDHISTORY'" :key='index' color='success' icon='icon-calendar' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'ACTIVITY_FIELDHISTORY' " flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }} ・
                                    //-                                 | {{ item.changed_by }}
                                    //-                         v-col(cols='12')
                                    //-                             p.mb-1.t-black  {{ item.content }}
                                    //- // - Time Line - file -
                                    //- //- v-tab-item.pa-3.pt-5(key='file' value='tab-6' :transition='false' :reverse-transition='false')
                                    //-     v-timeline.pt-0(dense align-top)
                                    //-         template(v-for='(item, index) in timeLine')
                                    //-             v-timeline-item(v-if="item.type === 'file'" :key='index' color='success' :icon='item.icon' small right fill-dot)
                                    //-                 v-card.px-3.py-1(flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col(cols='12' md='6')
                                    //-                             v-card-subtitle.pa-0
                                    //-                                 | {{ }}
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.detail.d-flex.align-center(cols='12' md='6')
                                    //-                             span  {{ }} ・ {{ }}
                                    //-                             v-menu(bottom left)
                                    //-                                 template(v-slot:activator='{ on, attrs }')
                                    //-                                     v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                    //-                                         v-icon(size='16')  icon-dot-3
                                    //-                                 v-list.pa-0
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(text='text')
                                    //-                                             span.ml-1 下載
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(@click='onDetele(item)' text='text')
                                    //-                                             span.ml-1 刪除                      
        v-dialog(v-model='editDealDialog' max-width='500')
            edit-deal(@emitEditDealDialog='onEmitEditDealDialog')
        v-dialog(v-model='changeOwnDialog' max-width='500' v-if='changeOwnDialog')
            change-own(@emitChangeOwnDialog='onEmitChangeOwnDialog' :owner-data='changeOwnData' :other-data='otherData' change='opportunities')
        v-dialog(v-model='changeProcessDialog' max-width='500' v-if='changeProcessDialog')
            change-process(@emitChangeProcessDialog='onEmitChangeProcessDialog' @emitChangeProcessData='onEmitChangeProcessData' :process-data='processData')
        v-dialog(v-model='relatedContactsDialog' width='1050' content-class='contact-dialog' v-if='relatedContactsDialog')
            related-contacts(@emitRelatedContactsDialog='onEmitRelatedContactsDialog' @MainContact='onEmitMainContactMessage($event)' objectType='Opportunity' :contact-data='mainContactData')
        v-dialog(v-model='activityDialog' width='470' data-app='true')
            activity-dialog(@emitActivityDialog='onEmitActivityDialog')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='addNoteDialog' max-width='500' v-if='addNoteDialog')
            add-note(@emitAddNoteDialog='onEmitAddNoteDialog' @emitAddNoteData='onEmitAddNoteData' :note-data='noteData')
        v-dialog(v-model='editNoteDialog' max-width='500' v-if='editNoteDialog')
            edit-note(@emitEditNoteDialog='onEmitEditNoteDialog' :note-data='noteData')
        v-dialog(v-model='emailContactsDialog' max-width='300' v-if='emailContactsDialog' scrollable)
            email-contacts-dialog(@emitEmailContactsDialog='onEmitEmailContactsDialog' :email-contact-data='emailContactData')
        v-dialog(v-model='emailOpptiesDialog' max-width='300' v-if='emailOpptiesDialog' scrollable)
            email-oppties-dialog(@emitEmailOpptiesDialog='onEmitEmailOpptiesDialog' :email-oppties-data='emailOpptiesData')
        v-dialog(v-model='emailAttachmentsDialog' max-width='300' v-if='emailAttachmentsDialog' scrollable)
            email-attachments-dialog(@emitEmailAttachmentsDialog='onEmitEmailAttachmentsDialog' :email-attachment-data='emailAttachmentData')
        v-dialog(v-model='addDealDialog' max-width='600' v-if='addDealDialog')
            add-deal(@emitAddDealDialog='onEmitAddDealDialog' :add-deal='addDealData')
        v-dialog(v-model='redirectDialog' width='360' content-class='statusDialog')
            redirect-dialog(@emitRedirectDialog='onEmitRedirectDialog' :message='message' :copyDataType='"deal"' :copyDataId='copyDataId')
        v-dialog(v-model='addCaseDialog' max-width='600' v-if='addCaseDialog')
            add-case(@emitAddCaseDialog='onEmitAddCaseDialog' @emitNewCaseData='onEmitNewCaseData' :add-case='addCaseData')
</template>

<script>
    // dialog
    import editDeal from '@/components/Dialog/Deal/editDeal';
    import changeOwn from '@/components/Dialog/changeOwn';
    import changeProcess from '@/components/Dialog/Deal/changeProcess';
    import addNote from '@/components/Dialog/addNote';
    import editNote from '@/components/Dialog/editNote';
    import newsDisplay from '@/components/Common/newsDisplay';
    import emailContactsDialog from '@/components/Dialog/emailContactsDialog';
    import emailOpptiesDialog from '@/components/Dialog/emailOpptiesDialog';
    import emailAttachmentsDialog from '@/components/Dialog/emailAttachmentsDialog';
    import activityDialog from '@/components/Dialog/activityDialog';
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import addDeal from '@/components/Dialog/Deal/addDeal';
    import redirectDialog from '@/components/Dialog/redirectDialog';
    import addCase from '@/components/Dialog/Case/addCase';
    import documentsTable from "@/components/Common/documentsTable";
    import productLineItem from "@/components/Common/productLineItem";
    import industryProductLineItem from "@/components/Common/industryProductLineItem";
    
    // array
    import get_oppty_closed_reason_func from "@/array/the_closeReasonList";
    import forecast_category_List from "@/array/the_forecastCategoryList";
    import opptySelectFieldsList from "@/array/the_opptySelectFieldsList";
    // other
    import activitiesTable from "@/components/Common/activitiesTable";
    import casesTable from "@/components/Common/casesTable";
    import customField from "@/components/Common/customField";
    import relatedContacts from '@/components/Dialog/Contact/relatedContacts';
    import emailEditor from '@/components/Common/emailEditor';
    import smsEditor from '@/components/Common/smsEditor';
    import { VueEditor } from "vue2-editor";
    import passData from "@/services/passData.js";
    // service
    import OpportunityDataService from '../services/OpportunityDataService';
    import OrganizationDataService from '../services/OrganizationDataService';
    import ContactDataService from '../services/ContactDataService';
    import NoteDataService from "../services/NoteDataService";
    import CaseDataService from "@/services/CaseDataService";
    import CampaignDataService from "@/services/CampaignDataService";
    import O365Service from "@/services/O365Service";
    import ActivityDataService from '../services/ActivityDataService';
    import PersonalSettingService from '../services/PersonalSettingService';
    import i18n from '/common/plugins/vue-i18n.js';
    import followBtn from "@/components/Common/followBtn";
    import PreferenceService from "@/services/PreferenceService";
    import wiadvanceProgressCheck from "@/views-wiadvance/wiadvanceProgressCheck";

    export default {
        name: 'Deals',
        components: {
            addCase,
            redirectDialog,
            editDeal,
            changeOwn,
            changeProcess,
            addNote,
            editNote,
            emailEditor,
            smsEditor,
            newsDisplay,
            relatedContacts,
            activityDialog,
            messageDialog,
            successDialog,
            errorDialog,
            emailContactsDialog,
            emailOpptiesDialog,
            emailAttachmentsDialog,
            addDeal,
            customField,
            casesTable,
            documentsTable,
            productLineItem,
            activitiesTable,
            followBtn,
            VueEditor,
            industryProductLineItem,
        },
        data() {
            return {		
            opptyStatusList:opptySelectFieldsList['opptyStatusList'],
            opptyIndustryList:opptySelectFieldsList['opptyIndustryList'],
            customerSourceList:opptySelectFieldsList['customerSourceList'],
            opptyProgressList:opptySelectFieldsList['opptyProgressList'],
            opptyTypeList:opptySelectFieldsList['opptyTypeList'],
            // vue editor
            editorSettings_no_tool: {
                modules: {
                    imageResize: false,
                    toolbar:false
                    },
            },
            redirectDialog: false,
            copyDataId:null,
            ownerEmail:'',	
            customFieldData:[], 
            closeReasonList: [],
            customStagecolor:'customcolor',
            loading: true,
            processStep: null,
            isProcessWon: false,
            isProcessLost: false,
            isProcessComplete: false,
            isProcessEdit: true,
            editable: true,
            stepContent: true,
            userEmail: '',
            userSms: '',
            mainContactData: {},
            tab: null,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            prevIcon: false,
            nextIcon: false,
            created_on: null, 
            customer_code:null,
            normalTabs: [{
                    id: 1,
                    key: 'detail',
                    name: i18n.t('DEAL_DETAIL.DETAIL')
                },
                {
                    id: 2,
                    key: 'product',
                    name: i18n.t('GENERAL.PRODUCT')
                },
                {
                    id: 3,
                    key: 'note',
                    name: i18n.t('DEAL_DETAIL.NOTE')
                },
                {
                    id: 4,
                    key: 'mail',
                    name: i18n.t('DEAL_DETAIL.EMAIL')
                },
                {
                    id: 5,
                    key: 'sms',
                    name: i18n.t('DEAL_DETAIL.SMS')
                },
                // {
                //     id: 6,
                //     key: 'cases',
                //     name: i18n.t('DEAL_DETAIL.ALL_CASES'),
                // },
                {
                    id: 7,
                    key: 'documents',
                    name: i18n.t('DOCUMENT.TITLE')
                },
                {
                    id: 8,
                    key: 'activities',
                    name: i18n.t('Bower_Title.Activity')
                },
            ],
            techUserTabs: [
                {
                    id: 1,
                    key: 'detail',
                    name: i18n.t('DEAL_DETAIL.DETAIL')
                },
                {
                    id: 2,
                    key: 'product',
                    name: i18n.t('GENERAL.PRODUCT')
                },
            ],
            tabs:[],
            timeLineType: [{
                    id: 1,
                    key: 'all',
                    name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_ALL')
                },
                {
                    id: 2,
                    key: 'moneyChange',
                    name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_AMOUNT')
                },
                {
                    id: 3,
                    key: 'stepChange',
                    name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_STAGE')
                },
                {
                    id: 4,
                    key: 'fieldHistory',
                    name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_FIELD')
                },
                {
                    id: 5,
                    key: 'mail',
                    name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_MAIL')
                },
                {
                    id: 6,
                    key: 'sms',
                    name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_SMS')
                },
                {
                    id: 7,
                    key: 'note',
                    name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_NOTE')
                },
                {
                    id: 8,
                    key: 'activity',
                    name: i18n.t('Bower_Title.Activity')
                }
            ],
            timeline_tab: ['all'],
            timelinetabData: {},
            breadcrumbs: [],
            emailOpptiesData: null,
            emailContactData: null,
            emailAttachmentData: null,
            campaignList:[],
            opptyDetails: {owner:{email:''}},
            opptyContacts: {},
            opptyContactsAmount:0,
            opptyTimeLines: [],
            showTimelines:[],
            opptySummary: {},
            noteData: {},
            pinning: true,
            valid: true,
            validForm: {
                basic: true,
                detail: true,
            },
            // edit Keyword
            isReadonlyKeyword: true,
            showEditKeywordBtn: true,
            showEditKeywordCheckBtn: false,
            keywordOriginalData: {},
            // 詳細資訊 edit
            loading_dealDeatil: false,
            originOpptyDetails: {},
            showDetailEditBtn: true,
            isDetailReadonly: true,
            forecast_category_List: forecast_category_List,
            isNew: {
                primary_contact: false,
                oppty_org: false,
                oppty_contact: false,
            },
            newPri:{
                fName: null,
                lName: null,
            },
            newOppCon:{
                fName: null,
                lName: null,
            },
            isLoading: {
                primary_contact: false,
                oppty_org: false,
                oppty_contact: false,
            },
            comboInput:{
                primary_contact: null,
                oppty_org: null,
                oppty_contact: null,
            },
            search_primary_contact: null,
            search_oppty_org: null,
            search_oppty_contact: null,
            menu: false,
            haveTip: false,
            pContact_lastName: null,
            pContact_firstName: null,
            contactData: [],
            organizationList: [],
            formRule: {
                requireRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                ],
                contactnNameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME_2'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                ],
                opptyContactRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'),
                ],
                nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_NAME'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                    ],
                contactRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                customerBudgetRule: [
                    (v) => /^\d*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
                    (v) => /^.{0,17}$/.test(v) || i18n.t('RULE.RULE_17'),
                ],
                amountRule: [
                    (v) => /^\d{1,17}(\.\d{0,2})?$/.test(v) || i18n.t('RULE.RULE_R_NUM_2')
                ],
                textRule: [(v) => (v || '').length <=90 || i18n.t('RULE.RULE_90')],	
                contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                contentLenRule100: [(v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100')],
                companyRule: [
                    (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
                    (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                    (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
                ],
            },
            // Dialog
            addCaseDialog: false,
            editDealDialog: false,
            changeOwnDialog: false,
            addNoteDialog: false,
            editNoteDialog: false,
            relatedContactsDialog: false,
            addContactDialog: false,
            copyDealDialog: false,
            activityDialog: false,
            messageDialog: false,
            message: '',
            successDialog: false,
            errorDialog: false,
            errorMessage: '',
            emailOpptiesDialog: false,
            emailContactsDialog: false,
            emailAttachmentsDialog: false,
            insertDealVal: '',
            contactTemplateVal: '',
            msgDialogSituation: '',
            StageTypeId:null,
            // Tab
            isNoteTab: false,
            changeOwnData: {},
            otherData: {},
            succMode: '',
            keywordedit: false,
            addDealDialog: false,
            alertNote: [],
            getfolder: true,
            truncateTextValue: null,
            closeReasonName: null,
            allProcess: [],
            ownerData: {},
            usersettingData: {},
            allStage: 0,
            currentStep: 0,
            score:0,
            isPhoto: false,
            cFEditable: true,
            formatAmount: null,     
            amountShow: false,  
            formatBudget: null,
            budgetShow: false,  
            amountValidate: true,
            budgetValidate: true,
            changeProcessDialog: false,
            processData: {},
            changeProcessData: {},
            isFirst: true,
            isLast: true,
            opptyState:'',
            addCaseData: {},
            primary_contact_data:{},
            cases: [
                {
                    subject: '1投訴',
                    case_number: 'A778885',
                    stage_light_color: {
                        color: "#79FF79",
                        tip: "The opportunity progress is on track, but approaching the normal duration needed."
                    }, 
                    status: 'pending',
                    priority: 'Very High',
                    agent: '呂先生',
                    owner: {
                        last_name: 'mccormick',
                        usersetting: {
                            photo: null
                        }
                    },
                    current_stage: {
                        stage_color: '#f05975',
                    }
                },
                {
                    subject: '2這是客訴',
                    case_number: 'V69802',
                    stage_light_color: {
                        color: '#fe8200',
                        tip: "The opportunity progress is on track, but approaching the normal duration needed."
                    }, 
                    status: 'pending',
                    priority: 'Medium',
                    agent: '呂先生',
                    owner: {
                        last_name: 'mccormick',
                        usersetting: {
                            photo: null
                        }
                    },
                    current_stage: {
                        stage_color: '#f05975',
                    }
                },
                {
                    subject: '3 ?!客訴',
                    case_number: 'V69802',
                    stage_light_color: {
                        color: '#fe8200',
                        tip: "The opportunity progress is on track, but approaching the normal duration needed."
                    }, 
                    status: 'pending',
                    priority: 'Medium',
                    agent: '呂先生',
                    owner: {
                        last_name: 'mccormick',
                        usersetting: {
                            photo: null
                        }
                    },
                    current_stage: {
                        stage_color: '#f05975',
                    }
                },
                {
                    subject: '4 還是客訴',
                    case_number: 'V69802',
                    stage_light_color: {
                        color: '#fe8200',
                        tip: "The opportunity progress is on track, but approaching the normal duration needed."
                    }, 
                    status: 'pending',
                    priority: 'Medium',
                    agent: '呂先生',
                    owner: {
                        last_name: 'mccormick',
                        usersetting: {
                            photo: null
                        }
                    },
                    current_stage: {
                        stage_color: '#f05975',
                    }
                },
            ],
            target_id: this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id'),
            //document
            isdocumentTab:false,
            isDelDocument:false,
            openDelDocumentDialog:false,
            isAddDoucment:false,
            isAddProduct: false,
            // product
            isProductTab:false,
            // activity
            activity_mode:"list",
            isActivityTab:false,
            isAddActivity:false,
            activityTimeline:[],
            originopptyTimeLines:[],
            due_activity_list:[],
            user_list:[],
            activity_org:null,
            activity_contact:null,
            isFollow:false,
            timeline_count:0,
            timeline_show_count:0,
            timeline_max_height:'max-height:50vh;',
            tenant_type:'default',
            isTechUser:false,
            is_opptyOrg: true,
            is_staff: JSON.parse(window.localStorage.getItem('is_staff')),
        }
    },
    async created(){
        this.checkTechUser();
        this.getOpptyClosedReason();
        await PreferenceService.getINDUSTRY().then((response)=>{
            if(response.data){
                this.tenant_type = response.data;
            }
        });
        await (this.uuidCheck(this.target_id))
        this.getLoginUserEmail();
        if(this.isTechUser){
            await OpportunityDataService.getOpptyDetailForTech(this.target_id)
            .then(response => {
                this.opptyDetails = response.data;
                this.isFollow = this.opptyDetails.follow;
                const title = localStorage.getItem('title');
                document.title = title+' : '+this.opptyDetails.name +' – UpDay';
                this.StageTypeId = this.opptyDetails.stage_type.id
                this.allProcess = response.data.summary;
                this.allStage = this.allProcess.length;
                this.score = this.opptyDetails.stage_score.score
                this.changeOwnData = this.opptyDetails.owner;
                this.check = [this.opptyDetails.amount,this.opptyDetails.name,this.opptyDetails.forecast_category,this.opptyDetails.expected_close_date];
                this.closeReasonName = this.getCloseReasonName();
                this.opptyContacts.contacts = response.data.contacts;
                this.opptyContacts.contacts = this.sortContactsJson(this.opptyContacts.contacts,"first_name")
                this.opptyContactsAmount = this.opptyContacts.contacts.length
                this.formatAmount = this.getFormatAmount(this.opptyDetails.amount)
                this.created_on = new Date(this.opptyDetails.created_on).toLocaleString();
                this.customer_code = this.opptyDetails.oppty_org ? this.opptyDetails.oppty_org.customer_code : null;
                if(this.opptyDetails.customer_budget != null){
                    this.formatBudget = this.getFormatAmount(this.opptyDetails.customer_budget).slice(0,-3);
                    this.opptyDetails.customer_budget = this.opptyDetails.customer_budget.slice(0,-3);
                }
                if(response.data.closed_reason >0 )
                {
                    this.cFEditable = false				
                }
                this.breadcrumbs = [{
                        text: i18n.t('BREADCRUMBS.Deals'),
                        disabled: false,
                        href: '/Deals',
                    },
                    {
                        text: this.opptyDetails.name,
                        disabled: true,
                        href: 'Deal',
                    },
                ]

                if(this.opptyDetails.custom_field != null){
                    this.customFieldData =  this.opptyDetails.custom_field;
                }

                switch(true){
                    case (this.opptyDetails.closed_reason == 1) :
                        this.stepContent = true;
                        this.isProcessEdit = false;
                        this.isProcessComplete = true;
                        this.isProcessWon = true;					
                        this.customStagecolor = 'woncolor'	
                        break;				
                    case (this.opptyDetails.closed_reason > 1):
                        this.stepContent = false;
                        this.isProcessEdit = false;
                        this.isProcessComplete = true;
                        this.isProcessLost = true;
                        this.stepContent= true;
                        break;
                    default:
                        break;
                }
                
                const tip_acc_history = this.opptyDetails.stage_score.tip.account_history != ""
                const tip_ammount = this.opptyDetails.stage_score.tip.amount != ""
                const tip_close_date = this.opptyDetails.stage_score.tip.expected_closing_date 
                const tip_forecast = this.opptyDetails.stage_score.tip.forecast != ""
                const tip_stage = this.opptyDetails.stage_score.tip.stage != ""

                if(tip_acc_history || tip_ammount || tip_close_date || tip_forecast || tip_stage){
                    this.haveTip = true;
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
                // Redirect to PermissionError page if error status is 403
                if (response.response.status == 403)
                {
                    this.$router.replace('/PermissionError');
                }
                else if(response.response.status == 404)
                {
                    this.$router.replace('/PageNotFound');
                }
            });
        }else{
            await OpportunityDataService.getOpptyDetail(this.target_id)
            .then(response => {
                this.opptyDetails = response.data;
                this.opptyDetails['end_account_id'] = Number(this.opptyDetails.end_account_id)
                this.is_opptyOrg = Boolean(response.data.oppty_org);
                this.isFollow = this.opptyDetails.follow;
                const title = localStorage.getItem('title');
                document.title = title+' : '+this.opptyDetails.name +' – UpDay';
                this.StageTypeId = this.opptyDetails.stage_type.id
                this.allProcess = response.data.summary;
                this.allStage = this.allProcess.length;
                this.score = this.opptyDetails.stage_score.score
                this.changeOwnData = this.opptyDetails.owner;
                this.check = [this.opptyDetails.amount,this.opptyDetails.name,this.opptyDetails.forecast_category,this.opptyDetails.expected_close_date];
                this.closeReasonName = this.getCloseReasonName();
                this.opptyContacts.contacts = response.data.contacts;
                this.opptyContacts.contacts = this.sortContactsJson(this.opptyContacts.contacts,"first_name")
                this.opptyContactsAmount = this.opptyContacts.contacts.length
                this.formatAmount = this.getFormatAmount(this.opptyDetails.amount)
                this.created_on = new Date(this.opptyDetails.created_on).toLocaleString();
                this.customer_code = this.opptyDetails.oppty_org ? this.opptyDetails.oppty_org.customer_code : null;
                if(this.opptyDetails.customer_budget != null){
                    this.formatBudget = this.getFormatAmount(this.opptyDetails.customer_budget).slice(0,-3);
                    this.opptyDetails.customer_budget = this.opptyDetails.customer_budget.slice(0,-3);
                }
                if(response.data.closed_reason >0 )
                {
                    this.cFEditable = false				
                }
                this.breadcrumbs = [{
                        text: i18n.t('BREADCRUMBS.Deals'),
                        disabled: false,
                        href: '/Deals',
                    },
                    {
                        text: this.opptyDetails.name,
                        disabled: true,
                        href: 'Deal',
                    },
                ]

                if(this.opptyDetails.custom_field != null){
                    this.customFieldData =  this.opptyDetails.custom_field;
                }

                switch(true){
                    case (this.opptyDetails.closed_reason == 1) :
                        this.stepContent = true;
                        this.isProcessEdit = false;
                        this.isProcessComplete = true;
                        this.isProcessWon = true;					
                        this.customStagecolor = 'woncolor'	
                        break;				
                    case (this.opptyDetails.closed_reason > 1):
                        this.stepContent = false;
                        this.isProcessEdit = false;
                        this.isProcessComplete = true;
                        this.isProcessLost = true;
                        this.stepContent= true;
                        break;
                    default:
                        break;
                }
                
                const tip_acc_history = this.opptyDetails.stage_score.tip.account_history != ""
                const tip_ammount = this.opptyDetails.stage_score.tip.amount != ""
                const tip_close_date = this.opptyDetails.stage_score.tip.expected_closing_date 
                const tip_forecast = this.opptyDetails.stage_score.tip.forecast != ""
                const tip_stage = this.opptyDetails.stage_score.tip.stage != ""

                if(tip_acc_history || tip_ammount || tip_close_date || tip_forecast || tip_stage){
                    this.haveTip = true;
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
                // Redirect to PermissionError page if error status is 403
                if (response.response.status == 403)
                {
                    this.$router.replace('/PermissionError');
                }
                else if(response.response.status == 404)
                {
                    this.$router.replace('/PageNotFound');
                }
            });
        }

        if (this.opptyDetails.owner.usersetting.photo != null) {
            this.isPhoto = true;
        }

        await (this.currentStep = this.allProcess.find(d=> d.id == this.opptyDetails.current_stage).sequence_number);
        await this.setOpptySummary();
        this.currentStep = this.currentStep - 1;
        await OrganizationDataService.getchangeowner('opportunities')
            .then((respose)=>{
                this.user_list = respose.data;
                this.user_list.forEach(item => {
                    item.fullName = item.last_name + ' ' + item.first_name;
                });
            });
        await this.getOpptyTimeline(this.target_id);
        
        const caseSearch = {"oppty":this.target_id}
        await CaseDataService.detailcard(caseSearch)
        .then((response)=>{
            this.cases = response.data
        })
        
        await CampaignDataService.selectlist()
        .then((response)=>{
            this.campaignList = response.data;
        })
        if(this.opptyDetails.oppty_org){
            this.activity_org = this.opptyDetails.oppty_org;
            this.activity_contact = this.opptyDetails.primary_contact;
        }else{
            this.activity_contact = this.opptyDetails.oppty_contact;
        }
        await this.getActivityTimeline();
        await PersonalSettingService.getCurrentUserDetail()
        .then(response => {
            if(response.data[0]['usersetting']['timeline_tab'] != null){
                if(response.data[0]['usersetting']['timeline_tab']['Opportunity'] != undefined){
                    this.timeline_tab = response.data[0]['usersetting']['timeline_tab']['Opportunity'];
                }
                else{
                    this.timeline_tab = ['all'];
                }
            }
            else{
                this.timeline_tab = ['all'];
            }
        })
        .catch(response => {
            this.showErrorDialog(response);
        });
        await this.showTimlinesCheck();
        await (this.loading = false);
        window.addEventListener('resize', this.onResize);
        this.onResize();
        const io = new IntersectionObserver((mutations) => {
            const isTimelineBtm = mutations[0].isIntersecting;
            if(isTimelineBtm && this.timeline_show_count > this.timeline_count){
                this.timeline_count = this.timeline_count + 100;
            }
        });
        io.observe(document.getElementById('timeline_btm'));
        if(this.currentStep >= 3){
            passData.$emit("wiadvance_oppty_change", true);
        }
    },
    computed: {
        isOrgNew: function(){
            if(this.opptyDetails.oppty_org == "" || this.opptyDetails.oppty_org == null){
                return false;
            }
            if(typeof (this.opptyDetails.oppty_org) === 'object'){
                if(this.opptyDetails.oppty_org.id == 0){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                return true;
            }
        },
        amount: {
            get(){
                if(this.opptyDetails.amount)
                    return this.toCurrecy(this.opptyDetails.amount);
                else
                    return '';
            },
            set(val){
                if(val != null){
                    let newAmount = val.replaceAll(',', '');
                    this.opptyDetails.amount = newAmount;
                }
            }
        },
        customer_budget: {
            get(){
                if(this.opptyDetails.customer_budget)
                    return this.toCurrecy(this.opptyDetails.customer_budget);
                else
                    return '';
            },
            set(val){
                if(val != null){
                    let newAmount = val.replaceAll(',', '');
                    this.opptyDetails.customer_budget = newAmount;
                }
            }
        },
    },
    destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
    methods: {
        checkTechUser(){
            let user = JSON.parse(window.localStorage.getItem('user'));
            if(user)
                this.isTechUser = JSON.parse(window.localStorage.getItem('user'))['is_tech_user'];
            if(this.isTechUser){
                this.tabs = this.techUserTabs;
                this.cFEditable = false;
            }else{
                this.tabs = this.normalTabs;
            }
        },
        async getOpptyClosedReason(){
            this.closeReasonList = await get_oppty_closed_reason_func();
        },
        checkMore(content,length){
            if(content.replace( /(<([^>]+)>)/ig, '').length > length || content.includes('base64')){
                return true;
            }else{
                return false;
            }
        },
        onEmitChangeFollow(val){
            this.opptyDetails.follow = val;
            this.isFollow  = val;
        },
        async getActivityTimeline(){
            await ActivityDataService.getActivityTimeline(this.target_id,"oppty")
            .then((response)=>{
                this.activityTimeline = this.arrangeTimeLine(response.data.timelineList);
                this.activityTimeline.forEach(item => {
                    item.timeConvert = this.getFormatTime(new Date(item.time));
                    item.content = item.content.replace("所有者","負責人");
                    item.content_list = item.content.split(item.activity_subject);
                })
                this.due_activity_list = response.data.due_list;
                this.opptyTimeLines = this.arrangeTimeLine(this.originopptyTimeLines.concat(this.activityTimeline));
                for( let i in this.due_activity_list){
                    let obj={
                        "id": this.due_activity_list[i].id,
                        "type":"DUE_ACTIVITY",
                        "subject": this.due_activity_list[i].subject,
                        "type_name": this.due_activity_list[i].type,
                        "is_due":this.due_activity_list[i].is_due
                    }
                    this.opptyTimeLines.unshift(obj);
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
        },
        sortContactsJson(json,key){
            if(this.opptyDetails.primary_contact != null){
                for(let k=1, jl=json.length; k < jl; k++){
                    let temp = json[k],
                        value = this.opptyDetails.primary_contact['id'],
                        i = k-1;
                    while(i >=0 && json[i]['id']!=value){
                        json[i+1] = json[i];
                        i = i-1;    
                    }
                    json[i+1] = temp;                
                }
            } else {
                for(let k=1, jl=json.length; k < jl; k++){
                    let temp = json[k],
                        value = temp[key],
                        i = k-1;
                    while(i >=0 && json[i][key]>value){
                        json[i+1] = json[i];
                        i = i-1;    
                    }
                    json[i+1] = temp;                
                }
            }
            return json;
        },
        changeSelectedColor(key){
            if(this.timeline_tab.includes(key)){
                return 'v-tab tabBtn-selected';
            }
            else{
                return 'v-tab tabBtn-unselect';
            }
        },
        onChangeTltSelected(){
            let choosedbtn = document.getElementById(event.target.id);
            let choosedbtnkey = choosedbtn.getAttribute('id').substring(4);
            if(choosedbtnkey == 'all'){
                this.timeline_tab = ['all'];
            }
            else{
                if(this.timeline_tab.includes('all')){
                    this.timeline_tab.pop();
                    this.timeline_tab.push(choosedbtnkey);
                }
                else if(this.timeline_tab.includes(choosedbtnkey)){
                    let index = this.timeline_tab.indexOf(choosedbtnkey);
                    this.timeline_tab.splice(index,1);
                    if(this.timeline_tab.length == 0)
                        this.timeline_tab = ['all'];
                }
                else{
                    this.timeline_tab.push(choosedbtnkey);
                    if(this.timeline_tab.length == (this.timeLineType.length-1)) 
                        this.timeline_tab = ['all'];
                }
            }
            let user = JSON.parse(window.localStorage.getItem('user'));
            let userid = user['userid'];
            this.timelinetabData['user_id'] = userid;
            this.timelinetabData['Opportunity'] = this.timeline_tab;
            PersonalSettingService.updateUserTimelineTab(userid,this.timelinetabData)
            .catch(response => {
                this.showErrorDialog(response);
            })
            this.showTimlinesCheck();               
        },
        showTimlinesCheck(){
            this.showTimelines = this.opptyTimeLines;
            for(let i = 0,ll = this.showTimelines.length ; i<ll ; i++){
                // 預設show全部false
                this.showTimelines[i]['show'] = false;
                // 到期活動 & 釘選改為 true
                if(this.showTimelines[i]['type'] == 'DUE_ACTIVITY')
                    this.showTimelines[i]['show'] = true;
                if(this.showTimelines[i]['pinned'] !== undefined && this.showTimelines[i]['pinned'] == true)
                    this.showTimelines[i]['show'] = true;
                // 當篩選為all 改為 全true
                if(this.timeline_tab.includes('all'))
                    this.showTimelines[i]['show'] = true;
                // 依篩選調整顯示項目
                if(this.timeline_tab.includes('moneyChange')){
                    if(this.showTimelines[i]['type'] == 'AMOUNT')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('stepChange')){
                    if(this.showTimelines[i]['type'] == 'STAGE')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('fieldHistory')){
                    if(this.showTimelines[i]['type'] == 'FIELDHISTORY')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('mail')){
                    if(this.showTimelines[i]['type'] == 'EMAIL')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('sms')){
                    if(this.showTimelines[i]['type'] == 'SMS')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('note')){
                    if(this.showTimelines[i]['type'] == 'NOTE')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('activity')){
                    if(this.showTimelines[i]['type'] == 'ACTIVITY_FIELDHISTORY')
                        this.showTimelines[i]['show'] = true;
                }
            }
            let showList = this.showTimelines.filter(el => el.show == true);
            for (let i in showList){
                showList[i]['show_index'] = parseInt(i);
            }
            this.timeline_show_count = showList.length;
            this.timeline_count = 100;
        },
        showFormatAmount(data){
            if(data==='amount'){
            this.amountShow = false;
            this.formatAmount = this.getFormatAmount(this.opptyDetails.amount);
            this.amountValidate = this.$refs.editAmount.validate();}
            if(data==='budget'){
            this.budgetShow = false;
            this.formatBudget = this.getFormatAmount(this.opptyDetails.customer_budget).slice(0,-3);
            this.budgetValidate = this.$refs.editBudget.validate();}
        },
        showEditAmount(data){
            if(!this.isDetailReadonly && data==='amount')
            {
                this.amountShow = true
                this.$refs.editAmount.focus()
            }   
            if(!this.isDetailReadonly && data==='budget')
            {
                this.budgetShow = true
                this.$refs.editBudget.focus()
            }             
        },		
        setOpptySummary() {
            if (this.allStage > 6) {
                if (this.currentStep > this.allStage - 4) {
                    this.isLast = false;
                    this.opptySummary = this.allProcess.slice(this.allStage - 6, this.allStage + 6);
                }
                else if (this.currentStep < 4) {
                    this.isFirst = false;
                    this.opptySummary = this.allProcess.slice(0, 6);
                }
                else {
                    this.isFirst = true;
                    this.isLast = true;
                    this.opptySummary = this.allProcess.slice(this.currentStep - 3, this.currentStep + 3);
                }
            }
            else {
                this.opptySummary = this.allProcess;
            }
            this.currentStep = this.currentStep + 1;
        },
        onResize: function () {
            this.truncateText(this.truncateTextValue);
            this.folder();
            this.folder();
            setTimeout(()=>{
                let timelineHeight = document.getElementsByClassName('deal-tab-card')[0].offsetHeight;
                this.timeline_max_height = `max-height:${timelineHeight}px;`
            },100);
        },
        toCurrecy(num){
            let parts = num.toString().split('.');
            parts[0] = parts[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            return parts.join('.');
        },
        validateForm(){
            return this.$refs.formOpptyDetail.validate();
        },
        validateBasicForm(){
            let allFields = [];
            for(let i = 0 ; i < this.$refs.basicForm.length; i++){
                allFields.push(this.$refs.basicForm[i].validate());
            }
            return allFields.every( e => e === true );
        },
        getProcessColor: function (groupIdx) {
            let color = '';
            switch (groupIdx) {
                case 1:
                    color = 'primary';
                    break;
                case 2:
                    color = 'orange';
                    break;
                case 3:
                    color = 'green';
                    break;
                case 4:
                    color = 'purple';
                    break;
                case 5:
                    color = 'red';
                    break;
                default:
                    color = 'gary';
                    break;
            }
            return color;
        },
        getTimeLineIcon: function(type){
                let icon = "";
                switch (type) {
                    case "EMAIL":
                        icon = 'icon-mail';
                        break;
                    case "NOTE":
                        icon = 'icon-note';
                        break;
                    case "STAGE":
                        icon = 'mdi-progress-upload';
                        break;
                    case "AMOUNT":
                        icon = 'mdi-currency-usd';
                        break;
                    case "FIELDHISTORY":
                        icon = 'mdi-history';
                        break;
                    case "SMS":
                        icon = 'mdi-message-text';
                        break;
                    case "ACTIVITY_FIELDHISTORY":
                        icon = 'icon-calendar';
                        break;
                    case "DUE_ACTIVITY":
                        icon = 'mdi-bell-ring';
                        break;
                    default:
                        icon = 'mdi-help-circle';
                        break;
                }
                return icon;
            },
        switchStepContent() {			
            if (this.stepContent === true) {
                this.stepContent = false;				
            } else {
                this.stepContent = true;				
            }
        },
        onWon() {			
            this.isProcessEdit = false;
            this.isProcessComplete = true;
            this.isProcessWon = true;
            this.customStagecolor = 'woncolor'	
            this.closeReason = {"closed_reason": 1}
            this.$set(this.opptyDetails,"closed_reason",1)			
            OpportunityDataService.opptySummaryUpdate(this.target_id,this.closeReason)
            .then(response =>{
                this.opptyDetails.closed_date = response.data.closed_date;
                this.$set(this.opptyDetails, 'status', "WON");
                this.$set(this.opptyDetails, 'oppty_status', {key: "3", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.3')});		
                this.cFEditable = false;
            })		
        },
        async onLost(data) {			
            this.isProcessEdit = false;
            this.closeReason = {"closed_reason": data}
            this.$set(this.opptyDetails,"closed_reason",data)
            await OpportunityDataService.opptySummaryUpdate(this.target_id, this.closeReason)
            .then(response => {				
                this.opptyDetails.closed_date = response.data.closed_date;
                this.closeReasonName = this.getCloseReasonName();
                this.$set(this.opptyDetails, 'status', "LOSE");
                this.$set(this.opptyDetails, 'oppty_status', {key: "4", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.4')});
                this.cFEditable = false;
            })
            .catch(response =>{
                this.showErrorDialog(response);
            })
            
            await (this.isProcessLost = true);
            await (this.isProcessComplete = true);
        },
        getCloseReasonName(){
            if(this.opptyDetails.closed_reason != null && this.opptyDetails.closed_reason != false) {
                const closeReasonObj = this.closeReasonList.find(e => e.key == this.opptyDetails.closed_reason);
                return closeReasonObj.name;
            }
            else{
                return '';
            }
        },
        onReStart() {
            this.showMessageDialog(i18n.t('DEAL_DETAIL.MESSAGE_3'), 'onReStart');
            this.mode = "ReStart";
            this.closeReason = {"closed_reason": 0};				
        },
        onAddNote(){
            this.getNoteData();
            this.addNoteDialog = true; 
        },
        onEditNote(item){
            this.noteData = item;
            this.editNoteDialog = true;
        },
        onDeleteNote(id){
            this.showMessageDialog(i18n.t('DEAL_DETAIL.MESSAGE'));
            this.noteIdOnDelete = id;
            this.delMode = 'delNote';
        },
        onPinNote(item){
            NoteDataService.editNote(this.getPinnData(item))
            .then(() =>{
                this.refreshTimeLine();
                if (item.pinned == true) {
                    this.pinning = true;
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
        },
        arrangeTimeLine(timelineList){
            let havePinned = timelineList.filter(this.pinnedTrue);
            let noPinned = timelineList.filter(this.pinnedFalse);
            havePinned = havePinned.sort(function(a, b){
                let aTime = new Date(a.time+"Z");
                let bTime = new Date(b.time+"Z");
                return bTime-aTime });
            noPinned = noPinned.sort(function(a, b){
                let aTime = new Date(a.time+"Z");
                let bTime = new Date(b.time+"Z");
                return bTime-aTime });
            return havePinned.concat(noPinned);
        },
        pinnedTrue(obj){
            if ("pinned" in obj && obj.pinned == true)
                return true;
        },
        pinnedFalse(obj){
            if (!("pinned" in obj))
                return true;
            else if (obj.pinned == false)
                return true;
        },
        getPinnData(obj){
            obj.pinned = obj.pinned ? false : true;
            let data = {};
            data.pinned = JSON.parse(JSON.stringify(obj.pinned));
            data.id = JSON.parse(JSON.stringify(obj.id));
            return data;
        },
        deleteNoteFrontEnd(id){
            // delete note in time line
            let item = this.opptyTimeLines.find(e => e.id == id);
            let index = this.opptyTimeLines.indexOf(item);
            this.opptyTimeLines.splice(index, 1);
            // delete note in alert            
            let item_alert = this.alertNote.find(e => e.id == id);
            let index_alert = this.alertNote.indexOf(item_alert);
            this.alertNote.splice(index_alert, 1);
        },
        getNoteData(){
            let userJson = window.localStorage.getItem('user');
            let user = JSON.parse(userJson);
            this.noteData = {};
            this.noteData.opportunity = this.opptyDetails.id;
            this.noteData.owner = `${user.last_name} ${user.first_name}`;
        },
        onEmailOppties(oppties){
            this.emailOpptiesData = oppties;
            this.emailOpptiesDialog = true;
        },
        onEmailContact(contacts){
            this.emailContactData = contacts;
            this.emailContactsDialog = true;
        },
        onEmailAttachment(attachments){
            this.emailAttachmentData = attachments;
            this.emailAttachmentsDialog = true;
        },
        getOpptyContact() {            
            OpportunityDataService.getOpptyDetail(this.target_id)
            .then(response => {				
                this.opptyContacts.contacts = (response.data.contacts).reverse();
                this.opptyContacts.contacts = this.sortContactsJson(this.opptyContacts.contacts,"first_name")
                this.opptyContactsAmount = this.opptyContacts.contacts.length
                this.mainContactData = this.opptyContacts.contacts;
                this.mainContactData.forEach((data) => {
                    if(data.first_name != null)
                        data.full_name = data.last_name +" "+ data.first_name;
                    else
                        data.full_name = data.last_name;
                    if (data.organization_set.length == 0) {
                        data.accountName = "";
                        data.accountId = ""
                    } else {
                        data.accountName = data.organization_set[0].name;
                        data.accountId = data.organization_set[0].id;
                    }
                }); 
            })
        },
        onEmitMainContactMessage(text) {
            if (text == 'success') {
                this.getOpptyContact();
            }
            else if(text == 'success_is_primary_contact'){
                this.getOpptyContact();
                this.opptyDetails.primary_contact = null;
            }
        },
        async onChangeOpptyStage(data) {
            if(this.$isViewOnly.isViewOnly) return;
            let o_current_stage = JSON.parse(JSON.stringify(this.currentStep));
            if(this.isTechUser) return;
            if(parseInt(data.sequence_number) >= 3 && !await wiadvanceProgressCheck([this.target_id])){
                this.tab = "tab-2";
                this.showErrorDialog(i18n.t('WIADVANCE.MESSAGE_1'));
                passData.$emit("wiadvance_oppty_change", true);
                this.currentStep = o_current_stage;
                this.loading_dealDeatil = false;
                return
            }
            this.stageData = {
                "current_stage_id": data.id
            }
            if(this.opptyDetails.current_stage != data && this.isProcessEdit == true)
            {
                OpportunityDataService.opptySummaryUpdate(this.opptyDetails.id, this.stageData)
                .then(response =>{
                    this.succMode="STAGE";
                    this.stageNumber = this.allProcess.find(d => d.id == response.data.current_stage_id).sequence_number;
                    this.stageName = this.allProcess.find(d => d.id == response.data.current_stage_id).stage_name;
                    this.changeText = "目前商機已更改為 第" + this.stageNumber+ "步驟 " + this.stageName
                    if(this.$i18n.locale == "en_US")
                    {
                      this.changeText = "The current stage of opportunity has been changed to step " + this.stageNumber+ " : " + this.stageName
                    }
                    this.getFieldHistoryData(this.changeText);                            
                    this.opptyTimeLines.unshift(this.fieldhistoryData);
                    this.opptyDetails.current_stage = data;
                    if(this.currentStep <=2){
                        passData.$emit("wiadvance_oppty_change", false);
                    }
                })
                .catch(response =>{
                    this.showErrorDialog(response);
                })
            }
        },
        moreContent(event){
            let n = event.target.closest('div').previousSibling.firstChild;			
            if (n.classList.contains('show-more')){
                n.classList.remove('show-more');
                event.target.innerText = 'MORE';
            }
            else{
                n.classList.add('show-more');
                event.target.innerText = 'LESS';
            }   
        },
        getStageProperty(stage_probability){
            return stage_probability + ' %';
        },
        // edit Keyword
        editKeyword: function(data) {
            this.isReadonlyKeyword = false;
            this.showEditKeywordBtn = false;
            this.showEditKeywordCheckBtn = true;
            this.keywordOriginalData = data;
            this.originOpptyDetails = JSON.parse(JSON.stringify(this.opptyDetails));
        },
        cancelEditKeyword() {
            this.isReadonlyKeyword = true;
            this.showEditKeywordBtn = true;
            this.showEditKeywordCheckBtn = false;
            this.opptyDetails = this.originOpptyDetails;
        },
        confirmEditKeyword() {
            this.keywordedit = true;
            this.infoData = {"description": this.opptyDetails.description,"budget_confirmed": this.opptyDetails.budget_confirmed, }
            if(this.validateBasicForm())
                OpportunityDataService.opptySummaryUpdate(this.opptyDetails.id, this.infoData)
                .then(() => {
                    // this.showSuccessDialog();
                    this.showEditKeywordCheckBtn = false;
                    this.isReadonlyKeyword = true;
                })
        },
        onEditDetail() {
            this.showDetailEditBtn = false;
            this.isDetailReadonly = false;
            this.originOpptyDetails = JSON.parse(JSON.stringify(this.opptyDetails));
        },
        defaultDetail(){
            this.isNew = {
                primary_contact: false,
                oppty_org: false,
                oppty_contact: false
            }
            this.newPri = {
                fName: null,
                lName: null,
            }
            this.newOppCon = {
                fName: null,
                lName: null,
            }
        },
        cancelEditDetail() {
            this.showDetailEditBtn = true;
            this.isDetailReadonly = true;
            this.defaultDetail();
            this.opptyDetails = this.originOpptyDetails;
            this.formatAmount = this.getFormatAmount(this.originOpptyDetails.amount);
            this.amountValidate = true;
            this.formatBudget = this.getFormatAmount(this.originOpptyDetails.customer_budget);
            this.formatBudget = this.formatBudget.slice(0,-3)
            this.budgetValidate = true;
        },
        async confirmEditDetail() {
            if(this.comboInput.oppty_org != '' && this.$refs.formOpptyDetail.validate() && this.checkCustomerSource()){	
                await (this.loading_dealDeatil = true);			
                this.validateForm();
                let data = JSON.parse(JSON.stringify(this.opptyDetails));
                // if(parseInt(data.progress) >= 3 && !await wiadvanceProgressCheck([data.id])){
                //     this.tab = "tab-2";
                //     this.showErrorDialog(i18n.t('WIADVANCE.MESSAGE_1'));
                //     this.loading_dealDeatil = false;
                //     return
                // }
                if(data.oppty_org == null)
                    data.oppty_org = {};
                else if(data.oppty_contact == null)
                    data.oppty_contact = {};
                if(data.primary_contact == null || data.primary_contact == '')
                    data.primary_contact = {};

                // ===== new primary_contact start =====
                if(this.isNew.primary_contact && this.newPri.lName)
                {
                    data.primary_contact.id = 0;
                    data.primary_contact.last_name = this.newPri.lName;
                    if(this.newPri.fName != null)
                        data.primary_contact.first_name = this.newPri.fName;
                    else 	
                        data.primary_contact.first_name = null;
                }
                else if(this.isNew.primary_contact && !this.newPri.lName)
                {
                    data.primary_contact = {};
                }
                else if(this.comboInput.primary_contact == "")
                {
                    data.primary_contact = {};
                }
                // ===== new primary_contact end =====
                // ===== new oppty_contact start =====
                if(this.isNew.oppty_contact && this.newOppCon.lName)
                {
                    data.oppty_contact = {
                        "id": 0,
                        "last_name": this.newOppCon.lName,
                        "first_name": null,
                    };
                    if(this.newOppCon.fName != null)
                    {
                        data.oppty_contact.first_name = this.newOppCon.fName;
                    }
                }
                // ===== new oppty_contact end =====
                // ===== new oppty_org start =====
                if(this.comboInput.oppty_org != ''){
                    if(this.comboInput.oppty_org != data.oppty_org.name && typeof(data.oppty_org) == 'string')
                    {
                        let newOrg = {
                            id: "-1", 
                            name: this.comboInput.oppty_org, 
                            company_number: "0"
                        };
                        data.oppty_org = newOrg;
                    }
                }
                // ===== new oppty_org end=====

                delete data.stage_progress;
                delete data.stage_score;
                delete data.summary;
                delete data.owner;
                delete data.stages;
                delete data.contacts;
                await OpportunityDataService.editOpptyDetail(data)
                .then(response => {
                    if (response.data.oppty_org != null) {
                        if (this.opptyDetails.oppty_org.id == 0) {
                            let principalData = {
                                "organization_id": response.data.oppty_org.id,
                                "owner_id": response.data.owner.id
                            };
                            ContactDataService.createCompanyPrincipal(principalData);
                        }
                    }
                    this.opptyDetails = response.data;
                    if(this.opptyDetails.customer_budget!=null){
                    this.opptyDetails.customer_budget = this.opptyDetails.customer_budget.slice(0,-3)
                    }
                    this.opptyDetails['end_account_id']= Number(this.opptyDetails.end_account_id)
                    this.opptyContacts.contacts = this.opptyDetails.contacts;
                    this.opptyContacts.contacts = this.sortContactsJson(this.opptyContacts.contacts,"first_name");
                    this.opptyContactsAmount = this.opptyContacts.contacts.length;
                    this.customer_code = this.opptyDetails.oppty_org ? this.opptyDetails.oppty_org.customer_code : null;
                    // this.dataCheck = [this.opptyDetails.amount,this.opptyDetails.name,this.opptyDetails.forecast_category,this.opptyDetails.expected_close_date];
                    //         if(this.check[0] != this.dataCheck[0])
                    //             {
                    //                 this.succMode = "AMOUNT";
                    //                 this.changeText = i18n.t('DEAL_DETAIL.TIMELINE_AMOUNT')+ this.opptyDetails.amount;
                    //                 this.getFieldHistoryData(this.changeText);
                    //                 this.opptyTimeLines.unshift(this.fieldhistoryData);
                    //             }
                    //         if (this.check[1] != this.dataCheck[1])
                    //             {
                    //                 this.succMode = ''
                    //                 this.changeText = i18n.t('DEAL_DETAIL.TIMELINE_NAME') + this.opptyDetails.name
                    //                 this.getFieldHistoryData(this.changeText);
                    //                 this.opptyTimeLines.unshift(this.fieldhistoryData);
                    //             }
                    //         if(this.check[2] != this.dataCheck[2])
                    //             {      
                    //                 this.succMode = ''
                    //                 this.changeText = i18n.t('DEAL_DETAIL.TIMELINE_FORECAST')+ this.getForecast_category(this.opptyDetails.forecast_category);
                    //                 this.getFieldHistoryData(this.changeText);
                    //                 this.opptyTimeLines.unshift(this.fieldhistoryData);
                    //             }
                    //         if(this.check[3] != this.dataCheck[3])
                    //             {      
                    //                 this.succMode = ''
                    //                 this.changeText = i18n.t('DEAL_DETAIL.TIMELINE_ECD')+ this.opptyDetails.expected_close_date
                    //                 this.getFieldHistoryData(this.changeText);
                    //                 this.opptyTimeLines.unshift(this.fieldhistoryData);
                    //             }
                    this.refreshTimeLine();
                    if(this.opptyDetails.oppty_org){
                        this.activity_org = this.opptyDetails.oppty_org;
                        this.activity_contact = this.opptyDetails.primary_contact;
                    }else{
                        this.activity_contact = this.opptyDetails.oppty_contact;
                    }
                    this.isDetailReadonly = true;
                    this.showDetailEditBtn = true;
                    this.defaultDetail();
                    if(this.keywordedit == true)
                    {
                        this.isReadonlyKeyword = true;
                        this.showEditKeywordBtn = true;
                        this.showEditKeywordCheckBtn = false;
                    }
                })
                .catch(response => {
                    console.log(response)
                })
                await (this.loading_dealDeatil = false);	
            }
        },
        cleanFirstName(field){
            if(field.lName == null || field.lName == "")
                field.fName = null;
        },
        getForecast_category(key){
            for(let i = 0; i < this.forecast_category_List.length; i++){
                if(this.forecast_category_List[i].key == key)
                    return this.forecast_category_List[i].name;
            }
        },
        // Common Function
        onCall: function (cell) {
            return 'tel:'+cell;
        },
        onMail: function (mail) {
            return 'mailto:'+mail;
        },
        // Dialog		
        onEmitAddNoteDialog(val){
            this.addNoteDialog = false;
            if (val) {
                if (this.noteData.content.length > 300) {
                    this.$set(this.noteData, 'isMore_timeLineAll', true);
                    this.$set(this.noteData, 'isMore_timeLineNote', true);
                    this.$set(this.noteData, 'isMore_note', true);
                } else {
                    this.$set(this.noteData, 'isMore_timeLineAll', false);
                    this.$set(this.noteData, 'isMore_timeLineNote', false);
                    this.$set(this.noteData, 'isMore_note', false);
                }
                this.opptyTimeLines.unshift(this.noteData);
                this.opptyTimeLines = this.arrangeTimeLine(this.opptyTimeLines);
                // this.showSuccessDialog();
            }
        },
        onEmitAddNoteData(data) {
            let index = this.opptyTimeLines.findIndex(item => {return item.id == data.id})
            this.opptyTimeLines[index].time = data.created_on;
            this.refreshTimeLine();
        },
        onEmitEditNoteDialog(val){
            this.editNoteDialog = false;
            if (val) {
                this.refreshTimeLine();
                if (this.noteData.content.length > 300) {
                    this.$set(this.noteData, 'isMore_timeLineAll', true);
                    this.$set(this.noteData, 'isMore_timeLineNote', true);
                    this.$set(this.noteData, 'isMore_note', true);
                } else {
                    this.$set(this.noteData, 'isMore_timeLineAll', false);
                    this.$set(this.noteData, 'isMore_timeLineNote', false);
                    this.$set(this.noteData, 'isMore_note', false);
                }
                // this.showSuccessDialog();
            }
        },
        onEditDeal() {
            this.editDealDialog = true;
        },
        onEmitEditDealDialog(val) {
            this.editDealDialog = false;
            if (val) {
                this.showSuccessDialog();
            }
        },
        async onChangeOwn(data) {
            await (this.otherData.oppty_id = data.id);
            await (this.otherData.name = data.name);
            await (this.changeOwnDialog = true);
        },
        onEmitChangeOwnDialog(val) {
            this.changeOwnDialog = false;
            if (val) {
                this.succMode = "changeOwn";
                this.showSuccessDialog();
            }
        },
        onChangeProcess(data) {
            this.processData.id = data.id;
            this.processData.name = data.name;
            this.processData.stage_type = data.stage_type;
            this.changeProcessDialog = true;
        },
        onEmitChangeProcessDialog() {
            this.changeProcessDialog = false;
        },
        async onEmitChangeProcessData(data) {
            this.opptyDetails = data
            this.allProcess = this.opptyDetails.summary;
            this.allStage = this.allProcess.length;
            this.score = this.opptyDetails.stage_score.score;
            this.StageTypeId = this.opptyDetails.stage_type.id
            if(data.custom_field != null){
                this.customFieldData = data.custom_field;
            }
            await this.setOpptySummary();
            await (this.currentStep = 1)
            this.changeText = i18n.t('DEAL_DETAIL.TIMELINE_PROCESS') + this.opptyDetails.stage_type.stage_type_name;
            this.getFieldHistoryData(this.changeText);
            this.opptyTimeLines.unshift(this.fieldhistoryData);
        },
        onMainContacts(data) {
            this.relatedContactsDialog = true;
            this.mainContactData = data;			
        },
        onEmitRelatedContactsDialog() {
            this.relatedContactsDialog = false;
        },
        onEditAactivity() {
            this.activityDialog = true;
        },
        onEmitActivityDialog(val) {
            this.activityDialog = false;
            if (val) {
                this.showSuccessDialog();
            }
        },
        showSuccessDialog() {
            this.successDialog = true;
        },
        async onEmitSuccessDialog() {
            this.successDialog = false;
            if (this.succMode == 'changeOwn') {
                this.changeText = i18n.t('DEAL_DETAIL.TIMELINE_OWNER')+`${this.changeOwnData.last_name} ${this.changeOwnData.first_name}`
                await this.refreshTimeLine();
            }
            if (this.succMode == 'DELOPPTY') {
                this.$router.replace('/Deals');            
            }
            this.succMode = '';
        },
        getFormatTime(data){
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
                let today  = data;
                if(this.$i18n.locale == "zh_TW")
                {
                   return today.toLocaleDateString("zh-TW", options); 
                }else
                {
                    return today.toLocaleDateString("en-US", options);
                }                
        },
        getLittleFormatTime(data){
                // let options = {year: 'numeric', month: 'long', day: 'numeric' };
                let today  = data;
                if(this.$i18n.locale == "zh_TW")
                {
                   return today.toLocaleDateString("zh-TW"); 
                }else
                {
                    return today.toLocaleDateString("en-US");
                }                 
        },
        getFieldHistoryData(data){
                this.fieldhistoryData = {};
                switch(this.succMode){
                    case 'changeOwn':
                        this.fieldhistoryData.type = "FIELDHISTORY";
                        break;
                    case 'AMOUNT':
                        this.fieldhistoryData.type = "AMOUNT";
                        break;
                    case 'STAGE':
                        this.fieldhistoryData.type = "STAGE";
                        break;
                    default:
                        this.fieldhistoryData.type = "FIELDHISTORY";
                        break;
                }
                let userJson = window.localStorage.getItem('user');
                let user = JSON.parse(userJson);
                this.fieldhistoryData.timeConvert = this.getFormatTime(new Date());
                this.fieldhistoryData.content = data;                
                this.fieldhistoryData.opportunity = this.opptyDetails.id;                
                this.fieldhistoryData.changed_by = `${user.last_name} ${user.first_name}`;
            },
        showMessageDialog(message, situation) {
            this.msgDialogSituation = situation;
            this.messageDialog = true;
            this.message = message;
        },
        onEmitMessageDialog(val) {
            this.messageDialog = false;
            if (val) {
                if(this.mode =="ReStart"){
                    this.$set(this.opptyDetails,"closed_reason",0)	
                    OpportunityDataService.opptySummaryUpdate(this.target_id,this.closeReason).then(()=>{
                        this.$set(this.opptyDetails, 'status', "OPEN");
                        this.$set(this.opptyDetails, 'oppty_status', {key: "2", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.2')});
                        this.cFEditable = true;
                    })
                }
                switch(this.msgDialogSituation){
                    case 'onReStart':						
                        this.stepContent = true;
                        this.isProcessComplete = false;
                        this.isProcessLost = false;
                        this.isProcessWon = false;
                        this.isProcessEdit = true;
                        this.customStagecolor = 'customcolor'
                        break;
                    default:
                        break;
                }
                if(this.succMode =="DELOPPTY"){
                    OpportunityDataService.delOpptyDetail(this.target_id);                   
                }
                this.msgDialogSituation = '';
                this.successDialog = true; 

                if (val && this.delMode == 'delNote'){
                    NoteDataService.deleteNote(this.noteIdOnDelete)
                    .then(() => {
                        this.deleteNoteFrontEnd(this.noteIdOnDelete);
                        // this.showSuccessDialog();
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    });
                }
                this.noteIdOnDelete == null;
                this.delMode = '';
            }
        },
        showErrorDialog(message) {
            this.errorDialog = true;
            this.errorMessage = message;
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
        onDelete () {
            this.succMode = "DELOPPTY";
            this.showMessageDialog(i18n.t('DEAL_DETAIL.MESSAGE_2'));
        },
        onEmitEmailContactsDialog(){
            this.emailContactsDialog = false;
        },
        onEmitEmailOpptiesDialog(){
            this.emailOpptiesDialog = false
        },
        onEmitEmailAttachmentsDialog(){
            this.emailAttachmentsDialog = false;
        },
        // Dialog Area ----------------------------------------- [End]
        onPinning() {
            this.pinning = false;
        },
        onEditorBlur(quill) {
            console.log("editor blur!", quill);
        },
        onEditorFocus(quill) {
            console.log("editor focus!", quill);
        },
        onSelectionChange(range) {
            console.log("selection change!", range);
        },
        onEmailTemplateValChange(val, insertText) {
            if (val && insertText) {
                this.mainEditor = this.mainEditor.replace(val, insertText);
            }
        },
        // email Template Area ----------------------------------------- [End]
        // timeLine Area ----------------------------------------- [Start]
        hasType(arr, type){
            let answer = arr.some(function(item){
                return item.type == type;
            }) 
            return answer;
        },
        // timeLine Area ----------------------------------------- [End]
        // Tab Area ----------------------------------------- [Start]
        getTabName(key){
            if(key == 'note')
                this.isNoteTab = true;
            else
                this.isNoteTab = false;
            if(key == 'documents')
                this.isdocumentTab = true;
            else
                this.isdocumentTab = false;
            if(key == 'product')
                this.isProductTab = true;
            else
                this.isProductTab = false;
            if(key == 'activities')
                this.isActivityTab = true;
            else
                this.isActivityTab = false;
        },
        // Tab Area ----------------------------------------- [End]
        getcolor: function(data) {
            this.colorQ = data;				 
            return {			
            '--backgroundColor':this.colorQ,
            '--color': this.colorQ		
            }
        },
        setPosition: function(data) {
            if(data==0)	{
                return {
                    top: '0px'				
                }
            }
            else{
                if(this.getfolder == false){ 
                return {
                        top : data*45+'px'
                    }
                }
                else{
                    return {
                        display : 'none'
                    }
                }
            }
        },
        folder(data){			
            if (this.getfolder == false){
            this.getfolder = true;
            this.setPosition(data);
            }
            else {
            this.getfolder = false;
            this.setPosition(data);
            }
        },
        showAddDealDialog() {
            this.addDealDialog = true;
            this.copyDataId = "deal"
            this.addDealData = {
                name: this.opptyDetails.name,
                primary_contact: this.opptyDetails.primary_contact,
                oppty_org: this.opptyDetails.oppty_org,
                stage_type: this.opptyDetails.stage_type,
                amount: this.opptyDetails.amount,
                expected_close_date: this.opptyDetails.expected_close_date,
                current_stage: this.currentStep,
                forecast_category: this.opptyDetails.forecast_category,
                custom_field: this.opptyDetails.custom_field,
                oppty_status: this.opptyDetails.oppty_status,
                industry: this.opptyDetails.industry,
                customer_source: this.opptyDetails.customer_source,
                progress: "1",
                oppty_type: this.opptyDetails.oppty_type,
            }
        },
        onEmitAddDealDialog(val) {
            this.addDealDialog = false;
            if (val) {
                if(this.copyDataId == null)
                {this.showSuccessDialog();}
                else
                {                
                this.redirectDialog = true
                this.message = ""
                this.copyDataId = this.addDealData.id
                }
            }
        },
        showNote(value) {
            if (value != null) {
                return value.substring(0, 300) + " ...";
            }
        },
        nextStage() {
            this.currentStep = this.currentStep + 1;
            if (this.opptySummary[5].sequence_number > this.allStage - 6) {
                let n = this.allStage - 5;
                this.changeAddStage(n);
            } else if (this.opptySummary[5].sequence_number < this.allStage) {
                let n = this.opptySummary[5].sequence_number + 1;
                this.changeAddStage(n);
            }
            if (this.opptySummary[0].sequence_number == 1) {
                this.isFirst = false;
            } else {
                this.isFirst = true;
            }
            if (this.opptySummary[5].sequence_number == this.allProcess.length) {
                this.isLast = false;
            } else {
                this.isLast = true;
            }
        },
        async preStage() {
            if (this.opptySummary[0].sequence_number <= 6) {
                let n = 6;
                await this.changeMinusStage(n);
            } else {
                let n = this.opptySummary[0].sequence_number - 1;
                await this.changeMinusStage(n);
            }
            await (this.currentStep = this.currentStep + 1);
            if (this.opptySummary[0].sequence_number == 1) {
                this.isFirst = false;
            } else {
                this.isFirst = true;
            }
            if (this.opptySummary[5].sequence_number == this.allProcess.length) {
                this.isLast = false;
            } else {
                this.isLast = true;
            }
        },
        async changeAddStage(n) {
            await (this.opptySummary = this.allProcess.slice(n - 1, n + 5));
            await (this.currentStep = this.currentStep - 1);
        },
        changeMinusStage(n) {
            this.currentStep = this.currentStep - 1;
            this.opptySummary = this.allProcess.slice(n - 6, n);
        },
        async getOpptyTimeline(id) {
            await OpportunityDataService.getOpptytimeline(id)
            .then(response => {	
                this.opptyTimeLines = this.arrangeTimeLine(response.data);
                let i = 0;
                this.alertNote = [];
                this.opptyTimeLines.forEach(item => {                    
                    // item.timeConvert = this.getFormatTime(new Date(item.time+"Z"))
                    item.timeConvert = this.getFormatTime(new Date(item.time))
                    // if(item.type =="STAGE"){							
                    // 	this.stageNumber = this.allProcess.find(d => d.id == item.content).sequence_number;
                    // 	this.stageName = this.allProcess.find(d => d.id == item.content).stage_name;
                    // 	item.content = "目前商機已更改為 第" + this.stageNumber+ "步驟 " + this.stageName
                    // }
                    if(item.type == "NOTE" && item.pinned ==true){
                        item.alertTime = this.getLittleFormatTime(new Date(item.time))
                        this.alertNote.push(item)
                    }
                    if (item.type == "NOTE") {
                        if (item.content.length > 300) {
                            this.$set(this.opptyTimeLines[i], 'isMore_timeLineAll', true);
                            this.$set(this.opptyTimeLines[i], 'isMore_timeLineNote', true);
                            this.$set(this.opptyTimeLines[i], 'isMore_note', true);
                        } else {
                            this.$set(this.opptyTimeLines[i], 'isMore_timeLineAll', false);
                            this.$set(this.opptyTimeLines[i], 'isMore_timeLineNote', false);
                            this.$set(this.opptyTimeLines[i], 'isMore_note', false);
                        }
                    }
                    if(item.type == "EMAIL"){
                        let to_list = [];
                        let cc_list = [];
                        let sender_name = "";
                        try{
                            sender_name = this.user_list.filter(el => el.email == item.sender)[0].fullName;
                        }
                        catch{
                            try{
                                sender_name = item.contacts.filter(el => el.email == item.sender)[0].__chineseName__;
                            }
                            catch{
                                sender_name = item.sender;
                            }
                        }
                        item.sender_data = {'sender_name':sender_name,'email':item.sender};
                        for(let i in item.recipient.to){
                            let name = "";
                            try{
                                name = item.contacts.filter(el => el.email == item.recipient.to[i])[0].__chineseName__;
                            }
                            catch{
                                name = "";
                            }
                            to_list.push({'name':name,'email':item.recipient.to[i]});
                        }
                        for(let i in item.recipient.cc){
                            let name = "";
                            try{
                                name = item.contacts.filter(el => el.email == item.recipient.cc[i])[0].__chineseName__;
                            }
                            catch{
                                name = "";
                            }
                            cc_list.push({'name':name,'email':item.recipient.cc[i]});
                        }
                        item.cc_list = cc_list;
                        item.to_list = to_list;
                    }
                    // else if (item.type == "SMS") {
                    //     if (item.sms_body.length > 300) {
                    //         this.$set(this.opptyTimeLines[i], 'isMore_timeLineAll', true);
                    //         this.$set(this.opptyTimeLines[i], 'isMore_timeLineSms', true);
                    //     } else {
                    //         this.$set(this.opptyTimeLines[i], 'isMore_timeLineAll', false);
                    //         this.$set(this.opptyTimeLines[i], 'isMore_timeLineSms', false);
                    //     }
                    // }
                    i ++;
                });
                this.originopptyTimeLines = JSON.parse(JSON.stringify(this.opptyTimeLines));
            })
            .catch(response => {
                this.showErrorDialog(response);
            })		
        },
        getLoginUserEmail() {
            O365Service.getO365Accounts()
            .then(response => {
                this.userEmail = response.data[0]? response.data[0].o365_user_principal_name:null;
            });

            // var user = window.localStorage.getItem('user');
            // if (user != null && user != undefined) {
            //     let obj = JSON.parse(user);
            //     return obj.email;
            // } else {
            //     return '';
            // }
        },
        async onEmitSendEmailDialog(val) {
            if (val) {
                this.showSuccessDialog();
                await this.refreshTimeLine();
            }
        },
        async onEmitSendSmsDialog(val) {
            if (val) {
                this.showSuccessDialog();
                await this.refreshTimeLine();
            }
        },
        showEmailDetail(event){
            let n = event.target.closest('div .no-gutters').previousSibling.lastChild;
            if (n.classList.contains('d-none')){
                n.classList.remove('d-none');
                event.target.innerText = i18n.t('GENERAL.HIDE_CONTENT');
            }
            else{
                n.classList.add('d-none');
                event.target.innerText = i18n.t('GENERAL.SHOW_CONTENT');
            }
        },
        isMore(index, place) {
            if (place == "timeLineAll") {
                this.opptyTimeLines[index].isMore_timeLineAll = this.opptyTimeLines[index].isMore_timeLineAll ? false : true;
            } else if (place == "timeLineNote") {
                this.opptyTimeLines[index].isMore_timeLineNote = this.opptyTimeLines[index].isMore_timeLineNote ? false : true;
            } else if (place == "NOTE") {
                this.opptyTimeLines[index].isMore_note = this.opptyTimeLines[index].isMore_note ? false : true;
            }
        },
        onEmitRedirectDialog(){
            this.redirectDialog = false
            this.copyDataId = null      
        },
        showAddCaseDialog(){
            if(this.opptyDetails.contacts && this.opptyDetails.primary_contact)
            {   let pc = this.opptyDetails.contacts.filter(el=>el.id = this.opptyDetails.primary_contact.id)
                // pc = this.opptyDetails.contacts.filter(el=>el.id = this.opptyDetails.primary_contact.id)[0]
                if(pc.length>0){
                    this.primary_contact_data = pc[0]
                }
                else{
                    this.primary_contact_data = {'email':null,'first_name':null,'id':null,'image':null,'last_name':null,'mobile_phone':null,'organization_set':null}
                }
            }
            else{
                this.primary_contact_data = {'email':null,'first_name':null,'id':null,'image':null,'last_name':null,'mobile_phone':null,'organization_set':null}
            }
            this.addCaseData = {
                subject: '',
                primary_contact: this.opptyDetails.primary_contact? this.opptyDetails.primary_contact: '',
                case_org: this.opptyDetails.oppty_org? this.opptyDetails.oppty_org:null,
                source: '',
                group: '',
                type: '',
                due_date: '',
                incoming_email:this.primary_contact_data.email? this.primary_contact_data.email:'',
                incoming_phone:this.primary_contact_data.mobile_phone? this.primary_contact_data.mobile_phone:'',                
            };
            this.addCaseData.opptys = [this.opptyDetails.id]
            this.addCaseDialog = true;
        },
        onEmitAddCaseDialog(val){
            this.addCaseDialog = false;
            if(val){
                this.showSuccessDialog();
            }
        },
        onEmitNewCaseData(data) {   
            this.cases.push(data);
        },
        // documnet
        onAddDocument(){
            this.isAddDoucment = true;
        },
        onAddProduct(){
            this.isAddProduct = true;
        },
        onEmitAddProduct(){
            this.isAddProduct = false;
        },
        onAddActivity(){
            this.isAddActivity = true;
        },
        async onEmitAddActivity(val){
            this.isAddActivity = false;
            if(val=="refresh"){
                await this.getActivityTimeline();
                await this.showTimlinesCheck();
            }
        },
        async refreshTimeLine(){
                await this.getOpptyTimeline(this.target_id);
                await this.getActivityTimeline();
                await this.showTimlinesCheck();
        },
        async onEmit_editFromProdcutLine(type, data){
            if(type == 'system_compute'){
                this.opptyDetails.amount = await Number(data);
                
                await this.confirmEditDetail();
                await this.showFormatAmount("amount");
            }
            else if(type == 'tax_option'){
                this.opptyDetails.tax_option = await data;
                await this.confirmEditDetail();
                await this.$refs.productLines.updateProductLine();
            }
        },
        async onEmit_fromProdcutLine(type,option,amount){
            if(type == "system_compute"){
                this.opptyDetails.amount = await Number(amount);
            }
            this.opptyDetails.tax_option = await option;
            await this.confirmEditDetail();
            await this.showFormatAmount("amount");
        },
        async onDelDocument(){
            this.openDelDocumentDialog = true;
        },
        onEmitDocDialog(){
            this.isAddDoucment = false;
            this.openDelDocumentDialog = false;
        },
        selectDocumentsList(data){
            if(data.length > 0)
            {
                this.isDelDocument = true;
            }
            else{
                this.isDelDocument = false;
            }
        },
        checkCustomerSource(){
            if(this.opptyDetails.customer_source == '6' && this.opptyDetails.oppty_org.customer_code == null){
                    this.showErrorDialog(i18n.t('DEAL_DETAIL.WIADVANCR_CUSTOMER_SOURCE'));
                    return false
            }else{
                return true
            }
        },
        checkWiadvanceShow(){
            if(this.isWiadvance()){
                return this.is_staff;
            }else{
                return true;
            }
        },
    },
    watch: {
        "redirectDialog":function () {
            if(this.redirectDialog == false)
            {
                this.copyDataId = null        
            }      
        },	
        async search_oppty_contact(val){
            this.comboInput.oppty_contact = val;
            let array = [];

            // check user input is en / zh / num
            let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
            let isEnZhNum = false;
            pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

            // check user input data type
            let isString; 
            typeof(val) == 'string' ? isString = true : isString = false;

            // search
            if(isEnZhNum && !!val && isString){
                this.isLoading.oppty_contact = true;
                ContactDataService.getAll(1,val,100,[])
                .then(response => {
                    response.data.list.forEach(item => {
                        if(item.full_name.includes(val) || item.full_name_zh.includes(val) || item.full_name_en.includes(val)){
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh
                            if(item.organization_set.length > 0)
                            {
                                item.org_name = "[ "+item.organization_set[0].name +" ]";
                            }
                            array.push(item);
                        }
                    })
                    this.contactData = array;
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
                .finally(()=>{
                    this.isLoading.oppty_contact = false;
                })
            }
        },
        async search_primary_contact(val){
            this.comboInput.primary_contact = val;
            let array = [];

            // check user input is en / zh / num
            let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
            let isEnZhNum = false;
            pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

            // check user input data type
            let isString; 
            typeof(val) == 'string' ? isString = true : isString = false;

            // search
            if(isEnZhNum && !!val && isString){
                this.isLoading.primary_contact = true;
                ContactDataService.getAll(1,val,100,[])
                .then(response => {
                    response.data.list.forEach(item => {
                        item.searchStr = item.full_name 
                                        +item.full_name_en
                                        +item.full_name_en.toLowerCase()
                                        +item.full_name_en.toUpperCase()
                                        +item.full_name_zh;
                        if(item.searchStr.includes(val)){
                            if(item.organization_set.length > 0)
                            {
                                item.org_name = "[ "+item.organization_set[0].name +" ]";
                            }
                            array.push(item);
                        }
                    })
                    this.contactData = array;
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
                .finally(()=>{
                    this.isLoading.primary_contact = false;
                })
            }
            else{
                this.contactList = [];
            }
        },
        async search_oppty_org(val) { 
            var re = /^(?!\s*$).+/;
            var check = re.test(val);
            var checkLength = /^.{0,200}$/.test(val);
            this.comboInput.oppty_org = "";
            if(check && val != null && checkLength){
                let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                this.comboInput.oppty_org = val;

                if(!this.isLoading.oppty_org && charNum >= 2 && charNum == val.length ){
                    this.isLoading.oppty_org = true;
                    await OrganizationDataService.findOrganizationByNamefromDB(val)
                    .then((response) => {
                        this.organizationList = response.data;
                        let num = 0
                        this.organizationList.forEach((item)=>{
                        item.uqNumber = num
                        num ++
                        })                                           
                    })
                    .finally(() => {
                        this.isLoading.oppty_org = false;
                    });
                }
                else {
                    this.isLoading.oppty_org = false;                        
                }
            }
        },
        isDetailReadonly(val){
            this.removeMenu(val);
        }
    },
    }
</script>

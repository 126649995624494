<template lang='pug'>
  #GPT.main-page.GPT-color.page-noScroll.pb-0.position-relative
    loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999' attach)
    loadingOverlay(v-if='delLoading' message='Loading . . .' :zIndex='999' attach)
            v-progress-circular(indeterminate color='primary')
    div.height_100(v-if='!loading')
      div(:href='"/upgpt/Knowledges"').d-flex.align-start.justify-start.pl-9
        v-breadcrumbs.pa-0.px-3.pb-2.ellipsis()
          v-breadcrumbs-item(v-for="item in breadcrumbs" :key="item.text" )
            .detail_a
              a(:href='item.href')
                v-icon(size='23' style='margin-bottom:2px;').pr-1 mdi-chevron-double-left
                span {{item.text}}
      #page-inner.pa-3.pt-.d-flex.justify-center
        v-card.deal-tab-card.rounded-card(style='width:95%;' flat)
          v-row.account-info(no-gutters='no-gutters' align="center" justify="center")
            v-col(cols='6' md='6')
                v-card-title(:class='!isEdit? " pa-0 pb-2 mt-2":" pa-0 pt-0 mt-2"')
                    div.pl-4.pr-4.d-flex.align-center(width="50px" style='margin-bottom:-66px;')
                      v-img.ml-2(src="../assets/images/book1.png" class="white--text align-end"  width="50px")
                .knowledges-detail-card-text.pt-0.pb-3
                  v-card-text.mt-0.pa-0.knowledges-card-text()
                        v-row(no-gutters :title='knoeledgeData.name')
                            v-col(cols='auto' ref="nameWidth")
                                h4.f-text-highlight-word.ellipsis(:class='isEdit? "pt-3":"pt-1"') {{ knoeledgeData.name }}
                            //- v-col(cols='1')
                            //-     v-btn.pt-1( icon text color='gary' @click='onEdit' v-if='!isEdit')
                            //-         v-icon(size='20') icon-edit
                                    //- span {{$t('GENERAL.EDIT')}}
                        //- v-row(v-if='!loading && isEdit' no-gutters)
                        //-     v-col.pr-md-4(cols='12' md='6')
                        //-       p.d-flex.align-center.input-has-label
                        //-         v-text-field.pb-0(v-model='knoeledgeData.name' solo :readonly='!isEdit' ref='firstTextField' :rules="formRule.nameRules" style='padding-top:0.1rem;') 
                        //-             template(v-slot:append)
                        //-                 v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                        //-                 v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                        //-                 v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                        //-                 v-icon(size='18' v-if='!isEdit && !loading' @click='onEdit()') icon-edit
                        v-form(v-model='valid' ref='formContact')
                          v-row(no-gutters)
                              v-col.pr-md-4(cols='12' md='12' :title='knoeledgeData.description').my-1
                                  .d-flex.align-center.justify-md-space-between.mr-12
                                    label {{$t('CONTACT_DETAIL.DESCRIPTION')}} :
                                    div
                                      v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                      v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                      v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                                      v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                                  p.d-flex.align-start.input-has-label.mr-12
                                    //- label {{$t('CONTACT_DETAIL.DESCRIPTION')}}
                                    v-textarea.span.textarea-style(v-model='knoeledgeData.description' :readonly='!isEdit' solo no-resize rows='2')
                                      template(v-slot:append)
                        p.mt-2.mb-0.t-gray(:title='knoeledgeData.docs_count')  {{$t("GPT_KNOWLEDGE.DOCS")}} : 
                            span.span {{knoeledgeData.docs_count}}
                        .d-flex.align-center.justify-md-space-between.t-gray 
                          p.d-flex.align-center.justify-center.pt-2(style='white-space:nowrap;') {{$t("GPT_KNOWLEDGE.LAST_UPDATED")}} :
                          v-list-item
                            v-list-item-avatar.ma-0.mr-2.knowledges-avatar-32(color='aliceBlue')
                              span.span(v-if="knoeledgeData.updated_by.usersetting.photo === ''") {{ knoeledgeData.updated_by.last_name }}
                              img(v-else :src="knoeledgeData.updated_by.usersetting.photo" :alt="knoeledgeData.updated_by.usersetting.last_name" )
                            v-list-item-content.pa-0.user_icon
                                span.t-gray(class='name ellipsis') {{knoeledgeData.updated_by.last_name+" "}}{{knoeledgeData.updated_by.first_name}}
                                span(class='small_word_gray ellipsis' ) {{getFormatTime(knoeledgeData.updated_on)}}
            v-col(cols='6')
              .knowledges-detail-subcard.pr-5
                v-card.rounded-card(flat style='height:100%;')
                  v-card-title.pa-0.pt-1.d-flex.align-center.justify-md-space-between
                    div.pa-2.d-flex.align-center(width="30px")
                      v-img.ml-2(id='' src="../assets/images/botsetting.png" class="white--text align-end"  width="40px")
                      h5.f-text-highlight-word.ellipsis.pl-3.pt-3 {{$t("GPT_KNOWLEDGE.SETTING")}}
                  .pa-0.pt-0.d-flex.justify-md-space-between
                    v-card-text.pt-0(style='padding-left:70px;')
                      v-icon mdi-power
                      span.pl-1 {{$t("GPT_KNOWLEDGE.ONOFF")}} :
                      label.ml-3.mr-3(class="switch")
                        input(v-model='knoeledgeData.active' type="checkbox" @click='openKnowledge(knoeledgeData)' :disabled='$isViewOnly.isViewOnly')
                        div(class="slider round")
                      .pt-3
                        v-icon(size='18').pb-1 mdi-share-variant
                        span.pl-2.mt-1 {{$t("GPT_KNOWLEDGE.PUBLIC")}} :
                        label.ml-3.mr-3(class="switch")
                          input(v-model='knoeledgeData.ispublic' type="checkbox" @click='isPublicKnowledge(knoeledgeData)' :disabled='$isViewOnly.isViewOnly')
                          div(class="slider round")
                      v-row.pt-3(no-gutters v-if='!loading && !isEditSub')
                          v-icon(size='16' style='padding-left:2px;padding-right:1px;') fas fa-robot
                          v-col(cols='auto' ref="nameWidth")
                              span.pl-1 {{$t("GPT_KNOWLEDGE.LINKBOT")}} : 
                              span(v-if='knoeledgeData.ispublic') {{$t("GPT_KNOWLEDGE.ISPUBLIC")}}
                              span(v-else) {{knoeledgeData.robots === null? $t("GPT_KNOWLEDGE.NO_LINKBOT"):getChatBotName()}}
                          v-col(cols='1' v-if='!knoeledgeData.ispublic')
                              v-btn.pb-1(height='15' icon text color='gary' @click='onEditSub' v-if='!isEditSub')
                                  v-icon(size='15') icon-edit
                      p(v-else).d-flex.align-center.knowledge-subcard-input
                        label.pr-4 {{$t("GPT_KNOWLEDGE.LINKBOT")}} : 
                        v-autocomplete.no-border(v-model='knoeledgeData.robots' return-object multiple solo :readonly='!isEditSub' :class='!isEditSub ? "remove-arrow" : ""' :items='chatBotList' item-text='bot_name' item-value='id'  :menu-props='{closeOnClick:true}')
                            template(v-slot:append-outer)
                                v-icon.mr-1(size='18' color="green" v-if='isEditSub' @click='confirmEditDetailSub()') icon-check
                                v-icon(size='18' color="red" v-if='isEditSub' @click='cancelEditDetailSub()') mdi-window-close
                                v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                    v-card-text(style='').pa-0
                      p
                        v-icon(size='22') mdi-broom
                        span.pl-1 {{$t("GPT_KNOWLEDGE.CLEAR_ALL")}} :
                        label.mr-3(class="switch")
                          v-btn.btn_green.ml-3(:disabled='$isViewOnly.isViewOnly' @click='resetData' width='' height='24' :ripple='false'  depressed dark )
                            span {{$t("GPT_KNOWLEDGE.CLEAR")}}
                      p
                        v-icon(size='16') icon-remove
                        span.pl-1 {{$t("GPT_KNOWLEDGE.DELETE")}} :
                        label.mr-3(class="switch")
                          v-btn.btn_red.ml-3(:disabled='$isViewOnly.isViewOnly' @click='onDelete' width='' height='24' :ripple='false'  depressed dark )
                            span {{$t("GENERAL.DELETE")}}
      #page-inner.pa-3.pt-5.d-flex.justify-center
          knowledgeTabs(ref='knowledgeTabs',:target_id='target_id', :knowledge_name='knoeledgeData.name' @emitreFresh='reFresh' @emitCount='reCount' @emitChangeTab='goRecord')

    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';
import knowledgeTabs from "../components/GPT_Common/KnowledgeTabs.vue";
import KnowledgeLibraryService from "../services/KnowledgeLibraryService";
import AiBotsService from "../services/AiBotsService";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    knowledgeTabs
  },
  data() {
    return {
      // other
      valid:true,
      isEdit:false,
      isEditSub:false,
      loading:false,
      delLoading:false,
      breadcrumbs: [{
              text: i18n.t('GPT_MENU.BACKTO') + i18n.t('GPT_KNOWLEDGE.TITLE'),
              disabled: false,
              href: '/upgpt/Knowledges',
          }
      ],
      formRule: {
          nameRules: [
              (v) => !!v || i18n.t('RULE.RULE_R'),
              (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
          ],
      },
      // Dialog
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      knoeledgeData:null,
      originKnowledgeData:null,
      chatBotList:[],
      chatBotName:"",
      target_id : this.$route.params.id,
      mode:null,
    };
  },
  async created() {
    this.loading = true;
    await this.getBotList();
    await this.getDetailData();
    this.loading = false;
  },
  destroyed() {
  },
  methods: {
    goRecord(){
      this.$router.replace('/upgpt/Uploadrecord');
    },
    set_title(){
        const title = localStorage.getItem('title');
        let title_name = this.knoeledgeData.name;
        let tab_name = '';
        tab_name = this.knoeledgeData.name;
        document.title = title+' : '+title_name +' – UpGPT';
        return tab_name;
    },
    async getDetailData(){
      await KnowledgeLibraryService.detail(this.target_id).then((response)=>{
          let data = response.data;
          data.robots = data.linked_bots_and_knowledge_set.map(item=> {return item.ai_bot_id});
          data.robots_count = data.linked_bots_and_knowledge_set.length;
          data.docs_count = data.knowledge_detial_set.length;
          this.knoeledgeData = response.data;
          this.set_title();
      }).catch(response => {
          // Redirect to PermissionError page if error status is 403
          if (response.response.status == 403)
          {
              this.$router.replace('/upgpt/PermissionError');
          }
          else if(response.response.status == 404)
          {
              this.$router.replace('/upgpt/PageNotFound');
          }
      });
    },
    async getBotList(){
      await AiBotsService.select_list().then((response)=>{
        this.chatBotList = response.data;
      })
    },
    async openKnowledge(data){
      if(this.$isViewOnly.isViewOnly) return;
      let edit_data = {
        "id" : data.id,
        "active" : !data.active
      }
      await KnowledgeLibraryService.edit(edit_data).then((response)=>{
        data.active = response.data.active;
      })
    },
    async isPublicKnowledge(data){
      if(this.$isViewOnly.isViewOnly) return;
      let edit_data = {
        "id" : data.id,
        "ispublic" : !data.ispublic
      }
      await KnowledgeLibraryService.edit(edit_data).then((response)=>{
        data.is_public = response.data.ispublic;
      })
    },
    onDelete(){
      this.mode = "delete";
      this.message = i18n.t("GPT_KNOWLEDGE.DEL_MSG");
      this.messageDialog = true;
    },
    resetData(){
      this.mode = "reset";
      this.message = i18n.t("GPT_KNOWLEDGE.CLEAR_MSG");
      this.messageDialog = true;
    },
    getChatBotName(){
      let names = this.knoeledgeData.robots.map(el=>{return el.bot_name}).join();
      return names
    },
    getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = new Date(data);
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }
    },
    onEdit(){
        this.isEdit = true;
        this.originKnowledgeData = JSON.parse(JSON.stringify(this.knoeledgeData));
    },
    async confirmEditDetail(){
      let edit_data = {
        "id" : this.knoeledgeData.id,
        "description" : this.knoeledgeData.description
      }
      await KnowledgeLibraryService.edit(edit_data).then((response)=>{
        this.knoeledgeData.description = response.data.description;
        this.knoeledgeData.updated_on = response.data.updated_on;
      })
      this.isEdit = false;
    },
    cancelEditDetail(){
      this.isEdit = false;
      this.knoeledgeData = this.originKnowledgeData;
    },
    onEditSub(){
        this.isEditSub = true;
        this.originKnowledgeData = JSON.parse(JSON.stringify(this.knoeledgeData));
    },
    async confirmEditDetailSub(){
      let edit_data = {
        "id" : this.knoeledgeData.id,
        "linked_ai_bot" : this.knoeledgeData.robots,
      }
      await KnowledgeLibraryService.set_detail_bots(edit_data).then((response)=>{
        this.knoeledgeData.robots = response.data.linked_ai_bot;
        this.knoeledgeData.updated_on = response.data.updated_on;
      })
      this.isEditSub = false;
    },
    cancelEditDetailSub(){
      this.isEditSub = false;
      this.knoeledgeData = this.originKnowledgeData;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    // Dialog Area ----------------------------------------- [Start]
    
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
      if(this.mode == 'delete'){
        this.mode = null;
        this.$router.push('/upgpt/Knowledges');
      }
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async onEmitMessageDialog(val) {
      if(val){
        this.delLoading = true;
        this.messageDialog = false;
        if(this.mode == "delete"){
          await KnowledgeLibraryService.delete(this.target_id).then(()=>{
            this.$router.push('/upgpt/Knowledges');
            this.delLoading = false;
            this.mode = null;
          }).catch(() => {
              this.showErrorDialog(i18n.t('GPT_KNOWLEDGE.DELETE_ERROR'));
              this.delLoading = false;
          });
        }else if(this.mode == "reset"){
          await KnowledgeLibraryService.clear_knowledge(this.target_id).then(()=>{
            this.$refs.knowledgeTabs.getKnowledgeInfo();
            this.delLoading = false;
            this.mode = null;
          }).catch(() => {
              this.showErrorDialog(i18n.t('GPT_KNOWLEDGE.CLEAR_ERROR'));
              this.delLoading = false;
              this.mode = null;
          });
        }
      }
      this.messageDialog = false;
    },
    async reFresh(){
      this.loading = true;
      await this.getDetailData();
      this.loading = false;
    },
    reCount(){
      this.knoeledgeData.docs_count = this.knoeledgeData.docs_count-1;
    }
  },
  watch:{
  }
};
</script>
import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const productLineItemLists = {
    order_status_list:[
        // {key:'1',name:i18n.t('PRODUCT_LINE_ITEM_LIST.1')},
        {key:'2',name:i18n.t('PRODUCT_LINE_ITEM_LIST.2')},
        {key:'3',name:i18n.t('PRODUCT_LINE_ITEM_LIST.6')},
        {key:'4',name:i18n.t('PRODUCT_LINE_ITEM_LIST.7')},
        {key:'5',name:i18n.t('PRODUCT_LINE_ITEM_LIST.9')},
        {key:'6',name:i18n.t('PRODUCT_LINE_ITEM_LIST.10')},
        {key:'7',name:i18n.t('PRODUCT_LINE_ITEM_LIST.11')},
        {key:'8',name:i18n.t('PRODUCT_LINE_ITEM_LIST.12')},
        {key:'9',name:i18n.t('PRODUCT_LINE_ITEM_LIST.19')},
        {key:'10',name:i18n.t('PRODUCT_LINE_ITEM_LIST.41')},
        {key:'11',name:"Azure"},
    ],
    billing_frequency_list:[
        {key:'1',name:i18n.t('PRODUCT_LINE_ITEM_LIST.13')},
        {key:'2',name:i18n.t('PRODUCT_LINE_ITEM_LIST.14')},
        {key:'3',name:i18n.t('PRODUCT_LINE_ITEM_LIST.15')},
        {key:'4',name:i18n.t('PRODUCT_LINE_ITEM_LIST.16')},
        {key:'5',name:i18n.t('PRODUCT_LINE_ITEM_LIST.17')},
        {key:'6',name:i18n.t('PRODUCT_LINE_ITEM_LIST.18')},
        {key:'7',name:i18n.t('PRODUCT_LINE_ITEM_LIST.19')}
    ],
    sla_list:[
        {key:'1',name:'5*8'},
        {key:'2',name:'5*8*4'},
        {key:'3',name:'7*24'},
        {key:'4',name:i18n.t('PRODUCT_LINE_ITEM_LIST.20')}
    ],
    service_plan_list:[
        {key:'1',name:i18n.t('PRODUCT_LINE_ITEM_LIST.21')},
        {key:'2',name:i18n.t('PRODUCT_LINE_ITEM_LIST.22')},
        {key:'3',name:i18n.t('PRODUCT_LINE_ITEM_LIST.23')},
        {key:'4',name:i18n.t('PRODUCT_LINE_ITEM_LIST.24')},
        {key:'5',name:i18n.t('PRODUCT_LINE_ITEM_LIST.25')}
    ],
    service_vendor_list:[
        {key:'1',name:i18n.t('PRODUCT_LINE_ITEM_LIST.26')},
        {key:'2',name:i18n.t('PRODUCT_LINE_ITEM_LIST.27')},
        {key:'3',name:i18n.t('PRODUCT_LINE_ITEM_LIST.28')}
    ],
    status_list:[
        {key:'1',name:i18n.t('PRODUCT_LINE_ITEM_LIST.33')},
        {key:'2',name:i18n.t('PRODUCT_LINE_ITEM_LIST.34')},
        {key:'3',name:i18n.t('PRODUCT_LINE_ITEM_LIST.35')},
        {key:'4',name:i18n.t('PRODUCT_LINE_ITEM_LIST.36')},
        {key:'5',name:i18n.t('PRODUCT_LINE_ITEM_LIST.37')},
        {key:'6',name:i18n.t('PRODUCT_LINE_ITEM_LIST.38')},
        {key:'7',name:i18n.t('PRODUCT_LINE_ITEM_LIST.39')},
        {key:'8',name:i18n.t('PRODUCT_LINE_ITEM_LIST.40')},
    ],
    invoice_month_list:[
        { key: "1", name: "1"},
        { key: "2", name: "2"},
        { key: "3", name: "3"},
        { key: "4", name: "4"},
        { key: "5", name: "5"},
        { key: "6", name: "6"},
        { key: "7", name: "7"},
        { key: "8", name: "8"},
        { key: "9", name: "9"},
        { key: "10", name: "10"},
        { key: "11", name: "11"},
        { key: "12", name: "12"},
    ],
    // wiadvance
    wiadvance_service_plan_list:[
        {key:'1',name:i18n.t('PRODUCT_LINE_ITEM_LIST.21')},
        {key:'2',name:i18n.t('PRODUCT_LINE_ITEM_LIST.22')},
        {key:'3',name:i18n.t('PRODUCT_LINE_ITEM_LIST.23')},
        {key:'4',name:i18n.t('PRODUCT_LINE_ITEM_LIST.24')},
        {key:'5',name:i18n.t('PRODUCT_LINE_ITEM_LIST.25')},
        {key:'6',name:i18n.t('PRODUCT_LINE_ITEM_LIST.29')}
    ],
    wiadvance_service_vendor_list:[
        {key:'1',name:i18n.t('PRODUCT_LINE_ITEM_LIST.30')},
        {key:'2',name:i18n.t('PRODUCT_LINE_ITEM_LIST.31')},
        {key:'3',name:i18n.t('PRODUCT_LINE_ITEM_LIST.32')}
    ],
}

export default productLineItemLists;
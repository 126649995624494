<template lang='pug'>
    div#productLineItem
        v-overlay(v-show='confirm_loading' opacity='0' z-index='999')
        div(v-if='!loading')
            .button-section 
                v-row(v-if='isWiadvance()')
                    template(v-if='is_staff || (oppty && !is_staff)')
                        v-col(cols='3' v-if='oppty')
                            v-radio-group(v-model='tax_option' row mandatory dense @change='toggleTaxType' v-hide-element)
                                template(v-slot:label)
                                    strong {{$t('OPPTYSFIELDLIST.tax_option')}}
                                v-radio(:label=`$t('PRODUCTLINEITEM.TAX_EXCLUSIVE')` value='TAX_EXCLUSIVE')
                                v-radio(:label=`$t('PRODUCTLINEITEM.NO_TAX')` value='NO_TAX')
                        v-col(cols='5' v-if='oppty')
                            v-radio-group(v-model='amount_type' row mandatory dense v-hide-element)
                                template(v-slot:label)
                                    strong {{$t(amountName)}}{{$t('ORDER.AMOUNT')}}
                                v-radio(:label=`$t('PRODUCTLINEITEM.MANUAL_INPUT')` value='manual_input')
                                v-radio(:label=`$t('PRODUCTLINEITEM.SYSTEM_COMPUTE')` value='system_compute')
                        v-col(:cols="oppty?'4':'12'" class='d-flex justify-end align-center' v-if='can_edit')
                            v-btn(:ripple="false" @click="onCancel" height="40" width='80' color="cancel" depressed dark class='mr-4') {{$t('GENERAL.CANCEL')}}
                            v-btn(:ripple="false" @click="onConfirm" height="40" width='80' color="green" depressed dark :readonly='confirm_loading')
                                v-progress-circular(size='18' v-if='confirm_loading' indeterminate )
                                .t-white(v-else) {{$t('GENERAL.SAVE')}}
                v-row(v-else)
                    template(v-if='!isTechUser')
                        v-col(cols='3')
                            v-radio-group(v-model='tax_option' row mandatory dense @change='toggleTaxType' v-hide-element)
                                template(v-slot:label)
                                    strong {{$t('OPPTYSFIELDLIST.tax_option')}}
                                v-radio(:label=`$t('PRODUCTLINEITEM.TAX_EXCLUSIVE')` value='TAX_EXCLUSIVE')
                                v-radio(:label=`$t('PRODUCTLINEITEM.NO_TAX')` value='NO_TAX')
                        v-col(cols='5')
                            v-radio-group(v-model='amount_type' row mandatory dense v-hide-element)
                                template(v-slot:label)
                                    strong {{$t(amountName)}}{{$t('ORDER.AMOUNT')}}
                                v-radio(:label=`$t('PRODUCTLINEITEM.MANUAL_INPUT')` value='manual_input')
                                v-radio(:label=`$t('PRODUCTLINEITEM.SYSTEM_COMPUTE')` value='system_compute')
                        v-col(cols='4' class='d-flex justify-end align-center' v-if='can_edit')
                            v-btn(:ripple="false" @click="onCancel" height="40" width='80' color="cancel" depressed dark class='mr-4') {{$t('GENERAL.CANCEL')}}
                            v-btn(:ripple="false" @click="onConfirm" height="40" width='80' color="green" depressed dark :readonly='confirm_loading')
                                v-progress-circular(size='18' v-if='confirm_loading' indeterminate )
                                .t-white(v-else) {{$t('GENERAL.SAVE')}}
                v-row
                    v-row.justify-sapce-between.mr-3.mt-3(no-gutters='no-gutters')
                        v-col(cols='auto')
                            h1 {{$t(isWiadvance()? 'ORDER_TYPE.LICENSE_CONTRACT':'PRODUCTSETTING.LP')}}
                        v-spacer
                        v-col(cols='auto' v-if="isWiadvance()")
                            v-btn(v-if='is_staff || (oppty && !is_staff)' @click='onAddProduct("licenseLine")' width='120' height='32' color='green' plain border-0)
                                v-icon.mr-1(size='14') icon-add
                                span {{$t('PRODUCTSETTING.ADD_PRODUCT')}}
                        v-col(cols='auto' v-else)
                            v-btn(v-if='!isTechUser' @click='onAddProduct("licenseLine")' width='120' height='32' color='green' plain border-0)
                                v-icon.mr-1(size='14') icon-add
                                span {{$t('PRODUCTSETTING.ADD_PRODUCT')}}
                    wiadvanceServiceOrLicenseProductLineItem(v-if='isWiadvance()' ref='licenseLine' service_or_license='license' :user_list='user_list' :technician_list='technician_list' :product_type_list='product_type_list' :end_account_list='end_account_list' :target_class='target_class',:target_id='target_id',:tax_option='tax_option',:baseHeaders='headers' :can_edit='can_edit' :order_org_data='order_org_data')
                    serviceOrLicenseProductLineItem(v-else ref='licenseLine' service_or_license='license' :user_list='user_list' :technician_list='technician_list' :product_type_list='product_type_list' :end_account_list='end_account_list' :target_class='target_class',:target_id='target_id',:tax_option='tax_option',:baseHeaders='headers' :can_edit='can_edit')
                    v-row.justify-sapce-between.mr-3.mt-3(no-gutters='no-gutters')
                        v-col(cols='auto')
                            h1 {{$t(isWiadvance()? 'ORDER_TYPE.SERVICE_CONTRACT':'PRODUCTSETTING.SP')}}
                        v-spacer
                        v-col(cols='auto' v-if="isWiadvance()")
                            v-btn(v-if='is_staff || (oppty && !is_staff)' @click='onAddProduct("serviceLine")' width='120' height='32' color='green' plain border-0)
                                v-icon.mr-1(size='14') icon-add
                                span {{$t('PRODUCTSETTING.ADD_PRODUCT')}}
                        v-col(cols='auto' v-else)
                            v-btn(v-if='!isTechUser' @click='onAddProduct("serviceLine")' width='120' height='32' color='green' plain border-0)
                                v-icon.mr-1(size='14') icon-add
                                span {{$t('PRODUCTSETTING.ADD_PRODUCT')}}
                    wiadvanceServiceOrLicenseProductLineItem(v-if='isWiadvance()' ref='serviceLine' service_or_license='service' :user_list='user_list' :technician_list='technician_list' :product_type_list='product_type_list' :end_account_list='end_account_list' :target_class='target_class',:target_id='target_id',:tax_option='tax_option',:baseHeaders='headers' :can_edit='can_edit' :order_org_data='order_org_data')
                    serviceOrLicenseProductLineItem(v-else ref='serviceLine' service_or_license='service' :user_list='user_list' :technician_list='technician_list' :product_type_list='product_type_list' :end_account_list='end_account_list' :target_class='target_class',:target_id='target_id',:tax_option='tax_option',:baseHeaders='headers' :can_edit='can_edit')

</template>
<script>
import Vue from "vue";
import messageDialog from '@/components/Dialog/messageDialog';
import i18n from '/common/plugins/vue-i18n.js';
import serviceOrLicenseProductLineItem from "@/components/Common/serviceOrLicenseProductLineItem";
import wiadvanceServiceOrLicenseProductLineItem from "@/views-wiadvance/wiadvanceServiceOrLicenseProductLineItem";
import OrganizationDataService from "@/services/OrganizationDataService";
import ProductDataService from "@/services/ProductDataService";
import EndCustomerDataService from "@/services/EndCustomerDataService";  

export default Vue.extend({ 
    props: {
        oppty:{
            type: Object,
            require: false,
        },
        order:{
            type: Object,
            require: false,
        },
    },
    components: {
        messageDialog,
        serviceOrLicenseProductLineItem,
        wiadvanceServiceOrLicenseProductLineItem
    },
    data() {
        return {
            amountName:"",
            can_edit:true,
            confirm_loading:false,
            loading:false,
            tax_option: 'TAX_EXCLUSIVE',
            amount_type: 'system_compute',
            target_class:'oppty',
            target_id:null,
            headers: [
                {  text: i18n.t('PRODUCTSETTING.PRICR'), value: 'price', align: 'center', },
                {  text: i18n.t('PRODUCTLINEITEM.OTHER_CHARGES')+" (%)", value: 'other_charges', align: 'center'},
                {  text: i18n.t('PRODUCTLINEITEM.DISCOUNT_H')+" (%)", value: 'discount', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.TAX_H')+" (%)", value: 'tax', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.AMOUNT_H'), value: 'net_amount', align: 'center',},
                {  text: i18n.t('GENERAL.ACTIVE'), value: 'active', align: 'center', sortable: false, width: "60",},
            ],
            user_list:[],
            technician_list:[],
            product_type_list:[],
            end_account_list:[],
            isTechUser:false,
            is_staff:false,
            order_org_data: null,
        }
    },
    async created(){
        this.loading = true;
        await OrganizationDataService.getchangeowner()
        .then((respose)=>{
            this.user_list = respose.data;
            this.user_list.forEach(item => {
                item.fullName = item.last_name + ' ' + item.first_name;
            });
        });
        await OrganizationDataService.getchangeowner('tech')
        .then((respose)=>{
            this.technician_list = respose.data;
            this.technician_list.forEach(item => {
                item.fullName = item.last_name + ' ' + item.first_name;
            });
        });
        await ProductDataService.productcategorylist()
        .then(response => 
        {  
            this.product_type_list = response.data;
            this.product_type_list.forEach(el => el.id = parseInt(el.id))
        });
        let org_id = null;
        if(this.oppty){
            org_id = this.oppty.oppty_org? this.oppty.oppty_org.id:null;
        }else{
            org_id = this.order.organization? this.order.organization.id:null;
        }
        if(org_id){
            await EndCustomerDataService.selectList(org_id)
            .then(response => 
            {  
                this.end_account_list = response.data;
                this.end_account_list.forEach(el => el.id = parseInt(el.id))
            });
        }
        
        this.table_style = "padding-bottom: 45px;min-width: 760px;"
        if(this.oppty){
            this.target_id = this.oppty.id;
            this.tax_option = JSON.parse(JSON.stringify(this.oppty.tax_option));
            this.toggleTaxType();
            this.loading = false;
            if(this.oppty.closed_reason != null && this.oppty.closed_reason != 0){
                this.can_edit = false;
            }
           this.amountName = 'BREADCRUMBS.Deals'
        }
        if(this.order){
            this.target_id = this.order.id;
            this.order_org_data = this.order.organization;
            this.target_class = 'order';
            this.tax_option = JSON.parse(JSON.stringify(this.order.tax_option));
            this.toggleTaxType();
            this.loading = false;
            this.amountName = this.isWiadvance() ? 'ORDER.WIADVANCE_TITLE' : 'ORDER.TITLE'
        }
        this.amount_type=this.isWiadvance() ? "manual_input" : "system_compute"
        this.checkTechUser();
    },
    methods: {
        checkTechUser(){
            let user = JSON.parse(window.localStorage.getItem('user'));
            if(user){
                this.isTechUser = JSON.parse(window.localStorage.getItem('user'))['is_tech_user'];
                this.is_staff = JSON.parse(window.localStorage.getItem('user'))['is_staff'];
            }
        },
        onAddProduct(val){
            this.$refs[val].onCreate();
        },
        getTotalWithDays(){
            const initialValue = 0;
            this.total_days = this.productList.reduce((a,b)=> {
                if(b.from_date && b.to_date){
                    return a + this.DateDiff(b.from_date,b.to_date)*b.quantity;
                }
                return a + parseInt(b.quantity);
            }
            ,initialValue)
        },
        async onCancel(){
            this.$refs.serviceLine.onCancel();
            this.$refs.licenseLine.onCancel();
        },
        async onConfirm(){
            if(this.$refs.serviceLine.validate() && this.$refs.licenseLine.validate()){
                this.confirm_loading = true;
                let all = 0;
                this.$refs.serviceLine.onConfirm().then((service_res)=>{
                    all += Number(service_res.toString().replaceAll(',',''));
                    this.$refs.licenseLine.onConfirm()
                    .then((license_res)=>{
                        all += Number(Number(license_res.toString().replaceAll(',','')));
                        this.$emit("emit_fromProdcutLine",this.amount_type,this.tax_option,all.toFixed(2));
                    }).finally(()=>{
                        this.confirm_loading = false;
                    });
                });
            }
        },
        toggleTaxType(){
            if(this.tax_option == 'TAX_EXCLUSIVE'){
                let taxHeader = this.headers.find(e => e.value == 'tax');
                const newTaxHeader= {  
                    text: i18n.t('PRODUCTLINEITEM.TAX') + ' (%)', 
                    value: 'tax',
                    align: 'center',
                }

                if(taxHeader == undefined){
                    this.headers.splice(-2, 0, newTaxHeader)
                }
            }
            else if(this.tax_option == 'NO_TAX'){
                this.headers = this.headers.filter(e => e.value != 'tax');
            }
        },
        toggleFromTo(){
            let show = false;
            for(let i in this.productList){
                if(this.productList[i].enable_trq){
                    show = true;
                }
                if(show){
                    break;
                }
            }
            if(show){
                this.headers = this.headers_trq;
                this.table_style = "padding-bottom: 45px;min-width: 1120px;"
            }else{
                this.headers = this.headers_no_trq;
                this.table_style = "padding-bottom: 45px;min-width: 760px;"
            }
            this.toggleTaxType()
        },
        checkWiadvanceRequiredField(){
            let service = this.$refs.serviceLine.checkWiadvanceRequiredField();
            let license = this.$refs.licenseLine.checkWiadvanceRequiredField();
            if(service==null && license==null){
                return false
            }
            if(service==false || license==false){
                return false
            }
            return true
        }
    },
    directives: {
    },
    watch:{}
})
</script>

<style lang="scss">
.text-black input{
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
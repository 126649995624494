<template lang="pug">
// 新增聯絡人
v-card
  v-card-title.d-flex.justify-center {{$t('ADD_CONTACT.TITLE')}}
  v-card-text
    v-form(
      v-model="valid",
      ref="form",
    )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.mb-3(
            v-model="addContact.last_name",
            :label= `$t('ADD_CONTACT.LAST_NAME')`,
            :rules="formRule.nameRules",
            validate-on-blur,
            required="required",
            large="large",
          )
        v-col(cols="6")
          v-text-field.mb-3(
            v-model="addContact.first_name",
            :label=`$t('ADD_CONTACT.FIRST_NAME')`,
            large="large",
            :rules="formRule.contentLenRule50",
          )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.mb-3(
            v-model="addContact.english_last_name",
            :label= `$t('ADD_CONTACT.ENG_LAST_NAME')`,
            :rules="formRule.contentLenRule100",
            validate-on-blur,
            required="required",
            large="large",
          )
        v-col(cols="6")
          v-text-field.mb-3(
            v-model="addContact.english_first_name",
            :label=`$t('ADD_CONTACT.ENG_FIRST_NAME')`,
            large="large",
            :rules="formRule.contentLenRule100",
          )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.title",
            :label= `$t('ADD_CONTACT.CN_TITLE')`,
            :rules="formRule.contentLenRule50X",
            validate-on-blur,
            prepend-icon="mdi-briefcase",
            required="required",
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.english_title",
            :label=`$t('ADD_CONTACT.ENG_TITLE')`,
            :rules="formRule.contentLenRule100",
            validate-on-blur,
            prepend-icon="mdi-briefcase",
            required="required",
          )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.department",
            :label= `$t('ADD_CONTACT.DEPARTMENT')`,
            :rules="formRule.contentLenRule50X",
            validate-on-blur,
            prepend-icon="mdi-briefcase",
            required="required",
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.english_department",
            :label=`$t('ADD_CONTACT.ENG_DEPARTMENT')`,
            :rules="formRule.contentLenRule100",
            validate-on-blur,
            prepend-icon="mdi-briefcase",
            required="required",
          )
      v-text-field.no-border(
        v-model="addContact.email",
        prepend-icon="mdi-email",
        :label= `$t('GENERAL.MAIL')`,
        :rules="formRule.emailRules",
        required="required",
        validate-on-blur,
      )
      v-row(no-gutters="no-gutters")
        v-col(cols="6").mt-1.pt-3
          v-row(no-gutters="no-gutters")
            v-col(cols="2")
              v-icon(size='20') mdi-cellphone
            v-col
              vue-tel-input-vuetify.no-border(
                v-model="addContact.mobile_phone",
                solo,
                :label=`$t('ADD_CONTACT.MOBILE_PHONE')`,
                :selectLabel=`$t('ADD_CONTACT.SELECT_LABEL')`,
                placeholder='',
                autocomplete='off',
                :defaultCountry='getLocation()',
                disabledFetchingCountry=true,
                mode='international',
                @validate="onPhoneCheck($event)",
                @input="onPhoneInput",
                :rules="formRule.phoneRules",
                )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.office_phone",
            :rules="formRule.contentLenRule50",
            prepend-icon="mdi-phone",
            :label=`$t('ADD_CONTACT.OFFICE_PHONE')`,
          )
      v-select.no-border(
        v-model='addContact.level'
        :items='levelList' 
        item-text='name'
        item-value='key'
        prepend-icon='mdi-domain' 
        :label=`$t('ADD_CONTACT.LEVEL')`,
      )
      v-combobox.no-border(
        v-model="addContact_organization_set",
        :items="organizationList",
        item-text="name",
        item-value="uqNumber",
        :search-input.sync="search",
        :loading="isLoading",
        prepend-icon="mdi-domain",
        :label=`$t('ADD_CONTACT.ORG_NAME')`,
        validate-on-blur,
        required="required",
        :rules="formRule.companyRule",
      )
        template(v-slot:append-outer)
          div(v-if="isNew")
            v-icon(color="red", size="25") mdi-new-box
        template(v-slot:item="data")
            div.ellipsis(v-if="data.item.id == 0")
              span {{ data.item.name }}
              v-icon(color="red", size="25") mdi-new-box
            div.ellipsis(v-else)
              span {{ data.item.name }}
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.country",
            :label= `$t('CONTACT_DETAIL.COUNTRY')`,
            :rules="formRule.contentLenRule20X",
            validate-on-blur,
            required="required",
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.zip_code",
            :label=`$t('CONTACT_DETAIL.ZIP_CODE')`,
            :rules="formRule.contentLenRule10X",
            validate-on-blur,
            required="required",
          )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.city",
            :label= `$t('CONTACT_DETAIL.CITY')`,
            :rules="formRule.contentLenRule50X",
            validate-on-blur,
            required="required",
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.billing_address",
            :label=`$t('CONTACT_DETAIL.ADDRESS')`,
            :rules="formRule.contentLenRule50X",
            validate-on-blur,
            required="required",
          )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.facebook",
            prepend-icon="mdi-facebook",
            :rules="formRule.fbRules",
            label="Facebook"
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.instagram_id",
            prepend-icon="mdi-instagram",
            :rules="formRule.contentLenRule50",
            label="Instagram"
          )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.line_id",
            prepend-icon="fab fa-line",
            :rules="formRule.contentLenRule50",
            label="Line ID"
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="addContact.linkedin",
            prepend-icon="mdi-linkedin",
            :rules="formRule.linkRules",
            label="LinkedIn"
          )
      v-file-input.no-border(
        v-model="contactImage",
        prepend-icon="mdi-image",
        :label=`$t('ADD_CONTACT.IMAGE')`,
        accept="image/*",
        @change='onChangePhoto',
        :rules="formRule.photoRule"
      )
      v-window
        customFieldRequired(ref='CFRequired' DataClass='Contact' :editData='this.addContact.custom_field'  :StageTypeId='null' ).mt-3
        
  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="success",
          depressed,
          dark
        ) 
          v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
          .t-white(v-else) {{$t('GENERAL.ADD')}}
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
    error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import OrganizationDataService from "../../../services/OrganizationDataService";
import ContactDataService from "../../../services/ContactDataService";
import checkContactEmailRepeat from "@/views-wiadvance/checkContactEmailRepeat";
import customFieldRequired from "@/components/Dialog/customFieldRequired";
import errorDialog from '@/components/Dialog/errorDialog';
import levelList from "@/array/the_levelList";
import i18n from '/common/plugins/vue-i18n.js';

export default Vue.extend({
  props: {
    addContact: {
      type: Object,
      required: true,
    },
  },
  components: {
      errorDialog,
      customFieldRequired,
  },
  data() {
    return {
      addBtn_loading:false,
      valid: true,
      isLoading: false,
      search: null,
      errorDialog: false,
      errorMessage: '',
      organizationList: [],
      addContact_organization_set: this.addContact.organization_set[0],
      contactImage: null,
      levelList: levelList,
      comboText: this.addContact.organization_set.length > 0 ? this.addContact.organization_set[0].name : "",
      formRule: {
        nameRules: [
          (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
        ],
        emailRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) ||  i18n.t('RULE.RULE_EMAIL'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
        ],
        phoneRules: [],
        linkRules: [
          (v) => /(http|https):\/\/\S*|(^$)|null/.test(v) || i18n.t('RULE.RULE_HTTP'),
          (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70'),
        ],
        fbRules: [
          (v) => /(http|https):\/\/\S*|(^$)|null/.test(v) || i18n.t('RULE.RULE_HTTP'),
          (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70'),
        ],
        contentLenRule100: [(v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100')],
        contentLenRule70: [(v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')],
        contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
        contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
        contentLenRule10: [(v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')],
        photoRule: [
          () => { return (!this.photoSize || i18n.t('RULE.RULE_FILE_2'))},
          () => { return (!this.fileType || i18n.t('RULE.RULE_PHOTO'))},
        ],
        companyRule: [
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
          (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
        ],
        contentLenRule10X: [
          (v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        contentLenRule20X: [
          (v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        contentLenRule50X: [
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        contentLenRule100X: [
          (v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
      },
      isNumVaild: true,
      vue_tel: {
        isValid: true,
      },
      photoSize: false,
      fileType: false,
      cfValid:true,
      hasCheck: false,
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    validateForm(){
      if(this.$refs.form.validate())
      {
        return true;
      }
      else
      {
        return false;
      }
    },   
    getResponsedata(){
      this.$refs.CFRequired.emitData();
      if(this.$refs.CFRequired.responsedata === false)
      {
        this.$refs.CFRequired.responsedata = null;
        this.cfValid = false;
        return;
      }
      this.addContact.custom_field = JSON.stringify(this.$refs.CFRequired.responsedata);
    },
    async onConfirm() {
      this.formRule.phoneRules.push(() => {return (this.isNumVaild || i18n.t('CONTACT_DETAIL.ERROR_3'))});
      if(this.validateForm() && this.addBtn_loading == false)
      {
        if((this.addContact.custom_field != null && this.addContact.custom_field !== undefined) || this.$refs.CFRequired.process_array.length != 0)
          this.getResponsedata();
        if(this.cfValid == false)
        {
          this.cfValid = true;
          return;
        }
        let contactDataOrg = this.addContact.organization_set;
        let org = this.addContact_organization_set;
        let newOrg = {id: "-1", name: this.comboText, company_number: "0"};
        
        if (contactDataOrg.length > 0 && this.comboText != "") 
        {
          if (contactDataOrg[0].id != org.id)
          { 
            contactDataOrg.splice(0, 1, org.id == undefined ? newOrg : org);
          }
          else if (org.name != this.comboText)
          {
            contactDataOrg.splice(0, 1, newOrg);
          }
        }
        else if (this.comboText != "")
        {
          if (org == undefined)
            contactDataOrg.push(newOrg);
          else if (org.name != this.comboText)
            contactDataOrg.push(newOrg);
          else
            contactDataOrg.push(org);
        }
        delete this.addContact.full_name;
        if (this.addContact.owner != undefined) 
        {
          delete this.addContact.owner.usersetting.photo;
        }
        this.addBtn_loading = true;
        let check_data = {
            "email": this.addContact.email,
        }
        if(this.isWiadvance() && !this.hasCheck && await checkContactEmailRepeat(check_data)){
            this.showErrorDialog(i18n.t('CONTACT.WIADVANCE_REPEAT_EMAIL'));
            this.hasCheck = true;
            this.addBtn_loading = false;
        }else{
          await ContactDataService.create(this.addContact).then(response => {
            if(this.addContact_organization_set)
            {
              if (this.addContact_organization_set.id == 0) 
              {
                if (response.data.organization_set.length != 0) 
                {
                  let principalData = {
                    "organization_id": response.data.organization_set[0].id,
                    "owner_id": response.data.owner.id,
                  };        
                  ContactDataService.createCompanyPrincipal(principalData);
                }
              }
            }
            this.addContact.id = response.data.id;
          });
          if (this.contactImage != null)
          {
            await ContactDataService.image(this.addContact.id, this.contactImage)
              .then(response => {
                this.addContact.image = response.data.image;
            });
          }
          this.hasCheck = false;
          this.addBtn_loading = false;
          this.$emit("emitAddContactDialog", true);
        }
      }
    },
    onCancel() {
      this.$emit("emitAddContactDialog", false);
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    onPhoneCheck(event) {
      this.vue_tel = event;
      if (this.vue_tel.isValid == true) 
      {
        this.isNumVaild = true;
      }
    },
    onPhoneInput() {
      if (this.isNumVaild == true) 
      {
        if (this.addContact.mobile_phone != this.vue_tel.number.input && this.addContact.mobile_phone != "") 
        {
          this.isNumVaild = false;
        }
      } 
      else 
      {
        if (this.addContact.mobile_phone == "") 
        {
          this.isNumVaild = true;
        }
      }
    },
    onChangePhoto(event) {
      if (event != null) 
      {
        // file type
        let n = event.type.indexOf("image");
        if (n == -1) 
        {
          this.fileType = true;
        } 
        else 
        {
          this.fileType = false;
        }
        // photo size
        if (event.size > 5242880) 
        {
          this.photoSize = true;
        } 
        else 
        {
          this.photoSize = false;
        }
      }
      else 
      {
        this.photoSize = false;
        this.fileType = false;
      }
    },
  },
  computed: {
    isNew: function(){
      if(this.addContact_organization_set == "" || this.addContact_organization_set == null)
      {
        return false;
      }
      if(typeof (this.addContact_organization_set) === 'object')
      {
        if(this.addContact_organization_set.id == 0)
        {
          return true;
        }
        else
        {
          return false;
        }
      }
      else
      {
        return true;
      }
    },
  },
  watch: {
    async search(val) {
      var re = /^(?!\s*$).+/;
      var check = re.test(val);
      var checkLength = /^.{0,200}$/.test(val);
      this.comboText = "";
      if(check && val != null && checkLength)
      {
        let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
        this.comboText = val;
        if(!this.isLoading && charNum>=2 && charNum==val.length )
        {
          this.isLoading = true;
          await OrganizationDataService.findOrganizationByNamefromDB(val)
          .then((response) => {
            this.organizationList = response.data;
            let num = 0;
            this.organizationList.forEach((item)=>{
              item.uqNumber = num;
              num ++;
              })
          })
          .finally(() => {
            this.isLoading = false;
          })
        }
        else 
        {
          this.isLoading = false;
        }
      }
    },
  },
});
</script>
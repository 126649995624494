import { authRequest } from './api/auth';

class ProductLineItemService {
    serviceList(target_class,target_id) {
        return authRequest.get(`/product_items/service/?target_class=${target_class}&target_id=${target_id}`);
    }
    serviceCreate(data) {
        return authRequest.post(`/product_items/service/`,data);
    }
    serviceEdit(data) {
        return authRequest.put(`/product_items/service/${data.id}/`,data);
    }   
    serviceDelete(id) {
        return authRequest.delete(`/product_items/service/${id}/`);
    } 
    licenseList(target_class,target_id) {
        return authRequest.get(`/product_items/license/?target_class=${target_class}&target_id=${target_id}`);
    }
    licenseCreate(data) {
        return authRequest.post(`/product_items/license/`,data);
    }
    licenseEdit(data) {
        return authRequest.put(`/product_items/license/${data.id}/`,data);
    }   
    licenseDelete(id) {
        return authRequest.delete(`/product_items/license/${id}/`);
    }
    change(data) {
        return authRequest.post(`/product_items/change/`,data);
    }

    getProductLineItem(id){
        return authRequest.get(`/product_items/order/${id}/products`);
    }
    updateProductLineItem(id, data){
        return authRequest.post(`/product_items/order/${id}/products/change`, data);
    }
    wiadvanceOpptyRequiredFieldCheck(data){
        return authRequest.post(`/product_items/wiadvancecheck/`, data);
    }
    wiadvanceSoApi(data){
        return authRequest.post(`/wiadvance/getsolines`, data);
    }
}

export default new ProductLineItemService();

<template lang='pug'>
  v-card

    v-card-text.pt-3
        v-form(v-model='valid' ref='form')
            v-row(no-gutters)  
                v-col(cols='12').px-2
                    v-textarea.v-text-field__slot.height-58(v-model='textData[textDataKey]' :title='textData' )
    v-card-actions.pb-5
      v-row(no-gutters).px-5
          v-col.pr-2(cols='6')
              v-btn(v-hide-element='false' :ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
          v-col.pl-2(cols='6')
              v-btn(v-hide-element='false' :ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.CONFIRM')}}
</template>

<script>

export default {
  components: {
  },
  props: {
    textData: {
      type: Object,
      required: true,
    },
    textDataKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
        // Dialog
        // Data
        valid:true,
    };
  },
  async created() {
  },
  destroyed() {
  },
  methods: {
    onCancel(){
      this.$emit('emitTextoDialog',false);
    },
    onConfirm(){
      this.$emit('emitTextoDialog',true);
    },
  },
  watch:{
  }
};
</script>
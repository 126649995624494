<template lang='pug'>
    div
        v-row.sheet-box.my-5(v-if='endcustomersList.length == 0')
            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    h5.ma-0.t-black {{$t('END_CUSTOMER.NO_DATA')}}
        .case-detail-table.scroll-x.pb-0(v-else)
            .data-area(style='position: relative;')
                v-data-table.height_100.table_in_tab( :headers='endcustomerHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" item-key='id' :items='endcustomersitems' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' multi-sort height='100%' hide-default-footer='hide-default-footer' :loading='isLoading' loading-text='loading...' style='padding-bottom: 45px')
                    template(v-for='e, index in endcustomerHeaders' v-slot:[`item.${e.value}`]='{ item }')
                        span(v-if='e.format == "opt"' class='ellipsis' :title='getOptValueFromList(e.option, item[`${e.value}`])') {{ getOptValueFromList(e.option, item[`${e.value}`]) }}
                        a.pl-2(v-else-if='e.format == "fil" && item[`${e.value}`] != null' href="#" @click='openFile(item[`${e.value}`])') {{ '已接受' }}
                        span(v-else-if='e.format == "fil" && item[`${e.value}`] == null') {{ '尚未接受' }}
                        div(v-else-if='e.format == "act"')
                            v-btn(icon @click='showEditEndCustomerDialog(item)' :disabled='!checkWiadvanceShow()')
                                v-icon(size='18') icon-edit
                            v-btn(icon @click='onDelete(item)' :disabled='!checkWiadvanceShow()')
                                v-icon(size='18') icon-remove
                        span(v-else class='ellipsis' :title='item[`${e.value}`]') {{ item[`${e.value}`] }}
                v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10" style='position: absolute; bottom: 0; width: 100%; height: 40px')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='addEndCustomerDialog' width='500' content-class='statusDialog' persistent v-if='addEndCustomerDialog')
            addEndCustomerDialog(:type_key='type_key' :organization_id='organization_id' :field_definition='field_definition_thistime' @emitAddDialog='onEmitAddDialog' )
        v-dialog(v-model='editEndCustomerDialog' width='500' content-class='statusDialog' persistent v-if='editEndCustomerDialog')
            editEndCustomerDialog(:type_key='type_key' :organization_id='organization_id' :field_definition='field_definition_thistime' :editData='editEndCustomer' @emitEditDialog='onEmitEditDialog')
</template>
<script>
import i18n from '/common/plugins/vue-i18n.js';
import EndCustomerDataService from '@/services/EndCustomerDataService';
import addEndCustomerDialog from '@/components/Dialog/EndCustomer/addEndCustomerDialog';
import editEndCustomerDialog from '@/components/Dialog/EndCustomer/editEndCustomerDialog';
import messageDialog from '@/components/Dialog/messageDialog';

export default { 
    props: {
        organization_id:{
            type: String,
            require: true,
        },
        type_key:{
            type: String,
            require: true,
        },
        end_customer_definitions:{
            type: Array,
            require: true,
        },
        addEndCustomerDialog:{
            type: Boolean,
            require: true,
        },
    },
    components: {
        addEndCustomerDialog,
        editEndCustomerDialog,
        messageDialog,
    },
    data() {
        return {
            is_staff: false,
            isLoading: true,
            endcustomersList:[],
            endcustomerHeaders: [],
            field_definition_thistime: [],
            endcustomersitems:[],
            itemPerPage: this.itemInPage ? this.itemInPage : 10,
            page: 1,
            pageCount: 0,
            msgDialog_mode:'',
            deleteItem:null,
            message:'',
            messageDialog:false,
            editEndCustomerDialog:false,
            oringalData:null,
            editEndCustomer:null,
        }
    },
    async created(){
        await this.get_end_customer_list();
        await (this.isLoading = false);
        this.checkTechUser();
    },
    methods: {
        getOptValueFromList: function (itemList, key) {
            var l = itemList.find(x => x.key == key);
            return l ? l.value : '';
        },
        get_end_customer_list(){
            // 取得對應標題
            let width_percent = '';
            for(let d in this.end_customer_definitions){
                if (this.end_customer_definitions[d].key == this.type_key){
                    width_percent = (100/(this.end_customer_definitions[d].field.length)) + '%';
                }
            }
            this.endcustomerHeaders = [];
            this.endcustomerHeaders.push({ text: i18n.t('END_CUSTOMER.NAME'), value: 'name', align: 'center', format: 'str', width: width_percent, sortable: true });
            for(let d in this.end_customer_definitions){
                if (this.end_customer_definitions[d].key == this.type_key){
                    this.field_definition_thistime = this.end_customer_definitions[d].field;
                    for(let f in this.end_customer_definitions[d].field){
                        let temp_data = {
                            text: this.end_customer_definitions[d].field[f].field_name,
                            value: this.end_customer_definitions[d].field[f].field_key,
                            align: 'center', 
                            format: this.end_customer_definitions[d].field[f].format,
                            width: width_percent
                        };
                        if(this.end_customer_definitions[d].field[f].format == 'opt'){
                            temp_data['option'] = this.end_customer_definitions[d].field[f].option;
                        }
                        this.endcustomerHeaders.push(temp_data);
                    }
                }
            }
            this.endcustomerHeaders.push({ text: i18n.t('GENERAL.ACTIVE'), value: 'active', align: 'center', format: 'act', width: '130', sortable: false });
            // 取得對應資料
            let data = {
                "organization_id":this.organization_id,
                "type":this.type_key
            }
            EndCustomerDataService.getEndCustomersData(data).then((response)=>{
                this.endcustomersList = response.data;
                this.endcustomersitems = [];
                for(let e in this.endcustomersList){
                    let temp_data = {};
                    for(let h in this.endcustomerHeaders){
                        temp_data[this.endcustomerHeaders[h].value] = this.endcustomersList[e].detail_field[this.endcustomerHeaders[h].value];
                    }
                    temp_data['id'] = this.endcustomersList[e].id;
                    temp_data['name'] = this.endcustomersList[e].name;
                    this.endcustomersitems.push(temp_data);
                }
            });
        },
        onDelete (item) {
            this.deleteItem = item;
            this.msgDialog_mode = 'delete';
            this.showMessageDialog(i18n.t('END_CUSTOMER.DEL'));
        },
        onEmitAddDialog(val,data){
            if(val){
                let temp_data = {};
                for(let h in this.endcustomerHeaders){
                    temp_data[this.endcustomerHeaders[h].value] = data.detail_field[this.endcustomerHeaders[h].value];
                }
                temp_data['id'] = data.id;
                temp_data['name'] = data.name;
                this.endcustomersitems.push(temp_data);
                this.endcustomersList.push(data);
            }
            this.$emit('emitEndCustomerDialog',false);
        },
        showEditEndCustomerDialog(item){
            this.editEndCustomer = item;
            this.editEndCustomerDialog = true;
            this.oringalData = JSON.parse(JSON.stringify(item));
        },
        onEmitEditDialog(data){
            if(data == true){
                data = this.oringalData;
                let temp_data = {};
                let temp_detail_field = {};
                for(let fd in this.field_definition_thistime){
                    temp_detail_field[this.field_definition_thistime[fd].field_key] = data[this.field_definition_thistime[fd].field_key];
                }
                temp_data['id'] = data.id;
                temp_data['name'] = data.name;
                temp_data['detail_field'] = temp_detail_field;
                data = temp_data;
            }
            if(data)
            {
                let editItem = this.endcustomersitems.find(el=>{ return el.id == this.editEndCustomer.id});
                for(let fd in this.field_definition_thistime){
                    editItem[this.field_definition_thistime[fd].field_key] = data.detail_field[this.field_definition_thistime[fd].field_key];
                }
                editItem.name = data.name;
                
                let editlist = this.endcustomersList.find(el=>{ return el.id == this.editEndCustomer.id});
                editlist.name = data.name;
                editlist.detail_field = data.detail_field;
            }
            this.editEndCustomerDialog = false;
            this.editEndCustomer = null;
            this.oringalData = null;
        },
        openFile(url){
            window.open(url);
        },
        showMessageDialog(message) {
            this.messageDialog = true;
            this.message = message;
        },
        onEmitMessageDialog(val) {
            this.messageDialog = false;
            if (val) { 
                if(this.msgDialog_mode == 'delete'){
                    EndCustomerDataService.delete(this.deleteItem.id).then().finally(()=>{
                        let list_index = '';
                        for(let e in this.endcustomersList){
                            if(this.endcustomersList[e].id == this.deleteItem.id){
                                list_index =  this.endcustomersList.indexOf(e);
                                break;
                            }
                        }
                        this.endcustomersList.splice(list_index, 1);
                        let item_index =  this.endcustomersitems.indexOf(this.deleteItem);
                        this.endcustomersitems.splice(item_index, 1);
                        this.deleteItem = null;
                        this.msgDialog_mode = '';
                    })
                }
            }
        },
        checkTechUser(){
            let user = JSON.parse(window.localStorage.getItem('user'));
            if(user){
                this.is_staff = JSON.parse(window.localStorage.getItem('user'))['is_staff'];
            }
        },
        checkWiadvanceShow(){
            if(this.isWiadvance()){
                return this.is_staff;
            }else{
                return true
            }
        },
    },
    watch:{
        "type_key": function(){
            this.get_end_customer_list();
        },
    }
}
</script>
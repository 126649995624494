<template lang="pug">
#DataTableSetting.py-4.addTableDialog.pa-10
    .d-flex.justify-center
        h3 {{$t('FILTERSETTING.ADD')}}－{{getItemNameFromList(classTypeList, className) }}{{getItemStageNameFromList() }}
    v-card(flat)
        v-form(ref='form' class='border').order-box
            v-card-title
                //- v-row.mt-1
                    v-col.pr-2.pl-2(cols='6')
                        v-select(v-model='className' :label=`$t('TABLESETTING.PAGE')` :items='tab_items' height='34' flat dense readonly append-icon='')
                            template(v-slot:selection='{ item }')
                                .t-black {{ getItemNameFromList(classTypeList, item) }}
                            template(v-slot:item='{ item }')
                                .t-black {{ getItemNameFromList(classTypeList, item) }}
                    v-col.pr-2.pl-2(cols='6')
                        v-select(v-if='className == "Case" || className == "Opportunity"' :label=`$t('TABLESETTING.PROCESS')` v-model='stageType' :items='stageTypeList' item-text='stage_type_name' item-value='id' return-object  height='34' flat dense readonly append-icon='')
                v-row(no-gutters)
                    v-col.pr-2.pl-2(cols='6')
                        v-text-field.pt-1(:label="$i18n.t('TABLESETTING.NAME')" :rules="formRule.nameRules" v-model='customFilterData.name' required)
                    v-col.pr-2.pl-2(cols='6')
                        v-select(v-model='customFilterData.public' :label=`$t('FILTERSETTING.VISIBILITY')` :items='visibilityList' item-text='name' item-value='key' height='34' flat dense )
            v-card-text(cols="12" md="12" class='pa-0 align-items-end')
                div( class="ma-2 text-center text-no-wrap rounded-lg")
                    //- select field
                    v-row(no-gutters)
                        v-col(cols="3" md="3" )
                            v-btn(@click='onAddCondition()' width='120' height='32' color='green' plain border-0 class='d-flex justify-start')
                                v-icon.mr-1(size='14') icon-add
                                span {{$t('FILTERSETTING.ADD_CONDITION')}}
                    //- generate condition from selected field 
                    v-row(v-for='(item, index) in filterSet' :key='index' class='filterField')
                        v-col(cols="1" md="1" class='d-flex justify-start align-items-start pr-0')
                            v-btn(icon='icon' @click='onDeleteField(item)')
                                v-icon(size='18') icon-remove
                        v-col(cols="3" md="3")
                            v-autocomplete.v-text-field__slot(:label=`$t('FILTERSETTING.FILTERFIELD')`, v-model='filterSet[index].field_name' @change='changeField(filterSet[index])' :items='fields'  height='34' flat dense item-disabled='disabled' )
                                //- template(v-slot:item="data")
                                    v-list-item-content(:disabled='data.item.type == "fk_org"')
                                        v-list-item-title {{ data.item.text }}
                        v-col(cols="3" md="3" v-if='filterSet[index].type == "fk_org" | filterSet[index].type == "fk_contact" | filterSet[index].type == "fk_cam" | filterSet[index].type == "opt" | filterSet[index].type == "mlt" | filterSet[index].type == "tst" |  filterSet[index].type == "user" | filterSet[index].type == "fk_seg" | filterSet[index].type == "fk_product" | filterSet[index].type == "fk_parentCase"')
                            v-select.v-text-field__slot(:label=`$t('FILTERSETTING.CONDITION')`, v-model='filterSet[index].op' :items='opFKList' item-text='name' item-value='key'  height='34' flat dense  )
                        v-col(cols="3" md="3" v-if='filterSet[index].type == "bol"')
                            v-select.v-text-field__slot(:label=`$t('FILTERSETTING.CONDITION')`, v-model='filterSet[index].op' :items='opBolList' item-text='name' item-value='key'  height='34' flat dense  )
                        v-col(cols="3" md="3" v-if='filterSet[index].type == "text" | filterSet[index].type == "date" | filterSet[index].type == "dtt" | filterSet[index].type == "num"')
                            v-select.v-text-field__slot(:label=`$t('FILTERSETTING.CONDITION')`, v-if='filterSet[index].type == "text"', v-model='filterSet[index].op' :items='opTextList' item-text='name' item-value='key'  height='34' flat dense  )
                            v-select.v-text-field__slot(:label=`$t('FILTERSETTING.CONDITION')`, v-if='filterSet[index].type == "date" || filterSet[index].type == "dtt"', v-model='filterSet[index].op' :items='opDateList' item-text='name' item-value='key'  height='34' flat dense  )
                            v-select.v-text-field__slot(:label=`$t('FILTERSETTING.CONDITION')`, v-if='filterSet[index].type == "num"', v-model='filterSet[index].op' :items='opNumList' item-text='name' item-value='key'  height='34' flat dense :rules='formRule.requireRule' required )
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "bol"')
                            v-select(:label=`$t('FILTERSETTING.CONDITION')`, :rules='formRule.bolRule' v-model='filterSet[index].search_word' :items='bolList' item-text='name' item-value='key'  height='34' flat dense )
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "text" && filterSet[index].op == "contains" || filterSet[index].op == "not_contain"')
                            v-text-field(:label=`$t('FILTERSETTING.TEXT')` :rules="formRule.nameRules" v-model='filterSet[index].search_word' required)
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "num" && filterSet[index].op')
                            v-text-field(:label=`$t('FILTERSETTING.NUM')` :rules="formRule.numRules" v-model.number='filterSet[index].search_word' required type='number')
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "fk_org"')
                            v-autocomplete.pt-0(
                                v-model="filterSet[index].search_word",
                                :items="organizationList",
                                item-text="name",
                                item-value="id",
                                :search-input.sync="search_org[index]",
                                :loading="org_loading",
                                :label=`$t('ADD_CONTACT.ORG_NAME')`,
                                :no-data-text=`$t('FILTERSETTING.INPUT_TO_SEARCH')`
                                :rules='formRule.requireRule',
                                validate-on-blur,
                                required,
                                :click='get_current_index(index)',
                                multiple 
                                chips
                                
                            )
                                template(v-slot:selection="data")
                                    v-chip( v-bind="data.attrs" :input-value="data.value" close @click="data.select" @click:close="remove(filterSet[index].search_word,data.index)")
                                        span(class='ellipsis' :title='data.item.name') {{data.item.name}}
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "fk_contact"')
                            v-autocomplete(v-model="filterSet[index].search_word" :rules='formRule.requireRule' :label= `$t('TO_DEAL.NAME')` :items="contactData" item-text="full_name" item-value="id" :search-input.sync="search_contact[index]" :loading="contact_loading" @focus='get_current_index(index)' multiple chips small-chips :no-data-text=`$t('FILTERSETTING.INPUT_CONTACT_TO_SEARCH')`)
                                template(v-slot:selection="data")
                                    v-chip(v-bind="data.attrs" :input-value="data.value" close @click="data.select" @click:close="remove(filterSet[index].search_word,data.index)")
                                        span(class='ellipsis' :title='data.item.full_name') {{data.item.full_name}}
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "fk_product"')
                            v-autocomplete(v-model="filterSet[index].search_word" :rules='formRule.requireRule' :label= `$t('FILTERSETTING.PRODUCT')` :items="productData" item-text="name" item-value="id" :search-input.sync="search_product[index]" :loading="product_loading" @focus='get_current_index(index)' multiple chips small-chips :no-data-text=`$t('FILTERSETTING.INPUT_PRODUCT_TO_SEARCH')`)
                                template(v-slot:selection="data")
                                    v-chip(v-bind="data.attrs" :input-value="data.value" close @click="data.select" @click:close="remove(filterSet[index].search_word,data.index)")
                                        span(class='ellipsis' :title='data.item.name') {{data.item.name}}
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "fk_parentCase"')
                            v-autocomplete(v-model="filterSet[index].search_word" :rules='formRule.requireRule' :label= `$t('FILTERSETTING.PARENT_CASE')` :items="caseData" item-text="subject" item-value="id" :search-input.sync="search_case[index]" :loading="case_loading" @focus='get_current_index(index)' multiple chips small-chips :no-data-text=`$t('FILTERSETTING.INPUT_PARENT_CASE_TO_SEARCH')`)
                                template(v-slot:selection="data")
                                    v-chip(v-bind="data.attrs" :input-value="data.value" close @click="data.select" @click:close="remove(filterSet[index].search_word,data.index)")
                                        span(class='ellipsis' :title='data.item.subject') {{data.item.subject}}
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "fk_cam"')
                            v-autocomplete.v-text-field__slot(:label=`$t('FILTERSETTING.CAMPAIGN')` :rules='formRule.requireRule' v-model='filterSet[index].search_word' :items='campaignList' item-text='name' item-value='id' :click='get_campaign_list()'  multiple chips )
                                template(v-slot:selection="data")
                                    v-chip(v-if='havecampaignlist && campaignList.length>0' v-bind="data.attrs" :input-value="data.value" close @click="data.select" @click:close="remove(filterSet[index].search_word,data.index)")
                                        span(class='ellipsis' :title='data.item.name') {{data.item.name}}
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "fk_seg"')
                            v-autocomplete.v-text-field__slot(:label=`$t('FILTERSETTING.SEG')` :rules='formRule.requireRule' v-model='filterSet[index].search_word' :items='segList' item-text='name' item-value='id' :click='get_seg_list()'  multiple chips )
                                template(v-slot:selection="data")
                                    v-chip(v-if='haveseglist && segList.length>0' v-bind="data.attrs" :input-value="data.value" close @click="data.select" @click:close="remove(filterSet[index].search_word,data.index)")
                                        span(class='ellipsis' :title='data.item.name') {{data.item.name}}
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "opt" || filterSet[index].type == "mlt" || filterSet[index].type == "tst"')
                            v-autocomplete.v-text-field__slot(:label=`$t('FILTERSETTING.OPT')` :rules='formRule.requireRule' v-model='filterSet[index].search_word' :items='getOptionList(filterSet[index])' item-text='name' item-value='key'  multiple chips)
                                template(v-slot:selection="data")
                                    v-chip(v-bind="data.attrs" :input-value="data.value" close @click="data.select" @click:close="remove(filterSet[index].search_word,data.index)")
                                        span(class='ellipsis' :title='data.item.name') {{data.item.name}}
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "user"')
                            v-autocomplete.v-text-field__slot(:label=`$t('FILTERSETTING.OPT')` , v-model='filterSet[index].search_word' item-text='fullName' item-value='id' :click='get_owner_list()'  flat :items='ownerList' :rules='formRule.requireRule' multiple chips)
                                template(v-slot:selection="data")
                                    v-chip(v-bind="data.attrs" :input-value="data.value" close @click="data.select" @click:close="remove(filterSet[index].search_word,data.index)")
                                        span(class='ellipsis' :title='data.item.fullName') {{data.item.fullName}}
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "date" && (filterSet[index].op == "on" || filterSet[index].op == "on_or_after" || filterSet[index].op == "on_or_before")' )
                            v-menu(ref="menu" v-model="menu[index]" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field.height-48(v-model='filterSet[index].search_word' :rules='formRule.dateRule' v-bind="attrs" v-on="on" prepend-icon='mdi-calendar' :readonly='loading' hide-details="auto", :label=`$t('FILTERSETTING.DATE')`,)
                                v-date-picker(v-model='filterSet[index].search_word' @input='menu[index] = false' no-title scrollable) 
                        v-col(cols="5" md="5" v-if='filterSet[index].type == "dtt" && (filterSet[index].op == "on" || filterSet[index].op == "on_or_after" || filterSet[index].op == "on_or_before")' )
                            v-menu(ref="menu" v-model="menu[index]" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field.height-48(v-model='filterSet[index].search_word' :rules='formRule.dateTimeRule' v-bind="attrs" v-on="on" prepend-icon='mdi-calendar' :readonly='loading' hide-details="auto", :label=`$t('FILTERSETTING.DATE')`,@click='datepickerFocus(index)')
                                date-picker(v-model='filterSet[index].search_word' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange") 
        v-card-actions(class='pa-0')
            v-row.mt-4
                v-col.pr-2.pl-0(cols='6')
                    v-btn(v-hide-element='false' :ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed='depressed' dark='dark') {{$t('GENERAL.CANCEL')}}
                v-col.pl-2.pr-0(cols='6')
                    v-btn(v-hide-element='false' :ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark') 
                        v-progress-circular(v-if='loading' indeterminate size='20')
                        .t-white(v-else) {{$t('GENERAL.CONFIRM')}}    
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import errorDialog from '@/components/Dialog/errorDialog';
    import i18n from '/common/plugins/vue-i18n.js';
    import DatePicker from 'vue2-datepicker';
    // list
    import classTypeList from "@/array/the_classTypeListWithOrder";
    import opptysFieldList from "@/array/the_opptysFieldList";
    import orgsFieldList from "@/array/the_orgsFieldList";
    import contactsFieldList from "@/array/the_contactsFieldList";
    import casesFieldList from "@/array/the_casesFieldList";
    import opDateList from "@/array/the_op_date";
    import opNumList from "@/array/the_op_num";
    import opTextList from "@/array/the_op_text";
    import levelList from "@/array/the_levelList";
    import get_oppty_closed_reason_func from "@/array/the_closeReasonList";
    import forecast_categoryList from "@/array/the_forecastCategoryList";
    import industryList from "@/array/the_IndustryList";
    import orgtypeList from "@/array/the_OrgtypeList"; 
    import caseSourceList from "@/array/the_caseSourceList"; 
    import caseStatusList from "@/array/the_caseStatusList";
    import opptyStatusList from "@/array/the_opptyStatusList";
    import closeCaseReasonList from "@/array/the_closeCaseReasonList";
    import casePriorityList from "@/array/the_casePriorityList";
    import opptySelectFieldsList from "@/array/the_opptySelectFieldsList";
    import ordersFieldList from "@/array/the_ordersFieldList";
    import orders_standard_FieldList from "@/array/the_orders_standard_FieldList";
    import orders_LP_FieldList from "@/array/the_orders_LP_FieldList";
    import orders_SP_FieldList from "@/array/the_orders_SP_FieldList";
    import orderShippingMethodsList from "@/array/the_orderShippingMethodsList";
    import paymentTermsList from "@/array/the_paymentTermsList";
    import productLineItemLists from "@/array/the_productLineItemLists";
    import wiadvancePaymentTermList from "@/array/the_wiadvancePaymentTermList";

    // service
    import CustomViewDataService from '@/services/CustomViewDataService';
    import CampaignDataService from "@/services/CampaignDataService";
    import OrganizationDataService from "@/services/OrganizationDataService";
    import ContactDataService from "@/services/ContactDataService";
    import CaseDataService from "@/services/CaseDataService";
    import CustomFilterDataService from "@/services/CustomFilterDataService";
    import SegmentDataService from "@/services/SegmentDataService";
    import ProductDataService from "@/services/ProductDataService";
    import TreeOptionsDataService from '@/services/TreeOptionsDataService';
    import belongGroupList from "@/array/the_belongGroupList";

    export default Vue.extend({
        components: {
            errorDialog,
            DatePicker,
        },
        props: {
            className: {
                type: String,
                required: true,
            },
            stageType: {
                type: Object,
            },
            stageTypeList: {
                type: Array,
            },
            customField: {
                required: true,
            }
        },
        data() {
            return {
                // list 
                opptyNewStatusList:opptySelectFieldsList['opptyStatusList'],
                opptyIndustryList:opptySelectFieldsList['opptyIndustryList'],
                customerSourceList:opptySelectFieldsList['customerSourceList'],
                opptyProgressList:opptySelectFieldsList['opptyProgressList'],
                opptyTypeList:opptySelectFieldsList['opptyTypeList'],
                visibilityList:[{key:false,name:i18n.t('FILTERSETTING.VISIBILITY_0')},{key:true,name:i18n.t('FILTERSETTING.VISIBILITY_1')}],
                booleanList:[{key:false,name:i18n.t('FILTERSETTING.FALSE')},{key:true,name:i18n.t('FILTERSETTING.TRUE')}],
                opFKList:[{ key:'contains', name: i18n.t('OP_TEXT.CONTAINS')}],
                opBolList:[{ key:'equal', name: i18n.t('OP_NUM.EQUAL')}],
                bolList:[{ key:true, name: i18n.t('FILTERSETTING.TRUE')},{ key:false, name: i18n.t('FILTERSETTING.FALSE')}],
                classTypeList: classTypeList,
                opptysFieldList: opptysFieldList,
                orgsFieldList: orgsFieldList,
                contactsFieldList: contactsFieldList,
                casesFieldList: casesFieldList,
                opDateList:opDateList,
                opNumList:opNumList,
                opTextList:opTextList,
                levelList: levelList,
                closeReasonList: [],
                forecast_categoryList: forecast_categoryList,
                industryList: industryList,
                orgtypeList: orgtypeList,
                caseSourceList: caseSourceList,
                caseStatusList: caseStatusList,
                opptyStatusList: opptyStatusList,
                closeCaseReasonList: closeCaseReasonList,
                casePriorityList: casePriorityList,
                belongGroupList:belongGroupList,
                campaignList:[],
                segList:[],
                organizationList:[],
                ownerList:[],
                groupList: [],
                typeList: [],
                ordersFieldList:ordersFieldList,
                orders_standard_FieldList:orders_standard_FieldList,
                orders_LP_FieldList:orders_LP_FieldList,
                orders_SP_FieldList:orders_SP_FieldList,
                orderShippingMethodsList:orderShippingMethodsList,
                paymentTermsList:paymentTermsList,
                wiadvancePaymentTermList:wiadvancePaymentTermList,
                productLineItemLists:productLineItemLists,
                product_type_list:[],
                // dialog
                errorDialog: false,
                errorMessage: '',

                // data
                user: JSON.parse(window.localStorage.getItem('user')),
                loading: false,
                contact_loading: false,
                org_loading: false,
                product_loading: false,
                case_loading: false,
                tab_items:['Contact', 'Opportunity', 'Organization','Case','Order','Order_License','Order_Service','Order_Standard'],
                filterSet:[],
                visibility: true,
                fields: [],
                menu:[],
                havecampaignlist:false,
                haveseglist:false,
                search_org:[],
                currentIndex:null,
                search_contact:[],
                contactData:[],
                productData: [],
                search_product: [],
                caseData: [],
                search_case: [],

                formRule: {
                    bolRule: [
                        (v) =>  v.toString().length>0  || i18n.t('RULE.RULE_R'),
                    ],
                    requireRule: [
                        (v) =>  v.length>0 || i18n.t('RULE.RULE_R'),
                    ],
                    nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_NAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    numRules: [
                        (v) => (v === 0 || !!v) || i18n.t('RULE.RULE_R_NUM')
                    ],
                    dateRule: [(v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE')],
                    dateTimeRule: [(v) => /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATETIME')],
                },
                customFilterData: {
                    name: null,
                    class_name: this.className,
                    definition: null,
                    stage_type_id: this.stageType ? this.stageType.id : null,
                    public: false,
                    default:false,
                    owner: JSON.parse(window.localStorage.getItem('user')).userid
                },
                treeList:null,
                openDatePicker:null,
            }
        },
        created(){
            this.getOpptyClosedReason();
            this.getTreeSelect();
            this.getfield();
            this.className == 'Case' ? this.getGropAndType() : '';
            if(this.className.includes('Order')){
                this.getProductTypeList();
            }
        },
        methods: {
            async getProductTypeList(){
                await ProductDataService.productcategorylist()
                .then(response => 
                {  
                    this.product_type_list = response.data;
                    this.product_type_list.forEach(el => el.key = parseInt(el.id));
                });
            },
            async getOpptyClosedReason(){
                this.closeReasonList = await get_oppty_closed_reason_func();
            },
            datepickerFocus(index){
                this.openDatePicker = index;
            },
            datepickerChange(value, type){
                if (type === 'minute') {
                    this.menu[this.openDatePicker] = false;
                }
            },
            async getTreeSelect(){
                await TreeOptionsDataService.list().then((response)=>{
                    this.treeList = response.data;
                })
            },
            getItemStageNameFromList(){
                if(this.className == "Case" || this.className == "Opportunity"){
                    let name = "－"+this.stageType.stage_type_name
                    return name
                }
            },
            async getGropAndType(){
                await CaseDataService.getcasegrouplist()
                .then((response)=>{
                    for(let i=0; i < response.data.length; i++){
                        let e = response.data[i];
                        let groupObj = {
                            key: e.id,
                            name: e.group_name
                        }
                        this.groupList.push(groupObj);
                    }
                })
                await CaseDataService.getcasetypelist()
                .then((response)=>{
                    for(let i=0; i < response.data.length; i++){
                        let e = response.data[i];
                        let groupObj = {
                            key: e.id,
                            name: e.type_name
                        }
                        this.typeList.push(groupObj);
                    }
                })
            },
            getOptionList(item){
                // if the field is customfield
                if(item.is_customfield){
                    for(let prop in this.customField){
                        if(prop == item.field_name && item.type != 'tst'){
                            let arr = [];
                            for(let optProp in this.customField[prop].option_set){
                                let anOpt = {
                                    key: optProp,
                                    name: this.customField[prop].option_set[optProp]
                                }
                                arr.push(anOpt);
                            }
                            return arr;
                        }
                        else if(prop == item.field_name && item.type == 'tst'){
                            let arr = [];
                            let tree_select_id = this.fields.filter(el=>el.value==item.field_name)[0].tree_select_id;
                            let tree_options = this.treeList.filter(el=>el.id==tree_select_id)[0].option_set;
                            for(let optProp in tree_options){
                                let anOpt = {
                                    key: optProp,
                                    name: tree_options[optProp]
                                }
                                arr.push(anOpt);
                            }
                            return arr;
                        }
                    }
                }
                else{
                    let list = [];
                    switch(item.field_name){
                        // Contact
                        case "level":
                            list = this.levelList;
                            break;
                        // Opportunity
                        case "current_stage":
                            this.stageType.stagedef_set.forEach(e => {
                                let obj = {
                                    key: e.id,
                                    name: e.stage_name
                                }
                                list.push(obj)
                            })
                            break;
                        case "closed_reason":
                            this.className == 'Case' ? list = this.closeCaseReasonList : list = this.closeReasonList;
                            break;
                        case "forecast_category":
                            list = this.forecast_categoryList;
                            break;
                        // Company
                        case "industry":
                            this.className == 'Opportunity' ? list = this.opptyIndustryList : list = this.industryList;
                            break;
                        case "organization_type":
                            list = this.orgtypeList;
                            break;
                        // Case
                        case "group":
                            list = this.groupList;
                            break;
                        case "type":
                            list = this.typeList;
                            break;
                        case "source":
                            list = this.caseSourceList;
                            break;
                        case "status":
                            if(this.className == 'Case'){
                                list = this.caseStatusList
                            }else if(this.className == 'Opportunity'){
                                list = this.opptyStatusList;
                            }else if(this.className.includes("Order")){
                                list = this.productLineItemLists['status_list'];
                            }
                            break;
                        case "priority":
                            list = this.casePriorityList;
                            break;
                        case "oppty_status":
                            list = this.opptyNewStatusList;
                            break;
                        case "customer_source":
                            list = this.customerSourceList;
                            break;
                        case "progress":
                            list = this.opptyProgressList;
                            break;
                        case "oppty_type":
                            list = this.opptyTypeList;
                            break;
                        // Order ALL
                        case "shipping_method":
                            list = this.orderShippingMethodsList;
                            break;
                        case "shipping_term":
                            list = [{ key: "FOB", name: 'FOB' },{ key: "free_shipping", name: i18n.t('SHIPPINH_TERM.FREE_SHIPPING') },]
                            break;
                        case "payment_term":
                            if(this.isWiadvance() && this.className == 'Organization'){
                                list = this.wiadvancePaymentTermList;
                            }else{
                                list = this.paymentTermsList;
                            }
                            break;
                        // case "closing_date":
                        //     list = Array.from({ length: 31  }, (_, index)=>({
                        //                 key: (index + 1).toString(),
                        //                 name: i18n.t(`THEDAYOFEVERYMONTH.${index + 1}`)
                        //             }))
                        //     break;
                        // case "regular_payment_date":
                        //     list = Array.from({ length: 31  }, (_, index)=>({
                        //                 key: (index + 1).toString(),
                        //                 name: i18n.t(`THEDAYOFEVERYMONTH.${index + 1}`)
                        //             }))
                        //     break;
                        // Order L
                        case "servers":
                            list = [{key:'A',name:"系統Systems(A/B/C.D)"},{key:'B',name:"伺服器Servers(A/B/C.D)"},{key:'C',name:"應用程式Applications(A/B/C.D)"}];
                            break;
                        case "order_status":
                            list = this.productLineItemLists['order_status_list'];
                            break;
                        case "billing_frequency":
                            list = this.productLineItemLists['billing_frequency_list'];
                            break;
                        // Order S
                        case "sla":
                            list = this.productLineItemLists['sla_list'];
                            break;
                        case "service_plan":
                            list = this.productLineItemLists['wiadvance_service_plan_list'];
                            break;
                        case "product_type_name":
                            list = this.product_type_list;
                            break;
                        case "belong_group":
                            list = this.belongGroupList;
                            break;
                        default:
                            break;
                    }
                    return list;
                }
            },
            get_current_index(index){
                this.currentIndex = index
            },
            getList(field_name){
                let list = [];
                if(field_name == 'group'){
                    CaseDataService.getcasegrouplist()
                    .then((response)=>{
                        response.data.forEach(e => {
                            let obj = {
                                key: e.id,
                                name: e.group_name
                            }
                            list.push(obj)
                        })
                    })
                    .finally(()=> {
                        return list;
                    })
                }
            },
            async get_campaign_list(){
                if(this.campaignList.length == 0 && !this.havecampaignlist){
                    await CampaignDataService.selectlist()
                    .then((response)=>{
                        this.campaignList = response.data;
                        this.havecampaignlist = true;
                    })
                }
            },
            async get_seg_list(){
                if(this.segList.length == 0 && !this.haveseglist){
                    await SegmentDataService.shortList()
                    .then((response)=>{
                        this.segList = response.data;
                        this.haveseglist = true;
                    })
                }
            },
            remove (list,index) {
                if (index >= 0) list.splice(index, 1)
            },
            async getfield(){
                this.fields = [];
                let class_name = this.className;
                
                let id = null;
                if(this.stageType != null){
                    id = this.stageType.id 
                }
                else{
                    id = null;
                }

                let tabFieldList = []
                switch(this.className){
                    case "Opportunity":
                        tabFieldList = this.opptysFieldList.filter(e => e.key !== 'stage_type');
                        this.stageType.id == 'all' ? tabFieldList = tabFieldList.filter(e => e.key !== "current_stage") :'';
                        break;
                    case "Contact":
                        tabFieldList = this.contactsFieldList
                        break;
                    case "Organization":
                        tabFieldList = this.orgsFieldList
                        break;
                    case "Case":
                        tabFieldList = this.casesFieldList.filter(e => e.key !== 'stage_type');
                        this.stageType.id == 'all' ? tabFieldList = tabFieldList.filter(e => e.key !== "current_stage") :'';
                        break;
                    case "Order":
                        tabFieldList = this.ordersFieldList;
                        break;
                    case "Order_License":
                        tabFieldList = this.orders_LP_FieldList;
                        break;
                    case "Order_Service":
                        tabFieldList = this.orders_SP_FieldList;
                        break;
                    case "Order_Standard":
                        tabFieldList = this.orders_standard_FieldList;
                        break;
                    default:
                        break;
                    }

                CustomViewDataService.getFieldList(class_name, id)
                .then(response =>{
                    let data = response.data;
                    class_name == 'Opportunity' ? data.fields.push('oppty_org') : '';
                    // 一般欄位
                    for(let i = 0; i<data.fields.length; i++){
                        let item = {
                            value: data.fields[i],
                            sequence_number: null,
                            is_customfield: false,
                            disabled: false,
                        }
                        if(data.fields[i] == 'oppty_contact'){
                            item.text = i18n.t('OPPTYSFIELDLIST.filter_oppty_contact');
                        }
                        else if(data.fields[i] == 'oppty_org'){
                            item.text = i18n.t('OPPTYSFIELDLIST.filter_oppty_org');
                        }
                        else{
                            item.text = this.getItemNameFromList(tabFieldList , item.value)
                        }
                        item.type = this.getItemNameFromListType(tabFieldList , item.value)
                        item.text ? this.fields.push(item) : '';
                    }
                    if(this.className == 'Contact'){
                        let item = {
                                text: i18n.t('CONTACTSFIELDLIST.seg'),
                                value: "segment",
                                sequence_number: null,
                                is_customfield: false,
                                disabled: false,
                                type: "fk_seg"
                            } 
                        this.fields.push(item)
                    }

                    let follow_item = {
                            text: i18n.t('FOLLOW.FOLLOW'),
                            value: "follow",
                            sequence_number: null,
                            is_customfield: false,
                            disabled: false,
                            type: "bol"
                        } 
                    if(!this.className.includes("Order")){
                        this.fields.push(follow_item);
                    }

                    // 客製化欄位
                    if(data.customField != null){
                        for(let prop in data.customField){
                            let item = {
                                text: data.customField[prop].label,
                                value: prop,
                                sequence_number: null,
                                is_customfield: true,
                                disabled: false,
                                type: prop.slice(0,3) == "tst" | prop.slice(0,3) == "mlt" | prop.slice(0,3) == "opt" | prop.slice(0,3) =="num" | prop.slice(0,3) =="bol" | prop.slice(0,3) =="dtt" | prop.slice(0,3) =="dat" ? prop.slice(0,3) : "text"
                            }
                            if(item.type == 'tst'){
                                item.tree_select_id = data.customField[prop].tree_select_id;
                            }
                            item.type == 'dat' ? item.type = 'date': '';
                            this.fields.push(item)
                        }
                    }                    
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
            },
            
            // dialog
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog(){
                this.errorDialog = false;
            },
            onConfirm() {
                let haveCondition = false;
                let conditionAreNotNull = this.filterSet.every(e => e.op && e.type && e.field_name);
                try{
                    this.filterSet[0].field_name ? haveCondition = true: '';
                }
                catch{
                    haveCondition = false;
                }
                

                if(haveCondition && conditionAreNotNull){
                    if(this.$refs.form.validate()){
                        this.customFilterData.definition = this.filterSet;
                        let data = this.customFilterData;
                        // delete data.owner;
                        if(this.className == "Case"){
                            let prioiityCheck = data.definition.filter(el=>el.field_name == "priority");
                            if(prioiityCheck.length>0){
                                let index = data.definition.indexOf(prioiityCheck[0]);
                                data.definition[index].field_name = "priority_number";
                            }
                        }
                        CustomFilterDataService.create(data)
                        .then(response => {
                            // get contact and org list
                            let contactList = {};
                            let orglist = {};
                            let productList = {};
                            let caseList = {};
                            this.filterSet.forEach(e => {
                                if(e.type == 'fk_org' ){
                                    orglist[e.field_name] = [];
                                    e.search_word.forEach(word => {
                                        let org = this.organizationList.find(e => e.id == word);
                                        orglist[e.field_name].push(org);
                                    })
                                }
                                else if(e.type == 'fk_contact'){
                                    contactList[e.field_name] = [];
                                    e.search_word.forEach(word => {
                                        let contact = this.contactData.find(e => e.id == word);
                                        contactList[e.field_name].push(contact);
                                    })
                                }
                                else if(e.type == 'fk_product'){
                                    productList[e.field_name] = [];
                                    e.search_word.forEach(word => {
                                        let product = this.productData.find(e => e.id == word);
                                        productList[e.field_name].push(product);
                                    })
                                }
                                else if(e.type == 'fk_parentCase'){
                                    caseList[e.field_name] = [];
                                    e.search_word.forEach(word => {
                                        let c = this.caseData.find(e => e.id == word);
                                        caseList[e.field_name].push(c);
                                    })
                                }
                            })
                            this.$emit('emitAddFilterDialog', true, response.data, contactList, orglist, productList, caseList);
                        })
                        .catch(response => {
                            if(response.response.status == 400 )
                            {
                                if(response.response.data.message == "Search word is empty")
                                    this.showErrorDialog(i18n.t('FILTERSETTING.ERROR3'));
                            }else
                            {
                                this.showErrorDialog(response);
                            }
                        })
                    }
                }
                else if(!haveCondition){
                    this.showErrorDialog(i18n.t('FILTERSETTING.ERROR'));
                }
                else if(!conditionAreNotNull){
                    this.showErrorDialog(i18n.t('FILTERSETTING.ERROR2'));
                }  
            },            
            onCancel(){
                this.$emit('emitAddFilterDialog', false);
            },
            onAddCondition(){
                this.filterSet.push({field_name:"",op:"",search_word:"",type:""})
                // this.menu.push(false)
                this.search_org.push('')
                this.search_contact.push('')
            },
            onDeleteField(item){
                this.filterSet = this.filterSet.filter(el => el != item);
                if(item.type == 'fk_org' || item.type == 'fk_contact' || item.type == 'fk_cam' || item.type == 'fk_product' || item.type == 'fk_parentCase'){
                    let fk_field = this.fields.find(e => e.type == item.type && e.value == item.field_name);
                    fk_field.disabled = false;
                }
            },
            changeField(item){
                item.search_word = '';
                const findType = new Promise((resolve, reject)=>{

                    for(let i = 0; i<this.fields.length; i++){
                        if(this.fields[i].value == item.field_name){
                            item.type = this.fields[i].type;
                            item.is_customfield = this.fields[i].is_customfield;
                        }
                    }
                    item.type ? resolve() : reject();
                })
                
                findType
                .then(()=>{
                    switch(item.type){
                        case "num":
                            item.op = "equal"
                            break;
                        case "date":
                            item.op = "on"
                            break;
                        case "dtt":
                            item.op = "on"
                            break;
                        case "bol":
                            item.op = "equal"
                            break;
                        default:
                            item.op = "contains"
                            break;
                    }

                    if(item.type == 'fk_org' || item.type == 'fk_contact' || item.type == 'fk_cam' || item.type == 'fk_product' || item.type == 'fk_parentCase'){
                        let fk_field = this.fields.find(e => e.type == item.type && e.value == item.field_name)
                        fk_field.disabled = true
                    }
                })
            },
            get_owner_list(){
                if(this.ownerList.length==0){
                let change = []
                switch(this.className){
                    case "Opportunity":
                        change = "opportunity"
                        break;
                    case "Contact":
                        change = "contacts"
                        break;
                    case "Organization":
                        change = "accounts"
                        break;
                    case "Case":
                        change = "cases"
                        break;
                    default:
                        break;
                    }
                OrganizationDataService.getchangeowner(change)
                .then((respose)=>{
                    this.ownerList = respose.data;
                    this.ownerList.forEach(item => {
                        item.fullName = item.last_name + ' ' + item.first_name
                    });
                });
            }
            },
        },
        watch:{
            async search_contact(val){
                val = val[this.currentIndex]

                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString){
                    this.contact_loading = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val)){
                                array.push(item);
                            }
                        })
                        this.contactData = this.contactData.concat(array);
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.contact_loading = false;
                    })
                }
            },
            async search_org(val) {
                val = val[this.currentIndex];
                var re = /^(?!\s*$).+/;
                var check = re.test(val)

                if(check && val != null){
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    if(!this.org_loading && charNum>=1 && charNum==val.length ){
                        this.org_loading = true;
                        OrganizationDataService.getAll(1, val, 100, [])
                        .then(response => {
                            this.organizationList = this.organizationList.concat(response.data.list);
                            let num = 0
                            this.organizationList.forEach((item)=>{
                                item.uqNumber = num
                                num ++
                            })
                        })
                        .finally(() => (this.org_loading = false));
                    }
                    else{
                        this.org_loading = false;
                    }
                }
            },
            async search_product(val){
                val = val[this.currentIndex];
                this.product_loading = true;
                let type = undefined;
                if(this.className == "Order_Service" ){
                    type = "service";
                }
                else if(this.className == "Order_License" ){
                    type = "license";
                }
                await ProductDataService.list(1, val, 100, [],type)
                .then(response => 
                {  
                    this.productData = this.productData.concat(response.data.list);
                    this.product_loading = false;
                });
            },
            async search_case(val){
                val = val[this.currentIndex];

                if(val){
                    this.case_loading = true;
                    await CaseDataService.kanabn('list', this.stageType.id, 0, 1, val, 100, [])
                    .then(response => 
                    {  
                        this.caseData = this.caseData.concat(response.data.list);
                        this.case_loading = false;
                    });
                }
            },
        },
    });
</script>
<template lang='pug'>
    div
        v-row.sheet-box.my-5(v-if='activityList.length == 0 && mode == "list"')
            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_ACTIVITY_CHANGE')}}
        .contact-detail-table.scroll-x.pa-0(v-if='mode == "list" && activityList.length > 0')
            .data-area(style='position: relative;')
                v-data-table.height_100.table_in_tab(v-if='!loading_content' :headers='activityHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='activityList' :items-per-page='itemPerPage' :page.sync='page' :page-count='pageCount' :sort-desc.sync='sortByDesc' multi-sort :sort-by.sync="sortBy" height='100%' hide-default-footer='hide-default-footer' :loading='loading_edit' loading-text='loading...' v-columns-resizable fixed-header v-model="selectedRows"  item-key='id' )
                    
                    template(v-slot:item.completed='{ item }')
                        v-btn(v-hide-element='false' v-if='item.completed=="COMPLETED"' rounded icon x-small dark class='bg-green' @click='updateCompleted(item,false)')
                            v-icon(dark size='8') fas fa-check
                        v-btn(v-hide-element='false' v-else rounded x-small icon outlined @click='updateCompleted(item,true)')

                    template(v-slot:item.subject='{ item }')
                        div(class=' d-flex justify-start ' :title='item.subject+" [ "+item.icon_name+" ]"' )
                            v-icon(class='justify-start' v-if='item.completed == "UNCOMPLETED" && item.is_due >= -3' size="12" color='rgb(255 22 22)' :title='getDueTitle(item.is_due)' ) mdi-checkbox-blank-circle

                            v-list-item-group(class='inline-edit-cell justify-start')
                                .ellipsis
                                    v-list-item-avatar.d-inline-block.ma-0.mx-4(:color='item.color', size="25")
                                        v-icon(size="18" color='white' :title='item.icon_name' @click='showEditActivityDialog(item)') {{ item.icon }}
                                    span.t-pink.ellipsis.pl-2.is-pointer(@click='showEditActivityDialog(item)' :title='item.subject+" [ "+item.icon_name+" ]"' v-if='item.completed == "UNCOMPLETED" && item.is_due >= -3' :class='item.completed == "COMPLETED" ? "line-through": ""') {{ item.subject }}
                                    span.t-primary.ellipsis.pl-2.is-pointer(@click='showEditActivityDialog(item)' :title='item.subject+" [ "+item.icon_name+" ]"' v-else :class='item.completed == "COMPLETED" ? "line-through": ""') {{ item.subject }}
                            //- inlineEditDialog(field_type='select_required_contact' page='cases' editProp='primary_contact' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                    //- 聯絡人
                    template(v-slot:item.activity_contact='{ item }')
                        div(v-if='item.activity_contact_id != null' class=' d-flex justify-center position-relative' :title='item.activity_contact.name' )
                            .inline-edit-cell.ellipsis
                                a.pl-2(class='' :title='item.activity_contact.name ? item.activity_contact.name :item.activity_contact.full_name' :href='"/Contacts/" + item.activity_contact_id' ) {{ item.activity_contact.name}}
                            //- inlineEditDialog(field_type='select_required_contact' page='cases' editProp='primary_contact' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 公司
                    template(v-slot:item.activity_org='{ item }')
                        div(v-if='item.activity_org_id != null' class=' d-flex justify-center position-relative' :title='item.activity_org_name' )
                            .inline-edit-cell.ellipsis
                                a.pl-2(v-if='item.activity_org_id' :title='item.activity_org_name' :href='"/Account/" + item.activity_org_id' ) {{ item.activity_org_name }}
                            //- inlineEditDialog(field_type='select' page='cases' editProp='owner' :editItem='item' :selectList='ownerList_inlineEdit' item-text="full_name" @emitInlineEdit='onEmitInlineEdit')
                    //- 負責人
                    template(v-slot:item.owner='{ item }')
                        div(class=' d-flex justify-center position-relative' :title='item.owner.last_name+" "+item.owner.first_name' )
                            v-list-item-group(class='inline-edit-cell justify-start')
                                .ellipsis
                                    v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="30")
                                        v-icon(v-if="item.owner == null") mdi-account 
                                        v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
                                        img(v-else :src='item.owner.usersetting.photo')
                                    a(v-if='!!item.owner' :href='onMail(item.owner.email)' class='ellipsis') {{ item.owner.last_name+' '+item.owner.first_name }}
                            //- inlineEditDialog(field_type='select' page='cases' editProp='owner' :editItem='item' :selectList='ownerList_inlineEdit' item-text="full_name" @emitInlineEdit='onEmitInlineEdit')
                    //- 相關
                    template(v-slot:item.regarding_name='{ item }')
                        div(v-if='item.regarding_id' class='d-flex justify-left position-relative' )
                            .inline-edit-cell
                                v-list-item-group(class='inline-edit-cell justify-start')
                                    v-list-item-avatar.d-inline-block(color="aliceBlue", size="30")
                                        v-icon(size="20" v-if='item.regarding_case.length > 0') mdi-wrench-outline
                                        v-icon(size="20" v-if='item.regarding_oppty.length > 0') icon-chatmoney-border
                                        img(v-else :src='item.owner.usersetting.photo')
                                    a(v-if='item.regarding_case.length > 0' class='ellipsis' :title='item.regarding_name+" [ "+ $t(`CASE.TITLE`) + " ]"' :href='"/Case/" + item.regarding_id' ) {{ item.regarding_name }}
                                    a(v-else class='ellipsis' :title='item.regarding_name+" [ "+ $t(`DEAL.TITLE`) + " ]"' :href='"/Deal/" + item.regarding_id' ) {{ item.regarding_name }}
                            //- inlineEditDialog(field_type='select' editProp='priority' :editItem='item' :selectList='priorityList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')
                    //- 優先度
                    template(v-slot:item.priority='{ item }')
                        div(class=' d-flex justify-center position-relative' :title='getItemNameFromList(activityPriorityList, item.priority)' )
                            .inline-edit-cell.ellipsis
                                v-chip.my-2.text-white(class='ellipsis ' v-if='item.priority == "URGENT" ' color='red' small :title='getItemNameFromList(activityPriorityList, item.priority)') {{ getItemNameFromList(activityPriorityList, item.priority) }}
                                v-chip.my-2.text-white(class='ellipsis' v-else-if='item.priority == "IMPORTANT"' color='orange' small :title='getItemNameFromList(activityPriorityList, item.priority)') {{ getItemNameFromList(activityPriorityList, item.priority) }}
                                v-chip.my-2.text-white(class='ellipsis ' v-else-if='item.priority == "MEDIUM"' color='green' small :title='getItemNameFromList(activityPriorityList, item.priority)') {{ getItemNameFromList(activityPriorityList, item.priority) }}
                                v-chip.my-2.text-white(class='ellipsis ' v-else color='blue' small :title='getItemNameFromList(activityPriorityList, item.priority)') {{ getItemNameFromList(activityPriorityList, item.priority) }}
                            //- inlineEditDialog(field_type='select' editProp='priority' :editItem='item' :selectList='priorityList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')

                    //- 到期日
                    template(v-slot:item.end='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell.ellipsis
                                span(:title='item.end') {{item.end.slice(0,16)}}
                            //- inlineEditDialog(field_type='select' editProp='priority' :editItem='item' :selectList='priorityList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')
                    //- 進度
                    template(v-slot:item.progress='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span(:title='item.progress+"%"') {{item.progress}}
                            //- inlineEditDialog(field_type='select' editProp='priority' :editItem='item' :selectList='priorityList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')
                    //- 執行
                    template(v-slot:item.action='{ item }')
                        .ellipsis
                            //- v-btn( icon='icon' @click='showActivityDialog(item)')
                            //-     v-icon(size='18') icon-edit
                            v-btn(icon='icon' )
                                v-icon(size='18' v-if="deleteCheck(item)" @click='onDelete(item.id)') icon-remove
                                img(v-else src='@/assets/images/readonly.svg' style='opacity: 0.4; width: 24px;')
                label.text-right.mr-4(v-if='!loading_content') {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}} 
                v-pagination(v-if='!loading_content' v-model='page' :length='pageCount' circle :total-visible="10")
        #Activity.page-inner.page-noScroll.position-relative(v-if='mode == "calender"')
            .data-area
                v-row(class="fill-height")
                    v-col
                        v-sheet
                            v-toolbar(flat)
                                v-btn.mr-4(v-hide-element='false' outlined color='grey darken-2' @click='setToday')
                                    | {{$t(`ACTIVITY.TODAY`)}}
                                v-btn(v-hide-element='false' fab text small color='grey darken-2' @click='prev')
                                    v-icon(small)
                                        | mdi-chevron-left
                                v-btn(v-hide-element='false' fab text small color='grey darken-2' @click='next')
                                    v-icon(small)
                                        | mdi-chevron-right
                                v-toolbar-title(v-if='$refs.calendar')
                                    | {{ $refs.calendar.title }}
                                v-spacer
                                v-menu(bottom right)
                                    template(v-slot:activator='{ on, attrs }')
                                        v-btn(v-hide-element='false' outlined color='grey darken-2' v-bind='attrs' v-on='on')
                                            span {{ typeToLabel[type] }}
                                            v-icon(right)
                                                | mdi-menu-down
                                    v-list
                                        v-list-item(@click="type = 'month'")
                                            v-list-item-title {{$t(`ACTIVITY.MONTH`)}}
                                        v-list-item(@click="type = 'week'")
                                            v-list-item-title {{$t(`ACTIVITY.WEEK`)}}
                                        v-list-item(@click="type = 'day'")
                                            v-list-item-title {{$t(`ACTIVITY.DAY`)}}
                            v-sheet(style='height:69vh;')
                                v-calendar(
                                    ref='calendar' 
                                    v-model='focus' 
                                    color='primary' 
                                    :events='events' 
                                    :event-color='getEventColor' 
                                    :event-overlap-mode="'column'"
                                    :event-more-text="$t('ACTIVITY.MORE')"
                                    :weekday-format="weekText"
                                    :type='type'
                                    @mousedown:event="eventDrag"
                                    @mouseup:day="monthUp"
                                    @mousemove:day="monthMove"
                                    @click:more="viewDay"

                                    @mousedown:time="otherDrag"
                                    @mousemove:time="otherMove"
                                    @mouseup:time="otherUp"
                                )
                                    template(v-slot:day-label='{ week, day,date }')
                                        div(class='d-flex justify-space-between align-center px-4 ')
                                            span {{ day }}
                                            v-btn( icon @click='showAddActivityDialog(date)' color='primary' class='add-activity-btn')
                                                v-icon mdi-plus
                                    
                                    template(v-slot:event='{ event, eventParsed,day,timed }')
                                        v-row(no-gutters class='pa-1 pr-5 v-event-draggable' :ref="event.id" :title='event.name+" [ "+event.icon_name+" ]   "+convertTime(event.start)+" - "+convertTime(event.end) ')
                                            v-col(cols='12' )
                                                div(class='d-flex justify-space-between')
                                                    div.justify-start
                                                        v-btn(v-hide-element='false' v-if='event.completed == "COMPLETED"' rounded icon x-small dark outlined class='bg-green' ,@click='updateCompleted(event,false)')
                                                            v-icon(dark size='8') fas fa-check
                                                        v-btn(v-hide-element='false' v-else rounded x-small icon outlined dark class='bg-white' ,@click='updateCompleted(event,true)') 
                                                    div.justify-center(v-if='type!="week"')
                                                        span(:class='event.completed == "COMPLETED" ? "line-through ": " "') {{ event.name }}   {{ convertTime(event.start) }} - {{ convertTime(event.end) }}
                                                    div.justify-center(v-else)
                                                        span(:class='event.completed == "COMPLETED" ? "line-through ": " "') {{ event.name }}
                                                        p(:class='event.completed == "COMPLETED" ? "line-through ": " "') {{ convertTime(event.start) }} - {{ convertTime(event.end) }}
                                                    div.pl-12(v-if='type=="week"')
                                                    span
                                        div(v-hide-element v-if='showExtendBtn(event, eventParsed,day) && type == "month"' class="activity-extend-btn" @mousedown.stop='extendActivity(event)')
                                        div(v-hide-element v-if='type!="month"' class="v-event-drag-bottom" @mousedown.stop='extendBottom(event)')
        v-dialog(v-model='addActivityDialog' max-width='630' v-if='addActivityDialog')
            add-activity(@emitAddActivityDialog='onEmitAddActivityDialog' :add-activity='addActivity' :activity-type='activityTypeList' :owner-list='ownerList' :newDateTime='newDateTime' :regarding_data='regarding_data' :from_page='from_page' :activity_org='activity_org' :activity_contact='activity_contact')
        v-dialog(v-model='editActivityDialog' max-width='630' v-if='editActivityDialog')
            edit-activity(@emitEditActivityDialog='onEmitEditActivityDialog' :activityId='activityId' :activity-type='activityTypeList' :owner-list='ownerList' :canEdit='canEdit')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from "vue";
    import ActivityDataService from '@/services/ActivityDataService';
    import OrganizationDataService from "@/services/OrganizationDataService";
    // dialog
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import bulkEditDialog from "@/components/Dialog/BulkEdit/caseBulkEditDialog";
    import inlineEditDialog from '@/components/Common/inlineEditDialog';
    import addActivity from '@/components/Dialog/Activity/addActivity';
    import editActivity from '@/components/Dialog/Activity/editActivity';
    // other
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import refreshBtn from "@/components/Common/refreshBtn";
    import i18n from '/common/plugins/vue-i18n.js'
    // list
    import activityPriorityList from "@/array/the_activityPriorityList";
    import activityCompletedList from "@/array/the_activityCompletedList";

    export default Vue.extend({
        props: {
            target_id: {
                type: String,
                require: true,
            },
            mode: {
                type: String,
                require: true,
            },
            target_name: {
                type: String,
                require: true,
            },
            isAddActivity: {
                type: Boolean,
                require: true,
            },
            from_page: {
                type: String,
                require: true,
            },
            activity_org: {
                type: Object,
                require: false,
            },
            activity_contact: {
                type: Object,
                require: false,
            },
        },
        name: 'Activity',
        components: {
            messageDialog,
            successDialog,
            errorDialog,
            bulkEditDialog,
            inlineEditDialog,
            addActivity,
            editActivity,
            refreshBtn,
            i18n,
            // other
            loadingOverlay,
        },
        data() {
            return {
                is_group_user:false,
                total_uncompleted_cases:0,
                startDayMenu: false,
                startDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                endDayMenu: false,
                endDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                selectTimeFrame: 0,
                dateMode: [
                    { index: 0, name: i18n.t('FILTERSETTING.NO_FILTER') },
                    { index: 1, name: i18n.t('DASHBOARD.THIS_YEAR') },
                    { index: 2, name: i18n.t('DASHBOARD.THIS_SEASON') },
                    { index: 3, name: i18n.t('DASHBOARD.THIS_MONTH') },
                    { index: 4, name: i18n.t('DASHBOARD.THIS_WEEK') },
                    { index: 5, name: i18n.t('DASHBOARD.SELECT_DATE') },
                ],
                clickDate:null,
                is_staff: JSON.parse(window.localStorage.getItem('is_staff')),
                user: JSON.parse(window.localStorage.getItem('user')),
                loading: false,
                // search
                searchActivity:'',
                awaitingSearch: false,

                // filters
                selectPriority: [],
                selectCompleted: {},
                selectType:[],
                selectOwner: [],
                activityTypeList: [],
                priorityList: [],
                activityCompletedList: [{
                    key: 0,
                    name: i18n.t('FILTERSETTING.NO_FILTER')
                }
                ].concat(activityCompletedList),
                activityPriorityList: activityPriorityList,
                ownerList: [],

                // calender
                type: "month",
                focus: "",
                typeToLabel: {
                    month: i18n.t('ACTIVITY.MONTH'),
                    week: i18n.t('ACTIVITY.WEEK'),
                    day: i18n.t('ACTIVITY.DAY'),
                },
                selectedElement: null,
                selectedOpen: false,
                events: [],
                colors: ["blue","indigo","deep-purple","cyan","green","orange","grey darken-1"],
                names: ["Meeting","Holiday","PTO","Travel","Event","Birthday","Conference","Party"],
                // calender drag and drop
                dragEvent: null,
                extendEvent: null,
                // listing view
                selectedRows: [],
                loading_edit: false,
                loading_content: true,
                sortBy:[],
                sortByForSearch:[],
                sortByDesc:[],
                pageDataCount:null,
                currentData:0,
                page: 1,
                pageCount: 0,
                itemPerPage: 10,
                activityList: [],
                activityHeaders: [
                    // {
                    //     value: "data-table-select",
                    //     align: "center",
                    //     width: '100px',
                    // },
                    {
                        text: i18n.t('ACTIVITY.COMPLETED'),
                        value: "completed",
                        align: "center",
                        width: '100px',
                    },
                    {
                        text: i18n.t('ACTIVITY.SUBJECT'),
                        value: "subject",
                        align: "center",
                        width: '200px',
                    },
                    // {
                    //     text: i18n.t('ACTIVITY.REGARDING'),
                    //     value: "regarding_name",
                    //     align: "center",
                    //     // width: '200px',
                    // },
                    {
                        text: i18n.t('ACTIVITY.PRIORITY'),
                        value: "priority",
                        align: "center",
                        width: '120px',
                    },
                    {
                        text: i18n.t('ACTIVITY.END_DAY'),
                        value: "end",
                        align: "center",
                        width: '220px',
                    },
                    {
                        text: i18n.t('ACTIVITY.PROGRESS')+"(%)",
                        value: "progress",
                        align: "center",
                        width: '140px',
                    },
                    {
                        text: i18n.t('ACTIVITY.ORG'),
                        value: "activity_org",
                        align: "center",
                        width: '130px',
                    },
                    {
                        text: i18n.t('ACTIVITY.CONTACT'),
                        value: "activity_contact",
                        align: "center",
                        width: '130px',
                    },
                    {
                        text: i18n.t('ACTIVITY.OWNER'),
                        value: "owner",
                        align: "center",
                        width: '180px',
                    },
                    // {
                    //     text: i18n.t('SEGMENT.ACTIVE'),
                    //     value: "action",
                    //     align: "center",
                    //     sortable: false,
                    //     width: '80px',
                    // },
                ],
                deleteActivityIds:'',
                isDelete:false,
                isUpdateCompleted:false,
                // dialog
                canEdit:false,
                activityId:null,
                editActivityDialog:false,
                addActivity: {},
                addActivityDialog: false,
                messageDialog: false,
                message: '',
                successDialog: false,
                errorDialog: false,
                errorMessage: '',
                firstRun:false,
                isAdmin:false,
                currentUser:null,
                mouseStart:null,
                mouseStop:null,
                isDrag:false,
                extendItem:null,
                extendItemWidth:0,
                lastBackDay:0,
                moveDay:null,
                extendWeekday:null,
                refStatus:null,

                dragStart: null,
                createEvent: null,
                createStart: null,
                extendOriginal: null,
                clickTime:null,
                newDateTime:null,
                isCompleteDrag:false,

                regarding_data:null,
                isSetToday:false,
            }
        },
        async created() {
            this.loading = true;
            this.firstRun = true;
            let userJson = window.localStorage.getItem('user');
            this.currentUser = JSON.parse(userJson);
            this.currentUser.is_staff ? this.isAdmin = true : this.isAdmin = false;
            await OrganizationDataService.getchangeowner('activities')
            .then(response => {
                this.ownerList = response.data;
                this.ownerList.forEach(item => {
                    if (item.first_name != null) {
                        item.full_name = item.last_name + " " + item.first_name;
                    } else {
                        item.full_name = item.last_name;
                    }
                });
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
            await ActivityDataService.getActivityType()
            .then(response => {
                this.activityTypeList = response.data;

            })
            .then(()=>{
                this.selectType = [];
                this.selectCompleted = this.activityCompletedList[0];
            })
            .catch(response => {
                console.log(response)
            })
            await this.getListData();
            await (this.firstRun = false);
            await (this.loading = false);
            window.removeEventListener("dblclick",this.dblclickEvent);
            if(this.from_page){
                switch(this.from_page){
                    case "contact" :
                        this.regarding_data = {
                            "id":this.target_id,
                            "full_name":this.target_name
                        };
                        break;
                    case "org" :
                        this.regarding_data = {
                            "id":this.target_id,
                            "name":this.target_name
                        };
                        break;
                    case "case" :
                        this.regarding_data = {
                            "id":this.target_id,
                            "subject":this.target_name,
                            "list_name":this.target_name,
                            "list_type":"case"
                            }
                        break;
                    case "oppty" :
                        this.regarding_data = {
                            "id":this.target_id,
                            "name":this.target_name,
                            "list_name":this.target_name,
                            "list_type":"oppty"
                            }
                        break;
                    default:
                        break;
                }
            }
        },
        mounted () {
        },
        methods: {
            // 抓資料
            async getListData(){
                this.loading_content = true;
                let selectPriority = [];
                this.selectPriority.map((item)=>{
                    selectPriority.push(item.key);
                })
                if(selectPriority.includes(0)){
                    selectPriority = [];
                }
                let selectCompleted = this.selectCompleted.key == 0 ? '' : this.selectCompleted.key;
                let selectOwner = [];
                this.selectOwner.map((item)=>{
                    selectOwner.push(item.id);
                })
                if(selectOwner.includes(0) || selectOwner.length == 0 ){
                    selectOwner = [0];
                }
                let selectTimeFrame = this.selectTimeFrame == 0 ? '' : this.selectTimeFrame;
                let startDay = this.selectTimeFrame == 5 ? this.startDay : '';
                let endDay = this.selectTimeFrame == 5 ? this.endDay : '';
                let selectType = [];
                this.selectType.map((item)=>{
                    selectType.push(item.id);
                })
                if(selectType.includes(0)){
                    selectType = [];
                }
                let calendar = "";
                // 抓日曆的日期
                if(this.mode != "list")
                {
                    if(this.refStatus.value.length >0 && !this.isSetToday){
                        calendar = this.refStatus.value.substr(0,7);
                    }else{
                        calendar = this.convertDateTime(new Date()).substring(0,7);
                        this.isSetToday = false;
                    }
                }
                await ActivityDataService.getActivity(this.page,this.searchActivity,this.itemPerPage,this.sortByForSearch,selectPriority,selectCompleted,selectTimeFrame,selectType,selectOwner,startDay,endDay,calendar,this.target_id,this.from_page)
                .then((response => {
                    this.activityList = response.data.list;
                    this.events = this.activityList;
                    this.pageCount = response.data.total_page;
                    this.pageDataCount = response.data.pageDataCount;
                    this.currentData = (this.page)*this.itemPerPage >= this.pageDataCount ? this.pageDataCount:(this.page)*this.itemPerPage;
                    this.total_uncompleted_cases = response.data.total_uncompleted_cases;
                    this.is_group_user = response.data.is_group_user;
                }))
                .then(()=>{
                    this.loading_content = false;
                    this.$emit("emitAddActivity","refresh");
                })
            },
            deleteCheck(item){
                if(this.isAdmin || this.currentUser.userid == item.created_by.id){
                    return true;
                }else if(this.is_group_user){
                    let sameGroup = this.ownerList.filter(el=>el.id == item.created_by.id);
                    if(sameGroup.length>0){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return false;
                }
            },
            updateCompleted(item,done){
                if(this.$isViewOnly.isViewOnly){
                    return;
                }
                if(this.mode != "list"){
                    this.isUpdateCompleted = true;
                }
                let data = {
                    id : item.id,
                    completed : done == true ? "COMPLETED": "UNCOMPLETED"
                }
                ActivityDataService.updateActivityCompleted(data)
                .then((response)=>{
                    item.completed = response.data.completed;
                    if(response.data.completed == "COMPLETED"){
                        item.progress = 100;
                    }
                })
                .finally(()=>{
                    if(this.mode != "list"){
                        this.isUpdateCompleted = true;
                    }
                    this.$emit("emitAddActivity","refresh");
                })
            },
            async searchActivitys(){
                await this.getListData();
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            async onEmitSuccessDialog() {
                this.successDialog = false;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                if(val){
                    this.messageDialog = false;
                    if(this.isDelete){
                        ActivityDataService.deleteActivity(this.deleteActivityIds)
                        .then(()=>{
                            this.getListData();
                        })
                    }
                }
                this.isDelete = false;
                this.deleteActivityIds = '';
            },
            showAddActivityDialog(date){
                this.addActivity = {
                    subject:"",
                    start_date: date? date+" 09:00"  : null,
                    due_date:"",
                    completed : false,
                    // completed : "UNCOMPLETED",
                    priority : "MEDIUM",
                    progress : 0,
                    remark: "",
                    activity_type:this.activityTypeList[0],
                    activity_contact:null,
                    activity_org:null,
                    owner: this.ownerList.find(e => e.id == this.currentUser.userid),
                    regarding_case:null,
                    regarding_oppty:null
                }
                this.addActivityDialog = true;
            },
            showEditActivityDialog(item){
                this.activityId = item.id;
                this.editActivityDialog = true;
                this.canEdit = this.deleteCheck(item);
            },
            onEmitAddActivityDialog(val){
                if(val=='add'){
                    this.getListData();
                }
                this.addActivityDialog = false;
            },
            onEmitEditActivityDialog(val){
                if(val=='edit' || val =='delete'){
                    this.getListData();
                }
                this.editActivityDialog = false;
            },
            onDelete(id){
                if(id != null){
                    this.showMessageDialog(i18n.t('ACTIVITY.MESSAGE_DELETE'));
                    this.deleteActivityIds += id;
                    this.isDelete = true;
                }
                else{
                    this.showMessageDialog(i18n.t('ACTIVITY.MESSAGE_DELETE'));
                    for(let i = 0; i < this.selectedRows.length; i++)
                    {
                    this.deleteActivityIds += `${this.selectedRows[i].id},`;
                    }
                    this.deleteActivityIds = this.deleteActivityIds.slice(0,-1);
                }
            },
            // 切換到 list View
            viewList(){
                this.mode = 'list';
                this.getListData();
            },
            // 切換到 calender View
            viewCalender(){
                this.mode = 'calender';
                setTimeout(()=>{
                    this.getMoveData();
                },50)
            },
            async onEmitRefresh(){
                await (this.loading = true);
                await this.getListData();
                await this.cleanAll();
                await (this.loading = false);
            },
            changeCheck(item){
                if(item.completed == "COMPLETED"){
                    return false;
                }else{
                    if(this.isAdmin || this.currentUser.userid == item.created_by.id){
                        return true;
                    }else{
                        return false;
                    }
                }
            },
            // --------------------------------------month--------------------------------------
            // 回傳星期機文字
            weekText(date){
                const daysOfWeek = [i18n.t("ACTIVITY.SUNDAY"),i18n.t("ACTIVITY.MONDAY"), i18n.t("ACTIVITY.TUESDAY"), i18n.t("ACTIVITY.WEDNESDAY"), i18n.t("ACTIVITY.THURSDAY"), i18n.t("ACTIVITY.FRIDAY"), i18n.t("ACTIVITY.SATURDAY")];
                return daysOfWeek[date.weekday];
            },
            // 點more切換到Day View
            viewDay ({ date }) {
                this.focus = date;
                this.type = 'day';
            },
            // 在month抓取滑鼠位置日期
            monthMove(date){
                this.moveDay = date['date'];
            },
            // 在month mousemove監聽事件
            mousemove(event){
                if(this.type != "month"){
                    return;
                }
                if(this.mouseStart == null){
                    this.mouseStart = [event.pageX,event.pageY];
                }
                if(event.pageX == event.pageX && event.pageY==event.pageY){
                    this.mouseStop = [event.pageX,event.pageY];
                }
                if(this.dragEvent && !this.changeCheck(this.dragEvent)){
                    this.isCompleteDrag = true;
                    return;
                }
                if((Math.abs(this.mouseStart[0] - this.mouseStop[0]) + Math.abs(this.mouseStart[1] - this.mouseStop[1]) > 26)){
                    this.isDrag = true;
                }else{
                    this.isDrag = false;
                }
                if(this.$isViewOnly.isViewOnly){
                    return;
                }
                if(this.dragEvent && this.isDrag){
                    let STime = (this.dragEvent.start).split(" ");
                    let ETime = (this.dragEvent.end).split(" ");
                    this.dragEvent.start = this.moveDay + " " + STime[1];
                    this.dragEvent.end = this.moveDay + " " + ETime[1];
                }
                if(this.extendEvent){
                    let backDay = parseInt((this.mouseStart[0] - this.mouseStop[0])/this.extendItemWidth);
                    let md = this.convertDateTime(new Date(this.moveDay)).substr(0,10);
                    let ed = this.convertDateTime(new Date((this.extendEvent.due_date))).substr(0,10);
                    if(md > ed){
                        let time = (this.extendEvent.end).split(" ");
                        let date = this.moveDay;
                        this.extendEvent.end = date + " " + time[1];
                    }
                    else if((this.mouseStart[0] - this.mouseStop[0]) > this.extendItemWidth ){
                        if(backDay>=1 && this.lastBackDay != backDay){
                            let time = (this.extendEvent.end).split(" ");
                            let date = (this.extendEvent.due_date).substr(0,10).split(" ");
                            this.extendEvent.end = this.get_backDay(date,time[1],backDay);
                            this.lastBackDay = backDay;
                        }
                    else if(md == ed){
                        let time = (this.extendEvent.end).split(" ");
                        this.extendEvent.end = ed + " " + time[1];
                    }
                    }else{
                        if(this.lastBackDay != 0 && backDay == 0){
                            let time = (this.extendEvent.end).split(" ");
                            let date = (this.extendEvent.due_date).substr(0,10).split(" ");
                            this.extendEvent.end = date + " " + time[1];
                            this.lastBackDay = 0;
                        }
                    }
                }
            },
            // 抓滑鼠往回的天數
            get_backDay(date,time,backDay){
                let d = new Date(date);
                d = d.setDate(d.getDate() - backDay);
                d =  new Date(d);
                return this.convertDateTime(d).substr(0,10)+" "+time;
            },
            // 抓取 calender move func 資料
            async getMoveData(){
                await this.move();
            },
            // 是否顯示||符號
            showExtendBtn(event, eventParsed,day){
                for(let i in day.week){
                    if(day.week[i].date == eventParsed.end.date){
                        return true;
                    }
                }
                return false;
            },
            // calender event color
            getEventColor(event) {
                return event.color;
            },
            async setToday() {
                this.focus = "";
                this.isSetToday = true;
                await this.getListData();
            },
            // 切換calender抓dom
            async move() {
                await (this.$refs.calendar.move(0));
                await (this.refStatus = this.$refs.calendar);
                await this.getListData();
            },
            async prev() {
                this.$refs.calendar.prev();
                await (this.refStatus = this.$refs.calendar);
                this.getListData();
            },
            async next() {
                this.$refs.calendar.next();
                await (this.refStatus = this.$refs.calendar);
                this.getListData();
            },
            // month mousedown func
            eventDrag ({ event, timed }) {
                window.addEventListener('mousemove', this.mousemove);
                if(this.$isViewOnly.isViewOnly){
                    this.clickDate = event;
                    return;
                }
                if(!this.changeCheck(event)){
                    this.dragEvent = event;
                    return;
                }
                this.clickDate = event;
                if(this.type == "month"){
                    if (event) {
                        this.dragEvent = event;
                    }
                }else{
                    if (event && timed) {
                        this.dragEvent = event;
                        this.dragTime = null;
                        this.extendOriginal = null;
                    }
                }
            },
            // month extend func
            extendActivity(event){
                if(this.$isViewOnly.isViewOnly){
                    return;
                }
                if(!this.changeCheck(event)){
                    return;
                }
                let eventId = event.id;
                this.extendItem = this.$refs[eventId];
                let days = this.get_days(event.start_date,event.due_date);
                if(days>1){
                    this.extendItemWidth = this.extendItem.offsetWidth/days;
                }
                this.extendEvent = event;
                this.clickDate = event;
                window.addEventListener('mousemove', this.mousemove);
            },
            // month mouseup func
            monthUp({ date }){
                setTimeout(()=>{
                    window.removeEventListener('mousemove', this.mousemove);
                    if(this.$isViewOnly.isViewOnly && this.clickDate && this.isDrag== false){
                        this.showEditActivityDialog(this.clickDate);
                        this.clickDate = null;
                        this.cleanAll();
                        return;
                    }
                    if(this.isUpdateCompleted){
                        this.isUpdateCompleted = false;
                        this.cleanAll();
                        return
                    }
                    if(this.dragEvent != null){
                        let event_start_date = new Date(this.dragEvent.start_date).toLocaleDateString("fr-CA", {year:"numeric", month: "2-digit", day:"2-digit"});
                        let event_end_date = new Date(this.dragEvent.due_date).toLocaleDateString("fr-CA", {year:"numeric", month: "2-digit", day:"2-digit"});
                        
                        if((date == event_start_date && date == event_end_date) || this.isDrag== false)
                        {
                            if(this.isCompleteDrag){
                                this.isCompleteDrag = false;
                                return;
                            }
                            this.showEditActivityDialog(this.dragEvent)
                            this.cleanAll();
                            return;
                        }
                    }
                    // let convertDateTime;
                    let convertDate;
                    const convert = new Promise((resolve) => {
                        // convertDateTime = new Date(date);
                        convertDate = this.convertDateTime(new Date(date)).substr(0,10);
                        resolve();
                    })
                    convert
                    .then(()=>{
                        if(this.dragEvent){
                            let startTime = this.convertTime(new Date(this.dragEvent.start));
                            let endTime = this.convertTime(new Date(this.dragEvent.end));
                            this.dragEvent.start = convertDate + ' ' + startTime;
                            this.dragEvent.end = convertDate + ' ' + endTime;
                            let date = {
                                id : this.dragEvent.id,
                                start_date: this.dragEvent.start,
                                due_date: this.dragEvent.end
                            }
                            ActivityDataService.updateActivityDate(date)
                            .then(()=>{
                                this.getListData();
                            })
                        }
                        else if(this.extendEvent){
                            let date = {
                                id : this.extendEvent.id,
                                start_date: this.extendEvent.start,
                                due_date: this.extendEvent.end
                            }
                            ActivityDataService.updateActivityDate(date)
                            .then(()=>{
                                this.getListData();
                            })
                        }
                    })
                    .then(()=>{
                        this.cleanAll();
                    })
                    this.clickDate = null;
                },100)
                
            },
            // month extend days
            get_days(date1, date2){
                let strDate, oDate1, oDate2, result;
                strDate = date1.substr(0,10).split("-");
                oDate1 = new Date(strDate[1] + '/' + strDate[2] + '/' + strDate[0]);
                strDate = date2.substr(0,10).split("-");
                oDate2 = new Date(strDate[1] + '/' + strDate[2] + '/' + strDate[0]);
                result = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24);
                return result+1
            },
            // addHours(date, hours) {
            //     date.setHours(date.getHours() + hours);
            //     return date;
            // },
            // subHours(start, end){
            //     start = new Date (start)
            //     end = new Date (end)
            //     let diff =(end.getTime() - start.getTime()) / 1000;
            //     diff /= (60 * 60);
            //     return Math.abs(Math.round(diff));
            // },
            // 時間轉型 HH:SS
            convertTime(time){
                time = new Date (time);
                return time.toLocaleTimeString("en-US", {
                    hour: '2-digit',
                    minute:'2-digit',
                    hour12:false,
                });
            },
            // 日期轉型 YYYY-MM-DD
            convertDateTime(time){
                time = new Date (time);
                return time.toLocaleTimeString("fr-CA", {
                    year:"numeric", month: "2-digit", day:"2-digit"
                });
            },
            // --------------------------- calendar week/day ---------------------------
            // calendar week/day mousedown func
            otherDrag (tms) {
                const mouse = this.toTime(tms);
                this.clickTime = mouse;
                if (this.dragEvent && this.dragTime === null) {
                    const start = new Date(this.dragEvent.start);
                    this.dragTime = mouse - start;
                }
            },
            // calendar week/day extend
            extendBottom (event) {
                this.createEvent = event;
                this.createStart = new Date(event.start);
                this.extendOriginal = new Date(event.end);
            },
            // calendar week/day mousemove
            otherMove (tms) {
                if(this.dragEvent){
                    if(!this.changeCheck(this.dragEvent)){
                        this.isDrag = true;
                        return;
                    }
                }else if(this.createEvent){
                    if(!this.changeCheck(this.createEvent)){
                        this.cleanAll();
                        return;
                    }
                }
                const mouse = this.toTime(tms)
                if (this.dragEvent && this.dragTime !== null) {
                    const start = new Date(this.dragEvent.start);
                    const end = new Date(this.dragEvent.end);
                    const duration = end - start;
                    const newStartTime = mouse - this.dragTime;
                    const newStart = this.roundTime(newStartTime);
                    const newEnd = newStart + duration;
                    this.dragEvent.start = newStart;
                    this.dragEvent.end = newEnd;
                    this.isDrag = true;
                } 
                else if (this.createEvent && this.createStart !== null) {
                    const mouseRounded = this.roundTime(mouse, false);
                    const min = Math.min(mouseRounded, this.createStart);
                    const max = Math.max(mouseRounded, this.createStart);
                    this.createEvent.start = min;
                    this.createEvent.end = max;
                    this.isDrag = true;
                }
            },
            // calendar week/day mouseup
            otherUp () {
                if(this.$isViewOnly.isViewOnly && this.clickDate && this.isDrag== false){
                    this.showEditActivityDialog(this.clickDate);
                    this.clickDate = null;
                    this.cleanAll();
                    return;
                }
                setTimeout(()=>{
                if(this.isUpdateCompleted){
                    this.isUpdateCompleted = false;
                    this.cleanAll();
                    return;
                }
                if(!this.isDrag && this.dragEvent){
                    this.showEditActivityDialog(this.dragEvent);
                    this.cleanAll();
                    return;
                }
                if(this.dragEvent){
                    if(this.isDrag && !this.changeCheck(this.dragEvent)){
                        this.cleanAll();
                        return;
                    }
                }
                if(this.createEvent){
                    if(this.createEvent && !this.changeCheck(this.createEvent)){
                        this.cleanAll();
                        return;
                    }
                }
                let startDate = null;
                let endDate = null;
                let startTime = null;
                let endTime = null;
                let id = null;
                if(this.createEvent){
                    startDate = this.convertDateTime(new Date(this.createEvent.start));
                    endDate = this.convertDateTime(new Date(this.createEvent.end));
                    startTime = this.convertTime(new Date(this.createEvent.start));
                    endTime = this.convertTime(new Date(this.createEvent.end));
                    id = this.createEvent.id;
                }
                else if(this.dragEvent){
                    startDate = this.convertDateTime(new Date(this.dragEvent.start));
                    endDate = this.convertDateTime(new Date(this.dragEvent.end));
                    startTime = this.convertTime(new Date(this.dragEvent.start));
                    endTime = this.convertTime(new Date(this.dragEvent.end));
                    id = this.dragEvent.id;
                }else{
                    return;
                }
                let date = {
                    id : id,
                    start_date: startDate.substr(0,10)+" "+startTime,
                    due_date: endDate.substr(0,10)+" "+endTime,
                }
                ActivityDataService.updateActivityDate(date)
                .then(()=>{
                    this.getListData();
                })
                this.cleanAll();
                },100)
            },
            // calendar week/day add activity
            dblclickEvent(){
                if(this.$isViewOnly.isViewOnly){
                    return;
                }
                if(this.addActivityDialog || this.editActivityDialog || !this.clickTime){
                    return;
                }
                let createStart = this.roundTime(this.clickTime);
                let createEnd = createStart + 15 * 60 * 1000;
                this.newDateTime = 
                {
                    startDate : this.convertDateTime(new Date(createStart)).substr(0,10) +" "+this.convertTime(new Date(createStart)).replace("24","00"),
                    endDate : this.convertDateTime(new Date(createEnd)).substr(0,10) +" "+this.convertTime(new Date(createEnd)).replace("24","00") ,
                }
                this.showAddActivityDialog();
                this.clickTime = null;
            },
            cleanAll(){
                this.isDrag = false;
                this.dragEvent = null;
                this.extendEvent = null;
                this.mouseStart = null;
                this.mouseStop = null;
                this.extendItem = null;
                this.dragTime = null;
                this.createEvent = null;
                this.createStart = null;
                this.extendOriginal = null;
                this.extendItemWidth = 0;
                this.clickTime = null;
            },
            roundTime (time, down = true) {
                const roundTo = 15; // minutes
                const roundDownTime = roundTo * 60 * 1000;

                return down
                    ? time - time % roundDownTime
                    : time + (roundDownTime - (time % roundDownTime))
            },
            toTime (tms) {
                return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
            },
            getColor: function(data) {
                this.colorQ = data;
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ		
                }
            },
            onMail: function (mail) {
                return "mailto:" + mail;
            },
            onCall: function (cell) {
                return "tel:" + cell;
            },
            getDueTitle(day){
                if(day<0){
                    return i18n.t("DEAL_DETAIL.SOON_DUE") + day*-1 + i18n.t("DEAL_DETAIL.SOON_DUE_1");
                }
                if(day==0){
                    return i18n.t("DEAL_DETAIL.SOON_DUE") + i18n.t("DEAL_DETAIL.SOON_DUE_4");
                }
                if(day>0){
                    return i18n.t("DEAL_DETAIL.SOON_DUE_2")+ day + i18n.t("DEAL_DETAIL.SOON_DUE_3");
                }
            },
        },
        watch:{
            "isAddActivity":async function () {
                if(this.isAddActivity){
                    this.showAddActivityDialog();
                }
            },
            "mode":async function () {
                if(this.mode == "list"){
                    this.viewList();
                }
                if(this.mode == "calender"){
                    this.viewCalender();
                }
            },
            "addActivityDialog" :async function () {
                if(!this.addActivityDialog){
                    this.newDateTime = null;
                    this.$emit('emitAddActivity');
                }
            },
            "type":async function () {
                window.removeEventListener("dblclick",this.dblclickEvent);
                if(this.type!="month"){
                    window.removeEventListener("dblclick",this.dblclickEvent);
                    window.addEventListener("dblclick",this.dblclickEvent);
                }
            },
            "this.$refs.calendar":async function () {
                this.refStatus = this.$refs.calendar;
            },
            "selectPriority":async function () {
                if(!this.firstRun){
                    await this.getListData();
                }
                 
            },
            "selectCompleted":async function () {
                if(!this.firstRun){
                await this.getListData();
                }
            },
            "selectTimeFrame":async function () {
                if(!this.firstRun){
                    await this.searchActivitys();
                }
            },
            "selectType":async function () {
                if(!this.firstRun){
                    await this.getListData();
                } 
            },
            "selectOwner":async function () {
                if(!this.firstRun){
                    await this.getListData();
                }
            },
            "page":async function () {
                await this.getListData();
            },
            "searchActivity":async function () {
                if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.page = 1
                    this.getListData();
                    this.awaitingSearch = false;
                    this.loading_content = false;
                }, 1000);
                }
                this.awaitingSearch = true;
            },
            "sortByDesc":async function () {   
                this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
                for(let i in this.sortByDesc)
                {
                    if(this.sortByDesc[i] == true)
                    {
                        this.sortByForSearch[i] = '-'+this.sortByForSearch[i];
                    }        
                }
                //   this.page = 1;
                await this.getListData();
            },
        }
    })
</script>

<style lang="scss">

.v-input.placeholder-black input::placeholder {
    color:#212121 !important;
}

</style>
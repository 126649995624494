<template lang="pug">
v-card
   v-card-title.d-flex.justify-center.mb-4 {{$t('PRODUCTSETTING.ADD_PRODUCT')}} 
        v-card-text
            v-form(v-model='valid' ref='form')
                    v-row(no-gutters)    
                        v-col(cols='12')
                            v-text-field.v-text-field__slot.height-58(v-model='addProduct.name' :label=`$t('PRODUCTSETTING.NAME')`, no-resize prepend-icon="fa-file-signature" , :rules='formRules.nameRules')
                    v-row(no-gutters)
                        v-col(cols='12')
                            v-combobox.v-text-field__slot.height-58(v-model='addProduct.category' :items="categoryList" item-value="id" item-text="name", :label=`$t('PRODUCTSETTING.CATEGORY')`, no-resize prepend-icon='fa-tag' :rules='formRules.numberRule' ,:ref="'check_category'")
                                template(v-slot:append-outer)
                                    div(v-if="isNew")
                                        v-icon(color="red", size="25") mdi-new-box
                                template(v-slot:item="data")
                                    div.ellipsis(v-if="data.item.id == 0")
                                        span {{ data.item.name }}
                                        v-icon(color="red", size="25") mdi-new-box
                                    div.ellipsis(v-else)
                                        span {{ data.item.name }}
                    v-row(no-gutters)
                        v-col(cols='12')
                            v-combobox.v-text-field__slot.height-58(v-model='addProduct.series' :items="seriesList" item-value="id" item-text="name", :label=`$t('PRODUCTSETTING.SERIES')`, no-resize prepend-icon='fa-tag' :rules='formRules.numberRule' ,:ref="'check_series'")
                                template(v-slot:append-outer)
                                    div(v-if="isNew_2")
                                        v-icon(color="red", size="25") mdi-new-box
                                template(v-slot:item="data")
                                    div.ellipsis(v-if="data.item.id == 0")
                                        span {{ data.item.name }}
                                        v-icon(color="red", size="25") mdi-new-box
                                    div.ellipsis(v-else)
                                        span {{ data.item.name }}
                    v-row(no-gutters)
                        v-col(cols='12')
                            v-text-field.v-text-field__slot.height-58( v-model='addProduct.product_code' :label=`$t('PRODUCTSETTING.PRODUCTCODE')`, no-resize prepend-icon="fa-solid fa-barcode" :rules='formRules.numberRule' validate-on-blur,)
                    v-row(no-gutters)
                        v-col(cols='12')
                            v-text-field.v-text-field__slot.height-58( v-model='addProduct.unit_price' :label=`$t('PRODUCTSETTING.PRICR')`, no-resize prepend-icon="icon-money" :rules='formRules.amountRule',v-show='priceShow|!priceValidate' :ref="'editPrice'" @blur='showFormatPrice')
                            v-text-field.v-text-field__slot.height-58( v-model='formatPrice' prepend-icon='icon-money', :label=`$t('PRODUCTSETTING.PRICR')`,  @click='showEditPrice' @blur='showEditPrice' :readonly='true' v-show='!priceShow && priceValidate')
                    v-row(no-gutters)
                        v-col(cols='12')
                            v-text-field.v-text-field__slot.height-58( v-model='addProduct.unit_cost' :label=`$t('PRODUCTSETTING.COST')`, no-resize prepend-icon="icon-money" :rules='formRules.amountRule', ,v-show='costShow|!costValidate' :ref="'editCost'" @blur='showFormatCost')
                            v-text-field.v-text-field__slot.height-58( v-model='formatCost' prepend-icon='icon-money', :label=`$t('PRODUCTSETTING.COST')`,  @click='showEditCost' @blur='showEditCost' :readonly='true' v-show='!costShow && costValidate')
                    v-row(no-gutters)
                        v-col(cols='12')
                            v-text-field.v-text-field__slot.height-58( v-model.number='addProduct.tax' type='number', :label=`$t('PRODUCTLINEITEM.TAX')`, no-resize prepend-icon="mdi-24px mdi-sale" :rules='formRules.amountRule')
                    v-row(no-gutters)
                        v-col(cols='12')
                            v-text-field.v-text-field__slot.height-58( v-model.number='addProduct.other_charges' type='number', :label=`$t('PRODUCTLINEITEM.OTHER_CHARGES')`, no-resize prepend-icon="mdi-24px mdi-sale" :rules='formRules.amountRule')
                    
                    v-row(no-gutters v-if="addProduct.enable_trq")
                        v-col(cols='12')
                            v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field.v-text-field__slot.height-58(:label=`$t('PRODUCTLINEITEM.FROM')`, no-resize prepend-icon="icon-calendar" v-model='addProduct.from_date'  v-bind="attrs" v-on="on"  hide-details="auto"  :rules='addProduct.to_date ? formRules.fromToRule:[]' class='num_field' readonly clearable  :change='toDateCheck()')
                                v-date-picker(v-model='addProduct.from_date' @input='menu_from = false' no-title scrollable) 
                    v-row(no-gutters v-if="addProduct.enable_trq").pt-3
                        v-col(cols='12')
                            v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field.v-text-field__slot.height-58(:label=`$t('PRODUCTLINEITEM.TO')`, no-resize prepend-icon="icon-calendar" v-model='addProduct.to_date'  v-bind="attrs" v-on="on" hide-details="auto"  :rules='addProduct.from_date ? formRules.fromToRule:[]' class='num_field' readonly clearable  )
                                v-date-picker(v-model='addProduct.to_date' @input='menu_to = false' no-title scrollable :min='minDate(addProduct.from_date)') 
                    
                    v-row(no-gutters)
                        v-col(cols='12')
                            v-textarea.textarea-style.no-border.v-text-field__slot(v-model='addProduct.description' :label=`$t('PRODUCTSETTING.DESCRIPTIONL')`, no-resize ,prepend-icon="mdi-equal-box")
                    v-row(no-gutters)
                        v-col(cols='12')
                            v-checkbox.v-text-field__slot.height-58( v-model='addProduct.enable_trq' :label=`$t('PRODUCTLINEITEM.ENABLE_TRQ')`, no-resize)
            v-card-actions.mt-4.px-0.py-0
                v-row(no-gutters)
                    v-col.pr-2(cols='6')
                        v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                    v-col.pl-2(cols='6')
                        v-btn(:ripple='false' @click='onProductAdd(addProduct)' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.ADD')}}
</template>

<script>
import Vue from "vue";
import ProductDataService from "@/services/ProductDataService";
import segment_typeList from "@/array/the_segmentTypeList";
import i18n from '/common/plugins/vue-i18n.js' 


export default Vue.extend({
  props: {
    copyProduct: {
      type: Object,
      required: false,
    },
    product_type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
        oringalData:null,
        priceValidate:false,
        costValidate:false,
        formatPrice:null,
        priceShow:false,
        formatCost:null,
        costShow:false,
        valid: true,
        search: null,
        isLoading: false,
        categoryList:[],
        seriesList:[],
        isWatch: true,
        typeList: segment_typeList,
        formRules: {
            nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
            amountRule: [(v) => /^\d{1,17}(\.\d{0,2})?$|(^$)|null/.test(v) || i18n.t('RULE.RULE_R_NUM_2')],
            numberRule:[(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
            fromToRule: [
                (v) => !!v || i18n.t('PRODUCTLINEITEM.FROMTOERROR'),
                (v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE')
            ],
        },
        newCate:false,
        newSeries:false,
        addProductDialog: false,
        addProduct : {
            name:null,
            description: null,
            product_code: null,
            unit_price: null,
            unit_cost: null,
            series: null,
            category: null,
            tax:null,
            enable_trq:false,
            other_charges:null,
            from_date:null,
            to_date:null
        },
    };
  },
  async created()
        {   
            this.getcategorylist();
            this.getserieslist();
            if(this.copyProduct){
                this.addProduct = {
                    name:this.copyProduct.name,
                    description: this.copyProduct.description,
                    product_code: this.copyProduct.product_code,
                    unit_price: this.copyProduct.unit_price,
                    unit_cost: this.copyProduct.unit_cost,
                    series: this.copyProduct.series,
                    category: this.copyProduct.category,
                    tax:this.copyProduct.tax,
                    enable_trq:this.copyProduct.enable_trq,
                    other_charges:this.copyProduct.other_charges,
                    from_date:this.copyProduct.from_date,
                    to_date:this.copyProduct.to_date
                }
                this.showFormatPrice();
                this.showFormatCost();
            }
        },
  methods: {
            minDate(date){
                let current_date = new Date(date).getTime();
                let min = current_date + 1000*60*60*24;
                return (new Date(min).getFullYear()).toString() +'-' +(new Date(min).getMonth()+1).toString().padStart(2,0)+'-'+ (new Date(min).getDate()).toString().padStart(2,0)
            },
            toDateCheck(){
                if(this.addProduct.from_date){
                    let FD = new Date(this.addProduct.from_date).getTime();
                    let TD = new Date(this.addProduct.to_date).getTime();
                    if(this.addProduct.to_date && (TD-FD)<=0){
                        this.addProduct.to_date = this.minDate(this.addProduct.from_date);
                    }
                }
            },
            async getcategorylist(){
                await ProductDataService.productcategorylist()
                .then(response => 
                {  
                    this.categoryList = response.data;
                });
            },
            async getserieslist(){
                await ProductDataService.productserieslist()
                .then(response => 
                {  
                    this.seriesList = response.data;
                });
            },
            showFormatPrice(){
                this.priceShow = false;
                if(this.addProduct.unit_price)
                {
                    this.priceValidate = true;
                    this.formatPrice = this.getFormatAmount(this.addProduct.unit_price);
                }
            },
            showEditPrice(){
                this.priceShow = true;
                this.$refs.editPrice.focus();
            },
            showFormatCost(){
                this.costShow = false;
                if(this.addProduct.unit_cost)
                {
                    this.costValidate = true;
                    this.formatCost = this.getFormatAmount(this.addProduct.unit_cost);
                }
            },
            showEditCost(){
                this.costShow = true;
                this.$refs.editCost.focus();
            },
            validate() {
            this.$refs.form.validate();
            },
            validateForm() {
            if (this.$refs.form.validate()) 
                return true;
            else 
                return false;
            },
            onCancel() {
            this.formatPrice = null;
            this.formatCost = null;
            this.$emit("emitAddProductDialog", false);
            },
            async onProductAdd(data) {
                    await(this.$refs.check_category.blur());
                    await(this.$refs.check_series.blur());
                if(this.validateForm()){
                    if(typeof(data.series) == "string")
                        {                            
                            const name = data.series;
                            data.series = {"id":"0","name" : name};
                        }                        
                    if(typeof(data.category) == "string")
                        {                            
                            const name = data.category;
                            data.category = {"id":"0","name" : name};
                        }
                    if(!data.tax){
                        data.tax = 0;
                    }
                    if(!data.other_charges){
                        data.other_charges = 0;
                    }
                    data.type = this.product_type;
                    await ProductDataService.create(data).then((response)=>{
                        response.data.formatPrice = this.getFormatAmount(response.data.unit_price);
                        response.data.formatCost = this.getFormatAmount(response.data.unit_cost);
                        this.$emit("emitAddProductDialog", response.data);

                    })
                        if(this.newSeries)
                        {
                            this.getserieslist();
                            this.newSeries = false;
                        }
                        if(this.newCate)
                        {
                            this.getcategorylist();
                            this.newCate = false;
                        }
                }}
        },
  computed: {
            isNew: function(){
            let datamode = this.addProduct.category;
            if(datamode == "" || datamode == null){
                return false;
            }
            if(typeof (datamode) === 'object'){
                if(datamode.id == 0){
                return true;
                }
                else{
                return false;
                }
            }
            else{
                return true;
            }
            },
            isNew_2: function(){
            let datamode = this.addProduct.series;
            if(datamode == "" || datamode == null){
                return false;
            }
            if(typeof (datamode) === 'object'){
                if(datamode.id == 0){
                return true;
                }
                else{
                return false;
                }
            }
            else{
                return true;
            }
            },
        },
    }
);
</script>
<template lang="pug">
#DataTableSetting.py-4.addTableDialog
    .d-flex.justify-center
        h3 {{$t('GENERAL.ADD')}} {{getItemNameFromList(classTypeList ,customViewData.class_name) }} {{$t('GENERAL.DATASHEET')}} 
    v-stepper(non-linear flat v-model='currentStep').w-100
        v-stepper-header
            v-stepper-step.stepper-btn(step="1" :complete="currentStep > 1") {{$t('TABLESETTING.SET')}} 
            v-divider
            v-stepper-step.stepper-btn(step="2" :complete="currentStep > 2") {{$t('TABLESETTING.FIELD')}}
            v-divider
            v-stepper-step.stepper-btn(step="3") {{$t('TABLESETTING.SEQ')}}
        v-stepper-items(step="1")
            //- 步驟1
            v-stepper-content(step="1")
                v-card.order-box.overflow-hidden(outlined flat)
                    v-card-text.pa-4
                        v-form(ref='form')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field(:label="$i18n.t('TABLESETTING.NAME')" :rules="formRule.nameRules" v-model='customViewData.definition[0].name' required)
                v-row.mt-4
                    v-col.pr-2.pl-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed='depressed' dark='dark') {{$t('GENERAL.CANCEL')}}
                    v-col.pl-2.pr-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='toStepTwo' width='100%' height='40' color='success' depressed='depressed' dark='dark') 
                            v-progress-circular(v-if='tostepTwo_loading' indeterminate size='20')
                            .t-white(v-else) {{$t('GENERAL.NEXT')}}
            //- 步驟2
            v-stepper-content(step="2")
                v-card.order-box(outlined flat)
                    v-card-text.pa-4
                        v-list(flat).pa-0
                            v-list-item-group(v-model='selectedIndex' multiple)
                                v-list-item(v-for='item, index in fields' :key='item.value').pa-0
                                    template(v-slot:default='{ active }')
                                        v-list-item-action.ma-0.mr-4
                                            v-checkbox.ma-0(:input-value='active')
                                        v-list-item-content() {{ item.text }}
                v-row.mt-4
                    v-col.pr-2.pl-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='toStepOne' width='100%' height='40' color='cancel' depressed='depressed' dark='dark') {{$t('GENERAL.PRE')}}
                    v-col.pl-2.pr-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='toStepThree' width='100%' height='40' color='success' depressed='depressed' dark='dark') {{$t('GENERAL.NEXT')}}
            //- 步驟3
            v-stepper-content(step="3")
                v-card.order-box(outlined flat )
                    v-card-text.pa-4
                        draggable.h-100(group="column" v-model='selectedFields' :disabled='loading')
                            v-chip.d-flex.w-100.mt-2(v-for='item, index in selectedFields' :key='item.index' animation="1000") 
                                v-badge.mr-2(inline :content='index+1') 
                                span(v-if='item.is_customfield') {{ item.text }}
                                span(v-else-if='customViewData.class_name == "Contact"') {{ getItemNameFromList(contactsFieldList , item.value) }}
                                span(v-else-if='customViewData.class_name == "Organization"') {{ getItemNameFromList(orgsFieldList , item.value) }}
                                span(v-else-if='customViewData.class_name == "Opportunity"') {{ getItemNameFromList(opptysFieldList , item.value) }}
                                span(v-else-if='customViewData.class_name == "Case"') {{ getItemNameFromList(casesFieldList , item.value) }}
                v-row.mt-4
                    v-col.pr-2.pl-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='currentStep = 2' width='100%' height='40' color='cancel' depressed='depressed' dark='dark') {{$t('GENERAL.PRE')}}
                    v-col.pl-2.pr-0(cols='6')
                        v-btn(v-hide-element='false' :ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark') 
                            v-progress-circular(v-if='loading' indeterminate size='20')
                            .t-white(v-else) {{$t('GENERAL.SAVE')}}
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import draggable from 'vuedraggable';
    import errorDialog from '@/components/Dialog/errorDialog';
    import CustomViewDataService from '@/services/CustomViewDataService';
    import i18n from '/common/plugins/vue-i18n.js' 
    // list
    import classTypeList from "@/array/the_classTypeList";
    import opptysFieldList from "@/array/the_opptysFieldList";
    import orgsFieldList from "@/array/the_orgsFieldList";
    import contactsFieldList from "@/array/the_contactsFieldList";
    import casesFieldList from "@/array/the_casesFieldList";
    
    export default Vue.extend({
        components: {
            errorDialog,
            draggable
        },
        props: {
            className: {
                type: String,
                required: true,
            },
            customViews: {
                type: Array,
                required: true,
            },
            stageType: {
                required: true,
            },
            stageTypeList: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                // list 
                classTypeList: classTypeList,
                opptysFieldList: opptysFieldList,
                orgsFieldList: orgsFieldList,
                contactsFieldList: contactsFieldList,
                casesFieldList: casesFieldList,

                // dialog
                errorDialog: false,
                errorMessage: '',

                // data
                loading: false,
                tostepTwo_loading: false,
                formRule: {
                    nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_NAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ]
                },
                currentStep: 1,
                fields: [],
                selectedIndex: [],
                selectedFields: [],
                isFrom_Step2: false,
                classTypes: [
                    'Contact',
                    'Organization',
                    'Case',
                    'Opportunity'
                ],
                customViewData: {
                    class_name: this.className,
                    definition: [
                        {
                            name: null,
                            is_default: false,
                            fields: []
                        }
                    ],
                    owner: null,
                },
                exist_CustomView: null,
            }
        },
        created(){
            if(this.className == 'Case' || this.className == 'Opportunity'){
                this.customViewData.definition[0].stage_type_id = this.stageType.id;
            } 

            CustomViewDataService.getCustomView(this.className)
            .then(response => {
                this.exist_CustomView = response.data.find(item =>
                    item.owner != null
                )
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
        },
        methods: {
            toStepOne(){
                this.currentStep = 1;
                this.isFrom_Step2 = true;
            },
            async toStepTwo(){
                if(this.$refs.form.validate()){
                    this.isFrom_Step2 = false;
                    await this.getfield();
                    await (this.currentStep = 2);
                }
            },
            toStepThree(){
                if(this.selectedIndex.length > 0){
                    this.getSelectedFields();
                    this.currentStep = 3;
                }
            },
            async getfield(){
                this.fields = [];
                this.tostepTwo_loading = true;
                let class_name = this.customViewData.class_name;
                let currentFieldList;
                switch(class_name){
                    case "Contact":
                        currentFieldList = this.contactsFieldList;
                        break;
                    case "Organization":
                        currentFieldList = this.orgsFieldList;
                        break;
                    case "Opportunity":
                        currentFieldList = this.opptysFieldList;
                        break;
                    case "Case":
                        currentFieldList = this.casesFieldList;
                        break;
                    default:
                        break ;
                }
                let id = null;
                if(this.stageType != null){
                    id = this.stageType.id 
                }
                else{
                    id = null;
                }

                CustomViewDataService.getFieldList(class_name, id)
                .then(response =>{
                    let data = response.data;
                    // 一般欄位
                    for(let i = 0; i<data.fields.length; i++){
                        if (data.fields[i] != 'bc_contact_id'){
                            let value = data.fields[i];
                            let text = this.getItemNameFromList(currentFieldList , value);
                            if(!text) continue;
                            let item = {
                                text: text,
                                value: value,
                                sequence_number: null,
                                is_customfield: false
                            }
                            this.fields.push(item)
                        }
                    }
                    // 客製化欄位
                    if(data.customField != null){
                        for(let prop in data.customField){
                            let item = {
                                text: data.customField[prop].label,
                                value: prop,
                                sequence_number: null,
                                is_customfield: true
                            } 
                            this.fields.push(item)
                        }
                    }
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
                this.tostepTwo_loading = false;
            },
            getSelectedFields(){
                this.selectedFields = [];
                this.selectedIndex.forEach( e => 
                    this.selectedFields.push(this.fields[e])
                )
            },
            clearData(){
                if(this.isFrom_Step2){
                    this.fields = [];
                    this.selectedFields = [];
                    this.selectedIndex = [];
                }
            },
            // dialog
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog(){
                this.errorDialog = false;
            },
            onConfirm() {
                // arrage new table data
                this.loading = true;
                for(let i = 0; i<this.selectedFields.length; i++){
                    this.selectedFields[i].sequence_number = i;
                }
                this.customViewData.definition[0].fields = this.selectedFields;

                // check if user add custom view first or not
                if(this.exist_CustomView != undefined)
                    this.addCustomView();
                else
                    this.addCustomViewFisrtTime();

            },
            addCustomViewFisrtTime(){
                if(this.className == 'Case' || this.className == 'Opportunity'){
                    // add custom view data row
                    CustomViewDataService.addCustomView(this.customViewData)
                    .then(first_res => {
                        
                        // set seed data default to array 
                        let seedData = this.customViews.find(e => e.owner == null);
                        let array = [];
                        this.stageTypeList.forEach(e => {
                            array.push(String(e.id));
                        })
                        seedData.definition[0].is_default = array;
                        
                        // upadate seed data
                        CustomViewDataService.updateCustomView(seedData)
                        .then(() => {
                            this.customViews.push(first_res.data)
                            this.$emit('emitAddTable', first_res.data, true)
                            this.$emit('emitAddTableDialog', true);
                        })
                        .catch(response => {
                            this.showErrorDialog(response)
                        })
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                        this.$emit('emitAddTableDialog', false);
                    })
                    .finally(()=>{
                        this.loading = false;
                    })
                }
                else{
                    // add custom view data row
                    CustomViewDataService.addCustomView(this.customViewData)
                    .then(response => {
                        this.customViews.push(response.data)
                        this.$emit('emitAddTable', response.data, true)
                        this.$emit('emitAddTableDialog', true);
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                        this.$emit('emitAddTableDialog', false);
                    })
                    .finally(()=>{
                        this.loading = false;
                    })
                }
            },
            addCustomView(){
                this.customViewData.owner = this.exist_CustomView.owner;
                this.exist_CustomView.definition.push(this.customViewData.definition[0])
                CustomViewDataService.updateCustomView(this.exist_CustomView)
                .then((response) => {
                    for(let i of response.data.definition){
                        if(i.id == response.data.max_key){
                            this.customViewData.definition[0] = i;
                        }
                    }
                    this.customViewData.id = this.exist_CustomView.id;
                    this.$emit('emitAddTable', this.customViewData);
                    this.$emit('emitAddTableDialog', true);
                })
                .catch(response => {
                    this.showErrorDialog(response)
                })
                .finally(()=>{
                    this.loading = false;
                })
            },
            onCancel(){
                this.$emit('emitAddTableDialog', false);
            },
        }
    });
</script>
import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

const orders_LP_FieldList = [
    // { key:'created_on', name: i18n.t('OPPTYSFIELDLIST.created_on') ,type:'date'},
	// { key:'created_by', name: i18n.t('OPPTYSFIELDLIST.created_by') ,type:'user'},
	// { key:'updated_on', name: i18n.t('OPPTYSFIELDLIST.updated_on') ,type:'date'},
	// { key:'updated_by',name: i18n.t('OPPTYSFIELDLIST.updated_by') ,type:'user'},
	// { key:'owner',name: i18n.t('OPPTYSFIELDLIST.owner') ,type:'user'},
	{ key:'order_name',name: i18n.t(`ORDER.WIADVANCE_NAME`) ,type:'text'},
	{ key:'order_status',name: i18n.t('ORDER.ORDER_STATUS') ,type:'opt'},
	{ key:'licenseproductionitem_sale_name',name: i18n.t('ORDER.ORDER_SALES') ,type:'user'},
	{ key:'remain_day',name: i18n.t('ORDER.FROM_DATE') ,type:'num'},
	{ key:'end_account_name',name: i18n.t('ORDER.ENDACCOUNT') ,type:'text'},
	{ key:'licenseproductionitem_contact_name',name: i18n.t('ORDER.CONTACT_NAME') ,type:'fk_contact'},
	// { key:'licenseproductionitem_contact_second_name',name: i18n.t('ORDER.CONTACT_NAME')+" 2",type:'fk_contact'},
	// { key:'licenseproductionitem_contact_third_name',name: i18n.t('ORDER.CONTACT_NAME')+" 3",type:'fk_contact'},
	{ key:'product_type_name',name: i18n.t('ORDER.TYPE') ,type:'opt'},
	{ key:'billing_frequency',name: i18n.t('ORDER.BILLING_FREQUENCY') ,type:'opt'},
	{ key:'product_code',name: i18n.t('ORDER.PRODUCT_CODE') ,type:'text'},
	{ key:'product_name',name: i18n.t('ORDER.PRODUCT_NAME') ,type:'fk_product'},
	{ key:'quantity',name: i18n.t('ORDER.QUANTITY') ,type:'num'},
	{ key:'start_date',name: i18n.t('ORDER.START_DATE') ,type:'date'},
	{ key:'end_date',name: i18n.t('ORDER.DUE_DATE') ,type:'date'},
	{ key:'order_number',name: i18n.t('ORDER.ORDER_NUMBER') ,type:'text'},
	{ key:'status',name: i18n.t('ORDER.STATUS') ,type:'opt'},
	{ key:'note',name: i18n.t('ORDER.NOTE') ,type:'text'},
	{ key:'sales_order',name: 'SO' ,type:'text'},
	{ key:'sales_order_line',name: 'SO Line' ,type:'text'},
	{ key:'purchase_order',name: "PO" ,type:'text'},
	{ key:'project_code',name: i18n.t('ORDER.PROJECT_CODE') ,type:'text'},
	// { key:'servers',name: i18n.t('ORDER.SERVERS') ,type:'opt'},
];
export default orders_LP_FieldList;
<template lang='pug'>
    #Order.main-page.page-noScroll.detail-page
        v-overlay(v-model='loading' z-index='99' opacity='.6')
            v-progress-circular(indeterminate color='primary')
        div.height_100(v-show='!loading')
            v-breadcrumbs.pa-0.px-3.pb-2.ellipsis(:items='breadcrumbs')
            v-row#page-head.d-flex.align-center.my-0
                v-col(cols='2' lg='4')
                    .d-flex.align-center.title-height
                        h1.my-0.d-flex.align-center.ellipsis(:title='orderDetails.name')
                            .cube-icon.bg-og.mr-3
                                v-icon(color='white' size='22') icon-chatmoney-border
                            span.ellipsis {{ orderDetails.name }}
                v-col(v-if='checkWiadvanceShow()' cols='10' lg='8')
                    v-row.justify-md-end(no-gutters='no-gutters')
                        v-col.ml-2(cols='auto')
                            .btn-group
                                v-btn(@click='showAddDealDialog' width='60' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.COPY')}}
                                v-btn.border-left(v-if='!isProcessComplete' @click='onDelete' width='60' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.DELETE')}}
                        v-col.ml-2(cols='auto')
                            .btn-group(v-if='!isProcessComplete')
                                v-btn( @click='onChangeOwn(orderDetails)' width='100%'  height='30' color='white' depressed tile dark)
                                        span {{$t('GENERAL.CHANGE_OWNER')}}
            #page-inner.pa-3.pt-0
                v-card.card-preface.card-shadow.px-0.py-6(flat)
                    v-row
                        v-col.py-0.px-2.px-md-4.ellipsis(cols='6' md='8')
                            v-card-subtitle.pa-0.t-lightgary {{$t('ORDER.CLIENT_NAME')}}
                            a.f-text-highlight.mb-0.t-primary(v-if='orderDetails.organization != null' :href='"/Account/" + orderDetails.organization.id' target='_blank') {{ orderDetails.organization.name }}
                        v-col.py-0.px-2.px-md-4.border-left(cols='12' md='4')
                            v-list-item.pa-0(href='/Setting' target='_blank')
                                v-list-item-avatar.ma-0.mr-2(color='aliceBlue')
                                    span.mx-auto(v-if="!isPhoto") {{ orderDetails.owner? orderDetails.owner.last_name : '' }}
                                    img(v-else :src='orderDetails.owner.usersetting.photo' :alt='orderDetails.owner.last_name')
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t(`ORDER_DETAIL.${ this.isWiadvance() ? 'WIADVANCE_ORDER_OWNER' : 'OWNER'}`)}}
                                    a(:href='onMail(orderDetails.owner.email)').t-primary.mb-0.f-text-highlight.ellipsis {{ orderDetails.owner ? orderDetails.owner.last_name : '' }} {{ orderDetails.owner? orderDetails.owner.first_name : '' }}

                v-row.account-info.mt-5(no-gutters='no-gutters')
                    v-col.pt-3(cols='12' md='12')
                        v-card.deal-tab-card(flat)
                            v-tabs.main-tabs(v-model='tab' background-color='transparent' show-arrows)
                                v-tabs-slider
                                v-tab(v-for='item in tabs' :key='item.key' :href='`#tab-${item.id}`' @click='getTabName(item.key)')
                                    | {{ item.name }}
                                // - Detail -
                                v-tab-item.pa-3(key='detail' value='tab-1' :transition='false' :reverse-transition='false')
                                    v-form(v-model="valid" ref='formOrderDetail')
                                        v-row(no-gutters).pb-5
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='orderName') {{$t(`ORDER.${ this.isWiadvance() ? 'WIADVANCE_NAME' : 'NAME'}`)}}
                                                    v-text-field#orderName.ellipsis(v-model='orderDetails.name' solo='solo'  :readonly='isDetailReadonly' :rules="formRule.nameRules"  :ref="'editname'")
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='checkWiadvanceShow() && isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                            
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='amount') {{$t('ORDER.AMOUNT')}}
                                                    v-text-field(v-model='orderDetails.amount' solo='solo'  :rules='formRule.amountRule' v-show='!isDetailReadonly&&amountShow|!amountValidate' :ref="'editAmount'" @blur='showFormatAmount("amount")')
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                                    v-text-field(v-model='formatAmount' solo='solo' @click='showEditAmount("amount")' @blur='showEditAmount("amount")' :readonly='true' v-show='!amountShow|!amountShow&&amountValidate')
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='checkWiadvanceShow() && isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                            //- 客戶名稱
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label {{$t('ORDER.CLIENT_NAME')}}
                                                        v-combobox(v-if='!isDetailReadonly && !isNew.order_org' v-model="orderDetails.organization" solo :items="organizationList" item-text="name" item-value="uqNumber" :search-input.sync="search_order_org" :loading="isLoading.order_org" )
                                                            template(v-slot:no-data)
                                                                v-list-item()
                                                                    span {{$t("ORDER_DETAIL.NO_REGARDING")}}
                                                            template(v-slot:append-outer)
                                                                div.mr-1(v-if="isOrgNew")
                                                                    v-icon(color="red", size="25") mdi-new-box
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                                            template(v-slot:item="data")
                                                                div.ellipsis(v-if="data.item.id == 0")
                                                                    span {{ data.item.name }}
                                                                    v-icon(color="red", size="25") mdi-new-box
                                                                div.ellipsis(v-else)
                                                                    span(v-if="isWiadvance() && data.item.customer_code") {{ data.item.name }}  [ {{ data.item.customer_code }} ]
                                                                    span(v-else) {{ data.item.name }}
                                                        .d-flex.justify-space-between.ellipsis.w-100(v-if='isDetailReadonly')
                                                            a.t-primary.ellipsis(v-if="orderDetails.organization != null && isDetailReadonly" :href="'/Account/' + orderDetails.organization.id" :title='orderDetails.organization.name') {{ orderDetails.organization.name }}
                                                            v-icon.ml-auto(size='18' v-if='checkWiadvanceShow() && isDetailReadonly && !isProcessComplete' v-hide-element @click='onEditDetail()') icon-edit
                                            //- 聯絡人
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label {{$t('ORDER.CONTACT_NAME')}}
                                                        v-text-field.pr-2(v-if='!isDetailReadonly && isNew.contact' v-model="newPri.lName" solo :label=`$t('GENERAL.LAST_NAME')` large="large" :rules='formRule.contactnNameRules' @input='cleanFirstName(newPri)')
                                                        v-text-field(v-if='!isDetailReadonly && isNew.contact' v-model="newPri.fName" solo :label=`$t('GENERAL.FIRST_NAME')` :rules='formRule.contentLenRule50' large="large")
                                                            template(v-slot:append-outer)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close
                                                                v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                                        v-combobox#primary_contact(v-if='!isDetailReadonly && !isNew.contact' v-model="orderDetails.contact" solo :items="contactList" item-text="searchStr" item-value="id" :search-input.sync="search_contact" :loading="isLoading.contact"  style='z-index: 100;' :menu-props="{openOnClick: true, closeOnClick: true}"  :no-data-text=`$t('RELATEDCONTACTS.NO_CONTACT')`  )
                                                            template( v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close
                                                                v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                                            template(v-slot:prepend-item)
                                                                v-list-item
                                                                    v-btn(width='120' height='32' color='blue' plain border-0 @click='isNew.contact = true')
                                                                        v-icon.mr-1(size='14') icon-add
                                                                        span {{$t('ADD_ORDER.NEW_CONTACT')}}
                                                            template(v-slot:item='data')
                                                                v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                                                    v-icon(v-if="data.item.image == null") mdi-account
                                                                    img(v-else :src='data.item.image')
                                                                .t-black {{ data.item.full_name }} 
                                                                .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                                            template(v-slot:selection='data')
                                                                .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                                                        .d-flex.justify-space-between.ellipsis(:class='isDetailReadonly?"w-100":""')
                                                            a.t-primary.ellipsis(v-if="orderDetails.contact != null && isDetailReadonly" :href="'/Contacts/'+ orderDetails.contact.id") {{ orderDetails.contact.__chineseName__ }}
                                                            v-icon.ml-auto(v-if='checkWiadvanceShow() && isDetailReadonly' size='18' v-hide-element @click='onEditDetail()') icon-edit

                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='shipping_method') {{$t('ORDER.SHIPPING_METHOD')}}
                                                    v-select#shipping_method.no-border(v-model='orderDetails.shipping_method' :menu-props='{closeOnClick:true}' solo :items='orderShippingMethodsList' item-text='name' item-value='key' :readonly='isDetailReadonly')
                                                        template(v-slot:append-outer v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close
                                                            v-icon(size='18' v-if='checkWiadvanceShow() && isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='payment_term') {{$t('ORDER.PAYMENT_TERM')}}
                                                    v-select#payment_term.no-border(v-model='orderDetails.payment_term' :menu-props='{closeOnClick:true}' solo :items='paymentTermsList' item-text='name' item-value='key' :readonly='isDetailReadonly')
                                                        template(v-slot:append-outer v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close
                                                            v-icon(size='18' v-if='checkWiadvanceShow() && isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label(for='shipping_term') {{$t('ORDER.SHIPPING_TERM')}}
                                                    v-select#shipping_term.no-border(v-model='orderDetails.shipping_term' :menu-props='{closeOnClick:true}' solo :items='orderShippingTermList' item-text='name' item-value='key' :readonly='isDetailReadonly')
                                                        template(v-slot:append-outer v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close
                                                            v-icon(size='18' v-if='checkWiadvanceShow() && isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : " "')
                                                        label(for='billing_address') {{$t('ORDER.BILLING_ADDRESS')}}
                                                        v-text-field#billing_address.ellipsis(v-if='!isDetailReadonly' v-model='orderDetails.billing_address' solo='solo' :readonly='isDetailReadonly')
                                                            template(v-slot:append v-if='!isProcessComplete')
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                        .d-flex.justify-space-between.w-100.ellipsis(v-else)
                                                            a.t-primary.ellipsis(:href='onGoogleMap(orderDetails.billing_addresss)') {{ orderDetails.billing_address }}
                                                            v-icon(size='18' v-if='checkWiadvanceShow() && isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : " "')
                                                        label(for='shipping_address') {{$t('ORDER.SHIPPING_ADDRESS')}}
                                                        v-text-field#shipping_address.ellipsis(v-if='!isDetailReadonly' v-model='orderDetails.shipping_address' solo='solo' :readonly='isDetailReadonly')
                                                            template(v-slot:append v-if='!isProcessComplete')
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                        .d-flex.justify-space-between.w-100.ellipsis(v-else)
                                                            a.t-primary.ellipsis(:href='onGoogleMap(orderDetails.shipping_address)') {{ orderDetails.shipping_address }}
                                                            v-icon(size='18' v-if='checkWiadvanceShow() && isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12')
                                                p.d-flex.align-center.input-has-label
                                                    label(for='description') {{$t('ORDER.DESCRIPTION')}}
                                                    v-textarea#customer_needs.textarea-style(v-model='orderDetails.description' solo='solo' :readonly='isDetailReadonly' no-resize)
                                                        template(v-slot:append v-if='!isProcessComplete')
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_orderDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_orderDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='checkWiadvanceShow() && isDetailReadonly'  v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_orderDeatil' indeterminate color='primary')
                                // - product -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isProductTab")
                                    v-btn(@click='onAddProduct()' v-if='tenant_type!="IT"' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1(size='14') icon-add
                                        span {{$t('PRODUCTSETTING.ADD_PRODUCT')}}
                                v-tab-item.pa-3.scrollbar(style="overflow-y: hidden;" key='product' value='tab-2' :transition='false' :reverse-transition='false')
                                    industryProductLineItem(v-if='tenant_type=="IT"' :order='orderDetails' ref='orderProductLines', @emit_fromProdcutLine='onEmit_fromProdcutLine')
                                    orderProductLineItem(v-else :isAddProduct='isAddProduct' @emitAddProduct='onEmitAddProduct' @emit_editFromProdcutLine='onEmit_editFromProdcutLine' :order='orderDetails' ref='productLines')
                                // - timeline -
                                v-tab-item.px-10.py-6(key='timeline' value='tab-3' :transition='false' :reverse-transition='false')
                                    v-row.sheet-box.my-5(v-if='orderTimeLines.length == 0')
                                        v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                h5.ma-0.t-black {{$t('ORDER_DETAIL.NO_TIMELINE')}}
                                    div.scrollTimeLine(:style='timeline_max_height')
                                        v-timeline.pt-0(dense align-top)
                                            v-timeline-item(v-for='(item, index) in showTimelines' :key='index' :color='item.type == "DUE_ACTIVITY" ? "#FFD306" : "success"' :icon='getTimeLineIcon(item.type)' small right fill-dot v-if='item.show == true && item.show_index<timeline_count')
                                                //- Time Line Item - STAGE and AMOUNT and FIELDHISTORY
                                                v-card.px-3.py-1(v-if="item.type === 'STAGE'|| item.type === 'AMOUNT' || item.type === 'FIELDHISTORY' || item.type === 'ACTIVITY_FIELDHISTORY'" flat outlined)
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.mr-3
                                                                | {{ item.timeConvert }} ・
                                                                | {{ item.changed_by }}
                                                        v-col(cols='12')
                                                            p.mb-1.t-black  {{ item.content }}
                    //- timeline
                    //- v-col.v-card.pt-4.pt-md-0.pl-md-4(cols='12' md='4')
                    //-     //- timeline
                    //-     v-card.mt-3.order-min-height(flat)
                    //-         v-card(flat)
                    //-             v-card-title.d-flex.justify-space-between.align-center.px-3.py-2
                    //-                 .d-flex.align-center.my-0
                    //-                     .cube-icon.small.bg-pink.mr-2
                    //-                         v-icon(size='21' color='white') mdi-clock
                    //-                     span {{$t('ORDER_DETAIL.TIMELINE')}}
                    //-             v-card-text.py-0
                    //-                 v-row.sheet-box.my-5(v-if='orderTimeLines.length == 0')
                    //-                     v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                    //-                         v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    //-                             h5.ma-0.t-black {{$t('ORDER_DETAIL.NO_TIMELINE')}}
                    //-                 //- v-tabs.borderBtn-tabs(v-else height='32' background-color='white' :hide-slider='true' :centered='true' :show-arrows='true')
                    //-                 //-     v-tab(v-if="!loading" v-for='item in timeLineType' :key='item.key' :id='`Tlt-${item.key}`'  @click='onChangeTltSelected' :class='changeSelectedColor(item.key)')
                    //-                 //-             | {{ item.name }}
                    //-                 div.pa-3.pt-5.scrollTimeLine(:style='timeline_max_height')
                    //-                     v-timeline.pt-0(dense align-top)
                    //-                         v-timeline-item(v-for='(item, index) in showTimelines' :key='index' :color='item.type == "DUE_ACTIVITY" ? "#FFD306" : "success"' :icon='getTimeLineIcon(item.type)' small right fill-dot v-if='item.show == true && item.show_index<timeline_count')
                    //-                             //- Time Line Item - STAGE and AMOUNT and FIELDHISTORY
                    //-                             v-card.px-3.py-1(v-if="item.type === 'STAGE'|| item.type === 'AMOUNT' || item.type === 'FIELDHISTORY' || item.type === 'ACTIVITY_FIELDHISTORY'" flat outlined)
                    //-                                 v-row.justify-space-between.align-center(no-gutters)
                    //-                                     v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                    //-                                         span.mr-3
                    //-                                             | {{ item.timeConvert }} ・
                    //-                                             | {{ item.changed_by }}
                    //-                                     v-col(cols='12')
                    //-                                         p.mb-1.t-black  {{ item.content }}
        v-dialog(v-model='changeOwnDialog' max-width='500' v-if='changeOwnDialog')
            change-own(@emitChangeOwnDialog='onEmitChangeOwnDialog' :owner-data='changeOwnData' :other-data='otherData' change='orders')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='addOrderDialog' max-width='600' v-if='addOrderDialog')
            add-order(@emitAddOrderDialog='onEmitAddOrderDialog' :add-order='addOrderData')
        v-dialog(v-model='redirectDialog' width='360' content-class='statusDialog')
            redirect-dialog(@emitRedirectDialog='onEmitRedirectDialog' :message='message' :copyDataType='"order"' :copyDataId='copyDataId')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
</template>
<script>
    import i18n from '/common/plugins/vue-i18n.js';
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import orderProductLineItem from "@/components/Common/orderProductLineItem";
    import industryProductLineItem from "@/components/Common/industryProductLineItem";
    // array
    import forecast_category_List from "@/array/the_forecastCategoryList";
    import paymentTermsList from "@/array/the_paymentTermsList";
    import orderShippingMethodsList from "@/array/the_orderShippingMethodsList";
    // service
    import OrganizationDataService from '../services/OrganizationDataService';
    import ContactDataService from '../services/ContactDataService';
    import PreferenceService from '../services/PreferenceService';
    // import PersonalSettingService from '../services/PersonalSettingService';
    import OrderDataService from "@/services/OrderDataService";
    // dialog
    import messageDialog from '@/components/Dialog/messageDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import changeOwn from '@/components/Dialog/changeOwn';
    import addOrder from '@/components/Dialog/Order/addOrder';
    import redirectDialog from '@/components/Dialog/redirectDialog';
    import successDialog from '@/components/Dialog/successDialog';
    
    export default {
        name: 'Order',
        components: {
            loadingOverlay,
            messageDialog,
            errorDialog,
            changeOwn,
            addOrder,
            redirectDialog,
            successDialog,
            orderProductLineItem,
            industryProductLineItem
        },
        data() {
            return {		
                loading: false,
                isProcessComplete: false,
                breadcrumbs: [],
                stepContent: false,
                tab: null,
                tabs: [
                    {
                        id: 1,
                        key: 'detail',
                        name: i18n.t('ORDER_DETAIL.DETAIL')
                    },
                    {
                        id: 2,
                        key: 'product',
                        name: i18n.t('ORDER_DETAIL.PRODUCT')
                    },
                    {
                        id: 3,
                        key: 'timeline',
                        name: i18n.t('ORDER_DETAIL.TIMELINE')
                    },
                ],
                formRule: {
                    contactnNameRules: [
                            (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME_2'),
                            (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    opptyContactRules: [
                            (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'),
                    ],
                    nameRules: [
                            (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_NAME'),
                            (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                        ],
                    contactRules: [
                            (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'),
                            (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                        ],
                    customerBudgetRule: [
                        (v) => /^\d*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
                        (v) => /^.{0,17}$/.test(v) || i18n.t('RULE.RULE_17'),
                    ],
                    amountRule: [
                        (v) => /^\d{1,17}(\.\d{0,2})?$/.test(v) || i18n.t('RULE.RULE_R_NUM_2')
                    ],
                    textRule: [(v) => (v || '').length <=90 || i18n.t('RULE.RULE_90')],	
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                    companyRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
                    ],
                },
                timeLineType: [{
                        id: 1,
                        key: 'all',
                        name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_ALL')
                    },
                    {
                        id: 2,
                        key: 'moneyChange',
                        name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_AMOUNT')
                    },
                    {
                        id: 3,
                        key: 'stepChange',
                        name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_STAGE')
                    },
                    {
                        id: 4,
                        key: 'fieldHistory',
                        name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_FIELD')
                    },
                    {
                        id: 5,
                        key: 'mail',
                        name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_MAIL')
                    },
                    {
                        id: 6,
                        key: 'sms',
                        name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_SMS')
                    },
                    {
                        id: 7,
                        key: 'note',
                        name: i18n.t('DEAL_DETAIL.TIMELINE_TAB_NOTE')
                    },
                    {
                        id: 8,
                        key: 'activity',
                        name: i18n.t('Bower_Title.Activity')
                    }
                ],
                orderShippingMethodsList: orderShippingMethodsList,
                paymentTermsList: paymentTermsList,
                orderShippingTermList: [
                    { key: "FOB", name: 'FOB' },
                    { key: "free_shipping", name: i18n.t('SHIPPINH_TERM.FREE_SHIPPING') },
                ],
                forecast_category_List: forecast_category_List,
                orderDetails: {owner:{email:''}},
                isDetailReadonly: true,
                // 詳細資訊 edit
                loading_orderDeatil: false,
                originOrderDetails: {},
                isNew: {
                    contact: false,
                    order_org: false,
                },
                organizationList: [],
                contactList: [],
                search_order_org: null,
                search_contact: null,
                isLoading: {
                    contact: false,
                    order_org: false,
                    parent_case: false
                },
                formatAmount: null,     
                amountShow: false, 
                amountValidate: true,
                comboInput:{
                    contact: null,
                    order_org: null,
                    parent_case: null
                },
                newPri:{
                    fName: null,
                    lName: null,
                },
                orderTimeLines: [],
                activityTimeline:[],
                showTimelines: [],
                timeline_count:0,
                timelinetabData: {},
                timeline_tab: ['all'],
                timeline_max_height: '632px',
                target_id: this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id'),
                changeOwnDialog: false,
                messageDialog: false,
                message: '',
                errorDialog: false,
                errorMessage: '',
                addOrderDialog: false,
                redirectDialog: false,
                successDialog: false,
                copyDataId:null,
                addOrderData: {},
                valid: true,
                succMode: '',
                itemPerPage: 12,
                // Tab
                isPhoto: false,
                otherData: {},
                showDetailEditBtn: true,
                // for product
                isProductTab:false,
                tenant_type:'default',
                isAddProduct: false,
                isTechUser: false,
                is_staff: false,
        }
    },
    async created(){
        await PreferenceService.getINDUSTRY().then((response)=>{
            if(response.data){
                this.tenant_type = response.data;
            }
        });
        await OrderDataService.getOrderDetail(this.target_id)
        .then(response => {
            this.orderDetails = response.data
            const title = localStorage.getItem('title');
            document.title = title+' : '+this.orderDetails.name +' – UpDay';
            this.changeOwnData = this.orderDetails.owner;

            this.formatAmount = this.getFormatAmount(this.orderDetails.amount)

            this.breadcrumbs =  [{
                    text: i18n.t(`BREADCRUMBS.${this.isWiadvance() ? 'WIADVANCE_Order' : 'Order'}`),
                    disabled: false,
                    href: '/Orders',
                },
                {
                    text: this.orderDetails.name,
                    disabled: true,
                    href: 'Deal',
                }
            ]    
        })
        .catch(response => {
            this.showErrorDialog(response);
            // Redirect to PermissionError page if error status is 403
            if (response.response.status == 403)
            {
                this.$router.replace('/PermissionError');
            }
            else if(response.response.status == 404)
            {
                this.$router.replace('/PageNotFound');
            }
        });

        await this.getOrderTimeline(this.target_id);
        await this.showTimlinesCheck();

        if (this.orderDetails.owner.usersetting.photo != null) {
            this.isPhoto = true;
        }

        window.addEventListener('resize', this.onResize)
        this.onResize();
        this.checkTechUser();
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize)
    },
    computed: {
        isOrgNew: function(){
            if(this.orderDetails.organization == "" || this.orderDetails.organization == null){
                return false;
            }
            if(typeof (this.orderDetails.organization) === 'object'){
                if(this.orderDetails.organization.id == 0){
                    return true;
                }
                else{
                    return false;
                }
            }else{
                return true;
            }
        },
    },
    methods: {
        async confirmEditDetail () {
            if(this.validateForm()){
                await (this.loading_orderDeatil = true);

                let data = JSON.parse(JSON.stringify(this.orderDetails));
                let saveOwner = data.owner

                if(data.organization == null)
                    data.organization = {};
                if(data.contact == null || data.contact == '')
                    data.contact = {};
                // order organization
                if(this.comboInput.order_org != data.organization.name && typeof(data.organization) == 'string')
                {
                    let newOrg = {
                        id: "-1", 
                        name: this.comboInput.order_org, 
                        company_number: "0"
                    };
                    data.organization = newOrg;
                }
               
                // order contact
                if(this.isNew.contact && this.newPri.lName){
                    data.contact.id = 0;
                    data.contact.last_name = this.newPri.lName;
                    if(this.newPri.fName != null)
                        data.contact.first_name = this.newPri.fName;
                    else 	
                        data.contact.first_name = null;
                }
                else if(this.isNew.contact && !this.newPri.lName)
                {
                    data.contact = {};
                }
                else if(this.comboInput.contact == "")
                {
                    data.contact = {};
                }

                // if(this.isNew.contact && this.newPri.lName){
                //     if(data.contact == null){
                //         data.contact = {
                //             id: 0,
                //             last_name: this.newPri.lName,
                //             first_name: this.newPri.fName != null ? this.newPri.fName : null
                //         }
                //     }else{
                //         data.contact.id = 0;
                //         data.contact.id.last_name = this.newPri.lName;
                //         if(this.newPri.fName != null)
                //             data.contact.id.first_name = this.newPri.fName;
                //         else
                //             data.contact.id.first_name = null;
                //     }
                // }else if (this.isNew.contact && !this.newPri.lName) {
                //     data.contact = {};
                // }
                await OrderDataService.updateOrderDetail(data)
                .then(response => {
                    this.orderDetails = response.data
                    this.orderDetails.owner = saveOwner
                    this.isDetailReadonly = true;
                    this.showDetailEditBtn = true;

                    this.defaultDetail();
                    this.refreshTimeLine();
                })
                .catch(response => {
                    console.log(response)
                    this.showErrorDialog(response);
                })
                await (this.loading_orderDeatil = false);
            }
        },
        async getOrderTimeline(id) {
            await OrderDataService.timeline(id)
            .then(response => {	
                this.orderTimeLines = response.data
                this.orderTimeLines.forEach((item,index) => {                    
                    // item.timeConvert = this.getFormatTime(new Date(item.time+"Z"))
                    item.timeConvert = this.getFormatTime(new Date(item.time))
                    item.show = true
                    item.show_index = index
                })
                this.originorderTimeLines = JSON.parse(JSON.stringify(this.orderTimeLines));
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
        },
        cancelEditDetail () {
            this.showDetailEditBtn = true;
            this.isDetailReadonly = true;
            this.orderDetails = this.originOrderDetails
            this.formatAmount = this.getFormatAmount(this.originOrderDetails.amount);
            this.amountValidate = true;
            this.defaultDetail();
        },
        showEditAmount (data) {
            if(!this.isDetailReadonly && data==='amount')
            {
                this.amountShow = true
                this.$refs.editAmount.focus()
            } 
        },
        showFormatAmount (data) {
            if(data==='amount'){
            this.amountShow = false;
            this.formatAmount = this.getFormatAmount(this.orderDetails.amount);
            this.amountValidate = this.$refs.editAmount.validate();}
        },
        async onChangeOwn(data) {
            await (this.otherData.order_id = data.id);
            await (this.otherData.name = data.name);
            await (this.changeOwnDialog = true);
        },
        onEmitChangeOwnDialog(val) {
            this.changeOwnDialog = false;
            if (val) {
                this.succMode = "changeOwn";
                this.showSuccessDialog();
            }
        },
        showAddDealDialog () {
            this.addOrderDialog = true;
            this.copyDataId = "order"
            this.addOrderData = {
                name: this.orderDetails.name,
                organization: this.orderDetails.organization,
                contact: this.orderDetails.contact,
                amount: this.orderDetails.amount,
                shipping_method: this.orderDetails.shipping_method,
                shipping_term: this.orderDetails.shipping_term,
                payment_term: this.orderDetails.payment_term,
                billing_address: this.orderDetails.billing_address,
                shipping_address: this.orderDetails.shipping_address,
            }
        },
        showSuccessDialog() {
            this.successDialog = true;
        },
        async onEmitSuccessDialog() {
            this.successDialog = false;
            if (this.succMode == 'changeOwn') {
                await this.refreshTimeLine();
            }
            if (this.succMode == 'DELORDER') {
                this.$router.replace('/Orders');            
            }
            this.succMode = '';
        },
        showMessageDialog (message) {
            this.messageDialog = true;
            this.message = message;
        },
        showErrorDialog(message) {
            this.errorDialog = true;
            this.errorMessage = message;
        },
        onEmitAddOrderDialog (val) {
            this.addOrderDialog = false;
            if (val) {
                if(this.copyDataId == null){
                    this.showSuccessDialog();
                }else{
                    this.redirectDialog = true
                    this.message = ""
                    this.copyDataId = this.addOrderData.id
                }
            }
        },
        onEditDetail () {
            this.showDetailEditBtn = false;
            this.isDetailReadonly = false;
            this.originOrderDetails = JSON.parse(JSON.stringify(this.orderDetails));
        },
        onDelete () {
            this.succMode = "DELORDER";
            this.showMessageDialog(i18n.t('ORDER.MESSAGE_DEL_ORDER'));
        },
        defaultDetail(){
            this.isNew = {
                contact: false,
            }
            this.newPri = {
                fName: null,
                lName: null,
            }
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
        onEmitMessageDialog(val) {
            this.messageDialog = false;
            if(val){
                if(this.succMode =="DELORDER"){
                    OrderDataService.delete(this.target_id);                   
                }
                this.successDialog = true; 
            }
        },
        onEmitRedirectDialog(){
            this.redirectDialog = false
            this.copyDataId = null      
        },
        getTabName (key) {
            if(key == 'product')
                this.isProductTab = true;
            else
                this.isProductTab = false;
        },
        getTimeLineIcon: function(type){
            let icon = "";
            switch (type) {
                case "EMAIL":
                    icon = 'icon-mail';
                    break;
                case "NOTE":
                    icon = 'icon-note';
                    break;
                case "STAGE":
                    icon = 'mdi-progress-upload';
                    break;
                case "AMOUNT":
                    icon = 'mdi-currency-usd';
                    break;
                case "FIELDHISTORY":
                    icon = 'mdi-history';
                    break;
                case "SMS":
                    icon = 'mdi-message-text';
                    break;
                case "ACTIVITY_FIELDHISTORY":
                    icon = 'icon-calendar';
                    break;
                case "DUE_ACTIVITY":
                    icon = 'mdi-bell-ring';
                    break;
                default:
                    icon = 'mdi-help-circle';
                    break;
            }
            return icon;
            },
        changeSelectedColor(key){
            if(this.timeline_tab.includes(key)){
                return 'v-tab tabBtn-selected';
            }
            else{
                return 'v-tab tabBtn-unselect';
            }
        },
        onChangeTltSelected(){
            let choosedbtn = document.getElementById(event.target.id);
            let choosedbtnkey = choosedbtn.getAttribute('id').substring(4);
            if(choosedbtnkey == 'all'){
                this.timeline_tab = ['all'];
            }
            else{
                if(this.timeline_tab.includes('all')){
                    this.timeline_tab.pop();
                    this.timeline_tab.push(choosedbtnkey);
                }
                else if(this.timeline_tab.includes(choosedbtnkey)){
                    let index = this.timeline_tab.indexOf(choosedbtnkey);
                    this.timeline_tab.splice(index,1);
                    if(this.timeline_tab.length == 0)
                        this.timeline_tab = ['all'];
                }
                else{
                    this.timeline_tab.push(choosedbtnkey);
                    if(this.timeline_tab.length == (this.timeLineType.length-1)) 
                        this.timeline_tab = ['all'];
                }
            }
            let user = JSON.parse(window.localStorage.getItem('user'));
            let userid = user['userid'];
            this.timelinetabData['user_id'] = userid;
            this.timelinetabData['Opportunity'] = this.timeline_tab;
        },
        arrangeTimeLine(timelineList){
            let havePinned = timelineList.filter(this.pinnedTrue);
            let noPinned = timelineList.filter(this.pinnedFalse);
            havePinned = havePinned.sort(function(a, b){
                let aTime = new Date(a.time+"Z");
                let bTime = new Date(b.time+"Z");
                return bTime-aTime });
            noPinned = noPinned.sort(function(a, b){
                let aTime = new Date(a.time+"Z");
                let bTime = new Date(b.time+"Z");
                return bTime-aTime });
            return havePinned.concat(noPinned);
        },
        validateForm(){
            return this.$refs.formOrderDetail.validate();
        },
        cleanFirstName(field){
            if(field.lName == null || field.lName == "")
                field.fName = null;
        },
        // timeline
        getFormatTime(data){
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
                let today  = data;
                if(this.$i18n.locale == "zh_TW")
                {
                   return today.toLocaleDateString("zh-TW", options); 
                }else
                {
                    return today.toLocaleDateString("en-US", options);
                }                
        },
        showTimlinesCheck(){
            this.showTimelines = this.orderTimeLines;
            for(let i = 0,ll = this.showTimelines.length ; i<ll ; i++){
                // 預設show全部false
                this.showTimelines[i]['show'] = false;
                // 到期活動 & 釘選改為 true
                if(this.showTimelines[i]['type'] == 'DUE_ACTIVITY')
                    this.showTimelines[i]['show'] = true;
                if(this.showTimelines[i]['pinned'] !== undefined && this.showTimelines[i]['pinned'] == true)
                    this.showTimelines[i]['show'] = true;
                // 當篩選為all 改為 全true
                if(this.timeline_tab.includes('all'))
                    this.showTimelines[i]['show'] = true;
                // 依篩選調整顯示項目
                if(this.timeline_tab.includes('moneyChange')){
                    if(this.showTimelines[i]['type'] == 'AMOUNT')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('stepChange')){
                    if(this.showTimelines[i]['type'] == 'STAGE')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('fieldHistory')){
                    if(this.showTimelines[i]['type'] == 'FIELDHISTORY')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('mail')){
                    if(this.showTimelines[i]['type'] == 'EMAIL')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('sms')){
                    if(this.showTimelines[i]['type'] == 'SMS')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('note')){
                    if(this.showTimelines[i]['type'] == 'NOTE')
                        this.showTimelines[i]['show'] = true;
                }
                if(this.timeline_tab.includes('activity')){
                    if(this.showTimelines[i]['type'] == 'ACTIVITY_FIELDHISTORY')
                        this.showTimelines[i]['show'] = true;
                }
            }
            let showList = this.showTimelines.filter(el => el.show == true);
            for (let i in showList){
                showList[i]['show_index'] = parseInt(i);
            }
            this.timeline_show_count = showList.length;
            this.timeline_count = 100;
        },
        async refreshTimeLine(){
            await this.getOrderTimeline(this.target_id);
            await this.showTimlinesCheck();
        },
        // Common Function
        onCall: function (cell) {
            return 'tel:'+cell;
        },
        onMail: function (mail) {
            return 'mailto:'+mail;
        },
        onGoogleMap: function (address) {
            return "https://www.google.com.tw/maps/place/" + address;
        },
        onResize: function () {
            let windowH = window.innerHeight - 420; //table高度
            let objH = 48;
            this.itemPerPage = parseInt(windowH/objH);
            setTimeout(()=>{
                let timelineHeight = document.getElementsByClassName('v-window__container')[0].offsetHeight;
                this.timeline_max_height = `max-height:${timelineHeight-159}px;`
            },100);
        },
        // for product
        onAddProduct(){
            this.isAddProduct = true;
        },
        onEmitAddProduct(){
            this.isAddProduct = false;
        },
        async onEmit_editFromProdcutLine(type, data){
            if(type == 'system_compute'){
                this.orderDetails.amount = await Number(data);
                
                await this.confirmEditDetail();
                await this.showFormatAmount("amount");
            }
            else if(type == 'tax_option'){
                this.orderDetails.tax_option = await data;
                await this.confirmEditDetail();
                await this.$refs.productLines.updateProductLine();
            }
        },
        async onEmit_fromProdcutLine(type,option,amount){
            if(type == "system_compute"){
                this.orderDetails.amount = await Number(amount);
            }
            this.orderDetails.tax_option = await option;
            await this.confirmEditDetail();
            await this.showFormatAmount("amount");
        },
        checkTechUser(){
            let user = JSON.parse(window.localStorage.getItem('user'));
            if(user){
                this.isTechUser = JSON.parse(window.localStorage.getItem('user'))['is_tech_user'];
                this.is_staff = JSON.parse(window.localStorage.getItem('user'))['is_staff'];
            }
        },
        checkWiadvanceShow(){
            if(this.isWiadvance()){
                return this.is_staff;
            }else{
                return true
            }
        },
    },
    watch: {	
       async search_order_org(val) {
            var re = /^(?!\s*$).+/;
            var check = re.test(val);
            var checkLength = /^.{0,200}$/.test(val);
            this.comboInput.order_org = "";
            if(check && val != null && checkLength){
                let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                this.comboInput.order_org = val;
                if(!this.isLoading.order_org && charNum >= 2 && charNum == val.length ){
                    this.isLoading.order_org = true;
                    await OrganizationDataService.findOrganizationByNamefromDB(val)
                    .then((response) => {
                        this.organizationList = response.data;
                        let num = 0
                        this.organizationList.forEach((item)=>{
                            item.uqNumber = num
                            num ++
                        })
                    })
                    .finally(() => {
                        this.isLoading.order_org = false;
                    });
                }
                else {
                    this.isLoading.order_org = false;
                }
            }
            else {
                this.comboInput.order_org = null;
            }            
       },
       async search_contact(val){
        this.comboInput.contact = val;

        // check user input is en / zh / num
        let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
        let isEnZhNum = false;
        pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

        // check user input data type
        let isString;
        typeof(val) == 'string' ? isString = true : isString = false;
        let array = [];

        // search
        if(isEnZhNum && !!val && isString){
            this.isLoading.contact = true;
            ContactDataService.getAll(1,val,100,[])
            .then(response => {
                response.data.list.forEach(item => {
                    item.searchStr = item.full_name 
                                    +item.full_name_en
                                    +item.full_name_en.toLowerCase()
                                    +item.full_name_en.toUpperCase()
                                    +item.full_name_zh;
                    if(item.searchStr.includes(val)){
                        if(item.organization_set.length > 0)
                        {
                            item.org_name = "[ "+item.organization_set[0].name +" ]";
                        }
                        array.push(item);
                    }
                })
                this.contactList = array;
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
            .finally(()=>{
                    this.isLoading.contact = false;
                })
        }
        else{
            this.contactList = [];
        }
       },
       isDetailReadonly(val){
            this.removeMenu(val);
        }
    },
    }
</script>
const wiadvance_paymentTermList = [
    { key: "0", name: "OA 30 Days , Pay on 25th" }, // ZB30
    { key: "1", name: "OA 30 Days ,Pay on 10th" }, // ZC30
    { key: "2", name: "Month Closing, Pay on 15th" }, // ZM00
    { key: "3", name: "120 Days After Month End" }, // ZM120
    { key: "4", name: "30 Days After Month End" }, // ZM30
    { key: "5", name: "30 Days After month End , Pay on 5th" }, // ZM3A
    { key: "6", name: "30 Days After month End , Pay on 6th" }, // ZM3B
    { key: "7", name: "30 Days After month End , Pay on 1th" }, // ZM3D
    { key: "8", name: "30 Days After month End , Pay on 15th" }, // ZM3E
    { key: "9", name: "30 Days After month End, Pay on 25th" }, // ZM3F
    { key: "10", name: "30 Days After month End, Cut on 20th, Pay on 20th" }, // ZM3H
    { key: "11", name: "30 Days After month End, Cut on 20th, Pay on Next 20th" }, // ZM3HA
    { key: "12", name: "30 Days After month End, Cut 25th, Pay on 10th" }, // ZM3L
    { key: "13", name: "45 Days After month End , Pay on 15th" }, // ZM45
    { key: "14", name: "60 Days After month End" }, // ZM60
    { key: "15", name: "65 Days After month End , Pay on 5th" }, // ZM65
    { key: "16", name: "60 Days After month End, Cut on 25th" }, // ZM6K
    { key: "17", name: "90 Days After month End" }, // ZM90
    { key: "18", name: "OA 45 days, Cut on 15th ; Pay at month-end" }, // ZO45
    { key: "19", name: "T/T Pre-Pay" }, // ZPTT
    { key: "20", name: "OA 7 Days" }, // ZR07
    { key: "21", name: "OA 14 Days" }, // ZR14
    { key: "22", name: "OA 15 Days" }, // ZR15
    { key: "23", name: "OA 20 Days" }, // ZR20
    { key: "24", name: "OA 30 Days" }, // ZR30
    { key: "25", name: "OA 45 Days" }, // ZR45
    { key: "26", name: "OA 60 Days" }, // ZR60
    { key: "27", name: "OA 90 Days" }, // ZR90
    { key: "28", name: "OA 30 Days" }, // ZW30
    { key: "29", name: "OA 45 Days" }, // ZW45
    { key: "30", name: "OA 60 Days (WEEKLY PAID)" }, // ZW60
];

export default wiadvance_paymentTermList;
import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

function isWiadvance(){
	let tanant = window.localStorage.getItem('tenant');
	return tanant == "wiadvance" || tanant == "demo" ? true : false ;
}

let orgsFieldList = [
    { key:'email', name: i18n.t('GENERAL.MAIL') ,type:'text'},
    { key:'description',name: i18n.t('ORGSFIELDLIST.description') ,type:'text'},
    { key:'created_on', name: i18n.t('ORGSFIELDLIST.created_on') ,type:'date'},
    { key:'created_by', name: i18n.t('ORGSFIELDLIST.created_by') ,type:'user'},
    { key:'updated_on', name: i18n.t('ORGSFIELDLIST.updated_on') ,type:'date'},
    { key:'updated_by',name: i18n.t('ORGSFIELDLIST.updated_by') ,type:'user'},
    { key:'owner',name: i18n.t('ORGSFIELDLIST.owner') ,type:'user'},
    { key:'name',name: i18n.t('ORGSFIELDLIST.name') ,type:'text'},
    { key:'website',name: i18n.t('ORGSFIELDLIST.website') ,type:'text'},
    { key:'company_number',name: i18n.t('ORGSFIELDLIST.company_number') ,type:'text'},
    { key:'main_phone',name: i18n.t('ORGSFIELDLIST.main_phone') ,type:'text'},
    { key:'industry',name: i18n.t('ORGSFIELDLIST.industry') ,type:'opt'},
    { key:'employee',name: i18n.t('ORGSFIELDLIST.employee') ,type:'num'},
    { key:'establish_date',name: i18n.t('ORGSFIELDLIST.establish_date') ,type:'date'},
    { key:'annual_revenue',name: i18n.t('ORGSFIELDLIST.annual_revenue') ,type:'num'},
    { key:'registered_capital',name: i18n.t('ORGSFIELDLIST.registered_capital') ,type:'num'},
    { key:'billing_address',name: i18n.t('ORGSFIELDLIST.billing_address') ,type:'text'},
    { key:'fax',name: i18n.t('ORGSFIELDLIST.fax') ,type:'text'},
    { key:'city',name: i18n.t('ORGSFIELDLIST.city') ,type:'text'},
    { key:'country',name: i18n.t('ORGSFIELDLIST.country') ,type:'text'},
    { key:'zip_code',name: i18n.t('ORGSFIELDLIST.zip_code') ,type:'text'},
    { key:'province',name: i18n.t('ORGSFIELDLIST.province') ,type:'text'},
    { key:'facebook',name: 'Facebook' ,type:'text'},
    { key:'linkedin',name: 'Linkedin' ,type:'text'},
    { key:'instagram_id',name: 'Instagram' ,type:'text'},
    { key:'line_id',name: 'Line' ,type:'text'},
    { key:'total_amount',name: i18n.t('ORGSFIELDLIST.total_amount') ,type:'num'},
    { key:'organization_type',name: i18n.t('ORGSFIELDLIST.organization_type') ,type:'opt'},
    { key:'primary_campaign_id',name: i18n.t('ORGSFIELDLIST.primary_campaign_id') ,type:'fk_cam'},
    { key:'sub_address',name: i18n.t('ORGSFIELDLIST.sub_address') ,type:'text'},
    { key:'customer_code',name: 'Customer Code' ,type:'text'},
    { key:'english_department',name: i18n.t('ORGSFIELDLIST.name_en') ,type:'text'},
    { key:'closing_date',name: i18n.t('ORGSFIELDLIST.closing_date') ,type:'text'},
    { key:'payment_term',name: i18n.t('ORGSFIELDLIST.payment_term') ,type:'opt'},
    { key:'regular_payment_date',name: i18n.t('ORGSFIELDLIST.regular_payment_date') ,type:'text'},
];
if (isWiadvance()) {
    orgsFieldList.push({ key:'belong_group',name: i18n.t('ORGSFIELDLIST.belong_group') ,type:'opt'})
}
export default orgsFieldList;
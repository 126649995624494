import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

function isWiadvance(){
	let tanant = window.localStorage.getItem('tenant');
	return tanant == "wiadvance" || tanant == "demo" ? true : false ;
}

const orders_standard_FieldList = [
    // { key:'created_on', name: i18n.t('OPPTYSFIELDLIST.created_on') ,type:'date'},
	// { key:'created_by', name: i18n.t('OPPTYSFIELDLIST.created_by') ,type:'user'},
	// { key:'updated_on', name: i18n.t('OPPTYSFIELDLIST.updated_on') ,type:'date'},
	// { key:'updated_by',name: i18n.t('OPPTYSFIELDLIST.updated_by') ,type:'user'},
	// { key:'owner',name: i18n.t('OPPTYSFIELDLIST.owner') ,type:'user'},
	{ key:'order_name',name: i18n.t(`ORDER.${isWiadvance()  ? 'WIADVANCE_NAME' : 'NAME'}`) ,type:'text'},
	{ key:'organization_name',name: i18n.t('ORDER.CLIENT_NAME') ,type:'fk_org'},
	{ key:'contact_name',name: i18n.t('ORDER.CONTACT_NAME') ,type:'fk_contact'},
	{ key:'product_name',name: i18n.t('PRODUCTSETTING.PRODUCT') ,type:'fk_product'},
	{ key:'op_price',name: i18n.t('PRODUCTSETTING.PRICR') ,type:'num'},
	{ key:'quantity',name: i18n.t('PRODUCTLINEITEM.QUANTITY') ,type:'num'},
	{ key:'other_charges',name: i18n.t('PRODUCTLINEITEM.OTHER_CHARGES') ,type:'num'},
	{ key:'discount',name: i18n.t('PRODUCTLINEITEM.DISCOUNT') ,type:'num'},
	{ key:'tax',name: i18n.t('PRODUCTLINEITEM.TAX') ,type:'num'},
	{ key:'net_amount',name: i18n.t('PRODUCTLINEITEM.AMOUNT') ,type:'num'},
];
export default orders_standard_FieldList;
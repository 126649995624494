<template lang="pug">
v-card
    v-card-title.d-flex.justify-space-between.align-center
        span {{$t('EMAILCONTACTSDIALOG.TITLE')}}
        v-btn(v-hide-element='false' icon @click='onCancel')
            v-icon mdi-close
    v-divider.my-2
    v-card-text
        v-list(two-line)
            template(v-for="item in emailContactData")
                v-list-item
                    v-list-item-avatar(color="aliceBlue" size="40")
                        v-icon(v-if='item.image == null') mdi-account
                        v-img(v-else :src="item.image")
                    v-list-item-content
                        a.t-primary.mb-1( :href='`/Contacts/${item.id}`') {{ item.__chineseName__ }} 
                        p.f-smaller.t-lightgary.ma-0(v-if='item.organization_set.length > 0' v-html="item.organization_set[0].name")
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
    props: {
        emailContactData: {
            type: Array,
            required: true,
        },
    },
    methods: {
        onCancel() {
            this.$emit('emitEmailContactsDialog', false);
        },
    }
    });
</script>


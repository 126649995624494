import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)
{
    lang = "zh_TW"
}
i18n.locale = lang

function isWiadvance(){
	let tanant = window.localStorage.getItem('tenant');
	return tanant == "wiadvance" || tanant == "demo"  ? true : false ;
}

const opptySelectFieldsList =  {
    opptyStatusList :[
        { key: "1", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.1') },
        { key: "2", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.2') },
        { key: "3", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.3')},
        { key: "4", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.4')},
        { key: "5", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.5')},
    ],
    opptyIndustryList:[
        { key: "1", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.6')},
        { key: "2", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.7')},
        { key: "3", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.8')},
        { key: "4", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.9')},
        { key: "5", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.10')},
        { key: "6", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.11')},
        { key: "7", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.12')},
        { key: "8", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.13')},
        { key: "9", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.14')},
        { key: "10", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.15')},
        { key: "11", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.16')},
        { key: "12", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.17')},
        { key: "13", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.18')},
        { key: "14", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.19')},
        { key: "15", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.20')},
        { key: "16", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.21')},
    ],
    customerSourceList:[
        { key: "1", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.22')},
        { key: "2", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.23')},
        { key: "3", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.24')},
        { key: "4", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.25')},
        { key: "5", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.26')},
        { key: "6", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.27')},
        { key: "7", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.28')},
    ],
    opptyProgressList:[
        { key: "1", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.29')},
        { key: "2", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.30')},
        { key: "3", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.31')},
        { key: "4", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.32')},
        { key: "5", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.33')},
        { key: "6", name: i18n.t('OPPTY_SELECT_FIELDS_LIST.34')},
    ],
    opptyTypeList:[
        { key: "1", name: "Lead"},
        { key: "2", name: "Pipeline"},
    ]
};

if(isWiadvance()){
	opptySelectFieldsList.opptyStatusList = opptySelectFieldsList.opptyStatusList.filter(item=>item.key != "5");
}

export default opptySelectFieldsList;
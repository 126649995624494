import { authRequest,searchString } from './api/auth';

class OrganizationDataService {
    findOrganizationByNamefromDB(name,inDB) {
        return authRequest.get(`/orgautolist/?name=${name}&inDB=${inDB}`);
    }
    findCompanyByNumberfromDB(companyNumber) {
        return authRequest.get(`/orgcomlist/?company_number=${companyNumber}`);
    }
    getAll(page,search,pageitem,sortby,filter) {
        if(filter == undefined){
            filter = ""
        }
        search = searchString(search)
        return authRequest.get(`/organizations/?page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}&filter=${filter}`);
    }
    edit(data) {   
        delete data.image;  // image upload will be handled in image function 
        delete data.owner.usersetting.photo;    
        return authRequest.put(`/organizations/${data.id}/`, data);
    }
    image(id, image) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('image', image);
        return authRequest.patch(`/organizations/${id}/image/`, formData, config);
    }    
    delete(id) {        
        let ids = id.split(",");
        if (ids.length > 1){
            let org_ids = {}
            org_ids.ids = ids
            return authRequest.post("/organizations/bulk/delete/", org_ids);
        }
        else
            return authRequest.delete(`/organizations/${id}/`);
    }
    create(data) {
        delete data.image;  // image upload will be handled in image function
        return authRequest.post("organizations/", data);
    }
    getowner(){
        return authRequest.get(`/ownerlist/`);
    } 
    getMentionOwner(){
        return authRequest.get(`/ownerlist/mention/`);
    } 
    getchangeowner(data){
        return authRequest.get(`/ownerlist/?change=${data}`);
    } 
    getContactList(id){
        return authRequest.get(`/organizations/${id}/maincontact/`);
    }
    maincontactdel(id){
        return authRequest.delete(`/organizationcontact/${id}/`);
    }
    maincontactedit(id,data){
        return authRequest.put(`/organizationcontact/${id}/`,data);
    }
    maincontactcreate(id,data){
        return authRequest.post(`/organizations/${id}/maincontact/`,data);
    }
    getOrgTimeline(id){
        return authRequest.get(`/organization/${id}/timeline/`);
    }
    getOrgDetail(id) {
        return authRequest.get(`/organizations/${id}/`);
    }
    checkCustomerCode(id,data) {
        return authRequest.post(`/organizations/${id}/customercode/`,data);
    }
    getCustomerCodeList(data) {
        return authRequest.post(`/organizations/getcustomercodelist`,data);
    }
    getEndAccountList(id) {
        return authRequest.post(`/organizations/getorganizationendaccountlist/${id}/`);
    }
}

export default new OrganizationDataService();
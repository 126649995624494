<template lang="pug">
    //- v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        //- message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-card
        v-card-title.d-flex.align-center.flex-column.justify-center.py-8
            v-icon(size='70' color='success') icon-success
            h3.mt-4.mb-0(v-if='copyDataType') SUCCESS
        v-card-text.pt-0.px-5.pb-8(v-if='!copyDataType')            
            div(class='font_arial' v-html='message')
        v-card-actions.px-2.pb-5
            v-row
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed='depressed' dark='dark') {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark' v-if='!copyDataType') {{$t('GENERAL.CONFIRM')}}
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark' v-if='copyDataType == "contact"') {{$t('REDIRECTDIALOG.NEW_CONTACT')}}
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark' v-if='copyDataType == "org"') {{$t('REDIRECTDIALOG.NEW_ORG')}}
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark' v-if='copyDataType == "deal"') {{$t('REDIRECTDIALOG.NEW_OPPTY')}}
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark' v-if='copyDataType == "case"') {{$t('REDIRECTDIALOG.NEW_CASE')}}
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark' v-if='copyDataType == "order"') {{$t(`REDIRECTDIALOG.${this.isWiadvance() ? 'NEW_CONTRACT' : 'NEW_ORDER'}`)}}
</template>

<script>
	import Vue from 'vue';

	export default Vue.extend({
        props: {
            message: {
                type: String,
                required: true,
            },  
            copyDataType: {
                type: String,
                required: false,
            },    
            copyDataId: {
                type: String,
                required: false,
            },          
        },
        methods: {
			onConfirm() {
                if(!this.copyDataType)
				{
                    this.$emit('emitMessageDialog', true)
                }
                else if (this.copyDataType == "contact")
                {
                    this.$router.push('Contacts/'+this.copyDataId);
                    this.$emit('emitRedirectDialog', false);
                }
                else if (this.copyDataType == "org")
                {
                    this.$router.push('Account/'+this.copyDataId);
                    this.$emit('emitRedirectDialog', false);
                }
                else if (this.copyDataType == "deal")
                {
                    const { href } = this.$router.resolve(this.copyDataId)
                    window.open(href, '_blank')
                    this.$emit('emitRedirectDialog', false);
                }
                else if (this.copyDataType == "case")
                {
                    const { href } = this.$router.resolve(this.copyDataId)
                    window.open(href, '_blank')
                    this.$emit('emitRedirectDialog', false);
                }
                else if (this.copyDataType == "order")
                {
                    const { href } = this.$router.resolve(this.copyDataId)
                    window.open(href, '_blank')
                    this.$emit('emitRedirectDialog', false);
                }
			},
			onCancel() {
				this.$emit('emitMessageDialog', false);
                this.$emit('emitRedirectDialog', false);                
			},
		}
	});
</script>
<template lang='pug'>
    #Segment.main-page.detail-page
        loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
        div.height_100(v-show='!loading')
            v-breadcrumbs.pa-0.px-3.pb-2.ellipsis(:items='breadcrumbs')
            v-row#page-head.d-flex.align-center.my-0
                v-col(cols='6')
                    .d-flex.align-center
                        h1.my-0.d-block.ellipsis(:title='segment.name') {{ segment.name }}
                v-col(cols='6')
                    v-row.justify-end(no-gutters='no-gutters')
                        v-col.ml-2(cols='auto')
                            .btn-group
                                v-btn(@click='onDelete' width='60' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.DELETE')}}                               
                        v-col.ml-2(cols='auto')
                            .btn-group
                                v-btn(@click='onChangeOwn(segment)' width='100%' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.CHANGE_OWNER')}} 
            #page-inner.pa-3.pt-0
                v-card.card-preface.card-shadow.px-0.py-6(flat)                    
                    v-row
                        v-col.px-2.px-md-4.py-0(cols='3')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-icon.mt-3.mb-0.mr-2
                                    v-icon(size='20') fas fa-address-card
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('SEGMENT_DETAIL.CONTACTS')}} 
                                    .f-text-highlight.mb-0.ellipsis(v-if='$i18n.locale == "zh_TW" || $i18n.locale == "zh_CN"') {{ segment.contacts_count + " 人" }}
                                    .f-text-highlight.mb-0.ellipsis(v-else) {{ segment.contacts_count }}
                        v-col.px-2.px-md-4.py-0.border-left(cols='3')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-icon.mt-3.mb-0.mr-2
                                    v-icon(size='20') fas fa-calendar-alt
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('SEGMENT_DETAIL.CAMPAIGNS')}} 
                                    .f-text-highlight.mb-0.ellipsis(v-if='$i18n.locale == "zh_TW"') {{ segment.campaign_count + " 個" }}
                                    .f-text-highlight.mb-0.ellipsis(v-else) {{ segment.campaign_count }}
                        v-col.px-2.px-md-4.py-0.border-left(cols='3')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-icon.mt-3.mb-0.mr-2
                                    v-icon(size='24') mdi-bullhorn-outline
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('SEGMENT_DETAIL.TYPE')}} 
                                    .f-text-highlight.mb-0.ellipsis {{ typeName }}
                        v-col.px-2.px-md-4.py-0.border-left(cols='3')
                            v-list-item.d-flex.align-center.pa-0
                                v-list-item-avatar.ma-0.mr-2(color='aliceBlue')
                                    span.mx-auto(v-if="!isPhoto") {{ changeOwnData.last_name }}
                                    img(v-else :src='changeOwnData.usersetting.photo' :alt='changeOwnData.last_name')
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('SEGMENT_DETAIL.OWNER')}} 
                                    a.t-primary.mb-0.f-text-highlight.ellipsis(:href='onMail(changeOwnData.email)' v-if='changeOwnData.first_name != null') {{ changeOwnData.last_name + " " +changeOwnData.first_name }}
                                    a.t-primary.mb-0.f-text-highlight.ellipsis(:href='onMail(changeOwnData.email)' v-else) {{ changeOwnData.last_name }}
                v-row.campaign-description.mt-5(no-gutters='no-gutters')
                    v-card.card-preface.card-shadow.px-0.py-6(flat style='width:100%')
                        v-col(cols='12')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('SEGMENT_DETAIL.DESCRIPTION')}} 
                                        v-btn(v-if='isDescReadonly' style='float:right' width='70' text color='gary' @click='onDescEditDetail()')
                                            v-icon(size='20') icon-edit
                                            span {{$t('GENERAL.EDIT')}}
                                        v-btn.ml-3(v-if='!isDescReadonly' style='float:right' width='70' text color='success' @click='confirmDescEditDetail()')
                                            v-icon(size='20') icon-check
                                            span {{$t('GENERAL.SAVE')}}
                                        v-btn(v-if='!isDescReadonly' style='float:right' width='70' text color='gary' @click='cancelDescEditDetail()')
                                            v-icon(size='20') icon-cancel
                                            span {{$t('GENERAL.CANCEL')}}
                                    v-textarea.textarea-style(v-model='segment.description' solo no-resize :readonly='isDescReadonly' dense='dense' rows='3')
                v-row.segment-info.mt-4(no-gutters='no-gutters')
                    v-col(cols='12')
                        v-card.deal-tab-card(flat)
                            v-tabs.main-tabs(v-model='tab' background-color='transparent' show-arrows)
                                v-tabs-slider
                                v-tab(v-for='item in tabs' :key='item.key' :href='`#tab-${item.id}`' @click='getTabName(item.key)')
                                    | {{ item.name }}
                                // - setup 
                                v-tab-item.pa-3(key='setup' value='tab-1' :transition='false' :reverse-transition='false')
                                    v-form(v-model='valid' ref='formSegment')
                                        v-row(no-gutters).mt-7.mb-4
                                            v-col.pr-md-4(cols='6')
                                                div.mb-4.d-flex.align-center.justify-space-between
                                                    h3.d-flex.align-center
                                                        .cube-icon.small.bg-og.mr-2
                                                            v-icon(size='20' color='white') fas fa-info-circle
                                                        span {{$t('SEGMENT_DETAIL.INFO')}} 
                                        v-row(no-gutters)
                                            v-col.pr-md-4(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('SEGMENT_DETAIL.NAME')}} 
                                                    v-text-field(v-model='segment.name' solo :readonly='isReadonly' :rules="formRule.nameRules" required="required")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading_segDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='isEdit && !loading_segDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='!isEdit' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_segDeatil' indeterminate color='primary')
                                            v-col.pr-md-4(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('SEGMENT_DETAIL.TYPE')}} 
                                                    v-select.no-border.no-pointer-event( v-model='segment.type' solo  :items="typeList" item-text="name" item-value="key" disabled :menu-props='{closeOnClick:true}')
                                                        //- template(v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading_segDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='isEdit && !loading_segDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='!isEdit' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_segDeatil' indeterminate color='primary')
                                        v-row(no-gutters).mt-7.mb-4
                                            v-col.pr-md-4(cols='8')
                                                div.mb-4.d-flex.align-center.justify-space-between
                                                    h3.d-flex.align-center
                                                        .cube-icon.small.bg-primary.mr-2
                                                            v-icon(size='20' color='white') fas fa-archive
                                                        span {{$t('SEGMENT_DETAIL.RELATED_CAMPAIGN')}} 
                                            v-col(cols='12' )
                                                v-text-field( v-model='search_setupCampaign' flat dense prepend-inner-icon='icon-search')   
                                            v-col(cols='12' )
                                                v-data-table.segment-table.ellipsis( 
                                                    :headers='campaignHeader' 
                                                    :header-props="{sortIcon: 'mdi mdi-arrow-up'}" 
                                                    :items='campaignSet' 
                                                    :search='search_setupCampaign' 
                                                    :items-per-page='itemPerPage_setupCampaign' 
                                                    :page.sync='page_setupCampaign' 
                                                    multi-sort
                                                    @page-count='pageCount_setupCampaign = $event' 
                                                    height='100%' 
                                                    hide-default-footer='hide-default-footer' 
                                                    item-key='id' 
                                                    :loading='Loading_setupCampaign' 
                                                    loading-text="Loading..." )
                                                    template(v-slot:item.name ="{ item }")
                                                        .d-flex.justify-right                                                
                                                            a(:href='"/Campaign/"+item.id').ellipsis {{ item.name }} 
                                                    template(v-slot:item.times ="{ item }")
                                                        .ellipsis                                                
                                                            span(v-if='$i18n.locale == "zh_TW" || $i18n.locale == "zh_CN"') {{ item.times + " 次" }} 
                                                            span(v-else) {{ item.times }} 
                                                    template(v-slot:item.owner.full_name='{ item }')
                                                        .ellipsis
                                                            a.pl-3(:href='onMail(item.owner.email)' :title='item.owner.last_name+" "+item.owner.first_name') {{ item.owner.last_name+" "+item.owner.first_name }}   
                                                v-pagination.py-1.mb-3(v-if='pageCount_setupCampaign >= 2' v-model='page_setupCampaign' :length='pageCount_setupCampaign' circle :total-visible="10")                                                 
                                // - contact -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isContactTab")                                    
                                    v-btn(@click='deleteContact()' width='120' height='32' color='red' plain border-0 v-if='selectedRows.length>0')
                                        v-icon.mr-1(size='14') icon-cancel
                                        span {{$t('SEGMENT_DETAIL.DELETE_CONTACT')}} 
                                    v-btn(@click='onAddContact()' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1(size='14') icon-add
                                        span {{$t('SEGMENT_DETAIL.ADD_CONTACT')}} 
                                v-tab-item.pa-3(key='contact' value='tab-2' :transition='false' :reverse-transition='false')                                    
                                    v-col.pa-0(cols="12")
                                        v-text-field(v-model='searchContact' flat dense  prepend-inner-icon='icon-search')
                                    v-data-table.segment-table.ellipsis( :headers='contactHeader' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='segment.contact_set' :search='searchContact' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' multi-sort height='100%' hide-default-footer='hide-default-footer' show-select item-key='id' v-model="selectedRows" :loading='contactTableLoading' loading-text="Loading..." )
                                        template(v-slot:item.full_name ="{ item }")
                                            .d-flex.justify-right                                                
                                                a(:href='"/Contacts/"+item.id' :title='item.full_name').ellipsis {{ item.full_name }}
                                        template(v-slot:item.org_name ="{ item }")
                                            .d-flex.justify-right
                                                a(:href='"/Account/"+item.id' :title='item.org_name').ellipsis {{ item.org_name }}
                                        template(v-slot:item.mobile_phone ="{ item }")
                                            v-btn.w-100.d-block(v-if='!!item.mobile_phone' :href='onCall(item.mobile_phone)' color="primary" text :title="item.mobile_phone") 
                                                .ellipsis.pt-3.w-100  
                                                    span(:title='item.mobile_phone').w-100  {{ item.mobile_phone }}
                                        template(v-slot:item.email ="{ item }")
                                            v-btn.w-100.d-block(v-if='!!item.email' :href='onMail(item.email)' color="primary" text :title="item.email") 
                                                .ellipsis.pt-3.w-100  
                                                    span(:title='item.email').w-100  {{ item.email }}
                                        template(v-slot:item.total_amount ="{ item }")
                                            .d-flex.justify-end
                                                span(:title='item.total_amount').ellipsis {{ item.total_amount }}
                                        template(v-slot:item.owner.full_name ="{ item }")
                                            .d-flex.justify-center
                                                a.pl-3.ellipsis(:href='onMail(item.owner.email)' :title='item.owner.last_name+" "+item.owner.first_name') {{ item.owner.last_name+" "+item.owner.first_name }}   
                                        template(v-slot:item.action='{ item }')
                                            v-btn(icon='icon' @click='deleteContact(item.id)')
                                                v-icon(size='18') icon-remove
                                    v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10") 
                                    v-form(v-model='valid' ref='formContact')
                                        v-row(no-gutters)
                                // - analysis -
                                v-tab-item.pa-3(key='analysis' value='tab-3' :transition='false' :reverse-transition='false')                                    
                                    .mt-2.mb-14
                                        .d-flex.align-center.justify-space-between.mb-4
                                            h3.mt-2.mb-4.d-flex.align-center
                                                .cube-icon.small.bg-og.mr-2
                                                    v-icon(size='19' color='white') fas fa-chart-pie
                                                span {{$t('SEGMENT_DETAIL.CAMPAIGN_Analysis_Chart')}}
                                            v-col(cols='12' sm='6' md='3')
                                                v-select(v-model='process' :items='campaignList' item-value='id' item-text='name' prepend-inner-icon='icon-step' height='34' flat dense solo background-color='#F0F0F0')
                                        //-------------------------------------------------------------------------------
                                        v-row.align-center.justify-space-between(v-if='process != "ALL" && isDoughnut === true')                                            
                                            v-col(cols='12' sm='6' md='3'  v-if='analysisType === "EMAIL"')
                                                bar-chart(v-if='isAnalysis' :chart-data='doughnutDataAll' :options='doughnutOptionsAll')
                                            v-col(cols='12' sm='6' md='3' v-if='analysisType === "EMAIL"')
                                                bar-chart(v-if='isAnalysis' :chart-data='doughnutDataOpen' :options='doughnutOptionsOpen')
                                            v-col(cols='12' sm='6' md='3'  v-if='analysisType === "EMAIL"')
                                                bar-chart(v-if='isAnalysis' :chart-data='doughnutDataClick' :options='doughnutOptionsClick')
                                            v-col(cols='12' sm='6' md='3' v-if='analysisType === "SMS"').mx-auto
                                                bar-chart(v-if='isAnalysis' :chart-data='doughnutDataSMS' :options='doughnutOptionsSMS')
                                        v-row.align-center.justify-space-between(v-if='(process === "ALL" | analysisType==="BOTH") && (haveEmailData === true | haveSMSData === true)')
                                            v-col(cols='12' sm='6' md='3' v-if="haveEmailData")
                                                bar-chart(v-if='isAnalysis' :chart-data='doughnutDataAll' :options='doughnutOptionsAll')
                                            v-col(cols='12' sm='6' md='3' v-if="haveEmailData")
                                                bar-chart(v-if='isAnalysis' :chart-data='doughnutDataOpen' :options='doughnutOptionsOpen')
                                            v-col(cols='12' sm='6' md='3' v-if="haveEmailData")
                                                bar-chart(v-if='isAnalysis' :chart-data='doughnutDataClick' :options='doughnutOptionsClick')
                                            v-col(cols='12' sm='6' md='3' v-if="haveSMSData & haveEmailData")
                                                bar-chart(v-if='isAnalysis' :chart-data='doughnutDataSMS' :options='doughnutOptionsSMS')
                                            v-col(cols='12' sm='6' md='12' v-if="haveSMSData & !haveEmailData")                                                
                                                bar-chart(v-if='isAnalysis' :chart-data='doughnutDataSMS' :options='doughnutOptionsSMS')
                                        //-------------------------------------------------------------------------------
                                        //- v-row.align-center.justify-space-between(v-if='process != "ALL" && isDoughnut === true')
                                        //-     v-col(cols='12' sm='6if='isAnalysis' :doughnut-data='doughnutDataAll' :doughnut-options='doughnutOptionsAll')
                                        //-     v-col(cols='12' sm='6'' md='3' v-if='analysisType === "EMAIL"')
                                        //-         doughnut-chart(v- md='3' v-if='analysisType === "EMAIL"')
                                        //-         doughnut-chart(v-if='isAnalysis' :doughnut-data='doughnutDataOpen' :doughnut-options='doughnutOptionsOpen')
                                        //-     v-col(cols='12' sm='6' md='3' v-if='analysisType === "EMAIL"')
                                        //-         doughnut-chart(v-if='isAnalysis' :doughnut-data='doughnutDataClick' :doughnut-options='doughnutOptionsClick')
                                        //-     v-col(cols='12' sm='6' md='12' v-if='analysisType === "SMS"')
                                        //-         doughnut-chart(v-if='isAnalysis' :doughnut-data='doughnutDataSMS' :doughnut-options='doughnutOptionsSMS')
                                        //- v-row.align-center.justify-space-between(v-if='(process === "ALL" | analysisType==="BOTH") && (haveEmailData === true | haveSMSData === true)')
                                        //-     v-col(cols='12' sm='6' md='3' v-if="haveEmailData")
                                        //-         doughnut-chart(v-if='isAnalysis' :doughnut-data="doughnutDataAll" :doughnut-options='doughnutOptionsAll')
                                        //-     v-col(cols='12' sm='6' md='3' v-if="haveEmailData")
                                        //-         doughnut-chart(v-if='isAnalysis' :doughnut-data="doughnutDataOpen" :doughnut-options='doughnutOptionsOpen')
                                        //-     v-col(cols='12' sm='6' md='3' v-if="haveEmailData")
                                        //-         doughnut-chart(v-if='isAnalysis' :doughnut-data="doughnutDataClick" :doughnut-options='doughnutOptionsClick')
                                        //-     v-col(cols='12' sm='6' md='3' v-if="haveSMSData & haveEmailData")
                                        //-         doughnut-chart(v-if='isAnalysis' :doughnut-data="doughnutDataSMS" :doughnut-options='doughnutOptionsSMS')
                                        //-     v-col(cols='12' sm='6' md='12' v-if="haveSMSData & !haveEmailData")
                                        //-         doughnut-chart(v-if='isAnalysis' :doughnut-data="doughnutDataSMS" :doughnut-options='doughnutOptionsSMS')
                                        v-row.sheet-box.my-5(v-if='haveEmailData=== false & haveSMSData=== false & isDoughnut === false')
                                            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                    h5.ma-0.t-black {{$t('SEGMENT_DETAIL.NO_DATA')}}                                        
                                    .mt-2.mb-15
                                        .d-flex.align-center.justify-space-between.mb-4
                                            h3.mt-2.mb-4.d-flex.align-center
                                                .cube-icon.small.bg-og.mr-2
                                                    v-icon(size='19' color='white') fas fa-list-ul
                                                span {{$t('SEGMENT_DETAIL.CAMPAIGN_Analysis_Statistics')}}
                                        v-row
                                            v-col.px-0(cols='auto')
                                                v-row
                                                    v-col.px-0(cols='9' sm='6' md='9')
                                                        v-card-title.pa-0 Email                                                            
                                                    v-col.pa-0(cols='12')
                                                        v-text-field(v-model='search_emailCampaign' flat dense prepend-inner-icon='icon-search')
                                                    v-col.pa-0(cols='12')
                                                        v-data-table.segment-table.ellipsis.mb-4(:headers='Header_emailCampaign' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='analysis.email' :search='search_emailCampaign'  :items-per-page='itemPerPage_emailCampaign' :page.sync='page_emailCampaign' @page-count='pageCount_emailCampaign = $event' multi-sort height='100%' hide-default-footer='hide-default-footer' item-key='id' :loading='Loading_emailCampaign' loading-text="Loading..." )                                                    
                                                            template(v-slot:item.name ="{ item }")
                                                                .ellipsis.d-flex.justify-space-around
                                                                    a(:href='`/Campaign/${item.id}`' v-if='item.id && !item.campaign_id') {{ item.name }}
                                                                    a(:href='`/Campaign/${item.campaign_id}`' v-if='item.campaign_id') {{ item.name }}                                                             
                                                            template(v-slot:item.sent ="{ item }")
                                                                .ellipsis.d-flex.justify-space-around(v-if='item.open_and_link')
                                                                    .t-pink.text-with-overline.pointer(@click='showContactList(item,"Success")') {{ item.try_count-item.error_count }}
                                                                    span vs
                                                                    .t-green {{ item.try_count }}
                                                            template(v-slot:item.open ="{ item }")
                                                                .ellipsis.d-flex.justify-space-around(v-if='item.open_and_link')
                                                                    .t-pink.text-with-overline.pointer(@click='showContactList(item,"Open")')  {{ item.open_and_link.open }}
                                                                    span vs
                                                                    .t-green {{ item.try_count-item.error_count }}
                                                                .ellipsis.d-flex.justify-space-around(v-if='!item.open_and_link')                                                            
                                                                    span {{$t('SEGMENT_DETAIL.NO_CAMPAIGN_DATA')}}                                                                                                                                                                   
                                                            template(v-slot:item.click ="{ item }")
                                                                .ellipsis.d-flex.justify-space-around(v-if='item.open_and_link')
                                                                    .t-pink.text-with-overline.pointer(@click='showContactList(item,"Link")')  {{ item.open_and_link.link }}
                                                                    span vs
                                                                    .t-green {{ item.try_count-item.error_count }}
                                                            template(v-slot:item.end_time ='{ item }')
                                                                .ellipsis
                                                                    span(:title='item.end_time') {{ item.end_time }}
                                                            template(v-slot:item.owner.full_name ='{ item }')
                                                                .ellipsis
                                                                    a.pl-3(:href='onMail(item.owner.email)' :title='item.owner.full_name') {{ item.owner.full_name }}
                                                        v-pagination.py-1.mb-3(v-if='pageCount_emailCampaign >= 2' v-model='page_emailCampaign' :length='pageCount_emailCampaign' circle :total-visible="10") 
                                                v-row
                                                    v-col.px-0(cols='9' sm='6' md='9')
                                                        v-card-title.pa-0 SMS
                                                    v-col.pa-0(cols='12')
                                                        v-text-field(v-model='search_smsCampaign' flat dense prepend-inner-icon='icon-search')
                                                    v-col.pa-0(cols='12')
                                                        v-data-table.segment-table.ellipsis(:headers='Header_SMSCampaign' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='analysis.sms' :search='search_smsCampaign' :items-per-page='itemPerPage_smsCampaign' :page.sync='page_smsCampaign' @page-count='pageCount_smsCampaign = $event' multi-sort height='100%' hide-default-footer='hide-default-footer' item-key='id' :loading='Loading_smsCampaign' loading-text="Loading..." )
                                                            template(v-slot:item.name ="{ item }")
                                                                .ellipsis.d-flex.justify-space-around
                                                                    a(:href='`/Campaign/${item.id}`' v-if='item.id && !item.campaign_id') {{ item.name }}
                                                                    a(:href='`/Campaign/${item.campaign_id}`' v-if='item.campaign_id') {{ item.name }}  
                                                            template(v-slot:item.sent ="{ item }")
                                                                .ellipsis.d-flex.justify-space-around(v-if='item.try_count')
                                                                    .t-pink.text-with-overline.pointer(@click='showContactList(item,"Success")') {{ item.try_count-item.error_count }}
                                                                    span vs
                                                                    .t-green {{ item.try_count }}
                                                                .ellipsis.d-flex.justify-space-around(v-if='!item.try_count')                                                            
                                                                    span {{$t('SEGMENT_DETAIL.NO_CAMPAIGN_DATA')}}
                                                            template(v-slot:item.end_time ="{ item }")
                                                                .ellipsis
                                                                    span(:title='item.end_time') {{ item.end_time }}
                                                            template(v-slot:item.owner.full_name ='{ item }')
                                                                .ellipsis
                                                                    a.pl-3(:href='onMail(item.owner.email)' :title='item.owner.full_name') {{ item.owner.full_name }}
                                                        v-pagination.py-1.mb-3(v-if='pageCount_smsCampaign >= 2' v-model='page_smsCampaign' :length='pageCount_smsCampaign' circle :total-visible="10") 
        v-dialog(v-model='changeOwnDialog' max-width='500' v-if='changeOwnDialog')
          change-own(@emitChangeOwnDialog='onEmitChangeOwnDialog' :owner-data='changeOwnData' :other-data='otherData' change='segments')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
          message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
          success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
          error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='addSegContact' max-width='1000' v-if='addSegContact')
          add-segment-contact(@emitAddSegContactDialog='onEmitAddSegContactDialog',@emitAddSegData='addSegContactData' :segContactList='segContactList')
        v-dialog(v-model='campaignContactList' max-width='1000' v-if='campaignContactList')
            campaign-contact-list(@emitCampaignContactList='onEmitCampaignContactList' :campaignlineId='campaignlineId' :campaignId='campaignId' :selectType='selectType')
</template>

<script>
    import DoughnutChart from '../assets/script/doughnutChart';
    import barChart from '../assets/script/barChart';
    import changeOwn from '@/components/Dialog/changeOwn';
    import emailEditor from '@/components/Common/emailEditor';
    import newsDisplay from '@/components/Common/newsDisplay';       
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';    
    import customField from "@/components/Common/customField";
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import SegmentDataService from "../services/SegmentDataService";
    import addSegmentContact from "@/components/Dialog/Segment/addSegmentContact";
    import segment_typeList from "@/array/the_segmentTypeList";
    import campaign_channelList from "@/array/the_campaignChannelList";
    import campaignContactList from "@/components/Dialog/Campaign/campaignContactList";
    import i18n from '/common/plugins/vue-i18n.js'  
    

    export default {
        components: {         
            campaignContactList,        
            SegmentDataService,
            DoughnutChart,
            barChart,
            customField,
            changeOwn,            
            emailEditor,
            newsDisplay,            
            messageDialog,
            successDialog,
            errorDialog,            
            loadingOverlay,
            addSegmentContact,
        },
        data() {
            return {
                loading_segDeatil: false,
                isDescReadonly: true,
                typeList: segment_typeList,
                isEdit: false,
                typeName: '',                
                formRule: {
                    nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_SEGMENT'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                    ],
                },
                isReadonly:true,
                page: 1,
                pageCount: 0,
                itemPerPage: 7,
                selectedRows: [],
                contactTableLoading: false,
                searchContact: '',
                // - email campaign table
                page_emailCampaign: 1,
                pageCount_emailCampaign: 0,
                itemPerPage_emailCampaign: 5,
                selectedRows_emailCampaign: [],
                Loading_emailCampaign: false,
                search_emailCampaign: '',
                // - sms campaign table
                page_smsCampaign: 1,
                pageCount_smsCampaign: 0,
                itemPerPage_smsCampaign: 5,
                selectedRows_smsCampaign: [],
                Loading_smsCampaign: false,
                search_smsCampaign: '',
                // - setup campaign table
                page_setupCampaign: 1,
                pageCount_setupCampaign: 0,
                itemPerPage_setupCampaign:6,
                selectedRows_setupCampaign: [],
                Loading_setupCampaign: false,
                search_setupCampaign: '',
                //
                valid: true,
                loading: true,
                tab: null,
                search: null,
                segContactList:[],
                segment: {},   
                analysis: {
                    email: [],
                    sms: []
                },
                isContactTab: false,
                isAnalysis: false,
                isSetupTab: true,
                tabs: [
                    {
                        id: 1,
                        key: 'setup',
                        name: i18n.t('SEGMENT_DETAIL.SETUP')
                    },
                    {
                        id: 2,
                        key: 'contact',
                        name: i18n.t('SEGMENT_DETAIL.CONTACT')
                    },
                    {
                        id: 3,
                        key: 'analysis',
                        name: i18n.t('SEGMENT_DETAIL.ANALYSIS')
                    },
                ],
                breadcrumbs: [{
                        text: i18n.t('BREADCRUMBS.Segment'),
                        disabled: false,
                        href: '/Segments',
                    },
                    {
                        text: "",
                        disabled: true,
                        href: 'Segment',
                    },
                ],
                contactHeader: [
                    {
                        value: "data-table-select",
                        align: "center",
                        width: '8%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_NAME'),
                        value: "full_name",
                        align: "left",
                        // width: '14%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_ORG'),
                        value: "org_name",
                        align: "left",
                        // width: '19%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_PHONE'),
                        value: "mobile_phone",
                        align: "center",
                        width: '15%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_MAIL'),
                        value: "email",
                        align: "left",
                        width: '15%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_AMOUNT'),
                        value: "total_amount",
                        align: "right",
                        // width: '12%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_OWNER'),
                        value: "owner.full_name",
                        align: "center",
                        width: '10%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_ACTIVE'),
                        value: "action",
                        align: "center",
                        sortable: false,
                        width: '5%',
                    },
                ],
                campaignHeader: [                    
                    {
                        text: i18n.t('SEGMENT_DETAIL.CAM_NAME'),
                        value: "name",
                        align: "center",
                        width: '20%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.CAM_TYPE'),
                        value: "channel",
                        align: "center",
                        width: '10%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.CAM_TIME'),
                        value: "times",
                        align: "center",
                        width: '10%',
                    },                    
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_OWNER'),
                        value: "owner.full_name",
                        align: "center",
                        width: '15%',
                    },                    
                ],
                Header_emailCampaign: [
                    {
                        text: i18n.t('SEGMENT_DETAIL.CAM_NAME'),
                        value: "name",
                        align: "center",
                        width: '20%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.S_V_S'),
                        value: "sent",
                        align: "center",
                        width: '15%',
                        sortable: false,
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.O_V_S'),
                        value: "open",
                        align: "center",
                        width: '15%',
                        sortable: false,
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_V_S'),
                        value: "click",
                        align: "center",
                        width: '15%',
                        sortable: false,
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.END_TIME'),
                        value: "end_time",
                        align: "center",
                        width: '25%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_OWNER'),
                        value: "owner.full_name",
                        align: "center",
                        width: '10%',
                    },
                ],
                Header_SMSCampaign: [
                    {
                        text: i18n.t('SEGMENT_DETAIL.CAM_NAME'),
                        value: "name",
                        align: "center",
                        width: '20%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.S_V_S'),
                        value: "sent",
                        align: "center",
                        width: '45%',
                        sortable: false,
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.END_TIME'),
                        value: "end_time",
                        align: "center",
                        width: '25%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_OWNER'),
                        value: "owner.full_name",
                        align: "center",
                        width: '10%',
                    },
                ],                
                changeOwnData: {
                    usersetting: {
                        phtoto: ""
                    }
                },
                channelList: campaign_channelList,
                otherData: {},
                newContactList:[],
                campaignSet:[],
                // Dialog
                selectType:null,
                campaignContactList:false,
                addSegContact:false,
                changeOwnDialog: false,                
                messageDialog: false,
                message: '',
                successDialog: false,
                errorDialog: false,
                errorMessage: '',
                delMode: '',
                succMode: '',
                delError:false,   
                isPhoto: true,
                delContactList:[],
                campaignList:[],
                process: "select",
                analysisType:"",
                haveEmailData:false,
                haveSMSData:false,
                // bar chart
                              
                // doughnut chart'
                isDoughnut:false,
                doughnutDataAll: {
                    labels: ['Lead In', 'Contact Made', 'Needs Defined', 'Proposal Made', 'NegoTiations Started'],
                    datasets: [
                        {
                            data: [20,10,30,20,20],
                            backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)','rgb(0, 170, 96)','rgb(123,104,238)','rgb(240,89,117)'],
                            hoverOffset: 12
                        }
                    ]
                },
                doughnutDataOpen: {
                    labels: ['Lead In', 'Contact Made', 'Needs Defined', 'Proposal Made', 'NegoTiations Started'],
                    datasets: [
                        {
                            data: [20,10,30,20,20],
                            backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)','rgb(0, 170, 96)','rgb(123,104,238)','rgb(240,89,117)'],
                            hoverOffset: 12
                        }
                    ]
                },
                doughnutDataClick: {
                    labels: ['Lead In', 'Contact Made', 'Needs Defined', 'Proposal Made', 'NegoTiations Started'],
                    datasets: [
                        {
                            data: [20,10,30,20,20],
                            backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)','rgb(0, 170, 96)','rgb(123,104,238)','rgb(240,89,117)'],
                            hoverOffset: 12
                        }
                    ]
                },
                doughnutDataSMS: {
                    labels: ['Lead In', 'Contact Made', 'Needs Defined', 'Proposal Made', 'NegoTiations Started'],
                    datasets: [
                        {
                            data: [20,10,30,20,20],
                            backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)','rgb(0, 170, 96)','rgb(123,104,238)','rgb(240,89,117)'],
                            hoverOffset: 12
                        }
                    ]
                },
                doughnutOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    borderWidth: "10px",
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: "10px",
                    legend: {
                        display: false,
                        position: 'bottom',
                    },
                    plugins: {
                        tooltip: {
                            titleFontSize: 10,
                            bodyFontSize: 10,
                        }
                    },
                    title: {
                        display: true,
                        text: 'Custom Chart Title'
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                },
                target_id: this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id')
            }
        },        
        async created(){
            await (this.uuidCheck(this.target_id))
            await (this.loading = true);
            await SegmentDataService.getDetail(this.target_id).then((response)=>
                {                    
                    this.segment = response.data;      
                    const title = localStorage.getItem('title');    
                    document.title = title+' : '+this.segment.name +' – UpDay';  
                    this.breadcrumbs[1].text = this.segment.name;            
                    this.campaignList = JSON.parse(JSON.stringify(this.segment.campaign_set))
                    this.campaignList.splice(0,0,{"name": i18n.t('GENERAL.ALL'),"id":"ALL"});
                    this.typeName = this.replaceType(this.segment.type);
                    this.segment.contacts_count = response.data.contacts.length;
                    // if(response.data.campaign_set.length>0)
                    this.segment.campaign_count = response.data.campaign_set.length
                    // else{this.segment.campaign_count = 0}                  
                    this.segment.contact_set = this.processContacts(this.segment.contact_set);
                    this.changeOwnData = response.data.owner;
                    if (response.data.owner.usersetting.photo == null)
                    {
                        this.isPhoto = false;
                    }     
                    this.getAnalysisData()
                    this.campaignSet = JSON.parse(JSON.stringify(this.campaignList)).splice(1)
                    for(let i in this.campaignSet)
                    {
                        this.campaignSet[i].channel = this.replaceChannel(this.campaignSet[i].channel)
                        this.campaignSet[i].times = this.campaignSet[i].campaignline_set.length
                        this.campaignSet[i].owner.full_name = this.campaignSet[i].owner.last_name+ ' '+ this.campaignSet[i].owner.first_name
                    }                   
                }
            ).catch(response => {
                this.showErrorDialog(response);
                // Redirect to PermissionError page if error status is 403
                if (response.response.status == 403)
                {
                    this.$router.replace('/PermissionError');
                }
                else if(response.response.status == 404)
                {
                    this.$router.replace('/PageNotFound');
                }
            });
            await (this.loading = false);        
        },
        methods: {
            getAnalysisData(){
                this.analysis.email = []
                    let emailData = this.segment.campaign_set.filter(e=>e.channel === "EMAIL")
                    this.analysis.sms = []
                    let smsData = this.segment.campaign_set.filter(e=>e.channel === "SMS") 
                    for(let i in emailData)
                        {                            
                            if(emailData[i].campaignline_set.length>0)
                            {                                   
                                for(let a in emailData[i].campaignline_set)
                                {             
                                    if(emailData[i].campaignline_set[a].channel==="EMAIL"){
                                      emailData[i].campaignline_set[a].owner = emailData[i].owner 
                                      this.analysis.email.push(emailData[i].campaignline_set[a])    
                                    }         
                                    if(emailData[i].campaignline_set[a].channel==="SMS"){
                                        emailData[i].campaignline_set[a].owner = emailData[i].owner 
                                      this.analysis.sms.push(emailData[i].campaignline_set[a])    
                                    }                                 
                                }                                
                            }
                            else
                            {
                                this.analysis.email.push(emailData[i])
                            }
                        }                    
                    for(let i in smsData)
                        {
                            if(smsData[i].campaignline_set.length>0)
                            {   
                                for(let a in smsData[i].campaignline_set)
                                {      
                                    if(smsData[i].campaignline_set[a].channel==="EMAIL"){                                        
                                        smsData[i].campaignline_set[a].owner = smsData[i].owner
                                        this.analysis.email.push(smsData[i].campaignline_set[a])
                                    }         
                                    if(smsData[i].campaignline_set[a].channel==="SMS"){    
                                        smsData[i].campaignline_set[a].owner = smsData[i].owner                                    
                                        this.analysis.sms.push(smsData[i].campaignline_set[a])                                         
                                    }                              
                                } 
                            }
                            else
                            {
                                this.analysis.sms.push(smsData[i])
                            }
                        }
                    for(let i in this.analysis.email)
                    {                                              
                        if(!this.analysis.email[i].name)
                        {                           
                            this.analysis.email[i].name = this.analysis.email[i].campaign                            
                        }    
                        if(!this.analysis.email[i].owner)
                        {
                            this.analysis.email[i].owner = emailData.filter(e=>e.id === this.analysis.email[i].campaign_id)[0].owner
                        }                                            
                        this.analysis.email[i].owner.full_name = this.analysis.email[i].owner.last_name + this.analysis.email[i].owner.first_name
                        if(this.analysis.email[i].end_time)
                        {
                            this.analysis.email[i].end_time = this.getFormatTime(new Date(this.analysis.email[i].end_time))
                        }
                    }
                    for(let i in this.analysis.sms)
                    {
                        if(!this.analysis.sms[i].name)
                        {
                            this.analysis.sms[i].name = this.analysis.sms[i].campaign                            
                        } 
                        if(!this.analysis.sms[i].owner)
                        {
                            this.analysis.sms[i].owner = smsData.filter(e=>e.id === this.analysis.sms[i].campaign_id)[0].owner
                        }                       
                        this.analysis.sms[i].owner.full_name = this.analysis.sms[i].owner.last_name + this.analysis.sms[i].owner.first_name
                        if(this.analysis.sms[i].end_time)
                        {
                            this.analysis.sms[i].end_time = this.getFormatTime(new Date(this.analysis.sms[i].end_time))
                        }                        
                    }
                    this.process = "ALL"                
            },
            getFormatTime(data){
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
                let today  = data;
                if(this.$i18n.locale == "zh_TW")
                {
                   return today.toLocaleDateString("zh-TW", options); 
                }else
                {
                    return today.toLocaleDateString("en-US", options);
                }
                               
            },
            replaceChannel(key) {
                let index = this.channelList.findIndex(item => {return item.key == key});
                return this.channelList[index].name;
            },
            replaceType(key) {
                let index = this.typeList.findIndex(item => {return item.key == key});
                return this.typeList[index].name;
            },
            processContacts(data){
                for(let i in data){                        
                        data[i].total_amount = "$ "+this.toCurrecy(data[i].total_amount)                         
                        if(data[i].first_name){
                            data[i].full_name = data[i].last_name +' '+ data[i].first_name
                        }else{
                            data[i].full_name = data[i].last_name
                        }
                        data[i].owner.full_name = data[i].owner.last_name +' '+ data[i].owner.first_name
                        if(data[i].organization_set.length >0){
                            data[i].org_name = data[i].organization_set[0].name
                        }
                        else{
                            data[i].org_name = ""
                        }
                    }
                return data
            },
            validate() {
                this.$refs.formSegment.validate();
            },
            validateForm(){
                if(this.$refs.formSegment.validate()){
                    return true;
                }
                else{
                    return false;
                }
            },            
            onCall: function (cell) {
                return 'tel:'+cell;
            },
            onMail: function (mail) {
                return 'mailto:'+mail;
            },
            toCurrecy(num){
            let parts = num.toString().split('.');
            parts[0] = parts[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            return parts.join('.');
            },
            // About Deal            
            getTabName(key){
                if(key == 'setup')
                    {this.isSetupTab = true;
                    this.process="select"} 
                else
                    this.isSetupTab = false;
                if(key == 'contact')
                    {this.isContactTab = true;
                    this.process="select"} 
                else
                    this.isContactTab = false;

                if(key == 'analysis')
                    {this.isAnalysis = true;                    
                    this.process="ALL"                    
                    }
                else
                    this.isAnalysis = false;                
            },              
            // Dialog
            async onChangeOwn(data) {
                await (this.otherData.segment_id = data.id);
                await (this.otherData.name = data.name);
                await (this.changeOwnDialog = true);                
            },
            onAddContact(){
                this.addSegContact = true;
                this.segContactList = this.segment.contacts
            },
            addSegContactData(data){                
                this.segment.contact_set = this.processContacts(data)
                this.segment.contacts_count = data.length
            },
            onEmitAddSegContactDialog(val){               
                if(val){        
                    this.addSegContact = false;
                }
                else{
                    this.addSegContact = false;
                }                
            },
            onEmitChangeOwnDialog(val) {
                this.changeOwnDialog = false;
                if (val) {
                    this.succMode = "changeOwn";
                    this.showSuccessDialog();
                }
            },            
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
                if (this.succMode == "changeOwn") {                
                if (this.changeOwnData.usersetting.photo == null)
                    {this.isPhoto = false;}
                else{
                    this.isPhoto = true
                }
                }                
                if(this.delMode === 'delSegment')
                {
                    this.$router.replace('/Segments'); 
                }
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            async onEmitMessageDialog(val) {
                
                if(val){
                    if(this.delMode === 'delContact')                         
                    {
                        if(this.selectedRows.length <= 1){                                                    
                            this.segment.contacts.splice(this.segment.contacts.indexOf(this.deleteContactId),1)
                            this.newContactList = {id:this.target_id,contacts:this.segment.contacts} 
                            this.segment.contacts_count = this.segment.contacts_count-1                               
                            }
                        else{                                
                            for(let i = 0; i < this.selectedRows.length; i++){
                                this.segment.contacts.splice(this.segment.contacts.indexOf(this.delContactList[i]),1)
                            }                        
                            this.newContactList = {id:this.target_id,contacts:this.segment.contacts}  
                            this.segment.contacts_count = this.segment.contacts_count-this.selectedRows.length                               
                            }
                        await SegmentDataService.editDetail(this.target_id,this.newContactList)
                                .then((response)=>{                            
                                this.segment.contact_set = this.processContacts(response.data.contact_set)
                                this.segment.contacts = response.data.contacts
                                })
                        this.selectedRows = []
                        this.successDialog = true;
                    }
                    if(this.delMode === 'delSegment')                         
                    {                        
                        SegmentDataService.delete(this.segment.id)      
                        .then((response) => {
                                if(response.data.error === 'Have Campaign!'){
                                    this.delError = true;       
                                    this.showErrorDialog(i18n.t('SEGMENT_DETAIL.ERROR'));                                    
                                }
                                else if (response.status === 204 || response.status === 200) {                                
                                this.showSuccessDialog();
                                }else {
                                this.showMessageDialog(
                                    `${response.statusText}, ${response.status}`
                                );
                                }                
                                this.selectedRows = [];
                                this.deleteId ='';                       
                                this.delError = false; 
                            })
                    }
                }
                this.messageDialog = false;                
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            deleteContact(id){                
                this.delMode = 'delContact';                
                if(id != null){
                    this.showMessageDialog(i18n.t('SEGMENT_DETAIL.MESSAGE_2'))
                    this.deleteContactId = id
                }
                else{
                    this.showMessageDialog(i18n.t('SEGMENT_DETAIL.MESSAGE'));
                    for(let i = 0; i < this.selectedRows.length; i++)
                    {
                        this.delContactList.splice(0,0,this.selectedRows[i].id)
                    }
                }                
            },
            onDelete () {
                this.delMode = 'delSegment';
                this.showMessageDialog(i18n.t('SEGMENT_DETAIL.MESSAGE'));
            },            
            onEmitSendEmailDialog(val) {
                if (val) {
                    this.showSuccessDialog();
                    this.getContactTimeline(this.target_id);
                }
            },
            onEditDetail() {
                this.isEdit = true;
                this.isReadonly = false;
                this.originData = JSON.parse(JSON.stringify(this.segment));
            },
            cancelEditDetail() {
                this.isEdit = false;
                this.isReadonly = true;
                this.segment = this.originData;
            },
            async confirmEditDetail() {                
                if(this.validateForm()) {
                    await (this.loading_segDeatil = true);
                    let segmentData = {};                    
                    segmentData = {
                        "id": this.segment.id,
                        "name": this.segment.name,
                        "type": this.segment.type,                            
                        "description": this.segment.description
                    };                    
                    await SegmentDataService.editSegment(segmentData)
                    .then(response => {
                        this.segment.name = response.data.name;                        
                        this.typeName = this.replaceType(this.segment.type);                  
                    });
                    this.isEdit = false;
                    this.isReadonly = true; 
                    // this.showSuccessDialog()  
                    await (this.loading_segDeatil = false);  
                }           
            },
            onDescEditDetail() {
                this.isDescReadonly = false;
                this.originData = JSON.parse(JSON.stringify(this.segment));
            },
            cancelDescEditDetail() {
                this.isDescReadonly = true;
                this.segment = this.originData;
            },
            confirmDescEditDetail() {
                if(this.validateForm()) {
                    let segmentData = {};
                    segmentData = {
                        "id": this.segment.id,
                        "name": this.segment.name,
                        "type": this.segment.type,                            
                        "description": this.segment.description
                        };                    
                    
                    SegmentDataService.editSegment(segmentData)
                    .then(response => {                        
                        this.segment.description = response.data.description;
                    });
                    this.isDescReadonly = true;
                    this.showSuccessDialog() 
                }
            },
            // Dialog Area ----------------------------------------- [End]            
            moreLess(value, place) {
                if (value != null) {
                    if (place == "DETAIL") {
                        return value.substring(0, 300) + " ...";
                    } else {
                        return value.substring(0, 100) + " ...";
                    }
                }
            },
            updateChart(){
                this.isDoughnut = false
                this.haveEmailData = false 
                this.haveSMSData = false 
            },
            showContactList(data,type){        
                this.campaignlineId = data.id
                this.campaignId = data.campaign_id
                this.selectType = type
                this.campaignContactList = true                
            },
            onEmitCampaignContactList(val){               
                if(val){        
                    this.campaignContactList = false;
                }
                else{
                    this.campaignContactList = false;
                }
            },    
        },        
        watch: {
            "process": async function () {
            if(this.process == "select")
            {
                return              
            }
            if(this.process != "ALL")
            {   
                let isSMS = this.campaignList.filter(e=>e.id===this.process)[0].campaignline_set.find(e=>e.channel === "SMS")
                let isEMAIL = this.campaignList.filter(e=>e.id===this.process)[0].campaignline_set.find(e=>e.channel === "EMAIL")
                if(isSMS && isEMAIL)
                {
                    this.analysisType = "BOTH"
                }
                else
                {
                    if(this.campaignList.filter(e=>e.id===this.process)[0].campaignline_set.length>0)
                    {
                        this.analysisType = this.campaignList.filter(e=>e.id===this.process)[0].campaignline_set[0].channel
                    }else
                    {
                        this.analysisType = this.campaignList.filter(e=>e.id===this.process)[0].channel
                    }
                }                
            }            
            await this.updateChart()           
            if(this.analysisType === "EMAIL" || this.process === "ALL" || this.analysisType === "BOTH")
            {    
                let doughnutEmail = []
                if(this.process != "ALL")                              
                {
                    doughnutEmail = this.analysis.email.filter(e=>e.campaign_id===this.process)
                }
                else
                {
                    doughnutEmail = this.analysis.email.filter(e=>e.try_count>0)
                }
                if(doughnutEmail.length>0)
                {                       
                    this.haveEmailData = true         
                    this.doughnutOptionsAll = JSON.parse(JSON.stringify(this.doughnutOptions))
                    this.doughnutOptionsOpen = JSON.parse(JSON.stringify(this.doughnutOptions))
                    this.doughnutOptionsClick = JSON.parse(JSON.stringify(this.doughnutOptions))
                    this.doughnutOptionsAll.title.text = 'Email '+i18n.t('SEGMENT_DETAIL.S_V_S')
                    this.doughnutOptionsOpen.title.text = 'Email '+i18n.t('SEGMENT_DETAIL.S_V_O')
                    this.doughnutOptionsClick.title.text = 'Email '+i18n.t('SEGMENT_DETAIL.S_V_C')
                    if(this.process === "ALL"){
                        this.doughnutOptionsAll.title.text = 'Email '+i18n.t('SEGMENT_DETAIL.S_V_S')
                        this.doughnutOptionsOpen.title.text = 'Email '+i18n.t('SEGMENT_DETAIL.S_V_O')
                        this.doughnutOptionsClick.title.text = 'Email '+i18n.t('SEGMENT_DETAIL.S_V_C')
                    }
                    this.isDoughnut = true
                    let doughnutAll = {error:0,total:0}                     
                    let doughnutOpen = 0
                    let doughnutClick = 0
                    for (let i in doughnutEmail)
                    {
                        doughnutAll.error = doughnutAll.error+doughnutEmail[i].error_count
                        doughnutAll.total = doughnutAll.total+doughnutEmail[i].try_count
                        doughnutOpen = doughnutOpen + doughnutEmail[i].open_and_link.open
                        doughnutClick = doughnutClick + doughnutEmail[i].open_and_link.link 
                    }
                    let doughnutS = doughnutAll.total - doughnutAll.error                    
                    this.doughnutDataAll={
                        labels:[i18n.t('SEGMENT_DETAIL.SUCCESS'),i18n.t('SEGMENT_DETAIL.SENT')],
                        datasets: [
                            {
                                label: 'Email',
                                data: [doughnutS,doughnutAll.total],
                                backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)'],
                                hoverOffset: 12
                            }
                        ]
                        }           
                    this.doughnutDataOpen={
                        labels:[i18n.t('SEGMENT_DETAIL.SUCCESS'),i18n.t('SEGMENT_DETAIL.OPEN')],
                        datasets: [
                            {
                                label: 'Email',
                                data: [doughnutS,doughnutOpen],
                                backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)'],
                                hoverOffset: 12
                            }
                        ]
                        }
                    this.doughnutDataClick={
                        labels:[i18n.t('SEGMENT_DETAIL.SUCCESS'),i18n.t('SEGMENT_DETAIL.CLICK')],
                        datasets: [
                            {
                                label: 'Email',
                                data: [doughnutS,doughnutClick],
                                backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)'],
                                hoverOffset: 12
                            }
                        ]
                    }                                      
                }
                else{
                    if(this.process != "ALL")
                    {
                        this.isDoughnut = false
                        this.haveEmailData = false
                    }
                    this.isDoughnut = false
                }
            }
            if(this.analysisType === "SMS" || this.process === "ALL" || this.analysisType === "BOTH")
            {      
                let doughnutSMS =[]  
                if(this.process != "ALL")                              
                {
                    doughnutSMS = this.analysis.sms.filter(e=>e.campaign_id===this.process)
                }
                else
                {
                    doughnutSMS = this.analysis.sms.filter(e=>e.try_count>0)
                }                          
                if(doughnutSMS.length>0)
                {             
                    this.haveSMSData = true       
                    this.doughnutOptionsSMS = JSON.parse(JSON.stringify(this.doughnutOptions))                    
                    this.doughnutOptionsSMS.title.text = 'SMS '+i18n.t('SEGMENT_DETAIL.S_V_S')
                    if(this.process === "ALL"){
                        this.doughnutOptionsSMS.title.text = 'SMS '+i18n.t('SEGMENT_DETAIL.S_V_S')
                    }
                    this.isDoughnut = true
                    let doughnutAll = {error:0,total:0}
                    for (let i in doughnutSMS)
                    {
                        doughnutAll.error = doughnutAll.error+doughnutSMS[i].error_count
                        doughnutAll.total = doughnutAll.total+doughnutSMS[i].try_count                        
                    }
                    let doughnutS = doughnutAll.total - doughnutAll.error
                    this.doughnutDataSMS={
                        labels:[i18n.t('SEGMENT_DETAIL.SUCCESS'),i18n.t('SEGMENT_DETAIL.SENT')],
                        datasets: [
                            {
                                label: 'SMS',
                                data: [doughnutS,doughnutAll.total],
                                backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)'],
                                hoverOffset: 12
                            }
                        ]
                    }          
                }
                else{
                    if(this.process != "ALL")
                    {
                        this.isDoughnut = false
                        this.haveSMSData = false
                    }
                    this.isDoughnut = false
                }
            }
            },
            isReadonly(val){
                this.removeMenu(val);
            }
        },
    }
</script>

<style lang="css">
    .campaignList {        
        padding: 8px 0rem 0 0rem
    }
</style>
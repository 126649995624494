import i18n from '/common/plugins/vue-i18n.js'
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

function isWiadvance(){
	let tanant = window.localStorage.getItem('tenant');
	return tanant == "wiadvance" || tanant == "demo" ? true : false ;
}

const ordersFieldList = [
    // { key:'created_on', name: i18n.t('OPPTYSFIELDLIST.created_on') ,type:'date'},
	// { key:'created_by', name: i18n.t('OPPTYSFIELDLIST.created_by') ,type:'user'},
	// { key:'updated_on', name: i18n.t('OPPTYSFIELDLIST.updated_on') ,type:'date'},
	// { key:'updated_by',name: i18n.t('OPPTYSFIELDLIST.updated_by') ,type:'user'},
	// { key:'owner',name: i18n.t('OPPTYSFIELDLIST.owner') ,type:'user'},
	{ key:'order_name',name: i18n.t(`ORDER.${isWiadvance() ? 'WIADVANCE_NAME' : 'NAME'}`) ,type:'text'},
	{ key:'organization_name',name: i18n.t('ORDER.CLIENT_NAME') ,type:'fk_org'},
	{ key:'contact_name',name: i18n.t('ORDER.CONTACT_NAME') ,type:'fk_contact'},
	{ key:'amount',name: i18n.t('ORDER.AMOUNT') ,type:'num'},
	{ key:'payment_term',name: i18n.t('ORDER.PAYMENT_TERM') ,type:'opt'},
	{ key:'shipping_method',name: i18n.t('ORDER.SHIPPING_METHOD') ,type:'opt'},
	{ key:'shipping_term',name: i18n.t('ORDER.SHIPPING_TERM') ,type:'opt'},
	{ key:'billing_address',name: i18n.t('ORDER.BILLING_ADDRESS') ,type:'text'},
	{ key:'shipping_address',name: i18n.t('ORDER.SHIPPING_ADDRESS') ,type:'text'},

];
export default ordersFieldList;
<template lang="pug">
v-card(:class='contactList.length >= 8 ? "dialog-height-big" : ""')
  v-col.d-flex.align-center.pa-5.pb-0
    v-row(no-gutters)
      v-col(cols="11")
        .d-flex.align-center
          h2.my-0 {{$t('CAMPAIGNCONTACTLIST.TITLE')}}
      v-col.text-right(cols="1")
        v-btn.ml-auto(
          v-hide-element='false'
          :ripple="false",
          @click="onCancel",
          depressed="depressed",
          icon
        )
          v-icon icon-cancel
  v-col.d-flex.align-center.pa-5.pb-0
    v-row(no-gutters)
      v-col(cols='4')
          v-select(v-model='process' :items='selectList' item-value='id' item-text='name' prepend-inner-icon='icon-step' height='34' flat dense solo background-color='#F0F0F0')   
      v-col.text-right(cols="4")
        .btn-group.ml-auto.mr-12         
          v-text-field(
            v-model="searchMainContact",
            flat,
            dense,
            solo,
            prepend-inner-icon="icon-search",
            hide-details
          )         
      v-col.text-right(cols="4")
        .btn-group.ml-auto.mr-2
          v-menu(offset-y)
                  template(v-slot:activator="{ on, attrs }")
                    v-btn(@click='' v-bind="attrs" v-on="on" :disabled='selectedRows.length < 1' height='38' color='white' depressed tile dark)
                      v-icon.pa-1(size='20') mdi-bullhorn-outline
                      span {{$t('Bulk_Message.TITLE')}}
                  v-list
                    v-list-item(@click='openEmailDrawer()') Email
                    v-list-item(@click='openSMSDrawer()') SMS
          v-btn.border-left(@click='showAddStaticSeg()' :disabled='selectedRows.length < 1 ' width='160' height='38' color='white' depressed tile dark)
              v-icon.pr-1.pt-1(size='20') mdi-chart-pie
              span {{$t('CAMPAIGNCONTACTLIST.ADD_SEG')}}
  v-card-text.pt-4.px-5.pb-5
    .data-area
      v-data-table.border-table(
        :headers="mainContactHeader",
        :items="contactList",
        :items-per-page="mainContactItemPerPage",
        :page.sync="mainContactPage",
        :search="searchMainContact",
        multi-sort
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}"
        @page-count="mainContactPageCount = $event",
        hide-default-footer="hide-default-footer"
        show-select item-key='id' v-model="selectedRows" :loading='isLoading' loading-text="Loading..." 
      )        
        template(v-slot:item.full_name="{ item }")
            .ellipsis
                a(:href="'/Contacts/' + item.id" :title='item.full_name') {{ item.full_name }}        
        template(v-slot:item.org_name="{ item }")
            v-btn.w-100.d-block(v-if="item.org_name != null" :href="onCall(item.org_name)" color="primary" text :title='item.org_name') 
                .ellipsis.pt-2.w-100 
                  span.w-100 {{ item.org_name }}
        template(v-slot:item.mobile_phone="{ item }")
            v-btn.w-100.d-block(v-if="item.mobile_phone != null" :href="onCall(item.mobile_phone)" color="primary" text :title='item.mobile_phone') 
                .ellipsis.pt-2.w-100 
                    span.w-100 {{ item.mobile_phone }}
        template(v-slot:item.email="{ item }")
            v-btn.w-100.d-block(v-if="item.email != null" :href="onMail(item.email)" color="primary" text :title='item.email') 
                .ellipsis.pt-2.w-100 
                  span.w-100 {{ item.email }}
        template(v-slot:item.active="{ item }")
          v-btn(icon="icon", @click="showEditContact(item)")
            v-icon(size="18") icon-edit
          v-btn(
            icon="icon",
            @click="onDelete(item.organizationcontacts_set[0].id)"
          )
            v-icon(size="18") icon-remove        
      v-pagination.mt-3(
        v-model="mainContactPage",
        :length="mainContactPageCount",
        circle="circle"
        :total-visible="10"
      )  
  v-dialog(
    v-model="successDialog",
    width="360",
    content-class="statusDialog"
  )
    success-dialog(@emitSuccessDialog="onEmitSuccessDialog")
  v-dialog(
    v-model="messageDialog",
    width="360",
    content-class="statusDialog"
  )
    message-dialog(
      @emitMessageDialog="onEmitMessageDialog",
      :message="message"
    )
  v-dialog(v-model='addSegDialog' max-width='500' v-if='addSegDialog')
      add-seg(@emitAddSeg='onEmitAddSeg' :addSegment='addSegment' :fromContact='true')
  v-dialog(v-model='sentEmail' max-width='800' v-if='sentEmail')
      emaileditor(:ownerData='ownerData' :objectType='objectType' :userEmail='userEmail' :contactListDetail='contactListDetail' :sentEmailList='sentEmailList' :fromCampaign='true' @emitSendEmailDialog='onEmitSendEmailDialog')
  v-dialog(v-model='sentSMS' max-width='800' v-if='sentSMS')
      emaileditor(:ownerData='ownerData' :objectType='"SMS"' :userEmail='userEmail' :contactListDetail='contactListDetail' :sentEmailList='sentEmailList' :fromCampaign='true' @emitSendEmailDialog='onEmitSendEmailDialog')
</template>

<script>
import Vue from "vue";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import CampaignDataService from "@/services/CampaignDataService";
import addSeg from '@/components/Dialog/Segment/addSegment';
import emailEditor from '@/components/Common/emailEditorContacts';
import emaileditor from '@/components/Common/emailEditorContacts';
import i18n from '/common/plugins/vue-i18n.js'

export default Vue.extend({
  props: {
    campaignlineId: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      required: false,
    },
    selectType:{
      type: String,
      required: true,
    },
  },
  components: { 
    successDialog,
    messageDialog,
    addSeg,
    emailEditor,
    emaileditor
  },
  async created() {
    let searchID = null
    if(this.campaignId)
    {
      searchID = this.campaignId;
    }
    else{
      searchID = this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id');
    }
    await  CampaignDataService.campaignlinecontact(searchID,this.campaignlineId).then((response)=>{
      if(response.data.success_open_link_contact.length > 0 ){
        this.contactList = response.data.success_open_link_contact[0].all;
        this.successList = response.data.success_open_link_contact[0].success;
        this.openList = response.data.success_open_link_contact[0].open;
        this.linkList = response.data.success_open_link_contact[0].link;
        this.contactList.forEach((data) => {
          data.full_name = data.last_name;
          if(data.first_name != null){
            data.full_name = data.last_name +" "+ data.first_name;
          }
          if (data.organization_set.length != 0) {
            data.org_name = data.organization_set[0].name;
          }
          // if (data.owner.first_name == null) {
          //   data.owner_name = data.owner.last_name;
          // } 
        });
      }
      this.process = this.selectType;
      this.isLoading = false;
    }) 
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  data() {
    return {      
      isLoading:true,
      unselectedRows:[],
      selectedRows:[],
      contactList:[],
      successList:[],
      openList:[],
      linkList:[],
      searchMainContact: "",
      process:"",
      selectList:[{name: i18n.t('CAMPAIGNCONTACTLIST.Success') ,id:"Success"},{name:i18n.t('CAMPAIGNCONTACTLIST.unSuccess'),id:"unSuccess"},{name: i18n.t('CAMPAIGNCONTACTLIST.Open') ,id:"Open"},{name: i18n.t('CAMPAIGNCONTACTLIST.unOpen'),id:"unOpen"},{name: i18n.t('CAMPAIGNCONTACTLIST.Link'),id:"Link"},{name: i18n.t('CAMPAIGNCONTACTLIST.unLink'),id:"unLink"}],
      mainContactHeader: [
        {
          value: "data-table-select",
          align: "center",
          width: '5%',
        },        
        {
          text: i18n.t('CAMPAIGNCONTACTLIST.CONTACT_NAME'),
          value: "full_name",
          align: "left",
          filterable: true,
          width: '12%',
        },      
        {
          text: i18n.t('CAMPAIGNCONTACTLIST.ORG_NAME'),
          value: "org_name",
          align: "left",
          width: '15%',
          filterable: true,
        },
        {
          text: i18n.t('CAMPAIGNCONTACTLIST.PHONE'),
          value: "mobile_phone",
          align: "left",
          filterable: true,
          width: '15%',
        },
        { 
          text: i18n.t('CAMPAIGNCONTACTLIST.MAIL'), 
          value: "email", 
          align: "left", 
          filterable: true,
          width: '18%', 
        },
      ],
      mainContactPage: 1,
      mainContactPageCount: 0,
      mainContactItemPerPage: 8,
      successDialog: false,
      messageDialog: false,
      message: "",
      addContact:[],     
      // navigation drawer
      emailDrawer: false,
      SMSDrawer: false,
      group: null,
      contactEmail:'',
      sentEmailList:[],
      userEmail: this.getLoginUserEmail(),
      contactListDetail: null,
      ownerData: "",
      sentEmail:false,
      sentSMS:false,
      objectType:'',
      // Dialog
      addMainContactDialog: false,
      editMainContactDialog: false,
      addMainContactData:{},
      edtorMainContactData:{},
      addSegDialog:false
    };
  },
  methods: {
    onResize: function () {
      let windowH = window.innerHeight - 150; //table高度
      let objH = 70;
      this.itemPerPage = parseInt(windowH/objH);
    },
    validate() {
      this.$refs.form.validate();
    },    
    // Common Function
    onCall: function (cell) {
      return "tel:" + cell;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;      
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    }, 
    onEmitMessageDialog() {
      this.messageDialog = false;      
    },   
    changePostData(){       
      if (this.selectedRows.length > 0){        
        for(let i = 0; i < this.selectedRows.length; i++)
        {          
          this.segContactList.splice(0,0,this.selectedRows[i].id)          
        }
      }    
    },
    async onConfirm() {    
      this.$router.go();  
    },
    onCancel() {
      this.$emit("emitAddSegContactDialog", false);
      this.$emit("emitCampaignContactList", false);
    },
    showAddStaticSeg(){
      // this.addStaticSegDialog = true;              
      this.addSegment={
      name: '',
      type: "STATIC",
      description: '',
      contacts:[]
      }     
      this.addSegDialog = true;
      for(let i in this.selectedRows)
      {
        this.addSegment.contacts.push(this.selectedRows[i].id)
      }      
    },
    onEmitAddSeg(val){      
      if(val){        
        this.addSegDialog = false;
        // this.showSuccessDialog();
      }
      else{
        this.addSegDialog = false;
      }      
      // this.selectedRows = [];
    },
    getLoginUserData() {
      var user = window.localStorage.getItem('user');
      if (user != null && user != undefined) {
          let obj = JSON.parse(user);          
          return obj.last_name + obj.first_name;
      } else {
          return '';
      }
    },
    getLoginUserEmail() {
      var user = window.localStorage.getItem('user');
      if (user != null && user != undefined) {
          let obj = JSON.parse(user);
          return obj.email;
      } else {
          return '';
      }
    },
    openEmailDrawer(){      
      this.objectType = "EMAIL"
      // this.contactsDetail =  this.selectedRows[0]
      // this.contactEmail = this.contactsDetail.email
      this.contactListDetail = this.selectedRows       
      this.emailDrawer =true       
      this.sentEmail = true    
      this.ownerData = this.getLoginUserData()
      for(let i in this.selectedRows)
      {
        this.sentEmailList.push(this.selectedRows[i].id)
      }      
    },
    openSMSDrawer(){
      this.objectType = "SMS"
      // this.contactsDetail =  this.selectedRows[0]
      // this.contactEmail = this.contactsDetail.email
      this.contactListDetail = this.selectedRows
      this.SMSDrawer =true    
      this.sentSMS = true    
      this.ownerData = this.getLoginUserData()
      for(let i in this.selectedRows)
      {
        this.sentEmailList.push(this.selectedRows[i].id)
      }  
    },
    onEmitSendEmailDialog(val) {
    if (val) {
        this.showSuccessDialog();        
    }
    else{
      this.sentEmail = false
      this.sentSMS = false
    }
    },
  },
  watch: {
            "process": async function () {  
            this.selectedRows = []     
            this.unselectedRows = []     
            if(this.process == "Success")
            {              
                this.contactList.forEach((data)=>{        
                  if(this.successList.includes(data.id))
                  {          
                    this.selectedRows.push(data)  
                  }
                  if(!this.successList.includes(data.id)){
                    this.unselectedRows.push(data) 
                  }
                })
                this.contactList = []
                this.contactList = this.selectedRows.concat(this.unselectedRows)
            }
            if(this.process == "unSuccess")
            {              
                this.contactList.forEach((data)=>{        
                  if(!this.successList.includes(data.id))
                  {          
                    this.selectedRows.push(data)  
                  }
                  if(this.successList.includes(data.id)){
                      this.unselectedRows.push(data) 
                    }
                  })
                  this.contactList = []
                  this.contactList = this.selectedRows.concat(this.unselectedRows)
            }
            if(this.process == "Open")
            {
              this.contactList.forEach((data)=>{        
                  if(this.openList.includes(data.id))
                  {          
                    this.selectedRows.push(data)  
                  }
                  if(!this.openList.includes(data.id)){
                      this.unselectedRows.push(data) 
                    }
                  })
                  this.contactList = []
                  this.contactList = this.selectedRows.concat(this.unselectedRows)
            }
            if(this.process == "unOpen")
            {
              this.contactList.forEach((data)=>{        
                  if(!this.openList.includes(data.id))
                  {          
                    this.selectedRows.push(data)  
                  }                  
                  if(this.openList.includes(data.id)){
                      this.unselectedRows.push(data) 
                    }
                  })
                  this.contactList = []
                  this.contactList = this.selectedRows.concat(this.unselectedRows)
            }
            if(this.process == "Link")
            {
              this.contactList.forEach((data)=>{        
                  if(this.linkList.includes(data.id))
                  {          
                    this.selectedRows.push(data)  
                  }
                  if(!this.linkList.includes(data.id)){
                      this.unselectedRows.push(data) 
                    }
                  })
                  this.contactList = []
                  this.contactList = this.selectedRows.concat(this.unselectedRows)
            }
            if(this.process == "unLink")
            {
              this.contactList.forEach((data)=>{        
                  if(!this.linkList.includes(data.id))
                  {          
                    this.selectedRows.push(data)  
                  }
                  if(this.linkList.includes(data.id)){
                      this.unselectedRows.push(data) 
                    }
                  })
                  this.contactList = []
                  this.contactList = this.selectedRows.concat(this.unselectedRows)
            }
            },
            "emailDrawer":function () {
              if(this.emailDrawer == false)
              {
                this.sentEmail = false
              }     
            },
            "SMSDrawer":function () {
              if(this.SMSDrawer == false)
              {
                this.sentSMS = false
              }      
            },
          }
});
</script>
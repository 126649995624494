import { authRequest } from './api/auth';

class PreferenceService {
    getWebPush() {
        return authRequest.get(`/preference/?code=WEB_PUSH`);
    }
    preferenceList() {
        return authRequest.get(`/preference/admin/`);
    }
    createPreference(data) {
        return authRequest.post(`/preference/admin/`, data);
    }
    editPreference(data) {
        return authRequest.put(`/preference/admin/${data.id}/`, data);
    }
    deletePreference(id) {
        return authRequest.delete(`/preference/admin/${id}/`);
    }
    getUpChat() {
        return authRequest.get(`/preference/?code=UPCHAT`);
    }
    getEmailSync() {
        return authRequest.get(`/preference/?code=EMAIL_SYNC`);
    }
    getSmsVendor() {
        return authRequest.get(`/preference/?code=SMS_VENDOR`);
    }
    update(code,data) {
        return authRequest.put(`/preference/?code=${code}`,data);
    }
    getOpenAI() {
        return authRequest.get(`/preference/?code=OPENAI`);
    }
    getApiKey() {
        return authRequest.get(`/preference/?code=API_ACCESS_KEY`);
    }
    getUPGPT_COPILOT() {
        return authRequest.get(`/preference/?code=UPGPT_COPILOT`);
    }
    getUPGPT() {
        return authRequest.get(`/preference/?code=UPGPT`);
    }
    getINDUSTRY() {
        return authRequest.get(`/preference/?code=INDUSTRY`);
    }
    getCUSTOM_DATA() {
        return authRequest.get(`/preference/?code=CUSTOM_DATA`);
    }
    getUPGPTSetting() {
        return authRequest.get(`/preference/?code=UPGPT_SETTING`);
    }
}

export default new PreferenceService();


export default {
  install(Vue) {
  Vue.directive('hide-element', {  
    bind(el, binding) {
      if(Vue.prototype.$isViewOnly.isViewOnly){
        if(binding.value === false){
          el.hideElement = false;
          el.style.display = ''; 
        }
        else{
          el.style.display = 'none';
        }
      }
    }  
  })
}
}; 
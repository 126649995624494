<template lang='pug'>
  #GPT.main-page.GPT-color.page-noScroll.pb-0.position-relative
    loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999' attach)
    loadingOverlay(v-if='delLoading' message='Loading . . .' :zIndex='999' attach)
            v-progress-circular(indeterminate color='primary')
    div.height_100(v-if='!loading')
      div(:href='"/upgpt/AINotebook"' style='margin-left:2.7%;').d-flex.align-start.justify-start
        v-breadcrumbs.pa-0.px-3.pb-2.ellipsis()
          v-breadcrumbs-item(v-for="item in breadcrumbs" :key="item.text" )
            .detail_a
              a(:href='item.href')
                v-icon(size='23' style='margin-bottom:2px;').pr-1 mdi-chevron-double-left
                span {{item.text}}
      #page-inner.pa-3.d-flex.justify-center.pt-0.mb-5(style='width:100%;' )
        v-card.deal-tab-card.rounded-card(style='width:95%;' flat)
          v-row.account-info(no-gutters='no-gutters' align="center" justify="center")
            v-col(style='max-width:63%;')
                v-card-title(:class='!isEdit? " pa-0 pb-2 mt-2":" pa-0 pt-0 mt-2"')
                    div.pl-4.pr-4.d-flex.align-center(width="50px" style='margin-bottom:-67px;')
                      v-img.ml-2(src="../assets/images/ainotebook.png" class="white--text align-end"  width="45px")
                .knowledges-detail-card-text.pt-0.pb-3
                  v-card-text.mt-0.pa-0.knowledges-card-text()
                        v-row(no-gutters :title='ainotebookData.name')
                            v-col.d-flex(cols='auto' ref="nameWidth")
                                h4.f-text-highlight-word.ellipsis(v-if='!isEdit' :class='isEdit? "pt-3":"pt-1"') {{ ainotebookData.name }}
                                v-btn.pb-2.pt-2( icon text color='gary' @click='onEdit' v-if='!isEdit')
                                    v-icon(size='18') icon-edit
                                p.d-flex.align-center.input-has-label.pt-0.mb-1(v-else)
                                  v-text-field.pt-0.pb-0(v-model='ainotebookData.name' :readonly='!isEdit'  :rules="formRule.nameRules") 
                                      template(v-slot:append)
                                          v-icon.mr-1.mt-2(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                          v-icon.mt-2(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                          v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                                          v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                        v-form(v-model='valid' ref='formContact')
                          v-row(no-gutters)
                              v-col.pr-md-4(cols='12' md='12' :title='ainotebookData.description').my-1.pt-0.mt-0 
                                span {{$t("GPT_AINOTEBOOK.DESCRIPTION")}} :
                                  p.d-flex.align-start.input-has-label.mr-12
                                    v-textarea.span.textarea-style.pt-0.mt-0(v-model='ainotebookData.description' :readonly='!isEdit' solo no-resize rows='3')
                                      template(v-slot:append)
                                        v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                        v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                        v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                                        v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                        .d-flex.align-center.justify-md-space-between.t-gray 
                          p.d-flex.align-center.justify-center.pt-2(style='white-space:nowrap;')  {{$t("GPT_AINOTEBOOK.LAST_UPDATED")}} :
                          v-list-item(v-if='ainotebookData.updated_by')
                            v-list-item-avatar.ma-0.mr-2.knowledges-avatar-32(color='aliceBlue')
                              span.span(v-if="ainotebookData.updated_by.usersetting.photo === ''") {{ ainotebookData.updated_by.last_name }}
                              img(v-else :src="ainotebookData.updated_by.usersetting.photo" :alt="ainotebookData.updated_by.last_name")
                            v-list-item-content.pa-0.user_icon
                              span.t-gray(class='name ellipsis') {{ ainotebookData.updated_by.last_name+" " }} {{ ainotebookData.updated_by.first_name}}
                              span(class='small_word_gray ellipsis' ) {{ getFormatTime(ainotebookData.updated_on) }}
                          v-list-item(v-else)
                            span.pb-2 {{$t("GPT_AINOTEBOOK.DEFAULT")}}
            v-col(style='max-width:37%;')
              //AiNotebook Chatbot hidden
              // .MeetingRecap-detail-subcard.pr-7
              //   v-card.rounded-card(flat style='height:100%;')
              //     v-card-title.pa-0.pt-1.d-flex.align-center.justify-md-space-between
              //       div.pa-2.d-flex.align-center(width="30px")
              //         v-img.ml-2(id='' src="../assets/images/AI_C.png" class="white--text align-end"  width="35px")
              //         h5.f-text-highlight-word.ellipsis.pl-3.pt-3 {{$t("GPT_MEETINGRECAP.ANR")}}
              //     .pa-0.pt-0.d-flex.justify-md-space-between
              //       v-card-text.pt-0(style='padding-left:70px;')
              //         v-row.pt-2(no-gutters)
              //             v-col(cols='auto' ref="nameWidth")
              //                 span.pl-1 {{$t("GPT_MEETINGRECAP.AN")}} : 
              //                 span {{meetingData.ai_notebook.name}}
              //             //- v-col(cols='1' v-if='!meetingData.ispublic')
              //             //-     v-btn.pb-1(height='15' icon text color='gary' @click='onEditSub' v-if='!isEditSub')
              //             //-         v-icon(size='15') icon-edit
              //         //- p(v-else).d-flex.align-center.knowledge-subcard-input
              //         //-   label.pr-4 {{$t("GPT_MEETINGRECAP.AN")}} :
              //         //-   v-autocomplete.no-border(v-model='meetingData.robots' return-object multiple solo :readonly='!isEditSub' :class='!isEditSub ? "remove-arrow" : ""' :items='chatBotList' item-text='bot_name' item-value='id'  :menu-props='{closeOnClick:true}')
              //         //-       template(v-slot:append-outer)
              //         //-           v-icon.mr-1(size='18' color="green" v-if='isEditSub' @click='confirmEditDetailSub()') icon-check
              //         //-           v-icon(size='18' color="red" v-if='isEditSub' @click='cancelEditDetailSub()') mdi-window-close
              //         //-           v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
              //         v-row.pt-5(no-gutters v-if='!loading && !isEditSub')
              //             v-col(cols='auto' ref="nameWidth")
              //                 span.pl-1 {{$t("GPT_MEETINGRECAP.OPPTY")}} : 
              //                 span {{meetingData.opportunity.name}}
              //             v-col(cols='1' v-if='!meetingData.ispublic')
              //                 v-btn.pb-1(height='15' icon text color='gary' @click='onEditSub' v-if='!isEditSub')
              //                     v-icon(size='15') icon-edit
              //         p(v-else).d-flex.align-center.knowledge-subcard-input
              //           label.pr-4 {{$t("GPT_MEETINGRECAP.OPPTY")}} : 
              //           v-autocomplete.no-border(v-model='meetingData.opportunity' return-object multiple solo :readonly='!isEditSub' :class='!isEditSub ? "remove-arrow" : ""' :items='opptyList' item-text='name' item-value='id'  :menu-props='{closeOnClick:true}')
              //               template(v-slot:append-outer)
              //                   v-icon.mr-1(size='18' color="green" v-if='isEditSub' @click='confirmEditDetailSub()') icon-check
              //                   v-icon(size='18' color="red" v-if='isEditSub' @click='cancelEditDetailSub()') mdi-window-close
              //                   v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
      #page-inner.d-flex.justify-center.pt-0(style='width:100%;margin-top:4px;')
        .switch-toggle(style='width:20%')
          input#type-toggle.check-toggle.check-toggle-round-flat(type="checkbox" v-model="isAiNotebookToggle" @click='changeType')
          label(for="type-toggle")
          span.toggle-on {{$t("GPT_MEETINGRECAP.AN")}}
          span.toggle-off {{$t("ADD_AINOTEBOOK.CRM_SYSTEM_FIELD")}}
          
      #page-inner.d-flex.justify-center.pt-0(v-if='!loading' style='width:100%;margin-left:auto;margin-right:auto;margin-top:4px;')
        CRMFieldToggle(v-if="this.ainotebookData.record_type == 'opportunity'" :ainotebookData ='ainotebookData') 
        AiNotebookToggle(v-else-if="this.ainotebookData.record_type == 'ai_notes'" :ainotebookData ='ainotebookData')

    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';
import UpMeetingService from "@/services/UpMeetingService";
import KnowledgeLibraryService from "@/services/KnowledgeLibraryService";
import AiNotebookToggle from "../components/GPT_Common/AiNotebookToggle.vue";
import CRMFieldToggle from "../components/GPT_Common/CRMFieldToggle.vue";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    AiNotebookToggle,
    CRMFieldToggle
  },
  data() {
    return {
      // other
      valid:true,
      isEdit:false,
      isEditSub:false,
      loading:false,
      delLoading:false,
      isAiNotebookToggle: false,
      apiRecordType: null,
      breadcrumbs: [{
              text: i18n.t('GPT_MENU.BACKTO') + i18n.t('GPT_AINOTEBOOK.TITLE'),
              disabled: false,
              href: '/upgpt/AINotebook',
          }
      ],
      formRule: {
          nameRules: [
              (v) => !!v || i18n.t('RULE.RULE_R'),
              (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
          ],
      },
      // Dialog
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      opptyList:[
        {id:1,name:"11111"},
        {id:2,name:"22222"},
      ],
      search:"",
      meetingData:{
        created_on:"2023-11-16T11:35:10.428792",
        updated_on:"2023-11-16T11:35:10.428792",
        last_access:"2023-11-16T11:35:10.428792",
        id:"4726498d-bcc0-8aa1-6440-fa6ac0cbd98d",
        name:"會議名稱 2023-12-11",
        meeting_summary:"",
        meeting_time:"2023-11-16T11:35:10.428792",
        ai_notes:{},
        suggested_tasks:{},
        questions_and_answers:{},
        ai_notebook:{id:1,name:"NOTE"},
        created_by:1,
        opportunity:{id:1,name:"OPPTY"},
        owner:{
            "id": 1,
            "first_name": "T",
            "last_name": "Victor",
            "usersetting": {
                "id": 1,
                "photo": "https://storage.googleapis.com/temp-next-app/demo/user/1/porco010.jpg"
            },
            "email": "alex.tian@artsolutions.io"
        },
        updated_by:1
      },

      ainotebookData: null,
      target_id : this.$route.params.id,
      mode:null,
      messageData:[
        {
            id:1,
            username:"123",
            time:"2023-12-11",
            text:"AAABBBCCC\n45678945\nQAQ\n98888AAABBBCCC\n45678945\nQAQ\n98888AAABBBCCC\n45678945\nQAQ\n98888AAABBBCCC\n45678945\nQAQ\n98888AAABBBCCC\n45678945\nQAQ\n98888AAABBBCCC\n45678945\nQAQ\n98888"
        },
        {
            id:2,
            username:"456",
            time:"2023-12-11",
            text:"AAABBBCCC\n45678945\nQAQ\n98888AAABBBCCC\n45678945\nQAQ\n98888AAABBBCCC\n45"
        },
      ],
    };
  },
  async created() {
    this.loading = true;
    await this.getDetailData();
    this.loading = false;
  },
  destroyed() {
  },
  methods: {
    highlight(text) {
        if(this.searchMsgList.length == 0) {
            return text;
        }
        return text.replace(new RegExp(this.lastSearchMsg, "gi"), match => {
            return '<span class="highlight">' + match + '</span>';
        });
    },
    getLittleFormatTime(data){
      let options = {year: 'numeric', month: '2-digit', day: '2-digit',hour: '2-digit',minute: '2-digit',hour12: false };
        let today = new Date(data);
        return today.toLocaleDateString("zh-TW", options).replaceAll('/','-'); 
    },
    set_title(){
        const title = localStorage.getItem('title');
        let title_name = this.ainotebookData.name;
        let tab_name = '';
        tab_name = this.ainotebookData.name;
        document.title = title +' : '+title_name +' – UpGPT';
        return tab_name;
    },
    async getDetailData(){
      await UpMeetingService.getAiNotebookDeteail(this.target_id).then((response)=>{
        let data = response.data;
        this.ainotebookData = response.data;
        this.apiRecordType= data.record_type;
        this.set_title()
      }).catch(response => {
          // Redirect to PermissionError page if error status is 403
          if (response.response.status == 403)
          {
              this.$router.replace('/upgpt/PermissionError');
          }
          else if(response.response.status == 404)
          {
              this.$router.replace('/upgpt/PageNotFound');
          }
      })
    },

    onDelete(){
      this.mode = "delete";
      this.message = i18n.t("GPT_KNOWLEDGE.DEL_MSG");
      this.messageDialog = true;
    },
    resetData(){
      this.mode = "reset";
      this.message = i18n.t("GPT_KNOWLEDGE.CLEAR_MSG");
      this.messageDialog = true;
    },
    getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = new Date(data);
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }
    },
    onEdit(){
        this.isEdit = true;
        this.originAiNotebookData = JSON.parse(JSON.stringify(this.ainotebookData));
    },
    async confirmEditDetail(){
      let edit_data = {
        "id" : this.ainotebookData.id,
        "name" : this.ainotebookData.name,
        "description" : this.ainotebookData.description,
        "ai_bot_id" : this.ainotebookData.ai_bot_id,
        "business_process_id" : this.ainotebookData.business_process_id,
        "record_type" : this.ainotebookData.record_type,
      }
      await UpMeetingService.editAiNotebook(edit_data).then((response)=>{
        this.ainotebookData.name = response.data.name;
        this.ainotebookData.ai_bot_id = response.data.ai_bot_id;
        this.ainotebookData.description = response.data.description;
        this.ainotebookData.updated_on = response.data.updated_on;
        this.ainotebookData.business_process_id = response.data.business_process_id;
        this.ainotebookData.record_type = response.data.record_type;
      })
      this.isEdit = false;
    },
    cancelEditDetail(){
      this.isEdit = false;
      this.ainotebookData = this.originAiNotebookData;
    },
    onEditSub(){
        this.isEditSub = true;
        this.originKnowledgeData = JSON.parse(JSON.stringify(this.meetingData));
    },
    async confirmEditDetailSub(){
      let edit_data = {
        "id" : this.meetingData.id,
        "linked_ai_bot" : this.meetingData.robots,
      }
      await KnowledgeLibraryService.set_detail_bots(edit_data).then((response)=>{
        this.meetingData.robots = response.data.linked_ai_bot;
        this.meetingData.updated_on = response.data.updated_on;
      })
      this.isEditSub = false;
    },
    cancelEditDetailSub(){
      this.isEditSub = false;
      this.meetingData = this.originKnowledgeData;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    // Dialog Area ----------------------------------------- [Start]
    
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
      if(this.mode == 'delete'){
        this.mode = null;
        this.$router.push('/upgpt/Knowledges');
      }
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async onEmitMessageDialog(val) {
      if(val){
        this.delLoading = true;
        this.messageDialog = false;
        if(this.mode == "delete"){
          await KnowledgeLibraryService.delete(this.target_id).then(()=>{
            this.$router.push('/upgpt/Knowledges');
            this.delLoading = false;
            this.mode = null;
          }).catch(() => {
              this.showErrorDialog(i18n.t('GPT_KNOWLEDGE.DELETE_ERROR'));
          });
        }else if(this.mode == "reset"){
          await KnowledgeLibraryService.clear_knowledge(this.target_id).then(()=>{
            // this.$refs.knowledgeTabs.getKnowledgeInfo();
            this.delLoading = false;
            this.mode = null;
          }).catch(() => {
              this.showErrorDialog(i18n.t('GPT_KNOWLEDGE.CLEAR_ERROR'));
              this.mode = null;
          });
        }
      }
      this.messageDialog = false;
    },
    async reFresh(){
      this.loading = true;
      await this.getDetailData();
      this.loading = false;
    },
    async changeType(){
      if(!this.loading){
        let edit_data = {
            "id" : this.ainotebookData.id,
            "name" : this.ainotebookData.name,
            "record_type" : !this.isAiNotebookToggle? "opportunity":"ai_notes",
          }
          await UpMeetingService.editAiNotebook(edit_data).then((response)=>{
            this.ainotebookData.record_type = response.data.record_type;
          })
        }
    }
  },
  watch:{
    apiRecordType(newVal){
      if(newVal == "ai_notes"){
        this.isAiNotebookToggle = false;
      }else if(newVal == "opportunity"){
          this.isAiNotebookToggle = true;
      }
    },
  }
};
</script>

<style lang="scss">
.small-line-height{
    .v-textarea textarea {
        line-height: 22px;
  }
}
.search_border{
  margin-top:0px;
  border: 1px solid rgb(163, 163, 163);
  border-radius: 5px;
}
</style>
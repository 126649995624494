<template>
    <v-card style="border-left:1px solid 353535; padding-left: 3px;border-radius: 0% !important;">
        <v-toolbar flat  color="#ffffff">
            <v-toolbar-title style="font-size: 28px;font-weight:bold;;"> {{$t('INBOX.TITLE')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon @click="getInboxData()"> -->
            <!-- <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn> -->
            <v-btn v-hide-element='false' icon height='38' color='gary' @click='refresh()'>
                <v-icon>mdi-refresh</v-icon>
             </v-btn>
            <v-menu v-model='menu' bottom left z-index='1000000' offset-y>
                <template v-slot:activator='{}'>
                    <v-btn v-hide-element='false' icon @click="openMenu()">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn> 
                </template>
                <v-list>
                    <v-list-item  @click ="markAllRead()">
                        <v-list-item-title>{{$t('INBOX.ALL_READ')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-hide-element @click ="deleteAllPost()">
                        <v-list-item-title>{{$t('INBOX.ALL_DELETE')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click ="deleteAllAlert()">
                        <v-list-item-title>{{$t('INBOX.ALL_DELETE_ALERT')}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- <v-btn icon @click="closeInboxNavigation()">
                <v-icon>mdi-close</v-icon>
            </v-btn> -->
        </v-toolbar>
        <hr style="margin: 2px 0;color: #3953ffb9;">
        <div >
            <v-tabs class="localfix" color="#3953ffb9"  v-model="tab" align-tabs="title">
                <v-tab v-for="item in items" :key="item.title" style="text-transform: none !important;">
                    {{ item.title }}
                </v-tab>
                <div style="height: 50px;">
                    <v-btn style="position: absolute; right: 10px;top: 5px; font-weight:bold;" color='success' @click="newPost=true">{{$t("INBOX.NWE_POST")}}</v-btn>
                </div>

            </v-tabs>
            <div style="position: relative; display: flex" class='mb-1'>
                    <div style="width: 98%; margin-top: 10px;margin-left: 5px;">
                        <v-text-field outlined v-model='searchContent' height="30px" flat dense  prepend-inner-icon='icon-search' style="width:auto;"></v-text-field>
                    </div>
                    <v-spacer></v-spacer>
                    <!-- <div class="filterLocalFix" style="width: 30%;margin-right: 5px;">
                        <v-select
                        v-model="filterSelect"
                        :items="filterItems"
                        label="Filter"
                        style="z-index: 999999999;"
                        ></v-select>                        
                    </div> -->
            </div>
            <!-- <hr class='mb-0 mt-0'> -->
            <div v-if="newPost" class="float_box_post ma-0 pa-0" >
                <ReplyEditor v-if="newPost" :ispost="true" @reply-pass="createNewPost" @reply-attachments="transAttachment" @close-editor="closeEditor">Post</ReplyEditor>
            </div>
            <!-- <ReplyEditor v-if="newPost" @reply-pass="createNewPost" @reply-attachments="transAttachment" @close-editor="closeEditor">Post</ReplyEditor> -->
            <!-- <hr v-if="newPost"> -->
            <loadingOverlay v-show='loading' message=' Loading . . .' style="z-index:99;"></loadingOverlay>
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in items" :key="item.key">
                    <!-- Unread -->
                    <div class="inboxScrollBar0" :class="scrollbar" v-if="item.key == 'Unread'"> 
                        <div v-if='unreadMessageCount != 0'>                       
                            <div class="inboxContainer" v-for="(post,index) in (isSearch)?searchData:combineData" :key="post.id">
                                <div v-if="!post.has_read" class="post_css">
                                    <post tab='Unread' :post="post" :postIndex="index" v-if="post.type =='POST'" class="post_css" @reloadCurrentPost="getSinglePost" @renewUnreadStage="updateUnreadMessageCount"></post>
                                    <alert :alert="post" v-else :alertIndex="index" @renewUnreadStage="updateUnreadMessageCount" @reloadCurrentAlert="ReloadAlert" @changeTab="changeTab" class="post_css"></alert>
                                </div>
                            </div>
                        </div>
                        <div class='blankbox' v-else>
                            <img src="../../assets/images/work_complete.png" />
                            <div>{{$t('INBOX.ALL_HAVE_READ')}}</div>
                        </div>
                    </div>
                    <!-- All -->
                    <div class="inboxScrollBar1" :class="scrollbar" v-else-if="item.key == 'All'">
                        <div v-if='combineData.length != 0'>
                            <div class="inboxContainer" v-for="(post,index) in (isSearch)?searchData:combineData" :key="post.id">
                                <post tab='All' :post="post" :postIndex="index" v-if="post.type =='POST'" @reloadCurrentPost="getSinglePost" @renewUnreadStage="updateUnreadMessageCount" class="post_css"></post>
                                <alert :alert="post" v-else :alertIndex="index" @renewUnreadStage="updateUnreadMessageCount" @reloadCurrentAlert="ReloadAlert" @changeTab="changeTab" class="post_css"></alert>
                            </div> 
                        </div>
                        <div class='blankbox' v-else>
                            <img src="../../assets/images/work_complete.png" />
                            <div>{{$t('INBOX.NO_NOTIFICATION')}}</div>
                        </div>
                    </div>
                    <!-- Post -->                   
                    <div class="inboxScrollBar2" :class="scrollbar" v-else-if="item.key == 'Post'">
                        <div v-if='postData.length != 0'>
                            <div class="inboxContainer" v-for="(post,index) in (isSearch)?searchData:combineData" :key="post.id">
                                <post tab='Post' :post="post" :postIndex="index" v-if="post.type =='POST'" @reloadCurrentPost="getSinglePost" @renewUnreadStage="updateUnreadMessageCount" class="post_css"></post>
                            </div>
                        </div>
                        <div class='blankbox' v-else>
                            <img src="../../assets/images/work_complete.png" />
                            <div>{{$t('INBOX.NO_NOTIFICATION')}}</div>
                        </div>
                    </div>
                    <!-- Alter -->
                    <div class="inboxScrollBar3" :class="scrollbar" v-else-if="item.key == 'Alert'">  
                        <div v-if='alertData.length != 0'>                      
                            <div class="inboxContainer" v-for="(post,index) in (isSearch)?searchData:combineData" :key="post.id">
                                <alert :alert="post" :alertIndex="index" v-if="post.type !='POST'"  @renewUnreadStage="updateUnreadMessageCount"  @reloadCurrentAlert="ReloadAlert" @changeTab="changeTab" class="post_css"></alert>
                            </div>
                        </div>
                        <div class='blankbox' v-else>
                            <img src="../../assets/images/work_complete.png" />
                            <div>{{$t('INBOX.NO_NOTIFICATION')}}</div>
                        </div>
                    </div>
                    <!-- Focus -->                   
                    <div class="inboxScrollBar4" :class="scrollbar" v-else-if="item.key == 'Focus'">
                        <div class="inboxContainer" v-for="(post,index) in focusData" :key="post.id">
                            <post tab='Post' :post="post" :postIndex="index" v-if="post.type =='POST'" @reloadCurrentPost="getSinglePost" @renewUnreadStage="updateUnreadMessageCount" class="post_css"></post>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-dialog v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog' :attach='true'>
            <message-dialog @emitMessageDialog='onEmitMessageDialog' :message='message'></message-dialog>
        </v-dialog>
        <v-dialog v-model='errorDialog' width='360' content-class='statusDialog' v-if='errorDialog' :attach='true'>
            <error-dialog @emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage'></error-dialog>
        </v-dialog>
    </v-card>
</template>

<script>
    import Post from './Post.vue'
    import Alert from './Alert.vue'
    import ReplyEditor from './ReplyEditor.vue'
    import PostDataService from '@/services/PostDataService';
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import messageDialog from '@/components/Dialog/messageDialog';
    import i18n from '/common/plugins/vue-i18n.js';
    import errorDialog from '@/components/Dialog/errorDialog';
 
    export default {
        name: 'Inbox',
        props: {
        },
        components: {
            Post,
            Alert,
            ReplyEditor,
            loadingOverlay,
            messageDialog,
            errorDialog,
        },
        data() {
            return {
                searchContent:"",
                isSearch:false,
                awaitingSearch:false,
                searchData:[],
                newPostContent:"",
                newPost:null,
                items: [
                    {title:i18n.t('INBOX.UNREAD'),key:'Unread'},
                    {title:i18n.t('INBOX.ALL'),key:'All'},
                    {title:i18n.t('INBOX.POST'),key:'Post'},
                    {title:i18n.t('INBOX.ALERT'),key:'Alert'},
                ],
                filterSelect: "",
                filterItems: ['Filter by person','Filter by content','Filter by All'
                ],
                menu:false,
                message:'',
                messageDialog: false,
                unreadMessageCount:0,
                deleteAllType:'',
                postData:[],
                alertData:[],
                combineData:[],
                tab:null,
                scrollbar:"scrollbar",
                attachments:[],
                // filter
                filter_read:'',
                filter_mention:'',
                search:'',
                loading:false,
                refreshing:false,
                focusData:null,
                errorMessage:'',
                errorDialog:false,
            };
        },
        async created(){
            this.loading = true;
            await this.getInboxData();
            window.addEventListener('resize', this.onResize)
            this.loading = false;
        },
        watch:{
            unreadMessageCount:{
                handler(){
                    this.$emit('updateInboxUnreadCount',this.unreadMessageCount)
                }
            },
            "searchContent":async function(){
                this.newPost = false;
                if (!this.awaitingSearch) {
                    setTimeout(() => {
                        this.reloadSearchData();
                        this.awaitingSearch = false;
                    }, 1000);
                }
                this.awaitingSearch = true;
            },
            filterSelect:{
                async handler(){
                    if(this.searchContent != ""){
                        this.newPost = false;
                        if (!this.awaitingSearch) {
                            setTimeout(() => {
                                this.reloadSearchData();
                                this.awaitingSearch = false;
                            }, 1000);
                        }
                        this.awaitingSearch = true;                    
                    }
                }
            },
            tab:function(){
                this.onResize();
                if(this.items.length==5 && this.tab !=4){
                    this.focusData=null;
                    this.items = [{title:i18n.t('INBOX.UNREAD'),key:'Unread'},
                    {title:i18n.t('INBOX.ALL'),key:'All'},
                    {title:i18n.t('INBOX.POST'),key:'Post'},
                    {title:i18n.t('INBOX.ALERT'),key:'Alert'},]
                }
            },
            focusData:function(){
                if(this.focusData!=null){
                    this.tab = 4;
                }
            }
        },
        methods: {
            changeTab(id){
                let focusData = this.combineData.filter(el => el.id == id )
                if(focusData.length == 0){
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('INBOX.ERROR_POST');
                    return;
                }
                this.items.push({title:i18n.t('INBOX.FOCUS'),key:'Focus'},)
                this.focusData = focusData;
            },
            onResize: function () {
                let windowH = window.innerHeight - 167; 
                setTimeout(()=>{
                    document.querySelector(`.inboxScrollBar${this.tab}`).style.height = windowH+"px";
                },100)
            },
            closeInboxNavigation(){
                this.$emit("closeInboxNavigation","")
            },
            async getInboxData(){
                this.combineData.length = 0;
                await this.getUserPost();
                await this.getUserAlert();

                this.postData.forEach(item=>{
                    this.combineData.push(item);
                    }
                )
                this.alertData.forEach(item=>{
                    this.combineData.push(item);
                    }
                )
                this.combineData = this.combineData.sort(function(a,b){
                    return -(a.created_on.localeCompare(b.created_on))
                });
            },
            async reloadSearchData(){
                this.searchData.length = 0;
                this.loading = true;
                this.newPost = false;
                await this.getInboxData();
                if(this.searchContent != ""){
                    switch (this.filterSelect) {
                        case 'Filter by person':{
                            this.combineData.forEach(item=>{
                                let filter = item.owner_full_name;
                                if(item.reply_set){
                                    item.reply_set.forEach(item=>{
                                        filter += item.owner_full_name;
                                        if(item.reply_set){
                                            item.reply_set.forEach(item=>{
                                                filter += item.owner_full_name;
                                            })
                                        }
                                    })
                                }
                                if(filter.toLowerCase().includes(this.searchContent.toLowerCase())){
                                    this.searchData.push(item)
                                }
                            })
                            break;
                        }
                        case 'Filter by content':{
                            this.combineData.forEach(item=>{
                                let filter = item.content;
                                if(item.reply_set){
                                    item.reply_set.forEach(item=>{
                                        filter += item.content;
                                        if(item.reply_set){
                                            item.reply_set.forEach(item=>{
                                                filter += item.content;
                                            })
                                        }
                                    })
                                }
                                if(filter.toLowerCase().includes(this.searchContent.toLowerCase())){
                                    this.searchData.push(item)
                                }
                            })
                            break;
                        }
                        case 'Filter by All':
                        case '':{
                            this.combineData.forEach(item=>{
                                if(JSON.stringify(item).toLowerCase().includes(this.searchContent.toLowerCase())){
                                    this.searchData.push(item)
                                }
                            })
                            break;
                        }
                    }
                    this.isSearch = true;
                }else{
                    this.isSearch = false;
                }
                this.loading = false;
            },
            async getUserPost(){
                await PostDataService.getAllPost(this.search,this.filter_read,this.filter_mention).then(response=>{
                    this.postData = response.data.post_list;
                    this.unreadMessageCount = response.data.unread;
                })
            },
            async getUserAlert(){
                await PostDataService.getAllAlert(this.search,this.filter_read,this.filter_mention).then(response=>{
                    this.alertData = response.data;
                })
            },
            async updateUnreadMessageCount(){
                await PostDataService.getAllPost(this.search,this.filter_read,this.filter_mention).then(response=>{
                    this.unreadMessageCount = response.data.unread;
                })
            },
            async createNewPost(val){
                let passData = val;
                passData.type = "POST";
                passData.reply_post = null;
                await PostDataService.createNewPost(passData).then(response=>{
                    if(this.attachments.length > 0){
                        let attachmentCounts = this.attachments.length;
                        this.attachments.forEach((item,index)=>{
                            PostDataService.passAttachment(response.data.id,item).then(()=>{
                                if(index == (attachmentCounts-1))this.reloadSearchData();
                            });
                        })
                        this.attachments = [];
                    }else{
                        this.reloadSearchData();
                    }
                    this.newPost = false;
                });
            },
            openMenu(){
                this.menu = !this.menu;
            },
            markAllRead(){
                this.combineData.forEach(post=>{
                    let data = {
                        "has_read": true,
                    }
                    if(post.type == "POST"){
                        data["id"]=post.regard_id;
                        data["subscribe"]=post.subscribe;
                        PostDataService.updatePostStatu(data).then(response=>{
                            post.has_read = response.data.has_read;
                        });
                    }else{
                        data["id"]=post.alertregard_id;
                        PostDataService.updateAlertStatu(data).then(response=>{
                            post.has_read = response.data.has_read;
                        });
                    }
                });
                this.unreadMessageCount = 0;
            },
            deleteAllPost(){
                this.deleteAllType = 'POST';
                this.messageDialog = true;
                this.message = i18n.t('INBOX.ALL_DELETE_CHECK');
            },
            deleteAllAlert(){
                this.deleteAllType = 'ALERT';
                this.messageDialog = true;
                this.message = i18n.t('INBOX.ALL_DELETE_ALERT_CHECK');
            },
            async onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val) {
                    switch(this.deleteAllType){
                        case 'POST':{
                            await PostDataService.deleteAllPost();
                            await this.reloadSearchData();
                            break;
                        }
                        case 'ALERT':{
                            await PostDataService.deleteAllAlert();
                            await this.reloadSearchData();
                            break;
                        }
                    }
                }
                this.deleteAllType = '';
            },
            onEmitErrorDialog(){
                this.errorDialog = false;
            },
            transAttachment(val){
                this.attachments = val;
            },
            closeEditor(){
                this.newPost = false;
            },
            async getSinglePost(postId,postIndex,actionType){
                if(this.isSearch){
                    this.reloadSearchData();
                }else{
                    switch(actionType){
                        case "reload":{
                            await PostDataService.getSinglePost(this.search,this.filter_read,this.filter_mention,postId)
                            .then(response=>{
                                let post = response.data[0];
                                let post_data_index = null;
                                if(this.tab == 4){
                                    post_data_index = this.postData.indexOf(this.focusData[0]);
                                    let combine_data_index = this.combineData.indexOf(this.focusData[0]);
                                    this.focusData = [post];
                                    this.postData.splice(post_data_index,1,post);
                                    this.combineData.splice(combine_data_index,1,post);
                                }else{
                                    post_data_index = this.postData.indexOf(this.combineData[postIndex]);
                                    this.postData.splice(post_data_index,1,post);
                                    this.combineData.splice(postIndex,1,post);
                                }
                            });
                            break;
                        }
                        case "delete":{
                            if(this.tab == 4){
                                this.focusData = null;
                                this.tab = 2;
                                await this.reloadSearchData();
                                break;
                            }
                            let post_data_index = this.postData.indexOf(this.combineData[postIndex]);
                            this.postData.splice(post_data_index,1);
                            this.combineData.splice(postIndex,1);
                            this.updateUnreadMessageCount();
                            break;
                        }
                    }
                }
            },
            async ReloadAlert(alertRegardId,alertIndex){
                if(this.isSearch){
                    this.reloadSearchData();
                }else{
                    this.combineData.splice(alertIndex,1);
                    let temp_delete;
                    for(let i = 0,ml = this.alertData.length; i<ml ; i++){
                        if(this.alertData[i].alertregard_id == alertRegardId){
                            temp_delete = i;
                        }
                    }
                    this.alertData.splice(temp_delete,1);
                    this.updateUnreadMessageCount();
                }
            },
            async refresh(){
                if(this.refreshing==true)
                {
                    return;
                }
                await (this.refreshing=true);
                this.combineData=[];
                this.searchData=[];
                await this.reloadSearchData();
                setTimeout(()=>{
                    this.refreshing=false;
                },500)
            },
        }
    }
</script>
<style lang="css">
    .localfix .v-slide-group__prev.v-slide-group__prev--disabled,.localfix .v-slide-group__next
    {
        display: none !important;
    }
    .inboxContainer{
        max-width: 780px;
        min-width: 507px;
        width: 43.8vw; 
        height:auto;
        position: relative;
        margin-top: 5px;
        margin-bottom: 10px;
        padding-left: 5px;
    }
    .inboxScrollBar0{
        overflow-x: hidden !important;
        height: 1080;
    }
    .inboxScrollBar1{
        overflow-x: hidden !important;
        height: 1080;
    }
    .inboxScrollBar2{
        overflow-x: hidden !important;
        height: 1080;
    }
    .inboxScrollBar3{
        overflow-x: hidden !important;
        height: 1080;
    }
    .post_css{
        max-width: 780px;
        min-width: 507px;
        width: 43.8vw; 
        border: 1px solid #F5F5F5; 
    }
    .filterLocalFix .v-select__selections input { display: none; }
    .float_box_post {
        /* background-image: linear-gradient(to top, #fffaba 0%, #ffffff 100%); */
        /* background-color: #ecedf3; */
        max-width: 793px;
        min-width: 500px;
        width: 44.5vw; 
        height: auto;
        min-height: auto;
        text-align: left;
        position: absolute;
        top: 170px;
        left: 0px;
        z-index: 9999999;
        border-radius: 5px 5px 5px 5px;
        /* border-bottom: 1px solid #eeeeee; */
        box-shadow: 0px 15px 10px -7px;
    }
    .blankbox{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 15vh;
    }
    .blankbox img{
        height: 300px;
        width: 450px;
    }
    .blankbox div{
        color: rgba(0, 0, 0, 0.54);
        font-size: 1.25rem;
        font-weight: 700;
        margin-top: 20px;
    }
</style>
<template lang='pug'>
  v-card
    v-card-title.d-flex.justify-center.pb-0 Sales Order 
        v-card-text.pt-3
            v-form(v-model='valid' ref='form')
                v-row(no-gutters)  
                    v-col(cols='12')
                        v-text-field.v-text-field__slot.height-58(v-model='soData.sales_order.name' :loading='soLoading' :title='soData.sales_order.name' prepend-icon="fa-file-signature" label='Sales Order')
                            template(v-slot:append)
                                v-icon(v-if='soCheck' size='18' color="green") icon-check
                                v-icon(v-if='!soCheck' size='18' color="red") mdi-window-close
                                //- v-progress-circular(size='18' v-if='soLoading' indeterminate color='primary')
                            template(v-slot:append-outer)
                                v-btn( :ripple='false' @click='searchSo' width='100%' height='30' color='cancel' depressed dark) 
                                  span(v-if='!soLoading') {{$t('GENERAL.SEARCH')}}
                                  v-progress-circular(size='18' v-else indeterminate color='white')
                v-row(no-gutters)     
                    v-col(cols='12')
                        v-autocomplete(
                            v-model="soData.sales_order_line",
                            :items="sales_order_line_list",
                            item-text="sequence",
                            item-value="sequence",
                            return-object,
                            flat,
                            :title='soData.sales_order_line',
                            prepend-icon="fa-file-signature",
                            label='Sales Order Line'
                            @change='selectpol'
                        )
                v-row(no-gutters)    
                    v-col(cols='12')
                        v-text-field.v-text-field__slot.height-58(v-model='soData.purchase_order' readonly :title='soData.purchase_order' prepend-icon="fa-file-signature" label='Purchase Order')
                v-row(no-gutters)
                    v-col(cols='12')
                        v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                            template(v-slot:activator="{ on, attrs }")
                                v-text-field.v-text-field__slot.height-58(:label=`$t('SERVICE_PRODUCT_LINE.START_DATE')`, no-resize prepend-icon="icon-calendar" v-model='soData.start_date'  v-bind="attrs" v-on="on"  hide-details="auto"  :rules='formRules.dateRule' class='num_field' readonly clearable  :change='toDateCheck()')
                            v-date-picker(v-model='soData.start_date' @input='menu_from = false' no-title scrollable) 
                v-row(no-gutters)
                    v-col(cols='12')
                        v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                            template(v-slot:activator="{ on, attrs }")
                                v-text-field.v-text-field__slot.height-58(:label=`$t('SERVICE_PRODUCT_LINE.END_DATE')`, no-resize prepend-icon="icon-calendar" v-model='soData.end_date'  v-bind="attrs" v-on="on" hide-details="auto"  :rules='formRules.dateRule' class='num_field' readonly clearable  )
                            v-date-picker(v-model='soData.end_date' @input='menu_to = false' no-title scrollable :min='minDate(soData.start_date)') 

    v-card-actions.pt-5.pb-5
      v-row(no-gutters).px-5
          v-col.pr-2(cols='6')
              v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
          v-col.pl-2(cols='6')
              v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.CONFIRM')}}

    //- v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
    //-   message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    //- v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
    //-   success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    //- v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
    //-   error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';
import ProductLineItemService from "@/services/ProductLineItemService";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
  },
  props: {
    soData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
        // Dialog
        messageDialog: false,
        message: "",
        successDialog: false,
        successMessage: "",
        errorDialog: false,
        errorMessage: "",
        // Data
        soLoading:false,
        soCheck:false,
        comboBoxLoading:false,
        valid:true,
        formRules:{
            dateRule: [
                (v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) || i18n.t('RULE.RULE_DATE')
            ],
        },
        sales_order_line_list:[],
        lastSoData:null,
        lastSoLineList:null,
    };
  },
  async created() {
    if(this.soData.sales_order.name){
      this.soCheck = true;
    }
    let selected_pol = {sequence:"",purchaseOrderNumber:""}
    if(this.soData.sales_order_line){
      selected_pol.sequence = this.soData.sales_order_line;
      if(this.soData.sales_order_line){
        selected_pol.purchaseOrderNumber = this.soData.purchase_order;
      }
      this.sales_order_line_list.push(selected_pol)
    }
    this.lastSoData = JSON.parse(JSON.stringify(this.soData));
    this.lastSoLineList = JSON.parse(JSON.stringify(this.sales_order_line_list));
  },
  destroyed() {
  },
  methods: {
    async searchSo(){
      // if(!this.soData.sales_order.name || this.soCheck){
      //   return
      // }
      this.soLoading = true;
      let data = {
          "soNumber":this.soData.sales_order.name
      }
      await ProductLineItemService.wiadvanceSoApi(data).then((response)=>{
        if(response.data == false){
          this.soCheck = false;
          this.sales_order_line_list = [];
        }else{
          this.soCheck = true;
          this.sales_order_line_list = response.data;
          if(this.soData.sales_order_line){
            let sol = this.sales_order_line_list.filter(el=> el.sequence == this.soData.sales_order_line)
            if(sol.length > 0){
              this.soData.sales_order_line = sol[0].sequence;
              this.soData.purchase_order = sol[0].purchaseOrderNumber;
            }
          }
        }
      }).finally(()=>{
        this.soLoading = false;
      })
    },
    selectpol(val){
      if(val){
        this.soData.sales_order_line = val.sequence;
        this.soData.purchase_order = val.purchaseOrderNumber;
        this.soData.start_date = val.periodStartDate == "0001-01-01" ? this.soData.start_date:val.periodStartDate;
        this.soData.end_date = val.periodEndDate == "0001-01-01" ? this.soData.end_date:val.periodEndDate;
        this.lastSoData = JSON.parse(JSON.stringify(this.soData));
        this.lastSoLineList = JSON.parse(JSON.stringify(this.sales_order_line_list));
      }
    },
    onCancel(){
      this.$emit('emitSoDialog',false);
    },
    onConfirm(){
      if(!this.$refs.form.validate()) return;
      if(!this.soCheck){
        this.soData.sales_order = {name:'',url:''}
      }
      this.$emit('emitSoDialog',true);
    },
    minDate(date){
        let current_date = new Date(date).getTime();
        let min = current_date + 1000*60*60*24;
        return (new Date(min).getFullYear()).toString() +'-' +(new Date(min).getMonth()+1).toString().padStart(2,0)+'-'+ (new Date(min).getDate()).toString().padStart(2,0)
    },
    toDateCheck(){
        if(this.soData.start_date){
            let FD = new Date(this.soData.start_date).getTime();
            let TD = new Date(this.soData.end_date).getTime();
            if(this.soData.end_date && (TD-FD)<=0){
                this.soData.end_date = this.minDate(this.soData.start_date);
            }
        }
    },
    // Dialog Area ----------------------------------------- [Start]
    
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog() {
      this.messageDialog = false;
    },
  },
  watch:{
    "soData.sales_order.name":function(){
      if(this.soCheck){
        this.soCheck = false;
        this.soData.sales_order_line = "";
        this.soData.purchase_order = "";
        this.sales_order_line_list = [];
      }
      if(this.lastSoData){
        if(this.soData.sales_order.name == this.lastSoData.sales_order.name){
          this.soCheck = true;
          this.sales_order_line_list = this.lastSoLineList;
          this.soData.sales_order_line = this.lastSoData.sales_order_line;
          this.soData.purchase_order = this.lastSoData.purchase_order;
        }
      }
    }
  }
};
</script>
import { authRequest } from './api/auth';

class OrderDataService {
    getOrderDetail(id) {
        return authRequest.get(`/order/${id}/`);
    }
    updateOrderDetail(data) {
        return authRequest.put(`/order/${data.id}/`, data);
    }
    create(data) {
        return authRequest.post('/order/', data);
    }
    delete(id) {
        return authRequest.delete(`/order/${id}/`)
    }
    editowner(data) {
        return authRequest.put(`/order/${data.id}/owner/`, data);
    }
    list(page, pageitem,mode,filter,search,sortby) {
        return authRequest.get(`/order/?page=${page}&pageitem=${pageitem}&mode=${mode}&filter=${filter}&search=${search}&sortby=${sortby}`);
    }
    timeline(id) {
        return authRequest.get(`/order/${id}/timeline/`)
    }
}
export default new OrderDataService();
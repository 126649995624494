<template lang='pug'>
#Setting.main-page.detail-page.px-12.py-6
    loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    v-row(v-show='!loading')
        v-col.pl-12(cols='12' md='3')
            h1.mt-2.mb-4 {{$t('SETTING.TITLE')}}
            v-tabs(v-model='tab' :vertical='isdesktop' hide-slider)
                h5 {{$t('SETTING.PERSONAL')}}
                v-tab.px-0(href='#PersonalSetting' :ripple='false') {{$t('SETTING.PERSONALSETTING')}}
                v-tab.px-0(href='#PasswordAndLogin' :ripple='false') {{$t('SETTING.PASSWORDANDLOGIN')}}
                
                h5(v-hide-element) {{$t('SETTING.COMMUNICATION')}}
                v-tab.px-0(v-hide-element href='#CalendarSync' :ripple='false') {{$t('SETTING.CALENDARSYNC')}}
                v-tab.px-0(v-hide-element href='#EmailSync' :ripple='false') {{$t('SETTING.EMAILSYNC')}}
                v-tab.px-0(v-hide-element href='#EmailTemplate' :ripple='false') {{$t('SETTING.EMAILTEMPLATE')}}
                v-tab.px-0(v-hide-element href='#smsTemplate' :ripple='false') {{$t('SETTING.SMSTEMPLATE')}}
                v-tab.px-0(v-hide-element v-if='isAdmin && canSetSmsVendor' href='#smsVendor' :ripple='false') {{$t('SETTING.SMSVENDOR')}}
                
                h5(v-hide-element v-if='canManageUsers') {{$t('SETTING.COMPANY_SETTING')}}
                v-tab.px-0(v-hide-element v-if='canManageUsers' href='#ManageUsers' :ripple='false') {{$t('SETTING.MANAGEUSERS')}}
                v-tab.px-0(v-hide-element v-if='isAdmin' href='#ManageGroups' :ripple='false') {{$t('SETTING.MANAGEGROUPS')}}
                v-tab.px-0(v-hide-element v-if='canManageStageType' href='#PipelineStageSetting' :ripple='false') {{$t('SETTING.PIPELINESTAGESETTING')}}
                v-tab.px-0(v-hide-element v-if='canManageStageType' href='#NotificationSetting' :ripple='false') {{$t('SETTING.NOTIFICATIONSETTING')}}
                v-tab.px-0(v-hide-element v-if='canManageStageType' href='#ActivityTypes' :ripple='false') {{$t('ACTIVITY.TYPE_SETTING')}}
                
                h5 {{$t('SETTING.DATA_VIEW')}}
                v-tab.px-0(v-hide-element v-if='canManageCustomFields' href='#CustomFields' :ripple='false') {{$t('SETTING.CUSTOMFIELDS')}}
                v-tab.px-0(v-hide-element v-if='canManageCustomFields' href='#TreeSetting' :ripple='false') {{$t('SETTING.TREESETTING')}}
                v-tab.px-0(v-hide-element v-if='canManageCustomFields' href='#TableSetting' :ripple='false') {{$t('SETTING.TABLESETTING')}}
                v-tab.px-0(href='#FilterSetting' :ripple='false') {{$t('SETTING.FILTERSETTING')}}
                v-tab.px-0(v-hide-element v-if='canManageCustomFields' href='#CustomOpptyClosedReason' :ripple='false') {{$t('SETTING.CUSTOMOPPTYCLOSEDREASON')}}

                h5(v-if='canManageStageType') {{$t('SETTING.CUSTOMER_SERVICE_SETTING')}}
                v-tab.px-0(v-hide-element v-if='canManageStageType && !isWiadvance()' href='#CustomerServiceSetting' :ripple='false') {{$t('SETTING.CUSTOMERSERVICESETTING')}}
                v-tab.px-0(v-hide-element v-if='canManageStageType && !isWiadvance()' href='#knowledgeArticleSubjectSetting' :ripple='false') {{$t('SETTING.KNOWLEDGEARTICLESUBJECTSETTING')}}
                v-tab.px-0(v-hide-element v-if='canManageStageType && !isWiadvance()' href='#AutoNumberSetting' :ripple='false') {{$t('SETTING.AUTONUMBERSETTING')}}
                v-tab.px-0(v-if='canManageStageType' href='#ProductSetting' :ripple='false') {{$t('SETTING.PRODUCTSETTING')}}

                //- h5(v-if='isAdmin && canSetOpenAI') {{$t('SETTING.UPGPTSETTING')}}
                //- v-tab.px-0(v-if='isAdmin && canSetOpenAI' href='#AssistantSettingAndUpload' :ripple='false') {{$t('SETTING.ASSISTANTSETTINGANDUPLOAD')}}
                //- 至MVP #0.2再打開
                //- v-tab.px-0(v-if='isAdmin' href='' :ripple='false') {{$t('SETTING.GPTTOOLS')}}

                h5(v-hide-element v-if='isAdmin') {{$t('SETTING.UPLOADSTATUS')}}
                v-tab.px-0(v-hide-element v-if='isAdmin' href='#UploadStatus' :ripple='false') {{$t('SETTING.UPLOADSTATUS')}}

        v-col.pl-md-0.pr-12(cols='12' md='9')
            v-tabs-items(v-model='tab')
                v-tab-item(value='PersonalSetting' transition='none')
                    personalSetting
                v-tab-item(value='PasswordAndLogin' transition='none')
                    passwordAndLogin
                v-tab-item(value='CalendarSync' transition='none')
                    calendarSync
                v-tab-item(value='EmailSync' transition='none')
                    emailSync
                v-tab-item(value='EmailTemplate' transition='none')
                    emailTemplate
                v-tab-item(value='smsTemplate' transition='none')
                    smsTemplate
                v-tab-item(value="FilterSetting" transition="none")
                    filterSetting
                v-tab-item(v-if='canManageCompanySettings' value='CompanySettings' transition='none')
                    companySetting
                v-tab-item(v-if='canManageUsers' value='ManageUsers' transition='none')
                    manageUsers()
                v-tab-item(v-if='isAdmin' value='ManageGroups' transition='none')
                    manageGroups()
                v-tab-item(v-if='canManageCustomFields' value="CustomFields", transition="none")
                    customField
                v-tab-item(v-if='canManageCustomFields' value="TreeSetting", transition="none")
                    treeSetting
                v-tab-item(v-if='canManageStageType' value="TableSetting" transition="none")
                    tableSetting
                v-tab-item(v-if='canManageStageType' value='PipelineStageSetting' transition='none')
                    settingProcess
                v-tab-item(v-if='canManageStageType' value='CustomerServiceSetting' transition='none')
                    customerService
                v-tab-item(v-if='canManageStageType' value='knowledgeArticleSubjectSetting' transition='none')
                    knowledgeArticleSubject
                v-tab-item(v-if='canManageStageType' value='AutoNumberSetting' transition='none')
                    autoNumber
                v-tab-item(v-if='canManageStageType' value='ProductSetting' transition='none')
                    product
                v-tab-item(v-if='canManageStageType' value='NotificationSetting' transition='none')
                    notification
                v-tab-item(v-if='canManageStageType' value='ActivityTypes' transition='none')
                    activityTypes
                v-tab-item(v-if='isAdmin && canSetSmsVendor' value='smsVendor' transition='none')
                    smsVendor
                v-tab-item(v-if='isAdmin' value='AssistantSettingAndUpload' transition='none')
                    assistantsettingandupload(@emitChangeTab='onEmitchangeTab')
                v-tab-item(v-if='isAdmin' value='UploadStatus' transition='none')
                    uploadStatus
                v-tab-item(v-if='canManageCustomFields' value='CustomOpptyClosedReason' transition='none')
                    customopptyclosedreason
                
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='mangeUserAddDialog' max-width='500' content-class='setting-dialog')
        v-card
            v-card-title.d-flex.justify-center.mb-4 Add User
            v-card-text
                v-form(v-model='addUser.valid' ref='form')
                    v-row(no-gutters)
                        v-col.pr-2(cols='6')
                            v-text-field(v-model='addUser.firstName' label='First Name' solo)
                        v-col.pl-2(cols='6')
                            v-text-field(v-model='addUser.lastName' label='First Name' solo)
                        v-col.mt-5(cols='12')
                            v-text-field(v-model='addUser.role' label='Role' solo)
                        v-col.mt-5(cols='12')
                            v-text-field(v-model='addUser.mail' type='mail' label='Email' solo)
                v-card-actions.mt-8.px-0.py-0
                    v-row(no-gutters)
                        v-col.pr-2(cols='6')
                            v-btn(:ripple='false' @click='mangeUserAddDialog = false' width='100%' height='40' color='cancel' depressed dark) 取消
                        v-col.pl-2(cols='6')
                            v-btn(:ripple='false' @click='onUserAdd' width='100%' height='40' color='success' depressed dark) 新增
    v-dialog(v-model='mangeUserEditDialog' max-width='500' content-class='setting-dialog')
        v-card
            v-card-title.d-flex.justify-center.mb-4 Edit User
            v-card-text
                v-form(v-model='editUser.valid' ref='form')
                    v-row(no-gutters)
                        v-col.pr-2(cols='6')
                            v-text-field(v-model='editUser.firstName' label='First Name' solo)
                        v-col.pl-2(cols='6')
                            v-text-field(v-model='editUser.lastName' label='First Name' solo)
                        v-col.pl-2(cols='6')
                            v-text-field(v-model='editUser.role' label='Role' solo)
                        v-col.mt-5(cols='12')
                            v-text-field(v-model='editUser.mail' type='mail' label='Email' solo)
                v-card-actions.mt-8.px-0.py-0
                    v-row(no-gutters)
                        v-col.pr-2(cols='6')
                            v-btn(:ripple='false' @click='mangeUserEditDialog = false' width='100%' height='40' color='cancel' depressed dark) 取消
                        v-col.pl-2(cols='6')
                            v-btn(:ripple='false' @click='onUserEdit' width='100%' height='40' color='success' depressed dark) 新增
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitErrorDialog(true)')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
// import _ from 'lodash';
import PreferenceService from "@/services/PreferenceService";
import messageDialog from '@/components/Dialog/messageDialog';
import draggable from 'vuedraggable';
import { VueEditor } from "vue2-editor";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import loadingOverlay from "@/components/Common/loadingOverlay";
import emailSync from "@/components/Setting/emailSync";
import emailTemplate from "@/components/Setting/emailTemplate";
import smsTemplate from "@/components/Setting/smsTemplate";
import passwordAndLogin from "@/components/Setting/passwordAndLogin";
import customField from "@/components/Setting/customField";
import tableSetting from "@/components/Setting/tableSetting";
import filterSetting from "@/components/Setting/filterSetting";
import personalSetting from "@/components/Setting/personalSetting";
import settingProcess from "@/components/Setting/settingProcess";
import manageUsers from "@/components/Setting/manageUsers";
import manageGroups from "@/components/Setting/manageGroups";
import companySetting from "@/components/Setting/companySetting";
import PermissionService from "@/services/PermissionService";
import customerService from "@/components/Setting/customerService";
import activityTypes from "@/components/Setting/activityTypes";
import smsVendor from "@/components/Setting/smsVendorSetting";
import knowledgeArticleSubject from "@/components/Setting/knowledgeArticleSubject";
import autoNumber from "@/components/Setting/autoNumberSetting";
import product from "@/components/Setting/productSetting";
import notification from "@/components/Setting/notificationSetting";
import assistantsettingandupload from "@/components/Setting/assistantsettingandupload";
import uploadStatus from "@/components/Setting/uploadStatus";
import i18n from '/common/plugins/vue-i18n.js';
import treeSetting from "@/components/Setting/treeSetting";
import calendarSync from "@/components/Setting/calendarSync";
import customopptyclosedreason from "@/components/Setting/customOpptyClosedReason";

export default {
    name: 'Setting',
    components: {
        manageUsers,
        manageGroups,
        personalSetting,
        customField,
        tableSetting,
        filterSetting,
        messageDialog,
        loadingOverlay,
        draggable,
        VueEditor,
        errorDialog,
        successDialog,
        emailSync,
        emailTemplate,
        smsTemplate,
        passwordAndLogin,
        settingProcess,
        companySetting,
        customerService,
        knowledgeArticleSubject,
        autoNumber,
        product,
        notification,
        activityTypes,
        smsVendor,
        assistantsettingandupload,
        uploadStatus,
        treeSetting,
        calendarSync,
        customopptyclosedreason,
    },
    data() {
        return {		
            permissions: [],	
            selected: [],
            allowDrag:true,
            loading: false,
            isdesktop: true,
            tab: this.getCurrentTab(),
            languageList: ['繁體中文','English'],
            currencyList: ['TWD(新台幣)','USD(美元)'],
            // table setting
            page: 1,
            pageCount: 1,
            itemPerPage: 12,
            mangeUserHeaders: [
                {  text: 'User', value: 'user', align: 'center', width: 300 },
                {  text: 'Role', value: 'role', align: 'center' },
                {  text: 'Last Login', value: 'lastLogin', align: 'center' },
                {  text: 'active', value: 'active', align: 'center', width: 120 },
            ],
            searchMangeUser: '',
            mangeUserAddDialog: false,
            addUser : {
                valid: true,
                firstName: '',
                lastName: '',
                role: '',
                mail: '',
            },
            mangeUserEditDialog: false,
            editUser : {
                valid: true,
                firstName: '',
                lastName: '',
                role: '',
                mail: '',
            },
            fieldTypes: [ 'Text','Long Text','Number','Date','Switch','Select','Radio','Checkbox'],
            // Dialog
            messageDialog: false,
            message: '',
            successDialog: false,
            errorDialog: false,
            errorMessage: '',
            o365AccountData: [],
            isAdmin: false,			
            canManageStageType: false,
            canManageUsers: false,
            canManageCompanySettings: false,
            canManageCustomFields: false,
            canSetSmsVendor:false,
            canSetOpenAI:false,
        }
    },
    created() {
        PermissionService.getUserPermissions()
        .then(response => {
            this.permissions = response.data;
            let isAdmin = this.permissions.filter(p => p.includes('admin')).length > 0;
            this.isAdmin = isAdmin;
            let matches = this.permissions.filter(p => p.includes('manage_stagetypes'));
            this.canManageStageType = matches.length > 0 || isAdmin;
            matches = this.permissions.filter(p => p.includes('manage_users'));
            this.canManageUsers = matches.length > 0 || isAdmin;
            matches = this.permissions.filter(p => p.includes('manage_companysettings'));
            this.canManageCompanySettings = matches.length > 0 || isAdmin;
            matches = this.permissions.filter(p => p.includes('manage_customfields'));
            this.canManageCustomFields = matches.length > 0 || isAdmin;
        })
        .then(()=>{
            if(this.$route.fullPath == "/Setting#UploadStatus")
            {
                this.tab = "UploadStatus";
            }
        });
        PreferenceService.getSmsVendor()
        .then(response => 
        {
            if(response.data != ""){
                this.canSetSmsVendor = true;
            }
        })
        .catch((response) => {
                this.showErrorDialog(response);
        });
        PreferenceService.getOpenAI()
        .then(response => {
            if(response.data != "" && response.data['core'] != undefined){
                this.canSetOpenAI = true;
            }
        })
        .catch((response) => {
                this.showErrorDialog(response);
        });
    },
    methods: {		
        onResize: function () {
            if (window.innerWidth < 992) {
                this.isdesktop = false;
            }
        },
        onMail: function (mail) {
            return 'mailto:'+mail;
        },
        // Message Dialog
        onDelete () {
            this.showMessageDialog('您確定要刪除嗎？');
        },
        showMessageDialog(message) {
            this.messageDialog = true;
            this.message = message;
        },
        onEmitMessageDialog(val) {
            this.messageDialog = false;
            if (val) { this.showSuccessDialog(); }
        },
        showAddUserDialog() {
            this.mangeUserAddDialog = true;
        },
        onUserAdd() {
            this.mangeUserAddDialog = false;
        },
        showEditUserDialog(data) {
            this.mangeUserEditDialog = true;
            this.editUser.firstName = data.firstName;
            this.editUser.lastName = data.lastName;
            this.editUser.mail = data.mail;
        },
        onUserEdit() {
            this.mangeUserEditDialog = false;
        },
        onEmitSuccessDialog() {
            this.successDialog = false;
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
        getCurrentTab() {
            let currentTab = this.$route.query.confirmO365Setup == 'Y' ? "EmailSync" : null;
            if(this.$route.query.confirmO365CalendarSetup == 'Y')
            {
                currentTab = "CalendarSync"
            }
            if(this.$route.fullPath == "/Setting#PasswordAndLogin")
            {				
                currentTab = "PasswordAndLogin";
            }
            if(this.$route.fullPath == "/Setting#EmailSync")
            {				
                currentTab = "EmailSync";
            }
            return currentTab;
        },
        onCloneCallback(item) {
            // Create a fresh copy of item
            const cloneMe = JSON.parse(JSON.stringify(item));

            return cloneMe;
        },
        onMoveCallback(evt) {
            const item = evt.draggedContext.element;
            // const itemIdx = evt.draggedContext.futureIndex;

            console.log("onMoveCallback");

            if (item.locked) {
                return false;
            }

            return true;
        },
        onDropCallback() {
            console.log("onDropCallback");
        },
        onEmitchangeTab(val){
            this.tab = val;
        }
    },
    watch:{
        "tab":function(){ 
            const name = this.tab.toUpperCase()
            document.title = i18n.t('SETTING.'+name) +' – UpDay';
        }
    }
}
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.customFieldSelect {
      max-width: 220px;
      max-height: 60px;
    }

</style>
<template lang="pug">
v-card(:class='contactData.length >= 8 ? "dialog-height-small" : ""')
  v-col.d-flex.align-center.pa-5.pb-0
    v-row(no-gutters)
      v-col(cols="8")
        .d-flex.align-center.height-62
          h2.my-0(style="white-space:nowrap;") {{$t('RELATEDCONTACTS.TITLE')}}
          v-btn.ml-3.add-deal-btn(v-if="isDetailReadonly" @click="showAddContact" width="34" height="34" min-width="34" :ripple="false" color="success" depressed dark fab)
            v-icon(size="16") icon-add
          v-form(v-model="valid", ref="form")
            v-combobox.ml-3.add-deal-btn-contact(v-if="!isDetailReadonly && !isNewContact" v-model="contact" :items="addContactList" :menu-props="{openOnClick: true}" :no-data-text=`$t('RELATEDCONTACTS.NO_CONTACT')` item-text="searchStr" item-value="id" :rules="nameRules" required :loading='combo_loading' :label=`$t('RELATEDCONTACTS.ADD_CONTACT')` :search-input.sync='search' @blur='clearData()')
                template(v-slot:prepend-item)
                    v-list-item
                        v-btn(ref="newbtn" width="120" height="32" color="blue" plain border-0 @click="isNewContact = true")
                            v-icon.mr-1(size="14") icon-add
                            span {{$t('RELATEDCONTACTS.NEW_CONTACT')}}
                template(v-slot:item='data')
                    v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                        v-icon(v-if="data.item.image == null") mdi-account
                        img(v-else :src='data.item.image')
                    .t-black {{ data.item.full_name }} 
                    .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                template(v-slot:selection='data')
                    .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
            v-row(no-gutters="no-gutters")        
             v-col(cols="3")
              v-text-field.ml-3(v-if="!isDetailReadonly && isNewContact" v-model="newContact.last_name" :label=`$t('RELATEDCONTACTS.LAST_NAME')`, validate-on-blur required="required" :rules="nameRules" large="large")
             v-col(cols="3")
              v-text-field.ml-3(v-if="!isDetailReadonly && isNewContact" v-model="newContact.first_name" :label=`$t('RELATEDCONTACTS.FIRST_NAME')`, large="large" :rules='contentLenRule50')
             v-col(cols="4").align-self-center
              v-btn.mr-3.v-btn-save(v-if="!isDetailReadonly && isNewContact" width="70" text color="success" @click="confirmAddContact()")
                v-icon(size="20") icon-check
                span {{$t('GENERAL.SAVE')}}    
              v-btn.mr-3.v-btn-save(v-if="!isDetailReadonly && isNewContact" width="70" text color="gary" @click="cancelAddContact()")
                v-icon(size="20") icon-cancel
                span {{$t('GENERAL.CANCEL')}}    
          v-btn.ml-3.v-btn-save( v-if="!isDetailReadonly && !isNewContact" width="70" text color="gary" @click="cancelAddContact()")
            v-icon(size="20") icon-cancel
            span {{$t('GENERAL.CANCEL')}}   
          v-btn.ml-3(v-if="!isDetailReadonly && !isNewContact" width="70" text color="success" @click="confirmAddContact()")
            v-icon(size="20") icon-check
            span {{$t('GENERAL.SAVE')}}    
      v-col.text-right(cols="3").d-flex.align-center
        .btn-group.ml-auto.mr-2
          v-text-field(v-model="searchMainContact" flat dense solo prepend-inner-icon="icon-search" hide-details)
      v-col.text-right(cols="1").d-flex.align-center
        v-btn.ml-auto(v-hide-element='false' :ripple="false" @click="onCancel" depressed="depressed" icon)
          v-icon icon-cancel
  v-card-text.pt-4.px-5.pb-5
    .data-area
      v-data-table.border-table( :headers="mainContactHeader" :items="contactData" :items-per-page="mainContactItemPerPage" :page.sync="mainContactPage" :search="searchMainContact" multi-sort :header-props="{sortIcon: 'mdi mdi-arrow-up'}" @page-count="mainContactPageCount = $event" hide-default-footer="hide-default-footer")
        template(v-slot:item.full_name="{ item }")
            .ellipsis
                a(:href="'/Contacts/' + item.id") {{ item.full_name }}
        template(v-slot:item.mobile_phone="{ item }")
            v-btn.w-100.d-block(v-if='item.mobile_phone!= null' :href='onCall(item.mobile_phone)' text color="primary" :title="item.mobile_phone") 
                .ellipsis.pt-3.w-100  
                    span.w-100  {{ item.mobile_phone }}
        template(v-slot:item.email="{ item }")
            v-btn.w-100.d-block(v-if='item.email!= null' :href="onMail(item.email)" text color="primary" :title="item.email") 
                .ellipsis.pt-3.w-100  
                    span.w-100  {{ item.email }}
        template(v-slot:item.accountName="{ item }")
            .ellipsis
                a.t-black(:href="'/Account/' + item.accountId") {{ item.accountName }}
        template(v-slot:item.active="{ item }")
          v-btn(icon="icon", @click="onDelete(item.id)")
            v-icon(size="18") icon-remove
      v-pagination.mt-3(
        v-model="mainContactPage",
        :length="mainContactPageCount",
        circle="circle"
        :total-visible="10"
      )
  v-dialog(v-model="successDialog" width="360" content-class="statusDialog")
    success-dialog(@emitSuccessDialog="onEmitSuccessDialog")
  v-dialog(v-model="messageDialog" width="360" content-class="statusDialog")
    message-dialog(@emitMessageDialog="onEmitMessageDialog" :message="message")
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog()')
    error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import addContact from "@/components/Dialog/Contact/addContact";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import OpportunityDataService from "@/services/OpportunityDataService";
import CaseDataService from "@/services/CaseDataService";
import ContactDataService from '@/services/ContactDataService';
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  props: {
    contactData: {
      type: Array,
      required: true,
    },
    objectType: {
      type: String,
      require: true
    }
  },
  components: {
    addContact,
    successDialog,
    messageDialog,
    errorDialog,
  },
  created() {
    this.contactData.forEach((data) => {
      if(data.first_name != null)
        data.full_name = data.last_name +" "+ data.first_name;
      else
        data.full_name = data.last_name;
        
      if (data.organization_set.length == 0) {
        data.accountName = "";
        data.accountId = ""
      } else {
        data.accountName = data.organization_set[0].name;
        data.accountId = data.organization_set[0].id;
      }
    });    
  },
  data() {
    return {
      valid: true,
      nameRules: [
          (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
        ],
      contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
      searchMainContact: "",
      mainContactHeader: [
        {
          text: i18n.t('RELATEDCONTACTS.CONTACT_NAME'),
          value: "full_name",
          align: "center",
          filterable: true,
          width: '20%',
        },
        {
          text: i18n.t('RELATEDCONTACTS.PHONE'),
          value: "mobile_phone",
          align: "center",
          filterable: false,
          width: '20%',
        },
        {
          text: i18n.t('RELATEDCONTACTS.MAIL'),
          value: "email",
          align: "center",
          filterable: false,
          width: '20%',
        },
        {
          text: i18n.t('RELATEDCONTACTS.ORG_NAME'),
          value: "accountName",
          align: "center",
          filterable: true,
          width: '20%',
        },
        { 
          text: i18n.t('RELATEDCONTACTS.ACTIVE'), 
          value: "active", 
          align: "center", 
          sortable: false,
          width: '20%', 
        },
      ],
      mainContactPage: 1,
      mainContactPageCount: 0,
      mainContactItemPerPage: 8,
      addContactDialog: false,
      successDialog: false,
      messageDialog: false,
      errorDialog: false,
      message: "",
      isNewContact: false,
      isDetailReadonly: true,
      addContactList: [],
      addContactData: null,
      newContact: {},
      search: '',
      errorMessage: '',
      searchText: '',
      contact: null,
      combo_loading: false,
    };
  },
  methods: {
    validateForm() {
        if (this.$refs.form.validate()){
            if(this.isNewContact){
                return true;
            }
            else{
                if(typeof(this.contact) == 'object')
                    return true;
                else
                    return false;
            }
        } 
        else {
            return false;
        }
    },
    onCancel() {
      if (this.contact != undefined) {
        this.contact = null;
      }
      this.$emit("emitRelatedContactsDialog", false);      
    },
    // Common Function
    onCall: function (cell) {
      return "tel:" + cell;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    showSussessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showAddContact() {
        this.isDetailReadonly = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if (val) {
        if (this.deleteContactId != false) {
          let target_id = this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id')
          if (this.objectType == "Opportunity") {
            OpportunityDataService.delOpptyContacts(
              target_id,
              this.deleteContactId
            )
            .then((response) => {
              if(response.data.isPrimary_contact != undefined){
                this.$emit("MainContact", "success_is_primary_contact");
              }
              this.$emit("MainContact", "success");
            });
          } else if (this.objectType == "Case") {
            CaseDataService.deleterelatedcontact(target_id, this.deleteContactId)
            .then(() => {
              this.$emit("MainContact", "success");
            });
          }
        }
        this.successDialog = true;
      }
    },
    onDelete(id) {
      this.showMessageDialog(i18n.t('RELATEDCONTACTS.MESSAGE'));
      this.deleteContactId = id;      
    },
    cancelAddContact() {
      this.isNewContact = false;
      this.isDetailReadonly = true;
      this.contact = null;
      this.newContact.last_name = null;
      this.newContact.first_name = null;
    },
    clearData(){
        this.addContactList = [];
    },
    changePostData() {
        if (this.contact != null && this.isNewContact == false) {
            if (typeof(this.contact) == 'string') {
                
                this.errorDialog = true;
                this.errorMessage = i18n.t('RELATEDCONTACTS.ERROR');
                return false;
            }
            else if (typeof(this.contact) == 'object')  {
                return true;
            } 
        } 
        else if(this.contact == null && this.isNewContact == true){
            return true
        }
        else if(this.contact == null && this.isNewContact == false){
            return false;
        }
    },
    confirmAddContact() {
        
        if(this.validateForm() && this.changePostData()){
            let newdata = {};
            if (this.isNewContact == true) {
                newdata = {
                    'contact': {
                        'id': "0",
                        'first_name': this.newContact.first_name,
                        'last_name': this.newContact.last_name
                    }
                }
            } 
            else {
                newdata = {
                    'contact':{
                        'id': this.contact.id,
                        'first_name': this.contact.first_name,
                        'last_name': this.contact.last_name
                    }
                }
            }
            let target_id = this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id');
            if (this.objectType == "Opportunity") {
                OpportunityDataService.addOpptyContacts(target_id, newdata)
                .then(() => {
                    this.$emit("MainContact", "success");
                });
            } 
            else if (this.objectType == "Case") {
                CaseDataService.addrelatedcontacts(target_id, newdata)
                .then(() => {
                    this.$emit("MainContact", "success");
                });
            }
            this.successDialog = true;
            this.isDetailReadonly = true;
            this.isNewContact = false;
            this.contact = null;
            this.newContact.last_name = null;
            this.newContact.first_name = null;
            this.addContactList = [];
        }
        else{
            this.addContactList = [];
            this.contact = null;
        }
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
  },
  watch: {
    search(val) {
        this.searchText = val;

        // check user input is en / zh / num
        let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
        let isEnZhNum = false;
        pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

        // check user input data type
        let isString; 
        typeof(val) == 'string' ? isString = true : isString = false;
        let array = [];

        // search
        if(isEnZhNum && !!val && isString){
            this.combo_loading = true;
            ContactDataService.getAll(1,val,100,[])
            .then(response => {
                response.data.list.forEach(item => {
                    item.searchStr = item.full_name 
                                    +item.full_name_en
                                    +item.full_name_en.toLowerCase()
                                    +item.full_name_en.toUpperCase()
                                    +item.full_name_zh;
                    if(item.searchStr.includes(val)){
                        if(item.organization_set.length > 0)
                        {
                            item.org_name = "[ "+item.organization_set[0].name +" ]";
                        }
                        array.push(item);
                    }
                })
                this.addContactList = array;
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
            .finally(()=>{
                this.combo_loading = false;
            })
        }
        else{
            this.addContactList = [];
        }
    },
    contact(val){
        if(val == ''){
            this.contact = null;
        }
    }
  }
});
</script>
<style>
.add-deal-btn-contact {
    width: 200px !important;
    height: 50px !important;    
}
.v-btn-save {  
  float: right; 
}
</style>

<template lang="pug">
    v-card(:height="windowHeight-6" :max-height="windowHeight" outlined elevation='0').dealDialog
        v-card-title.d-flex.justify-center {{$t('BULK_EDIT.TITLE')}}
        v-card(:height="change_stage? processHeight:windowHeight-200" :max-height="change_stage? processHeight:windowHeight-200"  outlined elevation='0'  class='scrollbar')
            v-card-text
                v-form(v-model='addDealValid' ref='form')  
                    v-card-text(height="100" max-height="100" class='scrollbar')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_DEAL.NAME')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.name.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='name', @change="visibleChange('name')")
                            v-text-field.mb-4(v-if='editDealData.name.change == 1' v-model='editDealData.name.value' :label=`$t('ADD_DEAL.NAME')`, :rules='formRules.nameRule' prepend-icon='mdi-file-document' ,ref='name_val', large='large' validate-on-blur)
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.CONTACT')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.primary_contact.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='primary_contact', @change="visibleChange('primary_contact')")
                            v-combobox.no-border.height-48(v-if="!isPriNewContact && editDealData.primary_contact.change == 1" v-model="editDealData.primary_contact.value" :items="primaryContactList" :search-input.sync="primaryContactSearch" :menu-props="{openOnClick: true}",ref='primary_contact_val', :no-data-text=`$t('ADD_DEAL.NO_CONTACT')` @blur='primaryContactList = []' item-text="searchStr" item-value="id" hide-details="auto" prepend-icon='mdi-account-multiple' :label=`$t('ADD_DEAL.CONTACT')` :loading='contact_loading' :rules='formRules.contactRules')
                                template(v-slot:prepend-item)
                                    v-list-item
                                        v-btn(width="120" height="32" color="blue" plain border-0 @click="isPriNewContact = true")
                                            v-icon.mr-1(size="14") icon-add
                                            span {{$t('ADD_DEAL.NEW_CONTACT')}}
                                template(v-slot:item='data')
                                    v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                        v-icon(v-if="data.item.image == null") mdi-account
                                        img(v-else :src='data.item.image')
                                    .t-black {{ data.item.full_name }} 
                                    .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                template(v-slot:selection='data')
                                    .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                            v-row(no-gutters="no-gutters")
                                v-col(cols="6")
                                    v-text-field.ml-3(v-if="isPriNewContact && editDealData.primary_contact.change == 1" v-model="newPriContact.last_name" :label= `$t('ADD_CONTACT.LAST_NAME')` large="large" ,:rules='formRules.contentLenRule50Require')
                                v-col(cols="6")
                                    v-text-field.ml-3(v-if="isPriNewContact && editDealData.primary_contact.change == 1" v-model="newPriContact.first_name" :label=`$t('ADD_CONTACT.FIRST_NAME')` large="large" ,:rules='formRules.contentLenRule50')  
                                v-col(cols="6")
                                    v-btn.w-100.justify-start.px-0(v-if="isPriNewContact" text color="blue" plain border-0 @click="isPriNewContact = false")
                                        v-icon(size="20") mdi-keyboard-return 
                                        span.ml-4 {{$t('ADD_DEAL.OLD_CONTACT')}}
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.CLIENT_NAME')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.contact.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='contact', @change="visibleChange('contact')")
                            //- v-combobox.no-border.height-48(v-if="(!isNewContact && editDealData.contact.change == 1 && editDealData.oppty_org.value == '') || (editDealData.contact.change == 1 && search=='')" v-model="editDealData.contact.value" :items="contactList" :search-input.sync="contactSearch" :menu-props="{openOnClick: true}",ref='contact_val', :no-data-text=`$t('ADD_DEAL.NO_CONTACT')` @blur='contactList = []' item-text="searchStr" item-value="id" hide-details="auto" prepend-icon='mdi-account-multiple' :label=`$t('ADD_DEAL.CONTACT')` :loading='contact_loading' :rules='formRules.contactRules')
                            //-     template(v-slot:prepend-item)
                            //-         v-list-item
                            //-             v-btn(width="120" height="32" color="blue" plain border-0 @click="isNewContact = true")
                            //-                 v-icon.mr-1(size="14") icon-add
                            //-                 span {{$t('ADD_DEAL.NEW_CONTACT')}}
                            //-     template(v-slot:item='data')
                            //-         v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                            //-             v-icon(v-if="data.item.image == null") mdi-account
                            //-             img(v-else :src='data.item.image')
                            //-         .t-black {{ data.item.full_name }} 
                            //-         .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                            //-     template(v-slot:selection='data')
                            //-         .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                            //- v-row(no-gutters="no-gutters")
                            //-     v-col(cols="6")
                            //-         v-text-field.ml-3(v-if="isNewContact && editDealData.contact.change == 1 && editDealData.oppty_org.value == ''" v-model="newContact.last_name" :label= `$t('ADD_CONTACT.LAST_NAME')` large="large" ,:rules='formRules.contentLenRule50Require')
                            //-     v-col(cols="6")
                            //-         v-text-field.ml-3(v-if="isNewContact " v-model="newContact.first_name" :label=`$t('ADD_CONTACT.FIRST_NAME')` large="large" ,:rules='formRules.contentLenRule50')  
                            //-     v-col(cols="6")
                            //-         v-btn.w-100.justify-start.px-0(v-if="isNewContact && editDealData.contact.change == 1 && editDealData.oppty_org.value == ''" text color="blue" plain border-0 @click="isNewContact = false")
                            //-             v-icon(size="20") mdi-keyboard-return 
                            //-             span.ml-4 {{$t('ADD_DEAL.OLD_CONTACT')}}
                            v-combobox.no-border.height-48(v-model='editDealData.oppty_org.value' v-if='(editDealData.contact.change == 1 && editDealData.contact.value == "" && !isNewContact) || (!isNewContact && editDealData.contact.change == 1 && !editDealData.contact.value)' :items='organizationList' item-text='name' item-value="uqNumber" :loading="isLoading" :search-input.sync="search",ref='oppty_org_val', prepend-icon='mdi-domain' hide-details="auto" :label=`$t('ADD_DEAL.ORG')` :rules='formRules.companyRule')
                                template(v-slot:append-outer)
                                    div(v-if="isNew")
                                        v-icon(color="red", size="25") mdi-new-box
                                template(v-slot:item="data")
                                    div.ellipsis(v-if="data.item.id == 0")
                                        span {{ data.item.name }}
                                        v-icon(color="red", size="25") mdi-new-box
                                    div.ellipsis(v-else)
                                        span {{ data.item.name }}
                                        span.t-black.ml-2(v-if='data.item.customer_code') [ {{data.item.customer_code }} ]
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('LOSEREASON.CLOSE_REASON')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.closed_reason.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='closed_reason', @change="visibleChange('closed_reason')")
                            v-select.no-border.height-48(v-if='editDealData.closed_reason.change == 1'  v-model='editDealData.closed_reason.value' :label=`$t('LOSEREASON.CLOSE_REASON')`,  :items='loseReasonList' ,ref='closed_reason_val', prepend-icon='mdi-comment-check-outline' item-text='name' item-value='key' :menu-props='{closeOnClick:true}' :rules='formRules.forecast')
                            v-menu(v-if='editDealData.closed_reason.value && editDealData.closed_reason.change == 1' ref="menu" v-model="menu[1]" :z-index='10000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field.no-border.height-48(v-model='editDealData.closed_date.value'  :rules='formRules.dateRule'  v-bind="attrs" v-on="on" prepend-icon='mdi-calendar' readonly  hide-details="auto" :label= `$t('LOSEREASON.CLOSE_DATE')` )
                                v-date-picker(v-model='editDealData.closed_date.value' @input='menu[1] = false' no-title scrollable)
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_DEAL.AMOUNT')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.amount.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='amount', @change="visibleChange('amount')")
                            v-text-field.no-border.height-48(v-if='editDealData.amount.change == 1' v-model='editDealData.amount.value' prepend-icon='icon-chatmoney' :label=`$t('ADD_DEAL.AMOUNT')`, :rules='formRules.amountRule'  :ref="'amount_val'" v-show='amountShow|!amountValidate' @blur='showFormatAmount("amount")')
                            v-text-field.no-border.height-48(v-if='editDealData.amount.change == 1' v-model='formatAmount' prepend-icon='icon-chatmoney' :label=`$t('ADD_DEAL.AMOUNT')`,  @click='showEditAmount' @blur='showEditAmount("amount")' :readonly='true' v-show='!amountShow&&amountValidate')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_DEAL.EXPECTED_CLOSE_DATE')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.expected_close_date.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='expected_close_date', @change="visibleChange('expected_close_date')")
                            v-menu(v-if='editDealData.expected_close_date.change == 1' ref="menu" v-model="menu[0]" :z-index='10000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field.no-border.height-48(v-model='editDealData.expected_close_date.value'  v-bind="attrs" v-on="on" prepend-icon='mdi-calendar' readonly hide-details="auto" :label= `$t('ADD_DEAL.EXPECTED_CLOSE_DATE')` :rules='formRules.dateRule' ref='expected_close_date_val')
                                v-date-picker(v-model='editDealData.expected_close_date.value' @input='menu[0] = false' no-title scrollable) 
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CASE_DETAIL.RELATED_CAMPAIGN')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.primary_campaign_id.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='primary_campaign_id', @change="visibleChange('primary_campaign_id')")
                            v-autocomplete.no-border(v-if='editDealData.primary_campaign_id.change == 1' v-model='editDealData.primary_campaign_id.value'  :items='campaignList' item-text='name' item-value='id' ,ref='primary_campaign_id_val', :menu-props='{closeOnClick:true}' :label=`$t('CASE_DETAIL.RELATED_CAMPAIGN')` prepend-icon='mdi-tag' ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('GENERAL.CHANGE_OWNER')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.owner.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='owner', @change="visibleChange('owner')")
                            v-autocomplete.no-border.height-48(v-if='editDealData.owner.change == 1' v-model='editDealData.owner.value' :items='ownerList' item-text='fullName' item-value='id' ,ref='owner_val', :label=`$t('GENERAL.CHANGE_OWNER')` return-object rounded  prepend-icon='mdi mdi-account' ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_DEAL.FORCAST')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.forecast_category.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='forecast_category', @change="visibleChange('forecast_category')")
                            v-select#forecast_category.no-border.height-48(v-if='editDealData.forecast_category.change == 1' v-model='editDealData.forecast_category.value'  :items='forecast_category_List' prepend-icon='mdi-crystal-ball' item-text='name' ,item-value='key',ref='forecast_category_val', :label=`$t('ADD_DEAL.FORCAST')`,:rules='formRules.forecast' )
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('TO_DEAL.BUDGET')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.budget_confirmed.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='budget_confirmed', @change="visibleChange('budget_confirmed')")
                            v-checkbox.no-border.textarea-style(v-if='editDealData.budget_confirmed.change == 1' v-model='editDealData.budget_confirmed.value' , :label=`$t('DEAL_DETAIL.CLIENT_BUDGET')`, no-resize )
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.CLIENT_BUDGET')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.customer_budget.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='customer_budget', @change="visibleChange('customer_budget')")
                            v-text-field.no-border.height-48(v-if='editDealData.customer_budget.change == 1' v-model='editDealData.customer_budget.value' prepend-icon='icon-chatmoney' :label=`$t('DEAL_DETAIL.CLIENT_BUDGET')`, :rules='formRules.amountRule'  :ref="'customer_budget_val'" v-show='budgetShow|!budgetValidate' @blur='showFormatAmount("budget")')
                            v-text-field.no-border.height-48(v-if='editDealData.customer_budget.change == 1' v-model='formatBudget' prepend-icon='icon-chatmoney' :label=`$t('DEAL_DETAIL.CLIENT_BUDGET')`,  @click='showEditAmount("budget")' @blur='showEditAmount("budget")' :readonly='true' v-show='!budgetShow&&budgetValidate')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.CUSTOMER_NEEDS')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.customer_needs.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='customer_needs', @change="visibleChange('customer_needs')")
                            v-textarea.no-border.textarea-style(v-if='editDealData.customer_needs.change == 1' v-model='editDealData.customer_needs.value', :label=`$t('DEAL_DETAIL.CUSTOMER_NEEDS')`,   no-resize ,ref='customer_needs_val' ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.PURCHASE_NEEDS_OR_PROCESS')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.purchase_needs_or_process.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='purchase_needs_or_process', @change="visibleChange('purchase_needs_or_process')")
                            v-textarea.no-border.textarea-style(v-if='editDealData.purchase_needs_or_process.change == 1' v-model='editDealData.purchase_needs_or_process.value', :label=`$t('DEAL_DETAIL.CUSTOMER_NEEDS')`,   no-resize ,ref='purchase_needs_or_process_val' ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.EXPECT_OR_NEXTSTEPS_OR_OTHERS')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.expect_or_nextsteps_or_others.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='expect_or_nextsteps_or_others', @change="visibleChange('expect_or_nextsteps_or_others')")
                            v-textarea.no-border.textarea-style(v-if='editDealData.expect_or_nextsteps_or_others.change == 1' v-model='editDealData.expect_or_nextsteps_or_others.value', :label=`$t('DEAL_DETAIL.CUSTOMER_NEEDS')`,   no-resize ,ref='expect_or_nextsteps_or_others_val' ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.SALES_NOTES')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.sales_notes.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='sales_notes', @change="visibleChange('sales_notes')")
                            v-textarea.no-border.textarea-style(v-if='editDealData.sales_notes.change == 1' v-model='editDealData.sales_notes.value', :label=`$t('DEAL_DETAIL.CUSTOMER_NEEDS')`,   no-resize ,ref='sales_notes_val' ,:rules='formRules.fieldRules')
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.OPPTY_STATUS')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.oppty_status.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='oppty_status', @change="visibleChange('oppty_status')")
                            v-select#forecast_category.no-border.height-48(v-if='editDealData.oppty_status.change == 1' v-model='editDealData.oppty_status.value'  :items='opptyStatusList' prepend-icon='mdi-tag' item-text='name' ,item-value='key',ref='oppty_status_val', :label=`$t('DEAL_DETAIL.OPPTY_STATUS')`,:rules='formRules.forecast' )
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.INDUSTRY')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.industry.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='industry', @change="visibleChange('industry')")
                            v-select#forecast_category.no-border.height-48(v-if='editDealData.industry.change == 1' v-model='editDealData.industry.value'  :items='opptyIndustryList' prepend-icon='mdi-tag' item-text='name' ,item-value='key',ref='industry_val', :label=`$t('DEAL_DETAIL.INDUSTRY')`,:rules='formRules.forecast' )
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.CUSTOMER_SOURCE')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.customer_source.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='customer_source', @change="visibleChange('customer_source')")
                            v-select#forecast_category.no-border.height-48(v-if='editDealData.customer_source.change == 1' v-model='editDealData.customer_source.value'  :items='customerSourceList' prepend-icon='mdi-tag' item-text='name' ,item-value='key',ref='customer_source_val', :label=`$t('DEAL_DETAIL.CUSTOMER_SOURCE')`,:rules='formRules.forecast' )
                        //- label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.PROGRESS_2')}}
                        //- div.bulk-edit-square(outline)
                        //-     v-select.select-text-size.no-border.no-padding(v-model='editDealData.progress.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='progress', @change="visibleChange('progress')")
                        //-     v-select#forecast_category.no-border.height-48(v-if='editDealData.progress.change == 1' v-model='editDealData.progress.value'  :items='opptyProgressList' prepend-icon='mdi-tag' item-text='name' ,item-value='key',ref='progress_val', :label=`$t('DEAL_DETAIL.PROGRESS_2')`,:rules='formRules.forecast' )
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.OPPTY_TYPE')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.oppty_type.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='oppty_type', @change="visibleChange('oppty_type')")
                            v-select#forecast_category.no-border.height-48(v-if='editDealData.oppty_type.change == 1' v-model='editDealData.oppty_type.value'  :items='opptyTypeList' prepend-icon='mdi-tag' item-text='name' ,item-value='key',ref='forecast_category_val', :label=`$t('DEAL_DETAIL.OPPTY_TYPE')`,:rules='formRules.forecast' )
                        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('DEAL_DETAIL.OTHER_CUSTOMER_SOURCE')}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='editDealData.other_customer_source.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='other_customer_source', @change="visibleChange('other_customer_source')")
                            v-text-field.no-border.height-48(:rules="formRules.contentLenRule100Require", prepend-icon='mdi-map',v-if='editDealData.other_customer_source.change == 1' v-model='editDealData.other_customer_source.value' :label=`$t('DEAL_DETAIL.OTHER_CUSTOMER_SOURCE')`, ,ref='other_customer_source_val',)

                        v-window
                            customField(v-if='!isLoading && isProcess' ref='CFRequired' DataClass = 'Opportunity'  :StageTypeId='passId' :stageList='stageList')
        
        v-card-text
            v-checkbox.py-0.ml-auto(v-model='change_stage' dense='dense' :hide-details='true' :label= `$t('BULK_EDIT.CHANGE_STAGE')`)
            v-combobox.no-border.my-0.py-0.mt-2(v-if='change_stage' :label=`$t('ADD_DEAL.PROCESS')` v-model='editDealData.stage_type.value' :items='stageList' item-text='stage_type_name' @change='selectStageType()' prepend-icon='mdi-sync' hide-details="auto" flat required='required' validate-on-blur :rules='formRules.stageTypeRule')
            div(style='height: 40px;' v-if='change_stage')
                .my-2.d-flex.justify-center.align-center.w-100.h-100(v-if='!isProcess && editDealData.stage_type.value != ""')
                    v-progress-circular(indeterminate size='20')
                v-stepper.process-box.my-2(v-if='isProcess && process.length > 0' v-model='currentStage')
                    v-stepper-header.process-bar
                        template(v-for='item in process')
                            v-stepper-step(:key='`${item.sequence_number}`' :complete='currentStage>item.sequence_number' :step='item.sequence_number' :class='`customcolor`' :style='getcolor(item.stage_color)' edit-icon='icon-check' editable)
                                span {{ item.stage_name }}
                        v-btn(v-if='lenProcess > 3 && process[0].sequence_number != 1' absolute style="marginTop: 4px; zIndex:1000;" class="mx-1" fab dark x-small color="white" @click='preStage()')
                            v-icon(color="primary") icon-arrow-left
                        v-btn(v-if='lenProcess > 3 && process[2].sequence_number != lenProcess' absolute style="marginLeft: 93.5%; marginTop: 4px; zIndex:1000;"  fab dark x-small color="white" @click='nextStage()')
                            v-icon(color="primary") icon-arrow-right
                v-stepper.process-box.my-2(v-if='isProcess && process.length == 0 || editDealData.stage_type.value == ""')
                    v-stepper-header.process-bar.lightGrey.rounded-pill.d-flex.justify-center.align-center
                        span(style='color: rgba(0, 0, 0, 0.38);') {{$('ADD_DEAL.DEAL_PROCESS')}}
        v-card-actions.px-2.py-4
            v-row
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='primary' depressed :disabled="editBtn_loading || contact_loading || isLoading || !isProcess")
                        v-progress-circular(v-if='editBtn_loading' indeterminate size='20')
                        .t-white(v-else) {{$t('GENERAL.CONFIRM')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' persistent)
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
</template>

<script>
    import Vue from 'vue';
    import ContactDataService from "@/services/ContactDataService";
    import OrganizationDataService from "@/services/OrganizationDataService";
    import wiadvanceProgressCheck from "@/views-wiadvance/wiadvanceProgressCheck";
    import StageDataService from "@/services/StageDataService";
    import CampaignDataService from "@/services/CampaignDataService";
    import forecast_category_List from "@/array/the_forecastCategoryList";
    import bulkEditList from "@/array/the_bulkEditList";
    import errorDialog from "@/components/Dialog/errorDialog";
    import i18n from '/common/plugins/vue-i18n.js';
    import get_oppty_closed_reason_func from "@/array/the_closeReasonList";
    import messageDialog from '@/components/Dialog/messageDialog';
    import customField from "@/components/Dialog/BulkEdit/customFieldBulkEdit";
    import opptySelectFieldsList from "@/array/the_opptySelectFieldsList";

    export default Vue.extend({
        props: {
            editItems: {
                type: Array,
                required: true,
            },
            stageTypeId: {
                type: Object,
                required: true,
            },
        },
        components: {
            errorDialog,
            customField,
            messageDialog,
        },
        async created(){
            await this.getOpptyClosedReason();
            let temp_arr = []
            for(let index in this.loseReasonList){
                if (this.loseReasonList[index].selected)
                    temp_arr.push(this.loseReasonList[index])
            }
            this.loseReasonList = temp_arr
            await StageDataService.stageList("deal")
                .then(response => {
                    this.stageList = response.data;
                    this.currentStage = 1;
                })
                .finally(() => (this.isLoading = false));
            await CampaignDataService.selectlist().then((response)=>{
                this.campaignList = response.data;
                })
            OrganizationDataService.getchangeowner('opportunities')
            .then((respose)=>{
                this.ownerList = respose.data;
                this.ownerList.forEach(item => {
                    item.fullName = item.last_name + ' ' + item.first_name
                });
            });
            if (this.editDealData.stage_type.value != undefined  ) {
                if(this.editDealData.stage_type.value.id=="all"){
                    this.editDealData.stage_type.value = this.stageList[0]
                }
                this.allProcess = this.stageList.filter(s => s.id == this.editDealData.stage_type.value.id)[0].stagedef_set;
                if (this.editDealData.current_stage == undefined) {
                    if (this.editDealData.stage_type.value.stagedef_set == undefined) {
                        this.currentStage = 1;
                    } else {
                        this.currentStage = this.editDealData.stage_type.value.stagedef_set.sequence_number;
                    }
                }else if (this.editDealData.current_stage == 0) {
                    this.currentStage = 1;
                }
                else {
                    this.currentStage = this.allProcess.find(p => { return p.id == this.editDealData.current_stage}).sequence_number;
                }
                this.selectStageType();
            }
            else{
                let default_process = null;
                try {
                    default_process = this.user.default_value.default_process;
                } 
                catch (error) {
                    default_process = null;
                }

                if(default_process == null || default_process.opportunity == null){
                    this.stageList.forEach(e => {
                        e.is_admin_default == true ? this.editDealData.stage_type = e : '';
                    })
                }
                else{
                    this.stageList.forEach(e => {
                        e.id == default_process.opportunity ? this.editDealData.stage_type = e : '';
                    })
                }
                this.currentStage = 1;
                this.selectStageType();
            }
        },
        data() {
            return {
                opptyStatusList:opptySelectFieldsList['opptyStatusList'],
                opptyIndustryList:opptySelectFieldsList['opptyIndustryList'],
                customerSourceList:opptySelectFieldsList['customerSourceList'],
                opptyProgressList:opptySelectFieldsList['opptyProgressList'],
                opptyTypeList:opptySelectFieldsList['opptyTypeList'],
                isLoading: false,
                addDealValid: true,
                formRules: {
                    fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
                    forecast: [(v) => /(^[0-9]\d*$)|null/.test(v) || i18n.t('RULE.RULE_R')],
                    nameRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_NAME'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                    ],
                    amountRule: [(v) => /^\d{1,17}(\.\d{0,2})?$|(^$)|null/.test(v) && !!v || i18n.t('RULE.RULE_R_NUM_2')],
                    dateRule: [(v) => !!v || i18n.t('RULE.RULE_R')],
                    stageTypeRule: [(v) => !!v || i18n.t('RULE.RULE_PROCCESS')],
                    forecastRule: [(v) => !!v || i18n.t('RULE.RULE_FORECAST')],
                    companyRule: [
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
                    ],
                    contactRules: [],
                    contentLenRule100Require: [
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                        (v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100')
                    ],
                    contentLenRule50Require: [
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    contentLenRule50: [
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    contactRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                },
                allProcess: [],
                process: [],
                contactList: [],
                primaryContactList:[],
                organizationList: [],
                forecast_category_List: forecast_category_List,
                stageList: [],
                comboText: '',
                search: null,
                menu: [false,false],
                isNewContact: false,
                isPriNewContact: false,
                newContact: {
                    last_name: '',
                    first_name: ''
                },
                newPriContact: {
                    last_name: '',
                    first_name: ''
                },
                errorDialog: false,
                errorMessage: '',
                isProcess: false,
                isContact: true,
                currentStage: 1,
                lenProcess: '',
                formatAmount:null,
                formatBudget:null,
                amountShow:false,
                amountValidate:false,
                budgetShow:false,
                budgetValidate:false,
                style: '',
                editBtn_loading: false,
                contactSearch: null,
                primaryContactSearch:null,
                contact_loading: false,
                user: JSON.parse(window.localStorage.getItem('user')),
                editDealData:{
                    name: {change:0,value:''},
                    primary_contact:{change:0,value:''},
                    oppty_org: {change:0,value:''},
                    contact: {change:0,value:''},
                    amount: {change:0,value:''},
                    expected_close_date: {change:0,value:''},
                    forecast_category: {change:0,value:''},
                    stage_type: {change:0,value:this.stageTypeId},
                    current_stage: 0,
                    customer_needs:{change:0,value:''},
                    owner: {change:0,value:''},
                    primary_campaign_id:{change:0,value:''},
                    customer_budget:{change:0,value:''},
                    budget_confirmed:{change:0,value:''},
                    closed_reason:{change:0,value:''},
                    closed_date:{change:0,value:''},
                    custom_field:{change:0,value:''},
                    
                    purchase_needs_or_process:{change:0,value:''},
                    expect_or_nextsteps_or_others:{change:0,value:''},
                    sales_notes:{change:0,value:''},
                    oppty_status:{change:0,value:''},
                    industry:{change:0,value:''},
                    customer_source:{change:0,value:''},
                    other_customer_source:{change:0,value:''},
                    progress:{change:0,value:''},
                    oppty_type:{change:0,value:''},
                },
                change_stage:false,
                loseReasonList: [],
                reason:'',
                ownerList: [],
                messageDialog: false,
                message: '',
                customFieldData:[],
                windowHeight: window.innerHeight,
                processHeight: window.innerHeight-300,
                selectToChange: bulkEditList,
                selectToChangeNoDel: bulkEditList.filter(el=>el.key!=2),
                bulkEditData:{},
                campaignList:[],
                cfValid:true,
                passId:this.stageTypeId.id,
                isEditLoading:false,
            }
        },
        methods: {
            async getOpptyClosedReason(){
                this.loseReasonList = await get_oppty_closed_reason_func();
            },
            visibleChange(blurVal){
                this.$refs[blurVal].blur();
                let focuVal = blurVal+'_val';
                setTimeout(()=>{
                    try{
                        this.$refs[focuVal].focus();
                        this.$refs[focuVal].isMenuActive = true;
                    }catch{
                    // pass;
                    }
                },100);
            },
            showFormatAmount(val){
                switch(val){
                    case 'amount':
                        this.amountShow = false;
                        this.formatAmount = this.getFormatAmount(this.editDealData.amount.value);
                        this.amountValidate = this.$refs.amount_val.validate();
                        break;
                    default:
                        this.budgetShow = false;
                        this.formatBudget = this.getFormatAmount(this.editDealData.customer_budget.value);
                        this.budgetValidate = this.$refs.customer_budget_val.validate();
                        break;
                }
            },
            showEditAmount(val){
                switch(val){
                    case 'amount':
                        this.amountShow = true;
                        this.$refs.amount_val.focus();
                        break;
                    default:
                        this.budgetShow = true;
                        this.$refs.customer_budget_val.focus();
                        break;
                }
            },
            validateForm(){
                if(this.$refs.form.validate()){
                    return true;
                }
                else{
                    return false;
                }
            },
            getProcessColor: function (groupIdx) {
                let color = '';
                switch (groupIdx) {
                    case 1:
                        color = 'primary';
                        break;
                    case 2:
                        color = 'orange';
                        break;
                    case 3:
                        color = 'green';
                        break;
                    case 4:
                        color = 'purple';
                        break;
                    case 5:
                        color = 'red';
                        break;
                    default:
                        color = 'gary';
                        break;
                }
                return color;
            },
            async onConfirm() {
                this.formRules.dateRule.push((v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE'));
                this.formRules.contactRules.push((v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'));
                if (await this.validateForm()) {
                    if (typeof(this.editDealData.stage_type) !== 'object') {
                        this.showErrorDialog(i18n.t('ADD_DEAL.ERROR'));
                        return;
                    }
                    let primary_contact = this.editDealData.primary_contact.value;
                    if(!this.isPriNewContact && typeof(primary_contact) === 'string' && primary_contact){
                        this.showErrorDialog(i18n.t('ADD_CASE.ERROR_2'));
                        return;
                    }
                    let contact = this.editDealData.contact.value;
                    if(!this.isNewContact && typeof(contact) === 'string' && contact){
                        this.showErrorDialog(i18n.t('ADD_DEAL.ERROR_2'));
                        return;
                    }
                    this.editBtn_loading = true;
                    let bulkEditDealData = {};
                    this.getResponsedata();
                    if(this.cfValid == false){
                        this.cfValid = true;
                        return;
                    }
                    for(let i in this.editDealData){
                        if(this.editDealData[i]['change']==1){
                            bulkEditDealData[i] = this.editDealData[i]['value'];
                            if(i == 'closed_reason'){
                                bulkEditDealData['closed_date'] = this.editDealData['closed_date']['value'];
                            }
                            if(i == 'owner'){
                                bulkEditDealData[i] = this.editDealData[i]['value']['id'];
                            }
                            if(i == 'contact'){
                                if(typeof(this.editDealData.oppty_org.value) == "string" && this.editDealData.oppty_org.value != ''){
                                    bulkEditDealData['oppty_org'] = {id: "-1", name: this.comboText, company_number: "0"};
                                }
                                if(typeof(this.editDealData.oppty_org.value) == "object"){
                                    bulkEditDealData['oppty_org'] = this.editDealData.oppty_org.value;
                                }
                                if(this.editDealData.contact.value == null){
                                    delete bulkEditDealData['contact'];
                                }
                                if(this.editDealData.contact.value== '' && this.newContact.last_name !=''){
                                    bulkEditDealData[i] = {
                                            id: "0",
                                            last_name: this.newContact.last_name,
                                            first_name: this.newContact.first_name != null ? this.newContact.first_name : ""
                                    };
                                }
                            }
                            if(i == 'primary_contact'){
                                if(this.editDealData.primary_contact.value== '' && this.newPriContact.last_name !=''){
                                    bulkEditDealData[i] = {
                                            id: "0",
                                            last_name: this.newPriContact.last_name,
                                            first_name: this.newPriContact.first_name != null ? this.newPriContact.first_name : ""
                                        };
                                    }
                            }
                            if(i == 'budget_confirmed' && this.editDealData.budget_confirmed.value== ''){
                                bulkEditDealData['budget_confirmed'] = false;
                            }
                        }
                        if(this.editDealData[i]['change']==2){
                            bulkEditDealData[i] = null;
                        }
                    }
                    let change_stage_sequence = 0;
                    if(this.change_stage){
                        bulkEditDealData['stage_type'] = this.editDealData.stage_type.value.id;
                        bulkEditDealData['current_stage'] = this.allProcess[this.currentStage - 1].id;
                        change_stage_sequence = this.allProcess[this.currentStage - 1].sequence_number;
                    }

                    let editItemName = '';
                    let editItemNameArray = [];
                    let closeOppty = '';
                    for(let i in this.editItems){
                        editItemName = i > 0 ? editItemName +','+ this.editItems[i].name : editItemName + this.editItems[i].name ;
                        editItemNameArray.push(this.editItems[i].id);
                        if(this.editItems[i].closed_reason != null && this.editItems[i].closed_reason != 0){
                            closeOppty = closeOppty.length > 0 ? closeOppty +','+ this.editItems[i].name : closeOppty + this.editItems[i].name;
                        }
                    }
                    this.bulkEditData = {
                        list:editItemNameArray,
                        data:bulkEditDealData,
                        cf:this.customFieldData.length > 0 ? this.customFieldData :null
                    };
                    if(change_stage_sequence>=3 && !await wiadvanceProgressCheck(editItemNameArray)){
                        this.showErrorDialog(i18n.t('WIADVANCE.MESSAGE_2'));
                        this.editBtn_loading = false;
                        return
                    }
                    // if(this.editDealData['progress'].change == 1 && parseInt(this.editDealData.progress.value) >=3 && !await wiadvanceProgressCheck(editItemNameArray)){
                    //     this.showErrorDialog(i18n.t('WIADVANCE.MESSAGE_2'));
                    //     this.editBtn_loading = false;
                    //     return
                    // }
                    if(this.customFieldData.length === 0 && Object.keys(this.bulkEditData.data).length === 0){
                        this.showErrorDialog(i18n.t('BULK_EDIT.MESSAGE_EMPTY'));
                        return;
                    }
                    if(closeOppty.length>0){
                        if(this.editDealData['closed_reason'].change == 1 && this.editDealData['closed_reason'].value == 0){
                            this.showMessageDialog(this.editItems.length);
                        }else{
                            this.showErrorDialog(`${i18n.t('BULK_EDIT.ERROR_DEAL')}<br>${closeOppty}`);
                        }
                    }else{
                        this.showMessageDialog(this.editItems.length);
                    }
                }
            },
            onCancel() {
                this.$emit('emitBulkEditDialog', false);
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                if(Object.keys(this.bulkEditData.data).length != 0 || this.customFieldData.length != 0)
                {
                    this.message = `${i18n.t('BULK_EDIT.MESSAGE_DATA')}${message}${i18n.t('BULK_EDIT.MESSAGE_DATA_2')}`;
                }else
                {
                    this.showErrorDialog(i18n.t('BULK_EDIT.MESSAGE_EMPTY'));
                    this.messageDialog = false;
                    return;
                }
            },
            async onEmitMessageDialog(val){
                if(val){
                    if(!this.isEditLoading){
                        this.isEditLoading = true;
                        if(Object.keys(this.bulkEditData.data).length != 0 || this.customFieldData.length != 0){
                            await(this.$emit('emitBulkEditDialog','editing',this.bulkEditData));
                        }
                    }
                }
                this.editBtn_loading = false;
                this.messageDialog = false;
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
                this.editBtn_loading = false;
            },
            async selectStageType() {
                try {
                    if (this.editDealData.stage_type.value.stagedef_set.length != undefined) {
                        this.allProcess = this.editDealData.stage_type.value.stagedef_set;
                    }
                    this.allProcess.sort((a, b) => {
                        return a.sequence_number - b.sequence_number;
                    });
                    this.isProcess = true;
                    this.lenProcess = this.allProcess.length;
                    await this.setProcess();
                    await (this.currentStage = this.currentStage - 1);
                } catch {
                    this.isProcess = false;
                    return;
                }
            },
            setProcess() {
                if (this.lenProcess > 3) {
                    if (this.currentStage <  3) {
                        this.process = this.allProcess.slice(0, 3);
                    } else if (this.currentStage > this.lenProcess - 2) {
                        this.process = this.allProcess.slice(this.lenProcess - 3, this.lenProcess);
                    } else {
                        this.process = this.allProcess.slice(this.currentStage - 2, this.currentStage + 1);
                    }
                }
                else {
                    this.process = this.allProcess;
                }
                this.currentStage = this.currentStage + 1;
            },
            getcolor: function(data) {
                this.colorQ= data	 
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ		
                }
            },
            async nextStage() {
                if (this.process[2].sequence_number == this.lenProcess) {
                    return;
                } else {
                    let n = this.process[2].sequence_number;
                    n = n + 2;
                    await this.changeAddStage(n);
                    await (this.currentStage = this.currentStage - 1);
                }
            },
            async preStage() {
                if (this.process[0].sequence_number == 1) {
                    return;
                } else {
                    let n = this.process[0].sequence_number;
                    n = n - 2;
                    await this.changeMinusStage(n);
                    await (this.currentStage = this.currentStage - 1);
                }
            },
            changeAddStage(n) {
                if (n > this.lenProcess - 2) {
                    this.process = this.allProcess.slice(this.lenProcess - 3, this.lenProcess);
                } else {
                    this.process = this.allProcess.slice(n - 2, n + 1);
                }
                this.currentStage = this.currentStage + 1;
            },
            changeMinusStage(n) {
                if (n < 3) {
                    this.process = this.allProcess.slice(0, 3);
                } else {
                    this.process = this.allProcess.slice(n - 2, n + 1);
                }
                this.currentStage = this.currentStage + 1;
            },
            getResponsedata(){
                this.$refs.CFRequired.emitData();
                if(this.$refs.CFRequired.responsedata === false)
                {
                    this.$refs.CFRequired.responsedata = null;
                    this.cfValid = false;
                    this.editBtn_loading = false;
                    return;
                }
                else if(this.$refs.CFRequired.responsedata === null)
                    this.customFieldData = [];
                else
                    this.customFieldData = this.$refs.CFRequired.responsedata;
            },
        },
        computed: {
            isNew: function(){
                if(this.editDealData.oppty_org.value == "" || this.editDealData.oppty_org.value == null){
                    return false;
                }
                if(typeof (this.editDealData.oppty_org.value) === 'object'){
                    if(this.editDealData.oppty_org.value.id == 0){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    return true;
                }
            },
        },
        watch: {
            "errorDialog":function(){
                if(!this.errorDialog){
                    this.editBtn_loading = false;
                }
            },
            "editDealData.stage_type.value":function(){
                this.currentStage = 2;
                if(this.change_stage && this.editDealData.stage_type.value){
                    this.passId = this.editDealData.stage_type.value.id;
                }else{
                    this.passId = this.stageTypeId.id;
                }
            },
            "change_stage":function(){
                if(!this.change_stage){
                    this.passId = this.stageTypeId.id;
                }else if(this.editDealData.stage_type.value){
                    this.passId = this.editDealData.stage_type.value.id;
                }
            },
            "isPriNewContact":function(){
                    this.editDealData.primary_contact.value = '';
            },
            "isNewContact":function(){
                    this.editDealData.contact.value = '';
            },
            async search(val) {
                var re = /^(?!\s*$).+/;
                var check = re.test(val);
                var checkLength = /^.{0,200}$/.test(val);
                this.comboText = "";
                if(check && val != null && checkLength){
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    this.comboText = val;
                    if (!this.isLoading && charNum>=2 && charNum==val.length ) {
                        this.isLoading = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val)
                            .then(response => { 
                                this.organizationList = response.data; 
                                let num = 0
                                this.organizationList.forEach((item)=>{
                                    item.uqNumber = num
                                    num ++
                                })
                                })
                            .finally(() => (this.isLoading = false));
                    }
                    else {
                        this.isLoading = false;
                    }
                }
            },
            contactSearch(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString){
                    this.contact_loading = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val)){
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.contactList = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.contact_loading = false;
                    })
                }
                else{
                    this.contactList = [];
                }
            },
            primaryContactSearch(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString){
                    this.contact_loading = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val)){
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.primaryContactList = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.contact_loading = false;
                    })
                }
                else{
                    this.primaryContactList = [];
                }
            }
        },
    });
</script>
<template lang='pug'>
    #Case.main-page.page-noScroll.detail-page
        v-overlay(v-model='loading' z-index='99' opacity='.6')
            v-progress-circular(indeterminate color='primary')
        div.height_100(v-show='!loading')
            v-breadcrumbs.pa-0.px-3.pb-2.ellipsis(:items='breadcrumbs')
            v-row#page-head.d-flex.align-center.my-0
                v-col(cols='4' lg='6')
                    .d-flex.align-center.title-height
                        h1.my-0.d-flex.align-center.ellipsis(:title='caseDetail.subject')
                            .cube-icon.bg-og.mr-3
                                v-icon(color='white' size='22') mdi-wrench-outline
                            span.ellipsis {{ caseDetail.subject }}
                        followBtn(:follow='isFollow' :page='"Case"' :target_id='caseId' @emitChangeFollow='onEmitChangeFollow')
                v-col(cols='8' lg='6').bg-p
                    v-row.justify-end(no-gutters='no-gutters')
                        v-col.ml-2(cols='auto')
                            .btn-group
                                v-btn(@click='showAddCaseDialog' width='60' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.COPY')}}
                                v-btn.border-left(v-if='!isProcessComplete' @click='onDelete' width='60' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.DELETE')}}
                        v-col.ml-2(cols='auto')
                            .btn-group(v-if='!isProcessComplete')
                                v-btn( @click='onChangeOwn(caseDetail)' width='100%' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.CHANGE_OWNER_2')}}
                        v-col.ml-2(cols='auto')
                            .btn-group(v-if='!isProcessComplete')
                                v-btn(@click='onChangeProcess(caseDetail)' width='100%' height='30' color='white' depressed tile dark)
                                    span {{$t('CASE_DETAIL.CHANGE_STAGE')}}
                        v-col.ml-2(v-if='!isProcessComplete' cols='auto')
                            v-menu(offset-y).z-100
                                template(v-slot:activator="{ on, attrs }")
                                    v-btn.ml-2(v-bind='attrs' v-on='on' @click='onClose' width='60' height='32' color='green' depressed dark)
                                        span {{$t('CASE_DETAIL.CLOSED')}}
                        v-col.ml-2(v-else cols='auto')
                            v-btn.mr-4(@click='onReStart()' width='100' height='32' color='green' depressed dark)
                                span {{$t('CASE_DETAIL.REOPEN')}}
                            v-chip.mr-4(v-if='isProcessClosed' color='primary' text-color='white') {{ closeReasonName }} {{$t('CASE_DETAIL.IN')}} {{ caseDetail.closed_date }} {{$t('CASE_DETAIL.CLOSED')}}
            #page-inner.pa-3.pt-0
                v-card.card-preface.card-shadow.px-0.py-6(flat)
                    v-row
                        v-col.py-0.px-2.px-md-4.ellipsis(cols='6' md='2')
                            v-card-subtitle.pa-0.t-lightgary {{$t('CASE_DETAIL.CONTACT')}}
                            a.f-text-highlight.mb-0.t-primary(v-if='caseDetail.primary_contact != null' :href='"/Contacts/" + caseDetail.primary_contact.id' target='_blank') {{ caseDetail.primary_contact.__chineseName__ }}
                        v-col.py-0.px-2.px-md-4.border-left.ellipsis(cols='6' md='2')
                            v-card-subtitle.pa-0.t-lightgary {{$t('CASE_DETAIL.PHONE')}}
                            a.f-text-highlight.mb-0.t-primary(:href='onCall(caseDetail.incoming_phone)') {{ caseDetail.incoming_phone }}
                        v-col.py-0.px-2.px-md-4.border-left.ellipsis(cols='6' md='2')
                            v-card-subtitle.pa-0.t-lightgary {{$t('CASE_DETAIL.EMAIL')}}
                            a.f-text-highlight.mb-0.t-primary(:href='onMail(caseDetail.incoming_email)') {{ caseDetail.incoming_email }}
                        v-col.py-0.px-2.px-md-4.border-left.ellipsis(cols='6' md='2')
                            v-card-subtitle.pa-0.t-lightgary {{$t('CASE_DETAIL.EXPECTED_CLOSE_DATE')}}
                            p.f-text-highlight.mb-0 {{ caseDetail.due_date }}
                        v-col.py-0.px-2.px-md-4.border-left(cols='6' md='1')
                            v-card-subtitle.pa-0.t-lightgary {{$t('CASE_DETAIL.PRIORITY')}}
                            v-icon.d-flex.align-center(v-if="caseDetail.priority == 'VERY_HIGH'" :title="getItemNameFromList(casePriorityList, caseDetail.priority)" size='20' color='red') fas fa-angle-double-up
                            v-icon.d-flex.align-center(v-if="caseDetail.priority == 'HIGH'" :title="getItemNameFromList(casePriorityList, caseDetail.priority)" size='20' color='red') fas fa-angle-up
                            v-icon.d-flex.align-center(v-if="caseDetail.priority == 'MEDIUM'" :title="getItemNameFromList(casePriorityList, caseDetail.priority)" size='20' color='orange') fas fa-equals
                            v-icon.d-flex.align-center(v-if="caseDetail.priority == 'LOW'" :title="getItemNameFromList(casePriorityList, caseDetail.priority)" size='20' color='primary') fas fa-angle-down
                            v-icon.d-flex.align-center(v-if="caseDetail.priority == 'VERY_LOW'" :title="getItemNameFromList(casePriorityList, caseDetail.priority)" size='20' color='primary') fas fa-angle-double-down
                        v-col.py-0.px-2.px-md-4.border-left(cols='6' md='1')
                            v-card-subtitle.pa-0.t-lightgary {{$t('CASE_DETAIL.STATUS')}}
                            p.f-text-highlight.mb-0 {{ getItemNameFromList(caseStatusList, caseDetail.status) }}
                        v-col.py-0.px-2.px-md-4.border-left(cols='6' md='2')
                            v-list-item.pa-0(href='/Setting' target='_blank')
                                v-list-item-avatar.ma-0.mr-2(color='aliceBlue')
                                    span.mx-auto(v-if="!isPhoto") {{ caseDetail.owner? caseDetail.owner.last_name : '' }}
                                    img(v-else :src='caseDetail.owner.usersetting.photo' :alt='caseDetail.owner.last_name')
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('CASE_DETAIL.OWNER')}}
                                    a(:href='onMail(caseDetail.owner.email)').t-primary.mb-0.f-text-highlight.ellipsis {{ caseDetail.owner ? caseDetail.owner.last_name : '' }} {{ caseDetail.owner? caseDetail.owner.first_name : '' }}
                v-card.process-card.mt-4
                    v-btn.process-box-open(v-hide-element='false' width='40' height='40' depressed fab outlined @click='switchStepContent()')
                        v-icon(size='14' v-if='stepContent === true') icon-arrow-top
                        v-icon(size='14' v-else) icon-arrow-btm
                    v-stepper.process-box(v-model='currentStep')
                        v-stepper-header.process-bar.process-bar-in-detail
                            template(v-for='item in caseSummary')
                                v-stepper-step(@click='onChangeCaseStage(item.id)' :key='`${item.sequence_number}-process`' :complete='currentStep > item.sequence_number' :step='item.sequence_number' :class='customStagecolor'  :style='getcolor(item.stage_color)'  edit-icon='icon-check' :editable='isProcessEdit && !$isViewOnly.isViewOnly')
                                    span(v-if='currentStep < item.sequence_number')  {{ item.stage_name }}
                                    span(v-else)  {{ item.stage_name }} {{ (item.stage_duration/(24*60*60)).toFixed(1) }} {{$t('CASE_DETAIL.DAY')}}
                            v-btn.mx-1(v-hide-element='false' v-if='allStage > 6 && isFirst && isProcessEdit' absolute style="marginTop: 4px; zIndex: 50;" fab dark x-small color="white" @click='preStage()')
                                v-icon(color="primary") icon-arrow-left
                            v-btn(v-hide-element='false' v-if='allStage > 6 && isLast && isProcessEdit' absolute style="marginTop: 4px; zIndex: 50; right: 5px"  fab dark x-small color="white" @click='nextStage()')
                                v-icon(color="primary") icon-arrow-right
                        v-stepper-items(:class="stepContent ? '':'hide'")
                            v-stepper-content.pa-0(v-for='(item, index) in caseSummary' :key='`${item.sequence_number}-process`' :step='item.sequence_number')
                                v-card(color flat) 
                                    v-form(ref='basicForm' v-model='validForm.basic')
                                        v-row(no-gutters='no-gutters')
                                            v-col.pr-md-4(cols='12' md='12')
                                                h4.my-2 {{$t('CASE_DETAIL.GUIDE')}}
                                                p.d-flex.align-center.input-has-label.dance
                                                    v-text-field(v-model='item.guideline' :id='`${index}-guideline`' solo readonly dense='dense' :hide-details='true' height='84px')
                v-row.pinning-card.hidden-sm-only.hidden-xs-only(no-gutters v-for='(item,index) in alertNote' :key='index' :style='setPosition(index)' :class='pinning ? "": "d-none"')
                    v-col(cols='1')
                        v-icon.ml-2(color='white') mdi-firework
                    v-col(:cols='index === 0 ? "7" : "9" ').ellipsis
                        strong.ellipsis {{ item.content.replace( /(<([^>]+)>)/ig, '') }}
                    v-col(:cols='index === 0 ? "4" : "2" ').d-flex.justify-end
                        span.ml-3 {{ item.alertTime }} 
                        .mx-3.d-flex( v-if='index === 0')
                            v-btn.process-box-open(v-hide-element='false' v-if='alertNote.length > 1' width='24' height='24' depressed fab outlined @click='folder(index)')
                                v-icon(size='14' v-if='getfolder === false') icon-arrow-top
                                v-icon(size='14' v-else) icon-arrow-btm
                            v-menu(bottom left)
                                template(v-slot:activator='{ on, attrs }')
                                    v-btn.ml-3(v-hide-element='false' color='white' dark v-bind='attrs' v-on='on' icon dense min-height='20' height='20' width='20')
                                        v-icon(size='16')  icon-dot-3
                                v-list.pa-0
                                    v-list-item.px-0.text-center
                                        v-btn(v-hide-element='false' @click='onPinning()' text='text')
                                            span.ml-1 {{$t('GENERAL.CLOSE')}}
                v-row(no-gutters='no-gutters' :class="stepContent ? 'deal-content mt-4':'deal-content big mt-4'")
                    v-col(cols='12' md='8')
                        v-card.deal-tab-card(flat)
                            v-tabs.main-tabs(id='main_tab' background-color='transparent' show-arrows)
                                v-tabs-slider
                                v-tab(v-for='item in tabs' :key='item.key' :href='`#tab-${item.id}`' @click='getTabName(item.key)')
                                    | {{ item.name }}
                                // - Detail -
                                v-tab-item.pa-3(key='detail' value='tab-1' :transition='false' :reverse-transition='false')
                                    v-form(v-model="valid" ref='formCaseDetail')
                                        v-row(no-gutters).pb-5
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CASE_DETAIL.CASE_NAME')}}
                                                    v-text-field#caseName.ellipsis(v-model='caseDetail.subject' solo='solo' :readonly='isDetailReadonly' :rules="formRule.nameRules")
                                                        template(v-if='isOpen' v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label {{$t('CASE_DETAIL.CONTACT')}}
                                                        v-text-field.pr-2(v-if='!isDetailReadonly && isNew.primary_contact' v-model="newPri.lName" solo :label=`$t('GENERAL.LAST_NAME')` large="large" :rules='formRule.contactnNameRules' @input='cleanFirstName(newPri)')
                                                        v-text-field(v-if='!isDetailReadonly && isNew.primary_contact' v-model="newPri.fName" solo :label=`$t('GENERAL.FIRST_NAME')` :rules='formRule.contentLenRule50' large="large")
                                                            template(v-slot:append-outer)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                                        v-combobox#primary_contact(v-if='!isDetailReadonly && !isNew.primary_contact' v-model="caseDetail.primary_contact" solo :items="contactData" item-text="searchStr" item-value="id" :search-input.sync="search_primary_contact" :loading="isLoading.primary_contact" :rules='formRule.contactRules' style='z-index: 100;'  :no-data-text=`$t('RELATEDCONTACTS.NO_CONTACT')`)
                                                            template( v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                                            template(v-slot:prepend-item)
                                                                v-list-item
                                                                    v-btn(width='120' height='32' color='blue' plain border-0 @click='isNew.primary_contact = true')
                                                                        v-icon.mr-1(size='14') icon-add
                                                                        span {{$t('ADD_CASE.NEW_CONTACT')}}
                                                            template(v-slot:item='data')
                                                                v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                                                    v-icon(v-if="data.item.image == null") mdi-account
                                                                    img(v-else :src='data.item.image')
                                                                .t-black {{ data.item.full_name }} 
                                                                .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                                            template(v-slot:selection='data')
                                                                .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                                                        .d-flex.justify-space-between.ellipsis(:class='isDetailReadonly?"w-100":""')
                                                            a.t-primary.ellipsis(v-if="caseDetail.primary_contact != null && isDetailReadonly" :href="'/Contacts/'+ caseDetail.primary_contact.id") {{ caseDetail.primary_contact.__chineseName__ }}
                                                            v-icon.ml-auto(v-if='isDetailReadonly && isOpen' size='18' v-hide-element @click='onEditDetail()') icon-edit
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CASE_DETAIL.CASE_NUMBER')}}
                                                    v-text-field.ellipsis(v-model='caseDetail.case_number' solo='solo' :readonly='autoNumberCheck? autoNumberCheck:isDetailReadonly' :rules='formRule.contentLenRule50')
                                                        template(v-if='isOpen' v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil && !autoNumberCheck' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil && !autoNumberCheck' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly && !autoNumberCheck' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil && !autoNumberCheck' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label {{$t('CASE_DETAIL.ORG_NAME')}}
                                                        v-combobox(v-if='!isDetailReadonly && !isNew.case_org' v-model="caseDetail.case_org" solo :items="organizationList" item-text="name" item-value="uqNumber" :search-input.sync="search_case_org" :loading="isLoading.case_org" :rules='formRule.companyRule')
                                                            template(v-slot:append-outer)
                                                                div.mr-1(v-if="isOrgNew")
                                                                    v-icon(color="red", size="25") mdi-new-box
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                                            template(v-slot:item="data")
                                                                div.ellipsis(v-if="data.item.id == 0")
                                                                    span {{ data.item.name }}
                                                                    v-icon(color="red", size="25") mdi-new-box
                                                                div.ellipsis(v-else)
                                                                    span {{ data.item.name }}
                                                        .d-flex.justify-space-between.ellipsis.w-100(v-if='isDetailReadonly')
                                                            a.t-primary.ellipsis(v-if="caseDetail.case_org != null && isDetailReadonly" :href="'/Account/' + caseDetail.case_org.id" :title='caseDetail.case_org.name') {{ caseDetail.case_org.name }}
                                                            v-icon.ml-auto(size='18' v-if='isDetailReadonly && !loading_caseDeatil && isOpen' v-hide-element @click='onEditDetail()') icon-edit
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CASE_DETAIL.CREATED_ON')}}
                                                    v-text-field.ellipsis(v-model='created_on'  solo='solo' readonly) 
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label {{$t('CASE_DETAIL.EXPECTED_CLOSE_DATE')}}
                                                    v-text-field(v-if='isDetailReadonly' v-model='caseDetail.due_date' solo :readonly='isDetailReadonly')
                                                        template(v-if='isOpen' v-slot:append)
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                    v-menu(v-if='!isDetailReadonly' ref="menu" v-model="menu" :z-index='100' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                                                        template(v-slot:activator="{ on, attrs }")
                                                            v-text-field(v-model='caseDetail.due_date' solo :readonly='!isDetailReadonly' v-bind="attrs" v-on="on")
                                                                template(v-slot:append)
                                                                    v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                                    v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                    v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                                        v-date-picker(v-model='caseDetail.due_date' @input='menu = false' no-title scrollable)
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CASE_DETAIL.INCOMING_CONTACT')}}
                                                    v-text-field.ellipsis(v-model='caseDetail.incoming_fullname' solo='solo' :readonly='isDetailReadonly')
                                                        template(v-if='isOpen' v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label {{$t('CASE_DETAIL.PHONE')}}
                                                        vue-tel-input-vuetify.w-100.no-border(v-if='!isDetailReadonly' v-model="caseDetail.incoming_phone" label=''  placeholder='' autocomplete='off' :defaultCountry='getLocation()' disabledFetchingCountry=true mode='international' @validate="onPhoneCheck($event)" @input="onPhoneInput" :rules="formRule.phoneRules")
                                                            template(v-slot:append v-if='!isDetailReadonly')
                                                                v-icon.mr-1(size='18' color="green" v-if='!loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close
                                                                v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                                        .d-flex.justify-space-between.w-100.ellipsis(v-if='isDetailReadonly')
                                                            a.t-primary.ellipsis(v-if='isDetailReadonly' :href='onCall(caseDetail.incoming_phone)') {{ caseDetail.incoming_phone }}
                                                            v-icon(size='18' v-if='isDetailReadonly && isOpen' v-hide-element @click='onEditDetail()') icon-edit
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label {{$t('CASE_DETAIL.EMAIL')}}
                                                        v-text-field.ellipsis(v-if='!isDetailReadonly' v-model='caseDetail.incoming_email' solo='solo' :readonly='isDetailReadonly' :rules="formRule.emailRules")
                                                            template(v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                                v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                                        .d-flex.justify-space-between.w-100.ellipsis(v-if='isDetailReadonly')
                                                            a.t-primary.ellipsis(:href='onMail(caseDetail.incoming_email)') {{ caseDetail.incoming_email }}
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isDetailReadonly && !loading_caseDeatil && isOpen' v-hide-element @click='onEditDetail()') icon-edit
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label {{$t('CASE_DETAIL.SOURCE')}}
                                                    v-select.no-border(v-model='caseDetail.source' solo :items='caseSourceList' item-text='name' item-value='key' :readonly='isDetailReadonly' :menu-props='{closeOnClick:true}')
                                                        template(v-if='isOpen' v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label {{$t('CASE_DETAIL.GROUP')}}
                                                    v-select.no-border(v-model='caseDetail.group' solo :items='caseGroupList' item-text='group_name' item-value='id' :readonly='isDetailReadonly' :menu-props='{closeOnClick:true}')
                                                        template(v-if='isOpen' v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label {{$t('CASE_DETAIL.TYPE')}}
                                                    v-select.no-border(v-model='caseDetail.type' solo :items='caseTypeList' item-text='type_name' item-value='id' :readonly='isDetailReadonly' :menu-props='{closeOnClick:true}')
                                                        template(v-if='isOpen' v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label {{$t('CASE_DETAIL.CASE_STATUS')}}
                                                    v-select.no-border(v-model='caseDetail.status' solo :items='caseStatusList' item-text='name' item-value='key' :readonly='isDetailReadonly' :menu-props='{closeOnClick:true}')
                                                        template(v-if='isOpen' v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label {{$t('CASE_DETAIL.PRIORITY')}}
                                                    v-select.no-border(v-model='caseDetail.priority_number' solo :items='casePriorityList' item-text='name' item-value='key' :readonly='isDetailReadonly' :menu-props='{closeOnClick:true}')
                                                        template(v-if='isOpen' v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                p.d-flex.align-center.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    label {{$t('CASE_DETAIL.RELATED_CAMPAIGN')}}
                                                    v-autocomplete.ellipsis.no-border(v-model='caseDetail.primary_campaign_id' solo :items='campaignList' item-text='name' item-value='id' :readonly='isDetailReadonly' :menu-props='{closeOnClick:true}')
                                                        template(v-if='isOpen' v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                                        template(v-slot:selection='data') 
                                                            a.t-primary.ellipsis(:href="'/Campaign/'+ data.item.id" style='max-width: 250px;') {{ data.item.name }}
                                            v-col.pr-md-4.my-1(cols='12' md='6')
                                                div.d-flex.align-center.justify-space-between.input-has-label(:class='isDetailReadonly ? "remove-arrow" : "" ')
                                                    .d-inline-flex.align-center.w-100(:class='isDetailReadonly ? "py-2" : ""')
                                                        label {{$t('CASE_DETAIL.PARENT_CASE')}}
                                                        v-combobox(v-if='!isDetailReadonly' v-model="caseDetail.parent_case" solo :items="parentCaseSelectList" item-text="subject" item-value="id" :search-input.sync="search_parent_case" :loading='isLoading.parent_case' @blur="parentCaseSelectList=[]")
                                                            template(v-slot:append-outer)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                                v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                                        .d-flex.justify-space-between.ellipsis.w-100(v-if='isDetailReadonly')
                                                            a.t-primary.ellipsis(v-if='caseDetail.parent_case != null && isDetailReadonly' :href="'/Case/' + caseDetail.parent_case.id" :title='caseDetail.parent_case.subject') {{caseDetail.parent_case.subject}}
                                                            v-icon.ml-auto(size='18' v-if='isDetailReadonly && !loading_caseDeatil && isOpen' v-hide-element @click='onEditDetail()') icon-edit
                                            v-col.pr-md-4.my-1(cols='12' md='12')
                                                p.d-flex.align-start.input-has-label
                                                    label(style='margin-top: 8px;') {{$t('CASE_DETAIL.DESCRIPTION')}}
                                                    v-textarea.textarea-style(v-model='caseDetail.description' solo :readonly='isDetailReadonly' dense='dense' rows='3')
                                                        template(v-if='isOpen' v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                            v-col.pr-md-4.my-1(cols='12' md='12')
                                                p.d-flex.align-start.input-has-label
                                                    label(style='margin-top: 8px;') {{$t('CASE_DETAIL.DESCRIPTION_2')}}
                                                    v-textarea.textarea-style(v-model='caseDetail.resolution' solo :readonly='isDetailReadonly' dense='dense' rows='3')
                                                        template(v-if='isOpen' v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isDetailReadonly && !loading_caseDeatil' @click='confirmEditDetail()') icon-check
                                                            v-icon(size='18' color="red" v-if='!isDetailReadonly && !loading_caseDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                            v-icon(size='18' v-if='isDetailReadonly' v-hide-element @click='onEditDetail()') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary')
                                        customField(v-if='!loading' :Data='customFieldData' DataClass = 'Case' :Editable='cFEditable' :StageTypeId='StageTypeId')
                                                        
                                // - Time line -
                                v-tab-item.pa-3(:style='tab_height' key='timeline' value='tab-2' :transition='false' :reverse-transition='false')
                                    v-row.sheet-box.my-5(v-if='caseTimeLines.length == 0')
                                        v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                h5.ma-0.t-black {{$t('CASE_DETAIL.NO_TIMELINE')}}
                                    v-tabs.borderBtn-tabs(v-else height='32' background-color='white' :hide-slider='true' :centered='true' show-arrows)
                                        v-tab(v-for='item in timeLineType' :key='item.key' :id='`Tlt-${item.key}`'  @click='onChangeTltSelected' :class='changeSelectedColor(item.key)')
                                            | {{ item.name }}
                                    div.pa-3.pt-5.scrollTimeLine(style='max-height:80vh')
                                        v-timeline.pt-0(dense align-top)
                                            v-timeline-item(v-for='(item, index) in showTimelines' :key='index' :color='item.type == "DUE_ACTIVITY" ? "#FFD306" : "success"' :icon='getTimeLineIcon(item.type)' small right fill-dot v-if='item.show == true && item.show_index<timeline_count')
                                                //- // - Time Line Item - Meeting and Call  -
                                                //- v-card.px-3.py-1(v-if="item.type === 'call' || item.type === 'meeting'" flat outlined)
                                                //- 	v-row.justify-space-between.align-center(no-gutters)
                                                //- 		v-col(cols='12' md='5')
                                                //- 			v-card-subtitle.pa-0
                                                //- 				| {{ item.title }}
                                                //- 		v-col.f-smaller.t-lightgary.t-lightgary.detail(cols='12' md='7')
                                                //- 			span.mr-3
                                                //- 				| {{ item.date }} ・
                                                //- 				| {{ item.owner }}
                                                //- 			.d-flex.align-center.mr-3
                                                //- 				v-icon(size='17') icon-group
                                                //- 				span  {{ item.client }}
                                                //- 			.d-flex.align-center
                                                //- 				v-icon(size='15') icon-company
                                                //- 				span  {{ item.clientConpany }}
                                                //- 			v-menu(bottom left)
                                                //- 				template(v-slot:activator='{ on, attrs }')
                                                //- 					v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                //- 						v-icon(size='16')  icon-dot-3
                                                //- 				v-list.pa-0
                                                //- 					v-list-item.px-0.text-center
                                                //- 						v-btn(@click='onEditAactivity(item)' text='text')
                                                //- 							span.ml-1 編輯
                                                //- 					v-list-item.px-0.text-center
                                                //- 						v-btn(@click='onDelete()' text='text')
                                                //- 							span.ml-1 刪除
                                                // - Time Line Item - DUE ACTIVITY -
                                                v-card.px-3.py-1(v-if="item.type === 'DUE_ACTIVITY'" flat outlined :class='"timeLine-note"')
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col(cols='12')
                                                            p(v-if="item.is_due < 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{(item.is_due)*-1}}{{$t("DEAL_DETAIL.SOON_DUE_1")}}
                                                            p(v-else-if="item.is_due == 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{$t("DEAL_DETAIL.SOON_DUE_4")}}
                                                            p(v-else).mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE_2")}}{{item.is_due}}{{$t("DEAL_DETAIL.SOON_DUE_3")}}
                                                // - Time Line Item - STAGE and STATUS and FIELDHISTORY -
                                                v-card.px-3.py-1(v-if="item.type === 'STAGE' || item.type === 'STATUS' || item.type === 'FIELDHISTORY' || item.type === 'ACTIVITY_FIELDHISTORY'" flat outlined)
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.mr-3
                                                                | {{ item.timeConvert }} ・
                                                                | {{ item.changed_by }}
                                                        v-col(cols='12')
                                                            p.mb-1.t-black  {{ item.content }}
                                                // Time Line Item - NOTE
                                                v-card.px-3.py-1(v-if="item.type === 'NOTE'" flat outlined :class='item.pinned == true ? "timeLine-note" : ""')
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.mr-3
                                                                | {{ item.timeConvert }} ・
                                                                | {{ item.owner }}
                                                        v-col.text-right(cols='1')
                                                            v-menu(bottom left)
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                        v-icon(size='16')  icon-dot-3
                                                                v-list.pa-0
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onPinNote(item)' text='text')
                                                                            v-icon.mr-1(v-if="item.pinned === true" size='10') fas fa-thumbtack
                                                                            span.ml-1 {{$t('GENERAL.PIN')}}
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onEditNote(item)' text='text')
                                                                            span.ml-1 {{$t('GENERAL.EDIT')}}
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onDeleteNote(item.id)' text='text')
                                                                            span.ml-1 {{$t('GENERAL.DELETE')}}
                                                        v-col(cols='12')
                                                            .timeLine-note-box(v-if='item.isMore_timeLineAll')
                                                                vue-editor.can-select-text.v2e_no_border.v2e_post_max_height.ellipsis(ref='editor' v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                                //- p.mb-1  {{ showNote(item.content) }}
                                                            div(v-else)
                                                                vue-editor.can-select-text.v2e_no_border( v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                                //- p.mb-1  {{ item.content }}
                                                        v-col.d-flex.justify-end(cols='12' v-if='checkMore(item.content,300)')
                                                            v-btn.pa-0(v-hide-element='false' v-if='item.isMore_timeLineAll' @click="isMore(index, 'timeLineAll')" color='primary' text) MORE
                                                            v-btn.pa-0(v-hide-element='false' v-else @click="isMore(index, 'timeLineAll')" color='primary' text) LESS
                                                // - Time Line Item - EMAIL  -
                                                v-card.px-3.py-1(v-if="item.type === 'EMAIL'" flat outlined)
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='11')
                                                            span.mr-3
                                                                | {{ item.timeConvert }}
                                                            div.mr-2
                                                                v-icon.mr-1(size='16') mdi-eye
                                                                span.ma-0 
                                                                    | {{ item.opentime }}
                                                            div
                                                                v-icon.mr-1(size='16') mdi-link-variant 
                                                                span.ma-0 
                                                                    | {{ item.linkclicktime }}
                                                        v-col.mb-1.f-smaller.t-lightgary.text-right(cols='1')
                                                            div
                                                                span
                                                                    | {{ item.direction==true ?  $t('GENERAL.OUTGOING'):$t('GENERAL.INCOMING')}}
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.email-sapn
                                                                | {{$t('GENERAL.SENDER')}} :
                                                            a.mb-0( :href='onMail(item.sender_data.email)' :title='item.sender_data.sender_name' target='_blank') {{ item.sender_data.sender_name }} 
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.email-sapn
                                                                | {{$t('GENERAL.TO')}} :
                                                            div.auto-div
                                                                div(v-for='item,index in item.to_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                                                    a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                                                    a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.email-sapn
                                                                | {{$t('GENERAL.CC')}} :
                                                            div.auto-div
                                                                div(v-for='item,index in item.cc_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                                                    a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                                                    a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                                        //- v-col.text-right(cols='2')
                                                            v-menu(bottom left)
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    v-btn(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                        v-icon(size='16')  icon-dot-3
                                                                v-list.pa-0
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(text='text')
                                                                            span 回信
                                                        v-col.d-flex.align-center(cols='12')
                                                            v-card-subtitle.pa-0.t-black
                                                                | {{ item.email_subject }}
                                                        v-col.d-none(cols='12 mt-2')
                                                            p.mb-1(v-html='item.email_body')
                                                    v-row.justify-end.align-center.mt-3(no-gutters)
                                                        v-col.text-right(cols='12')
                                                            v-btn.mr-2(v-hide-element='false' @click='showEmailDetail($event)' color='primary' text) {{$t('GENERAL.SHOW_CONTENT')}}
                                                            v-btn.mr-2(v-hide-element='false' v-if='item.attachments.length > 0' icon @click='onEmailAttachment(item.attachments)')
                                                                v-icon(size='20') icon-attach
                                                            //- v-btn.mr-2(icon @click='onEmailOppties(item.opportunities)')
                                                                v-icon(size='20') icon-chatmoney-border
                                                            v-btn(v-hide-element='false' icon @click='onEmailContact(item.contacts)')
                                                                v-icon(size='20') icon-contact-border
                                                // - Time Line Item - SMS  -
                                                v-card.px-3.py-1(v-if="item.type === 'SMS'" flat outlined)
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span
                                                                | {{ item.timeConvert }} 
                                                        v-col(cols='12')
                                                            v-textarea.no-border.textarea-style.mb-2(v-model='item.sms_body' rows=2 readonly=true style='font-size: 14.4px;')
                                                            //- v-textarea.no-border.no-pointer-event.hide-scrollbar(v-if='item.isMore_timeLineAll' v-model='item.sms_body' rows=3 no-resize=true style='font-size: 14.4px;')
                                                            //- v-textarea.no-border.no-pointer-event(v-else v-model='item.sms_body' auto-grow no-resize=true style='font-size: 14.4px;')
                                                        v-col.d-flex.justify-end(cols='12')
                                                            //- v-btn.pa-0(v-if='item.sms_body.length > 300 && item.isMore_timeLineAll' @click='isMore(index, "timeLineAll")' color='primary' text) MORE
                                                            //- v-btn.pa-0(v-if='item.sms_body.length > 300 && !item.isMore_timeLineAll' @click="isMore(index, 'timeLineAll')" color='primary' text) LESS
                                                            v-btn(v-hide-element='false' icon @click='onEmailContact(item.contacts)')
                                                                v-icon(size='20') icon-contact-border
                                        v-divider.pa-0.ma-0.pb-3(id='timeline_btm' style='border-color:#00000000;')
                                    //- // - Time Line - STAGE -
                                    //- v-tab-item.pa-3.pt-5(key='STAGE' value='tab-2' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(caseTimeLines, "STAGE")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('CASE_DETAIL.NO_STAGE_CHANGE')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in caseTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'STAGE'" :key='index' color='success' icon='mdi-progress-upload' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'STAGE' " flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }} ・
                                    //-                                 | {{ item.changed_by }}
                                    //-                         v-col(cols='12')
                                    //-                             p.mb-1.t-black  {{ item.content }}
                                    //- // - Time Line - STATUS -
                                    //- v-tab-item.pa-3.pt-5(key='STATUS' value='tab-3' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(caseTimeLines, "STATUS")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('CASE_DETAIL.NO_STATUS_CHANGE')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in caseTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'STATUS'" :key='index' color='success' icon='mdi-alert-circle-outline' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'STATUS' " flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }} ・
                                    //-                                 | {{ item.changed_by }}
                                    //-                         v-col(cols='12')
                                    //-                             p.mb-1.t-black  {{ item.content }}
                                    //- // - Time Line - FIELDHISTORY -
                                    //- v-tab-item.pa-3.pt-5(key='FIELDHISTORY' value='tab-4' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(caseTimeLines, "FIELDHISTORY")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('CASE_DETAIL.NO_FIELD_CHANGE')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in caseTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'FIELDHISTORY'" :key='index' color='success' :icon='getTimeLineIcon(item.type)' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'FIELDHISTORY' " flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }} ・
                                    //-                                 | {{ item.changed_by }}
                                    //-                         v-col(cols='12')
                                    //-                             p.mb-1.t-black  {{ item.content }}
                                    //- // - Time Line - NOTE -
                                    //- v-tab-item.pa-3.pt-5(key='NOTE' value='tab-5' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(caseTimeLines, "NOTE")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('CASE_DETAIL.NO_NOTE')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in caseTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'NOTE'" :key='index' color='success' icon='icon-note' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'NOTE'" flat outlined :class='item.pinned == true ? "timeLine-note" : ""')
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }} ・
                                    //-                                 | {{ item.owner }}
                                    //-                         v-col.text-right(cols='1')
                                    //-                             v-menu(bottom left)
                                    //-                                 template(v-slot:activator='{ on, attrs }')
                                    //-                                     v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                    //-                                         v-icon(size='16')  icon-dot-3
                                    //-                                 v-list.pa-0
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(@click='onPinNote(item)' text='text')
                                    //-                                             v-icon.mr-1(v-if="item.pinned === true" size='10') fas fa-thumbtack
                                    //-                                             span {{$t('GENERAL.PIN')}}
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(@click='onEditNote(item)' text='text')
                                    //-                                             span.ml-1 {{$t('GENERAL.EDIT')}}
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(@click='onDeleteNote(item.id)' text='text')
                                    //-                                             span.ml-1 {{$t('GENERAL.DELETE')}}
                                    //-                         v-col(cols='12')
                                    //-                             .timeLine-note-box(v-if='item.isMore_timeLineNote')
                                    //-                                 p.mb-1  {{ showNote(item.content) }}
                                    //-                             div(v-else)
                                    //-                                 p.mb-1  {{ item.content }}
                                    //-                         v-col.d-flex.justify-end(cols='12' v-if='item.content.length > 300')
                                    //-                             v-btn.pa-0(v-if='item.isMore_timeLineNote' @click="isMore(index, 'timeLineNote')" color='primary' text) MORE
                                    //-                             v-btn.pa-0(v-else @click="isMore(index, 'timeLineNote')" color='primary' text) LESS
                                    //- // - Time Line - EMAIL -
                                    //- v-tab-item.pa-3.pt-5(key='EMAIL' value='tab-6' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(caseTimeLines, "EMAIL")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('CASE_DETAIL.NO_MAIL')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in caseTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'EMAIL'" :key='index' color='success' icon='icon-mail' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'EMAIL'" flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='11')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }}
                                    //-                             div.mr-2
                                    //-                                 v-icon.mr-1(size='16') mdi-eye
                                    //-                                 span.ma-0 
                                    //-                                     | {{ item.opentime }}
                                    //-                             div
                                    //-                                 v-icon.mr-1(size='16') mdi-link-variant 
                                    //-                                 span.ma-0 
                                    //-                                     | {{ item.linkclicktime }}
                                    //-                         v-col.mb-1.f-smaller.t-lightgary.text-right(cols='1')
                                    //-                             div
                                    //-                                 span
                                    //-                                     | {{ item.direction==true ?  $t('GENERAL.OUTGOING'):$t('GENERAL.INCOMING')}}
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.email-sapn
                                    //-                                 | {{$t('GENERAL.SENDER')}} :
                                    //-                             a.mb-0( :href='onMail(item.sender_data.email)' :title='item.sender_data.sender_name' target='_blank') {{ item.sender_data.sender_name }} 
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.email-sapn
                                    //-                                 | {{$t('GENERAL.TO')}} :
                                    //-                             div.auto-div
                                    //-                                 div(v-for='item,index in item.to_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                    //-                                     a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                    //-                                     a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.email-sapn
                                    //-                                 | {{$t('GENERAL.CC')}} :
                                    //-                             div.auto-div
                                    //-                                 div(v-for='item,index in item.cc_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                    //-                                     a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                    //-                                     a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                    //-                         v-col.d-flex.align-center(cols='12')
                                    //-                             v-card-subtitle.pa-0.t-black
                                    //-                                 | {{ item.email_subject }}
                                    //-                         v-col.d-none(cols='12 mt-2')
                                    //-                             p.mb-1(v-html='item.email_body')
                                    //-                     v-row.justify-end.align-center.mt-3(no-gutters)
                                    //-                         v-col.text-right(cols='12')
                                    //-                             v-btn.mr-2(@click='showEmailDetail($event)' color='primary' text) {{$t('GENERAL.SHOW_CONTENT')}}
                                    //-                             v-btn.mr-2(v-if='item.attachments.length > 0' icon @click='onEmailAttachment(item.attachments)')
                                    //-                                 v-icon(size='20') icon-attach
                                    //-                             v-btn(icon @click='onEmailContact(item.contacts)')
                                    //-                                 v-icon(size='20') icon-contact-border
                                    //- // - Time Line - SMS -
                                    //- v-tab-item.pa-3.pt-5(key='SMS' value='tab-7' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(caseTimeLines, "SMS")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('CASE_DETAIL.NO_SMS')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in caseTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'SMS'" :key='index' color='success' :icon='getTimeLineIcon(item.type)' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'SMS'" flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }}                                                      
                                    //-                         v-col(cols='12 mt-2')
                                    //-                             v-textarea.no-border.textarea-style.mb-2(v-model='item.sms_body' rows=2 readonly=true style='font-size: 14.4px;')
                                    //-                     v-row.justify-end.align-center.mt-3(no-gutters)
                                    //-                         v-col.text-right(cols='12')
                                    //-                             v-btn(icon @click='onEmailContact(item.contacts)')
                                    //-                                 v-icon(size='20') icon-contact-border
                                    //- // - Time Line activity
                                    //- v-tab-item.pa-3.pt-5(key='ACTIVITY' value='tab-8' :transition='false' :reverse-transition='false')
                                    //-     v-row.sheet-box.my-5(v-if='!hasType(activityTimeline, "ACTIVITY_FIELDHISTORY")')
                                    //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                    //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                    //-                 h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_ACTIVITY_CHANGE')}}
                                    //-     v-timeline.pt-0(v-else dense align-top)
                                    //-         template(v-for='(item, index) in caseTimeLines')
                                    //-             v-timeline-item(v-if="item.type === 'DUE_ACTIVITY'" :key='item.id' color='#FFDC35' icon='mdi-bell-ring' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'DUE_ACTIVITY'" flat outlined class="timeLine-note")
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col(cols='12')
                                    //-                             p(v-if="item.is_due < 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{(item.is_due)*-1}}{{$t("DEAL_DETAIL.SOON_DUE_1")}}
                                    //-                             p(v-else-if="item.is_due == 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{$t("DEAL_DETAIL.SOON_DUE_4")}}
                                    //-                             p(v-else).mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE_2")}}{{item.is_due}}{{$t("DEAL_DETAIL.SOON_DUE_3")}}
                                    //-         template(v-for='(item, index) in activityTimeline')
                                    //-             v-timeline-item(v-if="item.type === 'ACTIVITY_FIELDHISTORY'" :key='index' color='success' icon='icon-calendar' small right fill-dot)
                                    //-                 v-card.px-3.py-1(v-if="item.type === 'ACTIVITY_FIELDHISTORY' " flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                    //-                             span.mr-3
                                    //-                                 | {{ item.timeConvert }} ・
                                    //-                                 | {{ item.changed_by }}
                                    //-                         v-col(cols='12')
                                    //-                             p.mb-1.t-black  {{ item.content }}
                                    //- // - Time Line - file -
                                    //- //- v-tab-item.pa-3.pt-5(key='file' value='tab-6' :transition='false' :reverse-transition='false')
                                    //-     v-timeline.pt-0(dense align-top)
                                    //-         template(v-for='(item, index) in timeLine')
                                    //-             v-timeline-item(v-if="item.type === 'file'" :key='index' color='success' :icon='item.icon' small right fill-dot)
                                    //-                 v-card.px-3.py-1(flat outlined)
                                    //-                     v-row.justify-space-between.align-center(no-gutters)
                                    //-                         v-col(cols='12' md='6')
                                    //-                             v-card-subtitle.pa-0
                                    //-                                 | {{ }}
                                    //-                         v-col.f-smaller.t-lightgary.t-lightgary.detail.d-flex.align-center(cols='12' md='6')
                                    //-                             span  {{ }} ・ {{ }}
                                    //-                             v-menu(bottom left)
                                    //-                                 template(v-slot:activator='{ on, attrs }')
                                    //-                                     v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                    //-                                         v-icon(size='16')  icon-dot-3
                                    //-                                 v-list.pa-0
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(text='text')
                                    //-                                             span.ml-1 下載
                                    //-                                     v-list-item.px-0.text-center
                                    //-                                         v-btn(@click='onDetele(item)' text='text')
                                    //-                                             span.ml-1 刪除
                                // - note -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isNoteTab")
                                    v-btn(@click='onAddNote()' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1(size='14') icon-add
                                        span {{$t('CASE_DETAIL.ADD_NOTE')}}
                                v-tab-item.pa-3(key='note' value='tab-3' :transition='false' :reverse-transition='false')
                                    .editor
                                    v-row.sheet-box.my-5(v-if='!hasType(caseTimeLines, "NOTE")')
                                        v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                h5.ma-0.t-black {{$t('CASE_DETAIL.NO_NOTE')}}
                                    v-timeline.mt-5.pt-0(dense align-top)
                                        template(v-for='(item, index) in caseTimeLines')
                                            v-timeline-item(v-if="item.type === 'NOTE'" :key='index' color='success' icon='icon-note' small right fill-dot)
                                                v-card.px-3.py-1(v-if="item.type === 'NOTE'" flat outlined :class='item.pinned == true ? "timeLine-note" : ""')
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.mr-3
                                                                | {{ item.timeConvert }} ・
                                                                | {{ item.owner }}
                                                        v-col.text-right(cols='1' )
                                                            v-menu(bottom left)
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                        v-icon(size='16')  icon-dot-3
                                                                v-list.pa-0
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onPinNote(item)' text='text')
                                                                            v-icon.mr-1(v-if="item.pinned === true" size='10') fas fa-thumbtack
                                                                            span.ml-1 {{$t('GENERAL.PIN')}}
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onEditNote(item)' text='text')
                                                                            span.ml-1 {{$t('GENERAL.EDIT')}}
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onDeleteNote(item.id)' text='text')
                                                                            span.ml-1 {{$t('GENERAL.DELETE')}}
                                                        v-col(cols='12')
                                                            .timeLine-note-box(v-if='item.isMore_note')
                                                                vue-editor.can-select-text.v2e_no_border.v2e_post_max_height.ellipsis(ref='editor' v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                                //- p.mb-1  {{ showNote(item.content) }}
                                                            div(v-else)
                                                                vue-editor.can-select-text.v2e_no_border( v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                                //- p.mb-1  {{ item.content }}
                                                        v-col.d-flex.justify-end(cols='12' v-if='checkMore(item.content,300)')
                                                            v-btn.pa-0(v-hide-element='false' v-if='item.isMore_note' @click="isMore(index, 'NOTE')" color='primary' text) MORE
                                                            v-btn.pa-0(v-hide-element='false' v-else @click="isMore(index, 'NOTE')" color='primary' text) LESS
                                                //- v-card.timeLine-note.px-3.py-1(flat outlined)
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.mr-3
                                                                | {{ item.date }} ・
                                                                | {{ item.owner }}
                                                        v-col.text-right(cols='1')
                                                            v-menu(bottom left)
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                        v-icon(size='16')  icon-dot-3
                                                                v-list.pa-0
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(text='text')
                                                                            span.ml-1 編輯
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onDetele(item)' text='text')
                                                                            span.ml-1 刪除
                                                        v-col(cols='12')
                                                            p.mb-1  {{ item.content }}
                                // - mail -
                                v-tab-item.pa-3(key='mail' value='tab-4' :transition='false' :reverse-transition='false')
                                    emailEditor( :objectDetail='caseDetail' :contactsDetail='caseDetail.primary_contact' objectType='Case' :contactEmail='""' :userEmail='userEmail' @emitSendEmailDialog='onEmitSendEmailDialog')

                                // - SMS -
                                v-tab-item.pa-3(key='sms' value='tab-5' :transition='false' :reverse-transition='false')
                                    smsEditor(:objectDetail='caseDetail' :contactsDetail='caseDetail.primary_contact' objectType='Case' :contactSms='""' :userSms='userSms' @emitSendSmsDialog='onEmitSendSmsDialog')

                                // - product -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isProductTab && isOpen")
                                    v-btn(@click='onDelProduct()' width='120' height='32' color='red' plain border-0 v-if="deletrProduct")
                                        v-icon.mr-1(size='14') icon-remove
                                        span {{$t('CASE_DETAIL.DEL_PRO')}}
                                    v-btn(@click='onAddProduct()' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1(size='14') icon-add
                                        span {{$t('CASE_DETAIL.ADD_PRO')}}
                                v-tab-item.pa-3(key='product' value='tab-6' :transition='false' :reverse-transition='false')
                                    productTable(:product='caseProduct' @emitProductList='selectProductList' ,:deletrProduct='deletrProduct' @emitProductDel='productDel')
                                // - News -
                                //- v-tab-item.pa-3(key='news' value='tab-6' :transition='false' :reverse-transition='false')
                                    newsDisplay()
                                // - documents -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isdocumentTab")
                                    v-btn(@click='onDelDocument()' width='120' height='32' color='red' plain border-0 v-if="isDelDocument")
                                        v-icon.mr-1(size='14') icon-remove
                                        span {{$t('DOCUMENT.DEL')}}
                                    v-btn(@click='onAddDocument()' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1(size='14') icon-add
                                        span {{$t('DOCUMENT.ADD')}}
                                v-tab-item.pa-3(key='documents' value='tab-7' :transition='false' :reverse-transition='false')
                                    documentsTable(class_name='Case' :target_id='caseId' @emitDocumentsList='selectDocumentsList' :isDel='openDelDocumentDialog' :addDocDialog ='isAddDoucment' @emitDocDialog='onEmitDocDialog' )
                                // - activity -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isActivityTab")
                                    .btn-switch.ml-3.mb-3
                                        v-btn(v-hide-element='false' :ripple='false' @click='activity_mode = "list"' width='30%' height='24' :color=" activity_mode == 'list'? 'primary':'white'"  depressed tile :dark='activity_mode != "list"')
                                            v-icon(size='14') icon-list
                                        v-btn(v-hide-element='false' :ripple='false' @click='activity_mode = "calender"' width='30%' height='24' :color=" activity_mode == 'calender' ? 'primary' : 'white' " depressed tile :dark='activity_mode != "calender"')
                                            v-icon.icon-calendar.mt-1(size='16')
                                    v-btn(@click='onAddActivity()' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1.mb-1(size='16') icon-add
                                        span.mb-1 {{$t('ACTIVITY.ADD')}}
                                v-tab-item.pa-3(key='activity' value='tab-8' :transition='false' :reverse-transition='false')
                                    activitiesTable(:isAddActivity='isAddActivity' @emitAddActivity='onEmitAddActivity' :target_id='caseId' :mode='activity_mode' :target_name='caseDetail.subject' ,from_page='case' ,:activity_org='activity_org' ,:activity_contact='activity_contact')
                    v-col.pt-4.pt-md-0.pl-md-4.min-pad-scroll(cols='12' md='4')
                        v-card.contact-card.mb-6(flat)
                            v-card-title.d-flex.justify-space-between.align-center.pb-1.pt-2
                                span {{$t('CASE_DETAIL.PRIMARY_CONTACT_CASE')}}
                            v-card-text.py-0.mb-4
                                v-text-field.mt-2(v-model='searchCases' flat dense solo background-color='#ecedf3' prepend-inner-icon='icon-search')
                                v-row.sheet-box.my-2(v-if='primary_contact_case_set == ""')
                                    v-col.d-flex.align-center.justify-center.pa-0( cols='12' )
                                        v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                            h5.ma-0.t-black {{$t('CASE_DETAIL.NO_RELATED_CASE')}}
                                v-data-table.table_in_tab(v-else :headers='casesHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='primary_contact_case_set' :search='searchCases' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' multi-sort height='100%' hide-default-footer='hide-default-footer')
                                    template(v-slot:item.priority_number='{ item }')
                                        v-icon(v-if="item.priority_number == '5'" :title='item.priority' size='20' color='red') fas fa-angle-double-up
                                        v-icon(v-if="item.priority_number == '4'" :title='item.priority' size='20' color='red') fas fa-angle-up
                                        v-icon(v-if="item.priority_number == '3'" :title='item.priority' size='20' color='orange') fas fa-equals
                                        v-icon(v-if="item.priority_number == '2'" :title='item.priority' size='20' color='primary') fas fa-angle-down
                                        v-icon(v-if="item.priority_number == '1'" :title='item.priority' size='20' color='primary') fas fa-angle-double-down
                                    template(v-slot:item.subject='{ item }')
                                        .ellipsis
                                            a.pl-2(:title='item.subject' :href='"/Case/" + item.id' ) {{ item.subject }}
                                    template(v-slot:item.statusName='{ item }')
                                        v-chip.my-2.text-white(v-if='item.status == "RESOLVED"' color='green' small) {{ item.statusName }}
                                        v-chip.my-2.text-white(v-else-if='item.status == "CLOSED"' color='primary' small) {{ item.statusName }}
                                        v-chip.my-2.text-black(v-else small) {{ item.statusName }}
                                    template(v-slot:item.closed_time='{ item }')
                                        .ellipsis
                                            span(v-if='item.closed_time' :title='item.closed_time') {{ item.closed_time }}
                                    //- template(v-slot:item.current_stage.sequence_number='{ item }')
                                        .ellipsis
                                            span(:title='item.current_stage.stage_name') {{ item.current_stage.stage_name }}
                                    //- template(v-slot:item.group='{ item }')
                                        .ellipsis
                                            span(v-if='item.group' :title='item.groupName') {{ item.groupName }}
                                    //- template(v-slot:item.type='{ item }')
                                        .ellipsis
                                            span(:title='item.typeName' v-if='item.typeNam') {{ item.typeName }}
                                v-pagination.py-1.mb-3(v-if='primary_contact_case_set != ""' v-model='page' :length='pageCount' circle :total-visible="10")
                        v-card.contact-card.mb-6(flat)
                            v-card-title.d-flex.justify-space-between.align-center.pb-1.pt-2
                                span {{$t('CASE_DETAIL.RELATED_CONTACT')}}
                                v-btn.pa-0(v-hide-element='false' v-if='isOpen' @click='onRelatedContacts(caseContacts.contacts)' color='primary' text) {{$t('GENERAL.EDIT')}}
                            v-card-text.py-0.mb-4
                                v-row.sheet-box.my-2(v-if='caseContacts.contacts == ""')
                                    v-col.d-flex.align-center.justify-center.pa-0( cols='12' )
                                        v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                            h5.ma-0.t-black {{$t('CASE_DETAIL.NO_RELATED_CONTACT')}}
                                v-list-item.px-0.py-2(v-else v-for='item in caseContacts.contacts' :key='item.name')
                                    v-list-item-avatar.ma-0.mr-3(color='blue-grey lighten-5' size='40')
                                        span.mx-auto(v-if="item.image === null") {{ item.last_name }}
                                        img(v-else :src='item.image' :alt='item.image')
                                    v-list-item-content
                                        v-list-item-title.d-flex.justify-space-between.align-Start
                                            v-row.w-100
                                                v-col(cols='4').pl-0.ellipsis
                                                    a.f-text-highlight.text-subtitle-2.ellipsis.mb-0(:href='"/Contacts/"+item.id' v-if='item.first_name == null') {{ item.last_name }}
                                                    a.f-text-highlight.text-subtitle-2.ellipsis.mb-0(:href='"/Contacts/"+item.id' v-else) {{ item.last_name+" "+item.first_name }}
                                                v-col(vols='8').ellipsis
                                                    .d-flex.justify-end.align-center
                                                        span.job-label.ml-3.d-block.ellipsis(v-if='!!item.organization_set[0]')  {{ item.organization_set[0].name }}
                                                        span.job-label.ml-3.d-block.ellipsis(v-if='!!item.title')  {{ item.title }}
                                        v-list-item-subtitle
                                            p.my-2.ellipsis(v-if='!!item.mobile_phone')
                                                v-icon.mr-1(size='14' color='gary') icon-cell
                                                a.t-black(:href='onCall(item.mobile_phone)') {{ item.mobile_phone }}
                                            p.ma-0.ellipsis(v-if='!!item.email')
                                                v-icon.mr-1(size='14' color='gary') icon-mail
                                                a.t-black(:href='onMail(item.email)') {{ item.email }}
                        v-card.contact-card.mb-6(flat)
                            v-card-title.d-flex.justify-space-between.align-center.pb-1.pt-2
                                span {{$t('CASE_DETAIL.CHILD_CASE')}}
                                div
                                    v-btn.pa-0(v-if='isOpen' @click='openAddNewChildCase' color='primary' text) {{$t('GENERAL.ADD')}}
                                    v-btn.pa-0(v-hide-element='false' v-if='isOpen' @click='childCasesDialog = true' color='primary' text) {{$t('GENERAL.EDIT')}}
                            v-card-text.py-0.mb-4
                                v-text-field.mt-2(v-model='searchChildCases' flat dense solo background-color='#ecedf3' prepend-inner-icon='icon-search')
                                v-row.sheet-box.my-2(v-if='childCases == ""')
                                    v-col.d-flex.align-center.justify-center.pa-0( cols='12' )
                                        v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                            h5.ma-0.t-black {{$t('CASE_DETAIL.NO_CHILD_CASE')}}
                                v-data-table.table_in_tab(v-else :headers='childCasesHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :search='searchChildCases' :items='childCases' :items-per-page='itemPerPage' :page.sync='page_childcase' @page-count='pageCount_childcase = $event' multi-sort height='100%' hide-default-footer='hide-default-footer')
                                    template(v-slot:item.subject='{ item }')
                                        .ellipsis
                                            a.pl-2(:title='item.subject' :href='"/Case/" + item.id' ) {{ item.subject }}
                                    template(v-slot:item.priority_number='{ item }')
                                        v-icon(v-if="item.priority_number == '5'" :title='item.priorityName' size='20' color='red') fas fa-angle-double-up
                                        v-icon(v-if="item.priority_number == '4'" :title='item.priorityName' size='20' color='red') fas fa-angle-up
                                        v-icon(v-if="item.priority_number == '3'" :title='item.priorityName' size='20' color='orange') fas fa-equals
                                        v-icon(v-if="item.priority_number == '2'" :title='item.priorityName' size='20' color='primary') fas fa-angle-down
                                        v-icon(v-if="item.priority_number == '1'" :title='item.priorityName' size='20' color='primary') fas fa-angle-double-down
                                    template(v-slot:item.statusName='{ item }')
                                        v-chip.my-2.text-white(v-if='item.statusName == $i18n.t("CASESTATUSLIST.RESOLVED")' color='green' small) {{ item.statusName }}
                                        v-chip.my-2.text-white(v-else-if='item.statusName == $i18n.t("CASESTATUSLIST.CLOSED")' color='primary' small) {{ item.statusName }}
                                        v-chip.my-2.text-black(v-else small) {{ item.statusName }}
                                    template(v-slot:item.closed_time='{ item }')
                                        .ellipsis
                                            span(v-if='item.closed_time' :title='item.closed_time') {{ item.closed_time }}
                                v-pagination.py-1.mb-3(v-if='childCases != ""' v-model='page_childcase' :length='pageCount_childcase' circle :total-visible="10")
                        v-card.contact-card(flat)
                            v-card-title.d-flex.justify-space-between.align-center.pb-1.pt-2
                                span {{$t('CASE_DETAIL.KNOWLEDGE_ARTICLE')}}
                            v-card-text.py-0
                                v-text-field.mt-2(v-model='searchArticles' flat dense solo background-color='#ecedf3' prepend-inner-icon='icon-search')
                                v-tabs.main-tabs(background-color='transparent' show-arrows)
                                    v-tabs-slider
                                    v-tab(v-for='item in article_tabs' :key='item.key' :href='`#tab-${item.id}`' @click='getTabName(item.key)')
                                        | {{ item.name }}
                                    // - Related -
                                    v-tab-item.pa-3(key='related' value='tab-1' :transition='false' :reverse-transition='false')
                                        v-row.sheet-box.my-2(v-if='relatedArticleList.length == 0 && !isSearch')
                                            v-col.d-flex.align-center.justify-center.pa-0( cols='12' )
                                                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                    h5.ma-0.t-black {{$t('CASE_DETAIL.NO_RELATED_ARTICLE')}}
                                        v-row.sheet-box.my-2.align-items-center.justify-content-center(v-if='isSearch')            
                                            v-progress-circular(indeterminate color='primary')
                                        v-list-item.px-0.py-2(v-if='relatedArticleList.length != 0 && !isSearch' v-for='(item, index) in relatedArticleList' :key='item.id')
                                            v-list-item-content.p-0
                                                v-list-item-title.d-flex.align-Start
                                                    v-row.w-100.mb-1
                                                        v-col(cols='12').pl-0.pb-0.ellipsis.d-flex.align-items-center
                                                            a.f-text-highlight.text-subtitle-2.ellipsis.mb-0(:href='"/KnowledgeArticle/" + item.id' :title='item.title')
                                                                h5.m-0 {{ item.title }}
                                                        v-col(cols='12').pl-0.d-flex
                                                            span.job-label.d-block.ellipsis(v-if='!!item.subject_name')  {{ item.subject_name }}
                                                        v-btn.pa-0(v-if='!isRelatedExpend[index]' @click='onExpend(index, "RELATED")' color='primary' text)
                                                            v-icon mdi-chevron-down
                                                            span {{$t('CASE_DETAIL.EXPAND')}}
                                                        v-btn.pa-0(v-else @click='onCloseUp(index, "RELATED")' color='primary' text)
                                                            v-icon mdi-chevron-up
                                                            span {{$t('CASE_DETAIL.COLLAPSE')}}
                                                        v-btn.pa-0(color='red' @click='onUnbindArticle(item, index, "RELATED")' text)
                                                            v-icon(size='20') mdi-bookmark-remove
                                                            span {{$t('CASE_DETAIL.REMOVE')}}
                                                v-list-item-subtitle
                                                    vue-editor.min-editor.can-select-text(v-if='!isRelatedExpend[index]' v-model='item.content' :disabled='true' :editorOptions='editorOptions')
                                                    vue-editor.can-select-text(v-else v-model='item.content' :disabled='true' :editorOptions='editorOptions')
                                        v-pagination.py-1(v-if='relatedArticleList.length != 0' v-model='relatedArticleListPage' :total-visible="10" :length='relatedArticleListLength' circle @input='onChangeRelatedArticlePage')
                                    // - All -
                                    v-tab-item.pa-3(key='all' value='tab-2' :transition='false' :reverse-transition='false')
                                        v-row.sheet-box.my-2(v-if='articleList.length == 0 && !isSearch')
                                            v-col.d-flex.align-center.justify-center.pa-0( cols='12' )
                                                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                    h5.ma-0.t-black {{$t('CASE_DETAIL.NO_RELATED_ARTICLE')}}
                                        v-row.sheet-box.my-2.align-items-center.justify-content-center(v-if='isSearch')            
                                            v-progress-circular(indeterminate color='primary')
                                        v-list-item.px-0.py-2(v-if='!isSearch' v-for='(item, index) in articleList' :key='item.id')
                                            v-list-item-content.p-0
                                                v-list-item-title.d-flex.justify-space-between.align-Start
                                                    v-row.w-100.mb-1
                                                        v-col(cols='12').pl-0.pb-0.ellipsis.d-flex.align-items-center
                                                            a.f-text-highlight.text-subtitle-2.ellipsis.mb-0(:href='"/KnowledgeArticle/" + item.id' :title='item.title')
                                                                h5.m-0 {{ item.title }}
                                                        v-col(cols='12').pl-0.d-flex
                                                            span.job-label.d-block.ellipsis(v-if='!!item.subject_name')  {{ item.subject_name }}
                                                        v-btn.pa-0(v-if='!isAllExpend[index]' @click='onExpend(index, "ALL")' color='primary' text)
                                                            v-icon mdi-chevron-down
                                                            span {{$t('CASE_DETAIL.EXPAND')}}
                                                        v-btn.pa-0(v-else @click='onCloseUp(index, "ALL")' color='primary' text)
                                                            v-icon mdi-chevron-up
                                                            span {{$t('CASE_DETAIL.COLLAPSE')}}
                                                        v-btn.pa-0(v-if='isRelated[index]' @click='onUnbindArticle(item, index, "ALL")' color='red' text)
                                                            v-icon(size='20') mdi-bookmark-remove
                                                            span {{$t('CASE_DETAIL.REMOVE')}}
                                                        v-btn.pa-0(v-else @click='onBindArticle(item, index)' color='primary' text)
                                                            v-icon(size='20') mdi-bookmark-plus
                                                            span {{$t('CASE_DETAIL.ADD')}}
                                                v-list-item-subtitle
                                                    vue-editor.min-editor.can-select-text(v-if='!isAllExpend[index]' v-model='item.content' :disabled='true' :editorOptions='editorOptions')
                                                    vue-editor.can-select-text(v-else v-model='item.content' :disabled='true' :editorOptions='editorOptions')
                                        v-pagination.py-1(v-if='articleList.length != 0' v-model='articleListPage' :total-visible="10" :length='articleListLength' circle @input='onChangeAllArticlePage')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        //-change own or process
        v-dialog(v-model='changeOwnDialog' max-width='500' v-if='changeOwnDialog')
            change-own(@emitChangeOwnDialog='onEmitChangeOwnDialog' :owner-data='changeOwnData' :other-data='otherData' change='cases')
        v-dialog(v-model='changeProcessDialog' max-width='500' v-if='changeProcessDialog')
            change-process(@emitChangeProcessDialog='onEmitChangeProcessDialog' @emitChangeProcessData='onEmitChangeProcessData' :process-data='processData')
        //- case contacts
        v-dialog(v-model='relatedContactsDialog' width='1050' content-class='contact-dialog' v-if='relatedContactsDialog')
            related-contacts(@emitRelatedContactsDialog='onEmitRelatedContactsDialog' @MainContact='onEmitRelatedContactMessage($event)' objectType='Case' :contact-data='relatedContactData')
        //- child cases
        v-dialog(v-model='childCasesDialog' width='1050' content-class='childcase-dialog' v-if='childCasesDialog')
            child-cases(@emitChildCasesDialog='onEmitChildCasesDialog' @emitChildCases='onEmitChildCases' :child-case-data='childCases' :parent-case-id='caseDetail.parent_case_id')
        //- about note
        v-dialog(v-model='addNoteDialog' max-width='500' v-if='addNoteDialog')
            add-note(@emitAddNoteDialog='onEmitAddNoteDialog' @emitAddNoteData='onEmitAddNoteData' :note-data='noteData')
        v-dialog(v-model='editNoteDialog' max-width='500' v-if='editNoteDialog')
            edit-note(@emitEditNoteDialog='onEmitEditNoteDialog' :note-data='noteData')
        //- in timeline about email and sms contacts, opportunities, attachments
        v-dialog(v-model='emailContactsDialog' max-width='300' v-if='emailContactsDialog' scrollable)
            email-contacts-dialog(@emitEmailContactsDialog='onEmitEmailContactsDialog' :email-contact-data='emailContactData')
        v-dialog(v-model='emailOpptiesDialog' max-width='300' v-if='emailOpptiesDialog' scrollable)
            email-oppties-dialog(@emitEmailOpptiesDialog='onEmitEmailOpptiesDialog' :email-oppties-data='emailOpptiesData')
        v-dialog(v-model='emailAttachmentsDialog' max-width='300' v-if='emailAttachmentsDialog' scrollable)
            email-attachments-dialog(@emitEmailAttachmentsDialog='onEmitEmailAttachmentsDialog' :email-attachment-data='emailAttachmentData')
        //- add case
        v-dialog(v-model='addNewChildCaseDialog' max-width='600' v-if='addNewChildCaseDialog')
            add-case(@emitAddCaseDialog='onEmitAddChildCaseDialog' @emitNewCaseData='onEmitNewCaseData' :add-case='addCaseData')
        //- copy case
        v-dialog(v-model='addCaseDialog' max-width='600' v-if='addCaseDialog')
            add-case(@emitAddCaseDialog='onEmitAddCaseDialog' @emitNewCaseData='onEmitNewCaseData' :add-case='addCaseData')
        v-dialog(v-model='redirectDialog' width='360' content-class='statusDialog')
            redirect-dialog(@emitRedirectDialog='onEmitRedirectDialog' :message='message' :copyDataType='"case"' :copyDataId='copyDataId')
        //- close case
        v-dialog(v-model='loseReason' :width ='loseDealData.childCases == null ? 400 : 700' v-if='loseReason')
            lose-reason(@emitLoseReasonDialog='onEmitLoseReasonDialog' @emitCloseReasonData='onEmitCloseReasonData' :lose-deal='loseDealData')
        v-dialog(v-model='addprodcut' max-width='1000' v-if='addprodcut')
          addProduct(@emitAddCaseProductDialog='onEmitAddCaseProductDialog',:caseProductList='caseProductID' :caseId='caseId' )
        //- v-dialog(v-model='activityDialog' width='470' data-app='true')
        //-     activity-dialog(@emitActivityDialog='onEmitActivityDialog')
</template>

<script>
    // dialog
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';

    import changeOwn from '@/components/Dialog/changeOwn';
    import changeProcess from '@/components/Dialog/Deal/changeProcess';
    
    import relatedContacts from '@/components/Dialog/Contact/relatedContacts';

    import childCases from '@/components/Dialog/Case/childCases';

    import addNote from '@/components/Dialog/addNote';
    import editNote from '@/components/Dialog/editNote';
    
    import emailContactsDialog from '@/components/Dialog/emailContactsDialog';
    import emailOpptiesDialog from '@/components/Dialog/emailOpptiesDialog';
    import emailAttachmentsDialog from '@/components/Dialog/emailAttachmentsDialog';
    
    import addProduct from '@/components/Dialog/Case/addCaseProduct';
    import addCase from '@/components/Dialog/Case/addCase';
    import redirectDialog from '@/components/Dialog/redirectDialog';

    import documentsTable from "@/components/Common/documentsTable";
    import loseReason from '@/components/Dialog/Deal/loseReason';
    // component
    import emailEditor from '@/components/Common/emailEditor';
    import smsEditor from '@/components/Common/smsEditor';
    import customField from "@/components/Common/customField";
    import productTable from "@/components/Common/productTable";
    import activitiesTable from "@/components/Common/activitiesTable";
    import followBtn from "@/components/Common/followBtn";
    // list
    import caseSourceList from "@/array/the_caseSourceList";
    import caseStatusList from "@/array/the_caseStatusList";
    import casePriorityList from "@/array/the_casePriorityList";
    import closeCaseReasonList from "@/array/the_closeCaseReasonList";
    // dataservice
    import CaseDataService from '../services/CaseDataService';
    import OrganizationDataService from '../services/OrganizationDataService';
    import ContactDataService from '../services/ContactDataService';
    import NoteDataService from "../services/NoteDataService";
    import AutoNumberService from "@/services/AutoNumberService";
    import CampaignDataService from "@/services/CampaignDataService";
    import KnowledgeArticleDataService from "@/services/KnowledgeArticleDataService";
    import O365Service from "@/services/O365Service";
    import ActivityDataService from '../services/ActivityDataService';
    import PersonalSettingService from '../services/PersonalSettingService';
    //plug in
    import { VueEditor } from "vue2-editor";
    import i18n from '/common/plugins/vue-i18n.js';

    // import newsDisplay from '@/components/Common/newsDisplay';
    // import activityDialog from '@/components/Dialog/activityDialog';
    export default {
        name: 'Deals',
        components: {
            // dialog
            addProduct,
            messageDialog,
            successDialog,
            errorDialog,
            changeOwn,
            changeProcess,
            relatedContacts,
            childCases,
            addNote,
            editNote,
            emailContactsDialog,
            emailOpptiesDialog,
            emailAttachmentsDialog,
            documentsTable,
            addCase,
            redirectDialog,
            loseReason,
            activitiesTable,
            // component
            emailEditor,
            smsEditor,
            customField,
            productTable,
            followBtn,
            //plug in
            VueEditor,

            // newsDisplay,
            // activityDialog,
        },
        data() {
            return {
            // vue editor
            editorSettings_no_tool: {
                modules: {
                    imageResize: false,
                    toolbar:false
                    },
            },
            // created
            loading: true,
            caseDetail: {owner:{email:''}},
            breadcrumbs: [],
            closeReasonName: null,
            isPhoto: false,
            isOpen: false,

            // validation
            valid: true,
            validForm: {
                basic: true,
                detail: true,
            },

            // dialog
            addprodcut:false,
            messageDialog: false,
            message: '',
            msgDialogSituation: '',
            successDialog: false,
            errorDialog: false,
            errorMessage: '',
            changeOwnDialog: false,
            changeOwnData: {},
            otherData: {},
            changeProcessDialog: false,
            processData: {},
            relatedContactsDialog: false,
            relatedContactData: {},
            addNewChildCaseDialog: false,
            childCasesDialog: false,
            addNoteDialog: false,
            editNoteDialog: false,
            noteData: {},
            emailContactsDialog: false,
            emailContactData: null,
            emailOpptiesDialog: false,
            emailOpptiesData: null,
            emailAttachmentsDialog: false,
            emailAttachmentData: null,
            addCaseDialog: false,
            addCaseData: {},
            redirectDialog: false,
            copyDataId: null,            
            loseReason: false,
            loseDealData: {},
            StageTypeId:null,
            // activityDialog: false,
            // component
            userEmail: '',
            userSms: '',
            customFieldData: [],
            cFEditable: true,

            // list
            campaignList:[],
            closeCaseReasonList: closeCaseReasonList,
            caseSourceList: caseSourceList,
            caseRelatedStatusList: caseStatusList,
            caseStatusList: [
                { key: "OPEN", name: i18n.t('CASESTATUSLIST.OPEN') },
                { key: "PENDING", name: i18n.t('CASESTATUSLIST.PENDING') },
                { key: "WAIT_CUSTOMER", name: i18n.t('CASESTATUSLIST.WAIT_CUSTOMER') },
                { key: "WAIT_3RD_PARTY", name: i18n.t('CASESTATUSLIST.WAIT_3RD_PARTY') },
                { key: "RESOLVED", name: i18n.t('CASESTATUSLIST.RESOLVED') },
            ],
            casePriorityList: casePriorityList,
            
            // form rule
            formRule: {
                contactnNameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME_2'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                ],
                nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_NAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                contactRules: [(v) => !!v || i18n.t('RULE.RULE_R_Case_Contact')],
                customerBudgetRule: [
                    (v) => /^\d*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
                    (v) => /^.{0,17}$/.test(v) || i18n.t('RULE.RULE_17'),
                ],
                amountRule: [
                    (v) => /^\d{1,17}(\.\d{0,2})?$/.test(v) || i18n.t('RULE.RULE_R_NUM_2')
                ],
                textRule: [(v) => (v || '').length <=90 || i18n.t('RULE.RULE_90')],	
                contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                phoneRules: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
                emailRules: [
                    (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
                    (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                ],
                companyRule: [
                    // (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
                    (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                    (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
                ],
            },

            // header
            haveTip: false,
            score:0,

            // process bar
            allStage: 0,
            currentStep: 0,
            caseSummary: {},
            isFirst: true,
            isLast: true,
            stepContent: false,
            stageData: {},
            
            // field history
            dataCheck: [],
            check: [],

            // edit
            isProcessEdit: true,

            // process status
            customStagecolor:'customcolor',
            // isProcessResolved: false,
            isProcessClosed: false,
            isProcessComplete: false,
            
            // tab
            tabs: [
                {
                    id: 1,
                    key: 'detail',
                    name: i18n.t('CASE_DETAIL.DETAIL')
                },
                {
                    id: 2,
                    key: 'timeline',
                    name: i18n.t('CASE_DETAIL.TIMELINE')
                },
                {
                    id: 3,
                    key: 'note',
                    name: i18n.t('CASE_DETAIL.NOTE')
                },
                {
                    id: 4,
                    key: 'mail',
                    name: i18n.t('CASE_DETAIL.EMAIL_2')
                },
                {
                    id: 5,
                    key: 'sms',
                    name: i18n.t('CASE_DETAIL.SMS')
                },
                {
                    id: 6,
                    key: 'product',
                    name: i18n.t('CASE_DETAIL.PRO')
                },
                {
                    id: 7,
                    key: 'documents',
                    name: i18n.t('DOCUMENT.TITLE')
                },
                {
                    id: 8,
                    key: 'activities',
                    name: i18n.t('Bower_Title.Activity')
                }
            ],
            isNoteTab: false,
            isProductTab: false,

            // article tabs
            article_tabs: [
                {
                    id: 1,
                    key: 'related',
                    name: i18n.t('CASE_DETAIL.RELATED_ARTICLE')
                },
                {
                    id: 2,
                    key: 'all',
                    name: i18n.t('CASE_DETAIL.ALL_ARTICLE')
                },
            ],

            // knowledge article
            relatedArticleList: [],
            showRelatedArticleList: [],
            originRelatedArticleList: [],
            articleList: [],
            editorOptions: {
                modules: {
                    toolbar: false,
                },
            },
            // subjectList: [],
            isRelatedExpend: [],
            isAllExpend: [],
            isRelated: [],
            searchArticles: '',
            isSearch: false,
            isSearched: false,
            articleListPage: 1,
            relatedArticleListPage: 1,
            articleListLength: 0,
            relatedArticleListLength: 0,

            // timeline
            timeLineType: [
                {
                    id: 1,
                    key: 'all',
                    name: i18n.t('GENERAL.ALL')
                },
                {
                    id: 2,
                    key: 'stepChange',
                    name: i18n.t('CASE_DETAIL.STAGE_CHANGE')
                },
                {
                    id: 3,
                    key: 'statusChange',
                    name: i18n.t('CASE_DETAIL.STATUS_CHANGE')
                },
                {
                    id: 4,
                    key: 'fieldHistory',
                    name: i18n.t('CASE_DETAIL.FIELD_CHANGE')
                },
                {
                    id: 5,
                    key: 'note',
                    name: i18n.t('CASE_DETAIL.NOTE')
                },
                {
                    id: 6,
                    key: 'mail',
                    name: i18n.t('CASE_DETAIL.EMAIL_2')
                },
                {
                    id: 7,
                    key: 'sms',
                    name: i18n.t('CASE_DETAIL.SMS')
                },
                {
                    id: 8,
                    key: 'activity',
                    name: i18n.t('Bower_Title.Activity')
                }
            ],
            caseTimeLines: [],
            timeline_tab: ['all'],
            timelinetabData: {},
            showTimelines:[],

            // case contacts
            caseContacts: {},

            // 主要聯絡人相關案件
            primary_contact_case_set: [],
            casesHeaders: [
                    {
                        text: i18n.t('CASE_DETAIL.SUBJECT'),
                        value: 'subject',
                        // width: '14%',
                        align: 'center'
                    },
                    {
                        text: i18n.t('CASE_DETAIL.PRIORITY'),
                        value: 'priority_number',
                        // width: '10%',
                        align: 'center',
                    },
                    {
                        text: i18n.t('CASE_DETAIL.STATUS'),
                        value: 'statusName',
                        // width: '10%',
                        align: 'center'
                    },
                    {
                        text: i18n.t('CASE_DETAIL.CLOSED_DATE'),
                        value: 'closed_time',
                        // width: '10%',
                        align: 'center'
                    },
                ],
                itemPerPage: 5,
                page: 1,
                pageCount: 0,
                searchCases: '',

            //子案件 child cases
            childCasesHeaders: 
            [
                {
                    text: i18n.t('CASE_DETAIL.SUBJECT'),
                    value: 'subject',
                    align: 'center',
                },
                {
                    text: i18n.t('CASE_DETAIL.PRIORITY'),
                    value: 'priority_number',
                    align: 'center',
                },
                {
                    text: i18n.t('CASE_DETAIL.STATUS'),
                    value: 'statusName',
                    align: 'center',
                },
                {
                    text: i18n.t('CASE_DETAIL.CLOSED_DATE'),
                    value: 'closed_time',
                    align: 'center',
                },
            ],
            page_childcase: 1,
            pageCount_childcase: 0,
            searchChildCases: '',
            parentCaseSelectList:[],
            childCases: [],
            sequence_number: 0,

            // note
            pinning: true,

            // 詳細資訊 edit
            autoNumberCheck:null,
            loading_caseDeatil: false,
            isDetailReadonly: true,
            isNew: {
                primary_contact: false,
                case_org: false,
            },
            isLoading: {
                primary_contact: false,
                case_org: false,
                parent_case: false
            },
            comboInput:{
                primary_contact: null,
                case_org: null,
                parent_case: null
            },
            newPri:{
                fName: null,
                lName: null,
            },
            newOppCon:{
                fName: null,
                lName: null,
            },
            search_primary_contact: null,
            search_case_org: null,
            search_oppty_contact: null,
            search_parent_case: null,
            menu: false,
            created_on: null,
            contactData: [],
            organizationList: [],
            caseGroupList: [],
            caseTypeList: [],
            caseCategoryList: [],

            // original data
            origincaseDetail: {},

            // funtions
            succMode: '',
            allProcess: [],

            // alert
            alertNote: [],
            getfolder: true,

            // mobile phone
            isNumVaild: true,
            vue_tel: {
                isValid: true,
            },
            caseProduct: [],
            caseProductID:[],
            deleteProductData:[],
            caseId:this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id'),
            deletrProduct:false,
            //document
            isdocumentTab:false,
            isDelDocument:false,
            openDelDocumentDialog:false,
            isAddDoucment:false,
            user_list:[],
            // activity
            activity_mode:"list",
            isActivityTab:false,
            isAddActivity:false,
            activityTimeline:[],
            originTimeLines:[],
            due_activity_list:[],
            full_name: "",
            activity_org:null,
            activity_contact:null,
            // follow
            isFollow:false,
            timeline_count:0,
            timeline_show_count:0,
            tab_height:'',
        }
    },
    async created(){
        await (this.uuidCheck(this.caseId))
        this.getLoginUserEmail();
        CaseDataService.getcasegrouplist()
        .then(response => {
            this.caseGroupList = response.data;
        });

        CaseDataService.getcasetypelist()
        .then(response => {
            this.caseTypeList = response.data;
        });

        CaseDataService.getcasecategorylist()
        .then(response => {
            this.caseCategoryList = response.data;
        });

        await CaseDataService.detail(this.caseId)
        .then(response => {
            this.caseDetail = response.data;
            this.isFollow = this.caseDetail.follow;
            this.caseDetail.priority_number = this.getPriorityNumber(this.caseDetail.priority)
            this.caseProduct = response.data.product;
            this.caseProduct.forEach(data=>{
                this.caseProductID.push(data.id)
            })
            // this.caseId = this.$route.params.id;
            const title = localStorage.getItem('title');
            document.title = title+' : '+this.caseDetail.subject +' – UpDay';
            this.StageTypeId = this.caseDetail.stage_type.id
            // process
            this.allProcess = response.data.summary;
            this.allStage = this.allProcess.length;
            // agent data
            this.changeOwnData = this.caseDetail.owner;
            // check timeline after edit
            this.check = [ this.caseDetail.subject, this.caseDetail.primary_contact, this.caseDetail.status, this.caseDetail.priority, this.caseDetail.group, this.caseDetail.type, this.caseDetail.due_date,];
            // if case closed get close reason
            if (this.caseDetail.closed_reason != null) {
                this.closeReasonName = this.getItemNameFromList(this.closeCaseReasonList, this.caseDetail.closed_reason);
            } else {
                this.isOpen = true;
            }
            // 案件相關聯絡人
            this.caseContacts.contacts = response.data.contacts;
            // 主要聯絡人相關案件
            this.primary_contact_case_set = this.caseDetail.primary_contact.case_set;
            let n = this.primary_contact_case_set.findIndex(c => {return c.id == this.caseDetail.id});
            this.primary_contact_case_set.splice(n, 1);
            this.primary_contact_case_set.forEach(caseItem => {
                caseItem.statusName = this.getItemNameFromList(this.caseRelatedStatusList, caseItem.status);
                caseItem.priority_number = this.getPriorityNumber(caseItem.priority)
                if (caseItem.closed_date != null) {
                    caseItem.closed_time = this.getFormatTime(new Date(caseItem.closed_date))
                }
            });
            // 知識庫文章
            this.originRelatedArticleList = response.data.knowledge_articles;

            // 建立日期
            this.created_on = new Date(this.caseDetail.created_on).toLocaleString();

            // custom field is editable or not
            if (response.data.closed_reason != null ) {
                this.cFEditable = false;
            }

            this.breadcrumbs = [
                {
                    text: i18n.t('BREADCRUMBS.Case'),
                    disabled: false,
                    href: '/Cases',
                },
                {
                    text: this.caseDetail.subject,
                    disabled: true,
                    href: 'Case',
                },
            ]

            if(this.caseDetail.custom_field != null){
                this.customFieldData =  this.caseDetail.custom_field;
            }

            if (this.caseDetail.closed_reason != null) {
                this.caseDetail.closed_date = this.getFormatTime(new Date(this.caseDetail.closed_date))
                this.isProcessEdit = false;
                this.isProcessComplete = true;
                this.isProcessClosed = true;
            }
        })
        .catch(response => {
            this.showErrorDialog(response);
            // Redirect to PermissionError page if error status is 403
            if (response.response.status == 403)
            {
                this.$router.replace('/PermissionError');
            }
            else if(response.response.status == 404)
            {
                this.$router.replace('/PageNotFound');
            }
        });

        await AutoNumberService.list()
        .then(response => {
            this.autoNumberCheck = response.data.filter(el => {return el.using_page == "CASE"})[0].active;
        })
        .catch((response) => {
            this.showErrorDialog(response);
        });

        this.getRelatedArticle();

        this.getAllArticle();

        if (this.caseDetail.owner.usersetting.photo != null) {
            this.isPhoto = true;
        }

        await (this.currentStep = this.allProcess.find(d=> d.id == this.caseDetail.current_stage).sequence_number);
        await this.setCaseSummary();
        this.currentStep = this.currentStep - 1;
        await OrganizationDataService.getchangeowner('cases')
            .then((respose)=>{
                this.user_list = respose.data;
                this.user_list.forEach(item => {
                    item.fullName = item.last_name + ' ' + item.first_name;
                });
            });
            
        this.activity_org = this.caseDetail.case_org;
        this.activity_contact = this.caseDetail.primary_contact;
        await this.getCaseTimeline(this.caseId);
        await this.getActivityTimeline();

        await CampaignDataService.selectlist().then((response)=>{
            this.campaignList = response.data;
        })
        // Get Chil dCase
        this.childCases = this.caseDetail.child_case
        if(this.childCases.length > 0){
            this.childCases.forEach(item => {
            item.priority_number = this.getPriorityNumber(item.priority)
            item.priorityName = this.getItemNameFromList(this.casePriorityList, item.priority)
            item.statusName = this.getItemNameFromList(this.caseRelatedStatusList, item.status)
            item.groupName = this.getItemFromListById(this.caseGroupList, item.group).group_name;
            item.typeName = this.getItemFromListById(this.caseTypeList, item.type).type_name;
            item.owner.fullName = item.owner.first_name != '' ? item.owner.last_name + ' ' + item.owner.first_name : item.owner.last_name;
            if (item.closed_date != null) {
                item.closed_time = this.getFormatTime(new Date(item.closed_date));
            }
        })
        }
        await PersonalSettingService.getCurrentUserDetail()
        .then(response => {
            if(response.data[0]['usersetting']['timeline_tab'] != null){
                if(response.data[0]['usersetting']['timeline_tab']['Case'] != undefined){
                    this.timeline_tab = response.data[0]['usersetting']['timeline_tab']['Case'];
                }
                else{
                    this.timeline_tab = ['all'];
                }
            }
            else{
                this.timeline_tab = ['all'];
            }
        })
        .catch(response => {
            this.showErrorDialog(response);
        });
        await this.showTimlinesCheck();
        this.loading = false;
        window.addEventListener('resize', this.onResize)
        this.onResize();
        setTimeout(()=>{
            let main_tab = document.getElementById('main_tab');
            this.tab_height = `max-height :${main_tab.offsetHeight - 47}px;`;
        },1000)
    },
    computed: {
        isOrgNew: function(){
            if(this.caseDetail.case_org == "" || this.caseDetail.case_org == null){
                return false;
            }
            if(typeof (this.caseDetail.case_org) === 'object'){
                if(this.caseDetail.case_org.id == 0){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                return true;
            }
        },
    },
    destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
    methods: {
        checkMore(content,length){
            if(content.replace( /(<([^>]+)>)/ig, '').length > length || content.includes('base64')){
                return true;
            }else{
                return false;
            }
        },
        onEmitChangeFollow(val){
            this.caseDetail.follow = val;
            this.isFollow  = val;
        },
        validateForm(){
            return this.$refs.formCaseDetail.validate();
        },
        changeSelectedColor(key){
            if(this.timeline_tab.includes(key)){
                return 'v-tab tabBtn-selected';
            }
            else{
                return 'v-tab tabBtn-unselect';
            }
        },
        onChangeTltSelected(){
            let choosedbtn = document.getElementById(event.target.id);
            let choosedbtnkey = choosedbtn.getAttribute('id').substring(4);
            if(choosedbtnkey == 'all'){
                this.timeline_tab = ['all'];
            }
            else{
                if(this.timeline_tab.includes('all')){
                    this.timeline_tab.pop();
                    this.timeline_tab.push(choosedbtnkey);
                }
                else if(this.timeline_tab.includes(choosedbtnkey)){
                    let index = this.timeline_tab.indexOf(choosedbtnkey);
                    this.timeline_tab.splice(index,1);
                    if(this.timeline_tab.length == 0)
                        this.timeline_tab = ['all'];
                }
                else{
                    this.timeline_tab.push(choosedbtnkey);
                    if(this.timeline_tab.length == (this.timeLineType.length-1)) 
                        this.timeline_tab = ['all'];
                }
            }
            let user = JSON.parse(window.localStorage.getItem('user'));
            let userid = user['userid'];
            this.timelinetabData['user_id'] = userid;
            this.timelinetabData['Case'] = this.timeline_tab;
            PersonalSettingService.updateUserTimelineTab(userid,this.timelinetabData)
            .catch(response => {
                this.showErrorDialog(response);
            })
            this.showTimlinesCheck();            
        },
        showTimlinesCheck(){
                this.showTimelines = this.caseTimeLines;
                for(let i = 0,ll = this.showTimelines.length ; i<ll ; i++){
                    // 預設show全部false
                    this.showTimelines[i]['show'] = false;
                    // 到期活動 & 釘選改為 true
                    if(this.showTimelines[i]['type'] == 'DUE_ACTIVITY')
                        this.showTimelines[i]['show'] = true;
                    if(this.showTimelines[i]['pinned'] !== undefined && this.showTimelines[i]['pinned'] == true)
                        this.showTimelines[i]['show'] = true;
                    // 當篩選為all 改為 全true
                    if(this.timeline_tab.includes('all'))
                        this.showTimelines[i]['show'] = true;
                    // 依篩選調整顯示項目
                    if(this.timeline_tab.includes('stepChange')){
                        if(this.showTimelines[i]['type'] == 'STAGE')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('statusChange')){
                        if(this.showTimelines[i]['type'] == 'STATUS')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('fieldHistory')){
                        if(this.showTimelines[i]['type'] == 'FIELDHISTORY')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('note')){
                        if(this.showTimelines[i]['type'] == 'NOTE')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('mail')){
                        if(this.showTimelines[i]['type'] == 'EMAIL')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('sms')){
                        if(this.showTimelines[i]['type'] == 'SMS')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('activity')){
                        if(this.showTimelines[i]['type'] == 'ACTIVITY_FIELDHISTORY')
                            this.showTimelines[i]['show'] = true;
                    }
                }
                let showList = this.showTimelines.filter(el => el.show == true);
                for (let i in showList){
                    showList[i]['show_index'] = parseInt(i);
                }
                this.timeline_show_count = showList.length;
                this.timeline_count = 100;
            },
        onResize: function () {
            this.folder();
        },
        folder(data){
            if (this.getfolder == false){
            this.getfolder = true;
            this.setPosition(data);
            }
            else {
            this.getfolder = false;
            this.setPosition(data);
            }
        },
        setPosition: function(data) {
            if(data==0)	{
                return {
                    top: '0px'				
                }
            }
            else{
                if(this.getfolder == false){ 
                return {
                        top : data*45+'px'
                    }
                }
                else{
                    return {
                        display : 'none'
                    }
                }
            }
        },
        //- message/success/error dialog
        showMessageDialog(message, situation) {
            this.msgDialogSituation = situation;
            this.messageDialog = true;
            this.message = message;
        },
        onEmitMessageDialog(val) {
            this.messageDialog = false;
            var today = this.getTodayDate();
            if(this.msgDialogSituation == 'onClose') {
                this.loseDealData = {
                    case_id: this.caseDetail.id,
                    subject: this.caseDetail.subject,
                    closed_date: today,
                    primary_contact: this.caseDetail.primary_contact,
                }
                if(val) {
                    this.childCases.forEach(childcase => {
                        if(childcase.closed_date == null) {
                            childcase.closed_date = today
                        } else {
                            childcase.closed_date = this.getTodayDate(new Date(childcase.closed_date))
                        }
                        if(childcase.status != 'CLOSED') {
                            childcase.closed_reason = null
                        }
                    })
                    this.loseDealData.childCases = this.childCases
                }
                else {
                    this.loseDealData.childCases = null
                }   
                this.loseReason = true;
            }
            if (val) {
                if(this.mode =="ReStart"){
                    // this.$set(this.caseDetail,"closed_reason",0)
                    let data = {
                        id: this.caseDetail.id,
                        subject: this.caseDetail.subject,
                        closed_reason: "",
                    }
                    CaseDataService.editdetail(data)
                    .then(() => {
                        this.caseDetail.status = "OPEN";
                        this.cFEditable = true;
                        this.caseDetail.closed_date = null;
                        this.caseDetail.closed_reason = null;
                        this.successDialog = true;
                    })
                }
                switch(this.msgDialogSituation){
                    case 'onReStart':						
                        // this.stepContent = true;
                        this.isProcessComplete = false;
                        this.isProcessClosed = false;
                        // this.isProcessResolved = false;
                        this.isProcessEdit = true;
                        this.customStagecolor = 'customcolor'
                        this.isOpen = true;
                        this.successDialog = true;
                        break;
                    default:
                        break;
                }
                if(this.succMode =="DELCASE"){
                    CaseDataService.delete(this.caseId);                   
                    this.successDialog = true;               
                }
                this.msgDialogSituation = '';

                if (val && this.delMode == 'delNote'){
                    NoteDataService.deleteNote(this.noteIdOnDelete)
                    .then(() => {
                        this.deleteNoteFrontEnd(this.noteIdOnDelete);
                        // this.showSuccessDialog();
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    });
                }                

                if (val && this.delMode == 'delProduct'){
                    let data = {case:this.caseId,product:[]}
                    for(let i in this.deleteProductData)
                    {
                        data.product.push(this.deleteProductData[i].id)        
                    }
                    CaseDataService.deletecaseproduct(data).then(()=>{
                    for(let i in this.deleteProductData)
                    {
                        let index =  this.caseProduct.indexOf(this.deleteProductData[i])
                        this.caseProduct.splice(index, 1)
                        let index_2 =  this.caseProductID.indexOf(this.deleteProductData[i].id)
                        this.caseProductID.splice(index_2, 1)
                    }
                    this.deleteProductData = []
                    this.deletrProduct = false
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    });
                }
                this.noteIdOnDelete == null;
                this.delMode = '';
            }
        },
        showSuccessDialog() {
            this.successDialog = true;
        },
        async onEmitSuccessDialog() {
            this.successDialog = false;
            if (this.succMode == 'changeOwn') {
                this.changeText = i18n.t('CASE_DETAIL.TIMELINE_OWNER')+`${this.changeOwnData.last_name} ${this.changeOwnData.first_name}`
                await this.refreshTimeLine();
            }
            if (this.succMode == 'DELCASE') {
                this.$router.replace('/cases');
            }
            this.succMode = '';
            this.mode = '';
        },
        showErrorDialog(message) {
            this.errorDialog = true;
            this.errorMessage = message;
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
        //- edit detail
        onEditDetail() {
            this.isDetailReadonly = false;
            this.origincaseDetail = JSON.parse(JSON.stringify(this.caseDetail));
        },
        async confirmEditDetail() {
            this.formRule.phoneRules.push(() => {return (this.isNumVaild || i18n.t('CASE_DETAIL.ERROR'))});
            if (this.comboInput.primary_contact != '' && this.$refs.formCaseDetail.validate()) {	
                await (this.loading_caseDeatil = true);
                this.validateForm();
                let data = JSON.parse(JSON.stringify(this.caseDetail));
                
                if (data.case_org == null)
                    data.case_org = {};

                // ===== new primary_contact start =====
                if(this.isNew.primary_contact && this.newPri.lName)
                {
                    if(data.primary_contact == null){
                        data.primary_contact = {
                            id: 0,
                            last_name: this.newPri.lName,
                            first_name: this.newPri.fName != null ? this.newPri.fName : null
                        }
                    }
                    else{
                        data.primary_contact.id = 0;
                        data.primary_contact.last_name = this.newPri.lName;
                        if(this.newPri.fName != null)
                            data.primary_contact.first_name = this.newPri.fName;
                        else 	
                            data.primary_contact.first_name = null;
                    }
                    
                } else if (this.isNew.primary_contact && !this.newPri.lName) {
                    data.primary_contact = {};
                } 
                // ===== new primary_contact end =====
                
                // ===== case_org start =====
                if (this.comboInput.case_org != '' && this.comboInput.case_org != null) {
                    if (this.comboInput.case_org != data.case_org.name) {
                        let newOrg = {
                            id: "-1", 
                            name: this.comboInput.case_org, 
                            company_number: "0"
                        };
                        data.case_org = newOrg;
                    }
                } else {
                    data.case_org = {};
                }
                // ===== new case_org end=====
                
                // ===== parent case =====
                if(data.parent_case != null) {
                    if(this.comboInput.parent_case == data.parent_case.subject) {
                        data.parent_case_id = data.parent_case.id
                    }
                    else {
                        data.parent_case_id = null
                    }
                }
                else{
                    data.parent_case_id = null
                }
                
                delete data.stage_progress;
                delete data.stage_score;
                delete data.summary;
                delete data.owner;
                delete data.stages;
                delete data.contacts;
                delete data.knowledge_articles
                await CaseDataService.editdetail(data)
                .then(response => {
                    if (response.data.case_org != null) { // 建立新公司負責人資料
                        if (this.caseDetail.case_org.id == 0) {
                            let principalData = {
                                "organization_id": response.data.case_org.id,
                                "owner_id": response.data.owner.id
                            };
                            ContactDataService.createCompanyPrincipal(principalData);
                        }
                    }
                    this.caseDetail = response.data;
                    this.caseDetail.priority_number = this.getPriorityNumber(this.caseDetail.priority);
                    this.caseContacts.contacts = this.caseDetail.contacts;
                    this.primary_contact_case_set = this.caseDetail.primary_contact.case_set;
                    let n = this.primary_contact_case_set.findIndex(c => {return c.id == this.caseDetail.id});
                    this.primary_contact_case_set.splice(n, 1);
                    this.primary_contact_case_set.forEach(caseItem => {
                        caseItem.statusName = this.getItemNameFromList(this.caseRelatedStatusList, caseItem.status);
                        if (caseItem.closed_date != null) {
                            caseItem.closed_time = this.getFormatTime(new Date(caseItem.closed_date))
                        }
                    });
                    this.dataCheck = [ this.caseDetail.subject, this.caseDetail.primary_contact, this.caseDetail.status, this.caseDetail.priority, this.caseDetail.group, this.caseDetail.type, this.caseDetail.due_date ]
                    this.onCheckData();
                    this.activity_org = this.caseDetail.case_org;
                    this.activity_contact = this.caseDetail.primary_contact;
                    this.isDetailReadonly = true;
                    this.defaultDetail();
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })				
                await this.refreshTimeLine();
                await (this.loading_caseDeatil = false);	
            }
        },
        cancelEditDetail() {
            this.isDetailReadonly = true;
            this.defaultDetail();
            this.caseDetail = this.origincaseDetail;
        },
        onCheckData() {
            if(this.check[0] != this.dataCheck[0])
            {
                this.changeText = i18n.t('CASE_DETAIL.TIMELINE_NAME') + this.caseDetail.subject;
                this.getFieldHistoryData(this.changeText);
                this.caseTimeLines.unshift(this.fieldhistoryData);
            }
            if (this.check[1].__chineseName__ != this.dataCheck[1].__chineseName__)
            {
                this.changeText = i18n.t('CASE_DETAIL.TIMELINE_CONTACT') + this.caseDetail.primary_contact.__chineseName__
                this.getFieldHistoryData(this.changeText);
                this.caseTimeLines.unshift(this.fieldhistoryData);
            }
            if(this.check[2] != this.dataCheck[2])
            {      
                this.changeText = i18n.t('CASE_DETAIL.TIMELINE_STATUS') + this.getItemNameFromList(this.caseStatusList, this.caseDetail.status);
                this.succMode = 'STATUS';
                this.getFieldHistoryData(this.changeText);
                this.caseTimeLines.unshift(this.fieldhistoryData);
            }
            if(this.check[3] != this.dataCheck[3])
            {      
                this.changeText = i18n.t('CASE_DETAIL.TIMELINE_PRIORITY') + this.getItemNameFromList(this.casePriorityList, this.caseDetail.priority_number);
                this.succMode = 'STATUS';
                this.getFieldHistoryData(this.changeText);
                this.caseTimeLines.unshift(this.fieldhistoryData);
            }
            if(this.check[4] != this.dataCheck[4])
            {
                this.changeText = i18n.t('CASE_DETAIL.TIMELINE_GROUP') + this.getItemFromListById(this.caseGroupList, this.caseDetail.group).group_name;
                this.getFieldHistoryData(this.changeText);
                this.caseTimeLines.unshift(this.fieldhistoryData);
            }
            if(this.check[5] != this.dataCheck[5])
            {      
                this.changeText = i18n.t('CASE_DETAIL.TIMELINE_TYPE') + this.getItemFromListById(this.caseTypeList, this.caseDetail.type).type_name;
                this.getFieldHistoryData(this.changeText);
                this.caseTimeLines.unshift(this.fieldhistoryData);
            }
            if(this.check[6] != this.dataCheck[6])
            {      
                this.changeText = i18n.t('CASE_DETAIL.TIMELINE_DUE_DATE') + this.caseDetail.due_date;
                this.getFieldHistoryData(this.changeText);
                this.caseTimeLines.unshift(this.fieldhistoryData);
            }
        },
        onPhoneCheck(event) {
            this.vue_tel = event;
            if (this.vue_tel.isValid == true) {
                this.isNumVaild = true;
            }
        },
        onPhoneInput() {
            if (this.isNumVaild == true) {
                if (this.caseDetail.incoming_phone != this.vue_tel.number.input && this.caseDetail.incoming_phone != "") {
                    this.isNumVaild = false;
                }
            } else {
                if (this.caseDetail.incoming_phone == "") {
                    this.isNumVaild = true;
                }
            }
        },
        // process and stage
        setCaseSummary() {
            if (this.allStage > 6) {
                if (this.currentStep > this.allStage - 4) {
                    this.isLast = false;
                    this.caseSummary = this.allProcess.slice(this.allStage - 6, this.allStage + 6);
                }
                else if (this.currentStep < 4) {
                    this.isFirst = false;
                    this.caseSummary = this.allProcess.slice(0, 6);
                }
                else {
                    this.isFirst = true;
                    this.isLast = true;
                    this.caseSummary = this.allProcess.slice(this.currentStep - 3, this.currentStep + 3);
                }
            }
            else {
                this.caseSummary = this.allProcess;
            }
            this.currentStep = this.currentStep + 1;
        },
        onChangeCaseStage(data) {
            if(this.$isViewOnly.isViewOnly) return;
            this.stageData = { "current_stage_id": data }
            if(this.caseDetail.current_stage != data && this.isProcessEdit == true)
            {
                CaseDataService.updatestage(this.caseDetail.id, this.stageData)
                .then(response =>{
                    this.succMode="STAGE";
                    this.stageNumber = this.allProcess.find(d => d.id == response.data.current_stage_id).sequence_number;
                    this.stageName = this.allProcess.find(d => d.id == response.data.current_stage_id).stage_name;
                    this.changeText = "目前案件已更改為 第" + this.stageNumber+ "步驟 " + this.stageName
                    if(this.$i18n.locale == "en_US")
                    {
                      this.changeText = "The current stage of case has been changed to step " + this.stageNumber+ " : " + this.stageName
                    }
                    this.getFieldHistoryData(this.changeText);                            
                    this.caseTimeLines.unshift(this.fieldhistoryData);
                    this.caseDetail.current_stage = data;
                })
                .catch(response =>{
                    this.showErrorDialog(response);
                })
            }
        },
        switchStepContent() {			
            if (this.stepContent === true) {
                this.stepContent = false;
            } else {
                this.stepContent = true;
            }
        },
        getcolor: function(data) {
            this.colorQ = data;				 
            return {			
            '--backgroundColor':this.colorQ,
            '--color': this.colorQ		
            }
        },
        nextStage() {
            this.currentStep = this.currentStep + 1;
            if (this.caseSummary[5].sequence_number > this.allStage - 6) {
                let n = this.allStage - 5;
                this.changeAddStage(n);
            } else if (this.caseSummary[5].sequence_number < this.allStage) {
                let n = this.caseSummary[5].sequence_number + 1;
                this.changeAddStage(n);
            }
            if (this.caseSummary[0].sequence_number == 1) {
                this.isFirst = false;
            } else {
                this.isFirst = true;
            }
            if (this.caseSummary[5].sequence_number == this.allProcess.length) {
                this.isLast = false;
            } else {
                this.isLast = true;
            }
        },
        async preStage() {
            if (this.caseSummary[0].sequence_number <= 6) {
                let n = 6;
                await this.changeMinusStage(n);
            } else {
                let n = this.caseSummary[0].sequence_number - 1;
                await this.changeMinusStage(n);
            }
            await (this.currentStep = this.currentStep + 1);
            if (this.caseSummary[0].sequence_number == 1) {
                this.isFirst = false;
            } else {
                this.isFirst = true;
            }
            if (this.caseSummary[5].sequence_number == this.allProcess.length) {
                this.isLast = false;
            } else {
                this.isLast = true;
            }
        },
        async changeAddStage(n) {
            await (this.caseSummary = this.allProcess.slice(n - 1, n + 5));
            await (this.currentStep = this.currentStep - 1);
        },
        changeMinusStage(n) {
            this.currentStep = this.currentStep - 1;
            this.caseSummary = this.allProcess.slice(n - 6, n);
        },
        // timeline
        getTimeLineIcon: function(type){
            let icon = "";
            switch (type) {
                case "EMAIL":
                    icon = 'icon-mail';
                    break;
                case "NOTE":
                    icon = 'icon-note';
                    break;
                case "STAGE":
                    icon = 'mdi-progress-upload';
                    break;
                case "STATUS":
                    icon = 'mdi-alert-circle-outline';
                    break;
                case "FIELDHISTORY":
                    icon = 'mdi-history';
                    break;
                case "SMS":
                    icon = 'mdi-message-text';
                    break;
                case "ACTIVITY_FIELDHISTORY":
                    icon = 'icon-calendar';
                    break;
                case "DUE_ACTIVITY":
                    icon = 'mdi-bell-ring';
                    break;
                default:
                    icon = 'mdi-help-circle';
                    break;
            }
            return icon;
        },
        arrangeTimeLine(timelineList){
            let havePinned = timelineList.filter(this.pinnedTrue);
            let noPinned = timelineList.filter(this.pinnedFalse);
            havePinned = havePinned.sort(function(a, b){
                let aTime = new Date(a.time+"Z");
                let bTime = new Date(b.time+"Z");
                return bTime-aTime });
            noPinned = noPinned.sort(function(a, b){
                let aTime = new Date(a.time+"Z");
                let bTime = new Date(b.time+"Z");
                return bTime-aTime });
            return havePinned.concat(noPinned);
        },
        async getCaseTimeline(id) {
            await CaseDataService.timeline(id)
            .then(response => {
                this.caseTimeLines = this.arrangeTimeLine(response.data);
                let i = 0;
                this.alertNote = [];
                this.caseTimeLines.forEach(item => {
                    item.timeConvert = this.getFormatTime(new Date(item.time));
                    if(item.type == "NOTE" && item.pinned ==true){
                        item.alertTime = this.getLittleFormatTime(new Date(item.time))
                        this.alertNote.push(item)
                    }
                    if (item.type == "NOTE") {
                        if (item.content.length > 300) {
                            this.$set(this.caseTimeLines[i], 'isMore_timeLineAll', true);
                            this.$set(this.caseTimeLines[i], 'isMore_timeLineNote', true);
                            this.$set(this.caseTimeLines[i], 'isMore_note', true);
                        } else {
                            this.$set(this.caseTimeLines[i], 'isMore_timeLineAll', false);
                            this.$set(this.caseTimeLines[i], 'isMore_timeLineNote', false);
                            this.$set(this.caseTimeLines[i], 'isMore_note', false);
                        }
                    }
                    if(item.type == "EMAIL"){
                        let to_list = [];
                        let cc_list = [];
                        let sender_name = "";
                        try{
                            sender_name = this.user_list.filter(el => el.email == item.sender)[0].fullName;
                        }
                        catch{
                            try{
                                sender_name = item.contacts.filter(el => el.email == item.sender)[0].__chineseName__;
                            }
                            catch{
                                sender_name = item.sender;
                            }
                        }
                        item.sender_data = {'sender_name':sender_name,'email':item.sender};
                        for(let i in item.recipient.to){
                            let name = "";
                            try{
                                name = item.contacts.filter(el => el.email == item.recipient.to[i])[0].__chineseName__;
                            }
                            catch{
                                name = "";
                            }
                            to_list.push({'name':name,'email':item.recipient.to[i]});
                        }
                        for(let i in item.recipient.cc){
                            let name = "";
                            try{
                                name = item.contacts.filter(el => el.email == item.recipient.cc[i])[0].__chineseName__;
                            }
                            catch{
                                name = "";
                            }
                            cc_list.push({'name':name,'email':item.recipient.cc[i]});
                        }
                        item.cc_list = cc_list;
                        item.to_list = to_list;
                    }
                    i ++;
                });
                this.originTimeLines = JSON.parse(JSON.stringify(this.caseTimeLines));
            })
            .catch(response => {
                this.showErrorDialog(response);
            })		
        },
        hasType(arr, type){
            let answer = arr.some(function(item){
                return item.type == type;
            }) 
            return answer;
        },
        onEmailContact(contacts){
            this.emailContactData = contacts;
            this.emailContactsDialog = true;
        },
        onEmitEmailContactsDialog(){
            this.emailContactsDialog = false;
        },
        onEmailOppties(oppties){
            this.emailOpptiesData = oppties;
            this.emailOpptiesDialog = true;
        },
        onEmitEmailOpptiesDialog(){
            this.emailOpptiesDialog = false
        },
        onEmailAttachment(attachments){
            this.emailAttachmentData = attachments;
            this.emailAttachmentsDialog = true;
        },
        onEmitEmailAttachmentsDialog(){
            this.emailAttachmentsDialog = false;
        },
        showEmailDetail(event){
            let n = event.target.closest('div .no-gutters').previousSibling.lastChild;
            if (n.classList.contains('d-none')){
                n.classList.remove('d-none');
                event.target.innerText = i18n.t('GENERAL.HIDE_CONTENT');
            }
            else{
                n.classList.add('d-none');
                event.target.innerText = i18n.t('GENERAL.SHOW_CONTENT');
            }                       
        },
        // Common Function
        onCall: function (cell) {
            return 'tel:' + cell;
        },
        onMail: function (mail) {
            return 'mailto:' + mail;
        },
        getFormatTime(data){
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Asia/Taipei' };
            let today  = data;
            if(this.$i18n.locale == "zh_TW")
            {
                return today.toLocaleDateString("zh-TW", options); 
            }else
            {
                return today.toLocaleDateString("en-US", options);
            } 
        },
        getLittleFormatTime(data){
            let today  = data;
            if(this.$i18n.locale == "zh_TW")
            {
                return today.toLocaleDateString("zh-TW"); 
            }else
            {
                return today.toLocaleDateString("en-US");
            }                 
        },
        getFieldHistoryData(data){
            this.fieldhistoryData = {};
            
            switch(this.succMode){
                case 'changeOwn':
                    this.fieldhistoryData.type = "FIELDHISTORY";
                    break;
                case 'STAGE':
                    this.fieldhistoryData.type = "STAGE";
                    break;
                case 'STATUS':
                    this.fieldhistoryData.type = "STATUS";
                    break;
                default:
                    this.fieldhistoryData.type = "FIELDHISTORY";
                    break;
            }
            let userJson = window.localStorage.getItem('user');
            let user = JSON.parse(userJson);
            this.fieldhistoryData.timeConvert = this.getFormatTime(new Date());
            this.fieldhistoryData.content = data;                
            // this.fieldhistoryData.opportunity = this.caseDetail.id;
            this.fieldhistoryData.changed_by = `${user.last_name} ${user.first_name}`;
        },
        defaultDetail(){
            this.isNew = {
                primary_contact: false,
                case_org: false,
            }
            this.newPri = {
                fName: null,
                lName: null,
            }
            this.newOppCon = {
                fName: null,
                lName: null,
            }
            this.formRule.phoneRules = [];
        },
        cleanFirstName(field){
            if(field.lName == null || field.lName == "")
                field.fName = null;
        },
        getTabName(key){
            if(key == 'timeline'){
                const io = new IntersectionObserver((mutations) => {
                    const isTimelineBtm = mutations[0].isIntersecting;
                    if(isTimelineBtm && this.timeline_show_count > this.timeline_count){
                            this.timeline_count = this.timeline_count + 100;
                        }
                });
                setTimeout(()=>{
                    let target = document.getElementById('timeline_btm');
                    if(!target.dataset.hasObserver){
                        io.observe(document.getElementById('timeline_btm'));
                        target.dataset.hasObserver = true;
                    }
                },200)
            }
            if(key == 'note')
                this.isNoteTab = true;
            else
                this.isNoteTab = false;
            if(key == 'product')
                this.isProductTab = true;
            else
                this.isProductTab = false;
            if(key == 'documents')
                this.isdocumentTab = true;
            else
                this.isdocumentTab = false;
            if(key == 'activities')
                    this.isActivityTab = true;
                else
                    this.isActivityTab = false;
        },
        isMore(index, place) {
            if (place == "timeLineAll") {
                this.caseTimeLines[index].isMore_timeLineAll = this.caseTimeLines[index].isMore_timeLineAll ? false : true;
            } else if (place == "timeLineNote") {
                this.caseTimeLines[index].isMore_timeLineNote = this.caseTimeLines[index].isMore_timeLineNote ? false : true;
            } else if (place == "NOTE") {
                this.caseTimeLines[index].isMore_note = this.caseTimeLines[index].isMore_note ? false : true;
            }
        },
        getTodayDate(fullDate = new Date()) {
            var yyyy = fullDate.getFullYear();
            var MM = (fullDate.getMonth() + 1) >= 10 ? (fullDate.getMonth() + 1) : ("0" + (fullDate.getMonth() + 1));
            var dd = fullDate.getDate() < 10 ? ("0"+fullDate.getDate()) : fullDate.getDate();
            var HH = fullDate.getHours() >= 10 ? fullDate.getHours() : ("0" + fullDate.getHours());
            var mm = fullDate.getMinutes() >= 10 ? fullDate.getMinutes() : ("0" + fullDate.getMinutes());
            var today = yyyy + "-" + MM + "-" + dd + " " + HH + ":" + mm;
            return today;
        },
        // note---------------------------------------------------------------------------------------------------------
        onAddNote(){
            this.getNoteData();
            this.addNoteDialog = true; 
        },
        onEmitAddNoteDialog(val){
            this.addNoteDialog = false;
            if (val) {
                if (this.noteData.content.length > 300) {
                    this.$set(this.noteData, 'isMore_timeLineAll', true);
                    this.$set(this.noteData, 'isMore_timeLineNote', true);
                    this.$set(this.noteData, 'isMore_note', true);
                } else {
                    this.$set(this.noteData, 'isMore_timeLineAll', false);
                    this.$set(this.noteData, 'isMore_timeLineNote', false);
                    this.$set(this.noteData, 'isMore_note', false);
                }
                this.caseTimeLines.unshift(this.noteData);
                this.caseTimeLines = this.arrangeTimeLine(this.caseTimeLines);
            }
        },
        onEmitAddNoteData(data) {
            let index = this.caseTimeLines.findIndex(item => {return item.id == data.id})
            this.caseTimeLines[index].time = data.created_on;
            this.refreshTimeLine();
        },
        onEditNote(item){
            this.noteData = item;
            this.editNoteDialog = true;
        },
        onEmitEditNoteDialog(val){
            this.editNoteDialog = false;
            if (val) {
                this.refreshTimeLine();
                if (this.noteData.content.length > 300) {
                    this.$set(this.noteData, 'isMore_timeLineAll', true);
                    this.$set(this.noteData, 'isMore_timeLineNote', true);
                    this.$set(this.noteData, 'isMore_note', true);
                } else {
                    this.$set(this.noteData, 'isMore_timeLineAll', false);
                    this.$set(this.noteData, 'isMore_timeLineNote', false);
                    this.$set(this.noteData, 'isMore_note', false);
                }
            }
        },
        onDeleteNote(id){
            this.showMessageDialog(i18n.t('CASE_DETAIL.MESSAGE'));
            this.noteIdOnDelete = id;
            this.delMode = 'delNote';
        },
        onPinNote(item){
            NoteDataService.editNote(this.getPinnData(item))
            .then(() =>{
                this.refreshTimeLine();
                if (item.pinned == true) {
                    this.pinning = true;
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
        },
        pinnedTrue(obj){
            if ("pinned" in obj && obj.pinned == true)
                return true;
        },
        pinnedFalse(obj){
            if (!("pinned" in obj))
                return true;
            else if (obj.pinned == false)
                return true;
        },
        getPinnData(obj){
            obj.pinned = obj.pinned ? false : true;
            let data = {};
            data.pinned = JSON.parse(JSON.stringify(obj.pinned));
            data.id = JSON.parse(JSON.stringify(obj.id));
            return data;
        },
        deleteNoteFrontEnd(id){
            // delete note in time line
            let item = this.caseTimeLines.find(e => e.id == id);
            let index = this.caseTimeLines.indexOf(item);
            this.caseTimeLines.splice(index, 1);
            // delete note in alert
            let item_alert = this.alertNote.find(e => e.id == id);
            let index_alert = this.alertNote.indexOf(item_alert);
            this.alertNote.splice(index_alert, 1);
        },
        getNoteData(){
            let userJson = window.localStorage.getItem('user');
            let user = JSON.parse(userJson);
            this.noteData = {};
            this.noteData.case = this.caseDetail.id;
            this.noteData.owner = `${user.last_name} ${user.first_name}`;
        },
        showNote(value) {
            if (value != null) {
                return value.substring(0, 300) + " ...";
            }
        },
        onPinning() {
            this.pinning = false;
        },
        // related contacts
        onRelatedContacts(data) {
            this.relatedContactsDialog = true;
            this.relatedContactData = data;
        },
        onEmitRelatedContactsDialog() {
            this.relatedContactsDialog = false;
            this.getCaseContacts();
        },
        onEmitRelatedContactMessage(text) {
            if (text == 'success') {
                this.getCaseContacts();
            }
        },
        getCaseContacts() {
            CaseDataService.detail(this.caseId)
            .then(response => {				
                this.caseContacts.contacts = (response.data.contacts);
                this.relatedContactData = this.caseContacts.contacts;
                this.relatedContactData.forEach((data) => {
                    if(data.first_name != null)
                        data.full_name = data.last_name +" "+ data.first_name;
                    else
                        data.full_name = data.last_name;
                    if (data.organization_set.length == 0) {
                        data.accountName = "";
                        data.accountId = ""
                    } else {
                        data.accountName = data.organization_set[0].name;
                        data.accountId = data.organization_set[0].id;
                    }
                }); 
            })
        },
        //child cases
            //CurrentStage目前是ID，要用它找stage_type中的stagedef_set(陣列)再輪詢出對應的sequence_number
        getCorrectCurrentStage(){
            for(let i = 0, ml = this.caseDetail.stage_type.stagedef_set.length; i<ml ; i++){
                if(this.caseDetail.current_stage == this.caseDetail.stage_type.stagedef_set[i].id){
                    this.sequence_number = this.caseDetail.stage_type.stagedef_set[i].sequence_number;
                    break;
                }
            }
        },
        onEmitChildCasesDialog() {
            this.childCasesDialog = false;
        },
        onEmitChildCases(val) {
            this.childCases = val
        },
        openAddNewChildCase() {
            this.addNewChildCaseDialog = true
            this.addCaseData = {
                parent_case : {
                    'id':this.caseId,
                    'subject':this.caseDetail.subject,
                },
                stage_type:this.caseDetail.stage_type,
                current_stage:1

            }
        },
        onEmitAddChildCaseDialog(val) {
            if(val) {
               this.showSuccessDialog();
            }
            this.addNewChildCaseDialog = false
        },
        onEmitNewCaseData(val) {
            val.priorityName = this.getItemNameFromList(this.casePriorityList, val.priority)
            val.statusName = this.getItemNameFromList(this.caseRelatedStatusList, val.status)
            val.groupName = this.getItemFromListById(this.caseGroupList, val.group).group_name;
            val.typeName = this.getItemFromListById(this.caseTypeList, val.type).type_name;
            val.owner.fullName = val.owner.first_name != '' ? val.owner.last_name + ' ' + val.owner.first_name : val.owner.last_name;
            if (val.closed_date != null) {
                val.closed_time = this.getFormatTime(new Date(val.closed_date));
            }            
            // Update子案件區塊
            if(val['parent_case_id'] == this.caseId) {
                this.childCases.unshift(val)
            }
            // Update主要聯絡人相關案件區塊
            if(val['primary_contact'].id == this.caseDetail['primary_contact'].id) {
                this.primary_contact_case_set.unshift(val)
            }
        },        
        // delete
        onDelete () {
            this.succMode = "DELCASE";
            this.showMessageDialog(i18n.t('CASE_DETAIL.MESSAGE_2'));
        },
        // change agent
        async onChangeOwn(data) {
            await (this.otherData.case_id = data.id);
            await (this.otherData.subject = data.subject);
            await (this.changeOwnDialog = true);
        },
        onEmitChangeOwnDialog(val) {
            this.changeOwnDialog = false;
            if (val) {
                this.succMode = "changeOwn";
                this.showSuccessDialog();
            }
        },
        // change stage type
        onChangeProcess(data) {
            this.processData.case_id = data.id;
            this.processData.subject = data.subject;
            this.processData.stage_type = data.stage_type;
            this.changeProcessDialog = true;
        },
        onEmitChangeProcessDialog(val) {
            this.changeProcessDialog = false;
            if (val) {
                this.showSuccessDialog();
            }
        },
        async onEmitChangeProcessData(data) {
            this.caseDetail = data
            this.allProcess = this.caseDetail.summary;
            this.allStage = this.allProcess.length;
            this.StageTypeId = this.caseDetail.stage_type.id
            if(data.custom_field != null){
                this.customFieldData = data.custom_field;
            }
            // this.score = this.caseDetail.stage_score.score;
            await this.setCaseSummary();
            await (this.currentStep = 1)
            this.changeText = i18n.t('CASE_DETAIL.TIMELINE_CASE_PROCESS') + this.caseDetail.stage_type.stage_type_name;
            this.getFieldHistoryData(this.changeText);
            this.caseTimeLines.unshift(this.fieldhistoryData);
        },
        // close and restart case 結案
        async onClose() {
            if(this.childCases.length > 0) {
                this.messageDialog = true;
                this.showMessageDialog(i18n.t('CASE_DETAIL.MESSAGE_4'), 'onClose')
                if (this.isDetailReadonly == false) {
                    this.cancelEditDetail();
                }
            }
            else
            {
                var today = this.getTodayDate();
                this.loseDealData = {
                    case_id: this.caseDetail.id,
                    subject: this.caseDetail.subject,
                    closed_date: today,
                    primary_contact: this.caseDetail.primary_contact,
                    childCases: null
                }
                this.loseReason = true;
            }
        },
        onEmitLoseReasonDialog(val) {
            this.loseReason = false;
            if (val) {
                this.isProcessEdit = false;
                this.isProcessComplete = true;
            }
        },
        onEmitCloseReasonData(data) {
            this.caseDetail = data;
            this.caseDetail.closed_date = this.getFormatTime(new Date(this.caseDetail.closed_date));
            this.caseDetail.priority_number = this.getPriorityNumber(this.caseDetail.priority);
            this.isOpen = false;
            this.cFEditable = false;
            this.closeReasonName = this.getItemNameFromList(this.closeCaseReasonList, this.caseDetail.closed_reason);
            // if (this.caseDetail.closed_reason == "RESOLVED") {
            //     this.isProcessResolved = true;
            // } else
            if (this.caseDetail.closed_reason != null) {
                this.isProcessClosed = true;
            }
            // Update子案件區塊
            this.primary_contact_case_set = []
            data.child_case.forEach(item => {
                item.priorityName = this.getItemNameFromList(this.casePriorityList, item.priority)
                item.statusName = this.getItemNameFromList(this.caseRelatedStatusList, item.status)
                item.groupName = this.getItemFromListById(this.caseGroupList, item.group).group_name;
                item.typeName = this.getItemFromListById(this.caseTypeList, item.type).type_name;
                item.owner.fullName = item.owner.first_name != '' ? item.owner.last_name + ' ' + item.owner.first_name : item.owner.last_name;
                if (item.closed_date != null) {
                    item.closed_time = this.getFormatTime(new Date(item.closed_date));
                }
                // Update主要聯絡人相關案件區塊
                if(item.primary_contact['id'] == data.primary_contact['id']) {
                    this.primary_contact_case_set.unshift(item)
                }
            })
            this.childCases = data.child_case
        },
        onReStart() {
            this.showMessageDialog(i18n.t('CASE_DETAIL.MESSAGE_3'), 'onReStart');
            this.mode = "ReStart";
        },
        // copy case
        showAddCaseDialog() {
            this.getCorrectCurrentStage();
            this.addCaseDialog = true;
            this.copyDataId = "Case"
            this.addCaseData = {
                subject: this.caseDetail.subject,
                primary_contact: this.caseDetail.primary_contact,
                case_org: this.caseDetail.case_org,
                source: this.caseDetail.source,
                group: this.caseDetail.group,
                type: this.caseDetail.type,
                category: this.caseDetail.category,
                due_date: this.caseDetail.due_date,
                current_stage: this.sequence_number,
                stage_type: this.caseDetail.stage_type,
                incoming_phone:this.caseDetail.incoming_phone,
                incoming_email:this.caseDetail.incoming_email,
                parent_case:this.caseDetail.parent_case,
                custom_field:this.caseDetail.custom_field,
            }
        },
        onEmitAddCaseDialog(val) {
            this.addCaseDialog = false;
            if (val) {
                if (this.copyDataId == null) {
                    this.showSuccessDialog();
                } else {                
                    this.redirectDialog = true
                    this.message = ""
                    this.copyDataId = this.addCaseData.id
                }
            }
        },
        onEmitRedirectDialog(){
            this.redirectDialog = false
            this.copyDataId = null      
        },
        
        // onEditAactivity() {
        //     this.activityDialog = true;
        // },
        // onEmitActivityDialog(val) {
        //     this.activityDialog = false;
        //     if (val) {
        //         this.showSuccessDialog();
        //     }
        // },
        
        getLoginUserEmail() {
            O365Service.getO365Accounts()
            .then(response => {
                this.userEmail = response.data[0]? response.data[0].o365_user_principal_name:null;
            });

            // var user = window.localStorage.getItem('user');
            // if (user != null && user != undefined) {
            //     let obj = JSON.parse(user);
            //     return obj.email;
            // } else {
            //     return '';
            // }
        },
        async onEmitSendEmailDialog(val) {
            if (val) {
                this.showSuccessDialog();
                await this.refreshTimeLine();
            }
        },
        async onEmitSendSmsDialog(val) {
            if (val) {
                this.showSuccessDialog();
                await this.refreshTimeLine();
            }
        },
        // about article
        async getRelatedArticle(search) {
            await (this.isSearch = true);
            if (search == undefined) {
                await CaseDataService.getrelatedarticles(this.caseId, this.relatedArticleListPage)
                .then(response => {
                    this.relatedArticleList = response.data.published_articles;
                    for(let n = 0; n < this.relatedArticleList.length; n++) {
                        this.$set(this.isRelatedExpend, n, false);
                    }
                    this.relatedArticleListLength = response.data.total_page;
                })
                .catch(error => {
                    this.showErrorDialog(error);
                });
            } else {
                await CaseDataService.getrelatedarticles(this.caseId, this.relatedArticleListPage, search)
                .then(response => {
                    this.relatedArticleList = response.data.published_articles;
                    for(let n = 0; n < this.relatedArticleList.length; n++) {
                        this.$set(this.isRelatedExpend, n, false);
                    }
                    this.relatedArticleListLength = response.data.total_page;
                })
                .catch(error => {
                    this.showErrorDialog(error);
                });
            }

            setTimeout(() => {
                this.isSearch = false;
            }, 1000);
        },
        async getAllArticle(search) {
            await (this.isSearch = true);
            if (search == undefined) {
                KnowledgeArticleDataService.publishedarticles(this.articleListPage)
                .then(response => {
                    this.isAllExpend = [];
                    this.isRelated = [];
                    this.articleList = response.data.published_articles;
                    for(let n = 0; n < this.articleList.length; n++) {
                        this.$set(this.isAllExpend, n, false);
                        let index = this.originRelatedArticleList.map(function(a) { return a.id; }).indexOf(this.articleList[n].id);
                        if (index != -1) {
                            this.$set(this.isRelated, n, true);
                        } else {
                            this.$set(this.isRelated, n, false);
                        }
                    }
                    this.articleListLength = response.data.total_page;
                })
                .catch(error => {
                    this.showErrorDialog(error);
                });
            } else {
                KnowledgeArticleDataService.publishedarticles(this.articleListPage, search)
                .then(response => {
                    this.isAllExpend = [];
                    this.isRelated = [];
                    this.articleList = response.data.published_articles;
                    for(let n = 0; n < this.articleList.length; n++) {
                        this.$set(this.isAllExpend, n, false);
                        let index = this.originRelatedArticleList.map(function(a) { return a.id; }).indexOf(this.articleList[n].id);
                        if (index != -1) {
                            this.$set(this.isRelated, n, true);
                        } else {
                            this.$set(this.isRelated, n, false);
                        }
                    }
                    this.articleListLength = response.data.total_page;
                })
                .catch(error => {
                    this.showErrorDialog(error);
                });
            }

            setTimeout(() => {
                this.isSearch = false;
            }, 1000);
        },
        onExpend(index, key) {
            if (key == "RELATED") {
                this.$set(this.isRelatedExpend, index, true);
            } else if (key == "ALL") {
                this.$set(this.isAllExpend, index, true);
            }
        },
        onCloseUp(index, key) {
            if (key == "RELATED") {
                this.$set(this.isRelatedExpend, index, false);
            } else if (key == "ALL") {
                this.$set(this.isAllExpend, index, false);
            }
        },
        onBindArticle(item, index) {
            let data = {
                "knowledge_article": {
                    "id": item.id,
                    "title": item.title,
                }
            }
            CaseDataService.addrelatedarticles(this.caseDetail.id, data)
            .then(() => {
                this.originRelatedArticleList.push(item);
                if (this.relatedArticleList.length < 5) {
                    this.relatedArticleList.push(item);
                    this.$set(this.isRelated, index, true);
                } else {
                    this.relatedArticleListLength++;
                    this.$set(this.isRelated, index, true);
                }
            })
            .catch(error => {
                this.showErrorDialog(error);
            });
        },
        onUnbindArticle(item, index, key) {
            let data = {
                "id": item.id,
            }

            let i = this.originRelatedArticleList.map(function(a) { return a.id; }).indexOf(item.id);
            let r = this.relatedArticleList.map(function(a) { return a.id; }).indexOf(item.id);
            
            if (key == "ALL") {
                CaseDataService.removerelatedarticles(this.caseDetail.id, data)
                .then(() => {
                    this.originRelatedArticleList.splice(i, 1);
                    if (r != -1) {
                        this.relatedArticleList.splice(r, 1);
                        this.$set(this.isRelated, index, false);
                    } else {
                        this.$set(this.isRelated, index, false);
                    }
                })
                .catch(error => {
                    this.showErrorDialog(error);
                });
            } else if (key == "RELATED") {
                let a = this.articleList.map(function(a) { return a.id; }).indexOf(item.id);
                CaseDataService.removerelatedarticles(this.caseDetail.id, data)
                .then(() => {
                    this.originRelatedArticleList.splice(i, 1);
                    this.relatedArticleList.splice(r, 1);
                    if (a != -1) {
                        this.$set(this.isRelated, a, false);
                    }
                })
                .catch(error => {
                    this.showErrorDialog(error);
                });
            }
        },
        onChangeAllArticlePage() {
            this.getAllArticle();
        },
        onChangeRelatedArticlePage() {
            this.getRelatedArticle();
        },
        onAddProduct(){
            this.addprodcut = true
        },
        onEmitAddCaseProductDialog(data){
            this.addprodcut = false
            if(data)
            {                
                for(let i in data)
                {
                    this.caseProduct.push(data[i])
                    this.caseProductID.push(data[i].id)
                }
            }            
        },
        selectProductList(data){
            if(data.length > 0)
            {
                this.deletrProduct = true
            }
            else{
                this.deletrProduct = false
            }
            this.deleteProductData = data
        },
        productDel(data){
            let index =  this.caseProductID.indexOf(data)
            this.caseProductID.splice(index, 1)
        },
        async onDelProduct(){
            this.delMode = 'delProduct';
            this.showMessageDialog(i18n.t('PRODUCTTABLE.MESSAGE'));

            // let data = {case:this.caseId,product:[]}
            // for(let i in this.deleteProductData)
            // {
            //     data.product.push(this.deleteProductData[i].id)        
            // }

            // await CaseDataService.deletecaseproduct(data).then(()=>{
            // for(let i in this.deleteProductData)
            // {
            //     let index =  this.caseProduct.indexOf(this.deleteProductData[i])
            //     this.caseProduct.splice(index, 1)
            // }
            // this.deleteProductData = []
            // this.deletrProduct = false
            // })
        },
        // documnet
        onAddDocument(){
            this.isAddDoucment = true;
        },
        async onDelDocument(){
            this.openDelDocumentDialog = true;
        },
        onEmitDocDialog(){
            this.isAddDoucment = false;
            this.openDelDocumentDialog = false;
        },
        selectDocumentsList(data){
            if(data.length > 0)
            {
                this.isDelDocument = true;
            }
            else{
                this.isDelDocument = false;
            }
        },
        async getActivityTimeline(){
            await ActivityDataService.getActivityTimeline(this.caseId,"case")
            .then((response)=>{
                this.activityTimeline = this.arrangeTimeLine(response.data.timelineList);
                this.activityTimeline.forEach(item => {
                    item.timeConvert = this.getFormatTime(new Date(item.time));
                    item.content = item.content.replace("所有者","負責人");
                    item.content_list = item.content.split(item.activity_subject);
                })
                this.due_activity_list = response.data.due_list;
                this.caseTimeLines = this.arrangeTimeLine(this.originTimeLines.concat(this.activityTimeline));
                for( let i in this.due_activity_list){
                    let obj={
                        "id": this.due_activity_list[i].id,
                        "type":"DUE_ACTIVITY",
                        "subject": this.due_activity_list[i].subject,
                        "type_name": this.due_activity_list[i].type,
                        "is_due":this.due_activity_list[i].is_due
                    }
                    this.caseTimeLines.unshift(obj);
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
        },
        onAddActivity(){
            this.isAddActivity = true;
        },
        async onEmitAddActivity(val){
            this.isAddActivity = false;
            if(val=="refresh"){
                await this.getActivityTimeline();
                await this.showTimlinesCheck();
            }
        },
        async refreshTimeLine(){
                await this.getCaseTimeline(this.caseId);
                await this.getActivityTimeline();
                await this.showTimlinesCheck();
        },
    },
    watch: {	
        "redirectDialog":function () {
            if(this.redirectDialog == false)
            {
                this.copyDataId = null        
            }      
        },	
        async search_primary_contact(val){
            this.comboInput.primary_contact = val;

            // check user input is en / zh / num
            let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
            let isEnZhNum = false;
            pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

            // check user input data type
            let isString; 
            typeof(val) == 'string' ? isString = true : isString = false;
            let array = [];

            // search
            if(isEnZhNum && !!val && isString){
                this.isLoading.primary_contact = true;
                ContactDataService.getAll(1,val,100,[])
                .then(response => {
                    response.data.list.forEach(item => {
                        item.searchStr = item.full_name 
                                        +item.full_name_en
                                        +item.full_name_en.toLowerCase()
                                        +item.full_name_en.toUpperCase()
                                        +item.full_name_zh;
                        if(item.searchStr.includes(val)){
                            if(item.organization_set.length > 0)
                            {
                                item.org_name = "[ "+item.organization_set[0].name +" ]";
                            }
                            array.push(item);
                        }
                    })
                    this.contactData = array;
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
                .finally(()=>{
                    this.isLoading.primary_contact = false;
                })
            }
            else{
                this.contactList = [];
            }
        },
        async search_case_org(val) {
            var re = /^(?!\s*$).+/;
            var check = re.test(val);
            var checkLength = /^.{0,200}$/.test(val);
            this.comboInput.oppty_org = "";
            if(check && val != null && checkLength){
                let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                this.comboInput.case_org = val;
                if(!this.isLoading.case_org && charNum >= 2 && charNum == val.length ){
                    this.isLoading.case_org = true;
                    await OrganizationDataService.findOrganizationByNamefromDB(val)
                    .then((response) => {
                        this.organizationList = response.data;  
                        let num = 0
                        this.organizationList.forEach((item)=>{
                        item.uqNumber = num
                        num ++
                        })                                                
                    })
                    .finally(() => {
                        this.isLoading.case_org = false;
                    });
                }
                else {
                    this.isLoading.case_org = false;                        
                }
            } else {
                this.comboInput.case_org = null;
            }
        },
        async search_parent_case(val) {
            // check user input is en / zh / num
            let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
            let isEnZhNum = false;
            pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

            // check user input data type
            let isString; 
            typeof(val) == 'string' ? isString = true : isString = false;

            // search
            if(isEnZhNum && !!val && isString) {
                this.isLoading.parent_case = true;
                this.comboInput.parent_case = val
                await CaseDataService.kanabn('list', 'all', 0, 1, val,9999,'','')
                .then(response => {
                    this.parentCaseSelectList = response.data.list
                    this.parentCaseSelectList = this.parentCaseSelectList.filter((item) => {
                        if(item.subject.includes(val)) {
                            return item.id != this.caseId && item.parent_case_id != this.caseId
                        }
                    })
                })
                .finally(() => {
                    this.isLoading.parent_case = false;
                })
            }
            else{
                this.comboInput.parent_case = null
            }
        },
        isProcessClosed(){
            if(this.isProcessClosed == true){
                this.caseStatusList = caseStatusList;
            }else{
                this.caseStatusList = 
                [
                    { key: "OPEN", name: i18n.t('CASESTATUSLIST.OPEN') },
                    { key: "PENDING", name: i18n.t('CASESTATUSLIST.PENDING') },
                    { key: "WAIT_CUSTOMER", name: i18n.t('CASESTATUSLIST.WAIT_CUSTOMER') },
                    { key: "WAIT_3RD_PARTY", name: i18n.t('CASESTATUSLIST.WAIT_3RD_PARTY') },
                    { key: "RESOLVED", name: i18n.t('CASESTATUSLIST.RESOLVED') },
                ];
            }
        },
        "searchArticles": async function(val) {
            let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
            if(val != null && val != ""){
                if (charNum >= 2 && charNum == val.length && this.isSearch == false) {
                    this.isSearched = true;
                    await (this.isSearch = true);
                    //- related article list
                    this.relatedArticleListPage = 1;
                    this.getRelatedArticle(val);

                    //- all article list
                    this.articleListPage = 1;
                    this.getAllArticle(val);
                }
            } else if (this.isSearched == true) {
                //- related article list
                this.relatedArticleListPage = 1;
                await this.getRelatedArticle();

                //- all article list
                this.articleListPage = 1;
                await this.getAllArticle();
                this.isSearched = false;
            }
        },
        isDetailReadonly(val){
            this.removeMenu(val);
        }
    },
}
</script>

<style>
    .country-code div {
        padding-top: 0;
        margin-top: 0;
    }

    .vue-tel-input-vuetify div {
        padding-top: 0;
        margin-top: 0;
    }

    .hide-scrollbar textarea {
        overflow-y: hidden;
    }

    table thead tr th {
        font-size: 14px !important;
    }
</style>
<template lang='pug'>
    div#productLineItem
        v-overlay(v-show='confirm_loading' opacity='0' z-index='999')
        div
            .button-section 
                v-row
                    v-col(cols='3')
                        v-radio-group(v-model='tax_option' row mandatory dense @change='toggleTaxType' v-hide-element)
                            template(v-slot:label)
                                strong {{$t('OPPTYSFIELDLIST.tax_option')}}
                            v-radio(:label=`$t('PRODUCTLINEITEM.TAX_EXCLUSIVE')` value='TAX_EXCLUSIVE')
                            v-radio(:label=`$t('PRODUCTLINEITEM.NO_TAX')` value='NO_TAX')
                    v-col(cols='5')
                        v-radio-group(v-model='oppty_amount_type' row mandatory dense v-hide-element)
                            template(v-slot:label)
                                strong {{$t(`ORDER.${isWiadvance() ? 'WIADVANCE_TITLE' : 'TITLE'}`)}}{{$t('ORDER.AMOUNT')}}
                            v-radio(:label=`$t('PRODUCTLINEITEM.MANUAL_INPUT')` value='manual_input')
                            v-radio(:label=`$t('PRODUCTLINEITEM.SYSTEM_COMPUTE')` value='system_compute')
                    //- v-col(cols='4' class='d-flex justify-end align-center' v-if='oppty.closed_reason == 0 || oppty.closed_reason == null')
                    v-col(cols='4' class='d-flex justify-end align-center')
                        v-btn(:ripple="false" @click="onCancel" height="40" width='80' color="cancel" depressed dark class='mr-4') {{$t('GENERAL.CANCEL')}}
                        v-btn(:ripple="false" @click="onConfirm" height="40" width='80' color="green" depressed dark)
                            v-progress-circular(size='18' v-if='confirm_loading' indeterminate )
                            .t-white(v-else) {{$t('GENERAL.SAVE')}}
                v-row
                    v-col(:cols='headers.length>=10? "3":"4"')
                        p.d-flex.align-center.input-has-label
                            label {{$t('PRODUCTLINEITEM.TOTAL_PRODUCTS')}}
                            v-text-field.ellipsis(v-model='productList.length' solo readonly class='num_field') 
                    v-col(:cols='headers.length>=10? "3":"4"')
                        p.d-flex.align-center.input-has-label
                            label {{$t('PRODUCTLINEITEM.QUANTITY')}}
                            v-text-field.ellipsis(v-model='productsData.total_quantity' solo readonly class='num_field')
                    v-col(v-if='headers.length>=10' cols='3')
                        p.d-flex.align-center.input-has-label
                            label {{$t('PRODUCTLINEITEM.QUANTITY_D')}}
                            v-text-field.ellipsis(v-model='total_days' solo readonly class='num_field')
                    v-col(:cols='headers.length>=10? "3":"4"')
                        v-menu(offset-y left :attach='false')
                            template(v-slot:activator="{ on, attrs }")
                                p.d-flex.align-center.input-has-label
                                    label {{$t('PRODUCTLINEITEM.TOTAL')}}
                                    v-text-field.ellipsis(v-model='total' solo readonly class='num_field')
                                        template(v-slot:append)
                                            v-btn( icon v-bind="attrs" v-on="on")
                                                v-icon(size='24') mdi-unfold-more-horizontal
                            v-list
                                v-list-item
                                    v-list-item-title {{$t('PRODUCTLINEITEM.SUBTOTAL_LIST_PRICE')}}
                                    v-list-item-title(class='text-end') {{getFormatAmount( productsData.subtotal_list_price )}}
                                v-list-item(v-for='item, index in productsData.other_charges_data' style='min-height: 25px !important;' :key='`otherc_harges-${index}`')
                                    v-list-item-subtitle.d-flex
                                        span {{$t('PRODUCTLINEITEM.OC')}}
                                        span {{ `(${item.other_charges}%)` }}
                                    v-list-item-subtitle(class='text-end') {{getFormatAmount( item.other_charges_amount )}}
                                v-list-item
                                    v-list-item-title(:title='getFormatAmount( productsData.total_discount_rate)+"%"') {{$t('PRODUCTLINEITEM.OC_DISCOUNT')}} ({{getFormatAmount( productsData.total_other_charges_rate)}}%)
                                    v-list-item-title(class='text-end') {{getFormatAmount( productsData.total_other_charges )}}
                                v-list-item(v-for='item, index in productsData.discount_data' style='min-height: 25px !important;' :key='`discount-${index}`')
                                    v-list-item-subtitle.d-flex
                                        span {{$t('PRODUCTLINEITEM.DISCOUNT_DATA')}}
                                        span {{ `(${item.discount}%)` }}
                                    v-list-item-subtitle(class='text-end') {{getFormatAmount( item.discount_amount )}}
                                v-list-item
                                    v-list-item-title(:title='getFormatAmount( productsData.total_discount_rate)+"%"') {{$t('PRODUCTLINEITEM.TOTAL_DISCOUNT')}} ({{getFormatAmount( productsData.total_discount_rate)}}%)
                                    v-list-item-title(class='text-end') {{getFormatAmount( productsData.total_discount )}}
                                v-list-item
                                    v-list-item-title {{$t('PRODUCTLINEITEM.SUBTOTAL_AMOUNT')}}
                                    v-list-item-title(class='text-end') {{getFormatAmount( productsData.subtotal_amount )}}
                                div(v-if='tax_option == "TAX_EXCLUSIVE" && tax_data != null')
                                    v-list-item(v-for='item, index in tax_data.TAX_EXCLUSIVE' style='min-height: 25px !important;' :key='`tax-${index}`')
                                        v-list-item-subtitle.d-flex
                                            span {{$t('PRODUCTLINEITEM.TAX_DATA')}}
                                            span {{ `(${item.tax}%)` }}
                                        v-list-item-subtitle(class='text-end') {{getFormatAmount( item.tax_amount )}}
                                v-list-item(v-if='tax_option == "TAX_EXCLUSIVE" && sales_tax != null')
                                    v-list-item-title {{$t('PRODUCTLINEITEM.SALES_TAX')}}
                                    v-list-item-title(v-if='tax_option == "TAX_EXCLUSIVE"' class='text-end') {{getFormatAmount( sales_tax.TAX_EXCLUSIVE )}}
                                    v-list-item-title(v-else class='text-end') {{getFormatAmount( sales_tax.TAX_EXCLUSIVE )}}
                                v-divider(class='my-1')
                                v-list-item
                                    v-list-item-title {{$t('PRODUCTLINEITEM.TOTAL')}}
                                    v-list-item-title(class='text-end') {{ total }}
            .case-detail-table.scroll-x.pb-0
                v-form.data-area(style='position: relative;' ref='productLineItem_form')
                    v-data-table.height_100.table_in_tab( :headers='headers' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" v-sortable-data-table item-key='id' v-model="selectedRows" :items='productList' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' multi-sort height='100%' hide-default-footer='hide-default-footer' @sorted='get_sequence($event)' :loading='loading_data' loading-text='loading...' :style='table_style')
                        template(v-slot:item.product_name='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-if='!showCombobox[item.sequence - 1]' v-model='item.product_name' @focus='nameBlur(item)' :rules='formRules.nameRules' :title='item.product_name')
                                        template(v-slot:append-outer)
                                            .mr-1(v-if="isNew[item.sequence]")
                                                v-icon(color="red", size="25") mdi-new-box
                                    v-combobox(v-else v-model="combo_product" :items="productSearchList" item-text="name" item-value="id" :search-input.sync="search" :loading="comboBoxLoading" @keydown.tab='nameTabBlur(index,item)' @blur='nameComboBlur(item)' ref='combobox' :rules='formRules.nameRules')
                                        template(v-slot:item="data")
                                            div.ellipsis(v-if="data.item.id == 0")
                                                span {{ data.item.name }}
                                                v-icon.mr-1(color="red", size="25") mdi-new-box
                                            div.ellipsis(v-else)
                                                span {{ data.item.name }}
                        template(v-slot:item.price='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field(v-model.number='item.price' :title='item.price' type='number' :rules='formRules.numberRule' :input='calcPriceAndQuantity(item)' class='num_field', :ref='"price_"+index')
                        template(v-slot:item.quantity='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field(v-model.number='item.quantity' :title='item.quantity' type='number' :rules='formRules.numberRule' :input='calcPriceAndQuantity(item)' class='num_field')
                        
                        template(v-slot:item.from_date='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                        template(v-slot:activator="{ on, attrs }")
                                            v-text-field(v-model='item.from_date' :title='item.from_date'  v-bind="attrs" v-on="on"  hide-details="auto"  :rules='item.to_date ? formRules.fromToRule:[]' class='num_field' readonly clearable :disabled='!item.enable_trq' :change='toDateCheck(item)' :input='calcPriceAndQuantity(item)')
                                        v-date-picker(v-model='item.from_date' @input='menu_from = false' no-title scrollable) 
                        
                        template(v-slot:item.to_date='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                        template(v-slot:activator="{ on, attrs }")
                                            v-text-field(v-model='item.to_date' :title='item.to_date'  v-bind="attrs" v-on="on" hide-details="auto"  :rules='item.from_date ? formRules.fromToRule:[]' class='num_field' readonly clearable :disabled='!item.enable_trq' :input='calcPriceAndQuantity(item)')
                                        v-date-picker(v-model='item.to_date' @input='menu_to = false' no-title scrollable :min='minDate(item.from_date)') 
                        template(v-slot:item.days='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.text-black(:title='getDays(item)' :value='getDays(item)' type='number' readonly disabled class='num_field')
                        template(v-slot:item.unit='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field(v-model='item.unit' readonly :title='item.unit')
                        template(v-slot:item.other_charges='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field(v-model.number='item.other_charges' :title='item.other_charges' type='number' :rules='formRules.numberRule' :input='calcPriceAndQuantity(item)' class='num_field')
                        template(v-slot:item.tax='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field(v-model.number='item.tax' :title='item.tax' type='number' :rules='formRules.numberRule' class='num_field')
                        template(v-slot:item.discount='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field(v-model.number='item.discount' :title='item.discount' type='number' :rules='formRules.numberRule' @input='calcDiscount(item)' class='num_field')
                        template(v-slot:item.net_amount='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field(v-model.number='item.net_amount' :title='item.net_amount' type='number' :rules='formRules.numberRule' @input='calcAmount(item)' :readonly='item.price == 0 || item.quantity == 0 ? true : false' class='num_field')
                        template(v-slot:item.active='{ item }')
                            //- v-btn(:disabled='oppty.closed_reason == 0 || oppty.closed_reason == null ? false : true' icon='icon' @click='onDelete(item)')
                            v-btn(icon='icon' @click='onDelete(item)')
                                v-icon(size='18') icon-remove
            .action-section
                v-pagination.py-1.mb-4(v-model='page' :length='pageCount' circle :total-visible="10" )
</template>
<script>
import Vue from "vue";
import Sortable from "sortablejs";
import ProductDataService from "@/services/ProductDataService";
import ProductLineItemService from "@/services/ProductLineItemService";
import messageDialog from '@/components/Dialog/messageDialog';
import i18n from '/common/plugins/vue-i18n.js';

export default Vue.extend({ 
    props: {
        isAddProduct:{
            type: Boolean,
            require: false,
        },
        order:{
            type: Object,
            require: true,
        },
    },
    components: {
        messageDialog,
    },
    data() {
        return {
            selectedRows:[],
            message:'',
            messageDialog:false,
            page: 1,
            pageCount: 0,
            itemPerPage: 10,
            headers: [],
            headers_no_trq: [
                {  text: i18n.t('PRODUCTSETTING.PRODUCT'), value: 'product_name', align: 'center', width: "20%",},
                {  text: i18n.t('PRODUCTSETTING.PRICR'), value: 'price', align: 'center', width: "15%",},
                {  text: i18n.t('PRODUCTLINEITEM.QUANTITY_H'), value: 'quantity', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.OTHER_CHARGES')+" (%)", value: 'other_charges', align: 'center',width: "10%",},
                // {  text: i18n.t('PRODUCTLINEITEM.UNIT'), value: 'unit', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.DISCOUNT_H')+" (%)", value: 'discount', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.TAX_H')+" (%)", value: 'tax', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.AMOUNT_H'), value: 'net_amount', align: 'center', width: "18%",},
                {  text: i18n.t('GENERAL.ACTIVE'), value: 'active', align: 'center', sortable: false, width: "60",},
            ],
            headers_trq: [
                {  text: i18n.t('PRODUCTSETTING.PRODUCT'), value: 'product_name', align: 'center', width: "14%",},
                {  text: i18n.t('PRODUCTSETTING.PRICR'), value: 'price', align: 'center', width: "10%",},
                {  text: i18n.t('PRODUCTLINEITEM.FROM'), value: 'from_date', align: 'center', width: "13%",},
                {  text: i18n.t('PRODUCTLINEITEM.TO'), value: 'to_date', align: 'center', width: "13%",},
                {  text: i18n.t('PRODUCTLINEITEM.QUANTITY_H'), value: 'quantity', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.DAYS'), value: 'days', align: 'center', width: "6.6%",},
                {  text: i18n.t('PRODUCTLINEITEM.OTHER_CHARGES')+" (%)", value: 'other_charges', align: 'center',width: "8%",},
                {  text: i18n.t('PRODUCTLINEITEM.DISCOUNT_H')+" (%)", value: 'discount', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.TAX_H')+" (%)", value: 'tax', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.AMOUNT_H'), value: 'net_amount', align: 'center', width: "10%",},
                {  text: i18n.t('GENERAL.ACTIVE'), value: 'active', align: 'center', sortable: false, width: "60",},
            ],
            formRules: {
                nameRules: [
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                ],
                numberRule: [(v) => /^\d+(\.\d+)?$/.test(v) || i18n.t('RULE.RULE_R_NUM_5')],
                fromToRule: [
                    (v) => !!v || i18n.t('PRODUCTLINEITEM.FROMTOERROR'),
                    (v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE')
                ],
            },
            loading_data: true,
            productSearchList: [],
            productsData: [],
            productList: [],
            productList_origin: [],
            tax_option: 'TAX_EXCLUSIVE',
            oppty_amount_type: 'system_compute',
            oppty_amount: null,
            combo_product: null,
            showCombobox: [],
            isNew: [],
            comboBoxLoading: false,
            confirm_loading: false,
            search: null,
            total: null,
            update_data: {
                "create": [],
                "delete": [],
                "edit": []
            },
            count: -1,
            sales_tax: null,
            tax_data: null,
            menu_from: false,
            menu_to: false,
            table_style:"",
            clickTab:false,
            total_days:0,
        }
    },
    async created(){
        this.headers = this.headers_no_trq;
        this.table_style = "padding-bottom: 45px;min-width: 760px;"
        ProductLineItemService.getProductLineItem(this.order.id)
        .then((response) => {
            this.loading_data = true;
            this.tax_option = JSON.parse(JSON.stringify(this.order.tax_option));
            this.refineData(response);
            for(let i in response.data.product_list){
                if(response.data.product_list[i]['enable_trq']){
                    this.headers = this.headers_trq;
                    this.table_style = "padding-bottom: 45px;min-width: 1120px;"
                    break;
                }
            }
        })
        .then(()=>{
            this.getisNewData();
        })
        .then(()=>{
            this.toggleTaxType();
            this.loading_data = false;
        })
    },
    methods: {
        getTotalWithDays(){
            const initialValue = 0;
            this.total_days = this.productList.reduce((a,b)=> {
                if(b.from_date && b.to_date){
                    return a + this.DateDiff(b.from_date,b.to_date)*b.quantity;
                }
                return a + parseInt(b.quantity);
            }
            ,initialValue)
        },
        getDays(item){
            if(item.from_date && item.to_date){
                item.days = this.DateDiff(item.from_date,item.to_date);
                return item.days;
            }
        },
        nameTabBlur(index,item){
            this.clickTab = true;
            const setName = new Promise((resolve) => {
                if(this.combo_product!= null){
                    item.product_name = this.combo_product.name;
                    item.product = this.combo_product.id;
                    item.enable_trq = this.combo_product.enable_trq;
                    item.price = this.combo_product.unit_price != null ? this.combo_product.unit_price : 0;
                    item.tax = this.combo_product.tax != null ? this.combo_product.tax : 0;
                    item.unit = this.combo_product.unit != null ? this.combo_product.unit : null;
                    item.other_charges = this.combo_product.other_charges != null ? this.combo_product.other_charges : 0;
                    item.from_date = this.combo_product.from_date != null ? this.combo_product.from_date : null;
                    item.to_date = this.combo_product.to_date != null ? this.combo_product.to_date : null;

                    item.sequence = index;
                    this.isNew[index] = false;
                }
                else if(this.combo_product == null && this.search != null){
                    if(this.search.trim() != ''){
                        item.enable_trq = false;
                        item.price = 0;
                        item.tax = 0;
                        item.unit = null;
                        item.other_charges = 0;
                        item.from_date = null;
                        item.to_date = null;
                        item.product = {name: this.search}
                        item.product_name = this.search;
                        this.isNew[index] = true;
                    }
                }
                resolve();
            })

            setName
            .then(()=>{
                this.combo_product = null;
                this.productSearchList = [];
                this.$set(this.showCombobox, (index - 1), false);
            })
            this.toggleFromTo();
            setTimeout(()=>{
                this.$refs["price_"+index].focus();
            },0)
        },
        minDate(date){
            let current_date = new Date(date).getTime();
            let min = current_date + 1000*60*60*24;
            return (new Date(min).getFullYear()).toString() +'-' +(new Date(min).getMonth()+1).toString().padStart(2,0)+'-'+ (new Date(min).getDate()).toString().padStart(2,0)
        },
        toDateCheck(item){
            if(item.from_date){
                let FD = new Date(item.from_date).getTime();
                let TD = new Date(item.to_date).getTime();
                if(item.to_date && (TD-FD)<=0){
                    item.to_date = this.minDate(item.from_date);
                }
            }
        },
        validate(){
            this.$refs.productLineItem_form.validate();
        },
        refineData(response){
            response.data.product_list.sort(function(a, b){
                return a.sequence - b.sequence
            });
            this.productList = response.data.product_list;
            this.productsData = response.data;
            this.productList_origin = JSON.parse(JSON.stringify(response.data.product_list));
            this.tax_option == "TAX_EXCLUSIVE" ? this.total = this.getFormatAmount(this.productsData.Total.TAX_EXCLUSIVE) : this.total = this.getFormatAmount(this.productsData.Total.OTHER);
            this.sales_tax = this.productsData.sales_tax;

            this.productsData.tax_data['TAX_EXCLUSIVE'].sort(function(a, b){
                return b.tax - a.tax
            });
            this.productsData.tax_data['TAX_INCLUSIVE'].sort(function(a, b){
                return b.tax - a.tax
            });
            this.tax_data = this.productsData.tax_data;
            this.productsData.discount_data = this.productsData.discount_data.sort(function(a, b){
                return b.discount - a.discount
            });
            this.getTotalWithDays();
        },
        async onCreate(){
            let lineItem = {
                "product_name": null,
                "id": this.count,
                "created_on": null,
                "updated_on": null,
                "price": 0,
                "quantity": 0,
                "tax": 0,
                "discount": 0,
                "net_amount": 0,
                "unit": null,
                "sequence": 0,
                "unit_cost": 0,
                "status": true,
                "order": this.order.id,
                "product": null,
                "other_charges": 0,
                "from_date":null,
                "to_date":null,
            }

            this.count--;
            await this.productList.unshift(lineItem);
            await this.isNew.unshift(false);
            this.$emit('emitAddProduct');
            await this.getComboboxData();
        },
        async onCancel(){
            this.productList = await JSON.parse(JSON.stringify(this.productList_origin));
            await this.getisNewData();
            this.refine_updateData();
            await this.toggleFromTo();
        },
        onConfirm(){
            if(this.$refs.productLineItem_form.validate()){
                const getData = new Promise((resolve) => {
                    this.getCreateData();
                    this.getEditData();
                    this.confirm_loading = true;
                    resolve();
                })

                getData
                .then(()=>{
                    this.$emit('emit_editFromProdcutLine', 'tax_option', this.tax_option);
                })
            }
        },
        updateProductLine(){
            ProductLineItemService.updateProductLineItem(this.order.id, this.update_data)
            .then((response) => {
                this.refineData(response);
                if(this.oppty_amount_type=="system_compute"){
                    let data;
                    if(this.tax_option == "TAX_EXCLUSIVE"){
                        data = this.productsData.Total.TAX_EXCLUSIVE;
                    }else{
                        data = this.productsData.Total.OTHER;
                    }   
                    this.$emit('emit_editFromProdcutLine', 'system_compute', data);
                }
            })
            .then(() => {
                this.getisNewData();
                this.refine_updateData();
            })
            .finally(() => {
                this.confirm_loading = false;
            })
            .catch((response) => {
                console.log(response)
            })
        },
        async onDelete(item){
            if(item.id > 0){
                this.update_data.delete.push(item.id);
            }
            this.productList = await this.productList.filter(e => e != item);
            await this.getisNewData();
            await this.getComboboxData();
            await this.toggleFromTo();
        },
        getCreateData(){
            this.update_data.create = JSON.parse(JSON.stringify(this.productList.filter(e => e.id < 0)));
            for(let i in this.update_data.create){
                this.update_data.create[i].id = null;
                delete this.update_data.create[i].product_name;
            }
        },
        getEditData(){
            this.update_data.edit = JSON.parse(JSON.stringify(this.productList.filter(e => e.id > 0)));
        },
        getComboboxData(){
            this.showCombobox = [];
            for(let i = 0; i < this.productList.length; i++){
                this.showCombobox.push(false);
                this.$set(this.productList[i], 'sequence', i);
            }
        },
        async getisNewData(){
            this.isNew = [];
            for(let i = 0; i < this.productList.length; i++){
                if(typeof(this.productList[i].product) == 'object' && this.productList[i].product != null){
                    this.isNew.push(true);
                }
                else{
                    this.isNew.push(false);
                }
            }
        },
        refine_updateData(){
            this.update_data = {
                "create": [],
                "delete": [],
                "edit": []
            }
        },
        async nameBlur(item){
            await this.$set(this.showCombobox, (item.sequence - 1), true);
            const combobox = await this.$refs.combobox;
            await combobox.focus();
        },
        nameComboBlur(item){
            if(this.clickTab){
                this.clickTab = false;
                return;
            }
            const setName = new Promise((resolve) => {
                if(this.combo_product!= null){
                    item.product_name = this.combo_product.name;
                    item.product = this.combo_product.id;
                    item.enable_trq = this.combo_product.enable_trq;
                    item.price = this.combo_product.unit_price != null ? this.combo_product.unit_price : 0;
                    item.tax = this.combo_product.tax != null ? this.combo_product.tax : 0;
                    item.unit = this.combo_product.unit != null ? this.combo_product.unit : null;
                    item.other_charges = this.combo_product.other_charges != null ? this.combo_product.other_charges : 0;
                    item.from_date = this.combo_product.from_date != null ? this.combo_product.from_date : null;
                    item.to_date = this.combo_product.to_date != null ? this.combo_product.to_date : null;

                    this.isNew[item.sequence] = false;
                }
                else if(this.combo_product == null && this.search != null){
                    if(this.search.trim() != ''){
                        item.product = {name: this.search}
                        item.product_name = this.search;
                        this.isNew[item.sequence] = true;
                    }
                }
                resolve();
            })

            setName
            .then(()=>{
                this.combo_product = null;
                this.productSearchList = [];
                this.$set(this.showCombobox, (item.sequence - 1), false);
            })
            this.toggleFromTo();
        },
        get_sequence(event){
            const getNewSeq = new Promise((resolve) => {
                this.showCombobox = [];
                const movedItem = this.productList.splice(event.oldIndex,1)[0];
                this.productList.splice(event.newIndex, 0, movedItem);
                resolve();
            })
            
            getNewSeq
            .then(()=>{
                for(let i in this.productList){
                    this.showCombobox.push(false);
                    this.productList[i].sequence = Number(i);
                }
            })
            .then(()=>{
                this.getisNewData();
            })
        },
        DateDiff(date1, date2){
            let strDate, oDate1, oDate2, result
            strDate = date1.split("/");
            oDate1 = new Date(strDate[1] + '/' + strDate[2] + '/' + strDate[0]);
            strDate = date2.split("/");
            oDate2 = new Date(strDate[1] + '/' + strDate[2] + '/' + strDate[0]);
            result = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); 
            return result;
        },
        calcPriceAndQuantity(item){
            let day = 0
            let amount = 0
            if(item.enable_trq && item.from_date && item.to_date){
                day = this.DateDiff(item.from_date,item.to_date);
            }
            if(item.enable_trq && day != 0){
                amount = (Number(item.price) * Number(day) * Number(item.quantity)) * ((100 + Number(item.other_charges))/100) * ((100 - Number(item.discount))/100);
            }else{
                amount = (Number(item.price) * Number(item.quantity)) * ((100 + Number(item.other_charges))/100) * ((100 - Number(item.discount))/100);
            }
            item.net_amount = Number(amount).toFixed(2);
        },
        calcDiscount(item){
            let day = 0
            let amount = 0
            if(item.enable_trq && item.from_date && item.to_date){
                day = this.DateDiff(item.from_date,item.to_date);
            }
            if(item.enable_trq && day != 0){
                amount = (Number(item.price) * Number(day) * Number(item.quantity)) * ((100 + Number(item.other_charges))/100) * ((100 - Number(item.discount))/100);
            }else{
                amount = (Number(item.price) * Number(item.quantity)) * ((100 + Number(item.other_charges))/100) * ((100 - Number(item.discount))/100);
            }
            item.net_amount = Number(amount).toFixed(2);
        },
        calcAmount(item){
            let day = 0
            let discount = 0
            if(item.enable_trq && item.from_date && item.to_date){
                day = this.DateDiff(item.from_date,item.to_date);
            }
            if(item.enable_trq && day != 0){
                discount = (1 - (Number(item.net_amount) / (Number(item.price) * Number(item.quantity) * Number(day) * ((100 + Number(item.other_charges))/100)))) * 100;
            }else{
                discount = (1 - (Number(item.net_amount) / (Number(item.price) * Number(item.quantity) * ((100 + Number(item.other_charges))/100)))) * 100;
            }
            item.discount = Number(discount).toFixed(2);
        },
        toggleTaxType(){
            if(this.tax_option == 'TAX_EXCLUSIVE'){
                let taxHeader = this.headers.find(e => e.value == 'tax');
                const newTaxHeader= {  
                    text: i18n.t('PRODUCTLINEITEM.TAX') + ' (%)', 
                    value: 'tax',
                    align: 'center',
                }

                if(taxHeader == undefined){
                    this.headers.splice(-2, 0, newTaxHeader)
                }
                this.total = this.getFormatAmount(this.productsData.Total.TAX_EXCLUSIVE);
            }
            else if(this.tax_option == 'NO_TAX'){
                this.headers = this.headers.filter(e => e.value != 'tax');
                this.total = this.getFormatAmount(this.productsData.Total.OTHER)
            }
        },
        toggleFromTo(){
            let show = false;
            for(let i in this.productList){
                if(this.productList[i].enable_trq){
                    show = true;
                }
                if(show){
                    break;
                }
            }
            if(show){
                this.headers = this.headers_trq;
                this.table_style = "padding-bottom: 45px;min-width: 1120px;"
            }else{
                this.headers = this.headers_no_trq;
                this.table_style = "padding-bottom: 45px;min-width: 760px;"
            }
            this.toggleTaxType()
        },
    },
    directives: {
        sortableDataTable: {
            bind(el, binding, vnode) {
                const options = {
                animation: 150,
                    onUpdate: function (event) {
                        vnode.child.$emit("sorted", event);
                    },
                };
                Sortable.create(el.getElementsByTagName("tbody")[0], options);
            },
        },
    },
    watch:{
        isAddProduct(val){
            if(val){
                this.onCreate();
            }
        },
        async search(val) {
            var re = /^(?!\s*$).+/;
            var check = re.test(val)
            if(check && val != null){
                let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                if(!this.comboBoxLoading && charNum>=1 && charNum==val.length ){
                    this.comboBoxLoading = true;
                    await ProductDataService.list(1, val, 100, [])
                    .then((response)=>{
                        this.productSearchList = response.data.list;
                    })
                    .finally(() => {
                        this.comboBoxLoading = false;
                    });
                }
                else {
                    this.comboBoxLoading = false;                        
                }
            }
        }
    }
})
</script>

<style lang="scss">
.text-black input{
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
<template lang='pug'>
    v-menu( offset-y :nudge-width="300" max-height="440" max-width="444" :close-on-content-click="false")
        template(v-slot:activator="{ on, attrs }")
            v-btn(v-hide-element='false' v-bind="attrs" v-on="on" height='38' color='white' depressed tile dark)
                v-icon.pa-1(size='16') mdi-image-filter-vintage
                span(v-if='!is_order') {{$t('GENERAL.SETTING_BTN')}}
                span(v-else) {{$t('SETTING.FILTERSETTING')}}
        v-card(max-height="420" min-height='360' outlined elevation='0')
            v-row(:class='showSettingTab()')
                v-col(cols='12' class='pb-0 my-3')
                    v-tabs( fixed-tabs v-model="setting_btn_tab" slider-color='primary')
                        v-tab(@click='change_custom("view")' :disabled='kanban' href="#tab-custom_view") {{$t('GENERAL.DATASHEET')}}
                        v-tab(@click='change_custom("filter")' href="#tab-custom_filter") {{$t('SETTING.FILTERSETTING')}}
            v-row(class='ma-0')
                v-tabs-items(v-model="setting_btn_tab"  class='w-100')
                    v-tab-item(value="tab-custom_view")
                        v-col(cols='12' md='12' class='pt-0')
                            v-card(height="330" max-height="330" class='scroll' outlined elevation='0')
                                v-row(no-gutters class='pa-3')
                                    v-col(cols="8").d-flex.justify-start 
                                        v-card-title(class="primary--text font-weight-bold").d-flex.align-center.justify-center.pa-0 {{$t('GENERAL.DATASHEET')}}
                                    v-col(cols="4" ).d-flex.justify-end.align-center
                                            v-btn(v-hide-element='false' @click='onAddView()' :disabled='!user.is_staff' class='pr-0' width='150' height='38' color='green' plain border-0)
                                                v-icon.mr-1(size='14') icon-add
                                                span {{$t('TABLESETTING.NEW')}}
                                v-divider.ma-0
                                v-card-text(height='100' max-height='260' class='custom-filter-style pa-0 ')
                                    v-list(max-height='260' class='pa-0')
                                        v-list-item(v-for='item, index in tables' :key='item.index' @click='changeTable(item)' :class='item.fields === currentTable.fields ? "primary": ""')
                                            v-row.ma-0
                                                v-col(cols='8' class='d-flex align-center justify-start')
                                                    span.ellipsis.list-item-txt(:class='item.fields === currentTable.fields ? "white--text": "black--text"' :title='item.name') {{ item.name }}
                                                v-col(cols='4' class='d-flex align-center justify-end')
                                                    v-btn(v-hide-element='false' @click='showCopyTableDialog(item)' icon height='0' max-width='30' min-width='30' color='transparent' :disabled='!user.is_staff')
                                                        v-icon(size='16' :class='item.fields === currentTable.fields ? "white--text": "black--text"' v-show='item.id != 0') mdi-content-copy
                                                    v-btn(v-hide-element='false' @click='showEditTableDialog(item)' icon height='0' max-width='30' min-width='30' color='transparent' :disabled='!canEdit_view(item)')
                                                        v-icon(size='16' :class='item.fields === currentTable.fields ? "white--text": "black--text"') icon-edit                
                    v-tab-item(value="tab-custom_filter")
                        v-col(cols='12' md='12' :class='showSettingTab()?"":"pt-0"')
                            v-card(height="330" max-height="330" class='scroll' outlined elevation='0')
                                v-row(no-gutters class='pa-3')
                                    v-col(cols="8").d-flex.justify-start 
                                        v-card-title(class="primary--text font-weight-bold d-flex align-center justify-center pa-0") {{$t('FILTERSETTING.FILTER')}}
                                    v-col(cols="4").d-flex.justify-end.align-center
                                            v-btn(v-hide-element='false' @click='onAddFilter()' class='pr-0' width='120' height='38' color='green' plain border-0)
                                                v-icon.mr-1(size='14') icon-add
                                                span {{$t('FILTERSETTING.ADD')}}
                                v-divider.ma-0
                                v-card-text(height='100' max-height='260' class='custom-filter-style pa-0 ')
                                    v-list(max-height='260' class='pa-0')
                                        v-list-group(v-for="item in filter_list_items" :key="item.title" v-model="item.active" :prepend-icon="item.action" no-action max-height='260' :ripple='false')
                                            template(v-slot:activator)
                                                v-list-item-content
                                                    v-list-item-title(v-text="item.title" class='font-weight-bold')
                                            v-list-item( v-for='filter in item.items' :key='filter.id' @click='changeFilter(filter)' :class='filter.id == default_filter.id ? "primary": ""')
                                                v-row.ma-0
                                                    v-col(cols='8' class='d-flex align-center justify-start px-0')
                                                        span.ellipsis.list-item-txt(:class='filter.id == default_filter.id ? "white--text": "black--text"' :title='filter.name') {{ filter.name }}
                                                    v-col(cols='4' class='d-flex align-center justify-end')
                                                        v-btn(v-hide-element='false' @click='showCopyFilterDialog(filter)' icon height='0' max-width='30' min-width='30' color='transparent' :disabled='filter.id == 0')
                                                            v-icon(size='16' :class='filter.id == default_filter.id ? "white--text": "black--text"') mdi-content-copy
                                                        v-btn(v-hide-element='false' @click='showEditFilterDialog(filter)' icon height='0' max-width='30' min-width='30' color='transparent'  :disabled='!canEdit_filter(filter)')
                                                            v-icon(size='16' :class='filter.id == default_filter.id ? "white--text": "black--text"' ) icon-edit
        v-dialog(v-model='addFilterDialog' width='800' content-class='statusDialog' v-if='addFilterDialog' scrollable)
            add-filter-dialog(@emitAddFilterDialog='onEmitAddFilter' :class-name='tab' :stage-type='slcStgType' :stage-type-list='stageTypeList' :customField='CFList')
        v-dialog(v-model='editFilterDialog' width='800' content-class='statusDialog' v-if='editFilterDialog' scrollable persistent)
            edit-filter-dialog(@emitEditFilterDialog='onEmitEditFilter' @emitCopyFilterDialog='onEmitCopyFilter' :custom-filter-data ='editData' :class-name='tab' :stage-type='slcStgType' :stage-type-list='stageTypeList' :customField='CFList' :editMode='editMode')
        v-dialog(v-model='addTableDialog' width='360' content-class='statusDialog' v-if='addTableDialog')
            add-table-dialog(@emitAddTableDialog='onEmitAddTableDialog' @emitAddTable='onEmitAddTable' :class-name='tab' :stage-type='slcStgType' :stage-type-list='stageTypeList' :custom-views='customViews')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
</template>
<script>
import addTableDialog from "@/components/Dialog/Setting/addTableDialog";
import StageDataService from "@/services/StageDataService";
import CustomFieldDataService from "@/services/CustomFieldDataService";
import errorDialog from '@/components/Dialog/errorDialog';
import addFilterDialog from '@/components/Dialog/Setting/addFilterDialog';
import editFilterDialog from "@/components/Dialog/Setting/editFilterDialog";
import successDialog from "@/components/Dialog/successDialog";
import i18n from '/common/plugins/vue-i18n.js';

export default {
    components: {
        errorDialog,
        addTableDialog,
        addFilterDialog,
        editFilterDialog,
        successDialog,
    },
    props: {
        tables: {
            type: Array,
            required: true,
        },
        filter_list: {
            type: Array,
            required: true,
        },
        currentTable: {
            type: Object,
            required: true,
        },
        default_filter: {
            type: (Object,null),
            required: true,
        },
        is_staff: {
            type: Boolean,
            required: true,
        },
        tab:{
            type: String,
            required: true,
        },
        slcStgType:{
            type: Object,
            required: false,
        },
        stageTypeList:{
            type: Array,
            required: false,
        },
        mode:{
            required: false,
        },
        customViews: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            // dialog
            errorDialog: false,
            errorMessage: '',
            addFilterDialog: false,
            editFilterDialog: false,
            addTableDialog: false,
            successDialog: false,
            // other
            select_datasheet:true,
            select_filter:false,
            user: JSON.parse(window.localStorage.getItem('user')),
            filter_list_items: [
                    {
                        action: 'mdi-lock-open',
                        items: [{ id: '',name: i18n.t('FILTERSETTING.NO_FILTER') }],
                        title: i18n.t('FILTERSETTING.PUBLIC'),
                        active: true,
                    },
                    {
                        action: 'mdi-lock',
                        items: [],
                        title: i18n.t('FILTERSETTING.PRIVATE'),
                        active: true,
                    }
            ],
            stageTypes: [],
            CFList: null,
            caseStageType: null,
            dealStageType: null,
            allCustomField: [],
            customFilters: [],
            have_edit:null,
            kanban:false,
            setting_btn_tab: 'tab-custom_filter',
            is_order:false,
        }
    },
    async created(){
        if(this.tab.includes("Order")){
            this.is_order = true;
        }
        if(this.default_filter == null){
            this.filter_list_items[0].active = false;
            this.filter_list_items[1].active = false;
            this.$emit('emitFilter',{ id: '',name: i18n.t('FILTERSETTING.NO_FILTER')}, 'applyFilter')
        }else{
            if(this.default_filter.id =="" || this.default_filter.public){
                this.filter_list_items[0].active = true;
                this.filter_list_items[1].active = false;
            }else{
                this.filter_list_items[0].active = false;
                this.filter_list_items[1].active = true;
            }
        }
        await (this.rearrageFilter());
        await (this.get_add_data());
        if(this.tab =="Case" &&this.mode!='list')
        {
            this.not_list();
        }
        if(this.tab=="Opportunity" && this.mode.list!= true)
        {
            this.not_list();
        }
    },
    methods: {
        showSettingTab(){
            if(this.is_order){
                return "d-none";
            }
            else if(this.tab == 'Opportunity' || this.tab == 'Case'){
                if(this.mode == "list" || this.mode.list){
                    return ""
                }
                else{
                    return "d-none";
                }
            }
            else{
                return "";
            }
        },
        rearrageFilter(){
            this.filter_list_items[0].items = [];
            this.filter_list_items[1].items = [];
            for(let i in this.filter_list){
                if(this.filter_list[i].public){
                    this.filter_list_items[0].items.push(this.filter_list[i]);
                }
                else{
                    this.filter_list_items[1].items.push(this.filter_list[i]);
                }
            }
            this.filter_list_items[0].items.unshift({ id: '',name: i18n.t('FILTERSETTING.NO_FILTER') })
        },
        getCustomField(){
            if(this.is_order){
                this.CFList = [];
            }
            else if(this.tab == "Contact" || this.tab == "Organization"){
                this.CFList = this.allCustomField.find(e => e.class_name == this.tab).definition;
            }else{
                let def = this.allCustomField.find(e => e.class_name == this.tab).definition;
                let alldef = null;
                if(def != null){
                    try{
                        // this.CFList = def.find(i => i.stage_type_id == this.slcStgType.id).data;
                        alldef = def.filter(i => i.stage_type_id == this.slcStgType.id || i.stage_type_id == 'all');
                        if(alldef.length==1){
                            this.CFList = alldef[0].data;
                        }else{
                            this.CFList = {}
                            for(let i in alldef){
                                this.CFList = Object.assign(this.CFList,alldef[i].data)
                            }
                        }
                    }
                    catch{
                        this.CFList = null;
                    }
                }
            }
        },
        not_list(){
            if( this.mode!='list' || this.mode.list!= true)
            {
                this.kanban=true;
                this.select_datasheet =false;
                this.select_filter =true;
                this.setting_btn_tab = 'tab-custom_filter';
            }
            if( this.mode=='list' || this.mode.list== true)
            {
                this.kanban=false;
                this.select_datasheet =true;
                this.select_filter =false;
            }
        },
        change_custom(mode){
            if(mode=='view'){
                this.select_datasheet =true;
                this.select_filter =false;
            }else{
                this.select_datasheet =false;
                this.select_filter =true;
            }
        },
        async changeTable(item){
            this.$emit('emitView',item);
        },
        async changeFilter(item){
            this.$emit('emitFilter',item, 'applyFilter');
        },
        showEditTableDialog(item){
            this.$emit('emitView', item, "edit");
        },
        showCopyTableDialog(item){
            this.$emit('emitView', item, "copy");
        },
        async showEditFilterDialog(item){
            await this.getCustomField();
            this.editMode = 'EDIT';
            this.editData = item;
            this.editFilterDialog = true;
        },
        async showCopyFilterDialog(item){
            await this.getCustomField();
            this.editMode = 'COPY';
            this.editData = JSON.parse(JSON.stringify(item));
            this.editData.name = this.editData.name + '-Copy';
            this.editFilterDialog = true;
        },
        onEmitCopyFilter(val, data){
            if(val){
                this.$emit('emitFilter', data, 'addFilter');
                this.showSuccessDialog();
            }
            this.editFilterDialog = false;
        },
        showSuccessDialog() {
            this.successDialog = true;
        },
        onEmitSuccessDialog() {
            this.successDialog = false;
        },
        async onAddFilter(){
            // await (this.get_add_data())
            await this.changeTab();
            await this.getCustomField();
            await (this.addFilterDialog = true)
        },
        onAddView(){
            this.addTableDialog = true;
        },
        onEmitAddFilter(val, data, contact_list, org_list, product_list, case_list){
            if(val){
                if(contact_list != {}){
                    data.contactList = {};
                    for(let c in contact_list){
                        data.contactList[c] = contact_list[c];
                    }
                }
                if(org_list != {}){
                    data.orgList = {};
                    for(let o in org_list){
                        data.orgList[o] = org_list[o];
                    }
                }
                if(product_list != {}){
                    data.productList = {};
                    for(let p in product_list){
                        data.productList[p] = product_list[p];
                    }
                }
                if(case_list != {}){
                    data.caseList = {};
                    for(let pc in case_list){
                        data.caseList[pc] = case_list[pc];
                    }
                }
                this.$emit('emitFilter', data, 'addFilter');
            }
            this.addFilterDialog = false;
        },
        onEmitEditFilter(confirmEdit, orignData, editedData){
            
            if(confirmEdit){
                this.have_edit = editedData;
                if(this.have_edit){
                    this.$emit('emitFilter',this.have_edit, 'applyFilter');
                    this.have_edit = null;
                }
            }
            else if(!confirmEdit){
                if(orignData != undefined && editedData != undefined){
                    this.$emit('emitFilter', orignData, 'cancelEdit');
                }
            }
            this.editFilterDialog = false;
        },
        async get_add_data(){
            
            await this.get_stageList();
            // get custom field
            await CustomFieldDataService.getCustomFieldDetail("Setting")
            .then((response) => {
                this.allCustomField = response.data;
            })
            .catch((response) => {
                this.showErrorDialog(response);
            });
        },
        async get_stageList(){
            let arr = ['case', 'deal'];
            await( arr.forEach(e => {
                StageDataService.stageList(e)
                .then(response => {
                    let stage = {stage_type_name: i18n.t('GENERAL.ALL'), id: 'all'};
                    if(e == 'case'){
                        this.caseStageType = response.data;
                        this.caseStageType.unshift(stage);
                    }
                    else if(e == 'deal'){
                        this.dealStageType = response.data;
                        this.dealStageType.unshift(stage);
                    }
                })
                .catch((response)=>{
                    this.showErrorDialog(response)
                })
            }))
        },
        async changeTab(){
            if(this.tab == 'Case' || this.tab == 'Opportunity'){
                this.tab == 'Case' ? this.stageTypes = this.caseStageType : this.stageTypes = this.dealStageType;
            }
            else{
                // this.slcStgType = null;
            }
        },
        canEdit_view(item){
            let is_seed_data = item.owner == null ? true : false;
            let is_created_by_this_user = item.owner != null ? item.owner.id == this.user.userid : false;
            let this_user_is_admin = this.user.is_staff;
            let is_NO_FILTER = item.id=='';

            if(is_NO_FILTER){
                return false;
            }
            else if(is_seed_data){
                if(this_user_is_admin){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                if(this_user_is_admin){
                    return true;
                }else{
                    if(is_created_by_this_user){
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        },
        canEdit_filter(item){
            let is_seed_data = item.owner == null ? true : false;
            let is_created_by_this_user = item.owner != null ? item.owner.id == this.user.userid : false;
            let this_user_is_admin = this.user.is_staff;
            let is_NO_FILTER = item.id=='';
            if(this.tab == "Opportunity" || this.tab == "Case"){
                if(item.stage_type_id != this.slcStgType.id){
                    return false;
                }
            }
            if(is_NO_FILTER){
                return false;
            }
            else if(is_seed_data){
                if(this_user_is_admin){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                if(this_user_is_admin){
                    return true;
                }else{
                    if(is_created_by_this_user){
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        },
        canDelete_filter(item){
            let is_seed_data = item.owner == null ? true : false;
            let is_created_by_this_user = item.owner != null ? item.owner.id == this.user.userid : false;
            let this_user_is_admin = this.user.is_staff;

            if(is_seed_data){
                return false;
            }
            else{
                if(this_user_is_admin){
                    return true;
                }
                else{
                    if(is_created_by_this_user){
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        },
        onEmitAddTableDialog(val){
            if(val){
                this.showSuccessDialog();
            }
            this.addTableDialog = false;
        },
        onEmitAddTable(data, first_time){
            let item = {
                id: data.id,
                name: data.definition[0].name,
                is_default: data.definition[0].is_default,
                fields: data.definition[0].fields,
                owner: data.owner,
                view_id : data.definition[0].id,
            }
            if(first_time){
                this.$emit('emitView', item)
            }
            else{
                this.$emit('emitView', item, "add");
            }
            
            // onEmitView
            this.tables.unshift(item);
        },
    },
    watch:{
        'mode':function(){
            this.not_list()
        },
        'mode.list':function(){
            this.not_list()
        },
        filter_list: {
            handler(){
                this.rearrageFilter();
                if(this.default_filter == null){
                    this.$emit('emitFilter',{ id: '',name: i18n.t('FILTERSETTING.NO_FILTER') }, 'applyFilter')
                    return;
                }
                if(this.default_filter != null){
                    if(this.default_filter.public){
                        this.filter_list_items[0].active = true;
                    }else{
                        this.filter_list_items[1].active = true;
                    }
                    return;
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
    .noHover{
        background-color: orange;
        &:not(.disable):hover {
            background-color: red;
        /* apply hover effect here */
        }   
    }

    .list-item-txt{
        font-size: 1rem;
        max-width: 180px;
    }
</style>
<template lang="pug">
v-card.noteDialog
    v-card-title.d-flex.justify-center {{$t('END_CUSTOMER.ADD')}}
    v-card-text
        v-form(v-model='valid' ref='form')
            v-text-field.no-border(v-model="addEndCustomer.name",prepend-icon="mdi-tag",:rules="formRule.nameRule", :label='end_customer_name' )
            v-row.mb-4(no-gutters)
                v-col(cols='12' md='12' v-for='(item,index) in field_definition' :key="item.field_key")
                    div(v-if='item.format=="str"' :key='item.index' )
                        v-text-field(v-model='addEndCustomer.detail_field[item.field_key]' :label=`item.field_name` prepend-icon='mdi-tag')
                    div(v-if='item.format=="dat"' :key='item.index')
                        v-menu(ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                            template(v-slot:activator="{ on, attrs }")
                                v-text-field(v-model='addEndCustomer.detail_field[item.field_key]' v-bind="attrs" v-on="on" :label=`item.field_name` prepend-icon='mdi-tag' readonly clearable=true)
                            v-date-picker(v-model='addEndCustomer.detail_field[item.field_key]' @input='item.menu = false' no-title scrollable)
                    div(v-if='item.format=="opt"' :key='item.index')
                        v-autocomplete(v-model='addEndCustomer.detail_field[item.field_key]'
                            :items="item.option",
                            item-text="value",
                            item-value="key",
                            :menu-props='{closeOnClick:true}',
                            :label=`item.field_name` prepend-icon='mdi-tag')
                    div(v-if='item.format=="fil"' :key='item.field_key' )
                        v-file-input.no-border(v-model='file' :label=`item.field_name` prepend-icon='mdi-file-document', @change='checkFile', :rules='formRule.fileRule')
    v-card-actions.px-2.py-4
        v-row
            v-col.pr-2(cols='6')
                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pl-2(cols='6')
                v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed :disabled='upLoading')
                    v-progress-circular(v-if='upLoading' indeterminate size='20')
                    .t-white(v-else) {{$t('GENERAL.ADD')}}
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
	import Vue from 'vue';
    import EndCustomerDataService from '@/services/EndCustomerDataService';
    import DatePicker from 'vue2-datepicker';
    import i18n from '/common/plugins/vue-i18n.js' 
    import messageDialog from '@/components/Dialog/redirectDialog';
    import errorDialog from "@/components/Dialog/errorDialog";

	export default Vue.extend({
        props: {
            type_key:{
                type: String,
                require: true,
            },
            organization_id:{
                type: String,
                require: true,
            },
            field_definition:{
                type: Array,
                require: true,
            },
        },
        components: {
            DatePicker,
            messageDialog,
            errorDialog,
        },
        created(){
            this.resetAddData();
        },
        data() {
			return {
                end_customer_name:i18n.t('END_CUSTOMER.NAME'),
                upLoading:false,
                menu: false,
                openDatePicker:null, 
                addEndCustomer:{
                    name:null,
                    type:this.type_key,
                    organization:this.organization_id,
                },
                detail_field:{},
                fileSize:false,
                valid: true,
                messageDialog:false,
                message:"",
                errorMessage:'',
                errorDialog:false,
                formRule: {
                    fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')], 
                    nameRule:[(v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')],
                    fileRule: [() => { return (!this.fileSize || i18n.t('RULE.RULE_FILE_3'))}],
                },
                file:null,
			}
		},
        methods: {
            resetAddData(){
                this.addEndCustomer = {
                    name:null,
                    type:this.type_key,
                    organization:this.organization_id,
                };
                for(let f in this.field_definition){
                    this.detail_field[this.field_definition[f]['field_key']] = null;
                    if(this.field_definition[f]['field_name'] == "建立日期"){
                        let now_date = new Date();
                        let year = now_date.getFullYear();
                        let month = String(now_date.getMonth() + 1).padStart(2, '0');
                        let day = String(now_date.getDate()).padStart(2, '0');
                        this.detail_field[this.field_definition[f]['field_key']] = `${year}-${month}-${day}`;
                    }
                }
                this.addEndCustomer['detail_field'] = this.detail_field;
            },
            datepickerFocus(item){
                this.openDatePicker = item;
            },
            datepickerChange(value, type){
                if (type === 'minute') {
                    this.openDatePicker.menu = false;
                }
            },
			validate() {
				this.$refs.form.validate();
			},
            validateForm(){
                if(this.$refs.form.validate())
                    return true;
                else
                    return false;
            },
			async onConfirm() {
                if(this.validateForm()&& !this.fileSize){
                    this.upLoading = true;
                    let newData={
                        name:this.addEndCustomer.name,
                        type:this.addEndCustomer.type,
                        organization:this.addEndCustomer.organization,
                        detail_field:this.addEndCustomer.detail_field
                    };
                    await EndCustomerDataService.create(newData,this.file).then((response)=>{
                        let data = response.data;
                        this.$emit('emitAddDialog', true, data);
                        this.resetAddData();
                        this.file = null;
                        this.upLoading = false;
                    });
                }
			},
			onCancel() {
                this.resetAddData();
                this.file = null;
				this.$emit('emitAddDialog', false);
			},
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            checkFile(event) {
                if (event != null) {
                    if (event.size > 20971520) {
                        this.fileSize = true;
                    }else {
                        this.fileSize = false;
                    }
                } else {
                    this.fileSize = false;
                }
            },
		},
        watch:{
        }
	});
</script>


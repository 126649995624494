<template lang="pug">
    div
        v-dialog( @input='open()' v-model="inlineEditdialog" attach max-width="250px" overlay-opacity='0' content-class="elevation-5 inline-edit-dialog" transition="slide-x-transition")
            template(v-slot:activator="{ on, attrs }")
                v-btn(class='inline-edit-btn' icon v-on="on" v-bind="attrs" v-if='(page == "deals" && editItem.status!="OPEN") ||  (page == "cases" && editItem.status=="CLOSED")? false : true')
                    v-icon(size='18') icon-edit
                v-btn(class='inline-edit-btn' icon v-on="on" v-bind="attrs" disabled v-else)
                    img(src='@/assets/images/readonly.svg' style='opacity: 0.4; width: 24px;')
            v-overlay(v-show='loading' opacity='0' z-index='999')
            v-form( ref='editDialogForm' v-model='valid' class='px-4' v-if='inlineEditdialog' @submit.prevent )
                //- general field
                v-text-field(v-if='field_type == "name"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.nameRules')
                v-text-field(v-else-if='field_type == "email"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.emailRules')
                v-text-field(v-else-if='field_type == "emailX"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.emailRulesX')
                v-text-field(v-else-if='field_type == "int"' class='inline-edit-input' v-model="inputValue" single-line counter type="number" :rules='formRule.intRules')
                v-text-field(v-else-if='field_type == "bigint"' class='inline-edit-input' v-model="inputValue" single-line counter type="number" :rules='formRule.BigIntRules')
                v-text-field(v-else-if='field_type == "amount"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.amountRule')
                v-text-field(v-else-if='field_type == "customer_budget"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.customerBudgetRule')
                v-text-field(v-else-if='field_type == "url"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.urlRules')
                v-text-field(v-else-if='field_type == "text_len10"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.contentLenRule10')
                v-text-field(v-else-if='field_type == "text_len10X"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.contentLenRule10X')
                v-text-field(v-else-if='field_type == "text_len20"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.contentLenRule20')
                v-text-field(v-else-if='field_type == "text_len20X"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.contentLenRule20X')
                v-text-field(v-else-if='field_type == "text_len50"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.contentLenRule50')
                v-text-field(v-else-if='field_type == "text_len100"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.contentLenRule100')
                v-text-field(v-else-if='field_type == "text_len50X"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.contentLenRule50X')
                v-text-field(v-else-if='field_type == "text_len100X"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.contentLenRule100X')
                v-text-field(v-else-if='field_type == "text"' class='inline-edit-input' v-model="inputValue" single-line counter )
                v-text-field(v-else-if='field_type == "textX"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.fieldRules')
                v-select(v-else-if='field_type == "select"' class='inline-edit-input' v-model="inputValue" single-line :items="selectList" :item-text="itemText" :item-value="itemValue" :return-object='editProp=="owner" ? true : false' :menu-props='{closeOnClick:true}' :rules='customRlues')
                v-select(v-else-if='field_type == "customer_code_selectX"' class='inline-edit-input' v-model="inputValue" clearable single-line :items="customer_code_List" :item-text="itemText" :item-value="itemValue" :return-object='editProp=="owner" ? true : false' :menu-props='{closeOnClick:true}' :rules='formRule.selectRuleX')
                v-select(v-else-if='field_type == "end_account_select"' class='inline-edit-input' v-model="inputValue" clearable single-line :items="end_account_list" :item-text="itemText" :item-value="itemValue" :return-object='editProp=="owner" ? true : false' :menu-props='{closeOnClick:true}' :rules='customRlues')
                //- v-select(v-else-if='field_type == "autocomplete_multiple"' multiple class='inline-edit-input' v-model="inputValue" single-line :items="selectList" :item-text="itemText" :item-value="itemValue" :return-object='editProp=="owner" ? true : false' :menu-props='{closeOnClick:true}' :rules='customRlues')
                v-autocomplete(v-else-if='field_type == "autocomplete"' clearable class='inline-edit-input' v-model="inputValue" single-line counter :items="selectList" :item-text="itemText" :item-value="itemValue" :menu-props='{closeOnClick:true}')
                v-autocomplete(v-else-if='field_type == "autocomplete_multiple"' multiple  clearable class='inline-edit-input' v-model="inputValue" single-line counter :items="selectList" :item-text="itemText" :item-value="itemValue" menu-props='{closeOnClick:true}')
                v-text-field(v-else-if='field_type == "textarea"' class='inline-edit-input' v-model="inputValue" single-line counter )
                v-menu(v-else-if='field_type == "date"' class='inline-edit-input' ref="menu" v-model="menu" :close-on-content-click="false" :z-index='202' transition="scroll-y-transition" offset-y min-width="auto") 
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field( v-model='inputValue' single-line readonly v-bind="attrs" v-on="on" clearable)
                    v-date-picker( v-model='inputValue' @input='menu = false' no-title scrollable)
                v-menu(v-else-if='field_type == "m_date"' class='inline-edit-input' ref="menu" v-model="menu" :close-on-content-click="false" :z-index='202' transition="scroll-y-transition" offset-y min-width="auto") 
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field( v-model='inputValue' single-line readonly v-bind="attrs" v-on="on" )
                    v-date-picker( v-model='inputValue' @input='menu = false' no-title scrollable)
                //- phone
                vue-tel-input-vuetify(
                    style='padding-top:15px;'
                    v-else-if='field_type == "mobile_phone"',
                    v-model="inputValue",
                    label='',
                    selectLabel='',
                    placeholder='',
                    autocomplete='off',
                    :defaultCountry='getLocation()',
                    disabledFetchingCountry=true,
                    mode='international',
                    @validate="onPhoneCheck($event)",
                    @input="onPhoneInput",
                    :rules="formRule.mobilephoneRules",
                    )
                //- name
                v-text-field(v-if='field_type == "orgname"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.companyNameRuleM')
                v-text-field(v-if='field_type == "opptyname"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.opptyNameRules')
                v-text-field(v-if='field_type == "casename"' class='inline-edit-input' v-model="inputValue" single-line counter :rules='formRule.caseNameRules')
                div(v-else-if='field_type == "full_name"' class='d-flex')
                    v-text-field(:label= `$t('ADD_CONTACT.LAST_NAME')` class='inline-edit-input mr-2' v-model="inputValue" single-line counter :rules='formRule.contactnNameRules')
                    v-text-field(:label= `$t('ADD_CONTACT.FIRST_NAME')` class='inline-edit-input' v-model="sec_inputValue" single-line counter :rules='formRule.contentLenRule50')
                div(v-else-if='field_type == "english_full_name"' class='d-flex')
                    v-text-field(:label= `$t('ADD_CONTACT.ENG_LAST_NAME')` class='inline-edit-input mr-2' v-model="inputValue" single-line counter :rules='formRule.contentLenRule100')
                    v-text-field(:label= `$t('ADD_CONTACT.ENG_FIRST_NAME')` class='inline-edit-input' v-model="sec_inputValue" single-line counter :rules='formRule.contentLenRule100')
                div(v-else-if='field_type == "english_full_nameX"' class='d-flex')
                    v-text-field(:label= `$t('ADD_CONTACT.ENG_LAST_NAME')` class='inline-edit-input mr-2' v-model="inputValue" single-line counter :rules='formRule.contentLenRule100X')
                    v-text-field(:label= `$t('ADD_CONTACT.ENG_FIRST_NAME')` class='inline-edit-input' v-model="sec_inputValue" single-line counter :rules='formRule.contentLenRule100')
                //- select org
                div(v-else-if='field_type == "select_org"')
                    v-combobox(class='inline-edit-input' v-model='inputValue' :items='organizationList' item-text='name' item-value="uqNumber", :loading="comboBoxIsLoading" :search-input.sync="searchOrg" hide-details="auto" clearable :rules='formRule.companyNameRule')
                        template(v-slot:append-outer)
                            div(v-if="isNew")
                                v-icon(color="red", size="25") mdi-new-box
                        template(v-slot:item="data")
                            div.ellipsis(v-if="data.item.id == 0")
                                span {{ data.item.name }}
                                v-icon(color="red", size="25") mdi-new-box
                            div.ellipsis(v-else)
                                span {{ data.item.name }} 
                div(v-else-if='field_type == "select_oppty_org"')
                    v-combobox(class='inline-edit-input' v-model='inputValue' :items='organizationList' item-text='name' item-value="uqNumber", :loading="comboBoxIsLoading" :search-input.sync="searchOrg" hide-details="auto" :rules='formRule.companyNameRuleM')
                        template(v-slot:append-outer)
                            div(v-if="isNew")
                                v-icon(color="red", size="25") mdi-new-box
                        template(v-slot:item="data")
                            div.ellipsis(v-if="data.item.id == 0")
                                span {{ data.item.name }}
                                v-icon(color="red", size="25") mdi-new-box
                            div.ellipsis(v-else)
                                span {{ data.item.name }}
                                span.t-black.ml-2(v-if='isWiadvance() && data.item.customer_code') [ {{data.item.customer_code }} ]

                //- select contact
                div(v-else-if='field_type == "select_required_contact"')
                    v-combobox(v-if="!isNewContact" class='inline-edit-input' v-model='inputValue' :items='contactList' item-text="searchStr" item-value="id", :loading="comboBoxIsLoading" :search-input.sync="searchContact" hide-details="auto" :rules='formRule.opptyContactRules' :menu-props="{openOnClick: true}")
                        template(v-slot:prepend-item)
                            v-list-item
                                v-btn(width="120" height="32" color="blue" plain border-0 @click="isNewContact = true")
                                    v-icon.mr-1(size="14") icon-add
                                    span {{$t('ADD_CASE.NEW_CONTACT')}}
                        template(v-slot:item='data')
                            v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                v-icon(v-if="data.item.image == null") mdi-account
                                img(v-else :src='data.item.image')
                            .t-black {{ data.item.full_name }} 
                            .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                        template(v-slot:selection='data')
                            .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.name? data.item.name: data.item.full_name }}
                    v-row(no-gutters="no-gutters")
                        v-col(cols="6")
                            v-text-field.ml-3(v-if="isNewContact" v-model="newContact.last_name" :label= `$t('GENERAL.LAST_NAME')` large="large",:rules='formRule.contactnNameRules')
                        v-col(cols="6")
                            v-text-field.ml-3(v-if="isNewContact " v-model="newContact.first_name" :label=`$t('GENERAL.FIRST_NAME')` large="large",:rules='formRule.contentLenRule50')  
                div(v-else-if='field_type == "select_contact"')
                    v-combobox(v-if="!isNewContact" class='inline-edit-input' v-model='inputValue' :items='contactList' item-text="searchStr" item-value="id", :loading="comboBoxIsLoading" :search-input.sync="searchContact" hide-details="auto" :menu-props="{openOnClick: true}" :rules='customRlues')
                        template(v-slot:prepend-item)
                            v-list-item
                                v-btn(width="120" height="32" color="blue" plain border-0 @click="isNewContact = true")
                                    v-icon.mr-1(size="14") icon-add
                                    span {{$t('ADD_CASE.NEW_CONTACT')}}
                        template(v-slot:item='data')
                            v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                v-icon(v-if="data.item.image == null") mdi-account
                                img(v-else :src='data.item.image')
                            .t-black {{ data.item.full_name }} 
                            .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                        template(v-slot:selection='data')
                            .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                    v-row(no-gutters="no-gutters")
                        v-col(cols="6")
                            v-text-field.ml-3(v-if="isNewContact" v-model="newContact.last_name" :label= `$t('GENERAL.LAST_NAME')` large="large",:rules='formRule.contactnNameRules')
                        v-col(cols="6")
                            v-text-field.ml-3(v-if="isNewContact " v-model="newContact.first_name" :label=`$t('GENERAL.FIRST_NAME')` large="large",:rules='formRule.contentLenRule50')  
                //- select parent_case
                div(v-else-if='field_type == "select_parent_case"')
                    v-combobox(class='inline-edit-input' v-model='inputValue' :items='parentCaseSelectList' item-text="subject" item-value="id" :loading="comboBoxIsLoading" :search-input.sync="search_parent_case" )
                        template(v-slot:append-outer)
                            v-progress-circular(size='18' v-if='comboBoxIsLoading' indeterminate color='primary')
                        template(v-slot:item="data")
                            span {{ data.item.subject }}
                
                v-checkbox(v-else-if='field_type == "checkbox"' v-model='inputValue' class='inline-edit-input')
                //- custom field 
                v-checkbox(v-else-if='field_type == "custom_field_bol"' v-model='inputValue' class='inline-edit-input')
                v-menu(v-else-if='field_type == "custom_field_dat" || field_type == "custom_field_dtt"' class='inline-edit-input' ref="menu" v-model="menu" :close-on-content-click="false" :z-index='202' transition="scroll-y-transition" offset-y min-width="auto") 
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field(v-model='inputValue' :rules='cf_mandatory ? formRule.cf_MandatoryRule : []' single-line readonly v-bind="attrs" v-on="on" :clearable='!cf_mandatory')
                    v-date-picker(v-if='field_type=="custom_field_dat"' v-model='inputValue' @input='menu = false' no-title scrollable) 
                    date-picker(v-if='field_type=="custom_field_dtt"' v-model='inputValue' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange") 
                v-text-field(v-else-if='field_type == "custom_field_str"' v-model='inputValue' class='inline-edit-input' single-line counter :rules='cf_mandatory ? formRule.cf_MandatoryRule : []')
                v-text-field(v-else-if='field_type == "custom_field_num"' v-model.number='inputValue' class='inline-edit-input' single-line counter type='number' :rules='cf_mandatory ? formRule.cf_numMandatoryRule : []')
                v-text-field(v-else-if='field_type == "custom_field_txt"' v-model='inputValue' class='inline-edit-input' single-line counter :rules='cf_mandatory ? formRule.cf_MandatoryRule : []')
                v-autocomplete(v-else-if='field_type == "custom_field_opt"' v-model="inputValue" class='inline-edit-input' single-line :items="selectList" :item-text="itemText" :item-value="itemValue" :menu-props='{closeOnClick:true}' :clearable='!cf_mandatory' :rules='cf_mandatory ? formRule.cf_MandatoryRule : []')
                v-autocomplete(v-else-if='field_type == "custom_field_mlt"' v-model="inputValue" multiple class='inline-edit-input' single-line :items="selectList" :item-text="itemText" :item-value="itemValue" :menu-props='{closeOnClick:true}' :clearable='!cf_mandatory' :rules='cf_mandatory ? formRule.cf_MandatoryOptRule : []')
                p.d-flex.align-center.input-has-label.mb-3(v-else-if='field_type == "custom_field_tst"')
                    treeselect.pt-3(
                        :title='inputValueStr'
                        :openOnFocus='true'
                        v-model="inputValue"
                        :options="kickOutChildren(selectList)",
                        :multiple="false"
                        :disable-branch-nodes="true"
                        placeholder='' 
                        :clearable='!cf_mandatory'
                        noResultsText='No data available'
                        item-text="value",
                        item-value="key", solo='solo'
                        :rules='cf_mandatory ? formRule.cf_MandatoryOptRule : []'
                        ref='treeSelect'
                        @input='changeTreeValue'
                        @select='closeTreeMenu'
                        appendToBody
                    )
                        div(slot="option-label" slot-scope="{ node }" :title='node.raw.label') {{ node.raw.label }}
                        div(slot="value-label" slot-scope="{ node }") {{ node.raw.customLabel }}

                .d-flex.justify-end.mt-1()
                    v-btn(v-if="isNewContact" icon elevation='0' @click="isNewContact = false")
                        v-icon(color="blue" size='18') mdi-keyboard-return 
                    v-btn(@click='save' icon elevation='0' :disabled='false') 
                        v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                        v-icon(color='green' size='18' v-else) fas fa-check 
                    v-btn(@click='cancel' icon elevation='0' )
                        v-icon(color='red' size='18') mdi-window-close
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')

</template>
<script>
    import Vue from 'vue';
    import i18n from '/common/plugins/vue-i18n.js'
    import OpportunityDataService from '@/services/OpportunityDataService';
    import OrganizationDataService from "@/services/OrganizationDataService";
    import wiadvanceProgressCheck from "@/views-wiadvance/wiadvanceProgressCheck";
    import getCustomerCodeListOptions from "@/views-wiadvance/getCustomerCodeListOptions";
    import checkCustomerCodeRepeat from "@/views-wiadvance/checkCustomerCodeRepeat";
    import checkContactEmailRepeat from "@/views-wiadvance/checkContactEmailRepeat";
    import ContactDataService from "@/services/ContactDataService";
    import CaseDataService from "@/services/CaseDataService";
    import Treeselect from '@riophae/vue-treeselect';
    import DatePicker from 'vue2-datepicker';
    import errorDialog from "@/components/Dialog/errorDialog";

    export default Vue.extend({
        components: {
            Treeselect,
            DatePicker,
            errorDialog,
        },
        props: {
            field_type:{
                type: String,
                required: true,
            },
            editProp:{
                type: String,
                required: true,
            },
            editItem:{
                type: Object,
                required: true,
            },
            selectList: {
                type: Array,
                required: false
            },
            itemText:{
                type: String,
                required: false
            },
            itemValue:{
                type: String,
                required: false
            },
            cf_mandatory:{
                type: Boolean,
                required: false
            },
            page:{
                type: String,
                required: false
            },
            treeSelectList:{
                type: Array,
                required: false
            }
        },
        data() {
            return {
                errorDialog: false,
                errorMessage: "",
                inlineEditdialog: false,
                inputValue: null,
                sec_inputValue: null,
                inputValue_origin: null,
                sec_inputValue_origin: null,
                valid: true,
                menu: false,
                formRule: {
                    companyNameRule: [
                        // (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
                    ],
                    companyNameRuleM: [
                        (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
                    ],
                    contactnNameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME_2'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
                    ],
                    opptyNameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_NAME'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                    ],
                    caseNameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_TITLE'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
                    ],
                    urlRules: [
                        // (v) => /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_URL'),
                        (v) => /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_URL'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                    ],
                    intRules: [
                        (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
                        (v) => /^.{0,9}$/.test(v) || i18n.t('RULE.RULE_9')
                        ],
                    BigIntRules: [
                        (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
                        (v) => /^.{0,16}$/.test(v) || i18n.t('RULE.RULE_16')
                        ],
                    emailRules: [
                        (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    emailRulesX: [
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                        (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    fieldRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R')
                    ],
                    contentLenRule100: [(v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100')],
                    contentLenRule70: [(v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')],
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                    contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
                    contentLenRule10: [(v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')],
                    cf_MandatoryRule: [(v) => !!v || i18n.t('RULE.RULE_R')],
                    cf_numMandatoryRule: [(v) => (v === 0 || !!v) || i18n.t('RULE.RULE_R')],
                    // oppty
                    opptyContactRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
                    ],
                    contactRules: [(v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact')],
                    customerBudgetRule: [
                        (v) => /^\d*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
                        (v) => /^.{0,17}$/.test(v) || i18n.t('RULE.RULE_17'),
                    ],
                    amountRule: [
                        (v) => /^\d{1,17}(\.\d{0,2})?$/.test(v) || i18n.t('RULE.RULE_R_NUM_2')
                    ],
                    textRule: [(v) => (v || '').length <=90 || i18n.t('RULE.RULE_90')],	
                    mobilephoneRules: [() => {return (this.isNumVaild || i18n.t('CONTACT_DETAIL.ERROR_3'))},
                                        (v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')
                    ],
                    cf_MandatoryOptRule: [(v) => (v || []).length >0 || i18n.t('RULE.RULE_R')],
                    contentLenRule10X: [
                        (v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10'),
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                    ],
                    contentLenRule20X: [
                        (v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20'),
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                    ],
                    contentLenRule50X: [
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                    ],
                    contentLenRule100X: [
                        (v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100'),
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                    ],
                    selectRuleX: [
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                    ],
                },
                customRlues:[],
                loading: false,
                isNumVaild: true,
                vue_tel: {
                    isValid: true,
                },
                head_org: {},
                comboText: "",
                searchOrg: null,
                searchContact: null,
                search_parent_case: null,
                contactList: [],
                organizationList: [],
                // orgData:null,
                comboBoxIsLoading:false,
                isNewContact: false,
                newContact: {
                    last_name: '',
                    first_name: ''
                },
                parentCaseSelectList:[],
                customer_code_List:[],
                end_account_list:[],
                inputValueStr:null,
                hasCheck: false,
            }
        },
        created() {
            if(this.isWiadvance()){
                let requireField = ["oppty_status","industry","customer_source","progress","primary_contact"]
                if(this.page =='deals' && requireField.includes(this.editProp)){
                    this.customRlues = [(v) => !!v || i18n.t('RULE.RULE_R')]
                }
            }
            // if(this.field_type.includes('custom_field')){
            //     console.log('!')
            //     this.inputValue = this.editItem['custom_field'][this.editProp];
            //     this.inputValue_origin = JSON.parse(JSON.stringify(this.editItem['custom_field'][this.editProp]));
            // }
            // else if(this.field_type.includes('full_name')){
            //     this.inputValue = this.editItem['last_name'];
            //     this.inputValue_origin = JSON.parse(JSON.stringify(this.editItem['last_name']));
            //     this.sec_inputValue = this.editItem['first_name'];
            //     this.sec_inputValue_origin = JSON.parse(JSON.stringify(this.editItem['first_name']));
            // }
            // else if(this.field_type.includes('select_org')){
            //     if(this.editItem.organization_set != ''){
            //         this.inputValue = this.editItem.organization_set[0].name;
            //     }
            //     if(this.inputValue){
            //         this.inputValue_origin = JSON.parse(JSON.stringify(this.editItem.organization_set));
            //     }else{
            //         this.inputValue_origin = "";
            //     }
            // }
            // else{
            //     this.inputValue = this.editItem[this.editProp];
            //     this.inputValue_origin = JSON.parse(JSON.stringify(this.editItem[this.editProp]));
            // }
        },
        methods: {
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            datepickerChange(value, type){
                if (type === 'minute') {
                    this.menu = false;
                }
            },
            changeTreeValue(){
                if(this.field_type == "custom_field_tst"){
                    this.inputValueStr = this.treeSelectList.filter(el=>el.key==this.inputValue)[0].name;
                }
            },
            closeTreeMenu(){
                this.menu = false;
            },
            kickOutChildren(data){
                return data.map((el) => {
                        if(el.children && el.children.length>0){
                            return {
                                ...el,
                                children: this.kickOutChildren(el.children),
                            };
                        }else{
                            delete el.children;
                        }
                        return el;
                });
            },
            async open(){
                if(this.field_type.includes('custom_field')){
                    if(this.page){
                        this.inputValue = this.editItem['custom_field_list'][this.editProp]
                    }else{
                        this.inputValue = this.editItem['custom_field'][this.editProp]
                    }
                }
                else if(this.editProp == 'customer_code'){
                    if(this.isWiadvance()){
                        this.customer_code_List = await getCustomerCodeListOptions(this.editItem);
                    }
                    this.inputValue = this.editItem['customer_code'];
                    this.inputValue_origin = JSON.parse(JSON.stringify(this.editItem['customer_code']));
                }
                else if(this.editProp == 'end_account_id' && this.page == 'deals'){
                    let org_id = this.editItem.oppty_org.id
                    if(this.isWiadvance() && org_id != ''){
                        let response_data = await OrganizationDataService.getEndAccountList(org_id)
                        this.end_account_list = response_data.data
                        this.inputValue = this.editItem['end_account_id'];
                    }
                }
                else if(this.editProp == 'email'){
                    this.inputValue = this.editItem['email'];
                    this.inputValue_origin = JSON.parse(JSON.stringify(this.editItem['email']));
                }
                else if(this.field_type == 'full_name'){
                    this.inputValue = this.editItem['last_name'];
                    this.inputValue_origin = JSON.parse(JSON.stringify(this.editItem['last_name']));
                    this.sec_inputValue = this.editItem['first_name'];
                    this.sec_inputValue_origin = JSON.parse(JSON.stringify(this.editItem['first_name']));
                }
                else if(this.field_type == 'english_full_name'){
                    this.inputValue = this.editItem['english_last_name'];
                    this.inputValue_origin = JSON.parse(JSON.stringify(this.editItem['english_last_name']));
                    this.sec_inputValue = this.editItem['english_first_name'];
                    this.sec_inputValue_origin = JSON.parse(JSON.stringify(this.editItem['english_first_name']));
                }
                else if(this.field_type == 'select_org'){
                    if(this.page =="cases"){
                        if(this.editItem.case_org){
                            this.inputValue = this.editItem.case_org;
                        }else{
                            this.inputValue = null;
                        }
                    }else{
                        if(this.editItem.organization_set.length >0){
                            this.inputValue = this.editItem.organization_set[0];
                        }else{
                            this.inputValue = null;
                        }
                    }
                }
                else if(this.field_type == 'select_oppty_org'){
                    if(this.editItem.oppty_org){
                        this.inputValue = this.editItem.oppty_org;
                    }else{
                        this.inputValue = null;
                    }
                }
                else if(this.field_type == 'select_parent_case'){
                    if(this.editItem.parent_case){
                        this.inputValue = this.editItem.parent_case;
                    }else{
                        this.inputValue = {"id":null,"subject":null};
                    }
                }
                else if(this.field_type == 'select_required_contact'){
                    if(this.page == "deals"){
                        if(this.editItem.oppty_contact){
                        this.inputValue = this.editItem.oppty_contact;
                        this.inputValue.full_name =this.editItem.oppty_contact.__chineseName__;
                        }else{
                            this.inputValue = null;
                        }
                    }
                    if(this.page == "cases"){
                        if(this.editItem.primary_contact){
                        this.inputValue = this.editItem.primary_contact;
                        this.inputValue.name =this.editItem.primary_contact.name;
                        }else{
                            this.inputValue = null;
                        }
                    }
                }else if(this.field_type == 'select_contact'){
                    if(this.page == "deals"){
                        if(this.editItem.primary_contact.id != null){
                            this.inputValue = this.editItem.primary_contact;
                            this.inputValue.full_name =this.editItem.primary_contact.__chineseName__;
                        }else{
                            this.inputValue = null;
                        }
                    }
                }
                else if(this.field_type == 'autocomplete_multiple'){
                    if(this.page == "contacts"){
                        const dateProps = ['regular_payment_date', 'closing_date'];
                        if(dateProps.includes(this.editProp)){
                            this.inputValue = this.editItem[this.editProp].split(',');
                        }
                    }
                }
                else if(this.page == 'cases'){
                    if(this.editProp == "group"){
                        this.inputValue = this.editItem.group_id;
                    }else if(this.editProp == "type"){
                        this.inputValue = this.editItem.type_id;
                    }else{
                        this.inputValue = this.editItem[this.editProp];
                    }
                }
                else{
                    this.inputValue = this.editItem[this.editProp];
                }
                if(this.field_type == "custom_field_tst" && this.inputValue != null && this.inputValue != ""){
                    this.inputValueStr = this.treeSelectList.filter(el=>el.key==this.inputValue)[0].name;
                }
            },
            async save(){
                if(this.$refs.editDialogForm.validate()){
                    if(this.field_type == 'custom_field_num' && this.inputValue == ''){
                        this.inputValue = null;
                    }
                    if(this.field_type == 'custom_field_tst'){
                        if(this.inputValueStr == null){
                            this.inputValue = null;
                        }
                    }
                    if(this.page == "deals"){
                        // wiadvance check
                        if(this.isWiadvance() && this.editProp == "current_stage_id" && parseInt(this.selectList.filter(el => el.id == this.inputValue)[0].sequence_number)>=3 && !await wiadvanceProgressCheck([this.editItem.id])){
                            this.showErrorDialog(i18n.t('WIADVANCE.MESSAGE_1'));
                            return
                        }
                        this.loading = true;
                        let deal_editData;
                        OpportunityDataService.getOpptyDetail(this.editItem.id)
                        .then(response =>{
                            deal_editData = response.data;
                            deal_editData.primary_contact == null ? deal_editData.primary_contact = {} : '';
                            deal_editData.oppty_contact == null ? deal_editData.oppty_contact = {} : '';
                            deal_editData.oppty_org == null ? deal_editData.oppty_org = {} : '';
                            deal_editData.organization_set ? delete deal_editData.organization_set.image: '';
                            deal_editData.contacts ? deal_editData.contacts = []: '';
                            delete deal_editData.owner.usersetting;
                        })
                        .then(()=>{
                            this.loading = false;
                            if(this.field_type.includes('custom_field')){
                                 deal_editData['custom_field'] = this.caseOpptyCf()
                                 this.$emit("emitInlineEdit",this.editItem,deal_editData);
                                 this.inlineEditdialog = false;
                                 return;
                            }else if(this.field_type == "select_oppty_org"){
                                if(typeof(this.inputValue) === "string"){
                                    if(this.inputValue != ""){
                                        this.inputValue = {id: "-1", name: this.inputValue, company_number: "0"}
                                    }else{
                                        return;
                                    }
                                }
                            }else if(this.field_type == "select_required_contact"){
                                if (this.isNewContact == true) {
                                    if (this.newContact.last_name == '') {
                                        this.inputValue = {};
                                    }
                                    else {
                                        this.inputValue = {
                                            id: 0,
                                            last_name: this.newContact.last_name,
                                            first_name: this.newContact.first_name != null ? this.newContact.first_name : ""
                                        }                       
                                    }
                                }
                            }else if(this.field_type == "select_contact"){
                                if (this.isNewContact == true) {
                                    if (this.newContact.last_name == '') {
                                        this.inputValue = {};
                                    }
                                    else {
                                        this.inputValue = {
                                            id: 0,
                                            last_name: this.newContact.last_name,
                                            first_name: this.newContact.first_name != null ? this.newContact.first_name : ""
                                        }
                                    }
                                }
                                if(!this.inputValue){
                                    this.inputValue = {};
                                }
                            }
                            this.$emit("emitInlineEdit", this.editItem, deal_editData, this.editProp, this.inputValue);
                            this.inlineEditdialog = false;
                            this.isNewContact = false;
                            this.newContact = {
                                last_name: '',
                                first_name: ''
                            }
                        })
                    }
                    else if(this.page == "cases"){
                        this.loading = true;
                        let case_editData;
                        CaseDataService.detail(this.editItem.id)
                        .then(response =>{
                            case_editData = response.data;
                            delete case_editData.owner.usersetting;
                            delete case_editData.stage_progress;
                            delete case_editData.stage_score;
                            delete case_editData.summary;
                            delete case_editData.owner;
                            delete case_editData.stages;
                            delete case_editData.contacts;
                            delete case_editData.knowledge_articles
                        })
                        .then(()=>{
                            this.loading = false;
                            if(this.field_type.includes('custom_field')){
                                case_editData['custom_field'] = this.caseOpptyCf();
                                 this.$emit("emitInlineEdit",this.editItem, case_editData);
                                 this.inlineEditdialog = false;
                                 return;
                            }else if(this.field_type == "select_org"){
                                if(typeof(this.inputValue) === "string"){
                                    if(this.inputValue != ""){
                                        this.inputValue = {id: "-1", name: this.inputValue, company_number: "0"}
                                    }else{
                                        this.inputValue = {};
                                    }
                                }
                            }else if(this.field_type == "select_required_contact"){
                                if (this.isNewContact == true) {
                                    if (this.newContact.last_name == '') {
                                        this.inputValue = {};
                                    }
                                    else {
                                        this.inputValue = {
                                            id: 0,
                                            last_name: this.newContact.last_name,
                                            first_name: this.newContact.first_name != null ? this.newContact.first_name : ""
                                        }                       
                                    }
                                }
                            }else if(this.field_type == "select_parent_case"){
                                if(!this.inputValue){
                                    this.inputValue = {};
                                }
                            }
                            this.$emit("emitInlineEdit", this.editItem, case_editData, this.editProp, this.inputValue);
                            this.inlineEditdialog = false;
                            this.isNewContact = false;
                            this.newContact = {
                                last_name: '',
                                first_name: ''
                            }
                        })
                    }
                    else{
                        if(this.isWiadvance() && this.editProp == "customer_code"){
                            this.editItem.customer_code = this.inputValue
                            if( await checkCustomerCodeRepeat(this.editItem.id, this.editItem)){
                                this.showErrorDialog(i18n.t('COMPANY.WIADVANCE_REPEAT_CUSTOMERCODE'));
                            }
                            else
                            {
                                this.$emit("emitInlineEdit", this.editItem, this.editProp, this.inputValue, this.customer_code_List);
                                this.inlineEditdialog = false;
                            }
                        }else if(this.isWiadvance() && !this.hasCheck && this.editProp == "email" && this.page == "contacts"){
                            this.editItem.email = this.inputValue
                            let check_data = {
                                "id": this.editItem.id,
                                "email": this.editItem.email,
                            }
                            if( await checkContactEmailRepeat(check_data)){
                                this.showErrorDialog(i18n.t('CONTACT.WIADVANCE_REPEAT_EMAIL'));
                                this.hasCheck = true
                            }
                            else
                            {
                                this.$emit("emitInlineEdit", this.editItem, this.editProp, this.inputValue);
                                this.inlineEditdialog = false;
                                this.hasCheck = false;
                            }
                        }else{
                            if(this.field_type == "full_name"){
                                this.editItem['last_name'] = this.inputValue;
                                this.editItem['first_name'] = this.sec_inputValue;
                                if(this.sec_inputValue != "" || this.sec_inputValue != null){
                                    this.inputValue = this.inputValue+" "+this.sec_inputValue
                                }
                            }
                            else if(this.field_type == "english_full_name"){
                                this.editItem['english_last_name'] = this.inputValue;
                                this.editItem['english_first_name'] = this.sec_inputValue;
                                if(this.sec_inputValue != "" || this.sec_inputValue != null){
                                    this.inputValue = this.inputValue+" "+this.sec_inputValue
                                }
                            }
                            else if(this.field_type == "select_org"){
                                if(typeof(this.inputValue) === "string"){
                                    if(this.inputValue != ""){
                                        this.inputValue = [{id: "-1", name: this.inputValue, company_number: "0"}]
                                    }else{
                                        this.inputValue = [];
                                    }
                                }else{
                                    if(this.inputValue != null){
                                        this.inputValue = [this.inputValue];
                                    }
                                    else if(this.inputValue == null){
                                        this.inputValue = [];
                                    }
                                }
                            }else{
                                this.editItem[this.editProp] = this.inputValue;
                            }
                        this.$emit("emitInlineEdit", this.editItem, this.editProp, this.inputValue);
                        this.inlineEditdialog = false;
                        this.hasCheck = false;
                        }
                    }
                }
                
            },
            caseOpptyCf(){
                if(this.editProp.includes('_all_')){
                    for(let i in this.editItem.custom_field){
                        if(this.editItem.custom_field[i].stage_type_id == "all"){
                            this.editItem.custom_field[i].data[this.editProp] = this.inputValue;
                            this.editItem.custom_field_list[this.editProp] = this.inputValue;
                        }
                    }
                }else{
                    for(let i in this.editItem.custom_field){
                        if(this.editItem.custom_field[i].stage_type_id == this.editItem.stage_type_id){
                            this.editItem.custom_field[i].data[this.editProp] = this.inputValue;
                            this.editItem.custom_field_list[this.editProp] = this.inputValue;
                        }
                    }
                }
                return this.editItem.custom_field;
            },
            cancel(){
                if(this.editProp == 'customer_code'){
                    this.editItem.customer_code = this.inputValue_origin
                    this.inputValue = this.inputValue_origin;
                }else if(this.editProp == 'email' && this.hasCheck == true && this.page == "contacts"){
                    this.editItem.email = this.inputValue_origin
                    this.inputValue = this.inputValue_origin;
                }else{
                    this.inputValue = this.inputValue_origin;
                }
                this.sec_inputValue = this.sec_inputValue_origin;
                this.inlineEditdialog = false;
                this.hasCheck = false
            },
            onPhoneCheck(event) {
                this.vue_tel = event;
                if (this.vue_tel.isValid == true) {
                    this.isNumVaild = true;
                }
            },
            onPhoneInput() {
                if (this.isNumVaild == true) {
                    if (this.inputValue != this.vue_tel.number.input && this.inputValue != "") {
                    this.isNumVaild = false;
                    }
                } else {
                    if (this.inputValue == "") {
                    this.isNumVaild = true;
                    }
                }
            },
        },
        computed: {
            isNew: function(){
                if(this.inputValue == undefined){
                    return false;
                }
                if(this.inputValue == "" || this.inputValue == null){
                    return false;
                }
                if(typeof(this.inputValue)==="object"){
                    if(this.inputValue.id == 0){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    return true;
                }
            },
        },
        watch: {
            inlineEditdialog: function(val){
                if(!val){
                    this.cancel();
                }
            },
            async searchOrg(val) {
                if(typeof(val)=== "object"){
                    val = null;
                }
                var re = /^(?!\s*$).+/;
                var check = re.test(val);
                var checkLength = /^.{0,200}$/.test(val);
                this.comboText = "";
                if(check && val != null && checkLength){
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    this.comboText = val;
                    if(!this.comboBoxIsLoading && charNum>=2 && charNum==val.length ){
                        this.comboBoxIsLoading = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val)
                        .then((response) => {
                            this.organizationList = response.data;
                            let num = 0
                            this.organizationList.forEach((item)=>{
                            item.uqNumber = num
                            num ++
                            })
                        })
                        .finally(() => {
                            this.comboBoxIsLoading = false;
                        });
                    }
                    else {
                        this.comboBoxIsLoading = false;                        
                    }
                }
            },
            async searchContact(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString){
                    this.comboBoxIsLoading = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val)){
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.contactList = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.comboBoxIsLoading = false;
                    })
                }
                else{
                    this.contactList = [];
                }
            },
            async search_parent_case(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;

                // search
                if(isEnZhNum && !!val && isString) {
                    this.comboBoxIsLoading = true;
                    // this.inputValue = val
                    await CaseDataService.kanabn('list', 'all', 0, 1, val,9999,'','')
                    .then(response => {
                        this.parentCaseSelectList = response.data.list
                        this.parentCaseSelectList = this.parentCaseSelectList.filter((item) => {
                            if(item.subject.includes(val)) {
                                return (item.id != this.editItem.id && item.parent_case_id != this.editItem.id);
                            }
                        })
                    })
                    .finally(() => {
                        this.comboBoxIsLoading = false;
                    })
                }
                else{
                    this.parentCaseSelectList = []
                }
            },
        }
    });
</script>


<template lang="pug">
    v-card.dealDialog
        v-card-title.d-flex.justify-center {{ addOrderTitle }}
        v-card-text
            v-form(v-model='addOrderValid' ref='form')
                v-text-field.mb-4(v-model='addOrder.name' :label='addOrderName', :rules='formRules.nameRule' required='required' large='large' validate-on-blur)
                v-combobox.no-border.height-48(v-model='addOrder.organization' :items='organizationList' item-text='name' item-value="uqNumber" :loading="isLoading" :search-input.sync="search" prepend-icon='mdi-domain' hide-details="auto" :label=`$t('ADD_ORDER.ORZ')` :rules='formRules.companyRule' required="required")
                    template(v-slot:no-data)
                        v-list-item()
                            span {{$t("ORDER_DETAIL.NO_REGARDING")}}
                    template(v-slot:append-outer)
                        div(v-if="isNew")
                            v-icon(color="red", size="25") mdi-new-box
                    template(v-slot:item="data")
                        div.ellipsis(v-if="data.item.id == 0")
                            span {{ data.item.name }}
                            v-icon(color="red", size="25") mdi-new-box
                        div.ellipsis(v-else)
                            span(v-if="isWiadvance() && data.item.customer_code") {{ data.item.name }} [ {{data.item.customer_code }} ]
                            span(v-else) {{ data.item.name }}
                v-combobox.no-border.height-48(v-if="!isNewContact" v-model="addOrder.contact" :items="contactList" :search-input.sync="contactSearch" :menu-props="{openOnClick: true, closeOnClick: true}" :no-data-text=`$t('ADD_ORDER.NO_CONTACT')` @blur='contactList = []' item-text="searchStr" item-value="id" hide-details="auto" prepend-icon='mdi-account-multiple' :label=`$t('ADD_ORDER.CONTACT')` :loading='contact_loading')
                    template(v-slot:prepend-item)
                        v-list-item
                            v-btn(width="120" height="32" color="blue" plain border-0 @click="isNewContact = true")
                                v-icon.mr-1(size="14") icon-add
                                span {{$t('ADD_ORDER.NEW_CONTACT')}}
                    template(v-slot:item='data')
                        v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                            v-icon(v-if="data.item.image == null") mdi-account
                            img(v-else :src='data.item.image')
                        .t-black {{ data.item.full_name }}
                        .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                    template(v-slot:selection='data')
                        .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                v-row(no-gutters="no-gutters")
                    v-col(cols="6")
                        v-text-field.ml-3(v-if="isNewContact" v-model="newContact.last_name" :label= `$t('ADD_ORDER.LAST_NAME')` large="large" :rules='formRules.contactRules')
                    v-col(cols="6")
                        v-text-field.ml-3(v-if="isNewContact" v-model="newContact.first_name" :label=`$t('ADD_ORDER.FIRST_NAME')` large="large" :rules='formRules.contentLenRule50')  
                    v-col(cols="6")
                        v-btn.w-100.justify-start.px-0(v-if="isNewContact" text color="blue" plain border-0 @click="isNewContact = false")
                            v-icon(size="20") mdi-keyboard-return 
                            span.ml-4 {{$t('ADD_ORDER.OLD_CONTACT')}}
                v-text-field.no-border.height-48(v-model='addOrder.amount' prepend-icon='icon-chatmoney' :label=`$t('ADD_ORDER.AMOUNT')`,   :ref="'editAmount'" v-show='amountShow|!amountValidate' @blur='showFormatAmount')
                v-text-field.no-border.height-48(v-model='formatAmount' prepend-icon='icon-chatmoney' :label=`$t('ADD_ORDER.AMOUNT')`,  @click='showEditAmount' @blur='showEditAmount' :readonly='true' v-show='!amountShow&&amountValidate')
                v-select.no-border.height-48(v-model='addOrder.payment_term' solo :items='paymentTermsList' prepend-icon='mdi-credit-card' item-text='name' item-value='key' :label=`$t('ADD_ORDER.PAYMENT_TERM')`)
                v-select.no-border.height-48(v-model='addOrder.shipping_method' solo :items='orderShippingMethodsList' prepend-icon='mdi-truck-fast' item-text='name' item-value='key' :label=`$t('ADD_ORDER.SHIPPING_METHOD')`)
                v-select.no-border.height-48(v-model='addOrder.shipping_term' solo :items='orderShippingTermList' prepend-icon='mdi-truck' item-text='name' item-value='key' :label=`$t('ADD_ORDER.SHIPPING_TERM')`)
                v-text-field.no-border(v-model="addOrder.billing_address",prepend-icon="mdi-locker",:label=`$t('ADD_ORDER.BILLING_ADDRESS')`,)
                v-text-field.no-border(v-model="addOrder.shipping_address",prepend-icon="mdi-map",:label=`$t('ADD_ORDER.SHIPPING_ADDRESS')`,)
        v-card-actions.px-2.py-4
            v-row
                v-col.pr-2(cols="6")
                    v-btn(:ripple="false",@click="onCancel",width="100%",height="40",color="cancel",depressed,dark,) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols="6")
                    v-btn(:ripple="false",@click="onConfirm",width="100%",height="40",color="success",depressed,dark,)
                        v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
                        .t-white(v-else) {{$t('GENERAL.ADD')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>
<script>
    import Vue from 'vue';
    import i18n from '/common/plugins/vue-i18n.js';
    import errorDialog from "@/components/Dialog/errorDialog";
    import orderShippingMethodsList from "@/array/the_orderShippingMethodsList";
    import paymentTermsList from "@/array/the_paymentTermsList";
    import OrganizationDataService from "@/services/OrganizationDataService";
    import ContactDataService from "@/services/ContactDataService";
    import OrderDataService from "@/services/OrderDataService";

    export default Vue.extend({
        props: {
            addOrder: {
                type: Object,
                required: true,
            },
        },
        components: {
            errorDialog
        },
        data() {
            return {
                isLoading: false,
                addOrderValid: true,
                addBtn_loading:false,
                formRules: {
                    nameRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                    ],
                    amountRule: [(v) => /^\d{1,17}(\.\d{0,2})?$|(^$)|null/.test(v) && !!v || i18n.t('RULE.RULE_R_NUM_2')],
                    dateRule: [],
                    stageTypeRule: [(v) => !!v || i18n.t('RULE.RULE_PROCCESS')],
                    forecastRule: [(v) => !!v || i18n.t('RULE.RULE_FORECAST')],
                    companyRule: [
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
                    ],
                    contactRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
                    ],
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                },
                isNewContact: false,
                newContact: {
                    last_name: '',
                    first_name: '',
                },
                comboText: '',
                search: null,
                contactSearch: null,
                contact_loading: false,
                menu: false,
                organizationList: [],
                contactList: [],
                isContact: true,
                amountShow:false,
                formatAmount:null,
                amountValidate:false,
                paymentTermsList: paymentTermsList,
                orderShippingMethodsList: orderShippingMethodsList,
                orderShippingTermList: [
                    { key: "FOB", name: 'FOB' },
                    { key: "free_shipping", name: i18n.t('SHIPPINH_TERM.FREE_SHIPPING') },
                ],
                errorDialog: false,
                errorMessage: '',
                orderDetailData: null
            }
        },
        created() {
            
        },
        methods: {
            showFormatAmount(){
                this.amountShow = false;
                this.formatAmount = this.getFormatAmount(this.addOrder.amount);
                this.amountValidate = this.$refs.editAmount.validate();
            },
            showEditAmount(){
                this.amountShow = true;
                this.$refs.editAmount.focus();             
            },
            validateForm(){
                if(this.$refs.form.validate())
                {
                    return true;
                }
                else
                {
                    return false;
                }
            },
            async onConfirm() {
                if (this.validateForm() && this.addBtn_loading == false )
                {

                    let order_org = this.addOrder.organization;
                    let order_contact = this.addOrder.contact;
                    let new_contact_lastName = this.newContact.last_name;

                    if (this.comboText == '') 
                    {
                        order_org = {};
                    } 
                    else if (typeof(order_org) == 'string') 
                    {
                        order_org = {
                            id: -1,
                            name: order_org,
                            company_number: '',
                        }
                    }

                    if (this.isNewContact == true) 
                    {
                        if (new_contact_lastName == '') 
                        {
                            this.isContact = false;
                            order_contact = null;
                        }
                        else 
                        {
                            this.isContact = true;
                            order_contact = {
                                id: 0,
                                last_name: this.newContact.last_name,
                                first_name: this.newContact.first_name != null ? this.newContact.first_name : "",
                            }                       
                        }
                    }
                    else 
                    {
                        if (order_contact == '' || order_contact == null) 
                        {
                            this.isContact = false;
                            order_contact = null;
                        }
                        else if (typeof(order_contact) === 'string') 
                        {
                            this.showErrorDialog(i18n.t('ADD_DEAL.ERROR_2'));
                            return;
                        }
                        else 
                        {
                            this.isContact = true;
                        }
                    }

                    this.addBtn_loading = true;

                    let addOrderData = {}

                    addOrderData = {
                        name: this.addOrder.name,
                        organization: this.addOrder.organization ? order_org : null,
                        contact: this.isContact ? order_contact : null,
                        amount: this.addOrder.amount,
                        shipping_method: this.addOrder.shipping_method ? this.addOrder.shipping_method : null,
                        shipping_term: this.addOrder.shipping_term ? this.addOrder.shipping_term : null,
                        payment_term: this.addOrder.payment_term ? this.addOrder.payment_term : null,
                        billing_address: this.addOrder.billing_address ? this.addOrder.billing_address : null,
                        shipping_address: this.addOrder.shipping_address ? this.addOrder.shipping_address : null,
                        description: null,
                    };

                    await OrderDataService.create(addOrderData)
                    .then(response => {
                        this.addOrder.id = response.data.id;
                        this.addBtn_loading = false;
                        this.$emit('emitAddOrderDialog', true);
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    });

                }
            },
            onCancel() {
                this.$emit("emitAddOrderDialog", false);
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
        },
        computed: {
            isNew: function(){
                if(this.addOrder.organization == "" || this.addOrder.organization == null || this.addOrder.organization == " ")
                {
                    return false;
                }
                if(typeof (this.addOrder.organization) === 'object')
                {
                    if(this.addOrder.organization.id == 0)
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }
                else
                {
                    return true;
                }
            },
            addOrderTitle(){
                let tenant = localStorage.getItem('tenant');
                return this.$t(`ADD_ORDER.${tenant == 'wiadvance' || tenant == 'demo'  ? 'WIADVANCE_TITLE' : 'TITLE'}` );
            },
            addOrderName(){
                let tenant = localStorage.getItem('tenant');
                return this.$t(`ADD_ORDER.${tenant == 'wiadvance' || tenant == 'demo'  ? 'WIADVANCE_NAME' : 'NAME'}` );
            },
        },
        watch: {
            async search(val) {
                var re = /^(?!\s*$).+/;
                var check = re.test(val);
                var checkLength = /^.{0,200}$/.test(val);
                this.comboText = "";
                if(check && val != null && checkLength)
                {
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    this.comboText = val;
                    if (!this.isLoading && charNum>=2 && charNum==val.length ) 
                    {
                        this.isLoading = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val)
                            .then(response => { 
                                this.organizationList = response.data; 
                                let num = 0;
                                this.organizationList.forEach((item)=>{
                                    item.uqNumber = num;
                                    num ++;
                                })
                                })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    }
                    else 
                    {
                        this.isLoading = false;
                    }
                }
            },
            contactSearch(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString)
                {
                    this.contact_loading = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val))
                            {
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.contactList = array
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.contact_loading = false;
                    })
                }else{
                    this.contactList = [];
                }
            }
        }
    });
</script>
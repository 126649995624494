<template lang="pug">
    .email-oppties-dialog
        v-card
            v-card-title.d-flex.justify-space-between.align-center
                span {{$t('EMAILOPPTIESDIALOG.TITLE')}}
                v-btn(v-hide-element='false' icon @click='onCancel')
                    v-icon mdi-close
            //- v-divider.my-2
            v-row.small-deals-box(v-if='emailOpptiesData.length == 0')
                v-col.d-flex.align-center.justify-center.pa-0(cols='12')
                    h5.ma-0.t-black.ma-5 {{$t('EMAILOPPTIESDIALOG.NO_OPPTY')}}
            v-card-text
                v-list(two-line)
                    template(v-for="item in emailOpptiesData")
                        v-list-item.border.rounded.mt-4
                            v-list-item-content
                                v-btn.t-primary(@click='showToDealDialog(item)' color='primary' depressed) {{ item.name }} 
                                p.f-smaller.t-lightgary.ma-0.mt-2 {{$t('EMAILOPPTIESDIALOG.EXPECTED_CLOSE_DATE')}} {{ item.expected_close_date }}
                                p.f-smaller.t-lightgary.ma-0.mt-1 $ {{ item.amount }}                               
                        //- v-divider.my-2
        v-dialog(v-model='toDealDialog' max-width='1200' v-if='toDealDialog')
            to-deal(@emitToDealDialog='onEmitToDealDialog' :oppty-data='opptyData')
</template>

<script>
	import Vue from 'vue';
    import toDeal from '../../components/Dialog/Deal/toDeal';
toDeal
	export default Vue.extend({
        props: {
            emailOpptiesData: {
                type: Array,
                required: true,
            },
        },
        components: {
            toDeal,
        },
        data() {
			return {
                toDealDialog: false,
                opptyData: {},
			}
		},
        methods: {
            onCancel() {
                this.$emit('emitEmailOpptiesDialog', false);
            },
            showToDealDialog(data){
                this.opptyData = data;
                this.toDealDialog = true;
            },
            onEmitToDealDialog(){
                this.toDealDialog = false;
            },
        }
	});
</script>


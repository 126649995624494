export default {  
    mounted() {  
        if(this.$isViewOnly.isViewOnly){
            if (this.$options.name === 'v-btn' && !Object.keys(this.$options.propsData).includes("to")) {
                if(this.$el.hideElement===false){
                    this.$el.style.display = '';
                }else{
                    this.$el.style.display = 'none';
                }
            }
        }
    }
  }
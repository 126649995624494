<template lang='pug'>
    div
        .d-flex.align-center.justify-space-between.mt-2.mb-4
            h3.mt-2.mb-4.d-flex.align-center
                .cube-icon.small.bg-green.mr-2
                    v-icon(size='19' color='white') mdi-wrench-outline
                span {{$t('CASECARD.TITLE')}}
        v-row.sheet-box(v-if='openCases.length == 0 | openAmount == 0 ')
            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    h5.ma-0.t-black {{$t('CASECARD.NO_CASE')}}
        v-row.deal-box(v-else :class="showMore ? 'show-more':''")
            v-col.px-1.py-3( cols='4' v-for='item, index in openCases' :key='index' v-show='item.closed_reason == null')
                v-card.deals-item.pa-0( flat outlined @click='showToCaseDialog(item)')
                    v-card-title.d-flex.align-center.justify-space-between.px-4.pt-4.pb-0
                        v-row.ellipsis
                            v-col.d-flex.align-center.px-0(cols='10')
                                v-icon.mb-2(v-if="getItemNameFromList(casePriorityList, item.priority) == 'Very High'" size='18' color='red') fas fa-angle-double-up
                                v-icon.mb-2(v-if="getItemNameFromList(casePriorityList, item.priority) == 'High'" size='18' color='red') fas fa-angle-up
                                v-icon.mb-2(v-if="getItemNameFromList(casePriorityList, item.priority) == 'Medium'" size='18' color='orange') fas fa-equals
                                v-icon.mb-2(v-if="getItemNameFromList(casePriorityList, item.priority) == 'Low'" size='18' color='primary') fas fa-angle-down
                                v-icon.mb-2(v-if="getItemNameFromList(casePriorityList, item.priority) == 'Very Low'" size='18' color='primary') fas fa-angle-double-down
                                h5.ellipsis(:title='item.subject') {{ item.subject }}
                            v-tooltip(bottom)
                                template(v-slot:activator='{ on, attrs }')
                                    v-col(cols='2').d-flex.justify-end.align-center.px-0
                                        v-btn.border-none.customcolor(width='14' height='14' :color='item.stage_light_color.color' v-bind='attrs' v-on='on' fab depressed)
                                span {{ item.stage_light_color.tip }}
                    v-card-text.pa-0
                        v-row.d-flex.text-left(no-gutters='no-gutters')
                            v-col(cols='12')
                                p.mt-1.mb-2.px-4 {{$t('CASECARD.CASE_NUMBER')}} : {{ item.case_number != null ? item.case_number : $t('GENERAL.NONE') }}
                        v-row.d-flex.align-center(no-gutters)
                            v-col.pl-4(cols='9')
                                v-chip.text-white(v-if="item.status != null" color='primary' small lighten-3) {{ getItemNameFromList(caseStatusList, item.status) }}
                            v-col.text-right.pr-3(cols='3')
                                v-avatar(color='aliceBlue' size='28')
                                    span(v-if="item.owner.usersetting.photo === null") {{ item.owner.last_name }}
                                    img(v-else :src='item.owner.usersetting.photo' :alt='item.owner.usersetting.photo')
                        v-progress-linear.mt-2( :value='(item.stage_progress)*100' :color='item.current_stage.stage_color')
        v-row.justify-end
            v-btn.pa-0(v-hide-element='false' v-if='openAmount > 3' @click='moreOppty()' color='primary' text) {{ btnText }}
        v-dialog(v-model='toCaseDialog' max-width='1200' v-if='toCaseDialog' persistent @keydown.esc='onEmitToCaseDialog(true)')
            to-case(@emitToCaseDialog='onEmitToCaseDialog' :case-data='caseData')
</template>
<script>
import toCase from '@/components/Dialog/Case/toCase';
import casePriorityList from "@/array/the_casePriorityList";
import caseStatusList from "@/array/the_caseStatusList";

export default {
    props: {
        openCases: {
            type: Array,
            require:true,
        },
        openAmount:{
            type: Number,
            require:false,
        },
    },
    components: {
        toCase
    },
    data() {
        return {
            showMore: false,
            btnText: 'MORE',
            toCaseDialog:false,
            casePriorityList: casePriorityList,
            caseStatusList: caseStatusList,
        }
    },
    methods: {
        // dialog
        showToCaseDialog(item){
            this.caseData = item
            this.toCaseDialog = true
        },
        onEmitToCaseDialog(data) {
            if(data.id)
            {
                this.caseData.subject = data.subject;
                this.caseData.primary_contact = data.primary_contact;
                this.caseData.due_date = data.due_date;
                this.caseData.priority = data.priority;
                this.caseData.status = data.status;
                this.caseData.stage_progress = data.stage_progress;
                this.caseData.current_stage.id = data.current_stage;
                this.caseData.current_stage.stage_color = (data.stage_type.stagedef_set.find(item => item.id == data.current_stage)).stage_color;
                this.caseData.stage_light_color = data.stage_light_color;
            }
            this.toCaseDialog = false;
        },
        // other
        moreOppty() {
            if (this.showMore == true) {
                this.showMore = false;
                this.btnText = 'MORE';
            } else {
                this.showMore = true;
                this.btnText = 'LESS';
            }
        },
    },
}
</script>
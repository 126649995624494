<template lang="pug">
    v-card.to-deal-dialog
        v-overlay(v-show='loading_dealDetail' opacity='0' z-index='999')
        v-card-title.d-block.pt-5
         v-form(v-model='addDealValid' ref='form')
            v-row.d-flex.align-center.mb-6(no-gutters)
                v-col(v-if='!permitted' cols='11')
                    v-icon(size='30' color='red') icon-error
                    label {{$t('GENERAL.AUTH')}}   
                v-col(v-if='permitted' cols='8')
                    v-skeleton-loader(v-show='!isCreated & !isEdit' type="list-item-avatar")
                    v-row(no-gutters v-if='isCreated && !isEdit')
                        v-col(:cols='opptyNameWidth' ref="nameWidth")
                            h2.mb-0.ellipsis(v-show='isCreated & !isEdit') {{ opptyDetails.name }}
                        v-col(cols='1')
                                v-btn.ml-3(width='70' text color='gary' @click='onEdit' v-if='!isEdit && open')
                                    v-icon(size='20') icon-edit
                                    span {{$t('GENERAL.EDIT')}}
                    v-row(v-if='isEdit')
                        v-col(cols='6')
                            v-text-field.opptyNameFieldInput(v-model='opptyDetails.name' :rules="formRule.nameRules"  v-if='isEdit' width='400px')
                        v-col(cols='6').opptyNameField
                            v-btn(width='70' text color='gary' @click='cancelEdit()' v-if='isEdit')
                                v-icon(size='20') icon-cancel
                                span {{$t('GENERAL.CANCEL')}} 
                            v-btn.ml-3(width='70' text color='success' @click='confirmEdit()' v-if='isEdit')
                                v-icon(size='20') icon-check
                                span {{$t('GENERAL.SAVE')}}
                            v-progress-circular(size='18' v-if='loading_dealDetail' indeterminate color='primary' class='ml-3')
                v-col.text-right(cols='4')
                    v-chip(v-if='opptyDetails.closed_reason > 1' color='red' text-color='white') {{$t('TO_DEAL.LOST')}}，{{$t('TO_DEAL.BECAUSE')}} {{ closeReasonName }} {{$t('TO_DEAL.IN')}} {{ opptyDetails.closed_date }} {{$t('TO_DEAL.CLOSED')}}
                    v-chip(v-if='opptyDetails.closed_reason == 1' color='green' text-color='white') {{$t('TO_DEAL.IN')}} {{ opptyDetails.closed_date }} {{$t('TO_DEAL.WIN')}}
                    v-btn(v-hide-element='false' :ripple='false' @click='onCancel' depressed='depressed' icon)
                        v-icon icon-cancel
            v-skeleton-loader(v-if='permitted' v-show='!isCreated' type="table-heading, list-item-two-line")
            v-card.card-preface.px-0.py-2(flat v-if='permitted' v-show='isCreated')
                v-row.pa-0
                    v-col.pa-2.pa-md-3.ellipsis(cols='6' md='2' v-if='!isEdit')
                        v-card-subtitle.pa-0.t-lightgary {{$t('TO_DEAL.NAME')}}
                        a.f-text-highlight.mb-0(v-if='isOrg' :href="'/Account/' + customerId") {{ customerName }}
                        a.f-text-highlight.mb-0(v-else :href="'/Contacts/' + customerId") {{ customerName }}                    
                    v-col.pa-2.pa-md-3.ellipsis(cols='6' md='2' v-if='!isEdit')
                        v-card-subtitle.pa-0.t-lightgary {{$t('TO_DEAL.EXPECTED_CLOSE_DATE')}}
                        p.f-text-highlight.mb-0 {{ opptyDetails.expected_close_date }}                                            
                    v-col.pa-2.pa-md-3(cols='6' md='2' v-if='!isEdit')
                        v-card-subtitle.pa-0.t-lightgary {{$t('TO_DEAL.AMOUNT')}}
                        p.f-text-highlight.mb-0.t-green.ellipsis(:title='"$ "+ getFormatAmount(opptyDetails.amount)') {{ getFormatAmount(opptyDetails.amount) }}
                    //-Edit-----
                    //-org edit
                    v-col.px-2.pa-md-3.ellipsis(cols='6' md='3' v-if='opptyDetails.oppty_org != null & isEdit') 
                        v-combobox#oppty_org(v-if='isEdit && !isNew.oppty_org' v-model="opptyDetails.oppty_org" :label= `$t('TO_DEAL.NAME')` :items="organizationList" item-text="name" item-value="uqNumber" :search-input.sync="search_oppty_org" :loading="isLoading.oppty_org" :rules='formRule.companyRule')
                            template(v-slot:append-outer)
                                div(v-if="isOrgNew")
                                    v-icon(color="red", size="25") mdi-new-box
                            template(v-slot:item="data")
                                div.ellipsis(v-if="data.item.id == 0")
                                    span {{ data.item.name }}
                                    v-icon(color="red", size="25") mdi-new-box
                                div.ellipsis(v-else)
                                    span {{ data.item.name }}
                                    span.t-black.ml-2(v-if='isWiadvance() && data.item.customer_code') [ {{data.item.customer_code }} ]

                    //-contact edit
                    v-col.pa-2.pa-md-3.ellipsis(cols='6' md='3' v-if='opptyDetails.oppty_org == null & isEdit')          
                        div.d-flex.align-center.justify-space-between
                        .d-inline-flex.align-center.w-100
                            v-text-field(v-if='isEdit && isNew.oppty_contact' v-model="newOppCon.lName" :label= `$t('ADD_CONTACT.LAST_NAME')` validate-on-blur required="required" large="large" @input='cleanFirstName(newOppCon)' :rules='formRule.contactNameRules')
                            v-text-field(v-if='isEdit && isNew.oppty_contact' v-model="newOppCon.fName" :label= `$t('ADD_CONTACT.FIRST_NAME')` large="large" :rules='formRule.contentLenRule50')
                            v-btn.pr-0(class="ma-0" outlined fab small color="white" @click='isNew.oppty_contact = false' v-if='isEdit && isNew.oppty_contact')
                                v-icon(size='20' color="black") mdi-window-close
                            v-combobox#oppty_contact(v-if='isEdit && !isNew.oppty_contact' v-model="opptyDetails.oppty_contact" :label= `$t('TO_DEAL.NAME')`  :items="contactData" item-text="searchStr" item-value="id" :no-data-text=`$t('ADD_CASE.NO_CONTACT')` :search-input.sync="search_oppty_contact" :loading="isLoading.oppty_contact" :rules='formRule.contactRules' )
                                template(v-slot:prepend-item)
                                    v-list-item
                                        v-btn(width='120' height='32' color='blue' plain border-0 @click='isNew.oppty_contact = true')
                                            v-icon.mr-1(size='14') icon-add
                                            span {{$t('ADD_DEAL.NEW_CONTACT')}}   
                                template(v-slot:item='data')
                                    v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                        v-icon(v-if="data.item.image == null") mdi-account
                                        img(v-else :src='data.item.image')
                                    .t-black {{ data.item.full_name }} 
                                    .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                template(v-slot:selection='data')
                                    .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                    //-expected_close_date edit
                    v-col.pa-2.pa-md-3.ellipsis(cols='6' md='2' v-if='isEdit')                       
                        v-menu(v-if='isEdit' ref="menu" v-model="menu"  :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                            template(v-slot:activator="{ on, attrs }")
                                v-text-field(v-model='opptyDetails.expected_close_date' v-bind="attrs" v-on="on" :label=`$t('TO_DEAL.EXPECTED_CLOSE_DATE')` )                                
                            v-date-picker(v-model='opptyDetails.expected_close_date' @input='menu = false' no-title scrollable)     
                    //-amount edit
                    v-col.pa-2.pa-md-3(cols='6' md='2' v-if='isEdit')
                        v-text-field(v-model='opptyDetails.amount' :label=`$t('TO_DEAL.AMOUNT')`  :rules='formRule.amountRule' v-show='isEdit&&amountShow|!amountValidate' :ref="'editAmount'" @blur='showFormatAmount("amount")')
                        v-text-field(v-model='formatAmount' :label=`$t('TO_DEAL.AMOUNT')` @click='showEditAmount("amount")' @blur='showEditAmount("amount")' :readonly='true' v-show='!amountShow|!amountShow&&amountValidate')
                    //-Edit END------
                    v-col.pa-2.pa-md-3(cols='6' md='1' v-if='tipcheck === 0') 
                        .pointer
                            v-card-subtitle.pa-0.t-lightgary {{$t('TO_DEAL.SCORE')}}
                            span.mx-auto(v-if="opptyDetails.closed_reason === 1") 100
                            span.mx-auto(v-else-if="opptyDetails.closed_reason > 1") 0
                            span.mx-auto(v-else) {{ Math.ceil(score) }}                        
                    v-col.pa-2.pa-md-3(cols='6' md='1' v-if='tipcheck > 0')                          
                        v-tooltip(top v-if='isCreated')
                            template(v-slot:activator='{ on, attrs }' )
                                div.pointer(v-bind='attrs' v-on='on')
                                    v-card-subtitle.pa-0.t-lightgary {{$t('TO_DEAL.SCORE')}}
                                    span.mx-auto(v-if="opptyDetails.closed_reason === 1") 100
                                    span.mx-auto(v-else-if="opptyDetails.closed_reason > 1") 0
                                    span.mx-auto(v-else) {{ Math.ceil(score) }}  
                            p.ma-0(v-if='opptyDetails.stage_score.tip.account_history != ""') {{ opptyDetails.stage_score.tip.account_history }}
                            p.ma-0(v-if='opptyDetails.stage_score.tip.amount != ""') {{ opptyDetails.stage_score.tip.amount }}
                            p.ma-0(v-if='opptyDetails.stage_score.tip.expected_closing_date != ""') {{ opptyDetails.stage_score.tip.expected_closing_date }}
                            p.ma-0(v-if='opptyDetails.stage_score.tip.forecast != ""') {{ opptyDetails.stage_score.tip.forecast }}
                            p.ma-0(v-if='opptyDetails.stage_score.tip.stage != ""') {{ opptyDetails.stage_score.tip.stage }}                      
                    v-col.pa-2.pa-md-3(cols='6' md='1')
                        v-card-subtitle.pa-0.t-lightgary {{$t('TO_DEAL.PROGRESS')}}
                        p.f-text-highlight.mb-0.t-blue {{ Math.ceil(stage_progress*100) }}%
                    v-col.pa-2.pa-md-3(cols='12' md='3')
                        v-list-item.pa-0
                            v-list-item-avatar.ma-0.mr-2(color='aliceBlue')
                                span.mx-auto(v-if="!isPhoto") {{ opptyDetails.owner.last_name }}
                                img(v-else :src='opptyDetails.owner.usersetting.photo' :alt='opptyDetails.owner.last_name')
                            v-list-item-content.pa-0
                                v-card-subtitle.pa-0.t-lightgary {{$t('TO_DEAL.OWNER')}}
                                a.t-primary.mb-0.f-text-highlight.ellipsis(:href='onMail(opptyDetails.owner.email)') {{ opptyDetails.owner.last_name+ " "+opptyDetails.owner.first_name }}
        v-skeleton-loader(v-if='permitted' v-show='!isCreated' type="article, actions")
        v-card-text.process-card.pt-0.mt-md-10(v-if='permitted' v-show='isCreated' )            
            v-stepper.process-box(v-model='currentStep' )
                v-stepper-header.process-bar
                    template(v-for='item in opptySummary')
                        v-stepper-step(v-if="!isEdit" :key='`${item.sequence_number}-process`' :complete='currentStep > item.sequence_number || isProcessWon' :step='item.sequence_number' class='customcolor'  :style='getcolor(item.stage_color)' edit-icon='icon-check' :editable='isEdit')
                            span(v-if='currentStep < item.sequence_number')  {{ item.stage_name }}
                            span(v-else)  {{ item.stage_name }} {{ (item.stage_duration/(24*60*60)).toFixed(1) }} {{$t('GENERAL.DAY')}}
                        v-stepper-step(v-if="isEdit" @click='onChangeOpptyStage(item)' :key='`${item.sequence_number}-process`' :complete='currentStep > item.sequence_number || isProcessWon' :step='item.sequence_number' class='customcolor'  :style='getcolor(item.stage_color)'  edit-icon='icon-check' :editable='isProcessEdit')
                            span(v-if='currentStep < item.sequence_number')  {{ item.stage_name }}
                            span(v-else)  {{ item.stage_name }} {{ (item.stage_duration/(24*60*60)).toFixed(1) }} {{$t('GENERAL.DAY')}}
                    v-btn.mx-1(v-hide-element='false' v-if='allStage > 6 && isFirst && isProcessEdit' absolute style="marginTop: 4px; zIndex: 50;" fab dark x-small color="white" @click='preStage()')
                        v-icon(color="primary") icon-arrow-left
                    v-btn(v-hide-element='false' v-if='allStage > 6 && isLast && isProcessEdit' absolute style="marginTop: 4px; zIndex: 50; right: 5px"  fab dark x-small color="white" @click='nextStage()')
                        v-icon(color="primary") icon-arrow-right
                v-row(no-gutters)
                    //-opptySummary
                    v-col(cols='12' md='11')
                        v-stepper-items(:class="stepContent ? '':'hide' ")
                            v-stepper-content.pa-0(v-for='(item, index) in allProcess' :key='`${item.sequence_number}-process`' :step='item.sequence_number')
                                v-card(flat height='180')
                                    v-row(no-gutters='no-gutters')
                                        v-col.pr-md-10(cols='12' md='6')
                                            .d-flex.align-center
                                                h4.my-2 {{$t('TO_DEAL.INFO')}}
                                            p.d-flex.align-center.input-has-label.dance
                                                label(:for='`${index}-budget_confirmed`') {{$t('TO_DEAL.BUDGET')}}
                                                v-checkbox(v-model='opptyDetails.budget_confirmed' :id='`${index}-budget_confirmed`' :readonly='!isEdit' dense='dense' :hide-details='true')
                                        v-col.pr-md-10(cols='12' md='6')
                                            .d-flex.align-center
                                                h4.my-2 {{$t('TO_DEAL.GUIDE')}}
                                            p.d-flex.align-center.input-has-label.dance(style='height: 41px;') 
                                                label(:for='`${index}-stage_probability`') {{$t('TO_DEAL.PROBABILITY')}}
                                                label.t-black(v-if='item.stage_probability != null') {{ item.stage_probability +" %" }}
                                        v-col.pr-md-10(cols='12' md='6').my-3
                                            p.d-flex.align-start.input-has-label.border-0
                                                label.mt-2 {{$t('TO_DEAL.DESCRIPTION')}}
                                                v-textarea.textarea-style(v-model='opptyDetails.description' :id='`${index}-describe`' solo no-resize :readonly='!isEdit' dense='dense' rows='3' )
                                        v-col.pr-md-10(cols='12' md='6')
                                            p.d-flex.align-center.input-has-label.dance.border-0.mt-3
                                                v-textarea.textarea-style(v-model='item.guideline' :id='`${index}-guideline`' solo readonly no-resize dense='dense' rows='3')
                    v-col.pt-4(cols='12' md='1')
                        a( :href="'/Deal/' + opptyData.id")
                            v-btn.go-deal-btn(v-hide-element='false' @click='toDealPage()' width='100%' height='100%' color='purple' outlined)
                                v-icon(size='36') icon-chatmoney-border
                                span {{$t('TO_DEAL.DETAIL_PAGE')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')

</template>

<script>
    import Vue from 'vue';
    import OpportunityDataService from "@/services/OpportunityDataService";
    import OrganizationDataService from '@/services/OrganizationDataService';
    import ContactDataService from '@/services/ContactDataService';
    import get_oppty_closed_reason_func from "@/array/the_closeReasonList";
    import i18n from '/common/plugins/vue-i18n.js';
    import wiadvanceProgressCheck from "@/views-wiadvance/wiadvanceProgressCheck";
    import errorDialog from '@/components/Dialog/errorDialog';

    export default Vue.extend({
        components: {
            errorDialog
        },
        props: {
            opptyData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                errorDialog: false,
                errorMessage: '',
                opptyNameWidth:"",
                closeReasonList: [],
                closeReasonName: null,
                addDealValid: true,
                permitted: true,
                processComplete: false,
                isProcessEdit: true,
                stepContent: true,
                opptyDetails: {
                    owner: {},
                },			
                opptySummary: [],
                allStage: '',
                allProcess: null,
                currentStep: 0,
                isProcessWon: false,
                score:'',
                isCreated: false,
                loading: false,
                isPhoto: false,
                isOrg: true,
                customerId: "",
                customerName: "",
                tipcheck:0,
                isEdit:false,
                formRule: {
                    nameRules: [
                            (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_NAME'),
                            (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                        ],
                    dateRule: [],
                    contactRules: [(v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact')
                    ],
                    customerBudgetRule: [(v) => /^\d{1,17}?$|(^$)|null/.test(v) || i18n.t('RULE.RULE_R_NUM_3')],
                    amountRule: [(v) => /^\d{1,17}(\.\d{0,2})?$/.test(v) || i18n.t('RULE.RULE_R_NUM_2')],
                    textRule: [(v) => (v || '').length <=90 || i18n.t('RULE.RULE_90')],
                    companyRule: [
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
                    ],
                    contactNameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                },
                menu: false,
                amountShow: false,
                amountValidate: true,  
                formatAmount: null,
                originOpptyDetails: {},
                isNew: {
                    primary_contact: false,
                    oppty_org: false,
                    oppty_contact: false,
                },
                isContactDeal:true,
                organizationList: [],
                search_oppty_org: null,
                isLoading: {
                    primary_contact: false,
                    oppty_org: false,
                    oppty_contact: false,
                },
                comboInput:{
                    primary_contact: null,
                    oppty_org: null,
                    oppty_contact: null,
                },
                contactData: [],
                search_oppty_contact: null,
                newOppCon:{
                    fName: null,
                    lName: null,
                },
                isFirst: true,
                isLast: true,
                open:true,
                orign_expected_closed_date: this.opptyData.expected_close_date,
                orign_amount: this.opptyData.amount,
                loading_dealDetail: false,
                stage_progress:null,
            }
        },
        computed: {
            isOrgNew: function(){
                if(this.opptyDetails.oppty_org == ""){
                    return false;
                }
                if(typeof (this.opptyDetails.oppty_org) === 'object'){
                    if(this.opptyDetails.oppty_org.id == 0){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    return true;
                }
            }
        },
        async created(){
            this.getOpptyClosedReason();
            await (this.isCreated = false);
            await OpportunityDataService.getOpptyDetail(this.opptyData.id)
            .then(response => {                
                this.opptyDetails = response.data;  
                this.stage_progress = this.opptyDetails.stage_progress;
                if(this.opptyDetails.closed_reason == 0 | this.opptyDetails.closed_reason == null){
                    this.open = true
                }
                else
                {
                    this.open = false
                }              
                if(this.opptyDetails.oppty_org != null)
                {
                    this.isContactDeal = false
                }
                this.formatAmount = this.getFormatAmount(this.opptyDetails.amount)
                this.allProcess = response.data.summary;
                this.allStage = this.allProcess.length;
                this.score = this.opptyDetails.stage_score.score
                this.permitted = true;         
                this.closeReasonName = this.getCloseReasonName();        
                
                for(var i in this.opptyDetails.stage_score.tip) 
                {
                    const tipLength = this.opptyDetails.stage_score.tip[i].length
                    this.tipcheck = this.tipcheck+tipLength                     
                }   
            })
            .catch(response => {
                // Redirect to PermissionError page if error status is 403
                if (response.response.status == 403)
                    this.permitted = false;
            });
            if (this.permitted) {
                await (this.currentStep = this.allProcess.find(d=> d.id == this.opptyDetails.current_stage).sequence_number);
                await this.getOpptySummary();
                this.currentStep = this.currentStep - 1;
                if (this.opptyDetails.owner.usersetting.photo != null) {
                    this.isPhoto = true;
                }
                await (this.isCreated = true);
            }

            if (this.opptyDetails.oppty_org == null) {
                this.isOrg = false;
                this.customerId = this.opptyDetails.oppty_contact.id;
                this.customerName = this.opptyDetails.oppty_contact.full_name;
            } else {
                this.customerId = this.opptyDetails.oppty_org.id;
                this.customerName = this.opptyDetails.oppty_org.name;
            }
            this.getNameWidth()
        },
        methods: {
            async getOpptyClosedReason(){
                this.closeReasonList = await get_oppty_closed_reason_func();
            },
            getNameWidth(){
                if(this.$refs.nameWidth.clientWidth > 704){
                this.opptyNameWidth = '11'
                }else
                {
                    this.opptyNameWidth = 'auto'
                }

                if(this.$refs.nameWidth.clientWidth == 704 && this.opptyDetails.name.length>24)
                {
                    this.opptyNameWidth = '11'
                }  
            },
            getCloseReasonName(){
                if(this.opptyDetails.closed_reason != null && this.opptyDetails.closed_reason != false) {
                    const closeReasonObj = this.closeReasonList.find(e => e.key == this.opptyDetails.closed_reason);
                    return closeReasonObj.name;
                }
                else{
                    return '';
                }
            },
            getcolor: function(data) {
                this.colorQ= data
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ		
                }
            },
            validate() {
                this.$refs.form.validate()
            },
            onCancel() {
                let oldMonth = Number(this.orign_expected_closed_date.substr(5,2))
                let newMonth = Number(this.opptyData.expected_close_date.substr(5,2))
                let change_amount = this.opptyData.amount - this.orign_amount;
                let  array = [];
                array.push(oldMonth);
                oldMonth != newMonth ? array.push(newMonth) : '';

                let newYear = this.opptyData.expected_close_date.substr(0,4)
                this.$emit('emitToDealDialog', array,change_amount,newYear);
            },
            toDealPage: function () {
                this.$router.push(`/Deal/${this.opptyData.id}`);
            },
            getOpptySummary() {
                if (this.allStage > 6) {
                    if (this.currentStep > this.allStage - 4) {
                        this.opptySummary = this.allProcess.slice(this.allStage - 6, this.allStage + 6);
                    }
                    else if (this.currentStep < 4) {
                        this.opptySummary = this.allProcess.slice(0, 6);
                    }
                    else {
                        this.opptySummary = this.allProcess.slice(this.currentStep - 3, this.currentStep + 3);
                    }
                }
                else {
                    this.opptySummary = this.allProcess;
                }
                this.currentStep = this.currentStep + 1;
            },
            onMail: function (mail) {
                return "mailto:" + mail;
            },
            onEdit(){
                this.isEdit = true
                this.originOpptyDetails = JSON.parse(JSON.stringify(this.opptyDetails));
            },
            async confirmEdit(){
                if(this.comboInput.oppty_org != '' && this.$refs.form.validate()){
                    this.loading_dealDetail = true;
                    let data = JSON.parse(JSON.stringify(this.opptyDetails));
                    // ===== new oppty_contact start =====
                    if(data.oppty_org == null)
                        data.oppty_org = {};
                    else if(data.oppty_contact == null)
                        data.oppty_contact = {};
                    if(data.primary_contact == null || data.primary_contact == '')
                        data.primary_contact = {};

                    if(this.isNew.oppty_contact && this.newOppCon.lName)
                    {
                        data.oppty_contact = {
                            "id": 0,
                            "last_name": this.newOppCon.lName,
                            "first_name": null,
                        };
                        data.oppty_contact.last_name = this.newOppCon.lName;
                        if(this.newOppCon.fName != null)
                            data.oppty_contact.first_name = this.newOppCon.fName;
                        else 	
                            data.oppty_contact.first_name = null;
                    }
                    // ===== new oppty_contact end =====
                    // ===== new oppty_org start =====
                    if(this.comboInput.oppty_org != ''){
                        if(this.comboInput.oppty_org != data.oppty_org.name)
                        {
                            let newOrg = {
                                id: "-1", 
                                name: this.comboInput.oppty_org, 
                                company_number: "0"
                            };
                            data.oppty_org = newOrg;
                        }
                    }       
                    delete data.stage_progress;
                    delete data.stage_score;
                    delete data.summary;
                    delete data.owner;
                    delete data.stages;
                    delete data.contacts;
                    
                    await OpportunityDataService.editOpptyDetail(data)
                    .then(response => {
                        if (response.data.oppty_org != null) {
                            if (this.opptyDetails.oppty_org.id == 0) {
                                let principalData = {
                                    "organization_id": response.data.oppty_org.id,
                                    "owner_id": response.data.owner.id
                                };
                                ContactDataService.createCompanyPrincipal(principalData);
                            }
                        }
                        this.opptyDetails = response.data;    
                        if (this.opptyDetails.oppty_org == null) {
                            this.isOrg = false;
                            this.customerId = this.opptyDetails.oppty_contact.id;
                            this.customerName = this.opptyDetails.oppty_contact.full_name;
                        } else {
                            this.customerId = this.opptyDetails.oppty_org.id;
                            this.customerName = this.opptyDetails.oppty_org.name;
                        }
                        this.opptyData.name = response.data.name;
                        this.opptyData.oppty_contact = response.data.oppty_contact;
                        this.opptyData.oppty_org = response.data.oppty_org;
                        this.opptyData.expected_close_date = response.data.expected_close_date;
                        this.opptyData.amount = response.data.amount;
                        this.opptyData.budget_confirmed = response.data.budget_confirmed;
                        this.opptyData.description = response.data.description;
                        this.score = response.data.stage_score.score;
                        this.opptyDetails.stage_score.tip = response.data.stage_score.tip;
                        this.opptyData.stage_light_color = response.data.stage_light_color;
                        this.opptyData.stage_progress = response.data.stage_progress;
                        this.opptyData.current_stage.stage_color = (response.data.stage_type.stagedef_set.find(item => item.id == response.data.current_stage)).stage_color;
                        this.opptyData.current_stage.sequence_number = response.data.current_stage;
                        this.opptyData.current_stage.stage_name = (response.data.stage_type.stagedef_set.find(item => item.id == response.data.current_stage)).stage_name;
                        
                        this.defaultDetail();
                        this.isEdit = false;
                    })
                    .finally(()=>{
                        this.loading_dealDetail = false;
                    })
                }
                
                await(this.getNameWidth());
            },
            defaultDetail(){
                this.isNew = {
                    primary_contact: false,
                    oppty_org: false,
                    oppty_contact: false
                }
                this.newPri = {
                    fName: null,
                    lName: null,
                }
                this.newOppCon = {
                    fName: null,
                    lName: null,
                }
            },
            cancelEdit(){
                this.isEdit = false
                this.opptyDetails = this.originOpptyDetails;                
                this.defaultDetail();                
                this.formatAmount = this.getFormatAmount(this.originOpptyDetails.amount);
                this.amountValidate = true;                
                this.budgetValidate = true;
            },
            showFormatAmount(data){
                if(data==='amount'){
                    this.amountShow = false
                    this.formatAmount = this.getFormatAmount(this.opptyDetails.amount)
                    this.amountValidate = this.$refs.editAmount.validate()
                }
                if(data==='budget'){
                    this.budgetShow = false
                    this.formatBudget = this.getFormatAmount(this.opptyDetails.customer_budget).slice(0,-3)
                    this.budgetValidate = this.$refs.editBudget.validate()
                }
            },
            showEditAmount(data){
                if(this.isEdit && data==='amount')
                {
                    this.amountShow = true
                    this.$refs.editAmount.focus()
                }                            
            },            
            cleanFirstName(field){
            if(field.lName == null || field.lName == "")
                field.fName = null;
            },
            async nextStage() {
                if (this.opptySummary[5].sequence_number > this.allStage - 6) {
                    let n = this.allStage - 5;
                    this.changeAddStage(n);
                } else if (this.opptySummary[5].sequence_number < this.allStage) {
                    let n = this.opptySummary[5].sequence_number + 1;
                    this.changeAddStage(n);
                }
                await (this.currentStep = this.currentStep + 1);
                await (this.currentStep = this.currentStep - 1);
            },
            async preStage() {
                if (this.opptySummary[0].sequence_number <= 6) {
                    let n = 6;
                    await this.changeMinusStage(n);
                } else {
                    let n = this.opptySummary[0].sequence_number - 1;
                    await this.changeMinusStage(n);
                }
                await (this.currentStep = this.currentStep + 1);
            },
            changeAddStage(n) {
                this.opptySummary = this.allProcess.slice(n - 1, n + 5);
            },
            changeMinusStage(n) {
                this.currentStep = this.currentStep - 1;
                this.opptySummary = this.allProcess.slice(n - 6, n);
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            async onChangeOpptyStage(data) {
                let o_current_stage = JSON.parse(JSON.stringify(this.currentStep));
                if(this.isWiadvance() && parseInt(data.sequence_number) >= 3 && !await wiadvanceProgressCheck([this.opptyDetails.id])){
                    this.showErrorDialog(i18n.t('WIADVANCE.MESSAGE_1'));
                    this.currentStep = o_current_stage;
                    return
                }
                this.stageData = {
                    "current_stage_id": data.id
                }
                if(this.opptyDetails.current_stage != data && this.isProcessEdit == true)
                {
                    OpportunityDataService.opptySummaryUpdate(this.opptyDetails.id, this.stageData)
                    .then(response =>{                    
                        this.succMode="STAGE";
                        this.stageNumber = this.allProcess.find(d => d.id == response.data.current_stage_id).sequence_number;
                        this.stageName = this.allProcess.find(d => d.id == response.data.current_stage_id).stage_name;					
                        this.opptyDetails.current_stage = data.id;                    
                        this.opptyData.current_stage.id = data.id                    
                    });
                }
        },
        },
        watch: {
            async search_oppty_contact(val){
                this.comboInput.oppty_contact = val;

                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString){
                    this.isLoading.oppty_contact = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val)){
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.contactData = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.isLoading.oppty_contact = false;
                    })
                }
            },
            async search_oppty_org(val) { 
                var re = /^(?!\s*$).+/;
                var check = re.test(val);
                var checkLength = /^.{0,200}$/.test(val);
                this.comboInput.oppty_org = "";
                if(check && val != null && checkLength){
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    this.comboInput.oppty_org = val;
                    // this.comboText = val;
                    if(!this.isLoading.oppty_org && charNum >= 2 && charNum == val.length ){
                        this.isLoading.oppty_org = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val)
                        .then((response) => {
                            this.organizationList = response.data; 
                            let num = 0
                            this.organizationList.forEach((item)=>{
                            item.uqNumber = num
                            num ++
                            })                                                                                               
                        })
                        .finally(() => {
                            this.isLoading.oppty_org = false;
                        });
                    }
                    else {
                        this.isLoading.oppty_org = false;                        
                    }
                }
            },
            opptySummary(){
                if(this.opptySummary.length >= 6){
                    if (this.opptySummary[0].sequence_number == 1) {
                        this.isFirst = false;
                    } else {
                        this.isFirst = true;
                    }
                    if (this.opptySummary[5].sequence_number == this.allProcess.length) {
                        this.isLast = false;
                    } else {
                        this.isLast = true;
                    }
                }
                else{
                    this.isFirst = false;
                    this.isLast = false;
                }
            },
        }
    });
</script>

<style scoped>
.opptyNameField{
      margin-top: 18px;
}
.opptyNameFieldInput {
    font-size: 1.6em;
}
</style>
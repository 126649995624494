<template lang="pug">
// 修改聯絡人
v-card
  v-card-title.d-flex.justify-center {{$t('EDIT_CONTACT.TITLE')}}
  v-card-text
    v-form(v-model="valid", ref="form")
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.mb-6(
            v-model="contactData.last_name",
            :label= `$t('EDIT_CONTACT.LAST_NAME')`,
            :rules="formRule.nameRules",
            validate-on-blur,
            required="required",
            large="large",
          )
        v-col(cols="6")
          v-text-field.mb-6(
            v-model="contactData.first_name",
            :label=`$t('EDIT_CONTACT.FIRST_NAME')`,
            :rules="formRule.contentLenRule50",
            large="large"
          )
      v-text-field.no-border(
        v-model="contactData.email",
        prepend-icon="mdi-email",
        :label= `$t('GENERAL.MAIL')`,
        :rules="formRule.emailRules",
        validate-on-blur,
      )
      v-row(no-gutters="no-gutters")
        v-col(cols="6").mt-1.pt-3
          v-row(no-gutters="no-gutters")
            v-col(cols="2")
              v-icon(size='20') mdi-cellphone
            v-col
              vue-tel-input-vuetify.no-border(
                v-model="contactData.mobile_phone",
                solo,
                :label=`$t('EDIT_CONTACT.MOBILE_PHONE')`,
                :selectLabel=`$t('EDIT_CONTACT.SELECT_LABEL')`,
                placeholder='',
                autocomplete='off',
                :defaultCountry='getLocation()',
                disabledFetchingCountry=true,
                mode='international',
                @validate="onPhoneCheck($event)",
                @input="onPhoneInput",
                :rules="formRule.phoneRules",
                )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="contactData.office_phone",
            prepend-icon="mdi-phone",
            :rules="formRule.contentLenRule50",
            :label=`$t('EDIT_CONTACT.OFFICE_PHONE')`,
          )
      v-select.no-border(
        v-model="contactData.level",
        :items="levelList",
        item-text="name",
        item-value="key",
        prepend-icon="mdi-domain",
        :label=`$t('EDIT_CONTACT.LEVEL')`,
      )
      v-combobox.no-border(
        v-model="contactData_organization_set",
        :items="organizationList",
        item-text="name",
        item-value="uqNumber",
        :search-input.sync="search",
        :loading="isLoading",
        prepend-icon="mdi-domain",
        :label=`$t('EDIT_CONTACT.ORG_NAME')`,
        required="required",
        validate-on-blur,
        :rules="formRule.companyRule",
      )
        template(v-slot:append-outer)
          div(v-if="isNew")
            v-icon(color="red", size="25") mdi-new-box
        template(v-slot:item="data")
            div.ellipsis(v-if="data.item.id == 0")
              span {{ data.item.name }}
              v-icon(color="red", size="25") mdi-new-box
            div.ellipsis(v-else)
              span {{ data.item.name }}
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="contactData.facebook",
            prepend-icon="mdi-facebook",
            :rules='formRule.fbRules',
            label="Facebook"
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="contactData.instagram_id",
            prepend-icon="mdi-instagram",
            :rules="formRule.contentLenRule50",
            label="Instagram"
          )
      v-row(no-gutters="no-gutters")
        v-col(cols="6")
          v-text-field.no-border(
            v-model="contactData.line_id",
            prepend-icon="fab fa-line",
            :rules="formRule.contentLenRule50",
            label="Line ID"
          )
        v-col(cols="6")
          v-text-field.no-border(
            v-model="contactData.linkedin",
            prepend-icon="mdi-linkedin",
            :rules='formRule.linkRules',
            label="LinkedIn"
          )
      v-file-input.no-border(v-model="contactImage",prepend-icon="mdi-image", :label=`$t('EDIT_CONTACT.IMAGE')`, @change='onChangePhoto' :rules="formRule.photoRule")
      v-window
        customFieldRequired(ref='CFRequired' DataClass='Contact' :editData='this.contactData.custom_field'  :StageTypeId='null' ).mt-3

  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="success",
          depressed,
          dark
        ) 
          v-progress-circular(v-if='editBtn_loading' indeterminate size='20')
          .t-white(v-else) {{$t('GENERAL.SAVE')}}
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
    error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import OrganizationDataService from "../../../services/OrganizationDataService";
import ContactDataService from "../../../services/ContactDataService";
import levelList from "@/array/the_levelList";
import errorDialog from '@/components/Dialog/errorDialog';
import i18n from '/common/plugins/vue-i18n.js'; 
import customFieldRequired from "@/components/Dialog/customFieldRequired";

export default Vue.extend({
  props: {
    contactData: {
      type: Object,
      required: true,
    },
  },
  components: {
    errorDialog,
    customFieldRequired,
  },
  data() {
    return {
      editBtn_loading:false,
      valid: true,
      isLoading: false,
      search: null,
      errorDialog: false,
      errorMessage: '',
      organizationList:this.contactData.organization_set,
      contactData_organization_set: this.contactData.organization_set[0],
      contactImage: null,
      contactphoto:this.contactData.image,
      userphoto:this.contactData.owner.usersetting.photo,
      levelList: levelList,
      comboText: "",
      formRule: {
        nameRules: [
          (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
        ],
        emailRules: [
          (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || "請填寫正確email格式",
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
        ],
        phoneRules: [],
        linkRules: [
          (v) => /(http|https):\/\/\S*|(^$)|null/.test(v) || i18n.t('RULE.RULE_HTTP'),
          (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70'),
        ],
        fbRules: [
          (v) => /(http|https):\/\/\S*|(^$)|null/.test(v) || i18n.t('RULE.RULE_HTTP'),
          (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70'),
        ],
        contentLenRule70: [(v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')],
        contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
        contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
        contentLenRule10: [(v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')],
        photoRule: [
          () => { return (!this.photoSize || i18n.t('RULE.RULE_FILE_2'))},
          () => { return (!this.fileType || i18n.t('RULE.RULE_PHOTO'))},
        ],
        companyRule: [
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
          (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
        ],
      },
      isNumVaild: true,
      vue_tel: {
        isValid: true,
      },
      cfValid:true,
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    validateForm(){
      if(this.$refs.form.validate())
        return true;
      else
        return false;
    },
    getResponsedata(){
      this.$refs.CFRequired.emitData();
      if(this.$refs.CFRequired.responsedata === false)
      {
        this.$refs.CFRequired.responsedata = null;
        this.cfValid = false;
        return;
      }
      this.contactData.custom_field = this.$refs.CFRequired.responsedata;
    },
    async onConfirm() {
      this.formRule.phoneRules.push(() => {return (this.isNumVaild || i18n.t('CONTACT_DETAIL.ERROR_3'))});
      if(this.validateForm() && this.editBtn_loading == false )
      {
        if((this.contactData.custom_field != null && this.contactData.custom_field !== undefined) || this.$refs.CFRequired.process_array.length != 0)
          this.getResponsedata();
        if(this.cfValid == false)
        {
          this.cfValid = true;
          return;
        }
        let contactDataOrg = this.contactData.organization_set;
        let org = this.contactData_organization_set;
        let newOrg = {id: "-1", name: this.comboText, company_number: "0"};
        if (contactDataOrg.length > 0)
        {
          if(this.comboText != "")
          {
            if (contactDataOrg[0].id != org.id)
            { 
              contactDataOrg.splice(0, 1, org.id == undefined ? newOrg : org);
            }
            else if (org.name != this.comboText)
            {
              contactDataOrg.splice(0, 1, newOrg);
            }
          }
          else
          {
            contactDataOrg.length = 0;
          }
        }
        else if (this.comboText != "")
        {
          if (org == undefined)
            contactDataOrg.push(newOrg);
          else if (org.name != this.comboText)
            contactDataOrg.push(newOrg);
          else
            contactDataOrg.push(org);
        }
        let isNewOrg = false;
        if (this.contactData.organization_set.length != 0) 
        {
          if (this.contactData.organization_set[0].id == 0) 
          {
            isNewOrg = true;
          }
        }
        if (this.contactData.organization_set.length != 0) 
        {
          let orgIdList = [];
          let orgNameList = [];
          this.contactData.organization_set.forEach(org => {
            orgIdList.push(org.id);
          });

          orgIdList.splice(0, 1);
          this.contactData.organization_set.forEach(org => {
            orgNameList.push(org.name);
          });

          orgNameList.splice(0, 1);
          var indexId = orgIdList.indexOf(this.contactData.organization_set[0].id);
          var indexName = orgNameList.indexOf(this.contactData.organization_set[0].name);

          if (indexId != -1 || indexName != -1)
          {
            this.showErrorDialog(i18n.t('ADDCONTACTORG.ERROR'));
            return;
          }
        }
        delete this.contactData.total_amount;
        delete this.contactData.total_amount_adj;
        delete this.contactData.primary_campaign_id;
        delete this.contactData.primary_campaign_name;
        delete this.contactData.created_by;
        delete this.contactData.updated_by;
        this.editBtn_loading = true;
        await ContactDataService.edit(this.contactData)
        .then((response)=>{
          // if(this.contactData.organization_set.length > 0)
          //   this.contactData.organization_set[0].id = response.data.organization_set[0].id;            
          // this.contactData.image = this.contactphoto,
          // this.contactData.owner.usersetting.photo = this.userphoto

          if (isNewOrg == true) 
          {
            let principalData = {
              "organization_id": response.data.organization_set.pop().id,
              "owner_id": response.data.owner.id,
            };  
            ContactDataService.createCompanyPrincipal(principalData);
          }
        });

        if (this.contactImage != null) 
        {
          await ContactDataService.image(this.contactData.id, this.contactImage)
          .then(response => {
              this.contactData.image = response.data.image;              
          });
        }
        this.editBtn_loading = false;
        this.$emit("emitEditContactDialog", true);
      }
    },
    onCancel() {
      this.$emit("emitEditContactDialog", false);
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    onPhoneCheck(event) {
      this.vue_tel = event;
      if (this.vue_tel.isValid == true) 
      {
        this.isNumVaild = true;
      }
    },
    onPhoneInput() {
      if (this.isNumVaild == true) 
      {
        if (this.contactData.mobile_phone != this.vue_tel.number.input && this.contactData.mobile_phone != "") 
        {
          this.isNumVaild = false;
        }
      } 
      else 
      {
        if (this.contactData.mobile_phone == "") 
        {
          this.isNumVaild = true;
        }
      }
    },
    onChangePhoto(event) {
      if (event != null) 
      {
        // file type
        let n = event.type.indexOf("image");
        if (n == -1) 
        {
          this.fileType = true;
        } 
        else 
        {
          this.fileType = false;
        }

        // photo size
        if (event.size > 5242880) 
        {
          this.photoSize = true;
        } 
        else 
        {
          this.photoSize = false;
        }
      } 
      else 
      {
        this.photoSize = false;
        this.fileType = false;
      }
    },
  },
  computed: {
    isNew: function(){
      if(this.contactData_organization_set == " " || this.contactData_organization_set == null)
      {
        return false;
      }
      if(typeof (this.contactData_organization_set) === 'object')
      {
        if(this.contactData_organization_set.id == 0)
        {
          return true;
        }
        else
        {
          return false;
        }
      }
      else
      {
        return true;
      }
    },
  },
  watch: {
    async search(val) { 
      var re = /^(?!\s*$).+/;
      var check = re.test(val);
      var checkLength = /^.{0,200}$/.test(val);
      this.comboText = "";
      if(check && val != null && checkLength)
      {
        let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
        this.comboText = val;
        if(!this.isLoading && charNum>=2 && charNum==val.length )
        {
          this.isLoading = true;
          await OrganizationDataService.findOrganizationByNamefromDB(val)
          .then((response) => {
            this.organizationList = response.data;
            let num = 0;
            this.organizationList.forEach((item)=>{
              item.uqNumber = num;
              num ++;
              })
            })
          .finally(() => {
            this.isLoading = false;
          });
        }
      }
      else 
      {
          this.isLoading = false;
      }
    }
  },  
});
</script>